import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import CompanyProfile from '../company/companyProfile';

import LayoutService from '../../services/layoutService';
import { addQueryParam, getQueryParam } from '../../utils/url-utils.js';
import CompaniesListPage from '../company/companyListPage/companies';
import CompanyNotifications from '../companyNotifications/companyNotifications';
import DivisionsListPage from '../divisions/divisions';
import ListCategoryPage from '../listCategoryPage/listCategoryPage';
import UserIndexPage from '../userListPage';
import XmatrixCycleListPage from '../xmatrixCycle/xmatrixCycleListPage';

const SettingsPageIndex = (props) => {
  const t = props.t;

  const [menu, setMenu] = useState();
  const [activeTab, setActiveTab] = useState(0);

  const getComponentForMenuItem = (menuItemLink) => {
    switch (menuItemLink) {
      case '/companies':
        return <CompaniesListPage />;
      case '/divisions':
        return <DivisionsListPage />;
      case '/utenti':
        return <UserIndexPage />;
      case '/categorie':
        return <ListCategoryPage />;
      case '/notifications':
        return <CompanyNotifications />;
      case '/strategicCycle':
        return <XmatrixCycleListPage />;
      default:
        return <div>No component found for this link</div>;
    }
  };

  useEffect(() => {
    setActiveTab(+getQueryParam('tab') || 0);

    LayoutService.getMenuItem()
      .then((response) => response.data)
      .then((data) => {
        if (data.success) {
          const menuItems = data.responseObject.value
            .filter((menu) => menu.menuLink === '/configurazione')
            .flatMap((menu) =>
              menu.menuItems.map((item, index) => ({
                key: index,
                label: item.menuItemDisplayName,
                children: getComponentForMenuItem(item.menuItemLink),
              })),
            )
            .concat({
              key: '20',
              label: t('headerMenu.miaSocieta'),
              children: <CompanyProfile />,
            });

          setMenu(menuItems);
        }
      })
      .catch((error) => {
        console.error('Error fetching menu items:', error);
      });
  }, []);

  return (
    <Tabs
      className="tw-px-4"
      onChange={(key) => {
        addQueryParam('tab', key);
        setActiveTab(key);
      }}
      activeKey={activeTab}
      items={menu}></Tabs>
  );
};

export default withTranslation()(SettingsPageIndex);

import { Col, Space, Table, Tooltip } from 'antd';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { RepositoryLineIcon } from '../../assets/icons';
import CommonService from '../../services/commonService';
import MatrixService from '../../services/matrix/matrixService';
import UsersService from '../../services/pages/usersService';
import XmatrixDropDownFilter from '../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import {
  BudgetSemaphore,
  BudgetTooltip,
  QualitySemaphore,
  QualityTooltip,
  TimeSemaphore,
  TimeTooltip,
} from '../shared/semaphores/semaphores';
import { filterRoles, statusPickListCodes } from '../shared/utils/constants';

const checkRoleFilter = (option) => {
  if (option === 'all') {
    return ['Team Leader', 'Team Member', 'Sponsor'];
  }

  if (option === 'tmtl') {
    return ['Team Leader', 'Team Member'];
  }

  if (option === 'sponsor') {
    return ['Sponsor'];
  }
};

const UserProjects = (props) => {
  const [loadingProjects, setLoadingProjects] = useState(false);
  const [userProjects, setUserProjects] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [roleListFilter] = useState(filterRoles);
  const [statusListFilter, setStatusListFilter] = useState([]);
  const [filteredInfo, setFilteredInfo] = useState(checkRoleFilter(props.toFilter));
  const [filteredStatusInfo, setFilteredStatusInfo] = useState([
    `${props.t('status.draft')}`,
    `${props.t('status.attivo')}`,
  ]);

  useEffect(() => {
    retrieveComponentData();
  }, []);

  const retrieveComponentData = async () => {
    await retrieveStatusList();
    await getActiveXmatrix();
  };

  const getActiveXmatrix = async () => {
    try {
      const response = await MatrixService.getActiveXmatrix();
      const resp = response.data;
      if (resp.success) {
        setActiveXmatrixInfo(resp.responseObject.value);
        retrieveUserProjects(resp.responseObject.value.xMatrixID);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onXmatrixSelect = (selectedXmatrix) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
    retrieveUserProjects(selectedXmatrix.value);
  };

  const retrieveUserProjects = async (xmatrix) => {
    const { userData, userId } = props;
    const matrixId = selectedXmatrixValue || activeXmatrixInfo?.xMatrixID || xmatrix;

    if (userData && Object.keys(userData).length > 0 && matrixId) {
      setLoadingProjects(true);
      try {
        const response = await UsersService.getUserProjects(matrixId, userId);
        const resp = response.data;
        if (resp.success) {
          setUserProjects(resp.responseObject.value);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoadingProjects(false);
      }
    }
  };

  const retrieveStatusList = async () => {
    const objectCode = statusPickListCodes.statusProjects;
    try {
      const response = await CommonService.getStatusData(objectCode);
      const resp = response.data;
      if (resp.success) {
        const statusOptions = resp.responseObject.value.map((item) => ({
          text: item.statusDescription,
          value: item.statusDescription,
        }));
        setStatusListFilter(statusOptions);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (pagination, filters) => {
    setFilteredInfo(filters.userRole);
    setFilteredStatusInfo(filters.statusDescription);
  };

  const { companyData, isCompact, t: t } = props;

  const userProjectColumns = [
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'projectCode',
      width: '150px',
      sorter: (a, b) => a.projectCode.localeCompare(b.projectCode),
      defaultSortOrder: 'ascend',
      render: (text, record) => (
        <div className="tw-flex tw-flex-row tw-items-center tw-justify-between">
          <Link to={{ pathname: `/progetti/id/${record.projectID}` }}>{text}</Link>
          {record.projectRepository && (
            <Tooltip title={t('a3Page.repositoriProgetto')}>
              <a
                href={record.projectRepository}
                target="_blank"
                rel="noreferrer">
                <RepositoryLineIcon />
              </a>
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: `${t('general.nome')}`,
      dataIndex: 'projectName',
      sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      width: '100px',
      filters: statusListFilter,
      filteredValue: filteredStatusInfo || null,
      onFilter: (value, record) => (record.statusDescription ? record.statusDescription.includes(value) : ''),
    },
    {
      title: `${t('general.ruolo')}`,
      dataIndex: 'userRole',
      width: '14ch',
      sorter: (a, b) => (a.userRole || '').localeCompare(b.userRole || ''),
      filters: roleListFilter,
      filteredValue: filteredInfo || null,
      onFilter: (value, record) => (record.userRole ? record.userRole.includes(value) : ''),
    },
    {
      key: 'action1',
      title: `${t('general.indicators')}`,
      width: '22ch',
      render: (text, record) => (
        <Space direction="horizontal">
          <Tooltip
            placement="top"
            title={
              <TimeTooltip
                data={record}
                t={t}
              />
            }>
            <Col
              className="semaphore-section"
              xs={8}
              sm={8}>
              <TimeSemaphore data={record} />
            </Col>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              <BudgetTooltip
                data={record}
                t={t}
                companyData={companyData}
              />
            }>
            <Col
              className="gutter-row semaphore-section"
              xs={8}
              sm={8}>
              <BudgetSemaphore data={record} />
            </Col>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              <QualityTooltip
                data={record}
                t={t}
              />
            }>
            <Col
              className="gutter-row semaphore-section no-border red-color-bgr"
              xs={8}
              sm={8}>
              <QualitySemaphore data={record} />
            </Col>
          </Tooltip>
        </Space>
      ),
    },
  ];

  const compactUserProjectColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'projectName',
      sorter: (a, b) => a.projectName.localeCompare(b.projectName),
      ellipsis: {
        showTitle: true,
      },
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      filters: statusListFilter,
      width: '100px',
      filteredValue: filteredStatusInfo || null,
      onFilter: (value, record) => (record.statusDescription ? record.statusDescription.includes(value) : ''),
    },
    {
      title: `${t('general.end')}`,
      dataIndex: 'endDate',
      width: '100px',
    },
  ];

  return (
    <>
      <div className="xm-filter-wrapper">
        {!isCompact && activeXmatrixInfo && (
          <XmatrixDropDownFilter
            onXmatrixSelect={onXmatrixSelect}
            activeXmatrixInfo={activeXmatrixInfo}
            t={t}
          />
        )}
      </div>

      <Table
        columns={isCompact ? compactUserProjectColumns : userProjectColumns}
        dataSource={userProjects}
        rowKey={(_, i) => i}
        loading={loadingProjects}
        size="small"
        onChange={handleChange}
        pagination={{ defaultPageSize: 5, pageSizeOptions: ['5', '10', '20', '50'] }}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default withTranslation()(connect(mapStateToProps)(UserProjects));

import { DownOutlined } from '@ant-design/icons';
import { Dropdown, Space } from 'antd';
import { Component } from 'react';
import MatrixService from '../../../../services/matrix/matrixService';

class XmatrixDropDownFilter extends Component {
  constructor(props) {
    super(props);

    this.state = {
      xmatrixRootList: null,
      xmatrixOptions: [],
      loadingRootList: false,
      selectedXmatrixInfo: null,
    };
  }

  componentWillMount() {
    this.retrieveComponentData();
  }

  retrieveComponentData = async () => {
    const { preSelectedXmatrixId } = this.props;
    await this.getXmatrixRootList();

    if (preSelectedXmatrixId) {
      let item = { key: preSelectedXmatrixId };
      this.handleDropdownItemClick(item);
    }
  };

  async getXmatrixRootList() {
    const { activeXmatrixInfo } = this.props;
    this.setState({ loadingRootList: true });
    await MatrixService.getXmatrixDivisionRootList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let xmatrixSelectionList = [];
          this.setState({ loadingRootList: false });
          let respData = resp.responseObject.value;
          this.setState({ xmatrixRootList: respData });

          respData.map((item, index) => {
            let xmatrixObjectParent = { key: null, label: null, className: '' };
            let xmatrixObjectChild = { key: null, label: null, children: [] };

            if (item.childList && item.childList.length > 0) {
              xmatrixObjectChild.key = item.xmatrixID + index;
              xmatrixObjectChild.label = item.hkCode;

              if (item.xmatrixID === activeXmatrixInfo?.xMatrixID) {
                xmatrixObjectChild.className = 'active-xmatrix-dropdown';
              }

              let parentAschild = { key: item.xmatrixID, label: item.hkCode };
              xmatrixObjectChild.children.push(parentAschild);

              item.childList.map((child) => {
                let childrenObject = { key: null, label: null };
                childrenObject.key = child.xmatrixID;
                childrenObject.label = child.hkCode;
                xmatrixObjectChild.children.push(childrenObject);
              });

              xmatrixSelectionList.push(xmatrixObjectChild);
            } else {
              if (item.xmatrixID === activeXmatrixInfo?.xMatrixID) {
                xmatrixObjectParent.className = 'active-xmatrix-dropdown';
              }

              xmatrixObjectParent.key = item.xmatrixID;
              xmatrixObjectParent.label = item.hkCode;
              xmatrixSelectionList.push(xmatrixObjectParent);
            }
          });
          this.setState({ xmatrixOptions: xmatrixSelectionList });
        } else {
          this.setState({ loadingRootList: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingRootList: false });
      });
  }

  recursiveSearchXmatrixArray = (arr, target) => {
    for (let i = 0; i < arr?.length; i++) {
      if (arr[i].xmatrixID === target.id) {
        return arr[i];
      }
      if (arr[i].childList && arr[i].childList.length > 0) {
        const result = this.recursiveSearchXmatrixArray(arr[i].childList, target);
        if (result) {
          return result;
        }
      }
    }
    return null;
  };

  handleDropdownItemClick = (item) => {
    let { xmatrixRootList } = this.state;
    const xmatrix = this.recursiveSearchXmatrixArray(xmatrixRootList, { id: item.key });

    if (xmatrix) {
      let selectedXmatrix = { label: xmatrix.hkCode, value: xmatrix.xmatrixID };

      this.setState({ selectedXmatrixValue: item.key, selectedXmatrixInfo: selectedXmatrix }, () => {
        this.props.onXmatrixSelect(selectedXmatrix);
      });
    }
  };

  render() {
    const { xmatrixRootList, loadingRootList, selectedXmatrixInfo, xmatrixOptions } = this.state;
    const { t, activeXmatrixInfo } = this.props;

    return (
      <>
        {xmatrixRootList && xmatrixRootList.length > 0 && activeXmatrixInfo && (
          <Dropdown.Button
            trigger={['click']}
            icon={<DownOutlined />}
            loading={loadingRootList}
            menu={{
              onClick: this.handleDropdownItemClick,
              items: xmatrixOptions,
            }}>
            <Space direction="horizontal">
              {t('general.xmatrix')}:{selectedXmatrixInfo ? selectedXmatrixInfo.label : activeXmatrixInfo.hkCode}
            </Space>
          </Dropdown.Button>
        )}
      </>
    );
  }
}

export default XmatrixDropDownFilter;

import { trackPromise } from 'react-promise-tracker';
import { acquireToken } from '../../config/auth/clientAuthProvider';
import axiosInstance from '../../config/axiosConfig/interceptors';
import i18n from '../../i18n';
import {
  AddRemoveCommitteeIDProjectRequestDto,
  authenticatedApi,
  CommonApi,
  GetCommitteeSintesiListRequest,
  InsertCommitteeRequestDto,
  InsertUpdateAgendaRequestDto,
  InsertUpdateCommitteeSessionRequestDto,
  UpdateCommitteeCommentRequestDto,
  UpdateCommitteeRequestDto,
} from 'src/config/connectors';

const CommitieService = {
  // get commities
  getCommitiesList: async function (withSecurity: boolean) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeListPage(withSecurity));
  },

  getCommitieByiD: async function (committeeId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeByID(committeeId));
  },

  updateCommitie: async function (payload: UpdateCommitteeRequestDto) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonUpdateCommittee(payload));
  },

  addCommitie: async function (payload: InsertCommitteeRequestDto) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonInsertCommittee(payload));
  },

  //delete committie
  deleteCommittie: async function (committeeId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonDeleteCommittee(committeeId));
  },

  getComitieRelatedProjects: async function (committeeId: string, xmatrixId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeProjects(committeeId, xmatrixId));
  },

  getComitieRelatedComities: async function (committeeId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetChildCommittee(committeeId));
  },

  getNoComititeProjects: async function (xmatrixId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetNoCommitteeProject(xmatrixId));
  },

  addRemoveProjectToCommitie: async function (payload: AddRemoveCommitteeIDProjectRequestDto) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonAddRemoveCommitteeIDProject(payload));
  },

  getAvailableChildCommititesList: async function (committeeId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetAvailableChildCommittees(committeeId));
  },

  addCommitteeChildRelation: async function (payload: { committeeID: string; childID: string }) {
    const { committeeID: commiteeId, childID: childCommitteeId } = payload;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonAddCommitteeChildRelation(commiteeId, childCommitteeId));
  },

  removeChildComitie: async function (childCommitteeId: string, committeeId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonDeleteCommitteeChild(committeeId, childCommitteeId));
  },

  getCommitieSessions: async function (committeeId: string, xmatrixId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeSessions(committeeId, xmatrixId));
  },

  insertUpdateSession: async function (payload: InsertUpdateCommitteeSessionRequestDto) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonInsertUpdateCommitteeSession(payload));
  },

  getAgenda: async function (committeeId: string, sessionId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetAgenda(committeeId, sessionId));
  },

  insertUpdateAgenda: async function (payload: InsertUpdateAgendaRequestDto) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonInsertUpdateAgenda(payload));
  },

  getComitieSummaryData: async function (committeeId: string, xmatrixId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeSummary(committeeId, xmatrixId));
  },

  getComitieActivities: async function (committeeId: string, onlyClosedActivities: boolean) {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeActivities(committeeId, currentLanguageCode, onlyClosedActivities));
  },

  getComitieActivitiesByDays: async function (committeeId: string, onlyClosedActivities: boolean) {
    const currentLangCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeActivities(committeeId, currentLangCode, onlyClosedActivities));
  },

  addComitieActicity: async function (payload: any) {
    const data = acquireToken().then((tokenResponse) => {
      const optionsVal = {
        method: 'POST',
        url: `api/Common/InsertComitieActivity`,
        data: payload,
        headers: {
          Authorization: `Bearer ${tokenResponse?.accessToken}`,
        },
      };
      const apiResponse = axiosInstance(optionsVal);
      return apiResponse;
    });
    return data;
  },

  updateComitieActicity: async function (payload: any) {
    const data = acquireToken().then((tokenResponse) => {
      const optionsVal = {
        method: 'POST',
        url: `api/Common/UpdateComitieActivity`,
        data: payload,
        headers: {
          Authorization: `Bearer ${tokenResponse?.accessToken}`,
        },
      };
      const apiResponse = axiosInstance(optionsVal);
      return apiResponse;
    });
    return data;
  },

  getCommitteeSummaryPerStatus: async function (committeeId: string) {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeSummaryPerStatus(committeeId, currentLanguageCode));
  },

  getCommitteeSummaryPerTime: async function (committeeId: string) {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeSummaryPerTime(committeeId, currentLanguageCode));
  },

  getCalendarData: async function (committeeId: string, month: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetAgendaCalendar(committeeId, month));
  },

  getComitieProjectsAndSubProjetcst: async function (committeeId: string, sessionId: string, xmatrixId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeAvailableProjects(committeeId, sessionId, xmatrixId));
  },

  deleteAgenda: async function (agendaId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonDeleteAgenda(agendaId));
  },

  sendAgendaByMail: async function (comitteeId: string, sessionId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonSendEmail(comitteeId, sessionId));
  },

  updateComitieComment: async function (payload: UpdateCommitteeCommentRequestDto) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonUpdateCommitteeComment(payload));
  },

  getSintesiListData: async function (filters: GetCommitteeSintesiListRequest) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteeSintesiList(filters));
  },

  deleteSession: async function (sessionId: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonDeleteCommitteeSession(sessionId));
  },
};
export default CommitieService;

import { Form } from 'antd';
import React from 'react';
import '../../deliverables/deliverable.scss';

import { InfoCircleOutlined } from '@ant-design/icons';
import { Card, Col, Modal, Popover, Row, Select } from 'antd';
import CommonService from '../../../services/commonService';
import { ModalFooterButtons } from '../../shared/components/modal/modalFooterButtons';
import { requiredFields } from '../../shared/utils/notifyMessages';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddKpiObAnnoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      loadingButton: false,
      kpiList: null,
      kpiData: null,
    };
  }

  componentDidMount() {
    this.retrieveKpiList();
  }

  // Retrieve KPI list
  async retrieveKpiList() {
    const { annualGoalID } = this.props;
    await CommonService.getKpiAnualGoalPicklist(annualGoalID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ kpiList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  addKpiData = (values, annualGoalID) => {
    this.props.handelAddKpiModalSave(values, annualGoalID);
  };

  kpiInfo = (item) => {
    const { t } = this.props;
    return (
      <div>
        <p>
          {' '}
          <b>{t('general.tipo')}: </b> {item.globaletype}
        </p>
        <p>
          {' '}
          <b>{t('kpiPage.cumulatoPuntuale')}: </b> {item.puntualetype}
        </p>
      </div>
    );
  };

  render() {
    const { showAddKpiModal, handelAddKpiModalClose, loadingButton, annualGoalID, t } = this.props;
    const { kpiList } = this.state;

    let kpiSelectDataList = [];

    if (kpiList) {
      kpiList.forEach((item) => {
        const value = item.kpiDetailID;
        kpiSelectDataList.push({
          value,
          key: item.kpiDetailID,
          kpidetailid: item.kpiDetailID,
          label: item.name,
          name: item.name,
          globaletype: item.globaleType,
          puntualetype: item.puntualeType,
        });
      });
    }

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('obiettiviAnnoPage.aggiungiNuovoKpi')}
        open={showAddKpiModal}
        destroyOnClose={true}
        onCancel={handelAddKpiModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handelAddKpiModalClose}
            formId={'newObAnnoKpi'}
          />,
        ]}>
        <Card>
          <Form
            id="newObAnnoKpi"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.addKpiData(values, annualGoalID)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  label={t('obiettiviAnnoPage.listaKpi')}
                  name="KPIDetailID"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}>
                  <Select
                    showSearch
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0}
                    // onChange={(value, option) => this.onKpiSelect(value, option)}
                  >
                    {kpiSelectDataList &&
                      kpiSelectDataList.map((item) => (
                        <Option
                          value={item.kpidetailid}
                          key={item.kpidetailid}>
                          {item.name}
                          <Popover
                            key={item.kpidetailid}
                            content={this.kpiInfo(item)}
                            title="KPI Info">
                            <InfoCircleOutlined style={{ fontSize: 12, color: '#1f85e4', marginLeft: '15px' }} />
                          </Popover>
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default AddKpiObAnnoModal;

import { Form } from 'antd';
import { Component } from 'react';

import { DeleteOutlined, FormOutlined, SaveOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Divider,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Switch,
} from 'antd';
import BraftEditor from 'braft-editor';
import Parser from 'html-react-parser';
import moment from 'moment';
import { connect } from 'react-redux';
import CommonService from '../../../../services/commonService';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import Loader from '../../../shared/components/loader/loader';
import Feed from '../../../shared/feed/feed';
import {
  feedObjectType,
  feedRegardingObjectType,
  projectStatus,
  statusPickListCodes,
} from '../../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import ProjectObjectives from '../projectObjectives/projectObjectives';
import ProjectRoutine from './projectRoutine/projectRoutine';
import EditorModal from './richTextEditorModal';
import ProjectTeamMembers from './teamMembers/teamMembers';
// import ProjectFeed from "../mainInfo/feed/feed";
import { withTranslation } from 'react-i18next';
import DashboardService from '../../../../services/pages/dashboardService';
import CustomPageHeader from '../../../shared/components/customPageHeader/CustomPageHeader';
import UserSelectDropDown from '../../../shared/components/userSelect/userSelect';
import { userIsAdminOrProjectTeam } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { areDatesValid, showErrorNotification, updateNotificationBell } from '../../../shared/utils/functions';

let createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 9,
  },
  wrapperCol: {
    span: 15,
  },
};

export const editFieldObjectData = (fieldKey, fieldValue, t) => {
  let objData = {};
  if (fieldKey === 'description') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('general.descrizione')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'projectGoal') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('proggetiPage.risultatoAtteso')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'groundtoAct') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('proggetiPage.motivoAgire')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'obtainedBenefit') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('proggetiPage.beneficiOtenuti')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'hanseiLearnedLessons') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('proggetiPage.henseiLezioniImparate')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'finishedActivitiesNote') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('proggetiPage.commentoAttivitaSvolte')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else if (fieldKey === 'nextStepsNote') {
    objData['fieldKey'] = fieldKey;
    objData['fieldName'] = `${t('proggetiPage.commentoProssimiPassi')}`;
    objData['fieldValue'] = fieldValue;
    return objData;
  } else {
    return null;
  }
};

class ProjectForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDataLoading: false,
      loadingButton: false,
      loadingButtonDelete: false,
      loadingEditorButton: false,
      editorModal: false,
      commitiePickList: null,
      projectPickList: null,
      divisionsList: null,
      statusList: null,
      fieldObject: null,
      teamMembers: null,
      showNewFeedModal: false,
      currentProject: props.currentProject,
      projectFieldsChanged: false,
      selectedParentProjectData: null,
      isMandatory: false,
    };
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //     if (this.props.currentProject !== nextProps.currentProject) {
  //       this.setState({
  //         currentProject:
  //           nextProps.currentProject > this.props.currentProject,
  //       });
  //     }
  //   }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.currentProject !== nextProps.currentProject) {
      this.setState({
        currentProject: nextProps.currentProject,
      });
    }
  }

  componentDidMount() {
    const { projectId, currentProject } = this.props;
    //this.state.currentProject = projectData;
    this.retrieveCommities();
    this.retrieveStatus();
    this.getTeamMembers(projectId);
    this.retrieveProjectList(projectId);
    this.retrieveDivisions();
    if (currentProject && currentProject.parentProjectID) {
      this.getParentProjectData(currentProject.parentProjectID);
    }
  }

  async retrieveCommities() {
    await CommonService.getCommitiePicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ commitiePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.statusProjects;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveProjectList(projectId) {
    await ProgettiService.getParentProjects(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projectPickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve list of divisions
  async retrieveDivisions() {
    await DashboardService.getDivisionFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ divisionsList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  handleDateChange(date) {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  updateProjectForm = (values, currentProject) => {
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);
    values['projectID'] = currentProject.projectID;
    values['description'] = currentProject.description;
    values['projectGoal'] = currentProject.projectGoal;
    values['groundtoAct'] = currentProject.groundtoAct;
    values['obtainedBenefit'] = currentProject.obtainedBenefit;
    values['hanseiLearnedLessons'] = currentProject.hanseiLearnedLessons;
    values['finishedActivitiesNote'] = currentProject.finishedActivitiesNote;
    values['nextStepsNote'] = currentProject.nextStepsNote;
    values['fastCreation'] = currentProject.fastCreation;

    if (values.status !== projectStatus.draft && currentProject.fastCreation == true) {
      values['fastCreation'] = false;
    }

    this.setState({ loadingButton: true });
    ProgettiService.updateProject(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          updateNotificationBell();
          this.setState({ loadingButton: false });
          //this.props.reloadProject(currentProject.projectID);
          this.getTeamMembers(currentProject.projectID);
          // window.location.reload();
          window.location = `/progetti/id/${currentProject.projectID}`;
          this.setState({ currentProject: this.props.currentProject });
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  getTeamMembers(id) {
    this.setState({ isDataLoading: true });
    ProgettiService.getProjectTeamMembers(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ teamMembers: resp.responseObject.value });
          this.setState({ isDataLoading: false });
        } else {
          this.setState({ isDataLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isDataLoading: false });
      });
  }

  toggleEditorModal = (fieldData) => {
    this.setState({ editorModal: !this.state.editorModal });
    this.setState({ fieldObject: fieldData });
  };

  handelEditorClose = () => {
    this.setState({ editorModal: false });
  };

  updateFieldToObject = (values) => {
    // this.setState({ projectFieldsChanged: true })
    var fieldName = Object.keys(values);
    let editorContentToHtml = BraftEditor.createEditorState(values[fieldName]).toHTML();
    this.setState({ currentProject: { ...this.state.currentProject, [fieldName]: editorContentToHtml } });
    // this.handelEditorClose();
    this.updateProjectEditorFields(values, fieldName, editorContentToHtml);
  };

  updateProjectEditorFields = (values, fieldName, editorContentToHtml) => {
    const { currentProject } = this.state;
    this.setState({ loadingEditorButton: true });
    let payloadObj = {};
    payloadObj['projectId'] = currentProject.projectID;
    payloadObj['projectFieldName'] = fieldName[0];
    payloadObj['projectFieldValue'] = editorContentToHtml;

    ProgettiService.updateEditorField(payloadObj)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          // message.success(notifyMessages.updateSuccess);
          this.setState({ loadingEditorButton: false });
          this.handelEditorClose();
        } else {
          // message.error(notifyMessages.updateFailed);
          this.setState({ loadingEditorButton: false });
        }
      })
      .catch((error) => {
        // message.error(notifyMessages.updateFailed);
        this.setState({ loadingEditorButton: false });
      });
  };

  backFunction = () => {
    history.goBack();
  };

  getParentProjectData = (id) => {
    ProgettiService.getParentProjectByID(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedParentProjectData: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  removeProject = (projectId) => {
    this.setState({ loadingButtonDelete: true });
    ProgettiService.deleteProject(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentProject: false });
          this.setState({ loadingButtonDelete: false });
          message.success(notifyMessages.deleteSuccess);
          window.location.replace('/progetti');
        } else {
          message.error(notifyMessages.deleteFailed);
          this.setState({ loadingButtonDelete: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
        this.setState({ loadingButtonDelete: false });
      });
  };

  disabledDate(current, startDate, dateType) {
    const { selectedParentProjectData, currentProject } = this.state;
    let curr = moment(current).endOf('day');
    let date = moment(startDate).endOf('day');
    if (dateType === 'start') {
      if (selectedParentProjectData) {
        let currProject = moment(current).endOf('day');
        let startDate = moment(selectedParentProjectData.startDate).endOf('day');
        let endDate = moment(selectedParentProjectData.endDate).endOf('day');
        return currProject < startDate || currProject > endDate;
      }
      // else {
      //     return (curr < date)
      // }
    } else {
      if (selectedParentProjectData) {
        let currProject = moment(current).endOf('day');
        let startDate = moment(selectedParentProjectData.startDate).endOf('day');
        let endDate = moment(selectedParentProjectData.endDate).endOf('day');
        return currProject < startDate || currProject > endDate;
      } else {
        let currentProjectDate = moment(current).endOf('day');
        let startDate = moment(currentProject.parentProjectStartDate).endOf('day');
        let endDate = moment(currentProject.parentProjectEndDate).endOf('day');
        return currentProjectDate < startDate || currentProjectDate > endDate;
      }
    }
  }

  onParentProjectClear = () => {
    this.setState({ selectedParentProjectData: null });
  };

  onParentProjectChange = (option) => {
    this.getParentProjectData(option);
  };

  checkProjectEndDateValidity = (endDate) => {
    if (endDate) {
      let currentDate = moment().endOf('day');
      let projectEndDate = moment(endDate).endOf('day');

      if (projectEndDate < currentDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  onStatusChange = (value, option) => {
    if (value === projectStatus.attivo) {
      this.setState({ isMandatory: true });
    } else {
      this.setState({ isMandatory: false });
    }
  };

  checkValidityBeforeUpdate = (values, currentProject) => {
    const { t } = this.props;
    if (areDatesValid(values.startDate, values.endDate)) {
      this.updateProjectForm(values, currentProject);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  render() {
    const {
      projectFieldsChanged,
      projectPickList,
      usersPickList,
      divisionsList,
      commitiePickList,
      statusList,
      editorModal,
      teamMembers,
      loadingButton,
      isDataLoading,
      currentProject,
      isMandatory,
      loadingEditorButton,
    } = this.state;
    const { userData, hasUserAccess, t } = this.props;

    return (
      <>
        <Loader />

        {currentProject && (
          <>
            <EditorModal
              show={editorModal}
              // handelEditorClose={this.updateFieldToObject}
              // handelEditorSave={this.updateFieldToObject}

              handelEditorClose={this.handelEditorClose}
              handelEditorSave={this.updateFieldToObject}
              data={this.state.fieldObject}
              loadingEditorButton={loadingEditorButton}
            />

            <Card
              className="project-card-wrapper"
              style={{ padding: 10 }}>
              <Form
                {...formItemLayout}
                name="project_details_formData"
                // onFinish={this.checkDatesValidity}
                // onFinish={(values) => this.updateProjectForm(values, currentProject)}
                onFinish={(values) => this.checkValidityBeforeUpdate(values, currentProject)}
                onValuesChange={() => {
                  this.setState({ projectFieldsChanged: true });
                }}>
                <Row gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row mb10"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 16 }}>
                    <div className="back-container">
                      {/* <Button
                                                type="dashed"
                                                onClick={this.backFunction}
                                                className="backButton"
                                            >
                                                <ArrowLeftOutlined  />{t('buttons.tornaindietro')}</Button> */}

                      {/* < GoBackButton t={t} /> */}
                      <span className="back-details">{t('proggetiPage.dettagliProgetto')}</span>
                    </div>
                  </Col>
                  <Col
                    className="gutter-row mb10"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 8 }}>
                    <CustomPageHeader
                      className="site-page-header"
                      extra={[
                        <div key="buttons">
                          {!hasUserAccess && (
                            <Form.Item wrapperCol={{ span: 24 }}>
                              <Button
                                className="project-button save"
                                loading={loadingButton}
                                icon={<SaveOutlined />}
                                type="primary"
                                htmlType="submit"
                                key="add"
                                disabled={!projectFieldsChanged}>
                                {t('buttons.salva')}
                              </Button>
                              <Popconfirm
                                placement="topRight"
                                className={
                                  'project-button ' + (currentProject.status === projectStatus.draft) ? 'save' : ''
                                }
                                title={t('proggetiPage.eliminaProgetto')}
                                onConfirm={() => this.removeProject(currentProject.projectID)}
                                okText={t('general.si')}
                                cancelText={t('general.no')}
                                disabled={currentProject.status === projectStatus.draft ? false : true}>
                                <Button
                                  className="project-button save"
                                  style={{ marginLeft: 10 }}
                                  key="delete"
                                  disabled={currentProject.status === projectStatus.draft ? false : true}>
                                  {' '}
                                  <DeleteOutlined /> {t('buttons.elimina')}
                                </Button>
                              </Popconfirm>
                            </Form.Item>
                          )}
                        </div>,
                      ]}></CustomPageHeader>
                  </Col>
                </Row>

                <Row
                  className="mb-row"
                  gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row tw-mb-4"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <Card
                      className="sub150-card-body card-body-bacground"
                      title={t('proggetiPage.motivoAgire')}
                      extra={
                        !hasUserAccess ? (
                          <FormOutlined
                            onClick={() =>
                              this.toggleEditorModal(
                                this.editFieldObjectData('groundtoAct', currentProject.groundtoAct),
                              )
                            }
                          />
                        ) : (
                          ''
                        )
                      }>
                      {Parser(currentProject.groundtoAct ? currentProject.groundtoAct : '')}
                    </Card>
                  </Col>

                  <Col
                    className="gutter-row tw-mb-4"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <Card
                      className="sub150-card-body card-body-bacground"
                      title={t('proggetiPage.risultatoAtteso')}
                      extra={
                        !hasUserAccess ? (
                          <FormOutlined
                            onClick={() =>
                              this.toggleEditorModal(
                                this.editFieldObjectData('projectGoal', currentProject.projectGoal),
                              )
                            }
                          />
                        ) : (
                          ''
                        )
                      }>
                      {Parser(currentProject.projectGoal ? currentProject.projectGoal : '')}
                    </Card>
                  </Col>

                  <Col
                    className="gutter-row tw-mb-4"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <Card
                      className="sub150-card-body card-body-bacground"
                      title={t('general.descrizione')}
                      extra={
                        !hasUserAccess ? (
                          <FormOutlined
                            onClick={() =>
                              this.toggleEditorModal(
                                this.editFieldObjectData('description', currentProject.description),
                              )
                            }
                          />
                        ) : (
                          ''
                        )
                      }>
                      {Parser(currentProject.description ? currentProject.description : '')}
                    </Card>
                  </Col>
                </Row>

                <Row
                  className="mb-row"
                  gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row tw-mb-4"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <Card
                      className="sub600-card-body"
                      title={t('buttons.dettagli')}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}
                        style={{
                          textAlign: 'left',
                          color: '#023B72',
                          fontSize: '19px',
                          fontWeight: '500',
                          padding: '0',
                        }}>
                        <Space direction="horizontal">
                          <span>{t('general.xmatrix')}: </span>
                          <span>{currentProject.xMatrixName}</span>
                        </Space>
                      </Col>
                      <Row style={{ marginTop: '20px' }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            className="main-container"
                            labelAlign="top"
                            label={t('proggetiPage.nomeProgetto')}
                            name="name"
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={currentProject.name}>
                            <Input
                              disabled={
                                hasUserAccess ||
                                !userIsAdminOrProjectTeam(
                                  userData,
                                  currentProject.teamLeaderID,
                                  currentProject.sponsorID,
                                )
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            className="main-container"
                            labelAlign="top"
                            label={t('proggetiPage.progettoPrincipale')}
                            name="parentProjectID"
                            initialValue={currentProject.parentProjectID}
                            style={{ margin: '0 0 -10 0' }}>
                            <Select
                              disabled={
                                hasUserAccess ||
                                !userIsAdminOrProjectTeam(
                                  userData,
                                  currentProject.teamLeaderID,
                                  currentProject.sponsorID,
                                )
                              }
                              allowClear
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              size="small"
                              placeholder={t('general.seleziona')}
                              style={{ width: '100%' }}
                              onSelect={(option) => this.onParentProjectChange(option)}
                              onClear={() => this.onParentProjectClear()}>
                              {projectPickList &&
                                projectPickList.map((item) => (
                                  <Option
                                    value={item.projectID}
                                    key={item.projectID}>
                                    {item.name} ({item.projectCode})
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <Form.Item
                            className="main-container projectCode"
                            extra={t('proggetiPage.codiceLungeza')}
                            labelAlign="top"
                            label={t('proggetiPage.codiceProgetto')}
                            name="projectCode"
                            rules={[
                              { required: true, message: requiredFields.required },
                              {
                                max: 10,
                                message: `${t('proggetiPage.codiceLungeza')}`,
                              },
                            ]}
                            initialValue={currentProject.projectCode}>
                            <Input
                              maxLength={10}
                              disabled={
                                hasUserAccess ||
                                !userIsAdminOrProjectTeam(
                                  userData,
                                  currentProject.teamLeaderID,
                                  currentProject.sponsorID,
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '20px' }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <UserSelectDropDown
                            disabled={
                              hasUserAccess ||
                              !userIsAdminOrProjectTeam(
                                userData,
                                currentProject.teamLeaderID,
                                currentProject.sponsorID,
                              ) ||
                              this.checkProjectEndDateValidity(currentProject.endDate)
                            }
                            allowClear={false}
                            selectSize={'small'}
                            fieldLabel={t('general.teamLeader')}
                            t={t}
                            formName={'teamLeaderID'}
                            required={
                              isMandatory ? true : currentProject.status === projectStatus.attivo ? true : false
                            }
                            initValue={currentProject.teamLeaderID}
                          />

                          {/* <Form.Item className='main-container'
                                                        labelAlign="top"
                                                        label={t('general.teamLeader')}
                                                        name="teamLeaderID"
                                                        hasFeedback
                                                        // rules={[{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                                                        rules={isMandatory ? [{ required: true, message: requiredFields.required }] : [{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                                                        initialValue={currentProject.teamLeaderID}
                                                    >
                                                        <Select
                                                            disabled={
                                                                hasUserAccess
                                                                || !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                                                                || this.checkProjectEndDateValidity(currentProject.endDate)
                                                            }
                                                            showSearch
                                                            placeholder={t('general.seleziona')}
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{ width: "100%" }}
                                                        >
                                                            {usersPickList && usersPickList.map(item =>
                                                                <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                                            )}
                                                        </Select>
                                                    </Form.Item> */}
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <UserSelectDropDown
                            disabled={
                              hasUserAccess ||
                              !userIsAdminOrProjectTeam(
                                userData,
                                currentProject.teamLeaderID,
                                currentProject.sponsorID,
                              ) ||
                              this.checkProjectEndDateValidity(currentProject.endDate)
                            }
                            allowClear={false}
                            selectSize={'small'}
                            fieldLabel={t('general.sponsor')}
                            t={t}
                            formName={'sponsorID'}
                            required={
                              isMandatory ? true : currentProject.status === projectStatus.attivo ? true : false
                            }
                            initValue={currentProject.sponsorID}
                          />

                          {/* <Form.Item className='main-container'
                                                        labelAlign="top"
                                                        label={t('general.sponsor')}
                                                        name="sponsorID"
                                                        hasFeedback
                                                        // rules={[{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                                                        rules={isMandatory ? [{ required: true, message: requiredFields.required }] : [{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                                                        initialValue={currentProject.sponsorID}
                                                    >
                                                        <Select
                                                            disabled={
                                                                hasUserAccess
                                                                || !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                                                                || this.checkProjectEndDateValidity(currentProject.endDate)
                                                            }
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            style={{ width: "100%" }}
                                                            placeholder={t('general.seleziona')}
                                                        >
                                                            {usersPickList && usersPickList.map(item =>
                                                                <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                                            )}
                                                        </Select>
                                                    </Form.Item> */}
                        </Col>
                      </Row>

                      <Row style={{ marginTop: '20px' }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            className="main-container"
                            labelAlign="top"
                            label={t('general.comitato')}
                            name="committeeID"
                            hasFeedback
                            // rules={[{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                            // rules={isMandatory ? [{ required: true, message: requiredFields.required }] : [{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                            initialValue={currentProject.committeeID}>
                            <Select
                              disabled={
                                hasUserAccess ||
                                !userIsAdminOrProjectTeam(
                                  userData,
                                  currentProject.teamLeaderID,
                                  currentProject.sponsorID,
                                )
                              }
                              placeholder={t('general.seleziona')}
                              showSearch
                              allowClear
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }>
                              {commitiePickList &&
                                commitiePickList.map((item) => (
                                  <Option
                                    disabled={item.hasAccess === 1 ? false : true}
                                    value={item.committeeID}
                                    key={item.committeeID}>
                                    {item.name}{' '}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            className="main-container"
                            labelAlign="top"
                            label={t('general.divisione')}
                            name="divisionID"
                            hasFeedback
                            initialValue={currentProject.divisionID}>
                            <Select
                              disabled={
                                hasUserAccess ||
                                !userIsAdminOrProjectTeam(
                                  userData,
                                  currentProject.teamLeaderID,
                                  currentProject.sponsorID,
                                ) ||
                                currentProject.secondaryProject
                              }
                              allowClear
                              placeholder={t('general.seleziona')}
                              showSearch
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }>
                              {divisionsList &&
                                divisionsList.map((item) => (
                                  <Option
                                    disabled={item.hasAccess === 1 ? false : true}
                                    value={item.divisionID}
                                    key={item.divisionID}>
                                    {item.divisionName}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '20px' }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            className="main-container"
                            labelAlign="top"
                            label={t('proggetiPage.revisioneEsterna')}
                            name="hasExternalReview"
                            valuePropName="checked"
                            initialValue={currentProject.hasExternalReview}>
                            <Switch
                              disabled={
                                hasUserAccess ||
                                !userIsAdminOrProjectTeam(
                                  userData,
                                  currentProject.teamLeaderID,
                                  currentProject.sponsorID,
                                )
                              }
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            className="main-container"
                            labelAlign="top"
                            label={t('general.budget')}
                            name="budget"
                            initialValue={currentProject.budget}>
                            <InputNumber
                              min={0}
                              disabled={
                                hasUserAccess ||
                                !userIsAdminOrProjectTeam(
                                  userData,
                                  currentProject.teamLeaderID,
                                  currentProject.sponsorID,
                                )
                              }
                            />
                          </Form.Item>
                        </Col>
                      </Row>

                      <Divider style={{ margin: '20px 0px' }} />
                      <Row style={{ marginTop: '20px' }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 8 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}>
                          <Form.Item
                            className="main-container"
                            labelAlign="top"
                            label={t('general.start')}
                            name="startDate"
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={
                              currentProject.startDate ? moment(currentProject.startDate) : currentProject.startDate
                            }>
                            {/* <DatePicker disabled={currentProject.fastCreation == true ? false : true } style={{ width: "100%" }} format="DD/MM/YYYY" disabledDate={(current) => this.disabledDate(current, currentProject.startDate, "start")} /> */}
                            <DatePicker
                              disabled={currentProject.status === projectStatus.draft ? false : true}
                              style={{ width: '100%' }}
                              format="DD/MM/YYYY"
                              disabledDate={(current) => this.disabledDate(current, currentProject.startDate, 'start')}
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 8 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}>
                          <Form.Item
                            className="main-container"
                            labelAlign="top"
                            label={t('general.end')}
                            name="endDate"
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={
                              currentProject.endDate ? moment(currentProject.endDate) : currentProject.endDate
                            }>
                            <DatePicker
                              disabled={
                                hasUserAccess ||
                                !userIsAdminOrProjectTeam(
                                  userData,
                                  currentProject.teamLeaderID,
                                  currentProject.sponsorID,
                                )
                              }
                              style={{ width: '100%' }}
                              format="DD/MM/YYYY"
                              disabledDate={(current) => this.disabledDate(current, currentProject.endDate, 'end')}
                            />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 8 }}
                          md={{ span: 8 }}
                          lg={{ span: 8 }}>
                          <Form.Item
                            className="main-container"
                            labelAlign="top"
                            label={t('general.stato')}
                            name="status"
                            hasFeedback
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={currentProject.status}>
                            <Select
                              disabled={
                                hasUserAccess ||
                                !userIsAdminOrProjectTeam(
                                  userData,
                                  currentProject.teamLeaderID,
                                  currentProject.sponsorID,
                                )
                              }
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}
                              onChange={(value, option) => this.onStatusChange(value, option)}>
                              {statusList &&
                                statusList.map((item) => (
                                  <Option
                                    value={item.statusID}
                                    key={item.statusID}>
                                    {item.statusDescription}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>

                    <Col
                      className="gutter-row tw-mb-4"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Card
                        style={{ marginBottom: 10, marginTop: 10 }}
                        className="sub100-card-body card-body-bacground sub100-card-height"
                        title={t('proggetiPage.beneficiOtenuti')}
                        extra={
                          !hasUserAccess ? (
                            <FormOutlined
                              onClick={() =>
                                this.toggleEditorModal(
                                  this.editFieldObjectData('obtainedBenefit', currentProject.obtainedBenefit),
                                )
                              }
                            />
                          ) : (
                            ''
                          )
                        }>
                        {Parser(currentProject.obtainedBenefit ? currentProject.obtainedBenefit : '')}
                      </Card>
                    </Col>
                  </Col>

                  <Col
                    className="gutter-row tw-mb-4"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <Feed
                      objectId={currentProject.projectID}
                      userData={userData}
                      objectType={feedObjectType.projectFeed}
                      isProject={1}
                      hasUserAccess={hasUserAccess}
                      feedRegardingObjType={feedRegardingObjectType.project}
                    />
                  </Col>

                  <Col
                    className="gutter-row tw-mb-4"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <Col
                      className="gutter-row tw-mb-4"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <ProjectTeamMembers
                        t={t}
                        projectId={currentProject.projectID}
                        teamMembers={teamMembers}
                        reloadUsers={() => this.getTeamMembers(currentProject.projectID)}
                        isDataLoading={isDataLoading}
                        hasUserAccess={hasUserAccess}
                        isAdminOrProjectTeam={
                          !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                        }
                        checkProjectEndDateValidity={this.checkProjectEndDateValidity(currentProject.endDate)}
                      />
                    </Col>

                    <Col
                      className="gutter-row tw-mb-4"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <ProjectRoutine
                        t={t}
                        projectId={currentProject.projectID}
                        endDateProject={currentProject.endDate}
                        startDateProject={currentProject.startDate}
                        hasUserAccess={hasUserAccess}
                        isAdminOrProjectTeam={
                          !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                        }
                      />
                    </Col>

                    <Col
                      className="gutter-row tw-mb-4"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Card
                        style={{ marginBottom: 10, marginTop: 10 }}
                        className="sub100-card-body card-body-bacground"
                        title={t('proggetiPage.henseiLezioniImparate')}
                        extra={
                          !hasUserAccess ? (
                            <FormOutlined
                              onClick={() =>
                                this.toggleEditorModal(
                                  this.editFieldObjectData('hanseiLearnedLessons', currentProject.hanseiLearnedLessons),
                                )
                              }
                            />
                          ) : (
                            ''
                          )
                        }>
                        {Parser(currentProject.hanseiLearnedLessons ? currentProject.hanseiLearnedLessons : '')}
                      </Card>
                    </Col>
                  </Col>
                </Row>

                <Row
                  className="mb-row"
                  gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row tw-mb-4"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <Card
                      className="sub244-card-body card-body-bacground"
                      title={t('proggetiPage.commentoAttivitaSvolte')}
                      extra={
                        !hasUserAccess ? (
                          <FormOutlined
                            onClick={() =>
                              this.toggleEditorModal(
                                this.editFieldObjectData(
                                  'finishedActivitiesNote',
                                  currentProject.finishedActivitiesNote,
                                ),
                              )
                            }
                          />
                        ) : (
                          ''
                        )
                      }>
                      {Parser(currentProject.finishedActivitiesNote ? currentProject.finishedActivitiesNote : '')}
                    </Card>
                  </Col>

                  <Col
                    className="gutter-row tw-mb-4"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <Card
                      className="sub244-card-body card-body-bacground"
                      title={t('proggetiPage.commentoProssimiPassi')}
                      extra={
                        !hasUserAccess ? (
                          <FormOutlined
                            onClick={() =>
                              this.toggleEditorModal(
                                this.editFieldObjectData('nextStepsNote', currentProject.nextStepsNote),
                              )
                            }
                          />
                        ) : (
                          ''
                        )
                      }>
                      {Parser(currentProject.nextStepsNote ? currentProject.nextStepsNote : '')}
                    </Card>
                  </Col>

                  <Col
                    className="gutter-row tw-mb-4 project-objectives"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 8 }}
                    lg={{ span: 8 }}>
                    <ProjectObjectives
                      t={t}
                      projectId={currentProject.projectID}
                    />
                  </Col>
                </Row>
              </Form>
            </Card>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ProjectForm));

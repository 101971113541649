import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import CompanyService from '../../services/pages/companyService';
import { AlertConfigurationResponseDto, CompanyResponseDto } from 'src/connectors/backend';

interface CompanyState {
  loading: boolean;
  companyData: CompanyResponseDto | null;
  companyNotificationData: AlertConfigurationResponseDto | null;
  error: string | null;
}

const initialState: CompanyState = {
  loading: false,
  companyData: null,
  companyNotificationData: null,
  error: null,
};

export const retrieveCompanyData = createAsyncThunk('company/retrieveCompanyData', async (_, { rejectWithValue }) => {
  try {
    const response = await CompanyService.getCompanyData();
    const { success, responseObject } = response.data;

    if (!success) {
      throw new Error('Failed to fetch company notifications');
    }

    return responseObject?.value;
  } catch (error: any) {
    return rejectWithValue(error.message || 'Unknown error');
  }
});

export const retrieveCompanyNotifications = createAsyncThunk(
  'company/retrieveCompanyNotifications',
  async (lastLoggedInCompanyID: string, { rejectWithValue }) => {
    try {
      const response = await CompanyService.getCompanyNotifications(lastLoggedInCompanyID);
      const { success, responseObject } = response.data;

      if (!success) {
        throw new Error('Failed to fetch company notifications');
      }

      return responseObject?.value;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  },
);

const companySlice = createSlice({
  name: 'company',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveCompanyData.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(retrieveCompanyData.fulfilled, (state, action) => {
        state.loading = false;
        state.companyData = action.payload ?? null;
      })
      .addCase(retrieveCompanyData.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || 'Unknown error'; // Ensure error is a string
      })
      .addCase(retrieveCompanyNotifications.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(retrieveCompanyNotifications.fulfilled, (state, action) => {
        state.loading = false;
        state.companyNotificationData = action.payload ?? null;
      })
      .addCase(retrieveCompanyNotifications.rejected, (state, action) => {
        state.loading = false;
        state.error = (action.payload as string) || 'Unknown error'; // Ensure error is a string
      });
  },
});

export default companySlice.reducer;

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { Component } from 'react';
import PersonalDashboardService from '../../../services/pages/personalDashboardService';
import Loader2 from '../../shared/components/loader2/loader2';
import { perTimeDeliverableChartOptions } from '../chartsConfig';
import { withTranslation } from 'react-i18next';

class PerTimeDeliverableChart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      perTimeChart: perTimeDeliverableChartOptions,
    };
  }

  // Init of component after rendering
  componentDidMount() {
    this.getPerTimeChartData();
  }

  async getPerTimeChartData() {
    let { t } = this.props;

    this.setState({ loadingData: true });
    await PersonalDashboardService.getDeliverablePerTimeChartData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let seriesObj = {};
          let dataObj = [];
          seriesObj['name'] = `${t('bachechaPersonalePage.deliverable')}`;
          resp.responseObject.value.map((item, index) =>
            dataObj.push({ name: item.deliverableStatusDescription, y: item.deliverableCount }),
          );
          seriesObj['data'] = dataObj;
          this.setState({ perTimeChart: { ...this.state.perTimeChart, series: seriesObj } });
          this.setState({ loadingData: false });
        } else {
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingData: false });
      });
  }

  render() {
    const { loadingData, perTimeChart } = this.state;

    return (
      <>
        {loadingData && <Loader2 />}
        {perTimeChart && (
          <HighchartsReact
            highcharts={Highcharts}
            options={perTimeChart}
          />
        )}
        {!perTimeChart && <p>No data</p>}
      </>
    );
  }
}

export default withTranslation()(PerTimeDeliverableChart);

import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi, ActivityApi, InsertMyActivityRequestDto } from 'src/config/connectors';
import i18n from 'src/i18n';

const minDate = '1900-01-01';
const maxDate = '3000-01-01';

const ActivityService = {
  // get project activities
  getProjectActivities: async function (
    projectId: string,
    startDate: string,
    endDate: string,
    deliverableId: string | null,
  ) {
    const api = await authenticatedApi(ActivityApi);
    return trackPromise(
      api.activityGetMyActivityProjectForm(
        projectId,
        startDate || 'null',
        endDate || 'null',
        i18n.language,
        deliverableId || 'null',
      ),
    );
  },

  // get my activities
  getMyActivities: async function (startDate: string, endDate: string) {
    const api = await authenticatedApi(ActivityApi);
    return trackPromise(api.activityGetMyActivityMenuForm(startDate || minDate, endDate || maxDate, i18n.language));
  },

  // get team leader activities
  getTeamLeaderActivities: async function (startDate: string, endDate: string) {
    const api = await authenticatedApi(ActivityApi);
    return trackPromise(
      api.activityGetTeamLeaderActivityMenuForm(startDate || minDate, endDate || maxDate, i18n.language),
    );
  },

  // get deliverable activities
  getDeliverableActivities: async function (deliverableId: string, startDate: string, endDate: string) {
    const api = await authenticatedApi(ActivityApi);
    return trackPromise(
      api.activityGetMyActivityDeliverableForm(deliverableId, startDate || minDate, endDate || maxDate, i18n.language),
    );
  },

  // update activity
  updateActivity: async function (payload: InsertMyActivityRequestDto) {
    const api = await authenticatedApi(ActivityApi);
    return trackPromise(api.activityUpdateMyActivity(payload));
  },

  // insert new activity
  insertActivity: async function (payload: InsertMyActivityRequestDto) {
    const api = await authenticatedApi(ActivityApi);
    return trackPromise(api.activityInsertMyActivity(payload));
  },

  // remove activity
  removeActivity: async function (activityId: string) {
    const api = await authenticatedApi(ActivityApi);
    return trackPromise(api.activityDeleteMyActivity(activityId));
  },
};

export default ActivityService;

import { Form } from 'antd';
import React from 'react';
import '../../project.scss';

import { Card, Col, Input, Modal, Row, Select } from 'antd';
import CommonService from '../../../../services/commonService';
import DeliverableService from '../../../../services/pages/projectServices/deliverableServices';
import { ModalFooterButtons } from '../../../shared/components/modal/modalFooterButtons';
import { objectCodes } from '../../../shared/utils/constants';
import { requiredFields } from '../../../shared/utils/notifyMessages';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddProjectCost extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      projectCostDetails: null,
      projectTeamPickList: [],
      deliverablePickList: null,
      costTypeList: null,
      selectedDeliverables: [],
    };
  }

  componentDidMount() {
    const { projectId } = this.props;
    if (projectId) {
      this.retrieveDeliverablePickList(projectId);
      this.retrieveProjectMembers(projectId);
    }
    this.retrieveProjectCostTypes();
  }

  async retrieveDeliverablePickList(projectId) {
    await DeliverableService.getDeliverableProjectPicklist(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ deliverablePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveProjectMembers(projectId) {
    await CommonService.getResponsibleUserList(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          this.setState({ projectTeamPickList: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveProjectCostTypes() {
    const objectCode = objectCodes.projectCostTypes;
    await CommonService.getProjectCostType(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ costTypeList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async addNewProjoectCost(values) {
    this.props.handelSaveNewDeatils(values);
  }

  render() {
    const { newProjectCostDetailsModal, handelNewDetailModalClose, loadingSaveButton, projectTeamLeader, t } =
      this.props;
    const { projectTeamPickList, deliverablePickList, costTypeList } = this.state;

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('proggetiPage.aggiungiNuovoCostoProgetto')}
        open={newProjectCostDetailsModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handelNewDetailModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingSaveButton}
            modalClose={handelNewDetailModalClose}
            formId={'newCostForm'}
          />,
        ]}>
        <Card>
          <Form
            id="newCostForm"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.addNewProjoectCost(values)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  label={t('general.descrizione')}
                  name="name"
                  rules={[{ required: true, message: requiredFields.required }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  labelAlign="left"
                  label={t('general.responsabile')}
                  name="ownerID"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={projectTeamLeader ? projectTeamLeader : null}>
                  <Select
                    allowClear
                    placeholder={t('general.seleziona')}
                    style={{ width: '100%' }}
                    options={projectTeamPickList}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    loading={!projectTeamPickList}
                  />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  style={{ marginBottom: 5 }}
                  labelAlign="left"
                  label={t('proggetiPage.rifDeliverable')}
                  name="deliverables"
                  hasFeedback>
                  <Select
                    allowClear
                    mode="multiple"
                    maxTagCount="responsive"
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {deliverablePickList &&
                      deliverablePickList.map((item) => (
                        <Option
                          value={item.deliverableID}
                          key={item.deliverableID}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  style={{ marginBottom: 5 }}
                  label={t('proggetiPage.tipologia')}
                  name="type"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={2}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}>
                    {costTypeList &&
                      costTypeList.map((item) => (
                        <Option
                          value={item.objectCodeListID}
                          key={item.objectCodeListID}>
                          {item.description}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default AddProjectCost;

import { PrinterOutlined } from '@ant-design/icons';
import { Button, Card, Col, Row } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ReactToPrint from 'react-to-print';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import ProjectDataHeader from '../../projects/projectDataHeader';
import { userHasAccessProjectFields } from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { projectStatus } from '../../shared/utils/constants';
import '../mainA3.scss';
import Section1PrintPreview from './printableSection1';
import Section2PrintPreview from './printableSection2';
import Section3PrintPreview from './printableSection3';
import Section4PrintPreview from './printableSection4';
import './printCSS.scss';

const pageStyle = `
  @page {
    size: landscape A4 !important;
    margin: 7mm 7mm 7mm 7mm !important;
  }

  @media all {
    break-after: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
  }

  @media print {
    break-after: always !important;
    page-break-after: always !important;
    page-break-inside: avoid !important;
  }
  
`;

class PrintA3Page extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      show: false,
      currentProject: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getProjectData(id);
  }

  getProjectData = (id) => {
    ProgettiService.getProgettiByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentProject: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  getProjectandSectionName = (sectionName) => {
    const { currentProject } = this.state;
    return (
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{sectionName}</span>
        <span>
          {currentProject.projectCode} - {currentProject.name}
        </span>
      </div>
    );
  };

  render() {
    const { id } = this.props.match.params;
    const { currentProject } = this.state;
    const { userData } = this.props;
    let t = this.props.t;
    let languageCode = this.props.i18n.language;

    let hasUserAccess =
      currentProject && userData
        ? !userHasAccessProjectFields(userData) && currentProject.status === projectStatus.completato
          ? true
          : false
        : null;

    return (
      <div className="mainA3 main-a3">
        {currentProject && (
          <>
            <div className="header-wrapper button-wrapper-print">
              <ReactToPrint
                content={() => this.charts}
                documentTitle="A3"
                pageStyle={pageStyle}
                trigger={() => (
                  <Button
                    type="primary"
                    icon={<PrinterOutlined />}>
                    {t('buttons.stampa')}
                  </Button>
                )}
              />
            </div>

            <Row
              className="mb-row main-print-wraper"
              gutter={{ lg: 24 }}
              ref={(el) => (this.charts = el)}
              id="a3-main-body">
              <Col
                className="gutter-row tw-mb-4 "
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Card
                  className="sub440-card-body"
                  title={this.getProjectandSectionName(t('proggetiPage.dettagliProgetto'))}>
                  {/* <A3HeaderPrint t={t} id={id} isPresentationMode={false} currentProjectData={currentProject} /> */}

                  <ProjectDataHeader
                    t={t}
                    id={id}
                    isPresentationMode={false}
                    currentProjectData={currentProject}
                    location={'print'}
                  />
                </Card>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                {/* <A3HeaderPrint id={id} isPresentationMode={false}/> */}

                <Card
                  className="sub440-card-body"
                  title={this.getProjectandSectionName(t('a3Page.motivoPerAgire'))}>
                  <Section1PrintPreview
                    t={t}
                    id={id}
                    isPrintPreview={false}
                    hasUserAccess={hasUserAccess}
                  />
                </Card>
              </Col>

              <Col
                className="gutter-row tw-mb-4 "
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Card
                  className="sub440-card-body"
                  title={this.getProjectandSectionName(t('a3Page.masterPlan'))}
                  headStyle={{
                    backgroundColor: 'lightgray',
                  }}>
                  <Section2PrintPreview
                    currentProject={currentProject}
                    t={t}
                    languageCode={languageCode}
                    id={id}
                    isPrintPreview={false}
                    hasUserAccess={hasUserAccess}
                    showGantt={true}
                    showPrs={true}
                  />
                </Card>
              </Col>

              <Col
                className="gutter-row tw-mb-4 "
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Card
                  className="sub440-card-body"
                  title={this.getProjectandSectionName(t('a3Page.implementazione'))}>
                  <Section3PrintPreview
                    t={t}
                    id={id}
                    isPrintPreview={false}
                    hasUserAccess={hasUserAccess}
                  />
                </Card>
              </Col>

              <Col
                className="gutter-row tw-mb-4 "
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Card
                  className="sub440-card-body"
                  title={this.getProjectandSectionName(t('a3Page.prossimiPassiOtenuti'))}>
                  <Section4PrintPreview
                    t={t}
                    id={id}
                    isPrintPreview={false}
                    hasUserAccess={hasUserAccess}
                  />
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(PrintA3Page));

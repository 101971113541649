import { DeleteOutlined, EditOutlined, PlusOutlined, SearchOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Input, message, Popconfirm, Space, Table } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { history } from '../../App';
import MatrixService from '../../services/matrix/matrixService';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';

class XmatrixCycleListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchedColumn: '',
      fieldsChanged: false,
      loadingData: false,
      cyclesList: null,
    };
  }

  componentDidMount() {
    this.retrieveXmatrixCycleList();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
                    type="link"
                    onClick={() => {
                      confirm({ closeDropdown: false });
                      this.setState({
                        searchText: selectedKeys[0],
                        searchedColumn: dataIndex,
                      });
                    }}
                  >
                    Filter
                  </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    // onFilterDropdownVisibleChange: visible => {
    //   if (visible) {
    //     setTimeout(() => this.searchInput.select(), 100);
    //   }
    // },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  async retrieveXmatrixCycleList() {
    this.setState({ loadingData: true });
    const { userData } = this.props;
    await MatrixService.getXmatrixCycleList(userData.lastLoggedInCompanyID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ loadingData: false });
          this.setState({ cyclesList: resp.responseObject.value });
        } else {
          this.setState({ loadingData: false });
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        this.setState({ loadingData: false });
        message.error(notifyMessages.retrieveFailed);
      });
  }

  onRemoveCycle = (id) => {
    MatrixService.removeXmatrixCycle(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.retrieveXmatrixCycleList();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  onNew = () => {
    history.push('/strategicCycle/new');
  };

  render() {
    const { cyclesList, loadingData } = this.state;
    const { userData } = this.props;
    let t = this.props.t;

    const cycleColumns = [
      {
        title: `${t('xmatrixCyclePage.nomeCycle')}`,
        dataIndex: 'cycleName',
        sorter: (a, b) => {
          a = a.cycleName || '';
          b = b.cycleName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('cycleName'),
      },

      {
        key: 'action',
        width: '50px',
        render: (text, record) => (
          <Button
            icon={<EditOutlined />}
            href={`/strategicCycle/id/${record.xMatrixCycleID}`}></Button>
        ),
      },
      {
        key: 'action2',
        width: '50px',
        render: (_, record) => (
          <Popconfirm
            key="remove"
            title={t('xmatrixCyclePage.rimuoveCycle')}
            icon={<WarningOutlined />}
            okText={t('general.si')}
            cancelText={t('general.no')}
            onConfirm={() => this.onRemoveCycle(record.xMatrixCycleID)}>
            <Button icon={<DeleteOutlined />}></Button>
          </Popconfirm>
        ),
      },
    ];

    return (
      <TableLayout title={t('xmatrixCyclePage.xmatrixCycle')}>
        <TableLayout.Actions>
          <Button
            type="primary"
            htmlType="submit"
            onClick={this.onNew}>
            <PlusOutlined /> {t('buttons.aggiungiNuovo')}
          </Button>
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            tableLayout="fixed"
            columns={cycleColumns}
            dataSource={cyclesList}
            size="small"
            rowKey={(obj) => obj.xMatrixCycleID}
            loading={loadingData}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(XmatrixCycleListPage));

import { Form, Tabs } from 'antd';
import { Component } from 'react';

import { DeleteOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Input, InputNumber, message, Popconfirm, Row, Select, Switch } from 'antd';
import FormItem from 'antd/es/form/FormItem';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommonService from '../../../../services/commonService';
import DashboardService from '../../../../services/pages/dashboardService';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import Loader from '../../../shared/components/loader/loader';
import UserSelectDropDown from '../../../shared/components/userSelect/userSelect';
import TableLayout from '../../../shared/tableLayout';
import { userIsAdminOrProjectTeam } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { projectStatus, statusPickListCodes } from '../../../shared/utils/constants';
import {
  areDatesValid,
  delayFuncCall,
  showErrorNotification,
  updateNotificationBell,
} from '../../../shared/utils/functions';
import { notifyMessages, requiredFields } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import ProjectRoutine from './projectRoutine/projectRoutine';
import ProjectTeamMembers from './teamMembers/teamMembers';

let createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

const { Option } = Select;

class SettingsAndTeam extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDataLoading: false,
      loadingButton: false,
      loadingButtonDelete: false,
      commitiePickList: null,
      projectPickList: null,
      divisionsList: null,
      statusList: null,
      teamMembers: null,
      currentProject: null,
      projectFieldsChanged: false,
      selectedParentProjectData: null,
      isMandatory: false,
    };
  }

  // UNSAFE_componentWillReceiveProps(nextProps) {
  //     if (this.props.currentProject !== nextProps.currentProject) {
  //         this.setState({
  //             currentProject: nextProps.currentProject,
  //         });
  //     }
  // }

  componentDidMount() {
    const { projectId, currentProject } = this.props;
    this.getProjectData(projectId);
    this.retrieveCommities();
    this.retrieveStatus();
    this.getTeamMembers(projectId);
    this.retrieveProjectList(projectId);
    this.retrieveDivisions();
    if (currentProject && currentProject.parentProjectID) {
      this.getParentProjectData(currentProject.parentProjectID);
    }
  }

  async getProjectData(id) {
    await ProgettiService.getProgettiByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentProject: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  }

  async retrieveCommities() {
    await CommonService.getCommitiePicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ commitiePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveStatus() {
    const objectCode = statusPickListCodes.statusProjects;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveProjectList(projectId) {
    await ProgettiService.getParentProjects(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projectPickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveDivisions() {
    await DashboardService.getDivisionFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ divisionsList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  handleDateChange(date) {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  reloadProjectPage = async (projectId) => {
    await delayFuncCall(2000);
    window.location = `/progetti/id/${projectId}/13`;
  };

  updateProjectForm = async (values, currentProject) => {
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);
    values['projectID'] = currentProject.projectID;
    values['description'] = currentProject.description;
    values['projectGoal'] = currentProject.projectGoal;
    values['groundtoAct'] = currentProject.groundtoAct;
    values['obtainedBenefit'] = currentProject.obtainedBenefit;
    values['hanseiLearnedLessons'] = currentProject.hanseiLearnedLessons;
    values['finishedActivitiesNote'] = currentProject.finishedActivitiesNote;
    values['nextStepsNote'] = currentProject.nextStepsNote;
    values['fastCreation'] = currentProject.fastCreation;
    if (values.status !== projectStatus.draft && currentProject.fastCreation == true) {
      values['fastCreation'] = false;
    }

    this.setState({ loadingButton: true });
    ProgettiService.updateProject(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          updateNotificationBell();
          this.setState({ loadingButton: false });
          this.getTeamMembers(currentProject.projectID);
          this.setState({ currentProject: this.props.currentProject });
          this.reloadProjectPage(currentProject.projectID);
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  getTeamMembers(id) {
    this.setState({ isDataLoading: true });
    ProgettiService.getProjectTeamMembers(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ teamMembers: resp.responseObject.value });
          this.setState({ isDataLoading: false });
        } else {
          this.setState({ isDataLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isDataLoading: false });
      });
  }

  backFunction = () => {
    history.goBack();
  };

  getParentProjectData = (id) => {
    ProgettiService.getParentProjectByID(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedParentProjectData: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  removeProject = (projectId) => {
    this.setState({ loadingButtonDelete: true });
    ProgettiService.deleteProject(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentProject: false });
          this.setState({ loadingButtonDelete: false });
          message.success(notifyMessages.deleteSuccess);
          window.location.replace('/progetti');
        } else {
          message.error(notifyMessages.deleteFailed);
          this.setState({ loadingButtonDelete: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
        this.setState({ loadingButtonDelete: false });
      });
  };

  disabledDate(current, startDate, dateType) {
    const { selectedParentProjectData, currentProject } = this.state;
    let curr = moment(current).endOf('day');
    let date = moment(startDate).endOf('day');
    if (dateType === 'start') {
      if (selectedParentProjectData) {
        let currProject = moment(current).endOf('day');
        let startDate = moment(selectedParentProjectData.startDate).endOf('day');
        let endDate = moment(selectedParentProjectData.endDate).endOf('day');
        return currProject < startDate || currProject > endDate;
      }
    } else {
      if (selectedParentProjectData) {
        let currProject = moment(current).endOf('day');
        let startDate = moment(selectedParentProjectData.startDate).endOf('day');
        let endDate = moment(selectedParentProjectData.endDate).endOf('day');
        return currProject < startDate || currProject > endDate;
      } else {
        let currentProjectDate = moment(current).endOf('day');
        let startDate = moment(currentProject.parentProjectStartDate).endOf('day');
        let endDate = moment(currentProject.parentProjectEndDate).endOf('day');
        return currentProjectDate < startDate || currentProjectDate > endDate;
      }
    }
  }

  onParentProjectClear = () => {
    this.setState({ selectedParentProjectData: null });
  };

  onParentProjectChange = (option) => {
    this.getParentProjectData(option);
  };

  checkProjectEndDateValidity = (endDate) => {
    if (endDate) {
      let currentDate = moment().endOf('day');
      let projectEndDate = moment(endDate).endOf('day');

      if (projectEndDate < currentDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  onStatusChange = (value, option) => {
    if (value === projectStatus.attivo) {
      this.setState({ isMandatory: true });
    } else {
      this.setState({ isMandatory: false });
    }
  };

  checkValidityBeforeUpdate = (values, currentProject) => {
    const { t } = this.props;
    if (areDatesValid(values.startDate, values.endDate)) {
      this.updateProjectForm(values, currentProject);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  render() {
    const {
      projectFieldsChanged,
      projectPickList,
      divisionsList,
      commitiePickList,
      statusList,
      teamMembers,
      loadingButton,
      isDataLoading,
      currentProject,
      isMandatory,
    } = this.state;
    const { userData, hasUserAccess, t } = this.props;

    return (
      <>
        <Loader />
        {currentProject && (
          <>
            <Form
              name="project_details_formData"
              className="[&_.header-content]:tw-z-50 [&_.header-content]:tw-bg-white [&_.header-content]:tw-sticky [&_.header-content]:tw-h-16 [&_.header-content]:-tw-top-8"
              layout="vertical"
              onFinish={(values) => this.checkValidityBeforeUpdate(values, currentProject)}
              onValuesChange={() => {
                this.setState({ projectFieldsChanged: true });
              }}>
              <TableLayout title={t('proggetiPage.impostazioneAndTeam')}>
                <TableLayout.Actions>
                  {!hasUserAccess && (
                    <Form.Item className="!tw-mb-0 tw-flex tw-gap-2">
                      <Button
                        loading={loadingButton}
                        icon={<SaveOutlined />}
                        type="primary"
                        htmlType="submit"
                        className="tw-ml-2"
                        key="add"
                        disabled={!projectFieldsChanged}>
                        {t('buttons.salva')}
                      </Button>
                    </Form.Item>
                  )}
                </TableLayout.Actions>
                <TableLayout.Content>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      lg={{ span: 9 }}>
                      {/* <Row>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                       
                          style={{
                            textAlign: 'left',
                            color: '#023B72',
                            fontSize: '19px',
                            fontWeight: '500',
                            padding: '0',
                          }}>
                        </Col>
                      </Row> */}

                      <Form.Item
                        label={t('proggetiPage.nomeProgetto')}
                        name="name"
                        rules={[{ required: true, message: requiredFields.required }]}
                        initialValue={currentProject.name}>
                        <Input
                          disabled={
                            hasUserAccess ||
                            !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label={t('proggetiPage.progettoPrincipale')}
                        name="parentProjectID"
                        initialValue={currentProject.parentProjectID}
                        style={{ margin: '0 0 -10 0' }}>
                        <Select
                          disabled={
                            hasUserAccess ||
                            !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                          }
                          allowClear
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children[0].toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }
                          //
                          placeholder={t('general.seleziona')}
                          style={{ width: '100%' }}
                          onSelect={(option) => this.onParentProjectChange(option)}
                          onClear={() => this.onParentProjectClear()}>
                          {projectPickList &&
                            projectPickList.map((item) => (
                              <Option
                                value={item.projectID}
                                key={item.projectID}>
                                {item.name} ({item.projectCode})
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>

                      <Form.Item
                        className="projectCode"
                        extra={t('proggetiPage.codiceLungeza')}
                        label={t('proggetiPage.codiceProgetto')}
                        name="projectCode"
                        rules={[
                          { required: true, message: requiredFields.required },
                          {
                            max: 10,
                            message: `${t('proggetiPage.codiceLungeza')}`,
                          },
                        ]}
                        initialValue={currentProject.projectCode}>
                        <Input
                          maxLength={10}
                          disabled={
                            hasUserAccess ||
                            !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                          }
                        />
                      </Form.Item>
                      <UserSelectDropDown
                        disabled={
                          hasUserAccess ||
                          !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID) ||
                          this.checkProjectEndDateValidity(currentProject.endDate)
                        }
                        allowClear={false}
                        fieldLabel={t('general.teamLeader')}
                        t={t}
                        formName={'teamLeaderID'}
                        required={isMandatory ? true : currentProject.status === projectStatus.attivo ? true : false}
                        initValue={currentProject.teamLeaderID}
                      />

                      {/* <Form.Item
                          label={t('general.teamLeader')}
                          name="teamLeaderID"
                          hasFeedback
                          // rules={[{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                          rules={isMandatory ? [{ required: true, message: requiredFields.required }] : [{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                          initialValue={currentProject.teamLeaderID}
                      >
                          <Select
                              disabled={
                                  hasUserAccess
                                  || !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                                  || this.checkProjectEndDateValidity(currentProject.endDate)
                              }
                              showSearch
                              placeholder={t('general.seleziona')}
                              optionFilterProp="children"
                              filterOption={(input, option) =>
                                  option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                              }
                              style={{ width: "100%" }}
                          >
                              {usersPickList && usersPickList.map(item =>
                                  <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                              )}
                          </Select>
                      </Form.Item> */}
                      <UserSelectDropDown
                        disabled={
                          hasUserAccess ||
                          !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID) ||
                          this.checkProjectEndDateValidity(currentProject.endDate)
                        }
                        allowClear={false}
                        fieldLabel={t('general.sponsor')}
                        t={t}
                        formName={'sponsorID'}
                        required={isMandatory ? true : currentProject.status === projectStatus.attivo ? true : false}
                        initValue={currentProject.sponsorID}
                      />

                      {/* <Form.Item
                                                        
                                                        label={t('general.sponsor')}
                                                        name="sponsorID"
                                                        hasFeedback
                                                        // rules={[{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                                                        rules={isMandatory ? [{ required: true, message: requiredFields.required }] : [{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                                                        initialValue={currentProject.sponsorID}
                                                    >
                                                        <Select
                                                            disabled={
                                                                hasUserAccess
                                                                || !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                                                                || this.checkProjectEndDateValidity(currentProject.endDate)
                                                            }                                                            
                                                            showSearch
                                                            optionFilterProp="children"
                                                            filterOption={(input, option) =>
                                                                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                            }
                                                            
                                                            placeholder={t('general.seleziona')}
                                                        >
                                                            {usersPickList && usersPickList.map(item =>
                                                                <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                                            )}
                                                        </Select>
                                                    </Form.Item> */}
                      <Form.Item
                        label={t('general.comitato')}
                        name="committeeID"
                        hasFeedback
                        // rules={[{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                        // rules={isMandatory ? [{ required: true, message: requiredFields.required }] : [{ required: currentProject.status === projectStatus.attivo ? true : false, message: requiredFields.required }]}
                        initialValue={currentProject.committeeID}>
                        <Select
                          disabled={
                            hasUserAccess ||
                            !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                          }
                          allowClear
                          placeholder={t('general.seleziona')}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }>
                          {commitiePickList &&
                            commitiePickList.map((item) => (
                              <Option
                                disabled={item.hasAccess === 1 ? false : true}
                                value={item.committeeID}
                                key={item.committeeID}>
                                {item.name}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={t('general.divisione')}
                        name="divisionID"
                        hasFeedback
                        initialValue={currentProject.divisionID}>
                        <Select
                          disabled={
                            hasUserAccess ||
                            !userIsAdminOrProjectTeam(
                              userData,
                              currentProject.teamLeaderID,
                              currentProject.sponsorID,
                            ) ||
                            currentProject.secondaryProject
                          }
                          allowClear
                          placeholder={t('general.seleziona')}
                          showSearch
                          optionFilterProp="children"
                          filterOption={(input, option) =>
                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                          }>
                          {divisionsList &&
                            divisionsList.map((item) => (
                              <Option
                                disabled={item.hasAccess === 1 ? false : true}
                                value={item.divisionID}
                                key={item.divisionID}>
                                {item.divisionName}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={t('proggetiPage.revisioneEsterna')}
                        name="hasExternalReview"
                        valuePropName="checked"
                        initialValue={currentProject.hasExternalReview}>
                        <Switch
                          disabled={
                            hasUserAccess ||
                            !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                          }
                        />
                      </Form.Item>
                      <Form.Item
                        label={t('general.budget')}
                        name="budget"
                        initialValue={currentProject.budget}>
                        <InputNumber
                          min={0}
                          disabled={
                            hasUserAccess ||
                            !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                          }
                        />
                      </Form.Item>
                      <Divider style={{ margin: '20px 0px' }} />

                      <Form.Item
                        label={t('general.start')}
                        name="startDate"
                        rules={[{ required: true, message: requiredFields.required }]}
                        initialValue={
                          currentProject.startDate ? moment(currentProject.startDate) : currentProject.startDate
                        }>
                        {/* <DatePicker disabled={currentProject.fastCreation == true ? false : true } style={{ width: "100%" }} format="DD/MM/YYYY" disabledDate={(current) => this.disabledDate(current, currentProject.startDate, "start")} /> */}
                        <DatePicker
                          disabled={currentProject.status === projectStatus.draft ? false : true}
                          style={{ width: '80%' }}
                          format="DD/MM/YYYY"
                          disabledDate={(current) => this.disabledDate(current, currentProject.startDate, 'start')}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t('general.end')}
                        name="endDate"
                        rules={[{ required: true, message: requiredFields.required }]}
                        initialValue={currentProject.endDate ? moment(currentProject.endDate) : currentProject.endDate}>
                        <DatePicker
                          disabled={
                            hasUserAccess ||
                            !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                          }
                          style={{ width: '80%' }}
                          format="DD/MM/YYYY"
                          disabledDate={(current) => this.disabledDate(current, currentProject.endDate, 'end')}
                        />
                      </Form.Item>
                      <Form.Item
                        label={t('general.stato')}
                        name="status"
                        hasFeedback
                        rules={[{ required: true, message: requiredFields.required }]}
                        initialValue={currentProject.status}>
                        <Select
                          disabled={
                            hasUserAccess ||
                            !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                          }
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          onChange={(value, option) => this.onStatusChange(value, option)}>
                          {statusList &&
                            statusList.map((item) => (
                              <Option
                                value={item.statusID}
                                key={item.statusID}>
                                {item.statusDescription}
                              </Option>
                            ))}
                        </Select>
                      </Form.Item>
                      <Form.Item
                        label={t('a3Page.monthsNumber')}
                        name="masterplanDuration"
                        initialValue={currentProject.masterplanDuration}>
                        <InputNumber
                          min={0}
                          disabled={
                            hasUserAccess ||
                            !userIsAdminOrProjectTeam(userData, currentProject.teamLeaderID, currentProject.sponsorID)
                          }
                        />
                      </Form.Item>

                      <FormItem>
                        <Popconfirm
                          placement="topRight"
                          className={'project-button ' + (currentProject.status === projectStatus.draft) ? 'save' : ''}
                          title={t('proggetiPage.eliminaProgetto')}
                          onConfirm={() => this.removeProject(currentProject.projectID)}
                          okText={t('general.si')}
                          cancelText={t('general.no')}
                          disabled={currentProject.status === projectStatus.draft ? false : true}>
                          <Button
                            key="delete"
                            danger
                            type="primary"
                            icon={<DeleteOutlined />}
                            disabled={currentProject.status === projectStatus.draft ? false : true}>
                            {t('buttons.elimina')}
                          </Button>
                        </Popconfirm>
                      </FormItem>
                    </Col>

                    <Col
                      xs={24}
                      lg={15}>
                      <Tabs
                        defaultActiveKey="1"
                        items={[
                          {
                            key: '1',
                            label: t('proggetiPage.teamMembers'),
                            children: (
                              <ProjectTeamMembers
                                t={t}
                                projectId={currentProject.projectID}
                                teamMembers={teamMembers}
                                reloadUsers={() => this.getTeamMembers(currentProject.projectID)}
                                isDataLoading={isDataLoading}
                                hasUserAccess={hasUserAccess}
                                isAdminOrProjectTeam={
                                  !userIsAdminOrProjectTeam(
                                    userData,
                                    currentProject.teamLeaderID,
                                    currentProject.sponsorID,
                                  )
                                }
                                checkProjectEndDateValidity={this.checkProjectEndDateValidity(currentProject.endDate)}
                              />
                            ),
                          },
                          {
                            key: '2',
                            label: t('proggetiPage.routneGestioneProgetto'),
                            children: (
                              <ProjectRoutine
                                projectId={currentProject.projectID}
                                endDateProject={currentProject.endDate}
                                startDateProject={currentProject.startDate}
                                hasUserAccess={hasUserAccess}
                                isAdminOrProjectTeam={
                                  !userIsAdminOrProjectTeam(
                                    userData,
                                    currentProject.teamLeaderID,
                                    currentProject.sponsorID,
                                  )
                                }
                              />
                            ),
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </TableLayout.Content>
              </TableLayout>
            </Form>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(SettingsAndTeam));

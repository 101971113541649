import { Avatar, Table, Typography } from 'antd';
import Column from 'antd/es/table/Column';
import { useTranslation, withTranslation } from 'react-i18next';
import TableLayout from '../../../shared/tableLayout';
import { generateUserInitials } from '../../../shared/utils/functions';

import { ParticipantIcon, StarIcon } from '../../../../assets/icons';

const CommitieTeam = ({ comitieId, comitieDetails, ...props }) => {
  const { t } = useTranslation();

  return (
    <>
      <TableLayout
        title={
          <Typography.Title
            className="!tw-mb-0"
            level={4}>
            {t('comitatiPage.team')}
          </Typography.Title>
        }>
        <TableLayout.Content>
          {comitieDetails && (
            <Table
              size="small"
              dataSource={[...comitieDetails.participants, ...comitieDetails.revisori]}
              rowKey={(obj) => obj.projectID}
              pagination={false}>
              <Column
                key="name"
                title={t('general.nome')}
                sorter={(a, b) => {
                  a = a.firstName || '';
                  b = b.firstName || '';
                  return a.localeCompare(b);
                }}
                width="110px"
                fixed="left"
                render={(user) => {
                  return (
                    <div className="table-avatar">
                      <Avatar size="small">{generateUserInitials(user.firstName, user.lastName)}</Avatar>
                      <span key={user.userID}>{user.firstName + ' ' + user.lastName}</span>
                    </div>
                  );
                }}
              />
              <Column
                key="role"
                title={t('general.ruolo')}
                width="110px"
                fixed="left"
                render={(user) => {
                  const isReviewer = comitieDetails.revisori.find((u) => u.userID === user.userID);

                  return (
                    <div className="tw-flex tw-flex-row tw-items-center tw-gap-1 tw-border tw-rounded-md tw-border-zinc-200 tw-w-fit tw-px-2 tw-py-1">
                      {isReviewer ? <StarIcon /> : <ParticipantIcon />}
                      <span>{isReviewer ? t('comitatiPage.revisore') : t('comitatiPage.participant')}</span>
                    </div>
                  );
                }}
              />
            </Table>
          )}
        </TableLayout.Content>
      </TableLayout>
    </>
  );
};

export default withTranslation()(CommitieTeam);

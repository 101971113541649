import {
  DeleteOutlined,
  EditOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  SearchOutlined,
  StarFilled,
  StarOutlined,
  SyncOutlined,
  UploadOutlined,
} from '@ant-design/icons';
import { Button, Input, Popconfirm, Space, Table, Tag, Tooltip, message } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import Highlighter from 'react-highlight-words';
import KpiService from '../../../../services/pages/kpiService';
import DeliverableService from '../../../../services/pages/projectServices/deliverableServices';
import UploadKpiSpecifico from '../../../kpiListPage/uploadKpiSpecifico';
import TableLayout from '../../../shared/tableLayout';
import { kpiCalculationType } from '../../../shared/utils/constants';
import {
  italianNumberFormat,
  kpiSemaphoreColor,
  kpiTypeColor,
  numberWithCommas,
} from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import EditProjectKpi from './editKpi';
import NewProjectKpi from './newKpi';
import { withTranslation } from 'react-i18next';

class ProjectKpi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchedColumn: '',
      loadingButton: false,
      loadingIcon: false,
      showNewKpiModal: false,
      showUploadKpiModal: false,
      showEditKpiModal: false,
      loadingKpi: false,
      kpiList: [],
      kpiRowData: null,
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  componentWillMount() {
    this.retrieveKpi();
  }

  componentWillUnmount() {
    this.setState({ loadingKpi: false });
  }

  handleDateChange(date) {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  async retrieveKpi() {
    this.setState({ loadingKpi: true });
    const { projectId } = this.props;
    await DeliverableService.getProjectKpi(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ kpiList: resp.responseObject.value });
          this.setState({ loadingKpi: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingKpi: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingKpi: false });
      });
  }

  handelNewKpiModalClose = () => {
    this.setState({ showNewKpiModal: false });
    this.setState({ loadingButton: false });
  };

  toggleNewKpiModal = () => {
    this.setState({ showNewKpiModal: true });
  };

  addProjectKpi = (values, projectId) => {
    this.setState({ loadingButton: true });
    values['projectID'] = projectId;

    DeliverableService.insertKpiProject(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.handelNewKpiModalClose();
          this.retrieveKpi();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  handelEditKpiModalClose = () => {
    this.setState({ showEditKpiModal: false });
    this.setState({ loadingButton: false });
  };

  toggleEditKpiModal = (rowData) => {
    this.setState({ showEditKpiModal: true });
    this.setState({ kpiRowData: rowData });
  };

  toggleUploadKpiSpecificoModal = (rowData) => {
    this.setState({ showUploadKpiModal: true });
    this.setState({ kpiRowData: rowData });
  };

  handelUploadKpiModalClose = () => {
    this.setState({ showUploadKpiModal: false });
    this.setState({ loadingButton: false });
  };

  updateBowlingChart = (payload) => {
    const { projectId } = this.props;

    this.setState({ loadingButton: true });
    DeliverableService.updateKpiBowlingChart(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.handelEditKpiModalClose();
          this.retrieveKpi();
          // window.location.reload();
          setTimeout(function () {
            window.location = `/progetti/id/${projectId}/8`;
          }, 1000);
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  removeKpi = (kpiProjectRelationShipID) => {
    DeliverableService.removeKpi(kpiProjectRelationShipID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.retrieveKpi();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  setKpiAsPrefered = (kpiDetailID) => {
    let dataObject = {};
    dataObject['KPIDetailID'] = kpiDetailID;

    this.setState({ loadingIcon: true });
    KpiService.setKpiPrefered(dataObject)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.retrieveKpi();
          this.setState({ loadingIcon: false });
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingIcon: false });
      });
  };

  removeKpiAsPrefered = (kpiDetailID) => {
    this.setState({ loadingIcon: true });
    KpiService.removeKpiPrefered(kpiDetailID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.retrieveKpi();
          this.setState({ loadingIcon: false });
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingIcon: false });
      });
  };

  render() {
    const {
      kpiList,
      loadingKpi,
      showNewKpiModal,
      showEditKpiModal,
      loadingButton,
      kpiRowData,
      showUploadKpiModal,
      loadingIcon,
    } = this.state;
    const { projectId, projectName, currentProject, hasUserAccess, t, projectTeamMembers } = this.props;

    const kpiColumns = [
      {
        dataIndex: 'isFavourite',
        width: '40px',
        showSorterTooltip: [false],

        render: (text, record, index) => {
          return {
            props: {
              style: { borderLeft: `5px solid ${kpiSemaphoreColor(record.semaforo)}` },
            },
            children: (
              <>
                {record.isFavourite && (
                  <Popconfirm
                    placement="topRight"
                    title={t('kpiPage.removeKpiPreferita')}
                    onConfirm={() => this.removeKpiAsPrefered(record.kpiDetailID)}
                    okText={t('general.si')}
                    cancelText={t('general.no')}>
                    <StarFilled
                      style={{ color: '#f5a645' }}
                      title={t('kpiPage.preferita')}
                    />
                  </Popconfirm>
                )}

                {!record.isFavourite && (
                  <Popconfirm
                    placement="topRight"
                    title={t('kpiPage.setKpiPreferita') + `${loadingIcon ? <SyncOutlined spin /> : ''}`}
                    onConfirm={() => this.setKpiAsPrefered(record.kpiDetailID)}
                    okText={t('general.si')}
                    cancelText={t('general.no')}>
                    <StarOutlined title={t('kpiPage.preferita')} />
                  </Popconfirm>
                )}
              </>
            ),
          };
        },
      },

      {
        title: `${t('general.nome')}`,
        dataIndex: 'name',
        key: 'name',
        width: '24%',
        fixed: 'left',
        ellipsis: {
          showTitle: false,
        },
        sorter: (a, b) => {
          a = a.name || '';
          b = b.name || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        ...this.getColumnSearchProps('name'),
        render: (title) => <Tooltip title={title}>{title}</Tooltip>,
      },
      {
        title: `${t('kpiPage.tipoCalcolo')}`,
        dataIndex: 'calculationTypeDescription',
        sorter: (a, b) => {
          a = a.calculationTypeDescription || '';
          b = b.calculationTypeDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        // ...this.getColumnSearchProps('calculationTypeDescription'),
        ellipsis: {
          showTitle: false,
        },
        render: (title) => (
          <Tooltip title={title}>
            <Tag>{title}</Tag>
          </Tooltip>
        ),
      },
      {
        title: `${t('kpiPage.cumulatoPuntuale')}`,
        dataIndex: 'parentTypeDescription',
        sorter: (a, b) => {
          a = a.parentTypeDescription || '';
          b = b.parentTypeDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        // ...this.getColumnSearchProps('parentTypeDescription'),
        ellipsis: {
          showTitle: false,
        },
        render: (title) => (
          <Tooltip title={title}>
            <Tag>{title}</Tag>
          </Tooltip>
        ),
      },

      {
        title: `${t('general.tipo')}`,
        dataIndex: 'kpiTypeDescription',
        key: 'kpiTypeDescription',
        sorter: (a, b) => {
          a = a.kpiTypeDescription || '';
          b = b.kpiTypeDescription || '';
          return a.localeCompare(b);
        },
        ...this.getColumnSearchProps('kpiTypeDescription'),
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <>
            <Tag>
              <InfoCircleOutlined
                style={{ fontSize: 12 }}
                className={kpiTypeColor(record.kpiType)}
              />
              <span> {text}</span>
            </Tag>
          </>
        ),
      },
      {
        align: 'right',
        title: `${t('general.jumpOffPoint')}`,
        dataIndex: 'jumpOffPoint',
        key: 'jumpOffPoint',
        sorter: (a, b) => a.jumpOffPoint - b.jumpOffPoint,
        ellipsis: {
          showTitle: false,
        },
        // render: (text) => (numberWithCommas(text))
        render: (text, record) => {
          if (record.calculationType === kpiCalculationType.valorePercentuale) {
            return numberWithCommas(text) + ' %';
          } else if (record.calculationType === kpiCalculationType.valoreFatoNonFato) {
            if (text === 1) {
              return t('kpiPage.fatto');
            } else {
              return t('kpiPage.nonFatto');
            }
          } else {
            return numberWithCommas(text);
          }
        },
      },
      {
        align: 'right',
        title: `${t('kpiPage.finalTarget')}`,
        dataIndex: 'target',
        key: 'target',
        sorter: (a, b) => a.target - b.target,
        ellipsis: {
          showTitle: false,
        },
        // render: (text) => (numberWithCommas(text))
        render: (text, record) => {
          if (record.calculationType === kpiCalculationType.valorePercentuale) {
            return italianNumberFormat(text) + ' %';
          } else if (record.calculationType === kpiCalculationType.valoreFatoNonFato) {
            if (text === 1) {
              return t('kpiPage.fatto');
            } else {
              return t('kpiPage.nonFatto');
            }
          } else {
            return italianNumberFormat(text);
          }
        },
      },
      {
        align: 'right',
        title: `${t('general.updateYtd')}`,
        dataIndex: 'updateYTD',
        key: 'updateYTD',
        sorter: (a, b) => a.updateYTD - b.updateYTD,
        ellipsis: {
          showTitle: false,
        },
        // render: (text) => (numberWithCommas(text))
        render: (text, record) => {
          if (record.calculationType === kpiCalculationType.valorePercentuale) {
            return italianNumberFormat(text) + ' %';
          } else if (record.calculationType === kpiCalculationType.valoreFatoNonFato) {
            if (text === 1) {
              return t('kpiPage.fatto');
            } else {
              return t('kpiPage.nonFatto');
            }
          } else {
            return italianNumberFormat(text);
          }
        },
      },
      {
        align: 'right',
        title: `${t('general.targetYtd')}`,
        dataIndex: 'targetYTD',
        key: 'targetYTD',
        sorter: (a, b) => a.targetYTD - b.targetYTD,
        ellipsis: {
          showTitle: false,
        },
        // render: (text) => (numberWithCommas(text))
        // render: (text) => (italianNumberFormat(text))
        render: (text, record) => {
          if (record.calculationType === kpiCalculationType.valorePercentuale) {
            return numberWithCommas(text) + ' %';
          } else if (record.calculationType === kpiCalculationType.valoreFatoNonFato) {
            if (text === 1) {
              return t('kpiPage.fatto');
            } else {
              return t('kpiPage.nonFatto');
            }
          } else {
            return italianNumberFormat(text);
          }
        },
      },
      {
        key: 'action',
        width: '150px',
        render: (_, record) => (
          <>
            {!this.props.isPresentationMode && (
              <div className="tw-flex tw-items-center tw-gap-2">
                <Button
                  type="ghost"
                  icon={<EditOutlined />}
                  onClick={() => this.toggleEditKpiModal(record)}></Button>
                <Popconfirm
                  placement="topRight"
                  title={t('proggetiPage.rimuoveProgettoKpi')}
                  onConfirm={() => this.removeKpi(record.kpiProjectRelationShipID)}
                  okText={t('general.si')}
                  cancelText={t('general.no')}>
                  <Button
                    icon={<DeleteOutlined />}
                    type="ghost"></Button>
                </Popconfirm>

                <Tooltip
                  placement="top"
                  title={'Carica dati del KPI'}>
                  <Button
                    type="ghost"
                    onClick={() => this.toggleUploadKpiSpecificoModal(record)}
                    icon={<UploadOutlined />}></Button>
                </Tooltip>
              </div>
            )}
          </>
        ),
      },
    ];

    const uniqueMembersIds = [];
    projectTeamMembers.map((element) => {
      let exist = uniqueMembersIds.filter((el) => el?.value === element?.value);
      if (exist.length === 0) {
        uniqueMembersIds.push(element);
      }
    });

    return (
      <TableLayout
        title={
          <h1
            className={
              'tw-text-xl tw-mb-2 tw-font-semibold' + this.props.isPresentationMode ? '!tw-text-3xl !tw-mb-4' : ''
            }>
            KPI
          </h1>
        }>
        <TableLayout.Actions>
          {!this.props.isPresentationMode && showNewKpiModal && (
            <NewProjectKpi
              showNewKpiModal={showNewKpiModal}
              handelNewKpiModalClose={this.handelNewKpiModalClose}
              handelKpiModalSave={this.addProjectKpi}
              loadingButton={loadingButton}
              projectId={projectId}
              t={t}
            />
          )}

          {!this.props.isPresentationMode && showEditKpiModal && (
            <EditProjectKpi
              showEditKpiModal={showEditKpiModal}
              handelEditKpiModalClose={this.handelEditKpiModalClose}
              handelUpdateBowlingChart={this.updateBowlingChart}
              loadingButton={loadingButton}
              projectId={projectId}
              kpiRowData={kpiRowData}
              projectTeamMembers={uniqueMembersIds}
              currentProject={currentProject}
            />
          )}

          {!this.props.isPresentationMode && showUploadKpiModal && (
            <UploadKpiSpecifico
              showUploadKpiModal={showUploadKpiModal}
              handelUploadKpiModalClose={this.handelUploadKpiModalClose}
              loadingButton={loadingButton}
              projectId={projectId}
              kpiRowData={kpiRowData}
              projectName={projectName}
            />
          )}

          {!this.props.isPresentationMode && !hasUserAccess && (
            <Button
              htmlType="submit"
              type="primary"
              onClick={this.toggleNewKpiModal}>
              <PlusOutlined />
              {t('buttons.aggiungiNuovo')}
            </Button>
          )}
        </TableLayout.Actions>
        <TableLayout.Content>
          {!hasUserAccess && (
            <Table
              columns={
                hasUserAccess
                  ? kpiColumns.filter((el) => {
                      return el.key !== 'action2' && el.key !== 'action';
                    })
                  : kpiColumns
              }
              size="small"
              dataSource={kpiList}
              rowKey={(obj) => obj.kpiProjectRelationShipID}
              loading={loadingKpi}
              scroll={{ x: 'calc(700px + 50%)' }}
              pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
            />
          )}
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

export default withTranslation()(ProjectKpi);

import { EditOutlined, FolderOpenOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Input, message, Popover, Space, Table, Tag, Tooltip, Typography } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';
import CommonService from '../../../services/commonService';
import DeliverablePageService from '../../../services/pages/deliverableService';
import TableLayout from '../../shared/tableLayout';
import { statusPickListCodes } from '../../shared/utils/constants';
import { ResponsiblePopoverContent } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';

const { Paragraph } = Typography;

class DeliverableList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchedColumn: '',
      deliverableList: null,
      statusListFilter: [],
      filteredInfo: [`${props.t('status.draft')}`, `${props.t('status.aperto')}`, `${props.t('status.sospeso')}`],
    };
  }

  // Init of component after rendering
  componentDidMount() {
    this.retrieveDeliverableList();
    this.retrieveStatusList();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
                type="link"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    // onFilterDropdownVisibleChange: visible => {
    //   if (visible) {
    //     setTimeout(() => this.searchInput.select(), 100);
    //   }
    // },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      filteredInfo: filters.statusDescription,
    });
  };

  // Retrieve deliverable list
  async retrieveDeliverableList() {
    this.setState({ loadingData: true });
    await DeliverablePageService.getDeliverableList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ deliverableList: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  async retrieveStatusList() {
    const objectCode = statusPickListCodes.delivarbleStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let obj = [];
          resp.responseObject.value.map((item) => {
            obj.push({ text: item.statusDescription, value: item.statusDescription });
          });
          this.setState({ statusListFilter: obj });
        } else {
        }
      })
      .catch((error) => {});
  }

  onNew = () => {
    this.props.history.push('/deliverables/new/pid');
  };

  render() {
    const { deliverableList, loadingData, statusListFilter } = this.state;
    let t = this.props.t;
    let { filteredInfo } = this.state;

    const columns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'deliverableName',
        ellipsis: {
          showTitle: true,
        },
        width: '40%',
        sorter: (a, b) => {
          a = a.deliverableName || '';
          b = b.deliverableName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('deliverableName'),
        render(title, record) {
          return {
            // props: {
            //   style: {
            //     borderLeft: `5px solid ${checkDeliverableStatus(record, 'deliverableList')}`,
            //     borderRadius: '5px 0 0 5px',
            //   },
            // },
            children: <Tooltip title={title}>{title}</Tooltip>,
            // <Tooltip  title={title}><Paragraph ellipsis={{ rows: 2}}>{title}</Paragraph></Tooltip>
          };
        },
      },

      {
        title: `${t('general.progetto')}`,
        dataIndex: 'projectName',
        sorter: (a, b) => {
          a = a.projectName || '';
          b = b.projectName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('projectName'),
        ellipsis: {
          showTitle: false,
        },
        render: (title) => <Tooltip title={title}>{title}</Tooltip>,
      },
      {
        title: `${t('general.responsabile')}`,
        dataIndex: 'ownerName',
        sorter: (a, b) => {
          a = a.ownerName || '';
          b = b.ownerName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('ownerName'),
        render: (title, record) => (
          <Popover
            content={
              <ResponsiblePopoverContent
                data={record}
                t={t}
              />
            }
            title={title}>
            <div className="table-avatar">
              <Avatar size={'small'}>{title?.charAt(0)}</Avatar>
              <span>{title}</span>
            </div>
          </Popover>
        ),
      },

      //   {
      //     title: `${t('deliverablePage.genericResponsabile')}`,
      //     dataIndex: 'genericResponsible',
      //     key: 'genericResponsible',
      //     ellipsis: {
      //         showTitle: false,
      //     },
      //     render: title => (
      //         <Tooltip  title={title}>
      //             {title}
      //         </Tooltip>
      //     ),
      //     showSorterTooltip: [true],
      // },

      {
        title: `${t('general.stato')}`,
        dataIndex: 'statusDescription',
        sorter: (a, b) => {
          a = a.statusDescription || '';
          b = b.statusDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        filters: statusListFilter,
        defaultFilteredValue: filteredInfo || null,
        filterSearch: true,
        onFilter: (value, record) => (record.statusDescription ? record.statusDescription.includes(value) : ''),
        render: (title) => <Tag>{title}</Tag>,

        //   render(text, record) {
        //     return {
        //         children: <div>{text}</div>
        //     };
        // }
      },

      {
        key: 'action',
        width: '50px',
        render: (text, record) =>
          record.projectID ? (
            <Tooltip title={t('proggetiPage.passaProgetto')}>
              <Button
                type="ghost"
                icon={<FolderOpenOutlined />}
                href={`/progetti/id/${record.projectID}`}></Button>
            </Tooltip>
          ) : null,
      },
      {
        key: 'action',
        width: '50px',
        render: (text, record) => (
          <Button
            icon={<EditOutlined />}
            href={`/deliverables/id/${record.deliverableID}`}></Button>
        ),
      },
    ];

    return (
      <Card>
        <TableLayout title={t('deliverablePage.pageHeader')}>
          <TableLayout.Actions>
            <Button
              type="primary"
              className="add-new mb10"
              onClick={this.onNew}
              data-testid="newChildDeliverable">
              <PlusOutlined />
              {t('buttons.aggiungiNuovo')}
            </Button>
          </TableLayout.Actions>
          <TableLayout.Content>
            <Table
              className="table-height"
              columns={columns}
              size="small"
              dataSource={deliverableList}
              rowKey={(obj) => obj.deliverableID}
              loading={loadingData}
              pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
              onChange={this.handleChange}
            />
          </TableLayout.Content>
        </TableLayout>
      </Card>
    );
  }
}

export default withTranslation()(DeliverableList);

import { FormOutlined } from '@ant-design/icons';
import { Button, Col, message, Row } from 'antd';
import BraftEditor from 'braft-editor';
import Parser from 'html-react-parser';
import { useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ProgettiService from '../../../../services/pages/projectServices/progettiService';
import Loader from '../../../shared/components/loader/loader';
import TableLayout from '../../../shared/tableLayout';
import { projectFieldEditorObjectUpdate } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import EditorModal from '../mainInfo/richTextEditorModal';
import ProjectObjectives from '../projectObjectives/projectObjectives';

const ProjectObjectivesIndex = (props) => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingEditorButton, setLoadingEditorButton] = useState(false);
  const [editorModal, setEditorModal] = useState(false);
  const [fieldObject, setFieldObject] = useState(null);
  const [currentProject, setCurrentProject] = useState(null);

  useEffect(() => {
    const { projectId } = props;
    retrieveComponentData(projectId);
  }, [props.projectId]);

  const retrieveComponentData = (id) => {
    getProjectData(id);
  };

  const getProjectData = (id) => {
    ProgettiService.getProgettiByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setCurrentProject(resp.responseObject.value);
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  const toggleEditorModal = (fieldData) => {
    setEditorModal(!editorModal);
    setFieldObject(fieldData);
  };

  const handelEditorClose = () => {
    setEditorModal(false);
  };

  const updateFieldToObject = (values) => {
    const fieldName = Object.keys(values)[0];
    const editorContentToHtml = BraftEditor.createEditorState(values[fieldName]).toHTML();
    setCurrentProject((prevProject) => ({
      ...prevProject,
      [fieldName]: editorContentToHtml,
    }));
    updateProjectEditorFields(values, fieldName, editorContentToHtml);
  };

  const updateProjectEditorFields = (values, fieldName, editorContentToHtml) => {
    const { currentProject } = props;
    setLoadingEditorButton(true);
    const payloadObj = {
      projectId: currentProject.projectID,
      projectFieldName: fieldName,
      projectFieldValue: editorContentToHtml,
    };

    ProgettiService.updateEditorField(payloadObj)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setLoadingEditorButton(false);
          handelEditorClose();
        } else {
          setLoadingEditorButton(false);
        }
      })
      .catch(() => {
        setLoadingEditorButton(false);
      });
  };

  const { hasUserAccess, t, projectId } = props;

  return (
    <>
      <Loader />
      {currentProject && (
        <>
          <EditorModal
            show={editorModal}
            handelEditorClose={handelEditorClose}
            handelEditorSave={updateFieldToObject}
            data={fieldObject}
            loadingEditorButton={loadingEditorButton}
          />

          <Row gutter={{ lg: 24 }}>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}>
              <ProjectObjectives
                t={t}
                projectId={projectId}
              />
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 14 }}
              lg={{ span: 14 }}>
              <TableLayout title={t('general.descrizione')}>
                <TableLayout.Actions>
                  {!hasUserAccess && (
                    <Button
                      icon={<FormOutlined />}
                      onClick={() =>
                        toggleEditorModal(projectFieldEditorObjectUpdate('description', currentProject.description, t))
                      }
                    />
                  )}
                </TableLayout.Actions>
                <TableLayout.Content>{Parser(currentProject.description || 'Nessuna descrizione')}</TableLayout.Content>
              </TableLayout>
            </Col>
          </Row>
        </>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ProjectObjectivesIndex));

import { DesktopOutlined, FullscreenExitOutlined, LeftOutlined } from '@ant-design/icons';
import { Button, message, Tabs, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import CommonService from '../../../services/commonService';
import MatrixService from '../../../services/matrix/matrixService';
import ObiettiviAnnoService from '../../../services/pages/obiettiviAnnoService';
import ObiettiviLongService from '../../../services/pages/obiettiviLongService';
import { addQueryParam, getQueryParam } from '../../../utils/url-utils.js';
import '../../a3/presentationMode/fontSizeZoom.scss';
import Loader from '../../shared/components/loader/loader';
import { isTeamleaderOrTeamMemberOrObAnnoResponsible } from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { longOrAnnualGoalFieldUpdate, objectTypeUserSecurity } from '../../shared/utils/constants';
import { NoAccessMesssage } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import '../obietiviAnno.scss';
import AnnualGoalFeed from './feed';
import Impostazioni from './impostazioni';
import WorkshopPresentation from './presentationModeWorkShop';
import Workshop from './workshop';

const { Paragraph } = Typography;

const IndexDetailsObAnno = (props) => {
  const urlParams = useParams();
  const annualGoalId = urlParams.id;
  const routeSelectedXmatrix = urlParams.xmatrix;
  const { t } = useTranslation();
  const history = useHistory();

  const userData = useSelector((state) => state.userData.userData);

  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);
  const [annualGoalData, setAnnualGoalData] = useState(null);
  const [loadingSaveField, setLoadingSaveField] = useState(false);
  const [loadingData, setLoadingData] = useState(false);
  const [checkUserAccessOnObject, setCheckUserAccessOnObject] = useState(false);
  const [activeKeyTab, setActiveKeyTab] = useState('2');

  const [editableTitle, setEditableTitle] = useState('');

  const [kpiList, setKpiList] = useState(null);
  const [loadingKpiList, setKoadingKpiList] = useState(false);

  const [presentationMode, setPresentationMode] = useState(false);

  useEffect(() => {
    getActiveXmatrix();
    checkIfUserHasAccess(annualGoalId);

    setActiveKeyTab(getQueryParam('tab') || '2');
  }, []);

  useEffect(() => {
    getAnnualGoalData();
    getObjAnnoKPI();
  }, [activeXmatrixInfo, selectedXmatrixValue]);

  const onXmatrixSelect = (selectedXmatrix) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
  };

  const getActiveXmatrix = async () => {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const checkIfUserHasAccess = async (objectId) => {
    let obj = {};
    obj['type'] = objectTypeUserSecurity.annualGoal;
    obj['objectID'] = objectId;
    await CommonService.chaeckUserAccessOnObject(obj)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (resp.responseObject.value.hasPrivilege === 1) setCheckUserAccessOnObject(true);
          else {
            setCheckUserAccessOnObject(false);
          }
        } else {
        }
      })
      .catch((error) => {});
  };

  const getAnnualGoalData = () => {
    let xmatrix = activeXmatrixInfo
      ? selectedXmatrixValue
        ? selectedXmatrixValue
        : activeXmatrixInfo?.xMatrixID
      : null;

    if (xmatrix) {
      setLoadingData(true);
      ObiettiviAnnoService.getObiettiviAnnoByiD(annualGoalId, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let respData = resp.responseObject.value;
            setAnnualGoalData(respData);
            setLoadingData(false);
            setEditableTitle(respData.getAnnualGoalDto.description);
          } else {
            message.error(notifyMessages.retrieveFailed);
            setLoadingData(false);
          }
        })
        .catch((error) => {
          message.error(notifyMessages.retrieveFailed);
          setLoadingData(false);
        });
    }
  };

  const onTitleSave = (value) => {
    let payload = {};
    setEditableTitle(value);

    payload['itemID'] = annualGoalId;
    payload['nameOrNote'] = value;
    payload['updateCase'] = longOrAnnualGoalFieldUpdate.anualGoalName;

    updateCommentOrName(payload);
  };

  const updateCommentOrName = (payload) => {
    setLoadingSaveField(true);
    ObiettiviLongService.updateCommentOrNameField(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setLoadingSaveField(false);
          getAnnualGoalData();
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingSaveField(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingSaveField(false);
      });
  };

  const getObjAnnoKPI = () => {
    if (activeXmatrixInfo) {
      let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

      setKoadingKpiList(true);
      ObiettiviAnnoService.getObjAnnoKpi(annualGoalId, xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let respData = resp.responseObject.value;
            setKpiList(respData);
            setKoadingKpiList(false);
          } else {
            setKoadingKpiList(false);
          }
        })
        .catch((error) => {
          setKoadingKpiList(false);
        });
    } else {
    }
  };

  const onPresentationModeActivate = () => {
    setActiveKeyTab('1');
    setPresentationMode(true);
  };

  const updateAnnualGoalData = (selectedAnnualId) => {
    if (selectedAnnualId === annualGoalId) {
      getAnnualGoalData();
    }
  };

  let isRoleTeamMemberOrTeamLeader =
    userData && Object.keys(userData).length > 0 && annualGoalData
      ? isTeamleaderOrTeamMemberOrObAnnoResponsible(userData, annualGoalData)
      : false;

  let tabsItems = [
    {
      label: <>{t('obiettiviLungoPage.workShopComments')}</>,
      key: '1',
      children: (
        <>
          {!presentationMode && (
            <Workshop
              routeSelectedXmatrix={routeSelectedXmatrix}
              updateAnnualGoalData={updateAnnualGoalData}
              annualGoalData={annualGoalData}
              annualGoalId={annualGoalId}
              t={t}
              isRoleTeamMemberOrTeamLeader={isRoleTeamMemberOrTeamLeader}
              updateFields={updateCommentOrName}
            />
          )}

          {presentationMode && (
            <WorkshopPresentation
              annualGoalData={annualGoalData}
              annualGoalId={annualGoalId}
              t={t}
              isRoleTeamMemberOrTeamLeader={isRoleTeamMemberOrTeamLeader}
              updateFields={updateCommentOrName}
            />
          )}
        </>
      ),
    },
    {
      label: <>{t('obiettiviLungoPage.impostazioni')}</>,
      key: '2',
      disabled: presentationMode ? true : false,
      children: (
        <Impostazioni
          routeSelectedXmatrix={routeSelectedXmatrix}
          annualGoalData={annualGoalData}
          annualGoalId={annualGoalId}
          t={t}
          isRoleTeamMemberOrTeamLeader={isRoleTeamMemberOrTeamLeader}
          reloadData={() => getAnnualGoalData()}
          activeXmatrixInfo={activeXmatrixInfo}
          onXmatrixSelect={onXmatrixSelect}
          loadingData={loadingData}
          kpiList={kpiList}
          loadingKpiList={loadingKpiList}
          reloadObAnnoKpiList={getObjAnnoKPI}
        />
      ),
    },
    {
      label: <>{t('general.commenti')}</>,
      key: '3',
      disabled: presentationMode ? true : false,
      children: (
        <AnnualGoalFeed
          defaultCheckedFeedType={2}
          annualGoalData={annualGoalData}
          annualGoalId={annualGoalId}
          t={t}
          userData={userData}
          commentFeedsOnly={true}
          fileFeedsOnly={false}
          othersFeedsOnly={false}
        />
      ),
    },
    {
      label: <>{t('proggetiPage.file')}</>,
      key: '4',
      disabled: presentationMode ? true : false,
      children: (
        <AnnualGoalFeed
          defaultCheckedFeedType={3}
          annualGoalData={annualGoalData}
          annualGoalId={annualGoalId}
          t={t}
          userData={userData}
          commentFeedsOnly={false}
          fileFeedsOnly={true}
          othersFeedsOnly={false}
        />
      ),
    },
    {
      label: <>{t('proggetiPage.notificeRiunioni')}</>,
      key: '5',
      disabled: presentationMode ? true : false,
      children: (
        <AnnualGoalFeed
          defaultCheckedFeedType={4}
          annualGoalData={annualGoalData}
          annualGoalId={annualGoalId}
          t={t}
          userData={userData}
          commentFeedsOnly={false}
          fileFeedsOnly={false}
          othersFeedsOnly={true}
        />
      ),
    },
  ];

  return (
    <div>
      <Loader />
      {annualGoalData && (
        <>
          {!checkUserAccessOnObject && <NoAccessMesssage />}

          {checkUserAccessOnObject && (
            <>
              <div className="tw-bg-white tw-gap-2 tw-border-b tw-h-14 tw-flex tw-items-center tw-box-border">
                <Button
                  onClick={() => history.goBack()}
                  type="ghost"
                  icon={<LeftOutlined />}></Button>
                <Typography.Title
                  level={4}
                  className="!tw-mb-0 tw-w-full"
                  editable={!isRoleTeamMemberOrTeamLeader ? { onChange: (value) => onTitleSave(value) } : false}>
                  {editableTitle}
                </Typography.Title>

                <span className="tw-w-fit tw-shrink-0 tw-mr-4"> {t('obiettiviAnnoPage.obbietiviAnuali')} </span>
              </div>

              <Tabs
                destroyInactiveTabPane={true}
                defaultActiveKey="1"
                className="tw-px-4"
                activeKey={activeKeyTab}
                onChange={(activeKey) => {
                  setActiveKeyTab(activeKey);
                  addQueryParam('tab', activeKey);
                }}
                items={tabsItems}
                tabBarExtraContent={{
                  right: (
                    <>
                      {activeKeyTab === '1' && (
                        <>
                          {presentationMode && (
                            <Button
                              onClick={() => {
                                setPresentationMode(false);
                              }}
                              icon={<FullscreenExitOutlined />}
                              type="primary">
                              Esci
                            </Button>
                          )}

                          {!presentationMode && (
                            <Button
                              type="primary"
                              icon={<DesktopOutlined />}
                              onClick={() => {
                                onPresentationModeActivate();
                              }}>
                              {t('a3Page.presenta')}
                            </Button>
                          )}
                        </>
                      )}
                    </>
                  ),
                }}></Tabs>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default IndexDetailsObAnno;

import { Comment } from '@ant-design/compatible';
import { Badge, Button, Dropdown, Empty, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { NotificationIcon } from '../../../../../assets/icons';
import LayoutService from '../../../../../services/layoutService';
import { feedRegardingObjectType } from '../../../../shared/utils/constants';
import '../header.scss';
import { retrieveNotificationList } from '../../../../../redux/slices/notificationsSlice';

const NotificationBell = () => {
  const dispatch = useDispatch();
  const notificationsList = useSelector((state) => state.notificationsList.notificationsList);
  const unreadNotifications = notificationsList.filter((obj) => !obj.isReadNotification).length;

  const updateNotificationsBell = async () => {
    try {
      const response = await LayoutService.updateNotificationBell();
      const resp = response.data;
      if (resp.success) {
        // Handle success
      } else {
        // Handle failure
      }
    } catch (error) {
      // Handle error
    }
  };

  const onVisible = (visible) => {
    if (visible) {
      if (unreadNotifications > 0) {
        updateNotificationsBell();
      }
    } else {
      if (unreadNotifications > 0) {
        dispatch(retrieveNotificationList());
      }
    }
  };

  const generateItems = (notificationsList) => {
    let items = [];

    if (notificationsList && notificationsList.length > 0) {
      notificationsList.map((item, index) => {
        switch (item.regardinObjectTypeCode) {
          case null:
            items.push(generateNotificationItem(item, index, '/home'));
            break;
          case feedRegardingObjectType.myActivity:
            items.push(generateNotificationItem(item, index, `/home/${item.regardingObjectID}/?tab=2`));
            break;
          case feedRegardingObjectType.project || 1:
            items.push(generateNotificationItem(item, index, `/progetti/id/${item.regardingObjectID}/?tab=11`));
            break;
          case feedRegardingObjectType.deliverable:
            items.push(generateNotificationItem(item, index, `/deliverables/id/${item.regardingObjectID}/`));
            break;
          case feedRegardingObjectType.kpi:
            items.push(generateNotificationItem(item, index, `/kpi/id/${item.regardingObjectID}`));
            break;
          case feedRegardingObjectType.annualGoal || 15:
            items.push(
              generateNotificationItem(item, index, `/obiettiviannuali/details/id/${item.regardingObjectID}/`),
            );
            break;
          default:
            items.push(generateNotificationItem(item, index, `/progetti/id/${item.regardingObjectID}`));
        }
      });
    } else {
      items.push({ label: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />, key: 'empty' });
    }

    return { items };
  };

  const generateNotificationItem = (item, index, path) => {
    return {
      label: (
        <Link
          to={{ pathname: path }}
          className="[&_.ant-comment]:tw-px-2 [&_.ant-comment]:tw-py-0 [&_.ant-comment]:tw-rounded-md">
          <Comment
            key={index}
            className={item.isReadNotification ? '' : 'isRead'}
            author={
              <Typography.Title
                className="!tw-my-0"
                level={5}>
                {item.subject}
              </Typography.Title>
            }
            content={
              <div className="tw-flex tw-flex-col">
                <span className="tw-text-xs tw-mb-2 first-letter:tw-uppercase tw-text-zinc-400">
                  {item.createdOn ? moment.utc(item.createdOn).fromNow() : ''}
                </span>
                <p className="tw-max-w-[400px] tw-text-pretty">{item.description}</p>
              </div>
            }
          />
        </Link>
      ),
      key: index,
    };
  };

  return (
    <div className="navbar-button">
      <Dropdown
        menu={generateItems(notificationsList)}
        placement="bottom"
        arrow
        destroyPopupOnHide
        autoAdjustOverflow
        rootClassName="[&_.ant-dropdown-menu]:tw-overflow-auto [&_.ant-dropdown-menu]:tw-max-h-[50vh] [&_.ant-dropdown-menu]:tw-border [&_.ant-dropdown-menu-item]:!tw-p-2 [&_.ant-dropdown-menu-item]:!tw-border-b"
        onOpenChange={onVisible}
        trigger={['click']}>
        <Button
          type="ghost"
          icon={
            <Badge
              count={unreadNotifications}
              overflowCount={10}>
              <NotificationIcon />
            </Badge>
          }
        />
      </Dropdown>
    </div>
  );
};

export default NotificationBell;

import { Card, Tabs } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import MatrixService from '../../services/matrix/matrixService';
import { addQueryParam, getQueryParam } from '../../utils/url-utils.js';
import TableLayout from '../shared/tableLayout';
import ObAnualBowlingChartList from './obAnualBowlingChart';
import BowlingChartList from './projectBowlingChart';

const BowlingChartIndex = (props) => {
  const [xmatrixRootList, setXmatrixRootList] = useState(null);
  const [loadingRootList, setLoadingRootList] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const { t } = useTranslation();

  useEffect(() => {
    setActiveTab(getQueryParam('tab') || '1');

    getXmatrixRootList();
  }, []);

  const getXmatrixRootList = async () => {
    setLoadingRootList(true);
    await MatrixService.getXmatrixDivisionRootList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setLoadingRootList(false);
          setXmatrixRootList(respData);
        } else {
          setLoadingRootList(false);
        }
      })
      .catch((error) => {
        setLoadingRootList(false);
      });
  };

  let tabsItems = [
    {
      label: <>{t('general.progetti')}</>,
      key: '1',
      children: (
        <BowlingChartList
          t={t}
          xmatrixRootList={xmatrixRootList}
          loadingRootList={loadingRootList}
        />
      ),
    },
    {
      label: <>{t('general.obiettiviAnno')}</>,
      key: '2',
      children: (
        <ObAnualBowlingChartList
          t={t}
          xmatrixRootList={xmatrixRootList}
          loadingRootList={loadingRootList}
        />
      ),
    },
  ];

  return (
    <Card>
      <TableLayout
        className="project-wrapper"
        title={t('bowlingChartPage.bowlingChart')}>
        <TableLayout.Content>
          <Tabs
            onChange={(key) => {
              addQueryParam('tab', key);
              setActiveTab(key);
            }}
            activeKey={activeTab}
            items={tabsItems}
            defaultActiveKey="1"></Tabs>
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

export default withTranslation()(BowlingChartIndex);

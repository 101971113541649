import { Avatar, Dropdown } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import '../header.scss';

class UserMenuAvatar extends React.Component {
  render() {
    const { userData, t } = this.props;

    const items = [
      // {
      //   label: <Link to={{ pathname: `/progettihoshinkanri` }}>{t('headerMenu.dashboardProgetti')}</Link>,
      //   key: 1,
      // },
      { label: <a href="/myprofile">{t('headerMenu.mioProfilo')}</a>, key: 2 },
      // { label: <Link to={{ pathname: `/mycompany` }}>{t('headerMenu.miaSocieta')}</Link>, key: 3 },
      {
        label: (
          <>
            <li
              className="tw-text-red-500"
              onClick={this.props.user.onSignOut}>
              {t('headerMenu.esci')}
            </li>
          </>
        ),
        key: 4,
      },
    ];

    return (
      <div className="navbar-button">
        {userData && (
          <Dropdown menu={{ items }}>
            <div className="!tw-flex tw-ml-4 tw-items-center tw-gap-2">
              {userData.fullName && (
                <p className="tw-block tw-leading-none tw-whitespace-nowrap tw-text-white">{userData.fullName}</p>
              )}
              {userData.profilePhoto && (
                <Avatar
                  size={'default'}
                  className="tw-bg-white tw-text-black"
                  src={userData.profilePhoto}
                />
              )}
              {!userData.profilePhoto && (
                <Avatar
                  size={'default'}
                  className="tw-bg-white tw-text-black">
                  {userData.userInitials}
                </Avatar>
              )}
            </div>
          </Dropdown>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(UserMenuAvatar));

import { ArrowRightOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import classNames from 'classnames';
import { FC } from 'react';

const A3BlueTitle: FC<{
  className?: string;
  title: string;
  href: string;
}> = ({ className, title, href }) => {
  return (
    <Button
      type="text"
      href={href}
      className={classNames('tw-text-xl -tw-ml-4 tw-flex tw-items-center', { className })}>
      <span className="tw-text-primary">{title}</span>
      <ArrowRightOutlined className="tw-text-primary" />
    </Button>
  );
};

export default A3BlueTitle;

import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { DeleteOutlined, LinkOutlined, SaveOutlined, SelectOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Row,
  Select,
  Space,
  Tabs,
  Tooltip,
  Typography,
} from 'antd';
import { useTranslation, withTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import CommonService from '../../services/commonService';
import CommitieService from '../../services/pages/commitieServices';
import Loader from '../shared/components/loader/loader';
import { formItemLayout } from '../shared/utils/constants';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';
import AddDefaultAgendaModal from './addDefaultAgendaModal';
import ComitieProjects from './comitieDashboard/comitieProjects';
import RelatedComities from './comitieDashboard/relatedComities';

const { Text, Link } = Typography;
const { Option } = Select;

function ViewEditCommitie(props) {
  const urlParams = useParams();
  const history = useHistory();
  const { t } = useTranslation();
  let commitieId = urlParams.id;

  const [participantList, setParticipantList] = useState(null);
  const [revisoriList, setRevisoriList] = useState(null);

  const [comitiesParticipantList, setComitiesParticipantList] = useState(null);
  const [commitieRevisoriList, setCommitieRevisoriList] = useState(null);

  const [commitieData, setCommitieData] = useState(null);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingSaveClose, setLoadingSaveClose] = useState(false);
  const [fieldsChanged, setFieldsChanged] = useState(false);

  const [standardAgendaModal, setStandardAgendaModal] = useState(false);

  useEffect(() => {
    getCommitie(commitieId);
    retrieveParticipants();
    retrieveRevisori();
  }, []);

  const redirectToCommitieDashboart = () => {
    history.push(`/committieDas/id/${commitieId}`);
  };

  const retrieveParticipants = async () => {
    await CommonService.getAvailableCommitteeMembers(commitieId, false)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          let usr = [];
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          setParticipantList(usr);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveRevisori = async () => {
    await CommonService.getAvailableCommitteeMembers(commitieId, true)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          let usr = [];
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          setRevisoriList(usr);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getCommitie = (id) => {
    CommitieService.getCommitieByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;

          let commRevisoriList = respData.revisori.filter((obj) => obj.isDeleted === false);
          setCommitieRevisoriList(commRevisoriList);

          let commParticipantList = respData.participants.filter((obj) => obj.isDeleted === false);
          setComitiesParticipantList(commParticipantList);

          setCommitieData(respData);
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  const updateData = (values, id) => {
    values['committeeID'] = id;

    setLoadingButton(true);
    CommitieService.updateCommitie(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setLoadingButton(false);
          getCommitie(id);
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingButton(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingButton(false);
      });
  };

  const removeCommittie = (id) => {
    CommitieService.deleteCommittie(id)
      .then((response) => {
        if (response.data.success) {
          message.success(notifyMessages.deleteSuccess);
          history.push('/committies');
        } else {
          message.error(notifyMessages.deleteCommitieFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteCommitieFailed);
      });
  };

  const linkLabel = (link) => {
    return (
      <Space direction="horizontal">
        {t('comitatiPage.repositoryLink')}
        {link && link !== '' && (
          <Tooltip title={t('comitatiPage.goToRepositoryLink')}>
            <LinkOutlined />{' '}
            <a
              href={link}
              target="_blank"
              rel="noopener noreferrer external">
              {' '}
              Link{' '}
            </a>
          </Tooltip>
        )}
      </Space>
    );
  };

  const addStandardAgenda = (values, closeModal) => {
    values['committeeID'] = commitieId;
    values['sessionID'] = null;
    values['agendaID'] = commitieData.agendaID;

    if (!closeModal) {
      setLoadingSave(true);
    }
    if (closeModal) {
      setLoadingSaveClose(true);
    }
    CommitieService.insertUpdateAgenda(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          if (!closeModal) {
            setLoadingSave(false);
          }
          if (closeModal) {
            setLoadingSaveClose(false);
          }
          if (closeModal) {
            setStandardAgendaModal(false);
          }
          getCommitie(commitieId);
        } else {
          message.error(notifyMessages.addFailed);
          if (!closeModal) {
            setLoadingSave(false);
          }
          if (closeModal) {
            setLoadingSaveClose(false);
          }
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        if (!closeModal) {
          setLoadingSave(false);
        }
        if (closeModal) {
          setLoadingSaveClose(false);
        }
      });
  };

  let tabItems = [
    {
      label: <Text>{t('comitatiPage.progettiCollegati')}</Text>,
      key: '1',
      children: (
        <ComitieProjects
          comitieId={commitieId}
          onlyView={false}
        />
      ),
    },
    {
      label: <Text>{t('comitatiPage.comitatiCollegati')}</Text>,
      key: '2',
      children: (
        <RelatedComities
          comitieId={commitieId}
          onlyView={false}
        />
      ),
    },
  ];

  const removeAgenda = (agendaId) => {
    CommitieService.deleteAgenda(agendaId)
      .then((response) => {
        if (response.data.success) {
          message.success(notifyMessages.deleteSuccess);
          getCommitie(commitieId);
        } else {
          message.error(notifyMessages.deleteCommitieFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteCommitieFailed);
      });
  };

  return (
    <div className="edit-commitie-wrapper">
      {standardAgendaModal && commitieData && (
        <AddDefaultAgendaModal
          showModal={standardAgendaModal}
          handelModalClose={() => setStandardAgendaModal(false)}
          saveDefaultAgenda={addStandardAgenda}
          t={t}
          committeeID={commitieId}
          loadingSave={loadingSave}
          loadingSaveClose={loadingSaveClose}
          commitieData={commitieData}
          agendaComitieId={commitieId}
          agendaSesionId={''}
          isDefault={true}
        />
      )}

      <Loader />

      {commitieData && (
        <>
          <div className="site-card-wrapper">
            <h3
              style={{ size: '50px' }}
              className="objective-item tw-mr-1">
              {commitieData.name}
            </h3>
            <span>- {t('comitatiPage.dettagliComitati')} </span>
          </div>

          <Card>
            <Form
              name="comitie_details"
              layout="vertical"
              {...formItemLayout}
              onFinish={(values) => updateData(values, commitieData.committeeID)}
              onValuesChange={() => {
                setFieldsChanged(true);
              }}>
              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 4 }}
                  sm={{ span: 4 }}
                  md={{ span: 4 }}
                  lg={{ span: 4 }}>
                  {/* <Button
                    onClick={() => history.goBack()}
                    className="backButton">
                    <RollbackOutlined />
                    {t('buttons.indietro')}
                  </Button> */}
                </Col>
                <Col
                  className="gutter-row"
                  xs={{ span: 20 }}
                  sm={{ span: 20 }}
                  md={{ span: 20 }}
                  lg={{ span: 20 }}>
                  <div className="button-wrapper">
                    {commitieData.usedInProject === 0 ? (
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Popconfirm
                          title={t('comitatiPage.eliminaComitato')}
                          onConfirm={() => removeCommittie(commitieData.committeeID)}
                          okText={t('general.si')}
                          cancelText={t('general.no')}>
                          <Button
                            type="primary"
                            className="tw-float-end"
                            icon={<DeleteOutlined />}>
                            {t('buttons.elimina')}
                          </Button>
                        </Popconfirm>
                      </Form.Item>
                    ) : (
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                          type="primary"
                          className="tw-float-end"
                          disabled={true}
                          onClick={() => removeCommittie(commitieData.committeeID)}>
                          <DeleteOutlined /> {t('buttons.elimina')}
                        </Button>
                      </Form.Item>
                    )}

                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button
                        loading={loadingButton}
                        type="primary"
                        className="tw-float-end"
                        disabled={!fieldsChanged}
                        htmlType="submit">
                        <SaveOutlined /> {t('buttons.salva')}
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <Card
                    title={t('general.informazioniGenerali')}
                    extra={
                      <Button onClick={() => redirectToCommitieDashboart()}>
                        {t('comitatiPage.goToComitieDashboard')} <SelectOutlined />
                      </Button>
                    }>
                    <Row
                      gutter={{ lg: 24 }}
                      className="mt10">
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('general.nome')}
                          name="name"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={commitieData.name}>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('comitatiPage.activitiesDuration')}
                          name="activitiesDuration"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={commitieData.activitiesDuration}>
                          <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={linkLabel(commitieData.repositoryLink)}
                          name="repositoryLink"
                          initialValue={commitieData.repositoryLink ? commitieData.repositoryLink : ''}
                          extra="Link format: http://exmaple.com/">
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      gutter={{ lg: 24 }}
                      className="mt10">
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('comitatiPage.participants')}
                          name="teamMember"
                          hasFeedback
                          // initialValue={(commitieData.participants && commitieData.participants.length > 0 ) ? commitieData.participants.map(a => a.userID) : []}
                          initialValue={
                            commitieData.participants && commitieData.participants.length > 0
                              ? comitiesParticipantList.map((a) => a.userID)
                              : []
                          }>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            mode="multiple"
                            allowClear
                            options={participantList}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            loading={!participantList}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          label={t('comitatiPage.revisori')}
                          name="revisoriMembers"
                          hasFeedback
                          // initialValue={(commitieData.revisori && commitieData.revisori.length > 0 )? commitieData.revisori.map(a => a.userID) : []}
                          initialValue={
                            commitieData.revisori && commitieData.revisori.length > 0
                              ? commitieRevisoriList.map((a) => a.userID)
                              : []
                          }>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            allowClear
                            mode="multiple"
                            options={revisoriList}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                            loading={!revisoriList}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 8 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item label={t('comitatiPage.verbaleStandart')}>
                          <Space
                            direction="horizontal"
                            style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <Link onClick={() => setStandardAgendaModal(true)}>
                              {t('comitatiPage.defaultAgendda')} <SelectOutlined />
                            </Link>

                            {commitieData.agendaID && (
                              <Popconfirm
                                title={t('comitatiPage.removeAgendaNotify')}
                                onConfirm={() => removeAgenda(commitieData.agendaID)}
                                okText={t('general.si')}
                                cancelText={t('general.no')}>
                                <Button
                                  type="dashed"
                                  icon={<DeleteOutlined />}>
                                  {t('comitatiPage.removeAgenda')}
                                </Button>
                              </Popconfirm>
                            )}
                          </Space>
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>

              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <Tabs
                    defaultActiveKey="1"
                    items={tabItems}
                  />
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      )}
    </div>
  );
}

export default withTranslation()(ViewEditCommitie);

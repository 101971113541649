import { trackPromise } from 'react-promise-tracker';
import { A3PageApi, authenticatedApi, UpdateImplementationSectionA3RequestDto } from 'src/config/connectors';

const Section3Service = {
  // get section 3 data
  getImplementationSection: async function (projectId: string) {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetImplementationSectionA3(projectId));
  },

  //update implementation section
  updateImplementation: async function (payload: UpdateImplementationSectionA3RequestDto) {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageUpdateImplementationSectionA3(payload));
  },

  getCompletedActivities: async function (projectId: string, implementationDaysOfNotice: number) {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetProjectCompletedActivitiesA3(projectId, implementationDaysOfNotice));
  },

  getFollowUpGraph: async function (projectId: string, kpiId: string) {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetFollowUpSectionA3(projectId, kpiId));
  },

  getKPIPicklist: async function (projectId: string) {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGetKPIPicklistA3(projectId));
  },
};

export default Section3Service;

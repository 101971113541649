import { Col, Divider, List, Row, Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import Riskervice from '../../../services/pages/projectServices/riskServices';
import Loader from '../../shared/components/loader/loader';
import { checkRiskSeverity, checkStatus } from '../../shared/utils/functions';

const rootStyle = { display: 'flex', alignItem: 'center' };
const rowStyle = {
  margin: '0 100px 0 0',
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  background: '#f5f5f5',
  borderRadius: '8px',
  width: '50%',
  textAlign: 'left',
};
const boxStyle = {
  background: '#f5f5f5',
  borderRadius: '8px',
  padding: '10px 0',
  marginLeft: '20px',
  fontSize: '16px',
};
const { Text } = Typography;

const Title = ({ title, description, size }) => {
  return (
    <div>
      <div
        style={{
          fontSize: size ? size : '16px',
          fontWeight: 'bold',
          textAlign: 'left',
        }}>
        <Tooltip title={description}>{title}</Tooltip>
      </div>
    </div>
  );
};

function Risks({ projectId, t, isPresentationMode, dynaFontClass }) {
  const [riskAndPrecautionList, setRiskAndPrecautionList] = useState([]);
  const [loadingData, setLoadingData] = useState(false);

  useEffect(() => {
    retrieveRiskAndPrecautionList(projectId);
  }, []);

  const retrieveRiskAndPrecautionList = (projectId) => {
    setLoadingData(true);
    Riskervice.getRiskAndPrecaution(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setRiskAndPrecautionList(respData);
          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch((error) => {
        setLoadingData(false);
      });
  };

  return (
    <div className={`${isPresentationMode ? dynaFontClass : ''}`}>
      <Row gutter={{ lg: 24 }}>
        <Col
          className="gutter-row tw-mb-4"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}>
          <Tooltip title={t('a3Page.criticitaRischiDescription')}>
            {!isPresentationMode ? (
              <span>{t('proggetiPage.criticitaRischi')}</span>
            ) : (
              <h1 className={'tw-mb-2 tw-font-semibold !tw-text-3xl !tw-mb-4'}>{t('proggetiPage.criticitaRischi')}</h1>
            )}
          </Tooltip>
          {/* <Title title={t('proggetiPage.criticitaRischi')} description={t('a3Page.criticitaRischiDescription')} /> */}
        </Col>
        <Col
          className="gutter-row tw-mb-4"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}>
          <Tooltip title={t('a3Page.contrimisureIdentificateDescription')}>
            {!isPresentationMode ? (
              <span>{t('proggetiPage.contromisureIdentificate')}</span>
            ) : (
              <h1 className={'tw-mb-2 tw-font-semibold !tw-text-3xl !tw-mb-4'}>
                {t('proggetiPage.contromisureIdentificate')}
              </h1>
            )}
          </Tooltip>
          {/* <Title title={t('proggetiPage.contromisureIdentificate')} description={t('a3Page.contrimisureIdentificateDescription')} /> */}
        </Col>
      </Row>

      {loadingData && <Loader />}

      {riskAndPrecautionList.map((critic, index) => (
        <Row
          className="row-list"
          gutter={2}
          key={index}>
          <Col
            className="gutter-row vertical-align"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}>
            <Col
              className={
                'gutter-row list-items tw-mb-4  risk-precaution-block' +
                (critic.status === 1 ? ' red' : '') +
                (critic.status === 2 ? ' green' : '')
              }
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <ArcherContainer
                strokeColor="red"
                className={`${isPresentationMode ? dynaFontClass : ''}`}>
                <div
                  className="arrow-root"
                  style={rootStyle}>
                  <ArcherElement
                    id="root"
                    relations={critic.precautions.map((el) => {
                      return {
                        targetId: el.precautionID,
                        targetAnchor: 'left',
                        sourceAnchor: 'right',
                        style: { endShape: 'circle', strokeColor: 'grey', strokeWidth: 1 },
                      };
                    })}>
                    <div style={rowStyle}>
                      {checkRiskSeverity(critic)}
                      <Divider type="vertical" />
                      {checkStatus(critic)}
                      <div>
                        <Text
                          className="text18"
                          style={{ padding: '0px 10px' }}>
                          {' '}
                          {critic.riskDescription}
                        </Text>
                      </div>
                    </div>
                  </ArcherElement>
                  {critic.precautions.length > 0 && critic.precautions && (
                    <List
                      bordered={false}
                      className="countermeasures"
                      dataSource={critic.precautions}
                      renderItem={(item) => (
                        <List.Item>
                          <div style={boxStyle}>
                            <ArcherElement id={item.precautionID}>
                              <div className="text18">
                                <Text style={{ padding: '0px 10px' }}> {item.precautionDescription}</Text>
                              </div>
                            </ArcherElement>
                          </div>
                        </List.Item>
                      )}
                    />
                  )}
                </div>
              </ArcherContainer>
            </Col>
          </Col>
        </Row>
      ))}
    </div>
  );
}

export default Risks;

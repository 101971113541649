import { Redirect, Route, Switch } from 'react-router-dom';
import A3Index from '../../components/a3/a3';
import CompanyProfile from '../../components/company/companyProfile';
import Dashboard from '../../components/dashboard/dashboard';
import KpiListPage from '../../components/kpiListPage/kpi';
import AppLayout from '../../components/layout/appLayout/appLayout';
import ObietiviAnno from '../../components/obietiviAnnoListPage/obietiviAnno';
import ObiettiviLongTerm from '../../components/obietiviLongTermListPage/obietiviLongTerm';
import UserProfile from '../../components/users/userProfile';
import Matrix from '../../components/xMatrix/matrix';
import { withAuth } from '../auth/auth';

import { connect } from 'react-redux';
import PresentationBase from '../../components/a3/presentationMode/presentationBase';
import PrintA3Page from '../../components/a3/printableA3/printA3';
import BowlingChartIndex from '../../components/bowlingChart';
import AddNewCommities from '../../components/commitie/addNew';
import ComitieDashboardIndex from '../../components/commitie/comitieDashboard';
import CommitieListPage from '../../components/commitie/commitie';
import ViewEditCommitie from '../../components/commitie/viewEditNew';
import AddNewCompany from '../../components/company/companyListPage/addNew';
import CompaniesListPage from '../../components/company/companyListPage/companies';
import EditCompanies from '../../components/company/companyListPage/viewEditModal';
import CompanyNotifications from '../../components/companyNotifications/companyNotifications';
import DeliverableIndex from '../../components/deliverables';
import AddNewDeliverable from '../../components/deliverables/deliverableForm/addNew';
import DeliverableList from '../../components/deliverables/deliverableForm/deliverablesList';
import AddNewDivision from '../../components/divisions/addNew';
import DivisionsListPage from '../../components/divisions/divisions';
import EditDivision from '../../components/divisions/viewEdit';
import HomePage from '../../components/home-dashboard/home-dashboard.tsx';
import KpiDashboard from '../../components/kpiDashboard/kpiDashboard';
import SingelKpiDetails from '../../components/kpiDashboard/singelKpiDetails/singelKpi';
import AddNewKpi from '../../components/kpiListPage/addNew';
import UploadKpiSpecifico from '../../components/kpiListPage/uploadKpiSpecifico';
import EditKpi from '../../components/kpiListPage/viewEdit';
import AddNewCategory from '../../components/listCategoryPage/addNew';
import listCategoryPage from '../../components/listCategoryPage/listCategoryPage';
import EditCategory from '../../components/listCategoryPage/viewEdit';
import AddNewObiettivoAnno from '../../components/obietiviAnnoListPage/addNew';
import IndexDetailsObAnno from '../../components/obietiviAnnoListPage/details/index';
import AddNewLongTermObjectives from '../../components/obietiviLongTermListPage/addNew';
import IndexDetailsObLungo from '../../components/obietiviLongTermListPage/details/index';
import PersonalDashboard from '../../components/personalDashbard/personalDashboard';
import AddNewProjectPage from '../../components/projects/addNew.jsx';
import EditProjectKpi from '../../components/projects/projectForm/deliverablesAndKpi/editKpi';
import NewDeliverable from '../../components/projects/projectForm/deliverablesAndKpi/newDeliverable';
import ProjectIndex from '../../components/projects/projectForm/index';
import ProjectListIndex from '../../components/projects/projectListViewPage/projectList';
import ProjectSintesi from '../../components/projects/projectsTimelineViewPage/projectsTimeline';
import ProjectTimePlanning from '../../components/projectTimePlanning/projectTimePlanning';
import ResourceWorkload from '../../components/resourceWorkload/resourceWorkload';
import SettingsPageIndex from '../../components/settings-page/index.jsx';
import NF404 from '../../components/shared/errorPages/404/404';
import { userRolesCodes } from '../../components/shared/utils/constants';
import UserIndexPage from '../../components/userListPage';
import UserTimePlanning from '../../components/userTimePlanning/userTimePlanning';
import SecondLevelXmatrix from '../../components/xMatrix/secondLevelXmatrix/secondLevelXmatrix';
import NewXmatrixCycle from '../../components/xmatrixCycle/addNewXmatrixCycle';
import ViewEditXmatrixCycle from '../../components/xmatrixCycle/viewEditXmatricCycle';
import XmatrixCycleListPage from '../../components/xmatrixCycle/xmatrixCycleListPage';
import { retrieveUserData } from 'src/redux/slices/userSlice';
import { useAppSelector } from 'src/redux/store';
import { Spin } from 'antd';

const AuthWrappedRoutes = ({ auth }) => {
  const userData = useAppSelector((state) => state.userData.userData);

  const randomGen = () => {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    return Array.from({ length: 20 }, () => characters.charAt(Math.floor(Math.random() * characters.length))).join('');
  };

  const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        if (!userData || !auth.isAuthenticated) return <Redirect to="/unauthorized" />;
        if (roles && !roles.includes(userData.roleCode)) return <Redirect to="/home" />;
        return (
          <Component
            {...props}
            requiredRoles={roles}
            currentUser={userData}
          />
        );
      }}
    />
  );

  return (
    <Route>
      <AppLayout data={auth}>
        <Switch>
          <Redirect
            exact
            from="/"
            to="/home"
          />
          <PrivateRoute
            path="/"
            exact
            component={HomePage}
          />
          <PrivateRoute
            path="/home/:acid?/:tab?/:company?"
            exact
            component={HomePage}
          />
          <PrivateRoute
            path="/progettihoshinkanri/:committee?"
            component={Dashboard}
          />
          <Route
            path="/a3/:id"
            exact
            component={A3Index}
          />
          <Route
            path="/a3/print/:id"
            exact
            component={PrintA3Page}
          />
          <Route
            path="/a3/presentation/:id"
            exact
            component={PresentationBase}
          />
          <PrivateRoute
            path="/mycompany"
            exact
            component={CompanyProfile}
          />
          <PrivateRoute
            path="/companies"
            exact
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin]}
            component={CompaniesListPage}
          />
          <PrivateRoute
            path="/companies/new"
            exact
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin]}
            component={AddNewCompany}
          />
          <Route
            path="/company/:id"
            exact
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin]}
            component={EditCompanies}
          />
          <PrivateRoute
            path="/divisions"
            exact
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin]}
            component={DivisionsListPage}
          />
          <Route
            path="/division/id/:id"
            component={EditDivision}
          />
          <PrivateRoute
            path="/divisions/new"
            exact
            component={AddNewDivision}
          />
          <PrivateRoute
            path="/deliverables"
            exact
            component={DeliverableList}
          />
          <Route
            path="/deliverables/id/:id/:cmpid?"
            render={(props) => (
              <DeliverableIndex
                {...props}
                key={randomGen()}
              />
            )}
          />
          <Route
            path="/deliverables/new/pid/:parentId?/:projectId?"
            component={AddNewDeliverable}
          />
          <PrivateRoute
            path="/committies"
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin]}
            exact
            component={CommitieListPage}
          />
          {/* <PrivateRoute
                path="/committiessp"
                exact
                component={CommitieListPageWithSecurity}
              /> */}
          <PrivateRoute
            path="/committie/new"
            exact
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin]}
            component={AddNewCommities}
          />
          <PrivateRoute
            path="/committie/id/:id"
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin]}
            component={ViewEditCommitie}
          />
          <Route
            path="/committieDas/id/:id"
            render={(props) => (
              <ComitieDashboardIndex
                {...props}
                key={randomGen()}
              />
            )}
          />
          <PrivateRoute
            path="/personalDashboard"
            component={PersonalDashboard}
          />
          <Redirect
            from="/myprofile"
            to={`profile`}
          />
          <Route
            path="/profile/:id?"
            component={UserProfile}
          />
          <PrivateRoute
            path="/xmatrix/:id?"
            roles={[
              userRolesCodes.techAdmin,
              userRolesCodes.lenovysAdmin,
              userRolesCodes.companyAdmin,
              userRolesCodes.steering,
              userRolesCodes.teamLeader,
              userRolesCodes.externalReviewer,
            ]}
            component={Matrix}
          />
          {/* Second Level Xmatrix */}
          {/* Second Level Xmatrix */}
          <PrivateRoute
            path="/indeep/id/:id"
            roles={[
              userRolesCodes.techAdmin,
              userRolesCodes.lenovysAdmin,
              userRolesCodes.companyAdmin,
              userRolesCodes.steering,
              userRolesCodes.teamLeader,
              userRolesCodes.externalReviewer,
            ]}
            component={SecondLevelXmatrix}
          />
          {/* Obiettivi Anno Page */}
          {/* Obiettivi Anno Page */}
          <PrivateRoute
            path="/obiettiviannuali"
            exact
            component={ObietiviAnno}
          />
          <PrivateRoute
            path="/obiettiviannuali/new"
            component={AddNewObiettivoAnno}
          />
          <Route
            path="/obiettiviannuali/details/id/:id/:tab?/:xmatrix?"
            render={(props) => (
              <IndexDetailsObAnno
                {...props}
                key={randomGen()}
              />
            )}
          />
          <PrivateRoute
            path="/obiettivialungoperiodo"
            exact
            component={ObiettiviLongTerm}
          />
          <Route
            path="/obiettivialungoperiodo/details/id/:id/:tab?/:xmatrix?"
            render={(props) => (
              <IndexDetailsObLungo
                {...props}
                key={randomGen()}
              />
            )}
          />
          <PrivateRoute
            path="/obiettivialungoperiodo/new"
            component={AddNewLongTermObjectives}
          />
          <PrivateRoute
            path="/kpi"
            exact
            component={KpiListPage}
          />
          <Route
            path="/kpi/id/:id"
            render={(props) => (
              <EditKpi
                {...props}
                key={randomGen()}
              />
            )}
          />
          <PrivateRoute
            path="/kpi/new"
            component={AddNewKpi}
          />
          <PrivateRoute
            path="/kpi/specifico/upload"
            exact
            component={UploadKpiSpecifico}
          />
          <PrivateRoute
            path="/kpiDashboard/:tab?/:projectId?/:annualId?"
            exact
            component={KpiDashboard}
          />
          <PrivateRoute
            path="/kpiDashboard/kdid/:kdid/:pid?/:aid?/:xmatrix?/:year?/:month?/:type?/"
            exact
            component={SingelKpiDetails}
          />
          <PrivateRoute
            path="/utenti"
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin]}
            component={UserIndexPage}
          />
          <PrivateRoute
            path="/categorie"
            exact
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin]}
            component={listCategoryPage}
          />
          <Route
            path="/categorie/id/:id"
            exact
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin]}
            component={EditCategory}
          />
          <PrivateRoute
            path="/categorie/new"
            exact
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin]}
            component={AddNewCategory}
          />
          <PrivateRoute
            path="/progetti"
            exact
            component={ProjectListIndex}
          />
          <PrivateRoute
            path="/progetti/new/:xmid?"
            component={AddNewProjectPage}
          />
          <Route
            path="/progetti/:id/deliverable/new"
            component={NewDeliverable}
          />
          <Route
            path="/progetti/:projectID/kpi/id/:kpiid"
            component={EditProjectKpi}
          />
          <Route
            path="/progetti/id/:id/:cmpid?"
            render={(props) => (
              <ProjectIndex
                {...props}
                key={randomGen()}
              />
            )}
          />
          <Route
            path="/projectSintesi"
            exact
            component={ProjectSintesi}
          />
          <PrivateRoute
            path="/resources"
            exact
            component={ResourceWorkload}
          />
          <PrivateRoute
            path="/timeReport"
            exact
            component={UserTimePlanning}
          />
          <PrivateRoute
            path="/projectTimePlanning"
            exact
            roles={[
              userRolesCodes.techAdmin,
              userRolesCodes.lenovysAdmin,
              userRolesCodes.companyAdmin,
              userRolesCodes.steering,
              userRolesCodes.teamLeader,
              userRolesCodes.externalReviewer,
            ]}
            component={ProjectTimePlanning}
          />
          <PrivateRoute
            path="/bowlingchart"
            exact
            component={BowlingChartIndex}
          />
          <PrivateRoute
            path="/notifications"
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin]}
            exact
            component={CompanyNotifications}
          />
          <PrivateRoute
            path="/strategicCycle"
            roles={[userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin]}
            exact
            component={XmatrixCycleListPage}
          />
          <Route
            path="/strategicCycle/id/:id"
            render={(props) => (
              <ViewEditXmatrixCycle
                {...props}
                key={randomGen()}
              />
            )}
          />
          <PrivateRoute
            path="/strategicCycle/new"
            component={NewXmatrixCycle}
          />
          <PrivateRoute
            path="/settings"
            component={SettingsPageIndex}
          />
          <PrivateRoute component={NF404} />
        </Switch>
      </AppLayout>
    </Route>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withAuth(connect(mapStateToProps)(AuthWrappedRoutes));

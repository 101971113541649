import { EditOutlined } from '@ant-design/icons';
import { Button, message, Table, Tooltip } from 'antd';
import classNames from 'classnames';
import React, { Component } from 'react';
import ObKpiService from '../../../../services/matrix/obKpiService';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';
import KpiDetails from './kpiDetailsModal';

class Kpi extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      loadingButton: false,
      modalData: {},
    };
  }

  columns = [
    {
      key: 'combined',
      render: (text, record, index) => {
        return {
          children: (
            <div
              onClick={() => this.props.onRowClick(record.kpiid)}
              className={`tw-flex tw-py-1 matrix-row tw-w-[32px] tw-items-center tw-justify-between ${classNames({
                'tw-bg-primary selected-row':
                  this.props.selection === 'primary' && this.props.selectedIds?.includes(record.kpiid),
                'tw-bg-primary/30 selected-row':
                  this.props.selection === 'secondary' && this.props.selectedIds?.includes(record.kpiid),
              })}`}>
              <Tooltip title={record.title}>
                <div className="tw-flex tw-items-center tw-truncate">
                  <p className="tw-text-sm tw-w-full tw-truncate">{record.title}</p>
                </div>
              </Tooltip>

              <Button
                className="tw-rotate-180"
                type="text"
                icon={<EditOutlined />}
                onClick={() => this.toggleModal(record)}></Button>
            </div>
          ),
        };
      },
    },
  ];

  onRemoveKpi = (id) => {
    this.setState({ loadingButton: false });
    const { selectedXmatrix } = this.props;
    ObKpiService.removeKpi(id, selectedXmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.handleModalCancel();
          this.props.reloadMatrix();
        } else {
          this.handleModalCancel();
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        this.handleModalCancel();
        message.error(notifyMessages.deleteFailed);
      });
  };

  handleModalCancel = () => {
    this.setState({ openModal: false });
    this.setState({ loadingButton: false });
  };

  toggleModal = (rowData) => {
    this.setState({ openModal: !this.state.openModal });
    this.setState({ modalData: rowData });
  };

  render() {
    const { kpi, t } = this.props;
    const { loadingButton, openModal } = this.state;

    return (
      <>
        <KpiDetails
          show={openModal}
          toggle={this.toggleModal}
          handleModalCancel={this.handleModalCancel}
          deleteKpi={this.onRemoveKpi}
          data={this.state.modalData}
          loadingButton={loadingButton}
          t={t}
        />

        <Table
          className="[&_table]:tw-h-full"
          dataSource={kpi}
          pagination={false}
          size="small"
          bordered
          showHeader={false}
          columns={this.columns}
          rowKey={(obj) => obj.kpiid}></Table>
      </>
    );
  }
}

export default Kpi;

import { EditOutlined, FormOutlined, InboxOutlined, SyncOutlined } from '@ant-design/icons';
import { Button, Card, Col, Popconfirm, Row, Segmented, Space, Tooltip, Typography, message } from 'antd';
import BraftEditor from 'braft-editor';
import Parser from 'html-react-parser';
import moment from 'moment';
import { useEffect, useState } from 'react';
import FontSizeChanger from 'react-font-size-changer';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { useTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import motivoPerAgire from '../../../assets/motivoPerAgire.jpg';
import risultatoAteso from '../../../assets/risultatoAteso.jpg';
import FeedService from '../../../services/pages/projectServices/feedServices';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import ProjectDataHeader from '../../projects/projectDataHeader';
import EditorModal from '../../projects/projectForm/mainInfo/richTextEditorModal';
import ProjectTeamMembers from '../../projects/projectForm/mainInfo/teamMembers/teamMembers';
import ProjectFeed from '../../projects/projectForm/projectFeed/projectFeed';
import ProjectFiles from '../../projects/projectForm/projectFiles/projectFiles';
import Loader from '../../shared/components/loader/loader';
import {
  userHasAccessProjectFields,
  userIsAdminOrProjectTeam,
} from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { feedObjectType, feedRegardingObjectType, projectStatus } from '../../shared/utils/constants';
import { SegmentTitle, projectFieldEditorObjectUpdate } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import '../mainA3.scss';
import '../presentationMode/presentationMode.scss';
import IndicatoriTarget from '../section1/indicatoriTarget';
import Section2 from '../section2/section2';
import AttivitaSvolte from '../section3/attivitaSvolte';
import ProssimiPassi from '../section4/prossimiPassi';
import Risks from '../section4/risks';
import './fontSizeZoom.scss';

const { Text } = Typography;

function PresentationBase(props) {
  const urlParams = useParams();
  const userData = useSelector((state) => state.userData.userData);
  const companyData = useSelector((state) => state.companyData.companyData);
  const { t } = useTranslation();
  let languageCode = props.i18n.language;

  const [projectData, setProjectData] = useState(null);
  const [currentTabLoaded, setCurrentTabLoaded] = useState('tab1');
  const [editorModal, setEditorModal] = useState(false);
  const [loadingEditorButton, setLoadingEditorButton] = useState(false);
  const [fieldObject, setFieldObject] = useState(null);
  const [loadingArchive, setLoadingArchive] = useState(false);
  const [sectionFieldChanged, setSectionFieldChanged] = useState(false);
  const [dynamicFontSize, setDynamicFontSize] = useState(20);
  const [dynamicFontClass, setDynamicFontClass] = useState('dynaFontSize20');
  const [teamMembers, setTeamMembers] = useState(null);
  const [loadingTeamMembers, setLoadingTeamMembers] = useState(false);

  let hasUserAccess =
    projectData && userData
      ? !userHasAccessProjectFields(userData) && projectData.status === projectStatus.completato
        ? true
        : false
      : null;

  useEffect(() => {
    if (props.takeAsBlackBox) {
      loadOnPresentationSection(props.takeAsBlackBox);
    }

    getProjectData(urlParams.id);
    getTeamMembers(urlParams.id);
  }, []);

  const getTeamMembers = (id) => {
    setLoadingTeamMembers(true);
    ProgettiService.getProjectTeamMembers(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setTeamMembers(resp.responseObject.value);
          setLoadingTeamMembers(false);
        } else {
          setLoadingTeamMembers(false);
        }
      })
      .catch((error) => {
        setLoadingTeamMembers(false);
      });
  };

  const getProjectData = (id) => {
    ProgettiService.getProgettiByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setProjectData(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const loadOnPresentationSection = (tab) => {
    if (tab === 'tab7') {
      let url = `/kpiDashboard/1/${urlParams.id}`;
      window.open(url, '_blank');
    }
    setCurrentTabLoaded(tab);
  };

  const toggleEditorModal = (fieldData) => {
    setEditorModal(!editorModal);
    setFieldObject(fieldData);
  };

  const handelEditorClose = () => {
    setEditorModal(false);
  };

  const updateFieldToObject = (values) => {
    setSectionFieldChanged(true);
    var fieldName = Object.keys(values);
    let editorContentToHtml = BraftEditor.createEditorState(values[fieldName]).toHTML();
    let prData = projectData;
    prData[fieldName] = editorContentToHtml;
    setProjectData(prData);
    updateProjectEditorFields(values, fieldName, editorContentToHtml);
  };

  const updateProjectEditorFields = (values, fieldName, editorContentToHtml) => {
    setLoadingEditorButton(true);
    let payloadObj = {};
    payloadObj['projectId'] = projectData.projectID;
    payloadObj['projectFieldName'] = fieldName[0];
    payloadObj['projectFieldValue'] = editorContentToHtml;

    ProgettiService.updateEditorField(payloadObj)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setLoadingEditorButton(false);
          handelEditorClose();
        } else {
          setLoadingEditorButton(false);
        }
      })
      .catch((error) => {
        setLoadingEditorButton(false);
      });
  };

  const resetSectionValueAfterArchive = (sectionKey) => {
    setSectionFieldChanged(true);
    var fieldName = [sectionKey];
    let editorContentToHtml = null;
    let prData = projectData;
    prData[fieldName] = editorContentToHtml;
    setProjectData(prData);
    updateProjectEditorFields('', fieldName, editorContentToHtml);
  };

  const archiveSection = (sectionName, sectionValue, sectionKey) => {
    let dataObj = {};
    let currentDate = moment().format('DD/MM/YYYY');
    dataObj['createdBy'] = userData.userId;
    dataObj['description'] = sectionValue;
    dataObj['priority'] = false;
    dataObj['regardingObjectID'] = urlParams.id;
    dataObj['regardingObjectTypeCode'] = feedRegardingObjectType.project;
    dataObj['subject'] = (sectionName + ' - ' + currentDate).toString();

    setLoadingArchive(true);
    FeedService.addFeedComment(dataObj)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          resetSectionValueAfterArchive(sectionKey);
          setLoadingArchive(false);
        } else {
          message.error(notifyMessages.addFailed);
          setLoadingArchive(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        setLoadingArchive(false);
      });
  };

  const checkProjectEndDateValidity = (endDate) => {
    if (endDate) {
      let currentDate = moment().endOf('day');
      let projectEndDate = moment(endDate).endOf('day');

      if (projectEndDate < currentDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  const handleFullscreen = useFullScreenHandle();

  return (
    <>
      <EditorModal
        show={editorModal}
        handelEditorClose={handelEditorClose}
        handelEditorSave={updateFieldToObject}
        data={fieldObject}
        loadingEditorButton={loadingEditorButton}
      />

      {!projectData && <Loader />}

      {projectData && (
        <>
          <div className={props.takeAsBlackBox ? 'tw-hidden' : ''}>
            <ProjectDataHeader
              enterFullScreen={handleFullscreen.enter}
              t={t}
              id={projectData.projectID}
              isPresentationMode={true}
              currentProjectData={projectData}
              location={'presentation'}
            />
          </div>

          <FullScreen handle={handleFullscreen}>
            <div className="mainA3 a3-main presentation-mode">
              <div className={'presSegment' + props.takeAsBlackBox ? 'tw-hidden' : ''}>
                <Row
                  className="mb-row"
                  gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row"
                    xs={{ span: 22 }}
                    sm={{ span: 22 }}
                    md={{ span: 22 }}
                    lg={{ span: 22 }}>
                    <Segmented
                      style={{ width: '100%' }}
                      block
                      value={props.takeAsBlackBox ?? 'tab1'}
                      onChange={(value) => loadOnPresentationSection(value)}
                      options={[
                        {
                          value: 'tab1',
                          label: <SegmentTitle title={t('proggetiPage.motivoAgire')} />,
                        },
                        {
                          value: 'tab2',
                          label: <SegmentTitle title={t('proggetiPage.risultatoAtteso')} />,
                        },
                        {
                          value: 'tab13',
                          label: <SegmentTitle title={t('proggetiPage.teamMembers')} />,
                        },
                        {
                          value: 'tab3',
                          label: <SegmentTitle title={t('general.kpi')} />,
                        },
                        {
                          value: 'tab4',
                          label: <SegmentTitle title={t('a3Page.masterPlanLower')} />,
                        },
                        {
                          value: 'tab5',
                          label: <SegmentTitle title={t('proggetiPage.prsFilter')} />,
                        },
                        {
                          value: 'tab6',
                          label: <SegmentTitle title={t('proggetiPage.attivitaSvolte')} />,
                        },
                        {
                          value: 'tab7',
                          label: <SegmentTitle title={t('proggetiPage.kpiStatus')} />,
                        },
                        {
                          value: 'tab8',
                          label: <SegmentTitle title={t('proggetiPage.prossimiPassi')} />,
                        },
                        {
                          value: 'tab9',
                          label: <SegmentTitle title={t('proggetiPage.criticitaRischi')} />,
                        },
                        {
                          value: 'tab10',
                          label: <SegmentTitle title={t('proggetiPage.hensei')} />,
                        },
                        {
                          value: 'tab11',
                          label: <SegmentTitle title={t('proggetiPage.file')} />,
                        },
                        {
                          value: 'tab12',
                          label: <SegmentTitle title={t('general.commenti')} />,
                        },
                      ]}
                    />
                  </Col>

                  <Col
                    className="gutter-row sizeChangerButtons"
                    xs={{ span: 2 }}
                    sm={{ span: 2 }}
                    md={{ span: 2 }}
                    lg={{ span: 2 }}>
                    <FontSizeChanger
                      targets={['.presContent']}
                      onChange={(element, newValue, oldValue) => {
                        setDynamicFontSize(newValue);
                        setDynamicFontClass('dynaFontSize' + newValue.toString());
                      }}
                      options={{
                        stepSize: 2,
                        range: 8,
                      }}
                      customButtons={{
                        up: (
                          <Button
                            disabled={dynamicFontSize < 36 ? false : true}
                            shape="circle">
                            {' '}
                            A+{' '}
                          </Button>
                        ),
                        down: (
                          <Button
                            disabled={dynamicFontSize > 20 ? false : true}
                            shape="circle">
                            {' '}
                            A-{' '}
                          </Button>
                        ),
                        style: {
                          border: 'none',
                          WebkitBoxSizing: 'none',
                        },
                      }}
                    />
                  </Col>
                </Row>
              </div>

              <Row
                className="mb-row a3-main-body presContent"
                gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  {currentTabLoaded === 'tab1' && (
                    <Card
                      className={`${dynamicFontClass} ` + ' zoomSecHeight'}
                      title={t('proggetiPage.motivoAgire')}
                      extra={
                        !hasUserAccess ? (
                          <FormOutlined
                            style={{ color: '#989898' }}
                            onClick={() =>
                              toggleEditorModal(
                                projectFieldEditorObjectUpdate('groundtoAct', projectData.groundtoAct, t),
                              )
                            }
                          />
                        ) : (
                          ''
                        )
                      }>
                      <Row>
                        <Col
                          xs={{ span: 12 }}
                          sm={{ span: 6 }}
                          md={{ span: 6 }}
                          lg={{ span: 6 }}>
                          <img
                            src={motivoPerAgire}
                            width="100%"
                            alt="Image"
                          />
                        </Col>
                        <Col
                          className="textSt"
                          xs={{ span: 12 }}
                          sm={{ span: 18 }}
                          md={{ span: 18 }}
                          lg={{ span: 18 }}>
                          <Text className={dynamicFontClass}>
                            {Parser(projectData.groundtoAct ? projectData.groundtoAct : '')}
                          </Text>
                        </Col>
                      </Row>
                    </Card>
                  )}

                  {currentTabLoaded === 'tab2' && (
                    <Card
                      className={`${dynamicFontClass} ` + ' zoomSecHeight'}
                      title={t('proggetiPage.risultatoAtteso')}
                      extra={
                        !hasUserAccess ? (
                          <FormOutlined
                            style={{ color: '#989898' }}
                            onClick={() =>
                              toggleEditorModal(
                                projectFieldEditorObjectUpdate('projectGoal', projectData.projectGoal, t),
                              )
                            }
                          />
                        ) : (
                          ''
                        )
                      }>
                      <Row>
                        <Col
                          xs={{ span: 12 }}
                          sm={{ span: 6 }}
                          md={{ span: 6 }}
                          lg={{ span: 6 }}>
                          <img
                            src={risultatoAteso}
                            width="100%"
                            alt="Image"
                          />
                        </Col>
                        <Col
                          className="textSt"
                          xs={{ span: 12 }}
                          sm={{ span: 18 }}
                          md={{ span: 18 }}
                          lg={{ span: 18 }}>
                          <Text className={dynamicFontClass}>
                            {' '}
                            {Parser(projectData.projectGoal ? projectData.projectGoal : '')}{' '}
                          </Text>
                        </Col>
                      </Row>
                    </Card>
                  )}

                  {currentTabLoaded === 'tab13' && (
                    <Card className={`${dynamicFontClass} `}>
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row no-padding sub700-pres-card-body"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <ProjectTeamMembers
                            t={t}
                            projectId={projectData.projectID}
                            teamMembers={teamMembers}
                            isDataLoading={loadingTeamMembers}
                            hasUserAccess={hasUserAccess}
                            isAdminOrProjectTeam={
                              !userIsAdminOrProjectTeam(userData, projectData.teamLeaderID, projectData.sponsorID)
                            }
                            checkProjectEndDateValidity={checkProjectEndDateValidity(projectData.endDate)}
                            reloadUsers={() => getTeamMembers(projectData.projectID)}
                            dynaFontClass={dynamicFontClass}
                          />
                        </Col>
                      </Row>
                    </Card>
                  )}

                  {currentTabLoaded === 'tab3' && (
                    <Card
                      title={t('proggetiPage.risultatoAtteso')}
                      className={`${dynamicFontClass} ` + ' section section-one-card presTable'}>
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row no-padding sub700-pres-card-body"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <IndicatoriTarget
                            t={t}
                            projectId={projectData.projectID}
                            isPresentationMode={true}
                            dynaFontClass={dynamicFontClass}
                          />
                        </Col>
                      </Row>
                    </Card>
                  )}

                  {currentTabLoaded === 'tab4' && (
                    <Card
                      title={t('a3Page.masterPlan')}
                      className={`${dynamicFontClass} ` + ' section section-two-card'}>
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row no-padding sub700-pres-card-body"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <Section2
                            className="presTable"
                            currentProject={projectData}
                            t={t}
                            languageCode={languageCode}
                            id={projectData.projectID}
                            hasUserAccess={hasUserAccess}
                            isPresentationMode={true}
                            dynaFontClass={dynamicFontClass}
                            userData={userData}
                            showGantt={true}
                            showPrs={false}
                          />
                        </Col>
                      </Row>
                    </Card>
                  )}

                  {currentTabLoaded === 'tab5' && (
                    <Card
                      title={t('a3Page.projectReviewSystem')}
                      className={`${dynamicFontClass} ` + ' section section-two-card'}>
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row no-padding sub700-pres-card-body"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <Section2
                            className="presTable"
                            currentProject={projectData}
                            t={t}
                            languageCode={languageCode}
                            id={projectData.projectID}
                            hasUserAccess={hasUserAccess}
                            isPresentationMode={true}
                            userData={userData}
                            showGantt={false}
                            showPrs={true}
                          />
                        </Col>
                      </Row>
                    </Card>
                  )}

                  {currentTabLoaded === 'tab6' && (
                    <Card className="sub700-pres-card-body">
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row"
                          xs={{ span: 12 }}
                          sm={{ span: 18 }}
                          md={{ span: 18 }}
                          lg={{ span: 18 }}>
                          {companyData && (
                            <Card
                              title={t('a3Page.statoAavanzamento')}
                              className={`${dynamicFontClass} ` + ' custom-card sub200-card-body presTable'}>
                              <AttivitaSvolte
                                t={t}
                                projectId={projectData.projectID}
                                companyData={companyData}
                                isPresentationMode={true}
                                dynaFontClass={dynamicFontClass}
                              />
                            </Card>
                          )}
                        </Col>
                        <Col
                          className="gutter-row "
                          xs={{ span: 12 }}
                          sm={{ span: 6 }}
                          md={{ span: 6 }}
                          lg={{ span: 6 }}>
                          <Card
                            title={t('a3Page.commentiS3')}
                            className={`${dynamicFontClass} ` + ' custom-card minH300'}
                            extra={
                              !hasUserAccess ? (
                                <Space direction="horizontal">
                                  <EditOutlined
                                    style={{ margin: '0 5px' }}
                                    key={1}
                                    onClick={() =>
                                      toggleEditorModal(
                                        projectFieldEditorObjectUpdate(
                                          'finishedActivitiesNote',
                                          projectData.finishedActivitiesNote,
                                          t,
                                        ),
                                      )
                                    }
                                  />

                                  {projectData.finishedActivitiesNote &&
                                    projectData.finishedActivitiesNote.length > 0 && (
                                      <Popconfirm
                                        title={t('a3Page.archiveSectionConfirm')}
                                        onConfirm={() =>
                                          archiveSection(
                                            t('a3Page.commentiS3'),
                                            projectData.finishedActivitiesNote,
                                            'finishedActivitiesNote',
                                          )
                                        }
                                        okText={t('general.si')}
                                        cancelText={t('general.no')}>
                                        <Tooltip title={t('a3Page.archiveSection')}>
                                          {!loadingArchive && (
                                            <InboxOutlined
                                              style={{ margin: '0 5px' }}
                                              key={2}
                                            />
                                          )}
                                          {loadingArchive && <SyncOutlined spin />}
                                        </Tooltip>
                                      </Popconfirm>
                                    )}
                                </Space>
                              ) : (
                                ''
                              )
                            }>
                            <Text className={dynamicFontClass}>
                              {' '}
                              {Parser(projectData.finishedActivitiesNote ? projectData.finishedActivitiesNote : '')}
                            </Text>
                          </Card>
                        </Col>
                      </Row>
                    </Card>
                  )}

                  {currentTabLoaded === 'tab8' && (
                    <Card
                      className={
                        'sub700-pres-card-body' + props.takeAsBlackBox
                          ? '!tw-border-none !tw-shadow-none [&_.ant-card-body]:!tw-border-none'
                          : ''
                      }>
                      <Row
                        gutter={{ lg: 24 }}
                        className={
                          'sub700-pres-card-body' + props.takeAsBlackBox
                            ? '!tw-border-none !tw-shadow-none tw-divide-x'
                            : ''
                        }>
                        <Col
                          className="gutter-row"
                          xs={{ span: 12 }}
                          sm={{ span: 18 }}
                          md={{ span: 18 }}
                          lg={{ span: 18 }}>
                          {companyData && (
                            <Card
                              title={
                                <h2 className={props.takeAsBlackBox ? 'tw-font-semibold !tw-text-3xl !tw-mb-4' : ''}>
                                  {t('a3Page.prossimiPasiDecisioniRichieste')}
                                </h2>
                              }
                              className={
                                `${dynamicFontClass} ` + ' presTable' + props.takeAsBlackBox
                                  ? '!tw-border-none !tw-shadow-none [&_.ant-card-body]:!tw-border-none'
                                  : ''
                              }>
                              <ProssimiPassi
                                t={t}
                                projectId={projectData.projectID}
                                companyData={companyData}
                                isPresentationMode={true}
                                dynaFontClass={dynamicFontClass}
                              />
                            </Card>
                          )}
                        </Col>
                        <Col
                          className="gutter-row "
                          xs={{ span: 12 }}
                          sm={{ span: 6 }}
                          md={{ span: 6 }}
                          lg={{ span: 6 }}>
                          <Card
                            title={<h2 className="tw-text-2xl tw-flex-1 tw-font-medium">{t('a3Page.commentiS4')}</h2>}
                            className={
                              `${dynamicFontClass} ` + ' custom-card minH300' + props.takeAsBlackBox
                                ? '!tw-border-none !tw-shadow-none [&_.ant-card-body]:!tw-border-none'
                                : ''
                            }
                            extra={
                              !hasUserAccess ? (
                                <Space direction="horizontal">
                                  <EditOutlined
                                    onClick={() =>
                                      toggleEditorModal(
                                        projectFieldEditorObjectUpdate('nextStepsNote', projectData.nextStepsNote, t),
                                      )
                                    }
                                  />
                                  {projectData.nextStepsNote && projectData.nextStepsNote.length > 0 && (
                                    <Popconfirm
                                      title={t('a3Page.archiveSectionConfirm')}
                                      onConfirm={() =>
                                        archiveSection(
                                          t('a3Page.commentiS4'),
                                          projectData.nextStepsNote,
                                          'nextStepsNote',
                                        )
                                      }
                                      okText={t('general.si')}
                                      cancelText={t('general.no')}>
                                      <Tooltip title={t('a3Page.archiveSection')}>
                                        {!loadingArchive && (
                                          <InboxOutlined
                                            style={{ margin: '0 5px' }}
                                            key={2}
                                          />
                                        )}
                                        {loadingArchive && <SyncOutlined spin />}
                                      </Tooltip>
                                    </Popconfirm>
                                  )}
                                </Space>
                              ) : (
                                ''
                              )
                            }>
                            <Text className={dynamicFontClass}>
                              {' '}
                              {Parser(projectData.nextStepsNote ? projectData.nextStepsNote : '')}
                            </Text>
                          </Card>
                        </Col>
                      </Row>
                    </Card>
                  )}

                  {currentTabLoaded === 'tab9' && (
                    <Card
                      title=""
                      className="section zoomSecHeight">
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row no-padding"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <Risks
                            className="sub700-pres-card-body"
                            t={t}
                            projectId={projectData.projectID}
                            isPresentationMode={true}
                            dynaFontClass={dynamicFontClass}
                          />
                        </Col>
                      </Row>
                    </Card>
                  )}

                  {currentTabLoaded === 'tab11' && (
                    <Row gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row no-padding presTable"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <div className="filesSecHeight">
                          <ProjectFiles
                            isPresentationMode={true}
                            dynaFontClass={dynamicFontClass}
                            t={t}
                            projectId={projectData.projectID}
                            currentProject={projectData}
                          />
                        </div>
                      </Col>
                    </Row>
                  )}

                  {currentTabLoaded === 'tab12' && (
                    <Row
                      gutter={{ lg: 24 }}
                      className="sub700-pres-card-body zoomSecHeight">
                      <Col
                        className="gutter-row no-padding"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <ProjectFeed
                          isPresentationMode={true}
                          dynaFontClass={dynamicFontClass}
                          objectId={projectData.projectID}
                          userData={userData}
                          isProject={1}
                          hasUserAccess={hasUserAccess}
                          objectType={feedObjectType.projectFeed}
                          feedRegardingObjType={feedRegardingObjectType.project}
                          showFeed={false}
                        />
                      </Col>
                    </Row>
                  )}

                  {currentTabLoaded === 'tab10' && (
                    <Card
                      className={
                        'sub700-pres-card-body zoomSecHeight' + props.takeAsBlackBox
                          ? '!tw-border-none !tw-shadow-none [&_.ant-card-body]:!tw-border-none'
                          : ''
                      }>
                      <Row
                        gutter={{ lg: 24 }}
                        className={
                          'sub700-pres-card-body' + props.takeAsBlackBox ? '!tw-border-none !tw-shadow-none' : ''
                        }>
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 12 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Card
                            title={
                              !props.takeAsBlackBox ? (
                                t('proggetiPage.henseiLezioniImparate')
                              ) : (
                                <h1 className={'tw-font-semibold !tw-text-3xl !tw-mb-4'}>
                                  {t('proggetiPage.henseiLezioniImparate')}
                                </h1>
                              )
                            }
                            className={
                              `${dynamicFontClass} ` + ' custom-card card-pres minH300' + props.takeAsBlackBox
                                ? '!tw-border-none !tw-shadow-none [&_.ant-card-body]:!tw-border-none'
                                : ''
                            }
                            extra={
                              !hasUserAccess ? (
                                <EditOutlined
                                  onClick={() =>
                                    toggleEditorModal(
                                      projectFieldEditorObjectUpdate(
                                        'hanseiLearnedLessons',
                                        projectData.hanseiLearnedLessons,
                                        t,
                                      ),
                                    )
                                  }
                                />
                              ) : (
                                ''
                              )
                            }>
                            <Text className={dynamicFontClass}>
                              {Parser(projectData.hanseiLearnedLessons ? projectData.hanseiLearnedLessons : '')}
                            </Text>
                          </Card>
                        </Col>
                        <Col
                          className="gutter-row "
                          xs={{ span: 24 }}
                          sm={{ span: 12 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Card
                            title={
                              !props.takeAsBlackBox ? (
                                t('proggetiPage.beneficiOtenuti')
                              ) : (
                                <h1 className={'tw-font-semibold !tw-text-3xl !tw-mb-4'}>
                                  {t('proggetiPage.beneficiOtenuti')}
                                </h1>
                              )
                            }
                            className={
                              `${dynamicFontClass} ` + ' custom-card card-pres minH300' + props.takeAsBlackBox
                                ? '!tw-border-none !tw-shadow-none [&_.ant-card-body]:!tw-border-none'
                                : ''
                            }
                            extra={
                              !hasUserAccess ? (
                                <EditOutlined
                                  onClick={() =>
                                    toggleEditorModal(
                                      projectFieldEditorObjectUpdate('obtainedBenefit', projectData.obtainedBenefit, t),
                                    )
                                  }
                                />
                              ) : (
                                ''
                              )
                            }>
                            <Text className={dynamicFontClass}>
                              {' '}
                              {Parser(projectData.obtainedBenefit ? projectData.obtainedBenefit : '')}
                            </Text>
                          </Card>
                        </Col>
                      </Row>
                    </Card>
                  )}
                </Col>
              </Row>
            </div>
          </FullScreen>
        </>
      )}
    </>
  );
}

export default withTranslation()(PresentationBase);

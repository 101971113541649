import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { Button, DatePicker, Divider, Table, Tooltip, Typography } from 'antd';
import localeEN from 'antd/es/date-picker/locale/en_US';
import localeIT from 'antd/es/date-picker/locale/it_IT';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import i18n from '../../../../i18n';
import CommonService from '../../../../services/commonService';
import CommitieService from '../../../../services/pages/commitieServices';
import TableLayout from '../../../shared/tableLayout';
import { sessionStatus, statusPickListCodes } from '../../../shared/utils/constants';
import { handleDateConvert } from '../../../shared/utils/functions';

const { Text } = Typography;

const ComitieCalendar = ({ comitieId, comitieDetails, ...props }) => {
  const { t } = useTranslation();
  let currentDate = moment();

  const [loadingCalendar, setLoadingCalendar] = useState(false);
  const [calendarData, setCalendarData] = useState(null);
  const [calendarTableColumns, setCalendarTableColumns] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(currentDate.startOf('month'));
  const [currentLng, setCurrentLng] = useState(localeIT);
  const [sessionStatusList, setSessionStatusList] = useState(null);

  useEffect(() => {
    const systemLanguage = i18n.language;
    if (systemLanguage && systemLanguage !== 'it') {
      setCurrentLng(localeEN);
    }
    retrieveStatus();
  }, []);

  useEffect(() => {
    getCalendarData();
  }, [selectedMonth]);

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.sessionStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setSessionStatusList(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getCalendarData = async () => {
    let month = handleDateConvert(selectedMonth);
    setLoadingCalendar(true);
    await CommitieService.getCalendarData(comitieId, month)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setLoadingCalendar(false);
          let respData = resp.responseObject.value;
          setCalendarData(respData);
          let tableColumns = () => generateCalendarTableColumns(respData);
          setCalendarTableColumns(tableColumns);
        } else {
          setLoadingCalendar(false);
        }
      })
      .catch((error) => {
        setLoadingCalendar(false);
      });
  };

  const generateCalendarTableColumns = (calendarData) => {
    let columns = [];
    let currentDay = moment().format('D');
    let currentMonthYear = moment().format('MM-YYYY');

    if (calendarData && calendarData.length > 0) {
      columns.push(
        {
          title: `${t('proggetiPage.codice')}`,
          dataIndex: 'projectCode',
          width: '110px',
          fixed: 'left',
          sorter: (a, b) => {
            return a.projectCode.localeCompare(b.projectCode);
          },
          showSorterTooltip: [false],
        },
        {
          title: `${t('general.nome')}`,
          dataIndex: 'projectName',
          width: '360px',
          sorter: (a, b) => {
            return a.projectName.localeCompare(b.projectName);
          },
          showSorterTooltip: [false],
        },
        {
          title: `${t('general.divisione')}`,
          dataIndex: 'divisionName',
          showSorterTooltip: [true],
          width: '150px',
        },
        {
          title: `${t('comitatiPage.comitatiCollegati')}`,
          dataIndex: 'committeeName',
          showSorterTooltip: [true],
          width: '150px',
          render: (_, record) => {
            if (record.isSubCommittee) {
              return record.committeeName;
            } else {
              return '';
            }
          },
        },
      );

      let daysList = calendarData.find(
        (element) => Object.keys(element.sessions).length !== null && Object.keys(element.sessions).length !== '',
      );
      let daysListToJson = JSON.parse(daysList.sessions);

      daysListToJson.map((col) => {
        let dayNumber = moment(col.Date).format('D');
        let monthYear = moment(col.Date).format('MM-YYYY');

        columns.push({
          dataIndex: dayNumber,
          className: dayNumber === currentDay && monthYear === currentMonthYear ? 'calendarCurrentDayIndicator' : '',
          align: 'center',
          width: '30px',
          title: dayNumber,
          render: (text, record) => {
            let recordDays = JSON.parse(record.sessions);
            let recordDay = recordDays.find((x) => x.Date === col.Date);
            if (recordDay && recordDay.HasEvent) {
              return (
                <Tooltip title={recordDay.Name}>
                  <Text className={generateDayCellStyle(recordDay.EventStatus)}></Text>
                </Tooltip>
              );
            } else {
              return <Text className="dot dot-empty"></Text>;
            }
          },
        });
      });

      return columns;
    } else {
      return columns;
    }
  };

  const onMonthChange = (date) => {
    if (date) {
      let firstDayOfMonth = moment(date).startOf('month');
      setSelectedMonth(firstDayOfMonth);
    } else {
      setSelectedMonth(currentDate.startOf('month'));
    }
  };

  const generateDayCellStyle = (status) => {
    if (status === sessionStatus.confirmed) {
      return 'dot dot-green';
    } else if (status === sessionStatus.notDiscussed) {
      return 'dot dot-red';
    } else if (status === sessionStatus.toBeConfirmed) {
      return 'dot dot-orange';
    } else if (status === sessionStatus.planned) {
      return 'dot dot-blue';
    } else {
      return 'dot dot-empty';
    }
  };

  const upDate = () => {
    setSelectedMonth(moment(selectedMonth.add(1, 'months')));
  };

  const downDate = () => {
    setSelectedMonth(moment(selectedMonth.subtract(1, 'months')));
  };

  return (
    <TableLayout title={t('comitatiPage.calendar') + ': ' + comitieDetails.name}>
      <TableLayout.Actions>
        {sessionStatusList &&
          sessionStatusList.map((item) => (
            <div
              className="tw-flex tw-items-center tw-gap-2"
              key={item.statusID}>
              <Text
                className={generateDayCellStyle(item.statusID)}
                style={{ marginRight: 5 }}></Text>
              <Text>{item.statusDescription}</Text>
              <Divider type="vertical" />
            </div>
          ))}
        <Button
          onClick={downDate}
          icon={<LeftOutlined />}
        />
        <DatePicker
          value={selectedMonth}
          placeholder={t('general.mese')}
          onChange={onMonthChange}
          picker="month"
          format={'MMM/YYYY'}
          locale={currentLng}
        />
        <Button
          onClick={upDate}
          icon={<RightOutlined />}
        />
      </TableLayout.Actions>

      <TableLayout.Content>
        <Table
          size="small"
          className="tableCal"
          loading={loadingCalendar}
          columns={calendarTableColumns}
          dataSource={calendarData}
          rowKey={(obj) => obj.projectID}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
      </TableLayout.Content>
    </TableLayout>
  );
};

export default withTranslation()(ComitieCalendar);

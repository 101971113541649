import { BookOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, message, Popconfirm, Space, Typography } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MatrixService from '../../services/matrix/matrixService';
import { isUserAdminUser, userIsAdminOrSteering } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { xmatrixStatus } from '../shared/utils/constants';
import { notifyMessages } from '../shared/utils/notifyMessages';

let createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

const { Text } = Typography;

class XmatrixHeader extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
    };
  }

  changeXmatrixStatus = async (xMatrixID) => {
    this.setState({ loadingButton: true });
    MatrixService.updateXmatrixStatus(xMatrixID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  deleteXmatrix = async (xMatrixID) => {
    this.setState({ loadingButton: true });
    MatrixService.deleteXmatrixDraft(xMatrixID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.setState({ loadingButton: false });
          localStorage.setItem('xmFilters', JSON.stringify({}));
          history.push(`/xmatrix`);
        } else {
          message.error(notifyMessages.deleteFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  render() {
    const { loadingButton } = this.state;
    const { userData, selectedXmatrixInfo, isSecondLevelXmatrix, t } = this.props;

    return (
      <>
        <div className="tw-flex tw-items-center tw-gap-2">
          {selectedXmatrixInfo && (
            <>
              {selectedXmatrixInfo.status === xmatrixStatus.active && (
                <Text className="activeText">{selectedXmatrixInfo.statusDescription}</Text>
              )}

              {selectedXmatrixInfo.status !== xmatrixStatus.active && (
                <Text className="inactiveText">{selectedXmatrixInfo.statusDescription}</Text>
              )}
            </>
          )}
          <Text className={'menu headerText'}>{selectedXmatrixInfo ? selectedXmatrixInfo.hkCode : ''}</Text>
          <Text className={'menu headerText'}>{selectedXmatrixInfo ? selectedXmatrixInfo.xMatrixName : ''}</Text>
          <Space
            className="matrixComponentsHeadrPrint"
            direction="vertical"
            style={{ lineHeight: '1' }}>
            {!isSecondLevelXmatrix && (
              <>
                {selectedXmatrixInfo &&
                  selectedXmatrixInfo.status !== xmatrixStatus.active &&
                  userIsAdminOrSteering(userData) && (
                    <Popconfirm
                      title={`${t('xMatrixPage.attivareXmatrix')}:  ${selectedXmatrixInfo ? selectedXmatrixInfo.hkCode : ''}`}
                      onConfirm={() => this.changeXmatrixStatus(selectedXmatrixInfo.xMatrixID)}
                      icon={<BookOutlined style={{ color: '#ca8a04' }} />}
                      okText={t('general.si')}
                      cancelText={t('general.no')}>
                      <Button
                        type="link"
                        loading={loadingButton}
                        icon={<BookOutlined />}>
                        {t('xMatrixPage.attivareXmatrix')}
                      </Button>
                    </Popconfirm>
                  )}
              </>
            )}

            {selectedXmatrixInfo && selectedXmatrixInfo.status === xmatrixStatus.draft && isUserAdminUser(userData) && (
              <Popconfirm
                title={`${t('xMatrixPage.eliminareXmatrix')}:  ${selectedXmatrixInfo ? selectedXmatrixInfo.hkCode : ''}`}
                onConfirm={() => this.deleteXmatrix(selectedXmatrixInfo.xMatrixID)}
                icon={<DeleteOutlined style={{ color: '#e94646' }} />}
                okText={t('general.si')}
                cancelText={t('general.no')}>
                <Button
                  style={{ color: '#646464' }}
                  type="link"
                  loading={loadingButton}
                  icon={<DeleteOutlined />}>
                  {t('xMatrixPage.anullaXmatrix')}
                </Button>
              </Popconfirm>
            )}
          </Space>
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedXmatrix: state.selectedXmatrix.selectedXmatrix,
  userData: state.userData.userData,
});

export default connect(mapStateToProps)(XmatrixHeader);

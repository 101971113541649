import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import { CommonApi, ProjectApi, UserApi } from 'src/connectors/backend';
import i18n from '../i18n';

const CommonService = {
  getStatusData: async function (objectCode: string) {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetStatusPicklist(objectCode, languageCode));
  },

  getCalculationTypeList: async function (objectCode: string) {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetObjectCodePicklist(objectCode, languageCode));
  },

  getUsersPicklist: async function () {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetUserSelect());
  },

  getCommitiePicklist: async function () {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetCommitteePicklist());
  },

  getProjectsOptionData: async function (objectCode: string) {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetObjectCodePicklist(objectCode, languageCode));
  },

  getSeverityPickList: async function (objectCode: string) {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetObjectCodePicklist(objectCode, languageCode));
  },

  getUsersAvailability: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetUserAvailability(projectId));
  },

  getPicklist: async function (objectCode: string) {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetObjectCodePicklist(objectCode, languageCode));
  },

  getResponsibleUserList: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectMembersList(projectId));
  },

  getUserProjects: async function (xMatrixID: string, userId: string) {
    const languageCode = i18n.language;
    const api = await authenticatedApi(UserApi);

    return trackPromise(api.userGetUserProject(userId, xMatrixID, languageCode));
  },

  getKpiAnualGoalPicklist: async function (annualGoalID: string) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetKPIAnnualGoalPicklist(annualGoalID));
  },

  chaeckUserAccessOnObject: async function (object: any) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonCheckObjectSecurityUser(object));
  },

  getProjectCostType: async function (objectCode: string) {
    const languageCode = i18n.language;
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetObjectCodePicklist(objectCode, languageCode));
  },

  getAvailableCommitteeMembers: async function (committeeID: string, revisori: boolean) {
    const api = await authenticatedApi(CommonApi);

    return trackPromise(api.commonGetAvailableCommitteeMembers(committeeID, revisori));
  },
};

export default CommonService;

import { Form } from 'antd';
import React from 'react';
import './divisions.scss';

import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, message, Row, Select, Space, Upload } from 'antd';
import { withTranslation } from 'react-i18next';
import DivisionService from '../../services/pages/divisionServices';
import Loader from '../shared/components/loader/loader';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

let createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class EditDivision extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      currentData: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getDivisionDataById(id);
  }

  backFunction = () => {
    history.goBack();
  };

  getDivisionDataById = (id) => {
    DivisionService.getDivisionByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentData: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(requiredFields.uploadCheckFileType);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(requiredFields.uploadCheckFileSize);
    }
    this.state.uploadedFile = file;
    return isJpgOrPng && isLt2M;
  }

  handleChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  removeUploadedFile = () => {
    this.setState({ uploadedFile: null });
  };

  updateData = (values, id) => {
    this.setState({ loadingButton: true });
    values['divisionID'] = id;
    const formData = new FormData();
    formData.append('data', JSON.stringify(values));
    formData.append('uploadedFile', this.state.uploadedFile);
    DivisionService.updateDivision(formData)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
          this.getDivisionDataById(id);
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  render() {
    const { currentData, loadingButton } = this.state;
    let t = this.props.t;

    return (
      <div className="edit-division-wrapper">
        <Loader />

        {currentData && (
          <>
            <div className="site-card-wrapper">
              <h2 style={{ size: '50px' }}></h2>
              <p> {t('divisioniPage.divisioni')} </p>
            </div>

            <Card>
              <Form
                name="company_Details"
                layout="vertical"
                {...formItemLayout}
                onFinish={(values) => this.updateData(values, currentData.divisionID)}
                onValuesChange={() => {
                  this.setState({ fieldsChanged: true });
                }}>
                <Row gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row"
                    xs={{ span: 4 }}
                    sm={{ span: 4 }}
                    md={{ span: 4 }}
                    lg={{ span: 4 }}>
                    {/* <Button
                      onClick={this.backFunction}
                      className="backButton">
                      <RollbackOutlined />
                      {t('buttons.indietro')}
                    </Button> */}
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={{ span: 20 }}
                    sm={{ span: 20 }}
                    md={{ span: 20 }}
                    lg={{ span: 20 }}>
                    <div className="button-wrapper">
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                          loading={loadingButton}
                          type="primary"
                          htmlType="submit"
                          className="tw-float-end"
                          disabled={!this.state.fieldsChanged}>
                          <SaveOutlined /> {t('buttons.salva')}
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Card title={t('general.informazioniGenerali')}>
                      <Row
                        gutter={{ lg: 24 }}
                        className="mt10">
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Form.Item
                            label={t('general.nome')}
                            name="divisionName"
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={currentData.divisionName}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <UserSelectDropDown
                            disabled={false}
                            allowClear={true}
                            fieldLabel={t('general.responsabile')}
                            t={t}
                            formName={'ownerID'}
                            required={false}
                            initValue={currentData.ownerID}
                          />
                          {/* <Form.Item
                                                    style={{ marginBottom: 5 }}
                                                    labelAlign="left"
                                                    label={t('general.responsabile')}
                                                    name="ownerID"
                                                   
                                                    initialValue={currentData.ownerID}
                                                >
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        placeholder={t('general.seleziona')}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{ width: "100%" }}
                                                    >
                                                        {responsabilePickList && responsabilePickList.map(item =>
                                                            <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                                        )}
                                                    </Select>
                                                </Form.Item> */}
                        </Col>

                        <Col>
                          <Form.Item
                            name="image"
                            extra={t('general.image')}>
                            <Space
                              direction="vertical"
                              style={{ width: '100%' }}>
                              <div className="ant-col ant-col-24 ant-form-item-label">
                                <label className="ant-form-item">{t('divisioniPage.immagineDivisione')}</label>
                              </div>

                              {currentData.image && (
                                <img
                                  src={`${currentData.image}` + `?${+new Date()}`}
                                  width="140"
                                  alt="Logo"
                                />
                              )}

                              <Upload
                                accept="image/*"
                                maxCount={1}
                                listType="picture"
                                onChange={this.handleImageChange}
                                onRemove={() => this.removeUploadedFile()}
                                beforeUpload={(file) => {
                                  this.setState({ uploadedFile: file });
                                  return false;
                                }}>
                                <Button
                                  icon={<UploadOutlined />}
                                  size="small">
                                  {t('general.upload')}
                                </Button>
                              </Upload>
                            </Space>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </Card>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(EditDivision);

import { useEffect, useState } from 'react';

import { EditFilled, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Divider, Form, message, Radio, Row, Select, Table, Typography } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import CommonService from '../../../../services/commonService';
import MatrixService from '../../../../services/matrix/matrixService';
import CommitieService from '../../../../services/pages/commitieServices';
import XmatrixDropDownFilter from '../../../shared/components/xmatrixDropdownFilter/xmatrixDropdownFilter';
import TableLayout from '../../../shared/tableLayout';
import {
  comitieSintesiPeriod,
  formatOfDate,
  formItemLayout,
  sessionStatus,
  statusPickListCodes,
} from '../../../shared/utils/constants';
import { alignCurentMonthProjectSintesi, handleDateConvert } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';

const { Text } = Typography;
const { Option } = Select;

const ProjectRevision = ({ comitieId, comitieDetails }) => {
  const [form] = Form.useForm();
  let defaultStartDate = moment().startOf('year');
  let defaultEndDate = moment().endOf('year');
  let defaultSessionStatus = [sessionStatus.confirmed, sessionStatus.planned];
  let defaultPeriod = comitieSintesiPeriod.month;
  const { t } = useTranslation();

  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);
  const [sintesiData, setSintesiData] = useState(null);
  const [loadingData, setLoadingData] = useState(false);
  const [sessionStatusList, setSessionStatusList] = useState(null);
  const [commitiePickList, setCommitiePickList] = useState(null);
  const [sintesiTableColums, setSintesiTableColums] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(defaultPeriod);
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [noComitiesProjects, setNoComitiesProjects] = useState(null);
  const [expandCollapseRows, setExpandCollapseRows] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    getActiveXmatrix();
    retrieveStatus();
    retrieveCommities();
  }, []);

  useEffect(() => {
    getSintesiData(form.getFieldsValue());
    getNoComitieProjects();
  }, [activeXmatrixInfo]);

  const onXmatrixSelect = (selectedXmatrix) => {
    setSelectedXmatrixValue(selectedXmatrix.value);
  };

  const getNoComitieProjects = () => {
    let xmatrix = activeXmatrixInfo
      ? selectedXmatrixValue
        ? selectedXmatrixValue
        : activeXmatrixInfo?.xMatrixID
      : null;

    if (xmatrix) {
      CommitieService.getNoComititeProjects(xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let respData = resp.responseObject.value;
            setNoComitiesProjects(respData);
          } else {
          }
        })
        .catch((error) => {});
    }
  };

  const noComitiesProjectsColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'projectName',
      width: '20%',
      ellipsis: {
        showTitle: true,
      },
      sorter: (a, b) => {
        a = a.projectName || '';
        b = b.projectName || '';
        return a.localeCompare(b);
      },
      fixed: 'left',
      showSorterTooltip: [false],
    },
    {
      title: `${t('proggetiPage.codice')}`,
      dataIndex: 'projectCode',
      sorter: (a, b) => {
        a = a.projectCode || '';
        b = b.projectCode || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [true],
      defaultSortOrder: 'ascend',
    },
    {
      title: `${t('general.teamLeader')}`,
      dataIndex: 'TeamLeaderName',
      // sorter: (a, b) => { return a.teamLeaderFullName.localeCompare(b.teamLeaderFullName) },
      sorter: (a, b) => {
        a = a.TeamLeaderName || '';
        b = b.TeamLeaderName || '';
        return a.localeCompare(b);
      },
      showSorterTooltip: [true],
    },

    {
      key: 'action',
      width: '60px',
      render: (text, record) => (
        <Button
          icon={<EditFilled />}
          href={`/progetti/id/${record.projectID}`}></Button>
      ),
    },
  ];

  const getSintesiData = (values) => {
    let xmatrix = activeXmatrixInfo
      ? selectedXmatrixValue
        ? selectedXmatrixValue
        : activeXmatrixInfo?.xMatrixID
      : null;

    if (xmatrix) {
      setLoadingData(true);
      values['XmatrixID'] = xmatrix;
      values.dateFrom = handleDateConvert(values.dateFrom);
      values.dateTo = handleDateConvert(values.dateTo);

      CommitieService.getSintesiListData(values)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let respData = resp.responseObject.value;
            respData.map((item) => {
              return (
                (item.projectSessions = JSON.parse(item.projectSessions)), (item.children = JSON.parse(item.children))
              );
            });
            setSintesiData(respData);
            setLoadingData(false);
            let tableColumns = generateTableColumns(respData);
            setSintesiTableColums(tableColumns);
            alignCurentMonthProjectSintesi();
          } else {
            message.error(notifyMessages.retrieveFailed);
            setLoadingData(false);
          }
        })
        .catch((error) => {
          message.error(notifyMessages.retrieveFailed);
          setLoadingData(false);
        });
    }
  };

  const getActiveXmatrix = async () => {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.sessionStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setSessionStatusList(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveCommities = async () => {
    await CommonService.getCommitiePicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setCommitiePickList(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const onPeriodChange = ({ target: { value } }) => {
    setSelectedPeriod(value);
  };

  const handelFilter = (values) => {
    getSintesiData(values);
  };

  const handelClear = () => {
    setSelectedPeriod(defaultPeriod);
    form.setFieldsValue({
      statusList: defaultSessionStatus,
      dateFrom: defaultStartDate,
      dateTo: defaultEndDate,
      periodType: defaultPeriod,
      committeeIDList: [],
    });
    setActiveXmatrixInfo(null);
    setSelectedXmatrixValue(null);
    getActiveXmatrix();
  };

  const periodOptions = [
    {
      label: t('comitatiPage.year'),
      value: comitieSintesiPeriod.year,
    },
    {
      label: t('comitatiPage.month'),
      value: comitieSintesiPeriod.month,
    },
    {
      label: t('comitatiPage.week'),
      value: comitieSintesiPeriod.week,
    },
  ];

  const convertDateBasedOnPeriod = (date) => {
    if (date) {
      //selected period year
      if (selectedPeriod === comitieSintesiPeriod.year) {
        return moment(date).format('YYYY');
      }
      //selected period month
      else if (selectedPeriod === comitieSintesiPeriod.month) {
        return moment(date).format('MMM YYYY');
      }
      //selected period week
      else if (selectedPeriod === comitieSintesiPeriod.week) {
        return moment(date).week();
      }
    }
  };

  const statusCellColor = (sesStatus) => {
    let status = sesStatus ? parseInt(sesStatus) : 100;

    if (status && status === sessionStatus.confirmed) {
      return 'status status-fatto';
    } else if (status && status === sessionStatus.notDiscussed) {
      return 'status status-sospeso';
    } else if (status && status === sessionStatus.planned) {
      return 'status status-panificato';
    } else if (status && status === sessionStatus.toBeConfirmed) {
      return 'status status-draft';
    } else if (status && status === 10) {
      return 'status status-grey';
    } else if (status && status === 11) {
      return 'status status-orange';
    } else {
      return 'status status-empty';
    }
  };

  const generateTableColumns = (data) => {
    let columns = [];
    if (data && data.length > 0) {
      columns.push(
        {
          title: `${t('general.comitato')}`,
          dataIndex: 'committeeName',
          fixed: 'left',
          width: '200px',
          sorter: (a, b) => {
            return a.committeeName?.localeCompare(b.committeeName);
          },
          showSorterTooltip: [false],
        },
        {
          title: `${t('proggetiPage.codice')}`,
          dataIndex: 'projectCode',
          fixed: 'left',
          width: '110px',
        },
        {
          title: `${t('general.nome')}`,
          dataIndex: 'projectName',
          fixed: 'left',
          width: '300px',
        },
        {
          title: `${t('general.teamLeader')}`,
          dataIndex: 'teamLeaderName',
          fixed: 'left',
          width: '150px',
          render: (title, record) => {
            if (title === 'total') {
              return (
                <Text
                  className={record.projectID ? '' : 'totalRow'}
                  strong>
                  Σ
                </Text>
              );
            } else {
              return <Text>{title}</Text>;
            }
          },
        },
      );

      let sessionData = data.find((element) => element.projectSessions.length > 0);
      let sessionDataToJson = sessionData.projectSessions;

      sessionDataToJson.map((col) => {
        let colDate = col.date;

        let currentDate = moment();
        let colDateRec = moment(col.date);

        if (selectedPeriod === comitieSintesiPeriod.year) {
          currentDate = moment().year();
          colDateRec = moment(col.date).year();
        } else if (selectedPeriod === comitieSintesiPeriod.month) {
          currentDate = moment().format('MMM YYYY');
          colDateRec = moment(col.date).format('MMM YYYY');
        } else if (selectedPeriod === comitieSintesiPeriod.week) {
          currentDate = moment().week();
          colDateRec = moment(col.date).week();
        }

        columns.push({
          dataIndex: colDate,
          align: 'center',
          className: colDateRec === currentDate ? 'currentMonthLineIndicatorSintesi' : '',
          title: convertDateBasedOnPeriod(colDate),
          render: (text, record) => {
            let recordDays = record.projectSessions;
            let recordDay = recordDays.find((x) => x.date === colDate);
            if (recordDay && recordDay.count) {
              return {
                props: {
                  className: statusCellColor(recordDay.status),
                },
                children: (
                  <>
                    <Text>{recordDay.count}</Text>
                  </>
                ),
              };
            } else {
              return <Text className="dot dot-empty"></Text>;
            }
          },
        });
      });

      columns.push({
        title: `Σ`,
        dataIndex: 'projectSessionCount',
        className: 'totalCol',
        render: (title) => <Text strong>{title}</Text>,
      });
      return columns;
    } else {
      return columns;
    }
  };

  const onRow = ({ id }) => expandedKeys.includes(id) && { className: 'parentRow' };

  const onExpand = (expanded, { id }) => {
    const keys = expandedKeys;
    const expandedKeysOn = expanded ? keys.concat(id) : keys.filter((k) => k !== id);
    setExpandedKeys(expandedKeysOn);
  };

  const expandCollapsRows = (expandAction) => {
    if (!expandAction && expandedKeys.length < 1) {
      setExpandedKeys(sintesiData.map((a) => a.id));
      setExpandCollapseRows(true);
    } else {
      setExpandedKeys([]);
      setExpandCollapseRows(false);
    }
  };

  return (
    <>
      <Form
        name="sintesiFilters"
        layout="vertical"
        form={form}
        {...formItemLayout}
        onFinish={(values) => handelFilter(values)}>
        <TableLayout title={t('comitatiPage.projectSintesi')}></TableLayout>
        <Row gutter={{ lg: 24 }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}>
            <Row gutter={{ lg: 24 }}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 10 }}>
                {activeXmatrixInfo && (
                  <Form.Item label={t('general.xmatrix')}>
                    <XmatrixDropDownFilter
                      onXmatrixSelect={onXmatrixSelect}
                      activeXmatrixInfo={activeXmatrixInfo}
                      t={t}
                    />
                  </Form.Item>
                )}
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 7 }}>
                <Form.Item
                  label={t('general.comitato')}
                  name="committeeIDList"
                  initialValue={[]}>
                  <Select
                    placeholder={t('general.seleziona')}
                    mode="multiple"
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                    {commitiePickList &&
                      commitiePickList.map((item) => (
                        <Option
                          disabled={item.hasAccess === 1 ? false : true}
                          value={item.committeeID}
                          key={item.committeeID}>
                          {item.name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 7 }}>
                <Form.Item
                  label={t('general.stato')}
                  name="statusList"
                  initialValue={defaultSessionStatus}>
                  <Select
                    placeholder={t('general.seleziona')}
                    maxTagCount="responsive"
                    mode="multiple">
                    {sessionStatusList &&
                      sessionStatusList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}>
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 12 }}>
            <Row gutter={{ lg: 24 }}>
              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}>
                <Form.Item
                  label={t('general.start')}
                  name="dateFrom"
                  initialValue={defaultStartDate}>
                  <DatePicker
                    placeholder={t('general.seleziona')}
                    format={formatOfDate}
                  />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 6 }}>
                <Form.Item
                  label={t('general.end')}
                  name="dateTo"
                  initialValue={defaultEndDate}>
                  <DatePicker
                    placeholder={t('general.seleziona')}
                    format={formatOfDate}
                  />
                </Form.Item>
              </Col>

              <Col
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 10 }}>
                <Form.Item
                  label={t('comitatiPage.aggregazione')}
                  name="periodType"
                  initialValue={defaultPeriod}>
                  <Radio.Group
                    options={periodOptions}
                    onChange={onPeriodChange}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row gutter={{ lg: 24 }}>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}>
            <Form.Item>
              <div className="tw-flex tw-gap-2 tw-items-center tw-justify-end">
                <Button
                  type="secondary"
                  onClick={() => handelClear()}>
                  {t('general.clearFilters')}
                </Button>
                <Button
                  type="primary"
                  htmlType="submit">
                  {t('general.filter')}
                </Button>
              </div>
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Divider />

      <Row gutter={{ lg: 24 }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}
          style={{ display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            className="tw-mb-2"
            icon={!expandCollapseRows ? <PlusSquareOutlined /> : <MinusSquareOutlined />}
            onClick={() => expandCollapsRows(expandCollapseRows)}>
            {t('xMatrixPage.expandCollapse')}
          </Button>
        </Col>

        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}>
          <Table
            size="small"
            className="sintesiTable"
            loading={loadingData}
            columns={sintesiTableColums}
            dataSource={sintesiData}
            rowKey={(record) => record.id}
            pagination={false}
            scroll={{ x: 'max-content' }}
            rowClassName={(obj) => (obj.total ? 'totalRow' : '')}
            bordered
            expandable={{
              expandRowByClick: true,
              onExpand: onExpand,
              expandedRowKeys: expandedKeys,
            }}
            onRow={onRow}
          />
        </Col>
      </Row>

      <Typography.Title
        className="tw-mt-8 tw-mb-4"
        level={4}>
        {t('comitatiPage.notRelatedComitieProjects')}
      </Typography.Title>

      <Row gutter={{ lg: 24 }}>
        <Col
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 24 }}>
          <Table
            size="small"
            loading={!noComitiesProjects}
            columns={noComitiesProjectsColumns}
            dataSource={noComitiesProjects}
            rowKey={(obj) => obj.projectID}
            scroll={{ x: 'calc(600px + 50%)' }}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </Col>
      </Row>
    </>
  );
};
export default ProjectRevision;

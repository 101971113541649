import { trackPromise } from 'react-promise-tracker';
import {
  AnnualGoalApi,
  authenticatedApi,
  InsertLongTermGoalRequestDto,
  KPIApi,
  LongTermGoalApi,
  UpdateAnnualOrLongTermGoalRequestDto,
  UpdateLongTermGoalRequestDto,
} from 'src/config/connectors';
import i18n from 'src/i18n';

const ObiettiviLongService = {
  // get kpi list data
  getObiettiviLongList: async function (xmatrixId: string) {
    const api = await authenticatedApi(LongTermGoalApi);
    return trackPromise(api.longTermGoalGetLongTermGoals(xmatrixId));
  },

  // get goal types list data
  getGoalTypes: async function () {
    const api = await authenticatedApi(LongTermGoalApi);
    const currentLng = i18n.language;
    return trackPromise(api.longTermGoalGetGoalTypePicklist(currentLng));
  },

  // get kpi data by id
  getObiettiviLongByiD: async function (id: string, selectedXmatrix: string) {
    const api = await authenticatedApi(LongTermGoalApi);
    return trackPromise(api.longTermGoalGetLongTermGoalById(id, selectedXmatrix));
  },

  // add long goal
  addLongTermlGoal: async function (payload: InsertLongTermGoalRequestDto) {
    const api = await authenticatedApi(LongTermGoalApi);
    return trackPromise(api.longTermGoalInsertLongTermGoal(payload));
  },

  // update long term objective
  updateLongTerm: async function (payload: UpdateLongTermGoalRequestDto) {
    const api = await authenticatedApi(LongTermGoalApi);
    return trackPromise(api.longTermGoalUpdateLongTermGoal(payload));
  },

  // delete long term objective
  deleteLongTermObjective: async function (longTermGoalID: string) {
    const api = await authenticatedApi(LongTermGoalApi);
    return trackPromise(api.longTermGoalDeleteLongTermGoal(longTermGoalID));
  },

  // get kpi list by long term id
  getKpiListByLongTermiD: async function (id: string) {
    const api = await authenticatedApi(KPIApi);
    return trackPromise(api.kPIGetLongTermGoalByKPI(id));
  },

  // update comment or name field
  updateCommentOrNameField: async function (payload: UpdateAnnualOrLongTermGoalRequestDto) {
    const api = await authenticatedApi(AnnualGoalApi);
    return trackPromise(api.annualGoalUpdateAnnualOrLongTermGoal(payload));
  },
};

export default ObiettiviLongService;

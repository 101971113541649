import { Form } from 'antd';
import React, { Component } from 'react';

import {
  Card,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
  Typography,
} from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import moment from 'moment';
import CommonService from '../../../../../services/commonService';
import ProgettiService from '../../../../../services/pages/projectServices/progettiService';
import { ModalFooterButtons } from '../../../../shared/components/modal/modalFooterButtons';
import { formItemLayout, objectCodes, projectRoutineProgram } from '../../../../shared/utils/constants';
import { areDatesValid, showErrorNotification } from '../../../../shared/utils/functions';
import { requiredFields } from '../../../../shared/utils/notifyMessages';

dayjs.extend(customParseFormat);
const { Option } = Select;
const { Text } = Typography;

const formatOfHHMM = 'HH:mm';
const formatOfDate = 'DD/MM/YYYY';

const weekDays = [
  { label: 'Monday', value: '1' },
  { label: 'Tuesday', value: '2' },
  { label: 'Wednesday', value: '3' },

  { label: 'Thursday', value: '4' },
  { label: 'Friday', value: '5' },
  { label: 'Saturday', value: '6' },
  { label: 'Sunday', value: '7' },
];

const monthWeek = [
  { label: 'First', value: '9' },
  { label: 'Second', value: '10' },
  { label: 'Third', value: '11' },
  { label: 'Fourth', value: '12' },
];

const weekOfDays = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

class NewRoutineModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      statusList: null,
      users: null,
      loadingButton: false,
      feedTypes: null,
      selectedType: null,
      routineOrganisers: null,
      routineScheduleType: null,
      startTime: null,
      endTime: null,
      duration: null,
      //endDate: null,
      selectedStartDate: null,
      selectedMeetigProgram: null,
      weekOfRecurrence: null,
      dayOfRecurrence: null,
      dailySelectDays: null, //dayofweek
      frequence: null, //frequence,
      routineDefaultStartDate: moment(),
      selectedUsers: [],
      usersList: null,
      isMandatory: false,
      integrateInOutlook: false,
      integrateInTeams: false,
      isFrozen: false,
    };
  }

  componentDidMount() {
    this.retrieveRoutineOrganiser();
    this.retrieveRoutineScheduleType();
    this.retrieveProjectUsers();
  }

  async retrieveRoutineOrganiser() {
    const objectCode = objectCodes.routineOrganiser;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ routineOrganisers: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveRoutineScheduleType() {
    const objectCode = objectCodes.routineSchedule;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let data = resp.responseObject.value;
          this.setState({ routineScheduleType: data });

          // if(data){
          //     let idxMonthly =  data.findIndex(x => x.objectCodeListID === 3); // get option "Monthly"
          //     let idxYearly =  data.findIndex(x => x.objectCodeListID === 4); // get option "Yearly"
          //     let elementToReplace =  data[idxYearly];
          //     // replce position on array
          //     data.splice(idxYearly, 1);
          //     data.splice(idxMonthly, 0, elementToReplace);

          //     this.setState({ routineScheduleType: data })
          // }
          // else{
          //     this.setState({ routineScheduleType: data })
          // }
        } else {
        }
      })
      .catch((error) => {});
  }

  handleDateChange(date) {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  integrateInTeamsChange(val) {
    this.setState({ integrateInTeams: val });
    var inOutLook = this.state.integrateInOutlook;
    if (val) {
      this.setState({ isMandatory: true });
    } else if (!val && !inOutLook) {
      this.setState({ isMandatory: false });
    }
  }

  integrateInOutLookChange(val) {
    this.setState({ integrateInOutlook: val });
    var inTeams = this.state.integrateInTeams;
    if (val) {
      this.setState({ isMandatory: true });
    } else if (!val && !inTeams) {
      this.setState({ isMandatory: false });
    }
  }

  toggleFreeze(val) {
    if (val === 2) {
      this.setState({ isFrozen: true });
    } else {
      this.setState({ isFrozen: false });
    }
  }

  checkValidityBeforeUpdate = (values, projectId) => {
    const { t } = this.props;
    const {
      selectedUsers,
      endTime,
      startTime,
      duration,
      endDate,
      selectedMeetigProgram,
      dayOfRecurrence,
      dailySelectDays,
      frequence,
      routineDefaultStartDate,
    } = this.state;
    values['endTime'] = endTime;
    values['startTime'] = startTime;
    values['startDate'] = this.handleDateChange(routineDefaultStartDate);
    values['duration'] = duration;
    //values["endDate"] = endDate;
    values['schedule'] = selectedMeetigProgram;

    values['frequenceType'] = selectedMeetigProgram;
    values['frequence'] = frequence;
    values['dayOfWeek'] = dailySelectDays;

    if (areDatesValid(values.startDate, values.endDate)) {
      this.props.addNewRoutine(values, projectId);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  onDurationChange = (value, startTime) => {
    if (value && startTime) {
      let endTime = moment(startTime, 'HH:mm').add(value, 'hours');
      this.setState({
        endTime: endTime,
        startTime: startTime,
        duration: value,
      });
    } else if (value && !startTime) {
      this.setState({
        duration: value,
      });
    } else {
      return;
    }
  };

  onStartTimeChange = (value, duration) => {
    if (value && duration) {
      let endTime = moment(value, 'HH:mm').add(duration, 'hours');
      this.setState({
        endTime: endTime,
        startTime: value,
        duration: duration,
      });
    } else if (value && !duration) {
      this.setState({
        endTime: value,
        startTime: value,
      });
    } else {
      return;
    }
  };

  onStartDateChange = (startDate) => {
    this.setState({ routineDefaultStartDate: startDate });
  };

  disableEndDate = (current) => {
    const { selectedMeetigProgram, routineDefaultStartDate } = this.state;
    const { endDateProject } = this.props;

    if (selectedMeetigProgram && routineDefaultStartDate) {
      if (selectedMeetigProgram === projectRoutineProgram.daily) {
        let curr = moment(current).endOf('day');
        let startDate = moment(routineDefaultStartDate).endOf('day');
        let endDate = moment(endDateProject).endOf('day');
        return curr < moment(startDate).add(1, 'days') - 1 || curr > endDate;
      } else if (selectedMeetigProgram === projectRoutineProgram.weekly) {
        let curr = moment(current).endOf('day');
        let startDate = moment(routineDefaultStartDate).endOf('day');
        let endDate = moment(endDateProject).endOf('day');
        return curr < moment(startDate).add(1, 'week') || curr > endDate;
      } else if (selectedMeetigProgram === projectRoutineProgram.monthly) {
        let curr = moment(current).endOf('day');
        let startDate = moment(routineDefaultStartDate).endOf('day');
        let endDate = moment(endDateProject).endOf('day');
        return curr < moment(startDate).add(1, 'month') || curr > endDate;
      } else {
        return;
      }
    }
  };

  disableStartDate = (current) => {
    const { endDateProject, startDateProject } = this.props;

    let curr = moment(current).endOf('day');
    let startDate = moment(startDateProject).endOf('day');
    let endDate = moment(endDateProject).endOf('day');
    return curr < startDate || curr > endDate;
  };

  onProgramSelect = (program) => {
    this.setState({ selectedMeetigProgram: +program });
  };

  onDayOfRecurrenceSelect = (value) => {
    this.setState({ dayOfRecurrence: value });
  };

  onDailySelectDays = (value) => {
    if (value == '8') {
      this.setState({ dailySelectDays: 8 });
      this.setState({ frequence: 0 });
    } else {
      this.setState({ frequence: value });
      this.setState({ dailySelectDays: 0 });
    }
  };

  onDayOfWeekRecurrenceSelect = (value) => {
    this.setState({ dailySelectDays: value });
  };

  onFrequenceSelect = (value) => {
    this.setState({ frequence: value });
  };

  onSelectChange = (users) => {
    this.setState({ users });
  };

  onSelectedUser = (id) => {};

  async retrieveProjectUsers() {
    this.setState({ isDataLoading: true });
    const { projectId } = this.props;
    await ProgettiService.getProjectTeamMembers(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ usersList: resp.responseObject.value });
          this.setState({ isDataLoading: false });
        }
      })
      .catch((error) => {});
  }

  render() {
    const { users, showNewRoutineModal, handelNewRoutineModalClose, loadingButton, projectId, t } = this.props;
    const {
      selectedUsers,
      routineOrganisers,
      routineScheduleType,
      startTime,
      endTime,
      duration,
      endDate,
      selectedMeetigProgram,
      selectedStartDate,
      routineDefaultStartDate,
      usersList,
      isMandatory,
      isFrozen,
    } = this.state;

    let unique = users.filter((a, i) => users.findIndex((s) => a.fullName === s.fullName) === i);
    let uniqueUsr = [];
    unique.map((item) => {
      uniqueUsr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
    });

    return (
      <Modal
        className="add-critic-modal"
        width={800}
        title={t('proggetiPage.aggiungiRoutineProgetto')}
        open={showNewRoutineModal}
        destroyOnClose={true}
        onCancel={handelNewRoutineModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handelNewRoutineModalClose}
            formId={'newProjectRoutine'}
          />,
        ]}>
        <Card className="overflow">
          <Form
            id="newProjectRoutine"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.checkValidityBeforeUpdate(values, projectId)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  label={t('general.nome')}
                  name="name"
                  rules={[{ required: true, message: requiredFields.required }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  label={t('proggetiPage.chi')}
                  name="who"
                  hasFeedback
                  // rules={ this.state.isMandatory? [{ required: true, message: requiredFields.required }] : ""}
                >
                  <Select
                    style={{ width: '100%' }}
                    onChange={(value) => this.toggleFreeze(value)}>
                    {routineOrganisers &&
                      routineOrganisers.map((item) => (
                        <Option
                          value={item.objectCodeListID}
                          key={item.objectCodeListID}>
                          {item.description}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              {/* users */}
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  className="main-container"
                  name="extraUsersRoutine"
                  hasFeedback
                  // rules={ this.state.isMandatory? [{ required: true, message: requiredFields.required }] : ""}
                >
                  <Select
                    mode="multiple"
                    style={{ width: '100%' }}
                    placeholder={t('general.utenti')}
                    onChange={(value) => this.onSelectedUser(value)}
                    options={uniqueUsr}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                  />
                </Form.Item>
              </Col>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Row>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}>
                    <Form.Item
                      label={t('proggetiPage.createReunioneOutlook')}
                      name="integrateInOutlook"
                      valuePropName="checked"
                      initialValue={false}>
                      <Switch
                        onClick={(val) => this.integrateInOutLookChange(val)}
                        disabled={this.state.isFrozen ? true : false}
                      />
                    </Form.Item>
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 6 }}
                    lg={{ span: 6 }}>
                    <Form.Item
                      label={t('proggetiPage.createMettingTeams')}
                      name="integrateInTeams"
                      valuePropName="checked"
                      initialValue={false}>
                      <Switch
                        onClick={(val) => this.integrateInTeamsChange(val)}
                        disabled={this.state.isFrozen ? true : false}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              {/* <Row > */}
              <br />
              {/* <Form> */}
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <h4>{t('proggetiPage.appontmentTime')}</h4>
                <Row>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item
                      label={t('proggetiPage.oraInizio')}
                      name="startTime"
                      value={startTime}
                      rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}>
                      <TimePicker
                        placeholder={new Date().getHours() + ':' + new Date().getMinutes()}
                        style={{ width: '100%' }}
                        format="HH:mm"
                        value={startTime}
                        onChange={(value) => this.onStartTimeChange(value, duration)}
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item
                      label={t('proggetiPage.durata')}
                      name="duration"
                      rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}>
                      <InputNumber
                        step=".5"
                        style={{ width: '100%' }}
                        value={duration}
                        onChange={(value) => this.onDurationChange(value, startTime)}
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Row>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item
                      label={t('proggetiPage.oraFine')}
                      rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}>
                      <TimePicker
                        placeholder={t('general.seleziona')}
                        style={{ width: '100%' }}
                        format={formatOfHHMM}
                        value={endTime}
                        name="endTime"
                        disabled={true}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <br />
              {/* Recurrence pattern */}
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <h4>{t('proggetiPage.recurencePattern')}</h4>
                <Row>
                  <Col
                    xs={{ span: 6 }}
                    sm={{ span: 6 }}
                    md={{ span: 4 }}
                    lg={{ span: 4 }}>
                    <Radio.Group onChange={(value) => this.onProgramSelect(value.target.value)}>
                      <Space direction="vertical">
                        {/* <Radio   key={1}   value={1}>Daily</Radio>
                            <Radio key= {2}    value={2}>Weekly</Radio>
                            <Radio key ={3}   value={3}>Monthly</Radio>
                            <Radio key={4}    value={4}>Yearly</Radio> */}

                        {routineScheduleType &&
                          routineScheduleType.map((item) => (
                            <Radio
                              key={item.objectCodeListID}
                              value={item.objectCodeListID}>
                              {item.description}
                            </Radio>
                          ))}
                      </Space>
                    </Radio.Group>
                  </Col>
                  {/* Weeks */}
                  <Col
                    xs={{ span: 18 }}
                    sm={{ span: 18 }}
                    md={{ span: 20 }}
                    lg={{ span: 20 }}>
                    {this.state.selectedMeetigProgram === 1 ? (
                      <Radio.Group onChange={(value) => this.onDailySelectDays(value.target.value)}>
                        <Space direction="vertical">
                          <Row
                            xs={{ span: 24 }}
                            md={{ span: 24 }}>
                            <Radio value="1">Every</Radio>
                            <Form.Item style={{ marginBottom: '0px' }}>
                              <InputNumber
                                onChange={(value) => this.onDailySelectDays(value)}
                                defaultValue={1}
                                min={1}
                                max={7}
                              />
                              <Text style={{ right: '3px' }}>day(s)</Text>
                            </Form.Item>
                          </Row>
                          <Radio value="8">Every weekday</Radio>
                        </Space>
                      </Radio.Group>
                    ) : null}

                    {this.state.selectedMeetigProgram === 2 ? (
                      <Form.Item
                        name="frequency"
                        rules={[{ required: true, message: 'Please input the frequency!' }]}>
                        <Row>
                          <Text style={{ marginRight: 5, marginTop: 4 }}>
                            <span style={{ color: 'red' }}>*</span> Recur every
                          </Text>
                          <InputNumber onChange={(value) => this.onFrequenceSelect(value)} />
                          <Text style={{ marginLeft: 5, marginTop: 4 }}>weeks(s) on:</Text>
                          <Row>
                            <Radio.Group onChange={(value) => this.onDayOfWeekRecurrenceSelect(value?.target.value)}>
                              <Space direction="horizontal">
                                {weekDays.map((item, i) => (
                                  <Col
                                    xs={{ span: 8 }}
                                    md={{ span: 6 }}
                                    key={i}>
                                    <Radio value={item.value}>{item.label}</Radio>
                                  </Col>
                                ))}
                              </Space>
                            </Radio.Group>
                          </Row>
                        </Row>
                      </Form.Item>
                    ) : null}
                    {this.state.selectedMeetigProgram === 3 ? (
                      <Row>
                        <Radio.Group>
                          <Space direction="vertical">
                            <Radio value="1">
                              <Row
                                xs={{ span: 24 }}
                                md={{ span: 24 }}>
                                Day
                                <Form.Item
                                  style={{ marginBottom: '0px' }}
                                  name="dayOfWeek">
                                  <InputNumber
                                    onChange={(value) => this.onDayOfWeekRecurrenceSelect(value)}
                                    defaultValue={1}
                                  />
                                  <Text style={{ marginLeft: '3px' }}>of every</Text>
                                </Form.Item>
                                <Form.Item name="frequence">
                                  <InputNumber onChange={(value) => this.onFrequenceSelect(value)} />
                                  <Text style={{ marginLeft: '3px' }}>month(s)</Text>
                                </Form.Item>
                              </Row>
                            </Radio>
                            <Row></Row>
                          </Space>
                        </Radio.Group>
                      </Row>
                    ) : null}

                    {/* <Row>
                          <Col>
                        
                            {
                              this.state.selectedMeetigProgram !== null && this.state.selectedMeetigProgram !== 1 ?
                                <Radio.Group onChange={(value) => this.onDayOfRecurrenceSelect(value.target.value)}
                                >
                                  <Space direction="horizontal">
                                    {weekDays.map((item, i) => (
                                      <Col xs={{ span: 8 }} md={{ span: 6 }}>
                                        <Radio key={i} value={item.value}  >
                                          {item.label}
                                        </Radio>
                                      </Col>
                                    ))}
                                  </Space>
                                </Radio.Group>
                                : null
                            }


                          </Col>
                        </Row> */}
                  </Col>
                </Row>
              </Col>
              <br />
              {/* Range of Recurrence */}
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <h4>{t('proggetiPage.rangeRequrence')}</h4>
                <Row gutter={{ md: 24 }}>
                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item
                      label={'Start'}
                      name="startDate"
                      rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}>
                      <DatePicker
                        value={routineDefaultStartDate}
                        onChange={(date) => this.onStartDateChange(date)}
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                        disabledDate={(current) => this.disableStartDate(current)}
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Form.Item
                      label={t('general.end')}
                      name="endDate"
                      rules={this.state.isMandatory ? [{ required: true, message: requiredFields.required }] : []}>
                      <DatePicker
                        disabled={selectedMeetigProgram && !routineDefaultStartDate}
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                        disabledDate={(current) => this.disableEndDate(current)}
                      />
                    </Form.Item>
                  </Col>
                  {/* </Col> */}
                </Row>
              </Col>
              <br />
              <br />

              {/* </Form> */}
              <br />

              {/* </Row> */}
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default NewRoutineModal;

import { CloseOutlined, DownOutlined, FlagOutlined } from '@ant-design/icons';
import { Avatar, Button, DatePicker, Drawer, Dropdown, Form, message, Select, Tooltip } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import Search from 'antd/es/transfer/search';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RiCalendar2Line, RiRecordCircleLine, RiSettingsLine, RiUser6Line } from 'react-icons/ri';
import { KanBanView, List } from '../../../../../assets/icons';
import CommonService from '../../../../../services/commonService';
import ActivityService from '../../../../../services/pages/activitiesServices';
import DeliverablePageService from '../../../../../services/pages/deliverableService';
import UsersService from '../../../../../services/pages/usersService';
import { addQueryParam, getQueryParam, hasQueryParam } from '../../../../../utils/url-utils';
import TableLayout from '../../../../shared/tableLayout';
import {
  activityStatus,
  deliverableStatus,
  objectCodes,
  statusPickListCodes,
} from '../../../../shared/utils/constants';
import { updateNotificationBell } from '../../../../shared/utils/functions';
import { notifyMessages } from '../../../../shared/utils/notifyMessages';
import { DeliverableIcon, PhaseIcon, TaskIcon } from './components/icons';
import KanBan from './components/kanban';
import {
  filterByItemType,
  flatArray,
  getIconFromStatus,
  getMonthsBetween,
  matchItemsWithCompanyUsers,
  toKanBanItems,
} from './kanban-view.utils';

const itemTypeOptions = (t) => [
  {
    key: 'phase',
    label: <span className="tw-ml-2">{t('general.phase')}</span>,
    icon: <PhaseIcon />,
  },
  {
    key: 'deliverable',
    label: <span className="tw-ml-2">{t('general.deliverable')}</span>,
    icon: <DeliverableIcon />,
  },
  {
    key: 'task',
    label: <span className="tw-ml-2">{t('general.activity')}</span>,
    icon: <TaskIcon />,
  },
];

const groupByOptions = (t) => [
  {
    key: 'month',
    label: <span className="tw-ml-2">{t('general.month')}</span>,
    icon: <RiCalendar2Line />,
  },
  {
    key: 'status',
    label: <span className="tw-ml-2">{t('general.status')}</span>,
    icon: <RiRecordCircleLine />,
  },
  {
    key: 'owner',
    label: <span className="tw-ml-2">{t('general.owner')}</span>,
    icon: <RiUser6Line />,
  },
];

const PrsKanBanView = ({ projectId, setView, view, deliverablesList, newBtn, updateDeliverables }) => {
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [users, setUsers] = useState([]);
  const [columns, setColumns] = useState([]);
  const [colFn, setColFn] = useState(() => () => undefined);
  const [selectedItemType, setSelectedItemType] = useState('deliverable'); // phase, task
  const [selectedGroupBy, setSelectedGroupBy] = useState('status'); // month, status, owner
  const [isFilterDrawerOpen, setIsFilterDrawerOpen] = useState(false);
  const [filters, setFilters] = useState({ query: '', status: [], period: [], priority: [], owner: [] });

  useEffect(() => {
    if (hasQueryParam('selectedGroupBy')) {
      setSelectedGroupBy(getQueryParam('selectedGroupBy'));
    }

    if (hasQueryParam('selectedItemType')) {
      setSelectedItemType(getQueryParam('selectedItemType'));
    }

    if (users.length === 0) {
      retrieveResponsibleUsersList(); // get users
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!deliverablesList.length) {
      setItems([]);
      return;
    }

    let kanBanItems = toKanBanItems(flatArray(deliverablesList));
    if (!kanBanItems.length) return;

    kanBanItems = matchItemsWithCompanyUsers(kanBanItems, users);

    // Reset state to avoid stale values for columns
    // setColFn(() => () => undefined);
    // setColumns([]);

    // Filter items by the selected type
    let filteredItems = filterByItemType(kanBanItems, selectedItemType);

    const { query, status, period, priority, owner } = filters;

    // Precompute query filter if provided
    const queryLower = query?.trim().toLowerCase();
    const hasQueryFilter = Boolean(queryLower);
    const statusSet = new Set(status || []);
    const prioritySet = new Set(priority || []);
    const ownerSet = new Set(owner || []);

    // Filter by search query, if provided
    if (hasQueryFilter) {
      filteredItems = filteredItems.filter(
        (item) =>
          item.title.toLowerCase().includes(queryLower) || item.ownerFullName?.toLowerCase().includes(queryLower),
      );
    }

    // Filter by status
    if (statusSet.size) {
      filteredItems = filteredItems.filter((item) => statusSet.has(item.status));
    }

    // Filter by date range
    const [startDate, endDate] = period || [];
    if (startDate && endDate) {
      const minDate = moment(startDate);
      const maxDate = moment(endDate);
      filteredItems = filteredItems.filter((item) => moment(item.startDate).isBetween(minDate, maxDate, null, '[]'));
    }

    // Filter by priority
    if (prioritySet.size) {
      filteredItems = filteredItems.filter((item) => prioritySet.has(item.priority));
    }

    // Filter by owner
    if (ownerSet.size) {
      filteredItems = filteredItems.filter((item) => ownerSet.has(item.ownerId));
    }

    setItems(filteredItems);
  }, [deliverablesList, selectedItemType, selectedGroupBy, filters, users]);

  useEffect(() => {
    if (items?.length === 0) return;

    const setColumnsAndColFn = (columns, colFn) => {
      // Cleanup before setting new columns and colFn
      setColumns([]);
      setColFn(() => () => undefined);

      setColumns(columns);
      setColFn(() => colFn);
    };

    switch (selectedGroupBy) {
      case 'status': {
        const statusMap = selectedItemType === 'task' ? activityStatus : deliverableStatus;
        const columns = Object.entries(statusMap).map(([key, value]) => ({
          id: value,
          title: t(`general.${key}`),
          icon: getIconFromStatus(value),
          canCollapse: false,
          isDndEnabled: true,
          hideIfEmpty: false,
        }));

        setColumnsAndColFn(columns, (item) => columns.find(({ id }) => id === item?.status));
        break;
      }
      case 'month': {
        const dates = items.map((i) => moment(i.endDate));
        const minDate = moment.min(dates);
        const maxDate = moment.max(dates);

        const columns = getMonthsBetween(minDate, maxDate).map((label) => ({
          id: label,
          title: label,
          canCollapse: true,
          isDndEnabled: false,
          hideIfEmpty: false,
        }));

        setColumnsAndColFn(columns, (item) =>
          columns.find(({ id }) => id.toString() === moment(item?.endDate).format('MMM YYYY').toString()),
        );
        break;
      }
      case 'owner': {
        const outOfProjectUsers = items.filter((i) => !users.some((u) => i.ownerId === u.userID));
        const columns = [
          ...users,
          ...(outOfProjectUsers.length ? [{ userID: 'outOfProject', fullName: 'Utenti fuori progetto' }] : []),
        ].map(({ userID, fullName, profilePhoto }) => ({
          id: userID,
          title: fullName,
          icon: (
            <Avatar
              size={'small'}
              src={profilePhoto}
              alt={fullName.at(0)}>
              {fullName.at(0)}
            </Avatar>
          ),
          canCollapse: true,
          isDndEnabled: false,
          hideIfEmpty: true,
        }));

        setColumnsAndColFn(
          columns,
          (item) => columns.find(({ id }) => id === item?.ownerId) ?? columns.find(({ id }) => id === 'outOfProject'),
        );
        break;
      }
      default:
        return;
    }
  }, [items, selectedGroupBy, selectedItemType, users, filters]);

  const retrieveResponsibleUsersList = () => {
    UsersService.getUsersListData(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          setUsers(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const updateDeliverable = (deliverable, newStatus) => {
    let values = {
      ...deliverable,
      status: newStatus,
    };

    if (values?.status === deliverableStatus.chiuso) {
      values['actualEndDate'] = null;
    }

    DeliverablePageService.updateDeliverable(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateDeliverables();
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const updateTask = (task, newStatus) => {
    let values = {
      ...task,
      status: newStatus,
    };

    values['projectID'] = projectId;
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateDeliverables();
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  const updateCardStatus = (item, newStatus) => {
    if (['phase', 'deliverable'].includes(item.itemType)) {
      updateDeliverable(item, newStatus);
    }

    if (item.itemType === 'task') {
      updateTask(item, newStatus);
    }
  };

  const groupByPhase = (arr) => {
    const key = 'parentDeliverableID';

    return arr.reduce((result, item) => {
      const groupKey = item[key];
      let group = result.find((g) => g.id === groupKey);

      if (!group) {
        group = {
          id: groupKey,
          labelContent: (
            <>
              <div className="tw-text-sm tw-font-medium tw-text-violet-700 tw-rounded tw-bg-violet-100 tw-gap-1 tw-border tw-border-violet-700/20 tw-py-0.5 tw-px-1 tw-flex tw-items-center">
                <div className="tw-shrink-0 tw-rounded-md tw-flex tw-items-center tw-justify-center">
                  <FlagOutlined className="!tw-text-violet-700 tw-text-lg" />
                </div>
                {item.parentDeliverableName}
              </div>
            </>
          ),
          items: [],
        };
        result.push(group);
      }

      group.items.push(item);
      return result;
    }, []);
  };

  const onFinish = (values) => {
    setFilters(values);
  };

  return (
    <TableLayout title={t('Project review system')}>
      <TableLayout.ExtraTitle>
        <Tooltip title={t('general.listView')}>
          <Button
            data-testid="go-to-prs-list"
            className={'tw-fill-black'}
            onClick={() => {
              addQueryParam('view', 'list');
              setView('list');
            }}
            icon={
              <List
                className={classNames('tw-fill-black', {
                  'tw-fill-primary': view === 'list',
                })}
              />
            }></Button>
        </Tooltip>
        <ButtonGroup>
          <Button
            className="tw-bg-primary/10"
            icon={<KanBanView className={'tw-fill-primary'} />}></Button>

          {view === 'board' && (
            <>
              <Dropdown
                placement="bottomLeft"
                menu={{
                  items: itemTypeOptions(t),
                  onClick: (e) => {
                    addQueryParam('selectedItemType', e.key);
                    setSelectedItemType(e.key);
                  },
                }}>
                <Button
                  className="tw-capitalize"
                  onClick={(e) => e.preventDefault()}>
                  {t(`general.${selectedItemType}`)}
                  <DownOutlined />
                </Button>
              </Dropdown>
              <Dropdown
                placement="bottomLeft"
                menu={{
                  items: groupByOptions(t),
                  onClick: (e) => {
                    addQueryParam('selectedGroupBy', e.key);
                    setSelectedGroupBy(e.key);
                  },
                }}>
                <Button
                  className="tw-capitalize"
                  onClick={(e) => e.preventDefault()}>
                  Per {t(`general.${selectedGroupBy}`)}
                  <DownOutlined />
                </Button>
              </Dropdown>
            </>
          )}
        </ButtonGroup>
      </TableLayout.ExtraTitle>
      <TableLayout.Actions>
        <Button
          className={classNames('tw-fill-black', {
            '!tw-bg-primary/10 !tw-text-primary': isFilterDrawerOpen,
          })}
          onClick={() => setIsFilterDrawerOpen(!isFilterDrawerOpen)}
          icon={<RiSettingsLine />}
        />

        <Search
          value={filters.query}
          onChange={(e) => {
            setFilters({ ...filters, query: e.currentTarget.value });
          }}
          placeholder={t('general.search')}
        />
        {newBtn}
      </TableLayout.Actions>
      <TableLayout.Content>
        <div className="tw-flex tw-size-full tw-relative tw-overflow-hidden">
          <div className="tw-flex tw-grow tw-relative tw-gap-4 tw-h-full tw-w-full tw-overflow-x-auto tw-animate-fadeIn">
            <KanBan
              // function for dnd - when a card is dropped
              onDropFn={updateCardStatus}
              // function to group cards into the column
              colGroupFn={selectedItemType === 'deliverable' ? groupByPhase : undefined}
              // function to get the column for a given item
              colFn={colFn}
              // list of items
              items={items}
              // search query
              searchQuery={filters.query}
              // list of columns
              columns={columns}
            />
          </div>

          <PrsKanBanFiltersDrawer
            onFinish={onFinish}
            isOpen={isFilterDrawerOpen}
            onClose={() => setIsFilterDrawerOpen(false)}
            selectedGroupBy={selectedGroupBy}
            projectId={projectId}
          />
        </div>
      </TableLayout.Content>
    </TableLayout>
  );
};

const PrsKanBanFiltersDrawer = ({ onFinish, isOpen, onClose, selectedGroupBy, projectId }) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const [priorityPickList, setPriorityPickList] = useState(null);
  const [statusPickList, setStatusPickList] = useState(null);
  const [ownerPickList, setOwnerPickList] = useState(null);

  useEffect(() => {
    retrieveResponsibleOptions(); // to get owner options
    if (isOpen && (!priorityPickList || !statusPickList)) {
      retrievePriorityOptions(); // to get priority options
      retrieveStatusOptions(); // to get status options
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const retrievePriorityOptions = async () => {
    const objectCode = objectCodes.deliverablePriority;

    try {
      const response = await CommonService.getPicklist(objectCode);
      const { success, responseObject } = response.data;

      if (success) {
        setPriorityPickList(responseObject.value);
      }
    } catch (error) {
      // Handle error appropriately (e.g., log it, show a notification)
    }
  };

  const retrieveStatusOptions = async () => {
    const objectCode = statusPickListCodes.delivarbleStatus;

    try {
      const response = await CommonService.getStatusData(objectCode);
      const { success, responseObject } = response.data;

      if (success) {
        setStatusPickList(responseObject.value);
      }
    } catch (error) {
      // Handle error appropriately (e.g., log it, show a notification)
    }
  };

  const retrieveResponsibleOptions = async () => {
    try {
      const response = await CommonService.getResponsibleUserList(projectId);
      const { success, responseObject } = response.data;

      if (success) {
        setOwnerPickList(responseObject.value);
      }
    } catch (error) {
      // Handle error appropriately (e.g., log it, show a notification)
    }
  };

  // const onReset = () => {
  //   form.resetFields();
  //   onClose();
  // };

  return (
    <Form
      layout="vertical"
      onFinish={onFinish}
      autoComplete="off">
      <Drawer
        title="Impostazioni vista"
        closable={false}
        form={form}
        getContainer={false}
        width={400}
        classNames={{
          mask: '!tw-bg-transparent',
          wrapper: 'white-shadow !tw-border-l',
        }}
        extra={
          <Button
            onClick={onClose}
            icon={<CloseOutlined />}
          />
        }
        footer={
          <div className="tw-flex tw-items-center tw-gap-2">
            <Button onClick={onClose}>{t('buttons.annulla')}</Button>
            <Button
              htmlType="submit"
              type="primary">
              {t('buttons.applica')}
            </Button>
          </div>
        }
        onClose={onClose}
        open={isOpen}>
        {selectedGroupBy !== 'status' && (
          <Form.Item
            label={t('general.stato')}
            name="status">
            <Select
              mode="multiple"
              className="tw-w-full"
              placeholder={t('general.seleziona')}>
              {statusPickList &&
                statusPickList.map((item) => (
                  <Select.Option
                    value={item.statusID}
                    key={item.statusID}>
                    {item.statusDescription}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}

        {selectedGroupBy !== 'month' && (
          <Form.Item
            label={t('general.start')}
            name="period">
            <DatePicker.RangePicker
              className="tw-w-full"
              format={'DD/MM/YYYY'}
            />
          </Form.Item>
        )}

        <Form.Item
          label={t('general.priorita')}
          name="priority">
          <Select
            mode="multiple"
            className="tw-w-full"
            placeholder={t('general.seleziona')}>
            {priorityPickList &&
              priorityPickList.map((item) => (
                <Select.Option
                  value={item.objectCodeListID}
                  key={item.objectCodeListID}>
                  {item.description}
                </Select.Option>
              ))}
          </Select>
        </Form.Item>

        {selectedGroupBy !== 'owner' && (
          <Form.Item
            label={t('general.responsabile')}
            name="owner">
            <Select
              placeholder={t('general.seleziona')}
              className="tw-w-full"
              mode="multiple">
              {ownerPickList &&
                ownerPickList.map((item) => (
                  <Select.Option
                    value={item.userID}
                    key={item.userID}>
                    {item.fullName}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        )}
      </Drawer>
    </Form>
  );
};

export default PrsKanBanView;

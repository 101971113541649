import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi, InsertKPIXMatrixRequestDto, XMatrixApi } from 'src/config/connectors';

const ObKpiService = {
  // add kpi in matrix
  addObKpi: async function (payload: InsertKPIXMatrixRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixInsertKPIMatrix(payload));
  },

  // remove kpi xmatrix
  removeKpi: async function (kpiId: string, selectedXmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixDeleteKPIRelationshipXMatrix(kpiId, selectedXmatrixId));
  },
};

export default ObKpiService;

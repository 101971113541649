import { Spin } from 'antd';
import React, { Suspense, useEffect, useState } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ProgettiService from '../../services/pages/projectServices/progettiService';
import { userHasAccessProjectFields } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { projectStatus } from '../shared/utils/constants';
import { ModalBox } from './ModalBox/modalBox';
import './mainA3.scss';
import Section1Form from './section1/section1';

// Lazy load the sections
const Section2 = React.lazy(() => import('./section2/section2'));
const Section3 = React.lazy(() => import('./section3/section3'));
const Section4 = React.lazy(() => import('./section4/section4'));

const A3Index = ({ id, userData, t, i18n }) => {
  const [currentProject, setCurrentProject] = useState(null);
  const [loading, setLoading] = useState(true);
  const [show, setShow] = useState(false);
  const [showData, setShowData] = useState(null);
  const languageCode = i18n.language;

  useEffect(() => {
    const fetchProjectData = async (projectId) => {
      setLoading(true);
      try {
        const response = await ProgettiService.getProgettiByiD(projectId);
        const resp = response.data;
        if (resp.success) {
          setCurrentProject(resp.responseObject.value);
        }
      } catch (error) {
        console.error('Error fetching project data:', error);
      } finally {
        setLoading(false);
        scrollToSection();
      }
    };

    fetchProjectData(id);
  }, [id]);

  const scrollToSection = () => {
    const sectionFragment = window.location.hash.slice(1);
    if (sectionFragment) {
      setTimeout(() => {
        const section = document.getElementById(sectionFragment);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
        }
      }, 1000);
    }
  };

  const toggleModal = (key) => {
    setShow(!show);
    setShowData(key);
  };

  const closeModal = () => {
    setShow(false);
  };

  const hasUserAccess =
    currentProject && userData
      ? !userHasAccessProjectFields(userData) && currentProject.status === projectStatus.completato
      : false;

  if (loading) {
    return (
      <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="mainA3 a3-main">
      <div className="a3-main-body">
        <h1 className="tw-text-3xl tw-mb-10">{currentProject.name}</h1>

        <div id="10.1">
          <Section1Form
            t={t}
            id={id}
            currentProject={currentProject}
            isPrintPreview={false}
            hasUserAccess={hasUserAccess}
          />
        </div>

        <div id="10.2">
          <Suspense fallback={<Spin size="large" />}>
            <Section2
              currentProject={currentProject}
              t={t}
              languageCode={languageCode}
              id={id}
              hasUserAccess={hasUserAccess}
              userData={userData}
              showGantt={true}
              showPrs={true}
            />
          </Suspense>
        </div>

        <div id="10.3">
          <Suspense fallback={<Spin size="large" />}>
            <Section3
              t={t}
              id={id}
              hasUserAccess={hasUserAccess}
            />
          </Suspense>
        </div>

        <div id="10.4">
          <Suspense fallback={<Spin size="large" />}>
            <Section4
              t={t}
              id={id}
              hasUserAccess={hasUserAccess}
            />
          </Suspense>
        </div>
      </div>

      <ModalBox
        className="a3-main-body"
        closeHandler={closeModal}
        visible={show}>
        <Suspense fallback={<Spin size="large" />}>
          {showData === 1 && (
            <Section1Form
              currentProject={currentProject}
              isPresentationMode={false}
              isModalOpen={show}
              openHandler={() => toggleModal(1)}
              id={id}
            />
          )}

          {showData === 2 && (
            <Section2
              currentProject={currentProject}
              t={t}
              languageCode={languageCode}
              isPresentationMode={false}
              isModalOpen={show}
              openHandler={() => toggleModal(2)}
              id={id}
              userData={userData}
              showGantt={true}
              showPrs={true}
            />
          )}

          {showData === 3 && (
            <Section3
              t={t}
              isPresentationMode={false}
              isModalOpen={show}
              openHandler={() => toggleModal(3)}
              id={id}
            />
          )}

          {showData === 4 && (
            <Section4
              t={t}
              isPresentationMode={false}
              isModalOpen={show}
              openHandler={() => toggleModal(4)}
              id={id}
            />
          )}
        </Suspense>
      </ModalBox>
    </div>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(A3Index));

import { Form } from 'antd';
import React from 'react';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, message, Row } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import MatrixService from '../../services/matrix/matrixService';
import { formItemLayout } from '../shared/utils/constants';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';
import './xmatrixCycle.scss';
let createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

class NewXmatrixCycle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      loadingButton: false,
    };
  }

  addData = (values) => {
    this.setState({ loadingButton: true });
    MatrixService.insertXmatrixCycle(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.setState({ loadingButton: false });
          this.props.history.push('/strategicCycle');
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  backFunction = () => {
    history.goBack();
  };

  render() {
    const { loadingButton } = this.state;
    let t = this.props.t;
    const { userData } = this.props;

    return (
      <div className="edit-obietivo-anno-wrapper">
        <>
          <div className="site-card-wrapper"> {t('xmatrixCyclePage.aggiungiCycle')} </div>
          <Card>
            <Form
              name="add_matrix_cycle"
              layout="vertical"
              {...formItemLayout}
              onFinish={this.addData}
              onValuesChange={() => {
                this.setState({ fieldsChanged: true });
              }}>
              <Row gutter={{ lg: 24 }}>
                <Form.Item
                  wrapperCol={{ span: 4 }}
                  name="companyID"
                  initialValue={userData.lastLoggedInCompanyID}>
                  <Input type="hidden" />
                </Form.Item>
                <Row
                  gutter={{ lg: 24 }}
                  style={{ justifyContent: 'space-between', width: '100%' }}>
                  <Col
                    className="gutter-row"
                    xs={{ span: 4 }}
                    sm={{ span: 4 }}
                    md={{ span: 4 }}
                    lg={{ span: 4 }}>
                    {/* <Button
                      onClick={this.backFunction}
                      className="backButton">
                      <RollbackOutlined />
                      {t('buttons.indietro')}
                    </Button> */}
                  </Col>

                  <Col
                    className="gutter-row"
                    xs={{ span: 20 }}
                    sm={{ span: 20 }}
                    md={{ span: 20 }}
                    lg={{ span: 20 }}>
                    <div className="button-wrapper">
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                          loading={loadingButton}
                          type="primary"
                          htmlType="submit"
                          disabled={!this.state.fieldsChanged}
                          className="tw-float-end">
                          <SaveOutlined /> {t('buttons.salva')}
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>
              </Row>

              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 12 }}>
                  <Card title={t('general.informazioniGenerali')}>
                    <Row gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          label={t('xmatrixCyclePage.nomeCycle')}
                          name="cycleName"
                          rules={[{ required: true, message: requiredFields.required }]}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(NewXmatrixCycle));

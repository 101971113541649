import { RightOutlined } from '@ant-design/icons';
import { Card, Col, Divider, List, message, Popover, Row, Table, Tooltip, Typography } from 'antd';
import Parser from 'html-react-parser';
import moment from 'moment';
import React from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Section4Service from '../../../services/a3/section4Service';
import Riskervice from '../../../services/pages/projectServices/riskServices';
import Loader2 from '../../shared/components/loader2/loader2';
import {
  checkRiskSeverity,
  checkStatus,
  projectDeliverableStatusColor,
  ResponsiblePopoverContent,
} from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';

const rootStyle = { display: 'flex', alignItem: 'center' };
const rowStyle = {
  margin: '0 100px 0 0',
  height: '70px',
  display: 'flex',
  alignItems: 'center',
  background: '#f5f5f5',
  borderRadius: '8px',
  width: '50%',
  textAlign: 'left',
};
const boxStyle = {
  background: '#f5f5f5',
  borderRadius: '8px',
  padding: '10px 0',
  marginLeft: '20px',
  fontSize: '16px',
};
const { Text } = Typography;

const getBackgroundColorValue = (status) => {
  if (status === 1) {
    return '#8FED8F';
  } else if (status === 2) {
    return '#42b21d';
  } else if (status === 3) {
    return '#FF0000';
  } else if (status === 4) {
    return '#FF7276';
  } else if (status === 5) {
    return '#fbd311';
  } else if (status === 6) {
    return '#FFFFFF';
  }
};

const Title = ({ title, description, size }) => {
  return (
    <div>
      <div
        style={{
          fontSize: size ? size : '16px',
          fontWeight: 'bold',
          textAlign: 'left',
        }}>
        <Tooltip title={description}>{title}</Tooltip>
      </div>
      {/* {description && (
          <p
            className="wrap"
            style={{
              textAlign: "left",
              color: "lightgray",
              marginBottom: "0",
              fontSize: "12px",
            }}
          >
            <Tooltip  title={description}>
            {description}
            </Tooltip>  
          </p>
        )} */}
    </div>
  );
};

class Section4PrintPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentData: null,
      fullScreen: false,
      deliverableArray: [],
      riskList: [],
      editorModal: false,
      fieldObject: null,
      sectionFieldChanged: false,
      companyData: this.props.companyData,
      riskAndPrecautionList: [],
    };
  }

  async componentDidMount() {
    const { id } = this.props;
    // this.getProjectDeliverable(id, companyData.daysOfNotice);

    if (this.props.companyData && Object.keys(this.props.companyData).length > 0) {
      await this.getProjectDeliverable(this.props.id, this.props.companyData.nextStepsDaysOfNotice);
    }

    this.getProjectNextSteps(id);
    this.getRisks(id);
    this.retrieveRiskAndPrecautionList();
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.companyData.nextStepsDaysOfNotice !== this.props.companyData.nextStepsDaysOfNotice) {
      await this.getProjectDeliverable(this.props.id, this.props.companyData.nextStepsDaysOfNotice);
    }
  }

  getProjectDeliverable = (id, daysOfNotice) => {
    Section4Service.getProjectDeliverable(id, daysOfNotice)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ deliverableArray: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  async retrieveRiskAndPrecautionList() {
    this.setState({ isDataLoading: true });
    const { id } = this.props;
    await Riskervice.getRiskAndPrecaution(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ riskAndPrecautionList: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  }

  getProjectNextSteps = (id) => {
    Section4Service.getNextSteps(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentData: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  getRisks = (id) => {
    Section4Service.getProjectRisk(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ riskList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  render() {
    const { currentData, deliverableArray, riskList, riskAndPrecautionList } = this.state;
    const { t, companyData } = this.props;

    const columns1 = [
      {
        title: `${t('general.end')}`,
        dataIndex: 'startDate',
        ellipsis: {
          showTitle: false,
        },
        width: '120px',
        render: (text, record) => {
          return {
            props: {
              style: { borderLeft: `5px solid ${projectDeliverableStatusColor(record.deliverableStatus)}` },
            },
            children: (
              <div>
                <Tooltip title={record.notes}>{moment(text).format('MM/YYYY')}</Tooltip>
              </div>
            ),
          };
        },
      },
      {
        title: `${t('general.deliverable')}`,
        dataIndex: 'name',
        key: 'name',
        className: 'twoRowEllipses',
        render: (title, record) => (
          <Tooltip title={title}>
            <Link
              to={{ pathname: `/deliverables/id/${record.deliverableID}` }}
              style={{ color: 'black' }}>
              {title}
            </Link>
          </Tooltip>
        ),
      },
      {
        title: `${t('general.assegnatoA')}`,
        dataIndex: 'ownerFullNameInitials',
        key: 'ownerFullNameInitials',
        width: '100px',
        ellipsis: {
          showTitle: false,
        },
        render: (title, record) => (
          <Popover
            content={
              <ResponsiblePopoverContent
                data={record}
                t={t}
              />
            }
            title={record.ownerFullName}>
            <span style={record.isGenericResponsabile == true ? { color: '#f2883b' } : {}}>{title}</span>
          </Popover>
        ),
      },
    ];

    return (
      <div>
        {!currentData && <Loader2 />}
        {currentData && (
          <>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row tw-mb-4"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                {companyData && (
                  <Card
                    className="custom-card height260"
                    title={t('a3Page.prossimiPasiDecisioniRichieste')}>
                    <Table
                      className="custom-table-section1 project-status"
                      columns={columns1}
                      rowClassName={(record) => 'status-' + record.deliverableStatus}
                      dataSource={deliverableArray}
                      pagination={false}
                      rowKey={(obj) => obj.deliverableID}
                      size="small"
                    />
                  </Card>
                )}
              </Col>

              <Col
                className="gutter-row tw-mb-4"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Card
                  title={t('proggetiPage.henseiLezioniImparate')}
                  className="custom-card text-area height260">
                  {Parser(currentData.hanseiLearnedLessons ? currentData.hanseiLearnedLessons : '')}
                </Card>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Card
                  title={t('a3Page.commentiS4')}
                  className="custom-card text-area height400">
                  {Parser(currentData.comment ? currentData.comment : '')}
                </Card>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Row
                  gutter={{ lg: 24 }}
                  className="mt5 sectionCriticsNewDwsign">
                  <Col
                    className="gutter-row tw-mb-4"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Card
                      style={{ height: '100%' }}
                      className="custom-card text-area height400"
                      title={t('proggetiPage.criticitaRischi')}>
                      <Row gutter={{ lg: 24 }}>
                        <Col
                          className="gutter-row tw-mb-4"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Title
                            title={t('proggetiPage.criticitaRischi')}
                            description={t('a3Page.criticitaRischiDescription')}
                          />
                        </Col>

                        <Col
                          className="gutter-row tw-mb-4"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <Title
                            title={t('proggetiPage.contromisureIdentificate')}
                            description={t('a3Page.contrimisureIdentificateDescription')}
                          />
                        </Col>
                      </Row>

                      {riskAndPrecautionList.map((critic, index) => (
                        <Row
                          className="row-list"
                          gutter={2}
                          key={index}
                          style={{ marginTop: '10px' }}>
                          <Col
                            className="gutter-row vertical-align"
                            xs={{ span: 24 }}
                            sm={{ span: 24 }}
                            md={{ span: 24 }}
                            lg={{ span: 24 }}>
                            <Col
                              className={
                                'gutter-row list-items tw-mb-4  risk-precaution-block' +
                                (critic.status === 1 ? ' red' : '') +
                                (critic.status === 2 ? ' green' : '')
                              }
                              xs={{ span: 24 }}
                              sm={{ span: 24 }}
                              md={{ span: 24 }}
                              lg={{ span: 24 }}>
                              <ArcherContainer strokeColor="red">
                                <div
                                  className="arrow-root"
                                  style={rootStyle}>
                                  <ArcherElement
                                    id="root"
                                    relations={critic.precautions.map((el) => {
                                      return {
                                        targetId: el.precautionID,
                                        targetAnchor: 'left',
                                        sourceAnchor: 'right',
                                        style: { endShape: 'circle', strokeColor: 'grey', strokeWidth: 1 },
                                      };
                                    })}>
                                    <span style={rowStyle}>
                                      {checkRiskSeverity(critic)}
                                      <Divider type="vertical" />
                                      {checkStatus(critic)}
                                      <span>
                                        <Text style={{ padding: '0px 10px' }}> {critic.riskDescription}</Text>
                                      </span>
                                    </span>
                                  </ArcherElement>
                                  {critic.precautions.length > 0 && critic.precautions && (
                                    <List
                                      bordered={false}
                                      className="countermeasures"
                                      dataSource={critic.precautions}
                                      renderItem={(item) => (
                                        <List.Item>
                                          <div style={boxStyle}>
                                            <ArcherElement id={item.precautionID}>
                                              <div>
                                                <Text style={{ padding: '0px 10px' }}>
                                                  {' '}
                                                  {item.precautionDescription}
                                                </Text>
                                              </div>
                                            </ArcherElement>
                                          </div>
                                        </List.Item>
                                      )}
                                    />
                                  )}
                                </div>
                              </ArcherContainer>
                            </Col>
                          </Col>
                        </Row>
                      ))}
                    </Card>
                  </Col>
                </Row>

                <Row
                  gutter={{ lg: 24 }}
                  className="mt5 sectionCritics">
                  <Col
                    className="gutter-row tw-mb-4"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Card className="card-body-bacground height400">
                      <Row style={{ background: '#f1f1f1' }}>
                        <Col
                          className="gutter-row tw-mb-4"
                          xs={{ span: 12 }}
                          sm={{ span: 12 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <div
                            className="ant-list-item-meta-content list-headers"
                            style={{ width: '100%' }}>
                            <h5
                              className="ant-list-item-meta-title"
                              style={{ marginBottom: 0 }}>
                              <Tooltip>{t('proggetiPage.criticitaRischi')}</Tooltip>
                            </h5>
                          </div>
                        </Col>

                        <Col
                          className="gutter-row tw-mb-4"
                          xs={{ span: 12 }}
                          sm={{ span: 12 }}
                          md={{ span: 12 }}
                          lg={{ span: 12 }}>
                          <div
                            className="ant-list-item-meta-content list-headers"
                            style={{ width: '100%' }}>
                            <h5
                              className="ant-list-item-meta-title"
                              style={{ marginBottom: 0 }}>
                              <Tooltip>{t('proggetiPage.contromisureIdentificate')}</Tooltip>
                            </h5>
                          </div>
                        </Col>
                      </Row>
                      <div></div>
                      {riskList.map((critic, index) => (
                        <Row
                          className="row-list"
                          gutter={2}
                          key={index}>
                          <Col
                            className="gutter-row vertical-align"
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}>
                            <Row gutter={2}>
                              <Col
                                className="gutter-row tw-mb-4"
                                xs={{ span: 23 }}
                                sm={{ span: 12 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }}>
                                <span>
                                  {checkRiskSeverity(critic)}
                                  <Divider type="vertical" />
                                  {checkStatus(critic)}
                                  <span> {critic.riskDescription}</span>
                                </span>
                              </Col>

                              {/* <Col className="gutter-row tw-mb-4" xs={{ span: 1 }} sm={{ span: 1 }} md={{ span: 1 }} lg={{ span: 1 }}>
                                                        <CaretRightOutlined style={{ color: "#c1c1c1" }} />
                                                    </Col> */}
                            </Row>
                          </Col>

                          <Col
                            className="gutter-row vertical-align"
                            xs={{ span: 12 }}
                            sm={{ span: 12 }}
                            md={{ span: 12 }}
                            lg={{ span: 12 }}>
                            <Row gutter={2}>
                              <Col
                                className="gutter-row"
                                xs={{ span: 24 }}
                                sm={{ span: 12 }}
                                md={{ span: 12 }}
                                lg={{ span: 12 }}>
                                {critic.precautionDescription && (
                                  <List
                                    bordered={false}
                                    dataSource={[critic.precautionDescription]}
                                    renderItem={(item) => (
                                      <List.Item>
                                        <RightOutlined className="arrow-icon" /> {item}
                                      </List.Item>
                                    )}
                                  />
                                )}
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      ))}
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyData: state.companyData.companyData,
});

export default withTranslation()(connect(mapStateToProps)(Section4PrintPreview));

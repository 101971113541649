import { EditOutlined } from '@ant-design/icons';
import { Button, message, Table, Tooltip } from 'antd';
import classNames from 'classnames';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import ObAnnoService from '../../../../services/matrix/obAnnoService';
import { obAnualSemaphoreColor, statusClass } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';
import EditYearObjectiveModal from './editYearObjectiveModal';

class YearObjectives extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      loadingButton: false,
      modalData: {},
    };
  }

  // open add new record modal
  toggleModal = (rowData) => {
    this.setState({ openModal: !this.state.openModal });
    this.setState({ modalData: rowData });
  };

  // handel modal close
  handleModalCancel = () => {
    this.setState({ openModal: false });
    this.setState({ loadingButton: false });
  };

  //handel update
  updateData = (values, id) => {
    this.setState({ loadingButton: true });
    values['annualGoalID'] = id;
    const payload = values;

    ObAnnoService.updateObiettivoAnnoxMartix(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.handleModalCancel();
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  columns = [
    {
      render: (text, record, index) => {
        return {
          children: (
            <div
              onClick={() => this.props.onRowClick(record.annualGoalID)}
              className={`tw-flex tw-py-1 matrix-row-vertical tw-w-[32px] tw-items-center tw-justify-between ${classNames(
                {
                  'tw-bg-primary selected-row':
                    this.props.selection === 'primary' && this.props.selectedIds?.includes(record.annualGoalID),
                  'tw-bg-primary/30 selected-row':
                    this.props.selection === 'secondary' && this.props.selectedIds?.includes(record.annualGoalID),
                },
              )}`}>
              <div className="tw-flex tw-items-center tw-gap-2 tw-truncate">
                <Tooltip title={record.statusDescription}>
                  <div
                    className={`xMatrix-square-label tw-rotate-90 ${statusClass(record.status)}`}
                    style={{
                      borderLeft: '3px solid',
                      borderColor: obAnualSemaphoreColor(record.qualitySemaphore),
                    }}
                    key={index}>
                    {record.orderingValue}
                  </div>
                </Tooltip>
                <Tooltip title={record.title}>
                  <p className="tw-w-full text-sm tw-truncate tw-text-black">{record.title}</p>
                </Tooltip>
              </div>
              <Button
                type="text"
                className="tw-rotate-180"
                icon={<EditOutlined />}
                onClick={() => this.toggleModal(record)}></Button>
            </div>
          ),
        };
      },
    },
  ];

  removeAnualGoal = (id) => {
    const { selectedXmatrix } = this.props;

    this.setState({ loadingButton: true });
    ObAnnoService.removeObAnno(id, selectedXmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.handleModalCancel();
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  render() {
    const { annualGoals, goalStatusList, t, selectedXmatrix } = this.props;

    return (
      <>
        <EditYearObjectiveModal
          show={this.state.openModal}
          toggle={this.toggleModal}
          handleModalCancel={this.handleModalCancel}
          data={this.state.modalData}
          handleSave={this.updateData}
          loadingButton={this.state.loadingButton}
          goalStatusList={goalStatusList}
          removeAnualGoal={this.removeAnualGoal}
          t={t}
          selectedXmatrix={selectedXmatrix}
        />

        <Table
          className="[&_table]:tw-h-full"
          dataSource={annualGoals}
          pagination={false}
          bordered
          size="small"
          showHeader={false}
          columns={this.columns}
          rowKey={(obj) => obj.annualGoalID}></Table>
      </>
    );
  }
}

export default withTranslation()(YearObjectives);

import { Button } from 'antd';
import i18n from '../../../../i18n';

export const ModalFooterButtons = ({ disabled, modalClose, loadingButton, formId }) => {
  return (
    <div className="tw-flex tw-justify-end tw-gap-2">
      <Button
        onClick={modalClose}
        key="close">
        {i18n.t('buttons.annulla')}
      </Button>
      <Button
        form={formId}
        loading={loadingButton}
        // icon={<SaveOutlined />}
        type="primary"
        htmlType="submit"
        key="submit"
        disabled={disabled}>
        {i18n.t('buttons.salva')}
      </Button>
    </div>
  );
};

import { Form } from 'antd';
import React from 'react';
import './kpi.scss';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, InputNumber, Row, Select, Switch, message } from 'antd';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommonService from '../../services/commonService';
import KpiService from '../../services/pages/kpiService';
import { isUserAdmin } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { kpiPuntualeAcumulato, kpiType, objectCodes } from '../shared/utils/constants';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const { TextArea } = Input;
const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

const NormalJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPointNORMAL"
      initialValue={0}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${props.t('kpiPage.jopNumber')}`));
            }
          },
        },
      ]}>
      <InputNumber
        style={{ width: '100%' }}
        min="0"
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

const PercentageJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPointPERCENTAGE"
      initialValue={0}
      rules={[
        { required: true, message: requiredFields.required },
        {
          validator: async (_, jumpOffPoint) => {
            if (jumpOffPoint > 100 || jumpOffPoint < 0 || isNaN(jumpOffPoint) || typeof jumpOffPoint !== 'number') {
              return Promise.reject(new Error(`${props.t('kpiPage.jopMax100Number')}`));
            }
          },
        },
      ]}>
      <InputNumber
        style={{ width: '100%' }}
        min={0}
        max={100}
        formatter={(value) => `${value} %`}
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

const BooleanJumpOffPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPointBOOLEAN"
      valuePropName="checked"
      initialValue={false}>
      <Switch
        checkedChildren={props.t('kpiPage.fatto')}
        unCheckedChildren={props.t('kpiPage.nonFatto')}
        disabled={props.isRollup}
      />
    </Form.Item>
  );
};

const DisabledJumpOfPoint = (props) => {
  return (
    <Form.Item
      label={props.t('general.jumpOffPoint')}
      name="jumpOffPoint"
      initialValue={null}>
      <InputNumber
        value={null}
        style={{ width: '100%' }}
        min="0"
        disabled={true}
      />
    </Form.Item>
  );
};

class AddNewKpi extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      fieldsChanged: false,
      statusList: null,
      calculationTypeList: null,
      typeList: null,
      acumulatoPuntuale: null,
      rollupChecked: false,
      jumpOffPointComponent: (
        <NormalJumpOffPoint
          jmp={1}
          isRollup={false}
          t={this.props.t}
        />
      ),
      //jumpOffPointComponent: null,
      kpiTypeGlobale: kpiType.globale,
      actualRollup: 0,
      disableJumpOfPointResetGlobalKpi: false,
      selectedType: null,
      selectedCumulatoPuntuale: null,
    };
  }

  componentDidMount() {
    this.retrieveCalculationType();
    this.retrieveType();
    this.retrieveAcumulatoPuntuale();
  }

  // Retrieve list of calculation types
  retrieveCalculationType() {
    const objectCode = objectCodes.calculationTypes;
    CommonService.getCalculationTypeList(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ calculationTypeList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve list of types
  retrieveType() {
    const objectCode = objectCodes.kpiType;
    CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ typeList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve acumulato/puntuale
  retrieveAcumulatoPuntuale() {
    const objectCode = objectCodes.acumulatoPuntuale;
    CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ acumulatoPuntuale: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  addData = (values) => {
    const { rollupChecked, kpiTypeGlobale, actualRollup, disableJumpOfPointResetGlobalKpi } = this.state;

    rollupChecked || disableJumpOfPointResetGlobalKpi
      ? (values['jumpOffPoint'] = 0)
      : Object.keys(values).forEach(function (fieldName) {
          if (
            fieldName === 'jumpOffPointBOOLEAN' ||
            fieldName === 'jumpOffPointPERCENTAGE' ||
            fieldName === 'jumpOffPointNORMAL'
          ) {
            if (fieldName === 'jumpOffPointBOOLEAN') {
              if (values[fieldName] === true) {
                values[fieldName] = 1;
              }
              if (values[fieldName] === false) {
                values[fieldName] = 0;
              }
            }
            values['jumpOffPoint'] = values[fieldName];
            delete values[fieldName];
          }
        });

    values['isRollupKPI'] = rollupChecked;
    values['type'] = kpiTypeGlobale;
    values['actualYTD'] = actualRollup;

    if (disableJumpOfPointResetGlobalKpi) {
      values['jumpOffPoint'] = 0;
    }

    this.setState({ loadingButton: true });
    KpiService.addKpi(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.props.history.push('/kpi');
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  _validateKpiName = (_rule, value, callback) => {
    KpiService.checkIfKpiNameExist(value)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (resp.responseObject.value) {
            callback(requiredFields.validateKpiName);
          }
          callback();
        }
        callback();
      })
      .catch((error) => {
        message.error(notifyMessages.errorAccured);
      });
  };

  onCalculationTypeChange = (value, option) => {
    const { rollupChecked } = this.state;
    const jumpOffPointCalculationType = value;
    let t = this.props.t;

    if (jumpOffPointCalculationType === 1 || jumpOffPointCalculationType === 2) {
      this.setState({
        jumpOffPointComponent: (
          <NormalJumpOffPoint
            isRollup={rollupChecked}
            t={t}
          />
        ),
      });
    } else if (jumpOffPointCalculationType === 3) {
      this.setState({
        jumpOffPointComponent: (
          <PercentageJumpOffPoint
            isRollup={rollupChecked}
            t={t}
          />
        ),
      });
    } else if (jumpOffPointCalculationType === 4) {
      this.setState({
        jumpOffPointComponent: (
          <BooleanJumpOffPoint
            isRollup={rollupChecked}
            t={t}
          />
        ),
      });
    } else {
      <NormalJumpOffPoint t={t} />;
    }
  };

  handelRollupChecked = (value) => {
    this.setState({ rollupChecked: value });
    if (value) {
      this.setState({ kpiTypeGlobale: kpiType.globale, selectedType: kpiType.globale }, () => {
        this.checkDisableJumpOfPointResetGlobale();
      });
      this.setState({ actualRollup: null });
    }
  };

  checkDisableJumpOfPointResetGlobale = () => {
    const { selectedType, selectedCumulatoPuntuale } = this.state;
    const { companyData } = this.props;

    if (
      companyData.resetGlobalKPI &&
      selectedCumulatoPuntuale === kpiPuntualeAcumulato.acumulato &&
      selectedType === kpiType.globale
    ) {
      this.setState({ disableJumpOfPointResetGlobalKpi: true });
    } else {
      this.setState({ disableJumpOfPointResetGlobalKpi: false });
    }
  };

  onTypeChange = (option) => {
    const { rollupChecked } = this.state;

    this.setState({ selectedType: option }, () => {
      this.checkDisableJumpOfPointResetGlobale();
    });

    if (!rollupChecked) {
      this.setState({ kpiTypeGlobale: option });
    }
  };

  onCumulatoPuntualeChange = (option) => {
    this.setState({ selectedCumulatoPuntuale: option }, () => {
      this.checkDisableJumpOfPointResetGlobale();
    });
  };

  onActualChange = (value) => {
    const { rollupChecked } = this.state;
    if (!rollupChecked) {
      this.setState({ actualRollup: value });
    }
  };

  render() {
    const {
      calculationTypeList,
      typeList,
      acumulatoPuntuale,
      loadingButton,
      jumpOffPointComponent,
      rollupChecked,
      kpiTypeGlobale,
      actualRollup,
      disableJumpOfPointResetGlobalKpi,
    } = this.state;
    const { userData } = this.props;
    let t = this.props.t;

    return (
      <div className="edit-obietivo-anno-wrapper">
        <>
          <div className="site-card-wrapper">{t('kpiPage.aggiungiKpi')}</div>
          <Card>
            <Form
              name="add_ob_lungo"
              layout="vertical"
              {...formItemLayout}
              onFinish={this.addData}
              onValuesChange={() => {
                this.setState({ fieldsChanged: true });
              }}>
              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <div className="button-wrapper">
                    <Form.Item wrapperCol={{ span: 24 }}>
                      <Button
                        loading={loadingButton}
                        type="primary"
                        htmlType="submit"
                        className="tw-float-end"
                        disabled={!this.state.fieldsChanged}>
                        <SaveOutlined /> {t('buttons.salva')}
                      </Button>
                    </Form.Item>
                  </div>
                </Col>
              </Row>

              <Row style={{ justifyContent: 'center' }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 16 }}>
                  <Card title={t('general.informazioniGenerali')}>
                    <Row gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          //hidden={true}
                          className="main-container"
                          label={t('kpiPage.gerarchicho')}>
                          <Switch
                            defaultChecked={rollupChecked}
                            onChange={(value) => this.handelRollupChecked(value)}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 12 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          className="main-container"
                          label={t('kpiPage.isVisible')}
                          name="isVisible"
                          initialValue={true}>
                          <Switch
                            defaultChecked={true}
                            disabled={!isUserAdmin(userData)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          className="main-container"
                          label={t('general.nome')}
                          name="name"
                          rules={[
                            { required: true, message: requiredFields.required },
                            {
                              validator: this._validateKpiName,
                            },
                          ]}>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          className="main-container"
                          label={t('kpiPage.tipoCalcolo')}
                          name="calculationType"
                          hasFeedback
                          rules={[{ required: true, message: requiredFields.required }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onChange={(value, option) => this.onCalculationTypeChange(value, option)}>
                            {calculationTypeList &&
                              calculationTypeList.map((item) => (
                                <Option
                                  value={item.objectCodeListID}
                                  key={item.objectCodeListID}>
                                  {item.description}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        {jumpOffPointComponent &&
                          !rollupChecked &&
                          !disableJumpOfPointResetGlobalKpi &&
                          jumpOffPointComponent}
                        {(rollupChecked || disableJumpOfPointResetGlobalKpi) && <DisabledJumpOfPoint t={t} />}
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          className="main-container"
                          label={t('kpiPage.cumulatoPuntuale')}
                          name="parentType"
                          hasFeedback
                          rules={[{ required: true, message: requiredFields.required }]}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onChange={(value, option) => this.onCumulatoPuntualeChange(value, option)}>
                            {acumulatoPuntuale &&
                              acumulatoPuntuale.map((item) => (
                                <Option
                                  value={item.objectCodeListID}
                                  key={item.objectCodeListID}>
                                  {item.description}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          className="main-container"
                          label={t('kpiPage.unitaMisura')}
                          name="unitOfMeasure">
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        {!rollupChecked && (
                          <Form.Item
                            className="main-container"
                            label={t('general.actualYtd')}
                            name="actualYTD">
                            <InputNumber
                              min="0"
                              style={{ width: '100%' }}
                              onChange={this.onActualChange}
                              disabled={!rollupChecked}
                            />
                          </Form.Item>
                        )}

                        {rollupChecked && (
                          <Form.Item
                            className="main-container"
                            label={t('general.actualYtd')}>
                            <InputNumber
                              value={actualRollup}
                              style={{ width: '100%' }}
                              disabled={rollupChecked}
                            />
                          </Form.Item>
                        )}
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 8 }}>
                        {!rollupChecked && (
                          <Form.Item
                            className="main-container"
                            label={t('general.tipo')}
                            name="type"
                            hasFeedback
                            rules={[{ required: true, message: requiredFields.required }]}>
                            <Select
                              disabled={rollupChecked}
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}
                              onChange={(option) => this.onTypeChange(option)}>
                              {typeList &&
                                typeList.map((item) => (
                                  <Option
                                    value={item.objectCodeListID}
                                    key={item.objectCodeListID}>
                                    {item.description}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        )}

                        {rollupChecked && (
                          <Form.Item
                            className="main-container"
                            label={t('general.tipo')}
                            initialValue={kpiType.globale}>
                            <Select
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}
                              disabled={rollupChecked}
                              defaultValue={kpiTypeGlobale}>
                              {/* <Option value={kpiTypeGlobale} key={kpiTypeGlobale}>Globale</Option> */}

                              {typeList &&
                                typeList.map((item) => (
                                  <Option
                                    value={item.objectCodeListID}
                                    key={item.objectCodeListID}>
                                    {item.description}
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        )}
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          className="main-container"
                          label={t('general.nota')}
                          name="additionalNote">
                          <TextArea rows={4} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>
              </Row>
            </Form>
          </Card>
        </>
      </div>
    );
  }
}

// export default (withTranslation()(AddNewKpi))

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default withTranslation()(connect(mapStateToProps)(AddNewKpi));

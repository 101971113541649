import { Form } from 'antd';
import React from 'react';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, InputNumber, Row, Select, message } from 'antd';
import { withTranslation } from 'react-i18next';
import CommonService from '../../services/commonService';
import CommitieService from '../../services/pages/commitieServices';
import { formItemLayout } from '../shared/utils/constants';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';
import './commitie.scss';

class AddNewCommities extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      loadingButton: false,
      participantList: null,
      revisoriList: null,
    };
  }

  componentDidMount() {
    this.retrieveParticipants();
    this.retrieveRevisori();
  }

  addData = (values) => {
    this.setState({ loadingButton: true });
    CommitieService.addCommitie(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.setState({ loadingButton: false });
          this.props.history.push('/committies');
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  async retrieveParticipants() {
    await CommonService.getAvailableCommitteeMembers('', false)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          this.setState({ participantList: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveRevisori() {
    await CommonService.getAvailableCommitteeMembers('', true)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          this.setState({ revisoriList: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  render() {
    const { loadingButton, participantList, revisoriList } = this.state;
    let t = this.props.t;

    return (
      <div className="edit-commitie-wrapper">
        <div className="site-card-wrapper">{t('comitatiPage.aggiungiCommitato')}</div>
        <Card>
          <Form
            name="add_ob_lungo"
            layout="vertical"
            {...formItemLayout}
            onFinish={this.addData}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <div className="button-wrapper">
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Button
                      loading={loadingButton}
                      type="primary"
                      className="tw-float-end"
                      htmlType="submit"
                      disabled={!this.state.fieldsChanged}>
                      <SaveOutlined />
                      {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Card title={t('general.informazioniGenerali')}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      {' '}
                      <Form.Item
                        label={t('general.nome')}
                        name="name"
                        rules={[{ required: true, message: requiredFields.required }]}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item
                        label={t('comitatiPage.activitiesDuration')}
                        name="activitiesDuration"
                        rules={[{ required: true, message: requiredFields.required }]}
                        initialValue={45}>
                        <InputNumber
                          min={0}
                          style={{ width: '100%' }}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item
                        label={t('comitatiPage.repositoryLink')}
                        name="repositoryLink"
                        extra="Link format: http://exmaple.com/"
                        initialValue={''}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item
                        label={t('comitatiPage.participants')}
                        name="users"
                        hasFeedback>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          mode="multiple"
                          maxTagCount="responsive"
                          options={participantList}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          loading={!participantList}
                        />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 12 }}
                      md={{ span: 8 }}
                      lg={{ span: 8 }}>
                      <Form.Item
                        label={t('comitatiPage.revisori')}
                        name="revisoriMembers"
                        hasFeedback>
                        <Select
                          style={{ width: '100%' }}
                          placeholder={t('general.seleziona')}
                          mode="multiple"
                          maxTagCount="responsive"
                          options={revisoriList}
                          showSearch
                          filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                          }
                          loading={!revisoriList}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(AddNewCommities);

import { LogoutOutlined } from '@ant-design/icons';
import { Button, Card, Typography } from 'antd';
import React from 'react';
import notFoundImg from '../../../../assets/notFound.png';
import { msalApp } from '../../../../config/auth/clientAuthProvider';

const { Paragraph } = Typography;

class Unauthorized extends React.Component {
  constructor(props) {
    super(props);
    this.t = this.props.t;
  }

  onSignOut() {
    msalApp.logout();
  }

  componentDidMount() {
    // var timeleft = 15;
    // var downloadTimer = setInterval(function () {
    //     if (timeleft <= 0) {
    //         clearInterval(downloadTimer);
    //         SignOut();
    //     }
    //     document.getElementById("timer").innerHTML = "Verrai disconnesso automaticamente in... " + timeleft + " secondi";
    //     timeleft -= 1;
    // }, 1000);
  }

  render() {
    return (
      <div className="tw-h-screen tw-flex tw-items-center tw-justify-center">
        <Card className="tw-max-w-[450px]">
          <div className="tw-flex tw-items-center tw-justify-center tw-flex-col">
            <div>
              <Typography.Title level={2}>Non autorizzato</Typography.Title>
              <Paragraph>Non sei autorizzato ad accedere alla pagina che stai cercando.</Paragraph>
              <Button
                onClick={this.onSignOut}
                href="/"
                type="primary"
                icon={<LogoutOutlined />}>
                Esci
              </Button>
            </div>

            <img
              className="tw-mt-8 tw-max-w-[200px]"
              src={notFoundImg}
              alt="Page not found"
            />
          </div>
        </Card>
      </div>
    );
  }
}

export default Unauthorized;

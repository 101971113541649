import { Card, Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Routines from 'src/components/a3/section1/Routines';
import A3BlueTitle from 'src/components/A3BlueTitle';
import { GetGoalGroundtoActA3ResponseDto, GetProjectResponseDto } from 'src/connectors/backend';
import Section1Service from '../../../services/a3/section1Service';
import ProjectGoalEditor from '../../projects/projectGoalEditor/goalEditor.jsx';
import ReasonToAct from '../../projects/projectReasonToAct/reasonToAct.jsx';
import Loader2 from '../../shared/components/loader2/loader2';
import TableLayout from '../../shared/tableLayout';
import IndicatoriTarget from './indicatoriTarget';

interface Section1FormProps {
  id: string;
  hasUserAccess: boolean;
  currentProject: GetProjectResponseDto;
}

const Section1Form = (props: Section1FormProps) => {
  const { id, hasUserAccess } = props;
  const [currentData, setCurrentData] = useState<GetGoalGroundtoActA3ResponseDto | null>(null);
  const [currentProject, setCurrentProject] = useState<any>(null);

  const { t } = useTranslation();

  useEffect(() => {
    getGoalGroundtoActA3(id);
  }, [id]);

  useEffect(() => {
    if (props.currentProject) {
      setCurrentProject(props.currentProject);
    }
  }, [props.currentProject]);

  const getGoalGroundtoActA3 = (id: string) => {
    Section1Service.getGoal(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setCurrentData(resp.responseObject?.value || {});
        }
      })
      .catch((error: any) => {});
  };

  return (
    <div>
      <div>
        <h1 className="tw-text-3xl">{t('a3Page.motivoPerAgire')}</h1>

        {!currentData && <Loader2 />}
        {currentData && (
          <div className="tw-flex tw-flex-col tw-gap-8">
            {/* Motivi per agire e risultato atteso */}

            <Row
              gutter={32}
              className="tw-border-b">
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                className="tw-border-r tw-p-6">
                <Card
                  className="!tw-shadow-none"
                  bordered={false}>
                  <ReasonToAct
                    isEditable={!hasUserAccess}
                    isPresentationMode={false}
                    key={'groundToAct'}
                    currentProject={currentData}
                  />
                </Card>
              </Col>
              <Col
                xs={{ span: 24 }}
                md={{ span: 12 }}
                className="tw-p-6">
                <Card
                  className="!tw-shadow-none"
                  bordered={false}>
                  <ProjectGoalEditor
                    isEditable={!hasUserAccess}
                    isPresentationMode={false}
                    key={'projectGoal'}
                    currentProject={currentData}
                  />
                </Card>
              </Col>
            </Row>

            <Routines
              currentProject={currentProject}
              id={id}
            />

            {/* Indicatori e target */}

            <TableLayout
              title={
                <A3BlueTitle
                  title={t('a3Page.indicatoriTarget')}
                  href={`/progetti/id/${id}/?tab=8`}
                />
              }>
              <TableLayout.Content>
                <IndicatoriTarget currentProject={currentProject} />
              </TableLayout.Content>
            </TableLayout>
          </div>
        )}
      </div>
    </div>
  );
};

export default Section1Form;

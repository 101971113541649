import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import './loader.scss';

export const Loader = (props) => {
  const { promiseInProgress } = usePromiseTracker();
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  return (
    promiseInProgress && (
      <div
        className="spinner"
        align="center">
        <Spin indicator={antIcon} />
        <p className="loading">Loading...</p>
      </div>
    )
  );
};

export default Loader;

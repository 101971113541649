import { DownCircleOutlined, FolderOpenOutlined, UpCircleOutlined } from '@ant-design/icons';
import { Avatar, Button, Card, Col, Divider, Row, Space, Tooltip, Typography } from 'antd';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  BudgetTooltip,
  ProjectBudgetSemaphore,
  ProjectQualitySemaphore,
  ProjectTimeSemaphore,
  QualityTooltip,
  TimeTooltip,
} from '../shared/semaphores/semaphores';
import { projectStatus, semaphoreStatus, userRoles } from '../shared/utils/constants';
import { statusClass } from '../shared/utils/functions';
import './dashboard.scss';

const { Text, Paragraph } = Typography;

const checkProjectSemaphoreColor = (status) => {
  if (status === semaphoreStatus.s1) {
    return 'projectGreenColorIndicator';
  } else if (status === semaphoreStatus.s2) {
    return 'projectYellowColorIndicator';
  } else if (status === semaphoreStatus.s3) {
    return 'projectRedColorIndicator';
  } else {
    return 'projectGreyColorIndicator';
  }
};

const CardExtras = ({ status, project, companyData, t, handleToggle, isActive, expanded }) => {
  return (
    <>
      <Row>
        <Tooltip
          placement="top"
          title={
            <TimeTooltip
              data={project}
              t={t}
            />
          }>
          <Col
            className={
              'gutter-row lower-section header-border b-l ' + checkProjectSemaphoreColor(project.timeSemaphore)
            }
            xs={8}
            sm={8}>
            <ProjectTimeSemaphore data={project} />
          </Col>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <BudgetTooltip
              data={project}
              t={t}
              companyData={companyData}
            />
          }>
          <Col
            className={'gutter-row lower-section header-border ' + checkProjectSemaphoreColor(project.budgetSemaphore)}
            xs={8}
            sm={8}>
            <ProjectBudgetSemaphore data={project} />
          </Col>
        </Tooltip>

        <Tooltip
          placement="top"
          title={
            <QualityTooltip
              data={project}
              t={t}
            />
          }>
          <Col
            className={'gutter-row lower-section b-r ' + checkProjectSemaphoreColor(project.qualitySemaphore)}
            xs={8}
            sm={8}>
            <ProjectQualitySemaphore data={project} />
          </Col>
        </Tooltip>
      </Row>

      <Row className="card-extra-name">
        <Space
          id="statusSection"
          direction="horizontal">
          <div className={'status ' + statusClass(project.status)}></div>
          <Text>{status}</Text>
        </Space>

        <Space direction="horizontal">
          <Text>{project.projectCode}</Text>
        </Space>

        <Space direction="horizontal">
          <Tooltip title={t('proggetiPage.passaProgetto')}>
            <Link to={{ pathname: `/progetti/id/${project.projectID}` }}>
              <FolderOpenOutlined />
            </Link>
          </Tooltip>
          {isActive && !expanded && (
            <Button onClick={handleToggle}>
              <UpCircleOutlined />
            </Button>
          )}
          {!isActive && !expanded && (
            <Button onClick={handleToggle}>
              <DownCircleOutlined />
            </Button>
          )}
        </Space>
      </Row>
    </>
  );
};

const checkUpdatedWeek = (updatedDate) => {
  let currentWeek = moment(moment(), 'MMDDYYYY').isoWeek();
  let updatedWeek = moment(updatedDate, 'MMDDYYYY').isoWeek();
  if (updatedWeek) {
    if (updatedWeek === currentWeek) {
      return true;
    }
    return false;
  }
  return false;
};

class ProjectCard extends Component {
  constructor(props) {
    super(props);

    this.state = {
      projects: [],
      dashboardData: [],
      isActive: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.expanded !== this.state.isActive) {
      this.setState({ isActive: nextProps.expanded });
    }
  }

  handleToggle = () => {
    const { isActive } = this.state;
    this.setState({ isActive: !isActive });
  };

  render() {
    const { project, companyData, t, expanded } = this.props;
    const { isActive } = this.state;
    const formatedDate = moment(project.lastUpdated).format('MMDDYYYY');
    const updatedWeek = moment(formatedDate, 'MMDDYYYY').isoWeek();

    let teamLeader = project.users.find((x) => x.role === userRoles.teamLeader);
    let sponsor = project.users.find((x) => x.role === userRoles.sponsor);

    return (
      <Card
        className={
          'projectCard !tw-shadow-none [&_.ant-card-body]:tw-flex-1 tw-flex tw-flex-col tw-min-h-full' +
          (isActive && expanded ? ' expand ant-card-body-minHeight' : '')
        }
        extra={
          <CardExtras
            handleToggle={this.handleToggle}
            status={project.statusDescription}
            project={project}
            companyData={companyData}
            t={t}
            isActive={isActive}
            expanded={expanded}
          />
        }>
        <div className="tw-flex tw-flex-1 tw-flex-col tw-p-4">
          <div className="tw-mb-2">
            {teamLeader && (
              <Space direction="horizontal">
                {teamLeader.profilePhoto ? (
                  <Avatar
                    size={'small'}
                    src={teamLeader.profilePhoto}
                  />
                ) : (
                  <Avatar size={'small'}>{teamLeader.initials ? teamLeader.initials : '-'}</Avatar>
                )}
                <Text>{teamLeader.fullName}</Text>
                {/* <Divider type="vertical" /> */}
                {/* <Text style={{ color: '#9b9a9a' }}>{teamLeader.role}</Text> */}
              </Space>
            )}
          </div>

          <div className="tw-min-h-20">
            <Tooltip title={project.name}>
              <Typography.Title
                level={4}
                ellipsis={{ rows: 2 }}>
                {project.name}
              </Typography.Title>
            </Tooltip>
          </div>

          <div className="tw-mt-auto">
            {checkUpdatedWeek(formatedDate) ? (
              <p
                style={{
                  color: '#16a34a',
                }}>
                {t('proggetiPage.settimanaAggiornamento')}: {updatedWeek} |{' '}
                {moment(new Date(project.lastUpdated), 'MMDDYYYY').year()}
              </p>
            ) : (
              <p
                style={{
                  color: '#ef4444',
                }}>
                {t('proggetiPage.settimanaAggiornamento')}: {updatedWeek} |{' '}
                {moment(new Date(project.lastUpdated), 'MMDDYYYY').year()}
              </p>
            )}
          </div>

          {(expanded || isActive) && (
            <>
              <Divider className="cardDivider !tw-my-4" />
              {sponsor && (
                <Space
                  direction="horizontal"
                  className="tw-ml-2">
                  {sponsor.profilePhoto ? (
                    <Avatar
                      size={'small'}
                      src={sponsor.profilePhoto}
                    />
                  ) : (
                    <Avatar size={'small'}>{sponsor.initials ? sponsor.initials : '-'}</Avatar>
                  )}
                  <Text style={{ fontSize: '16px', color: '#012c60' }}>{sponsor.fullName}</Text>
                  <Divider type="vertical" />
                  <Text style={{ color: '#9b9a9a' }}>{sponsor.role}</Text>
                </Space>
              )}

              <span className="wrap-meta tw-mt-4">
                <p className="subtitle">{t('proggetiPage.progettoPrincipale')}:</p>
                <Tooltip
                  placement="top"
                  title={project.parentProjectName}>
                  <Paragraph
                    className="description"
                    ellipsis={{ rows: 1 }}>
                    {project.parentProjectName}
                  </Paragraph>
                </Tooltip>
              </span>

              <span className="wrap-meta">
                <p className="subtitle">{t('general.divisione')}:</p>
                <Paragraph
                  className="description"
                  ellipsis={{ rows: 1 }}>
                  {project.divisionName}
                </Paragraph>
              </span>
              <div className="tw-grid tw-grid-cols-2">
                <span className="wrap-meta">
                  <p className="subtitle">{t('general.start')}:</p>
                  <p className="description">{moment(project.startDate).format('DD/MM/YYYY')}</p>
                </span>

                <span className="wrap-meta">
                  {project.endDate > project.lastUpdated && project.status === projectStatus.attivo ? (
                    <span className="subtitle">{t('general.end')}:</span>
                  ) : (
                    <Row
                      direction="horizontal"
                      style={{ alignItems: 'center' }}>
                      <span className="subtitle">{t('general.end')}</span>
                      <span className="delay"> </span>
                    </Row>
                  )}
                  <span className="description">{moment(project.endDate).format('DD/MM/YYYY')}</span>
                </span>
              </div>
            </>
          )}
        </div>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  companyData: state.companyData.companyData,
});

export default connect(mapStateToProps)(ProjectCard);

import i18n from '../../../i18n';

const messages = {};

function fillNotifyMessages() {
  messages.errorAccured = i18n.t('notifications.errorAccured');
  messages.retrieveFailed = i18n.t('notifications.retrieveFailed');
  messages.updateSuccess = i18n.t('notifications.updateSuccess');
  messages.updateFailed = i18n.t('notifications.updateFailed');
  messages.addSuccess = i18n.t('notifications.addSuccess');
  messages.addFailed = i18n.t('notifications.addFailed');
  messages.deleteSuccess = i18n.t('notifications.deleteSuccess');
  messages.deleteFailed = i18n.t('notifications.deleteFailed');
  messages.loadMenuFailed = i18n.t('notifications.loadMenuFailed');
  messages.deleteCommitieFailed = i18n.t('notifications.deleteCommitieFailed');
  messages.changeCompanySuccess = i18n.t('notifications.changeCompanySuccess');
  messages.changeCompanyFailed = i18n.t('notifications.changeCompanyFailed');
  messages.uploadSuccess = i18n.t('notifications.uploadSuccess');
  messages.uploadFailed = i18n.t('notifications.uploadFailed');
  messages.actionSucces = i18n.t('notifications.actionSuccess');
  messages.mailSendSuccess = i18n.t('notifications.mailSendSuccess');
  messages.mailSendError = i18n.t('notifications.mailSendError');
  messages.dbNameStartWithNumber = i18n.t('notifications.dbNameStartWithNumber');
}

fillNotifyMessages();
i18n.on('languageChanged init', () => {
  fillNotifyMessages();
});
export const notifyMessages = messages;

const requiredFieldsMessages = {};
function fillRequiredFields() {
  requiredFieldsMessages.required = i18n.t('notifications.required');
  requiredFieldsMessages.validateKpiName = i18n.t('notifications.validateKpiName');
  requiredFieldsMessages.validateProjectCode = i18n.t('notifications.validateProjectCode');
  requiredFieldsMessages.uploadCheckFileType = i18n.t('notifications.uploadCheckFileType');
  requiredFieldsMessages.uploadCheckFileSize = i18n.t('notifications.uploadCheckFileSize');
  requiredFieldsMessages.validateEndDate = i18n.t('notifications.validateEndDate');
  requiredFieldsMessages.validateRoutineDates = i18n.t('notifications.validateRoutineDates');
  requiredFieldsMessages.responsabileRequired = i18n.t('notifications.responsabileRequired');
}

fillRequiredFields();
i18n.on('languageChanged init', () => {
  fillRequiredFields();
});
export const requiredFields = requiredFieldsMessages;

// export const requiredFields = {
//   required: "Campo è obbligatorio ",
//   validateKpiName: "Nome Kpi esiste già ",
//   validateProjectCode: "Codice progetto esiste già ",
//   uploadCheckFileType: "Puoi caricare solo file JPG/PNG file!",
//   uploadCheckFileSize: "L'immagine deve essere più piccola di 2MB!",
//   validateEndDate: "Data fine deve essere maggiore di data di inizio ",
// };

// export const notifyMessages = {
//     errorAccured: "Si è verificato un errore. Riprova ",
//     retrieveFailed: "Impossibile caricare i dati. Riprova ",
//     updateSuccess: "Aggiornamento effettuato con successo",
//     updateFailed: "Aggiornamento non riuscito",
//     addSuccess: "Inserimento effettuato con successo",
//     addFailed: "Inserimento non riuscito",
//     deleteSuccess: "Informazioni rimosse con successo",
//     deleteFailed: "Rimosse non riuscito",
//     loadMenuFailed: "Impossibile caricare la barra di navigazione. Riprova ",
//     deleteCommitieFailed: "Cancellazione fallita",
//     changeCompanySuccess: "Cambia azienda...",
//     changeCompanyFailed: "Cambia azienda non riuscito",
//     uploadSuccess: "Il caricamento effettuato con successo",
//     uploadFailed: "Il caricamento non riuscito",
//   };

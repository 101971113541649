// const colorPalete = ["#660066", "#FF9999", "#3399ff", "#0CB773", "#9999FF", "#9966FF", "#CC00CC", "#00FFCC", "#006699", "#33CCFF", "#bae6ff", "#d4bbff"]

const colorPalette = [
  '#22C55E',
  '#3B82F6',
  '#dc2626',
  '#EAB308',
  '#19a3c3',
  '#a05ad5',
  '#d79139',
  '#db3a9b',
  '#84aabd',
  '#73cf98',
  '#bae6ff',
  '#d4bbff',
];

export const perStatusDeliverableChartOptions = {
  chart: {
    renderTo: 'perStatus',
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    width: 350,
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    x: 0,
    y: 100,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      colors: colorPalette,
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [],
};

export const perStatusProgettiChartOptions = {
  chart: {
    renderTo: 'perStatus',
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    width: 330,
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    x: 0,
    y: 100,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      colors: colorPalette,
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [],
};

export const perTimeDeliverableChartOptions = {
  chart: {
    renderTo: 'perTime',
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    width: 350,
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    x: 0,
    y: 100,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      colors: colorPalette,
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [],
};

export const perStatusActivityChartOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    height: 200,
    width: 250,
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    x: 0,
    y: 100,
  },
  title: {
    text: '',
  },
  tooltip: {
    pointFormat: '<b>{point.y}</b>',
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      colors: colorPalette,
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [],
};

export const perTimeActivityChartOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    height: 200,
    width: 260,
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    x: 0,
    y: 100,
  },
  tooltip: {
    pointFormat: '<b>{point.y}</b>',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      colors: colorPalette,
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [],
};

export const perTimeDashboardActivityChartOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    width: 350,
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    x: 0,
    y: 100,
  },
  tooltip: {
    pointFormat: '<b>{point.y}</b>',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      colors: colorPalette,
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [],
};

export const perStatusDashboardActivityChartOptions = {
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
    width: 350,
  },
  legend: {
    align: 'right',
    verticalAlign: 'top',
    layout: 'vertical',
    x: 0,
    y: 100,
  },
  title: {
    text: '',
  },
  tooltip: {
    pointFormat: '<b>{point.y}</b>',
  },
  credits: {
    enabled: false,
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      colors: colorPalette,
      dataLabels: {
        enabled: false,
      },
      showInLegend: true,
    },
  },
  series: [],
};

export const resurceWorkloadChartOptionsOptions = {
  chart: {
    height: (6 / 16) * 100 + '%', // 16:9 ratio
    type: 'bar',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    min: 0,
    title: {
      text: '',
    },
  },
  tooltip: {
    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
    pointFormat:
      '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
      '<td style="padding:0"><b>{point.y:.1f}</b></td></tr>',
    footerFormat: '</table>',
    shared: true,
    useHTML: true,
  },
  plotOptions: {
    bar: {
      pointPadding: 0.3,
      borderWidth: 0,
    },
  },
  legend: {
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
    x: -40,
    y: 80,
    floating: true,
    borderWidth: 0.5,
  },
  colors: colorPalette,
  series: [],
};

export const xMatrixFilterChartOptions = {
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    min: 0,
    title: {
      text: '',
    },
  },
  tooltip: {
    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
    shared: true,
  },
  plotOptions: {
    column: {
      stacking: 'normal',
    },
  },
  colors: colorPalette,
  series: [],
};

export const kpiDetailsPasYearsChartOptions = {
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: '',
    },
  },
  tooltip: {
    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
    shared: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  colors: ['#989898', '#0e72a6'],
  series: [],
};

export const kpiDetailsChartOptions = {
  chart: {
    type: 'column',
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    categories: [],
    crosshair: true,
  },
  yAxis: {
    min: 0,
    title: {
      text: '',
    },
  },
  tooltip: {
    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y}</b> <br/>',
    shared: true,
  },
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
    },
  },
  colors: ['#6B7280', '#3B82F6'],
  series: [],
};

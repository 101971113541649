import { LogoutOutlined } from '@ant-design/icons';
import { Tabs } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import UserProjects from '../../users/userProjects';
import PerStatusProgettiChart from './perStatusChart';
import { withTranslation } from 'react-i18next';

const RedirectLink = () => {
  return (
    <Link to={{ pathname: `/progettihoshinkanri` }}>
      {' '}
      <LogoutOutlined className="hrefIcon" />{' '}
    </Link>
  );
};

class PersonalDashboardProgetti extends Component {
  render() {
    let { t, userData } = this.props;

    return (
      <Tabs
        className="no-sticky tw-px-2"
        defaultActiveKey="1"
        tabBarExtraContent={<RedirectLink />}
        items={[
          {
            label: <>{t('bachechaPersonalePage.perStatus')}</>,
            key: '1',
            children: (
              <>
                <PerStatusProgettiChart t={t} />
                <UserProjects
                  isCompact={true}
                  toFilter={'tmtl'}
                  userId={userData.userId}
                />
              </>
            ),
          },
        ]}></Tabs>
    );
  }
}

export default withTranslation()(PersonalDashboardProgetti);

import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Card, Col, Modal, Row, Select, Switch } from 'antd';
import React, { Component } from 'react';
import '../../dashboard/dashboard.scss';
import { projectStatus } from '../../shared/utils/constants';

const { Option } = Select;

class SintesiFilters extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterHasValue: false,
      teamLider: [],
      sponsor: [],
      status: projectStatus.attivo,
      division: [],
      committie: this.props.committeeId ? this.props.committeeId : [],
      projectCode: [],
      selectNotRelatedProjects: true,
      filtersLabelList: [
        { value: 'statusFilter', label: this.props.t('dashboardProgettiPage.statoProgetto') },
        { value: 'selectNotRelatedProjects', label: this.props.t('proggetiPage.progettiSenzaRelazione') },
      ],
    };
  }

  componentDidMount() {}

  checkFilterExistOnFilterLabelsList = (filterObj) => {
    let { filtersLabelList } = this.state;
    const found = filtersLabelList.some((el) => el.value === filterObj.value);
    return found;
  };

  addItemToFilterLabelList = (object) => {
    let { filtersLabelList } = this.state;
    if (!this.checkFilterExistOnFilterLabelsList(object)) {
      filtersLabelList.push(object);
    }
  };

  handleChange = (filter, val, filterLabelObject) => {
    this.addItemToFilterLabelList(filterLabelObject);
    if (val) {
      this.setState({ ...this.state, [filter]: val.value });
      const filterObj = { filter: filter, value: val?.value ? String(val.value) : '' };
      this.props.filtersChanged(filterObj);
    }
  };

  handleProjectRelationFilterChange = (filter, val, filterLabelObject) => {
    const { filtersLabelList } = this.state;
    if (!val) {
      const foudElement = filtersLabelList.findIndex((obj) => obj.value === 'selectNotRelatedProjects');
      if (foudElement > -1) {
        let newArray = filtersLabelList.splice(foudElement, 1);
        this.setState({ filtersLabelList: newArray });
      }
    } else {
      this.addItemToFilterLabelList(filterLabelObject);
    }
    this.setState({ ...this.state, [filter]: val });
    const filterObj = { filter: filter, value: val };
    this.props.filtersChanged(filterObj);
  };

  handleSubmit = () => {
    let { filtersLabelList } = this.state;
    this.props.applyFilters(filtersLabelList);
  };

  handelClear = () => {
    this.setState({ teamLider: [] });
    this.setState({ sponsor: [] });
    this.setState({ status: [] });
    this.setState({ division: [] });
    this.setState({ committie: [] });
    this.setState({ projectCode: [] });
    this.setState({ selectNotRelatedProjects: false });

    this.props.resetFilters();
    this.setState({ filterHasValue: false });
    this.setState({ filtersLabelList: [] });
  };

  handelFilterLabelsClear = (fiterLabelsListFromParent, filterName) => {
    this.setState({ filtersLabelList: fiterLabelsListFromParent });
    this.clearFiltersSections(filterName);
  };

  clearFiltersSections = (filterName) => {
    switch (filterName) {
      case 'teamLeaderFilter':
        this.setState({ teamLider: [] });
        break;
      case 'sponsorFilter':
        this.setState({ sponsor: [] });
        break;
      case 'statusFilter':
        this.setState({ status: [] });
        break;
      case 'divisionFilter':
        this.setState({ division: [] });
        break;
      case 'committieFilter':
        this.setState({ committie: [] });
        break;
      case 'projectCodeFilter':
        this.setState({ projectCode: [] });
        break;
      case 'selectNotRelatedProjects':
        this.setState({ selectNotRelatedProjects: false });
        break;
      default:
        break;
    }
  };

  render() {
    const { showFiltersModal, handelModalFiltersClose, filters, t } = this.props;
    const { filterHasValue } = this.state;

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('dashboardProgettiPage.dashboardFilter')}
        open={showFiltersModal}
        onCancel={handelModalFiltersClose}
        footer={null}>
        <Card className="tw-flex tw-flex-col">
          <Row
            className="tw-py-2"
            gutter={{ lg: 24 }}
            style={{ borderBottom: '1px dashed #a0a0a0' }}>
            <Col
              className="center-text pad6 gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <span>{t('dashboardProgettiPage.teamLeader')}</span>
              <Select
                value={this.state.teamLider}
                style={{ width: '100%' }}
                placeholder={t('general.seleziona')}
                onChange={(event, val) =>
                  this.handleChange('teamLider', val, {
                    value: 'teamLeaderFilter',
                    label: t('dashboardProgettiPage.teamLeader'),
                  })
                }
                options={filters.teamLeaders}
                showSearch
                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
              />
            </Col>

            <Col
              className="center-text pad6 gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <span>{t('dashboardProgettiPage.statoProgetto')}</span>
              <Select
                value={this.state.status}
                style={{ width: '100%' }}
                showSearch
                placeholder={t('general.seleziona')}
                optionFilterProp="children"
                onChange={(event, val) =>
                  this.handleChange('status', val, {
                    value: 'statusFilter',
                    label: t('dashboardProgettiPage.statoProgetto'),
                  })
                }
                filterOption={(input, option) =>
                  option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                <Option
                  value={0}
                  key="statusNan"
                  style={{ color: '#afaeae' }}>
                  {t('general.seleziona')}...
                </Option>
                {filters.status.map((item) => (
                  <Option
                    value={item.statusID}
                    key={item.statusID}>
                    {item.statusDescription}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col
              className="center-text pad6 gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <span>{t('proggetiPage.codiceProgetto')}</span>
              <Select
                value={this.state.projectCode}
                style={{ width: '100%' }}
                showSearch
                placeholder={t('general.seleziona')}
                optionFilterProp="children"
                onChange={(event, val) =>
                  this.handleChange('projectCode', val, {
                    value: 'projectCodeFilter',
                    label: t('proggetiPage.codiceProgetto'),
                  })
                }
                filterOption={(input, option) =>
                  option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                <Option
                  value={null}
                  key="projectCode"
                  style={{ color: '#afaeae' }}>
                  {t('general.seleziona')}...
                </Option>
                {filters.projectCode.map((item) => (
                  <Option
                    value={item.projectCode}
                    key={item.projectCode}>
                    {item.projectCode}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col
              className="center-text pad6 gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <span>{t('dashboardProgettiPage.divisioniReparti')}</span>
              <Select
                value={this.state.division}
                style={{ width: '100%' }}
                showSearch
                placeholder={t('general.seleziona')}
                optionFilterProp="children"
                onChange={(event, val) =>
                  this.handleChange('division', val, {
                    value: 'divisionFilter',
                    label: t('dashboardProgettiPage.divisioniReparti'),
                  })
                }
                filterOption={(input, option) =>
                  option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                <Option
                  value={null}
                  key="divisionNan"
                  style={{ color: '#afaeae' }}>
                  {t('general.seleziona')}...
                </Option>
                {filters.division.map((item) => (
                  <Option
                    disabled={item.hasAccess === 1 ? false : true}
                    value={item.divisionID}
                    key={item.divisionID}>
                    {item.divisionName}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col
              className="center-text pad6 gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <span>{t('general.commitato')}</span>
              <Select
                value={this.state.committie}
                style={{ width: '100%' }}
                showSearch
                placeholder={t('general.seleziona')}
                optionFilterProp="children"
                onChange={(event, val) =>
                  this.handleChange('committie', val, {
                    value: 'committieFilter',
                    label: t('general.commitato'),
                  })
                }
                filterOption={(input, option) =>
                  option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                <Option
                  value={null}
                  key="committiesNan"
                  style={{ color: '#afaeae' }}>
                  {t('general.seleziona')}...
                </Option>
                {filters.committie.map((item) => (
                  <Option
                    disabled={item.hasAccess === 1 ? false : true}
                    value={item.committeeID}
                    key={item.committeeID}>
                    {item.name}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col
              className="center-text pad6 gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <span>{t('general.sponsor')}</span>
              <Select
                value={this.state.sponsor}
                style={{ width: '100%' }}
                showSearch
                placeholder={t('general.seleziona')}
                optionFilterProp="children"
                onChange={(event, val) =>
                  this.handleChange('sponsor', val, { value: 'sponsorFilter', label: t('general.sponsor') })
                }
                filterOption={(input, option) =>
                  option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
                }>
                <Option
                  value={null}
                  key="sponsorNan"
                  style={{ color: '#afaeae' }}>
                  {t('general.seleziona')}...
                </Option>
                {filters.sponsor.map((item) => (
                  <Option
                    value={item.userID}
                    key={item.userID}>
                    {item.fullName}
                  </Option>
                ))}
              </Select>
            </Col>

            <Col
              className="pad6 gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <span>{t('proggetiPage.progettiSenzaRelazione')}</span>
              <br />
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                checked={this.state.selectNotRelatedProjects}
                onChange={(checked) =>
                  this.handleProjectRelationFilterChange('selectNotRelatedProjects', checked, {
                    value: 'selectNotRelatedProjects',
                    label: t('proggetiPage.progettiSenzaRelazione'),
                  })
                }
              />
            </Col>
          </Row>

          {/* <span>
            <CalendarOutlined style={{ color: 'black' }} />{' '}
            <span style={{ color: 'black' }}>
              {t('dashboardProgettiPage.settimanaCorrente')} {moment(moment(), 'MMDDYYYY').isoWeek()}{' '}
            </span>
          </span> */}
          <Col
            className="gutter-row-filter pad6"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}>
            <div className="buttonFilters tw-py-2">
              <Button
                disabled={!filterHasValue ? false : true}
                type="primary"
                style={{ marginRight: 10 }}
                onClick={this.handleSubmit}>
                {' '}
                {t('general.filter')}{' '}
              </Button>
              <Button
                disabled={!filterHasValue ? false : true}
                type="dashed"
                onClick={() => this.handelClear()}>
                {' '}
                {t('general.clearFilters')}{' '}
              </Button>
            </div>
          </Col>
        </Card>
      </Modal>
    );
  }
}

export default SintesiFilters;

import { Form } from 'antd';
import React, { Component } from 'react';

import { Input, Modal, Select } from 'antd';
import { ModalFooterButtons } from '../../../shared/components/modal/modalFooterButtons';
import { formItemLayout, longTermGoalStatus } from '../../../shared/utils/constants';
import { requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';
import './centerSection.scss';

const { Option } = Select;

class AddObietivoLungoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
    };
  }

  addData = (values) => {
    this.props.handleObLungoSave(values);
  };

  render() {
    const { showObLungo, handleObLungoClose, loadingButton, statusPickList, t } = this.props;

    return (
      <Modal
        className="add_obLungo_modal"
        width={700}
        title={t('obiettiviLungoPage.aggiungiObjLungo')}
        open={showObLungo}
        destroyOnClose={true}
        onCancel={handleObLungoClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleObLungoClose}
            formId={'newObLungoXmatrix'}
          />,
        ]}>
        <Form
          id="newObLungoXmatrix"
          name="add_ob_lungo"
          layout="vertical"
          {...formItemLayout}
          onFinish={this.addData}
          onValuesChange={() => {
            this.setState({ fieldsChanged: true });
          }}>
          <Form.Item
            label={t('general.nome')}
            name="title"
            rules={[{ required: true, message: requiredFields.required }]}>
            <Input />
          </Form.Item>
          <Form.Item
            label={t('general.stato')}
            name="status"
            hasFeedback
            rules={[{ required: true, message: requiredFields.required }]}
            initialValue={longTermGoalStatus.active}>
            <Select
              style={{ width: '100%' }}
              placeholder={t('general.seleziona')}>
              {statusPickList &&
                statusPickList.map((item) => (
                  <Option
                    value={item.statusID}
                    key={item.statusID}>
                    {item.statusDescription}
                  </Option>
                ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default AddObietivoLungoModal;

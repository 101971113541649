import moment from 'moment';
import { bowlingChartRowName } from '../../../shared/utils/constants';

export const getTotalTargetMonths = (bowlingChartData) => {
  if (bowlingChartData) {
    let totalMonths = 0;
    let target = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.target;
    })[0];
    for (let key in target) {
      if (key !== 'Name') {
        totalMonths += target[key];
      }
    }
    return totalMonths;
  }
  return;
};

export const getTotalActualsMonths = (bowlingChartData) => {
  if (bowlingChartData) {
    let totalMonths = 0;
    let target = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.actual;
    })[0];
    for (let key in target) {
      if (key !== 'Name') {
        totalMonths += target[key];
      }
    }
    return totalMonths;
  }
  return;
};

export const previousMonthOfTarget = (bowlingChartData) => {
  if (bowlingChartData) {
    const currentMonth = moment().format('YYYY-MM');

    const getPreviousMonth = moment(currentMonth).subtract(1, 'months').format('YYYY-MM');

    let previousMonth = {};

    let target = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.target;
    })[0];

    if (target) {
      for (let key in target) {
        if (key === getPreviousMonth) {
          previousMonth['monthName'] = key;
          previousMonth['monthValue'] = target[key];
        }
      }

      if (Object.keys(previousMonth).length === 0) {
        previousMonth['monthName'] = currentMonth;
        previousMonth['monthValue'] = target[currentMonth];
        return previousMonth;
      }
    }

    return previousMonth;
  }
  return;
};

export const previousMonthOfTargetYtd = (bowlingChartData, month) => {
  if (bowlingChartData) {
    const difinedMonth = moment(month).format('YYYY-MM');

    const getPreviousMonth = moment(difinedMonth).subtract(1, 'months').format('YYYY-MM');

    let previousMonth = {};

    let targetYtd = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.targetYtd;
    })[0];

    if (targetYtd) {
      for (let key in targetYtd) {
        if (key === getPreviousMonth) {
          previousMonth['monthName'] = key;
          previousMonth['monthValue'] = targetYtd[key];
        }
      }

      if (Object.keys(previousMonth).length === 0) {
        previousMonth['monthName'] = month;
        previousMonth['monthValue'] = targetYtd[month];
        return previousMonth;
      }
    }
    return previousMonth;
  }
  return;
};

export const currentMonthOfTargetYtd = (bowlingChartData, month) => {
  if (bowlingChartData) {
    const difinedMonth = moment(month).format('YYYY-MM');

    const getCurrentMonth = moment(difinedMonth).format('YYYY-MM');

    let currentMonth = {};

    let targetYtd = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.targetYtd;
    })[0];

    if (targetYtd) {
      for (let key in targetYtd) {
        if (key === getCurrentMonth) {
          currentMonth['monthName'] = key;
          currentMonth['monthValue'] = targetYtd[key];
        }
      }

      if (Object.keys(currentMonth).length === 0) {
        currentMonth['monthName'] = month;
        currentMonth['monthValue'] = targetYtd[month];
        return currentMonth;
      }
    }
    return currentMonth;
  }
  return;
};

export const previousMonthOfActualYtd = (bowlingChartData, month) => {
  if (bowlingChartData) {
    const difinedMonth = moment(month).format('YYYY-MM');

    const getPreviousMonth = moment(difinedMonth).subtract(1, 'months').format('YYYY-MM');

    let previousMonth = {};

    let actualYtd = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.actualYtd;
    })[0];

    if (actualYtd) {
      for (let key in actualYtd) {
        if (key === getPreviousMonth) {
          previousMonth['monthName'] = key;
          previousMonth['monthValue'] = actualYtd[key];
        }
      }

      if (Object.keys(previousMonth).length === 0) {
        previousMonth['monthName'] = month;
        previousMonth['monthValue'] = actualYtd[month];
        return previousMonth;
      }
    }

    return previousMonth;
  }
  return;
};

export const currentMonthOfActualYtd = (bowlingChartData, month) => {
  if (bowlingChartData) {
    const difinedMonth = moment(month).format('YYYY-MM');

    const getCurrentMonth = moment(difinedMonth).format('YYYY-MM');

    let currentMonth = {};

    let actualYtd = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.actualYtd;
    })[0];

    if (actualYtd) {
      for (let key in actualYtd) {
        if (key === getCurrentMonth) {
          currentMonth['monthName'] = key;
          currentMonth['monthValue'] = actualYtd[key];
        }
      }

      if (Object.keys(currentMonth).length === 0) {
        currentMonth['monthName'] = month;
        currentMonth['monthValue'] = actualYtd[month];
        return currentMonth;
      }
    }

    return currentMonth;
  }
  return;
};

export const lastMonthOfTarget = (bowlingChartData) => {
  if (bowlingChartData) {
    let lastMonth = {};
    let target = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.target;
    })[0];

    let maxMonth = { monthName: '1400-01', monthValue: 0 };
    for (let key in target) {
      let formatKey = moment(key, 'YYYY-MM');
      if (formatKey > moment(maxMonth.monthName, 'YYYY-MM')) {
        maxMonth.monthName = key;
        maxMonth.monthValue = target[key];

        lastMonth = maxMonth;
      }
    }
    return lastMonth;
  }
  return;
};

export const firstMonthOfTarget = (bowlingChartData) => {
  if (bowlingChartData) {
    let firstMonth = {};
    let target = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.target;
    })[0];
    let minMonth = { monthName: '2900-01', monthValue: 0 };
    for (let key in target) {
      let formatKey = moment(key, 'YYYY-MM');
      if (formatKey < moment(minMonth.monthName, 'YYYY-MM')) {
        minMonth.monthName = key;
        minMonth.monthValue = target[key];

        firstMonth = minMonth;
      }
    }
    return firstMonth;
  }
  return;
};

export const firstMonthOfActual = (bowlingChartData) => {
  if (bowlingChartData) {
    let firstMonth = {};
    let actual = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.actual;
    })[0];
    let minMonth = { monthName: '2900-01', monthValue: 0 };
    for (let key in actual) {
      let formatKey = moment(key, 'YYYY-MM');
      if (formatKey < moment(minMonth.monthName, 'YYYY-MM')) {
        minMonth.monthName = key;
        minMonth.monthValue = actual[key];

        firstMonth = minMonth;
      }
    }
    return firstMonth;
  }
  return;
};

export const firstMonthOfTargetYtd = (bowlingChartData) => {
  if (bowlingChartData) {
    let firstMonth = {};
    let target = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.targetYtd;
    })[0];
    let minMonth = { monthName: '2900-01', monthValue: 0 };
    for (let key in target) {
      let formatKey = moment(key, 'YYYY-MM');
      if (formatKey < moment(minMonth.monthName, 'YYYY-MM')) {
        minMonth.monthName = key;
        minMonth.monthValue = target[key];

        firstMonth = minMonth;
      }
    }
    return firstMonth;
  }
  return;
};

export const firstMonthOfActualYtd = (bowlingChartData) => {
  if (bowlingChartData) {
    let firstMonth = {};
    let actual = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.actualYtd;
    })[0];
    let minMonth = { monthName: '2900-01', monthValue: 0 };
    for (let key in actual) {
      let formatKey = moment(key, 'YYYY-MM');
      if (formatKey < moment(minMonth.monthName, 'YYYY-MM')) {
        minMonth.monthName = key;
        minMonth.monthValue = actual[key];
        firstMonth = minMonth;
      }
    }
    return firstMonth;
  }
  return;
};

export const listOfTargetPreviousMonths = (bowlingChartData) => {
  if (bowlingChartData) {
    let target = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.target;
    })[0];

    const currentMonth = moment().format('YYYY-MM');

    let previousMonths = [];

    for (let key in target) {
      if (key !== 'KPIID' && key !== 'Name' && moment(key, 'YYYY-MM') < moment(currentMonth, 'YYYY-MM')) {
        let month = {};
        month['monthName'] = key;
        month['monthValue'] = target[key];
        previousMonths.push(month);
      }
    }

    return previousMonths;
  }
  return;
};

export const getTotalTargetMonthsYTD = (bowlingChartData) => {
  if (bowlingChartData) {
    let sumTotalMonths = 0;

    let listOfTargetPreviousMonthsArray = listOfTargetPreviousMonths(bowlingChartData);

    listOfTargetPreviousMonthsArray.forEach((item) => {
      sumTotalMonths += item.monthValue;
    });

    return sumTotalMonths;
  }

  return;
};

export const previousMonthOfActual = (bowlingChartData) => {
  if (bowlingChartData) {
    const currentMonth = moment().format('YYYY-MM');

    const getPreviousMonth = moment(currentMonth).subtract(1, 'months').format('YYYY-MM');

    let previousMonth = {};

    let actual = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.actual;
    })[0];

    if (actual) {
      for (let key in actual) {
        if (key === getPreviousMonth) {
          previousMonth['monthName'] = key;
          previousMonth['monthValue'] = actual[key];
        }
      }

      if (Object.keys(previousMonth).length === 0) {
        previousMonth['monthName'] = currentMonth;
        previousMonth['monthValue'] = actual[currentMonth];
        return previousMonth;
      }
    }

    return previousMonth;
  }
  return;
};

export const listOfActualPreviousMonths = (bowlingChartData) => {
  if (bowlingChartData) {
    let actual = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.actual;
    })[0];

    const currentMonth = moment().format('YYYY-MM');

    let previousMonths = [];

    for (let key in actual) {
      if (key !== 'KPIID' && key !== 'Name' && moment(key, 'YYYY-MM') < moment(currentMonth, 'YYYY-MM')) {
        let month = {};
        month['monthName'] = key;
        month['monthValue'] = actual[key];
        previousMonths.push(month);
      }
    }

    return previousMonths;
  }
  return;
};

export const getTotalActualMonthsYTD = (bowlingChartData) => {
  if (bowlingChartData) {
    let sumTotalMonths = 0;

    let listOfActualPreviousMonthsArray = listOfActualPreviousMonths(bowlingChartData);

    listOfActualPreviousMonthsArray.forEach((item) => {
      sumTotalMonths += item.monthValue;
    });

    return sumTotalMonths;
  }

  return;
};

export const targetYtdRow = (bowlingChartData) => {
  if (bowlingChartData) {
    let targetYtdRow = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.targetYtd;
    })[0];

    return targetYtdRow;
  }

  return;
};

export const targetRow = (bowlingChartData) => {
  if (bowlingChartData) {
    let targetRow = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.target;
    })[0];

    return targetRow;
  }

  return;
};

export const actualYtdRow = (bowlingChartData) => {
  if (bowlingChartData) {
    let actualYtdRow = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.actualYtd;
    })[0];

    return actualYtdRow;
  }

  return;
};

export const lastMonthOfTargetYtd = (bowlingChartData) => {
  if (bowlingChartData) {
    let lastMonth = {};
    let targetYtd = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.targetYtd;
    })[0];

    let maxMonth = { monthName: '1400-01', monthValue: 0 };
    for (let key in targetYtd) {
      let formatKey = moment(key, 'YYYY-MM');
      if (formatKey > moment(maxMonth.monthName, 'YYYY-MM')) {
        maxMonth.monthName = key;
        maxMonth.monthValue = targetYtd[key];

        lastMonth = maxMonth;
      }
    }
    return lastMonth;
  }
  return;
};

export const lastMonthOfActualYtd = (bowlingChartData) => {
  if (bowlingChartData) {
    let lastMonth = {};
    let actualYtd = bowlingChartData.filter((obj) => {
      return obj.Name === bowlingChartRowName.actualYtd;
    })[0];

    let maxMonth = { monthName: '1400-01', monthValue: 0 };
    for (let key in actualYtd) {
      let formatKey = moment(key, 'YYYY-MM');
      if (formatKey > moment(maxMonth.monthName, 'YYYY-MM')) {
        maxMonth.monthName = key;
        maxMonth.monthValue = actualYtd[key];

        lastMonth = maxMonth;
      }
    }
    return lastMonth;
  }
  return;
};

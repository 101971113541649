import { EditOutlined } from '@ant-design/icons';
import { Button, Table, Tooltip, Typography, message } from 'antd';
import React, { Component } from 'react';
import '../../../matrix.scss';
// import EditProjectModal from './editProjectModal';
import classNames from 'classnames';
import { history } from '../../../../../App';
import ObProgettiService from '../../../../../services/matrix/obProgettiService';
import { statusClass } from '../../../../shared/utils/functions';
import { notifyMessages } from '../../../../shared/utils/notifyMessages';
import EditProjectModal from '../../../data_tables/projects/editProjectModal';
import { XMatrixProjectRow } from '../../../data_tables/projects/projects';

class SubProjectsLevel2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      loadingButton: false,
      loadingButtonRemove: false,
      modalData: {},
    };
  }

  // open add new record modal
  toggleModal = (rowData) => {
    this.setState({ openModal: !this.state.openModal });
    this.setState({ modalData: rowData });
  };

  // handel modal close
  handleModalCancel = () => {
    this.setState({ openModal: false });
    this.setState({ loadingButton: false });
  };

  columns = [
    {
      dataIndex: 'orderingValue',
      render: (text, record, index) => {
        return {
          children: (
            <XMatrixProjectRow
              record={record}
              index={index}
              props={this.props}
              toggleModal={this.toggleModal}
              selectedRowClassName={classNames({
                'tw-bg-primary selected-row':
                  this.props.selection === 'primary' && this.props.selectedIds?.includes(record.projectID),
                'tw-bg-primary/30 selected-row':
                  this.props.selection === 'secondary' && this.props.selectedIds?.includes(record.projectID),
              })}
            />
          ),
        };
      },
    },
  ];

  //handel update
  updateData = (values, id) => {
    const { selectedXmatrix } = this.props;
    values['xMatrixID'] = selectedXmatrix;
    values['projectID'] = id;
    const payload = values;

    this.setState({ loadingButton: true });
    ObProgettiService.updateObProgetti(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.handleModalCancel();
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  removeProject = (id, DeleteProject) => {
    const { selectedXmatrix, t } = this.props;
    this.setState({ loadingButtonRemove: true });
    ObProgettiService.removeProjectXmatrix(id, selectedXmatrix, DeleteProject)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.handleModalCancel();
          this.props.reloadMatrix();
          this.setState({ loadingButtonRemove: false });
        } else {
          this.handleModalCancel();
          message.error(notifyMessages.deleteFailed);
          this.setState({ loadingButtonRemove: false });
        }
      })
      .catch((error) => {
        this.handleModalCancel();
        message.error(notifyMessages.deleteFailed);
        this.setState({ loadingButtonRemove: false });
      });
  };

  removeProjectDraft = (id, DeleteProject) => {
    const { selectedXmatrix } = this.props;

    this.setState({ loadingButtonRemove: true });
    ObProgettiService.removeProjectDraftXmatrix(id, selectedXmatrix, DeleteProject)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ loadingButtonRemove: false });
          message.success(notifyMessages.deleteSuccess);
          this.handleModalCancel();
          this.props.reloadMatrix();
        } else {
          this.setState({ loadingButtonRemove: false });
          this.handleModalCancel();
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        this.setState({ loadingButtonRemove: false });
        this.handleModalCancel();
        message.error(notifyMessages.deleteFailed);
      });
  };

  // removeProject = (id) => {
  //   const {selectedXmatrix} = this.props;

  //   this.setState({ loadingButtonRemove: true });
  //   ObProgettiService.removeProjectXmatrix(id, selectedXmatrix)
  //     .then(response => response.data)
  //     .then(resp => {
  //       if (resp.success) {
  //         message.success(notifyMessages.deleteSuccess);
  //         this.handleModalCancel();
  //         this.props.reloadMatrix();
  //       }
  //       else {
  //         this.handleModalCancel();
  //         message.error(notifyMessages.deleteFailed);
  //       }
  //     }).catch(error => {
  //       this.handleModalCancel();
  //       message.error(notifyMessages.deleteFailed);
  //     });

  //  };

  //  removeProjectDraft = (id) => {
  //   const {selectedXmatrix} = this.props;

  //   this.setState({ loadingButtonRemove: true });
  //   ObProgettiService.removeProjectDraftXmatrix(id, selectedXmatrix)
  //     .then(response => response.data)
  //     .then(resp => {
  //       if (resp.success) {
  //         message.success(notifyMessages.deleteSuccess);
  //         this.handleModalCancel();
  //         this.props.reloadMatrix();
  //       }
  //       else {
  //         this.handleModalCancel();
  //         message.error(notifyMessages.deleteFailed);
  //       }
  //     }).catch(error => {
  //       this.handleModalCancel();
  //       message.error(notifyMessages.deleteFailed);
  //     });

  //  };

  render() {
    const { projects, comittieList, statusList, divisionsList, selectedXmatrixInfoData, isSecondLevelXmatrix, t } =
      this.props;
    const { loadingButton, loadingButtonRemove } = this.state;

    return (
      <>
        {this.state.openModal && (
          <EditProjectModal
            show={this.state.openModal}
            toggle={this.toggleModal}
            handleModalCancel={this.handleModalCancel}
            data={this.state.modalData}
            handleSave={this.updateData}
            removeProject={this.removeProject}
            removeProjectDraft={this.removeProjectDraft}
            commities={comittieList}
            statuses={statusList}
            divisionsList={divisionsList}
            loadingButton={loadingButton}
            loadingButtonRemove={loadingButtonRemove}
            selectedXmatrixInfo={selectedXmatrixInfoData}
            isSecondLevelXmatrix={isSecondLevelXmatrix}
            t={t}
          />
        )}

        <Table
          className="[&_table]:tw-h-full"
          size="small"
          dataSource={projects}
          pagination={false}
          bordered
          showHeader={false}
          columns={this.columns}
          rowKey={(obj) => obj.projectID}></Table>
      </>
    );
  }
}

export default SubProjectsLevel2;

import React from 'react';
import UsersService from '../../services/pages/usersService';

import {
  HourglassOutlined,
  MailOutlined,
  PhoneOutlined,
  RiseOutlined,
  SaveOutlined,
  SolutionOutlined,
  UploadOutlined,
  UserOutlined,
} from '@ant-design/icons';
import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  Row,
  Select,
  Switch,
  Tabs,
  Typography,
  Upload,
  message,
} from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { objectCodes, userRoles, userRolesCodes } from '../../components/shared/utils/constants';
import CommonService from '../../services/commonService';
import MatrixService from '../../services/matrix/matrixService';
import DashboardService from '../../services/pages/dashboardService';
import Loader from '../shared/components/loader/loader';
import {
  checkUserIsAdminUser,
  checkUserIsLenovysAdmin,
  checkUserIsNormalUser,
} from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { NoAccessMesssage, getCurrencySymbol } from '../shared/utils/functions';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';
import UserComities from './userComities';
import './userProfile.scss';
import UserProjects from './userProjects';
import UserResourceWorkload from './userWorkload';

// let createBrowserHistory = require('history').createBrowserHistory;
// const history = createBrowserHistory({ forceRefresh: true });
const { Option } = Select;

class UserProfile extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      loadingProjects: false,
      uploadedFile: null,
      curentUserData: null,
      fieldsChanged: false,
      divisionsList: null,
      userTypes: null,
      selectedUserId: null,
      roleList: null,
      userProjects: null,
      userCommities: null,
      xmatrixPicklist: null,
      selectedXmatrixValue: null,
      activeTab: '1',
      selectedYear: moment(),
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.userData !== prevProps.userData) {
      this.retrieveUserProfileInfo();
    }
  }

  componentDidMount() {
    const { tab } = this.props.match.params;
    if (tab) {
      this.setState({ activeTab: tab });
    }
    this.retrieveUserProfileInfo();
    this.retrieveDivisions();
    this.retrieveUserType();
    this.retrieveRoles();
    this.retrieveComponentData();
  }

  retrieveComponentData = async () => {
    const { id } = this.props.match.params;
    let userId;
    const { userData } = this.props;
    if (id === '0' || id === undefined) {
      userId = userData.userId;
      this.setState({ selectedUserId: userId });
    } else {
      userId = id;
      this.setState({ selectedUserId: userId });
    }
  };

  // Retrieve list of divisions
  async retrieveDivisions() {
    await DashboardService.getDivisionFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ divisionsList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveUserProjects() {
    const { selectedXmatrixValue } = this.state;

    let userId;
    const { id } = this.props.match.params;
    const { userData } = this.props;

    if (id === '0' || id === undefined) {
      userId = userData.userId;
      this.setState({ selectedUserId: userId });
    } else {
      userId = id;
      this.setState({ selectedUserId: userId });
    }

    if (userData && Object.keys(userData).length > 0) {
      if (selectedXmatrixValue) {
        this.setState({ loadingProjects: true });
        await UsersService.getUserProjects(selectedXmatrixValue, userId)
          .then((response) => response.data)
          .then((resp) => {
            if (resp.success) {
              this.setState({ userProjects: resp.responseObject.value });
              this.setState({ loadingProjects: false });
            } else {
              this.setState({ loadingProjects: false });
            }
          })
          .catch((error) => {
            this.setState({ loadingProjects: false });
          });
      }
    }
  }

  async retrieveUserCommities() {
    let userId;
    const { id } = this.props.match.params;
    const { userData } = this.props;

    if (id === '0' || id === undefined) {
      userId = userData.userId;
      this.setState({ selectedUserId: userId });
    } else {
      userId = id;
      this.setState({ selectedUserId: userId });
    }

    if (userData && Object.keys(userData).length > 0) {
      await UsersService.getUserCommities(userId)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            this.setState({ userCommities: resp.responseObject.value });
          } else {
          }
        })
        .catch((error) => {});
    }
  }

  async retrieveUserType() {
    const objectCode = objectCodes.userType;
    await CommonService.getCalculationTypeList(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ userTypes: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveRoles() {
    await DashboardService.getUserRole()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ roleList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve user data
  async retrieveUserProfileInfo() {
    let userId;
    const { id } = this.props.match.params;
    const { userData } = this.props;
    let currentYear = moment().format('YYYY');

    if (id === '0' || id === undefined) {
      userId = userData.userId;
      this.setState({ selectedUserId: userId });
    } else {
      userId = id;
      this.setState({ selectedUserId: userId });
    }

    if (userData && Object.keys(userData).length > 0) {
      await UsersService.getUserProfile(userId, currentYear)
        .then((response) => response.data)
        .then((resp) => {
          console.log('here', resp);
          if (resp.success) {
            this.setState({ curentUserData: resp.responseObject.value });
          } else {
            message.error(notifyMessages.retrieveFailed);
          }
        })
        .catch((error) => {
          message.error(notifyMessages.retrieveFailed);
        });
    }
  }

  // update user data
  updateUserProfileInfo = (values, id) => {
    this.setState({ loadingButton: true });

    values['userId'] = id;

    const formData = new FormData();
    formData.append('data', JSON.stringify(values));
    formData.append('uploadedFile', this.state.uploadedFile);

    UsersService.updateUserData(formData)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.retrieveUserProfileInfo();
          this.setState({ loadingButton: false });
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(requiredFields.uploadCheckFileType);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(requiredFields.uploadCheckFileSize);
    }
    this.setState({ uploadedFile: file });
    return isJpgOrPng && isLt2M;
  }

  handleChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  checkUserRole = (curentUserData) => {
    if (
      curentUserData.userProfile.role === userRoles.lenovysAdmin ||
      curentUserData.userProfile.role === userRoles.techAdmin
    ) {
      return false;
    } else if (
      curentUserData.userProfile.role === userRoles.steering ||
      curentUserData.userProfile.role === userRoles.teamLeader ||
      curentUserData.userProfile.role === userRoles.teamMember ||
      curentUserData.userProfile.role === userRoles.companyAdmin ||
      curentUserData.userProfile.role === userRoles.sponsor
    ) {
      return true;
    } else {
      return true;
    }
  };

  removeUploadedFile = () => {
    this.setState({ uploadedFile: null });
  };

  onReloadUserData = () => {
    this.retrieveUserProfileInfo();
  };

  getDefaultXmatrix = (xmatrixPicklist) => {
    let defaultXmatrix = xmatrixPicklist
      ? xmatrixPicklist.find((x) => x.status === 1 && x.parentXMatrixID === null).xMatrixID
      : null;
    this.setState({ selectedXmatrixValue: defaultXmatrix });
  };

  async xmatrixPicklist() {
    await MatrixService.getXmatrixPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ xmatrixPicklist: resp.responseObject.value }, async () => {
            this.setState({ loadingPicklist: false });
            await this.getDefaultXmatrix(resp.responseObject.value);
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  onXmatrixChange = (option) => {
    this.setState({ selectedXmatrixValue: option }, () => {
      this.retrieveUserProjects();
    });
  };

  render() {
    const { curentUserData, divisionsList, userTypes, loadingButton, selectedUserId, roleList, activeTab } = this.state;
    const { userData, companyData } = this.props;
    let t = this.props.t;

    return (
      <>
        <Loader />

        {curentUserData && curentUserData.userProfile && !curentUserData.userProfile.isDeleted && (
          <Form
            name="user_profile"
            layout="vertical"
            onFinish={(values) => this.updateUserProfileInfo(values, curentUserData.userProfile.userID)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 9 }}>
                <Card bordered={false}>
                  <div className="tw-flex tw-items-center tw-justify-between">
                    <Typography.Title level={3}>{t('general.myProfile')}</Typography.Title>
                    <Form.Item className="!tw-mb-0">
                      <Button
                        loading={loadingButton}
                        type="primary"
                        htmlType="submit"
                        className="tw-float-end"
                        disabled={!this.state.fieldsChanged}>
                        <SaveOutlined />
                        {t('buttons.salva')}
                      </Button>
                    </Form.Item>
                  </div>
                  <div className="tw-flex tw-items-center tw-gap-4">
                    <div className="tw-shrink-0">
                      {curentUserData.userProfile.profilePhoto && (
                        <Avatar
                          className="avatar"
                          size={50}
                          icon={<UserOutlined />}
                          src={`${curentUserData.userProfile.profilePhoto}?${+new Date()}`}
                        />
                      )}
                      {!curentUserData.userProfile.profilePhoto && (
                        <Avatar
                          size={50}
                          icon={<UserOutlined />}
                        />
                      )}
                    </div>

                    <Form.Item
                      name="upload"
                      // label={t('general.image')}
                      // extra={t('utentiPage.caricaFotoProfilo')}
                      className="!tw-mb-0">
                      <Upload
                        accept="image/*"
                        maxCount={1}
                        listType="picture"
                        onChange={this.handleChange}
                        onRemove={() => this.removeUploadedFile()}
                        beforeUpload={(file) => {
                          this.setState({ uploadedFile: file });
                          return false;
                        }}>
                        <Button
                          disabled={checkUserIsAdminUser(userData)}
                          icon={<UploadOutlined />}>
                          {t('general.cambiaImagine')}
                        </Button>
                      </Upload>
                    </Form.Item>
                  </div>

                  <Divider />

                  <Form.Item
                    label={t('general.nome')}
                    name="firstName"
                    initialValue={curentUserData.userProfile.name ?? 'ok'}>
                    <Input
                      prefix={<UserOutlined />}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('general.cognome')}
                    name="surname"
                    initialValue={curentUserData.userProfile.surname}>
                    <Input
                      prefix={<UserOutlined />}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('utentiPage.titoloLavoro')}
                    name="jobTitle"
                    initialValue={curentUserData.userProfile.jobTitle}>
                    <Input
                      prefix={<SolutionOutlined />}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('general.ruolo')}
                    name="roleID"
                    hasFeedback
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={curentUserData.userProfile.roleID}>
                    <Select
                      style={{ width: '100%' }}
                      placeholder={t('general.seleziona')}
                      disabled={checkUserIsAdminUser(userData)}>
                      {roleList &&
                        roleList.map((item) => (
                          <Option
                            value={item.roleID}
                            key={item.roleID}
                            disabled={
                              userData.roleCode === userRolesCodes.companyAdmin &&
                              item.roleDescription === userRoles.companyAdmin
                                ? true
                                : false
                            }>
                            {item.roleDescription}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={t('utentiPage.divisioneReparto')}
                    name="divisionID"
                    hasFeedback
                    initialValue={curentUserData.userProfile.divisionID}>
                    <Select
                      style={{ width: '100%' }}
                      placeholder={t('general.seleziona')}
                      disabled={checkUserIsAdminUser(userData)}>
                      {divisionsList &&
                        divisionsList.map((item) => (
                          <Option
                            disabled={item.hasAccess === 1 ? false : true}
                            value={item.divisionID}
                            key={item.divisionID}>
                            {item.divisionName}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <Form.Item
                    label={t('general.email')}
                    name="emailAddress"
                    initialValue={curentUserData.userProfile.emailAddress}>
                    <Input
                      prefix={<MailOutlined />}
                      disabled={true}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('utentiPage.recapitoTelefonico')}
                    name="phoneNumber"
                    initialValue={curentUserData.userProfile.phoneNumber}>
                    <Input
                      prefix={<PhoneOutlined />}
                      disabled={checkUserIsNormalUser(userData)}
                    />
                  </Form.Item>

                  <Form.Item
                    label={t('utentiPage.tipologiaUtente')}
                    name="type"
                    hasFeedback
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={curentUserData.userProfile.type}>
                    <Select
                      style={{ width: '100%' }}
                      placeholder={t('general.seleziona')}
                      disabled={checkUserIsAdminUser(userData)}>
                      {userTypes &&
                        userTypes.map((item) => (
                          <Option
                            value={item.objectCodeListID}
                            key={item.objectCodeListID}>
                            {item.description}
                          </Option>
                        ))}
                    </Select>
                  </Form.Item>

                  <div
                    className="ant-row ant-form-item formInputs"
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div className="ant-col ant-col-10 ant-form-item-label ant-form-item-label-left">
                      <label>{t('utentiPage.disabilitatoAnno')}</label>
                    </div>
                    <div className="ant-col ant-col-14 ant-form-item-control">
                      <InputNumber
                        disabled={true}
                        value={curentUserData.userProfile.availability}
                        min={0}
                        prefix={<HourglassOutlined />}
                      />
                    </div>
                  </div>

                  <Form.Item
                    label={t('utentiPage.costoGiornalero')}
                    name="dailyRate"
                    initialValue={curentUserData.userProfile.dailyRate}>
                    <InputNumber
                      min={0}
                      prefix={<RiseOutlined />}
                      disabled={checkUserIsAdminUser(userData)}
                    />
                  </Form.Item>

                  <div
                    className="ant-row ant-form-item formInputs"
                    style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                    <div className="ant-col ant-col-10 ant-form-item-label ant-form-item-label-left">
                      <label>{t('utentiPage.valuta')}</label>
                    </div>
                    <div className="ant-col ant-col-14 ant-form-item-control">
                      <Input
                        value={
                          companyData ? getCurrencySymbol(companyData.currency) + ' - ' + companyData.currency : ''
                        }
                        disabled={true}
                      />
                    </div>
                  </div>

                  <Form.Item
                    className="tw-mt-2"
                    label={t('utentiPage.disabilitato')}
                    tooltip={t('utentiPage.disabilitatoInfoMessage')}
                    name="isDisabled"
                    valuePropName="checked"
                    initialValue={curentUserData.userProfile.isDisabled}>
                    <Switch
                      checkedChildren={t('general.si')}
                      unCheckedChildren={t('general.no')}
                      disabled={!checkUserIsLenovysAdmin(userData)}
                    />
                  </Form.Item>
                </Card>
              </Col>

              <Col
                className="-tw-mt-3"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 15 }}>
                <Card bordered={false}>
                  {/* <Row style={{ justifyContent: 'space-between' }}>
                    <Button
                      onClick={this.backFunction}
                      className="backButton">
                      <RollbackOutlined />
                      {t('buttons.indietro')}
                    </Button>
                  </Row> */}

                  <Tabs
                    defaultActiveKey={activeTab}
                    items={[
                      {
                        label: <>{t('general.progetti')}</>,
                        key: '1',
                        children: (
                          <UserProjects
                            toFilter={'all'}
                            userId={selectedUserId}
                          />
                        ),
                      },
                      {
                        label: <>{t('general.comitato')}</>,
                        key: '2',
                        children: <UserComities userId={selectedUserId} />,
                      },
                      {
                        label: <>{t('utentiPage.caricoPersonale')}</>,
                        key: '3',
                        children: (
                          <UserResourceWorkload
                            userId={selectedUserId}
                            reloadUserData={() => this.onReloadUserData()}
                          />
                        ),
                      },
                    ]}></Tabs>
                </Card>
              </Col>
            </Row>
          </Form>
        )}

        {curentUserData && curentUserData.userProfile && curentUserData.userProfile.isDeleted && <NoAccessMesssage />}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default withTranslation()(connect(mapStateToProps)(UserProfile));

import { trackPromise } from 'react-promise-tracker';
import { acquireToken } from 'src/config/auth/clientAuthProvider';
import axiosInstance from 'src/config/axiosConfig/interceptors';
import { authenticatedApi } from 'src/config/connectors';
import {
  CompanyApi,
  GeneralApi,
  UpdateAlertConfigurationRequestDto,
  UpdateCompanyRequestDto,
} from 'src/connectors/backend';

const CompanyService = {
  getCompanyData: async () => {
    const api = await authenticatedApi(GeneralApi);

    return trackPromise(api.generalGetCompany());
  },
  getCompanyNotifications: async (companyId: string) => {
    const api = await authenticatedApi(CompanyApi);

    return trackPromise(api.companyGetAlertConfiguration(companyId));
  },
  updateCompanyData: function (payload: any) {
    const data = acquireToken().then((tokenResponse) => {
      const optionsVal = {
        method: 'PUT',
        url: `api/Company/UpdateCompanyProfile`,
        data: payload,
        headers: {
          Authorization: `Bearer ${tokenResponse?.accessToken}`,
        },
      };
      const apiResponse = trackPromise(axiosInstance(optionsVal));
      return apiResponse;
    });
    return data;
  },

  updateCompanyLicense: async function (payload: UpdateCompanyRequestDto) {
    const api = await authenticatedApi(GeneralApi);

    return trackPromise(api.generalUpdateCompany(payload));
  },
  insertUserNextYearPlan: async function (year: string) {
    const api = await authenticatedApi(CompanyApi);

    return trackPromise(api.companyInsertNextYearUserActivityPlanning(year));
  },
  updateCompanyNotificationsAlert: async function (payload: UpdateAlertConfigurationRequestDto) {
    const api = await authenticatedApi(CompanyApi);

    return trackPromise(api.companyUpdateAlertConfiguration(payload));
  },
};

export default CompanyService;

import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi, InsertRiskRequestDto, ProjectApi, UpdateRiskRequestDto } from 'src/config/connectors';

const Riskervice = {
  // get project risks and counter measures
  getRiskAndPrecaution: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetRiskPrecaution(projectId));
  },

  addCritic: async function (payload: InsertRiskRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectInsertRisk(payload));
  },

  // update company data
  updateCritic: async function (payload: UpdateRiskRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateRisk(payload));
  },

  deleteRisk: async function (riskId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectDeleteRisk(riskId));
  },

  // delete counter measure
  deletePrecaution: async function (precautionId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectDeletePrecaution(precautionId));
  },
};

export default Riskervice;

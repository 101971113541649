import { Card, Col, Row, Table, Tooltip, Typography } from 'antd';
import Parser from 'html-react-parser';
import moment from 'moment';
import React from 'react';
import Section1Service from '../../../services/a3/section1Service';
import Loader2 from '../../shared/components/loader2/loader2';
import { formatOfDate } from '../../shared/utils/constants';
const { Text } = Typography;

class Section1PrintPreview extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      firstTableData: [],
      secondTableDate: [],
      currentData: null,
      fullScreen: false,
      currentDataRoutine: null,
      showProjectRoutine: false,
      loadingButton: false,
      kpiList: [],
      editorModal: false,
      fieldObject: null,
      sectionFieldChanged: false,
    };
  }

  componentDidMount() {
    const { id } = this.props;
    this.getGoalGroundtoActA3(id);
    this.retrieveProjectRoutine(id);
    this.retrieveKPIList(id);
  }

  getGoalGroundtoActA3 = (id) => {
    Section1Service.getGoal(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentData: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  retrieveProjectRoutine = (id) => {
    Section1Service.getProjectRoutine(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentDataRoutine: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  retrieveKPIList = (id) => {
    Section1Service.getKPI(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ kpiList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  render() {
    const { currentData, currentDataRoutine, kpiList } = this.state;
    const { t } = this.props;

    const projectsColumns = [
      {
        title: `${t('bachechaPersonalePage.attivita')}`,
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        ellipsis: {
          showTitle: false,
        },
        render: (text, record) => (
          <Tooltip title={text}>
            <Text>{text}</Text>
          </Tooltip>
        ),
      },
      {
        title: `${t('proggetiPage.pianificazione')}`,
        dataIndex: 'schedule',
        key: 'schedule',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: `${t('general.start')}`,
        dataIndex: 'startDate',
        render: (text, record) => moment(text).format(formatOfDate),
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: `${t('general.end')}`,
        dataIndex: 'endData',
        render: (text, record) => moment(text).format(formatOfDate),
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: `${t('proggetiPage.chi')}`,
        dataIndex: 'whoDescription',
        key: 'whoDescription',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: `${t('proggetiPage.durata')} (h)`,
        dataIndex: 'duration',
        key: 'duration',
        ellipsis: {
          showTitle: false,
        },
      },
    ];

    const kpiColumns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'name',
        key: 'name',
        width: '30%',
        className: 'twoRowEllipses',
        //   ellipsis: {
        //     showTitle: false,
        // },
        render: (text, record) =>
          record.showInA3 === 1 ? (
            <Tooltip title={text}>
              <Text strong>{record.name}</Text>{' '}
            </Tooltip>
          ) : (
            <Tooltip title={text}>
              <Text>{record.name}</Text>
            </Tooltip>
          ),
      },
      {
        title: `${t('general.jumpOffPoint')}`,
        dataIndex: 'jumpOffPoint',
        key: 'jumpOffPoint',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: `${t('general.target')}`,
        dataIndex: 'target',
        key: 'target',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: `${t('general.updateYtd')}`,
        dataIndex: 'updateYTD',
        key: 'updateYTD',
        ellipsis: {
          showTitle: false,
        },
      },
      {
        title: `${t('general.targetYtd')}`,
        dataIndex: 'targetYTD',
        key: 'targetYTD',
        ellipsis: {
          showTitle: false,
        },
      },
    ];

    return (
      <div className="section">
        {!currentData && <Loader2 />}

        {currentData && (
          <>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Card
                  title={t('proggetiPage.risultatoAtteso')}
                  className="height200 text-area">
                  {Parser(currentData.projectGoal ? currentData.projectGoal : '')}
                </Card>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Card
                  title={t('proggetiPage.motivoAgire')}
                  className="height200 text-area">
                  {Parser(currentData.groundtoAct ? currentData.groundtoAct : '')}
                </Card>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Card
                  title={t('a3Page.indicatoriTarget')}
                  className="height260">
                  <Table
                    columns={kpiColumns}
                    dataSource={kpiList}
                    pagination={false}
                    rowKey={(obj) => obj.kpiProjectRelationShipID}
                    size="small"
                  />
                </Card>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Card
                  title={t('proggetiPage.routneGestioneProgetto')}
                  className="height260">
                  <Table
                    className="custom-tb-section1"
                    columns={projectsColumns}
                    dataSource={currentDataRoutine}
                    pagination={false}
                    rowKey={(obj) => obj.projectRoutineID}
                    size="small"
                  />
                </Card>
              </Col>
            </Row>

            {/* <Row gutter={{ lg: 24 }} className="row-margin mt2" >
                


                <Col className="gutter-row no-padding" xs={{ span: 15 }} sm={{ span: 15 }} md={{ span: 15 }} lg={{ span: 15 }}>

                  <Row gutter={{ lg: 24 }} className="row-margin" >
                    <Col className="gutter-row no-padding" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} style={{paddingLeft: 0}} >

                      <Card
                        className="custom-card"
                        title={
                          <Title
                            title={<Link style={{ color: "#000000" }} to={{ pathname: `/progetti/id/${id}/2` }}>Indicatori e target <ExportOutlined style={{ fontSize: 16, color: "#b7b7b7", marginLeft: 10 }} /></Link>}
                            description="Che indicatori misureranno i risultati del progetto? Che target quantitativi ci diamo?"
                          />
                        }    
                      >
                        <Table
                          className="scroll custom-table-section1"
                          columns={this.kpiColumns}
                          dataSource={kpiList}
                          pagination={false}
                          rowKey={obj => obj.kpiProjectRelationShipID}

                        />
                      </Card>

                    </Col>

                    <Col className="gutter-row no-padding" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} style={{paddingLeft: 0}}>

                      <Card
                        className="custom-card"
                        title={
                          <Title
                            title={<Link style={{ color: "#000000" }} to={{ pathname: `/progetti/id/${id}/1` }}>Routine di gestione progetto<ExportOutlined style={{ fontSize: 16, color: "#b7b7b7", marginLeft: 10 }} /></Link>}
                            description="Quali routine di gestione del progetto e del team è opportuno mettere in piedi? (descrizione e frequenza)"
                          />
                        }

                      >
                        <Table
                          className="scroll custom-table2-section1"
                          columns={projectsColumns}
                          dataSource={currentDataRoutine}
                          pagination={false}
                          rowKey={obj => obj.projectRoutineID}
                        />
                      </Card>

                    </Col>

                  </Row>


                </Col>
              </Row> */}
          </>
        )}
      </div>
    );
  }
}
export default Section1PrintPreview;

import { Form } from 'antd';
import { Component } from 'react';

import { SyncOutlined } from '@ant-design/icons';
import { Col, Popover, Radio, Row } from 'antd';
import { withTranslation } from 'react-i18next';
import { formItemLayout } from '../../shared/utils/constants';
import '../matrix.scss';

const radioStyle = {
  display: 'block',
  height: '30px',
  lineHeight: '30px',
};

class RelationTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingIcon: false,
    };
  }

  onRelationCellForm = (text, rowId, colId) => {
    const { loadingIcon } = this.state;
    const { t } = this.props;
    return (
      <Form
        layout="vertical"
        {...formItemLayout}
        initialValues={{ correlationCoefficient: text }}
        onValuesChange={(value) => this.props.onUpdateRelation(value, rowId, colId)}>
        <Row gutter={{ lg: 24 }}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}>
            <Form.Item name="correlationCoefficient">
              <Radio.Group>
                <Radio
                  style={radioStyle}
                  key="1"
                  value={1}>
                  {t('xMatrixPage.primaria')}
                </Radio>
                <Radio
                  style={radioStyle}
                  key="2"
                  value={2}>
                  {t('xMatrixPage.secondaria')}
                </Radio>
                <Radio
                  style={radioStyle}
                  key="3"
                  value={3}>
                  {t('xMatrixPage.nessuna')}
                </Radio>
              </Radio.Group>
            </Form.Item>
            {loadingIcon && (
              <SyncOutlined
                style={{ color: '#0f87dc', marginRight: 5, display: 'inherit' }}
                spin
              />
            )}
          </Col>
        </Row>
      </Form>
    );
  };

  renderCell = (cell) => {
    return (
      <Popover
        key={cell.colId + '-' + cell.rowId}
        content={this.onRelationCellForm(cell.code, cell.rowId, cell.colId)}
        trigger="click">
        <div
          className={`selection-container ${cell.selected ? 'is-selected' : ''} ${cell.passingThrough ? 'is-passing-through' : ''}`}>
          {cell.code === 1 && (
            <span
              className="relationFull"
              style={{ cursor: 'pointer' }}></span>
          )}
          {cell.code === 2 && (
            <span
              className="relationPartial"
              style={{ cursor: 'pointer' }}></span>
          )}
          {cell.code === 3 && (
            <span
              className="noRelation"
              style={{ cursor: 'pointer' }}></span>
          )}
          {cell.code === 0 && cell.selected && <span></span>}
          {(cell.leftLine || cell.rightLine) && (
            <i
              className={`horizontal-lines ${cell.leftLine ? 'has-left-line' : ''} ${cell.rightLine ? 'has-right-line' : ''}`}></i>
          )}
          {(cell.topLine || cell.bottomLine) && (
            <i
              className={`vertical-lines ${cell.topLine ? 'has-top-line' : ''} ${cell.bottomLine ? 'has-bottom-line' : ''}`}></i>
          )}
        </div>
      </Popover>
    );
  };

  findRelation = (relationList, colId, rowId) =>
    relationList.find(
      (item) =>
        ((item.highestIDValue === colId && item.lowestIDValue === rowId) ||
          (item.highestIDValue === rowId && item.lowestIDValue === colId)) &&
        item.correlationCoefficient !== 3,
    );

  cells = (rowList, colList, relationList) => {
    if (!rowList || rowList.length === 0 || !colList || colList.length === 0) {
      return [];
    }

    let cells = [];

    rowList.forEach((rowItem) => {
      const innerCells = [];
      colList.forEach((colItem) => {
        const colId = colItem[this.props.colItemId];
        const rowId = rowItem[this.props.rowItemId];
        const relation = this.findRelation(relationList, colId, rowId);
        innerCells.push({
          code: relation?.correlationCoefficient ?? 3,
          colId: colItem[this.props.colItemId],
          rowId: rowItem[this.props.rowItemId],
        });
      });

      cells.push(innerCells);
    });

    const verticalWalkOnCell = (cell, topLine, bottomLine) => {
      if (this.props.colSelectionIds?.includes(cell.colId) && !cell.selected) {
        if (this.props.rowSelectionIds?.includes(cell.rowId) && this.props.colSelectionIds?.includes(cell.colId)) {
          cell.selected = true;
        } else {
          cell.passingThrough = true;
        }

        cell.bottomLine = bottomLine;
        cell.topLine = topLine;
      }
    };

    const horizontalWalkOnCell = (cell, leftLine, rightLine) => {
      if (this.props.rowSelectionIds?.includes(cell.rowId) && this.props.colSelectionIds?.includes(cell.colId)) {
        cell.selected = true;
      } else {
        cell.passingThrough = true;
      }

      cell.rightLine = rightLine;
      cell.leftLine = leftLine;
    };

    if (this.props.colSelectionIds?.length) {
      // Vertical lines
      this.props.colList.forEach((colItem, colIndex) => {
        if (this.props.direction?.startsWith('top')) {
          const lastSelectedRowIndex = this.props.rowList.findLastIndex((rowItem) =>
            this.findRelation(this.props.relationList, colItem[this.props.colItemId], rowItem[this.props.rowItemId]),
          );

          if (lastSelectedRowIndex >= 0) {
            for (let rowIndex = 0; rowIndex <= lastSelectedRowIndex; rowIndex++) {
              const cell = cells[rowIndex][colIndex];
              verticalWalkOnCell(cell, true, rowIndex !== lastSelectedRowIndex);
            }
          }
        } else {
          const firstSelectedRowIndex = rowList.findIndex((rowItem) =>
            this.findRelation(this.props.relationList, colItem[this.props.colItemId], rowItem[this.props.rowItemId]),
          );

          if (firstSelectedRowIndex >= 0) {
            for (let rowIndex = firstSelectedRowIndex; rowIndex < rowList.length; rowIndex++) {
              const cell = cells[rowIndex][colIndex];
              verticalWalkOnCell(cell, rowIndex !== firstSelectedRowIndex, true);
            }
          }
        }
      });
    }

    if (this.props.rowSelectionIds?.length) {
      // Horizontal lines
      this.props.rowList.forEach((rowItem, rowIndex) => {
        if (this.props.rowSelectionIds?.includes(rowItem[this.props.rowItemId])) {
          if (this.props.direction?.endsWith('Left')) {
            const lastSelectedColIndex = this.props.colList.findLastIndex((colItem) =>
              this.findRelation(this.props.relationList, colItem[this.props.colItemId], rowItem[this.props.rowItemId]),
            );

            // no matches
            if (lastSelectedColIndex >= 0) {
              for (let colIndex = 0; colIndex <= lastSelectedColIndex; colIndex++) {
                const cell = cells[rowIndex][colIndex];
                horizontalWalkOnCell(cell, true, colIndex !== lastSelectedColIndex);
              }
            }
          } else {
            const firstSelectedColIndex = this.props.colList.findIndex((colItem) =>
              this.findRelation(this.props.relationList, colItem[this.props.colItemId], rowItem[this.props.rowItemId]),
            );

            // no matches
            if (firstSelectedColIndex >= 0) {
              for (let colIndex = firstSelectedColIndex; colIndex < colList.length; colIndex++) {
                const cell = cells[rowIndex][colIndex];
                horizontalWalkOnCell(cell, colIndex !== firstSelectedColIndex, true);
              }
            }
          }
        }
      });
    }

    return cells;
  };

  unmutableArrayReverse(array) {
    return [...array].reverse();
  }

  render() {
    let cells = this.cells(this.props.rowList, this.props.colList, this.props.relationList);

    if (this.props.origin?.startsWith('bottom')) {
      // Revert the matrix columns
      cells = cells.map((columns) => this.unmutableArrayReverse(columns));
    }

    if (this.props.origin?.endsWith('Right')) {
      // Revert the matrix rows
      cells = this.unmutableArrayReverse(cells);
    }

    return (
      <table className="relation-table tw-border-collapse tw-size-full">
        <tbody>
          {cells.map((columns, i) => (
            <tr key={i}>
              {columns.map((cell, j) => (
                <td
                  className="tw-size-8 tw-p-0"
                  key={j}>
                  {this.renderCell(cell)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    );
  }
}

export default withTranslation()(RelationTable);

import { Table, Tabs, Tag } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonalDashboardService from '../../../services/pages/personalDashboardService';
import { formatOfDate, mapDeliverableStatus } from '../../shared/utils/constants';
import { capitalizeFirstLetter } from '../../shared/utils/functions';
import PerStatusPhasesChart from './perStatusPhasesChart';
import PerTimePhasesChart from './perTimePhasesChart';

const PersonalDashboardPhases = () => {
  const [phases, setPhases] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    fetchPersonalDashboardPhases();
  }, []);

  const phasesColumns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'name',
      ellipsis: {
        showTitle: true,
      },
      showSorterTooltip: true,
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'status',
      render: (text) => <Tag>{capitalizeFirstLetter(mapDeliverableStatus[text])}</Tag>,
    },
    {
      title: `${t('general.end')}`,
      dataIndex: 'endDate',
      render: (text) => (text ? moment(text).format(formatOfDate) : ''),
    },
  ];

  const tabItems = [
    {
      label: <>{t('bachechaPersonalePage.perStatus')}</>,
      key: '1',
      children: <PerStatusPhasesChart phases={phases} />,
    },
    {
      label: <>{t('bachechaPersonalePage.inTempoRitardo')}</>,
      key: '2',
      children: (
        <PerTimePhasesChart
          phases={phases}
          t={t}
        />
      ),
    },
  ];

  const fetchPersonalDashboardPhases = async () => {
    const resp = await PersonalDashboardService.getPersonalDashboardPhases();

    setPhases(resp.data.responseObject.value);
  };

  return (
    <>
      <Tabs
        className="tw-px-2 no-sticky"
        defaultActiveKey="1"
        items={tabItems}
      />

      <Table
        className="table-full-height bottom-paginator"
        columns={phasesColumns}
        dataSource={phases}
        rowKey={(_, index) => index}
        loading={false}
        size="small"
        pagination={{ defaultPageSize: 5, pageSizeOptions: ['5', '10', '20', '50'] }}
      />
    </>
  );
};

export default PersonalDashboardPhases;

import { useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import ComitieCalendar from './calendar';

const CalendarIndex = ({ comitieId, comitieDetails, ...props }) => {
  const { t } = useTranslation();

  useEffect(() => {}, []);

  return (
    <>
      <div className="comitieCalendar">
        <ComitieCalendar
          comitieId={comitieId}
          comitieDetails={comitieDetails}
        />
      </div>
    </>
  );
};

export default withTranslation()(CalendarIndex);

import { Table, Tooltip, Typography } from 'antd';
import classNames from 'classnames';
import React, { Component } from 'react';
import '../../matrix.scss';

class UsersXmatrix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      modalData: {},
    };
  }

  // open add new record modal
  toggleModal = (rowData) => {
    this.setState({ openModal: !this.state.openModal });
    this.setState({ modalData: rowData });
  };

  // handel modal close
  handleModalCancel = () => {
    this.setState({ openModal: false });
  };

  columns = [
    {
      key: 'combinedColumn',
      render: (text, record, index) => {
        return {
          children: (
            <div
              onClick={() => this.props.onRowClick(record.userID)}
              className={`tw-flex tw-py-1 tw-h-full tw-w-[32px] tw-items-center tw-justify-between ${classNames({
                'tw-bg-primary selected-row':
                  this.props.selection === 'primary' && this.props.selectedIds?.includes(record.userID),
                'tw-bg-primary/30 selected-row':
                  this.props.selection === 'secondary' && this.props.selectedIds?.includes(record.userID),
              })}`}>
              <div className="tw-flex tw-items-center tw-gap-2">
                <div
                  className={`xMatrix-square-label tw-rotate-90 !tw-bg-zinc-100`}
                  key={index}>
                  {index + 1}
                </div>
                <Tooltip title={record.title}>
                  <Typography.Text className="tw-text-sm tw-truncate tw-text-black">{record.title}</Typography.Text>
                </Tooltip>
              </div>
            </div>
          ),
        };
      },
    },
  ];

  render() {
    const { usersXmatrix } = this.props;

    return (
      <>
        <Table
          className="table-full-height"
          dataSource={usersXmatrix}
          pagination={false}
          bordered
          size="small"
          showHeader={false}
          columns={this.columns}
          rowKey={(obj) => obj.userID}></Table>
      </>
    );
  }
}

export default UsersXmatrix;

import { EditOutlined } from '@ant-design/icons';
import { Button, message, Table, Tooltip } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import { history } from '../../../../App';
import ObProgettiService from '../../../../services/matrix/obProgettiService';
import { statusClass, updateNotificationBell } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';
import EditProjectModal from './editProjectModal';

const Projects = (props) => {
  const [openModal, setOpenModal] = useState(false);
  const [loadingButton, setLoadingButton] = useState(false);
  const [loadingButtonRemove, setLoadingButtonRemove] = useState(false);
  const [modalData, setModalData] = useState({});

  // open add new record modal
  const toggleModal = (rowData) => {
    setOpenModal(!openModal);
    setModalData(rowData);
  };

  // handel modal close
  const handleModalCancel = () => {
    setOpenModal(false);
    setLoadingButton(false);
  };

  const columns = [
    {
      dataIndex: 'orderingValue',
      render: (text, record, index) => {
        return {
          children: (
            <XMatrixProjectRow
              record={record}
              index={index}
              props={props}
              toggleModal={toggleModal}
              selectedRowClassName={classNames({
                'tw-bg-primary selected-row':
                  props.selection === 'primary' && props.selectedIds?.includes(record.projectID),
                'tw-bg-primary/30 selected-row':
                  props.selection === 'secondary' && props.selectedIds?.includes(record.projectID),
              })}
            />
          ),
        };
      },
    },
  ];

  //handel update
  const updateData = (values, id) => {
    const { selectedXmatrix } = props;
    values['xMatrixID'] = selectedXmatrix;
    values['projectID'] = id;
    const payload = values;

    setLoadingButton(true);
    ObProgettiService.updateObProgetti(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          updateNotificationBell();
          handleModalCancel();
          props.reloadMatrix();
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingButton(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingButton(false);
      });
  };

  // ... (removeProject, removeProjectDraft functions)

  const removeProject = (id, DeleteProject) => {
    const { selectedXmatrix } = props;
    setLoadingButtonRemove(true);
    ObProgettiService.removeProjectXmatrix(id, selectedXmatrix, DeleteProject)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          handleModalCancel();
          props.reloadMatrix();
          setLoadingButtonRemove(false);
        } else {
          handleModalCancel();
          message.error(notifyMessages.deleteFailed);
          setLoadingButtonRemove(false);
        }
      })
      .catch((error) => {
        handleModalCancel();
        message.error(notifyMessages.deleteFailed);
        setLoadingButtonRemove(false);
      });
  };

  const removeProjectDraft = (id, DeleteProject) => {
    const { selectedXmatrix } = props;

    setLoadingButtonRemove(true);
    ObProgettiService.removeProjectDraftXmatrix(id, selectedXmatrix, DeleteProject)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setLoadingButtonRemove(false);
          message.success(notifyMessages.deleteSuccess);
          handleModalCancel();
          props.reloadMatrix();
        } else {
          setLoadingButtonRemove(false);
          handleModalCancel();
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        setLoadingButtonRemove(false);
        handleModalCancel();
        message.error(notifyMessages.deleteFailed);
      });
  };

  return (
    <>
      {openModal && (
        <EditProjectModal
          show={openModal}
          toggle={toggleModal}
          handleModalCancel={handleModalCancel}
          data={modalData}
          handleSave={updateData}
          removeProject={removeProject}
          removeProjectDraft={removeProjectDraft}
          commities={props.comittieList}
          statuses={props.statusList}
          divisionsList={props.divisionsList}
          loadingButton={loadingButton}
          loadingButtonRemove={loadingButtonRemove}
          selectedXmatrixInfo={props.selectedXmatrixInfoData}
          isSecondLevelXmatrix={props.isSecondLevelXmatrix}
          t={props.t}
        />
      )}

      <Table
        className="[&_table]:tw-h-full"
        dataSource={props.projects}
        pagination={false}
        bordered
        size="small"
        showHeader={false}
        columns={columns}
        rowKey={(obj) => obj.projectID}></Table>
    </>
  );
};

export const XMatrixProjectRow = ({ record, index, props, toggleModal, selectedRowClassName }) => {
  return (
    <div
      onDoubleClick={() => history.push(`/progetti/id/${record.projectID}`)}
      onClick={() => props.onRowClick(record.projectID)}
      className={`tw-flex tw-px-1 tw-h-[31px] tw-w-full matrix-row tw-truncate tw-items-center tw-justify-between ${selectedRowClassName}`}>
      <div className="tw-flex tw-truncate tw-items-center">
        <Tooltip title={record.statusDescription}>
          <div
            className={`xMatrix-square-label ${statusClass(record.status)}`}
            key={index}>
            {record.orderingValue}
          </div>
        </Tooltip>
        <Button
          type="link"
          href={`/progetti/id/${record.projectID}`}
          className=" tw-text-left tw-shrink-0 tw-min-w-[131px] tw-truncate tw-text-rest">
          <span className="tw-truncate tw-text-sm tw-w-full">{record.projectCode}</span>
        </Button>
        <Tooltip title={record.title}>
          <span className="tw-truncate tw-text-sm tw-w-full">{record.title}</span>
        </Tooltip>
      </div>

      <Button
        className="tw-shrink-0"
        type="text"
        icon={<EditOutlined />}
        onClick={() => toggleModal(record)}></Button>
    </div>
  );
};

export default Projects;

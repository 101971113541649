import { EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space, Table, message } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';
import { history } from '../../App';
import DivisionService from '../../services/pages/divisionServices';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './divisions.scss';

class DivisionsListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchedColumn: '',
      divisionListData: null,
    };
  }

  // Init of component after rendering
  componentDidMount() {
    this.retrieveDivisionListData();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',

    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  // Retrieve division data
  async retrieveDivisionListData() {
    await DivisionService.getDivisionsList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ divisionListData: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  }

  onNew = () => {
    history.push('/divisions/new');
  };

  // Retrieve divisions list page
  async retrieveDivisionsListPage() {
    await DivisionService.getDivisionsList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ divisionListData: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  }

  render() {
    const { divisionListData } = this.state;
    let t = this.props.t;

    const columns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'divisionName',
        ellipsis: {
          showTitle: true,
        },
        width: '45%',
        sorter: (a, b) => {
          a = a.divisionName || '';
          b = b.divisionName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('divisionName'),
      },
      {
        title: `${t('general.responsabile')}`,
        dataIndex: 'responsabileDivisione',
        width: '45%',
        sorter: (a, b) => {
          a = a.responsabileDivisione || '';
          b = b.responsabileDivisione || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('responsabileDivisione'),
      },

      {
        key: 'action',
        // width: "30px",
        width: '10%',
        render: (text, record) => (
          <Button
            icon={<EditOutlined />}
            href={`/division/id/${record.divisionID}`}></Button>
        ),
      },
    ];

    return (
      <TableLayout title={t('divisioniPage.divisioni')}>
        <TableLayout.Actions>
          <Button
            type="primary"
            htmlType="submit"
            onClick={this.onNew}>
            <PlusOutlined />
            {t('buttons.aggiungiNuovo')}
          </Button>
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            className="table-height"
            size="small"
            columns={columns}
            dataSource={divisionListData}
            rowKey={(obj) => obj.divisionID}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

export default withTranslation()(DivisionsListPage);

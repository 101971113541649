import React, { useState } from 'react';
import { message } from 'antd';
import { Button, Col, Row } from 'antd';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import { withTranslation } from 'react-i18next';
import { richTectEditoControlls } from '../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../shared/utils/notifyMessages';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import classNames from 'classnames';

const ReasonToAct = ({ currentProject, isPresentationMode, isEditable, t: t }) => {
  const [editorContent, setEditorContent] = useState(BraftEditor.createEditorState(currentProject.groundtoAct));
  const [isTouched, setIsTouched] = useState(false);
  const [loadingEditorButton, setLoadingEditorButton] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Update editor content and set touched state
  const handleEditorChange = (editorState) => {
    setEditorContent(editorState);
    setIsTouched(true);
  };

  // Handle form submission
  const handleSubmit = (event) => {
    event.preventDefault();

    if (!editorContent || editorContent.isEmpty()) {
      setErrorMessage(requiredFields.required);
      return;
    }

    const payload = {
      projectId: currentProject.projectID,
      projectFieldName: 'groundToAct',
      projectFieldValue: editorContent.toHTML(),
    };

    setLoadingEditorButton(true);

    ProgettiService.updateEditorField(payload)
      .then(() => {
        message.success(notifyMessages.updateSuccess);
        setLoadingEditorButton(false);
        setIsTouched(false);
        setErrorMessage('');
      })
      .catch(() => {
        setLoadingEditorButton(false);
      });
  };

  return (
    <div className="tw-h-full">
      {currentProject && (
        <form
          className="tw-h-full tw-flex tw-flex-col"
          data-testid="reasonToActForm"
          id="editorForm"
          onSubmit={handleSubmit}>
          <h1
            className={classNames('tw-text-xl tw-mb-2 tw-font-semibold', {
              '!tw-text-3xl !tw-mb-4': isPresentationMode,
            })}>
            {t('proggetiPage.motivoAgire')}
          </h1>

          <div className="tw-rounded-lg tw-grow tw-overflow-hidden">
            <BraftEditor
              contentFormat="html"
              value={editorContent}
              language="en"
              readOnly={isPresentationMode || !isEditable}
              onChange={handleEditorChange}
              controls={isPresentationMode ? [] : richTectEditoControlls}
              id="editor-text"
              className={classNames({
                '[&_.bf-content]:tw-h-full tw-min-h-full [&_.bf-content]:tw-text-3xl': isPresentationMode,
              })}
            />
            {errorMessage && <p className="tw-text-red-500 tw-mt-2">{errorMessage}</p>}
          </div>

          {!isPresentationMode && (
            <div
              key="buttons"
              className="tw-ml-auto tw-w-fit tw-mt-4">
              <Button
                type="primary"
                htmlType="submit"
                disabled={!isTouched}
                loading={loadingEditorButton}>
                {t('buttons.salva')}
              </Button>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default withTranslation()(ReasonToAct);

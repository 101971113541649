import { Avatar } from 'antd';
import classNames from 'classnames';
import moment from 'moment';
import { useCallback } from 'react';
import { useDrag } from 'react-dnd';
import { BsArrowDownCircleFill, BsArrowUpCircleFill, BsPauseCircleFill } from 'react-icons/bs';
import { RiCalendar2Fill } from 'react-icons/ri';
import { formatOfDate } from '../../../../../shared/utils/constants';
import { generateUserInitials } from '../../../../../shared/utils/functions';
import { DeliverableIcon, PhaseIcon, TaskIcon } from './icons';
import { history } from '../../../../../../App';

export const ItemTypes = {
  CARD: 'card',
};

const KanBanItem = ({ item, isDndEnabled }) => {
  const { title, itemType, statusIcon, ownerFullName, ownerAvatar, priority, startDate, endDate } = item;

  const [{ isDragging }, drag] = useDrag(() => ({
    type: ItemTypes.CARD,
    item: { colItem: item },
    collect: (monitor) => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const typeIcon = useCallback(() => {
    switch (itemType) {
      case 'phase':
        return <PhaseIcon />;
      case 'deliverable':
        return <DeliverableIcon />;
      case 'task':
        return <TaskIcon />;
      default:
        return <></>;
    }
  }, [itemType]);

  const priorityComponent = useCallback(() => {
    switch (priority) {
      case 1:
        return { label: 'High Priority', icon: <BsArrowUpCircleFill size={23} /> };
      case 2:
        return {
          label: 'Medium Priority',
          icon: (
            <BsPauseCircleFill
              className="tw-rotate-90"
              size={23}
            />
          ),
        };
      case 3:
        return { label: 'Low Priority', icon: <BsArrowDownCircleFill size={23} /> };
      default:
        return null;
    }
  }, [priority]);
  const priorityData = priorityComponent();

  const openCardPage = () => {
    switch (item.itemType) {
      case 'phase':
        history.push(`/deliverables/id/${item.deliverableID}`);
        break;
      case 'deliverable':
        history.push(`/deliverables/id/${item.deliverableID}`);
        break;
      case 'task':
        history.push(`/deliverables/id/${item.deliverableID}?tab=2`);
        break;
      default:
        break;
    }
  };

  return (
    <div
      onClick={() => openCardPage()}
      ref={isDndEnabled ? drag : undefined}
      className={classNames(
        'tw-flex tw-cursor-pointer tw-transition-all tw-rounded tw-shadow-sm tw-w-full tw-flex-col tw-bg-white',
        {
          '!tw-shadow-lg !tw-cursor-grabbing tw-opacity-30': isDragging,
          '!tw-cursor-grab hover:!tw-shadow-md': isDndEnabled,
        },
      )}>
      <div className="tw-flex tw-gap-2 tw-p-2">
        {typeIcon()}
        <p className="text-base tw-font-semibold tw-text-balance tw-grow">{title}</p>
        {statusIcon}
      </div>

      <div className="tw-flex tw-flex-col tw-mt-3 tw-gap-1 tw-p-2">
        <ItemAvatar
          name={ownerFullName}
          avatar={ownerAvatar}
        />
        {item.itemType !== 'task' && priorityData && (
          <div className="tw-flex tw-gap-2">
            {priorityData.icon}
            {priorityData.label}
          </div>
        )}
        <div className="tw-flex tw-items-center tw-gap-2">
          <RiCalendar2Fill size={20} />
          {moment(startDate).format(formatOfDate)}
          {` <-> `}
          {moment(endDate).format(formatOfDate)}
        </div>
      </div>
    </div>
  );
};

export const ItemAvatar = ({ name, avatar }) => (
  <div className="table-avatar s!tw-gap-2">
    {avatar ? (
      <Avatar
        size="small"
        src={avatar}
      />
    ) : (
      <Avatar size="small">{generateUserInitials(name?.split(' ')?.[0], name?.split(' ')?.[1])}</Avatar>
    )}
    <span className="tw-text-sm">{name}</span>
  </div>
);

export default KanBanItem;

import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import React from 'react';
import './loader2.scss';

export const Loader2 = (props) => {
  const antIcon = (
    <LoadingOutlined
      style={{ fontSize: 24 }}
      spin
    />
  );

  return (
    <div
      className="spinner2"
      align="center">
      <Spin indicator={antIcon} />
      <p className="loading">Loading...</p>
    </div>
  );
};

export default Loader2;

import { trackPromise } from 'react-promise-tracker';
import { acquireToken } from '../../../config/auth/clientAuthProvider';
import axiosInstance from '../../../config/axiosConfig/interceptors';
import i18n from '../../../i18n';
import { getParentProjects } from './progettiService.ext';
import {
  authenticatedApi,
  CreateProjectRequestDto,
  InsertProjectRepositoryRequestDto,
  ProjectApi,
  SwapTeamMembersActivitiesRequest,
  UpdateOnCallPropertyRequestDto,
  UpdateProjectFieldsRequestDto,
  UpdateProjectRequestDto,
  UpdateTeamMembersRequest,
  XMatrixApi,
} from 'src/config/connectors';

const ProgettiService = {
  getProgettiList: async function (hasProjectRelation: boolean, xMatrixId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectListPage(hasProjectRelation, xMatrixId));
  },

  // get project by id
  getProgettiByiD: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProject(projectId));
  },

  getParentProjectByID: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProject(projectId));
  },

  addNewProject: async function (project: CreateProjectRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectCreateProject(project));
  },

  getProjectTeamMembers: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectMembers(projectId));
  },

  getProjectObjectives: async function (projectId: string, xmatrixId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetGoalByProject(projectId, xmatrixId));
  },

  checkIfProjectCodeExist: async function (projectCode: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectCheckDuplicateProject(projectCode));
  },

  updateProject: async function (payload: UpdateProjectRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateProject(payload));
  },

  // update project editor fields
  updateEditorField: async function (payload: UpdateProjectFieldsRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateProjectFields(payload));
  },

  getParentProjects,

  getDivisionProjects: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetDivisionProjectCreationPicklist(xmatrixId));
  },

  updateTeamMembers: async function (payload: UpdateTeamMembersRequest) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateTeamMembers(payload));
  },

  setUserOnCall: async function (payload: UpdateOnCallPropertyRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateOnCallProperty(payload));
  },

  // get project deliverables picklist
  getProjectPicklist: async function () {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectDeliverablePicklist());
  },

  getKpiProjectPicklist: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetKPIProjectPicklist(projectId));
  },

  deleteProject: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectDeleteProject(projectId));
  },

  getChildProjects: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectChilds(projectId));
  },

  getProjectCostUserAccess: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetUserProjectCostAccess(projectId));
  },

  getProjectLink: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectRepository(projectId));
  },

  updateProjectLink: async function (payload: InsertProjectRepositoryRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectInsertProjectRepository(payload));
  },

  getProjectSintesiData: function (isChecked: any, xMatrixId: any) {
    let queryParams = `?HasProjectRelation=${isChecked}&xMatrixID=${xMatrixId}`;
    const data = acquireToken().then((tokenResponse?) => {
      const optionsVal = {
        method: 'GET',
        url: `api/Project/getProjectSintesiData${queryParams}`,
        headers: {
          Authorization: `Bearer ${tokenResponse?.accessToken}`,
        },
      };
      const apiResponse = axiosInstance(optionsVal);
      return apiResponse;
    });
    return data;
  },

  getProjectSintesi: async function (filters: any, XmatrixID: any, selectNotRelatedProjects: any) {
    const languageCode = i18n.language;
    const { accessToken } = (await acquireToken()) as { accessToken: string };
    const queryString = Object.entries({ ...filters, XmatrixID, selectNotRelatedProjects, languageCode })
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const optionsVal = {
      method: 'GET',
      url: `api/ProjectDashboard/GetProjectSummary?${queryString}`,
      data: filters,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return trackPromise(axiosInstance(optionsVal));
  },

  getProjectTeamMebersList: async function (hasProjectRelation: boolean, xmatrixId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectListPage(hasProjectRelation, xmatrixId));
  },

  swapProjectTeamMembers: async function (payload: SwapTeamMembersActivitiesRequest) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectSwapTeamMembersActivities(payload));
  },
};

export default ProgettiService;

import React, { useState, useEffect } from 'react';
import { msalApp, acquireToken } from './clientAuthProvider';
import CustomSpin from '../../components/shared/Spin';
import { Spin } from 'antd';

export function withAuth(HocComponent) {
  return function AuthWrapper(props) {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [errorMessage, setErrorMessage] = useState(null);

    useEffect(() => {
      const authenticate = async () => {
        try {
          const result = await acquireToken(false);
          setIsAuthenticated(!!result?.accessToken);
          setErrorMessage(null);
        } catch (error) {
          setIsAuthenticated(false);
          setErrorMessage(error.errorMessage || error.message || JSON.stringify(error) || 'An error occurred');
        }
      };

      authenticate();
    }, []);

    const onSignOut = () => {
      msalApp.logoutRedirect();
    };

    if (isAuthenticated) {
      return (
        <HocComponent
          auth={{ isAuthenticated, errorMessage }}
          onSignIn={() => {}}
          onSignOut={onSignOut}
          {...props}
        />
      );
    }

    return (
      <div className="tw-h-screen tw-w-screen tw-flex tw-items-center tw-justify-center">
        <p className="tw-text-xl tw-font-medium">{errorMessage || <Spin size="lg" />}</p>
      </div>
    );
  };
}

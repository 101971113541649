import { Form } from 'antd';
import React from 'react';
import '../projects/projectForm/deliverablesAndKpi/deliverable.scss';

import { Card, Col, Modal, Row, Select } from 'antd';
import KpiService from '../../services/pages/kpiService';
import { ModalFooterButtons } from '../shared/components/modal/modalFooterButtons';
import { requiredFields } from '../shared/utils/notifyMessages';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class NewChildKpiModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      loadingButton: false,
      kpiList: null,
    };
  }

  componentDidMount() {
    this.retrieveChildPickList();
  }

  // Retrieve KPI list
  async retrieveChildPickList() {
    const { parentKpiId } = this.props;
    await KpiService.getKpiProjectPicklist(parentKpiId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ kpiList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  addKpiData = (values, parentKpiId) => {
    this.props.handelChildKpiSave(values, parentKpiId);
  };

  render() {
    const { showChildKpiModal, handelChildKpiModalClose, loadingButton, parentKpiId, t } = this.props;
    const { kpiList } = this.state;

    let kpiOtions = kpiList
      ? kpiList.map((item) => {
          return { value: item.kpiDetailID, label: item.name };
        })
      : [];

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('kpiPage.aggiungiKpiFiglio')}
        open={showChildKpiModal}
        destroyOnClose={true}
        onCancel={handelChildKpiModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handelChildKpiModalClose}
            formId={'newChildKpiForm'}
          />,
        ]}>
        <Card>
          <Form
            id="newChildKpiForm"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.addKpiData(values, parentKpiId)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  label={t('kpiPage.listaKpi')}
                  name="KPIDetailID"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}>
                  <Select
                    allowClear
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                    options={kpiOtions}></Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default NewChildKpiModal;

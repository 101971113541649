import {
  CheckCircleOutlined,
  CheckOutlined,
  EditOutlined,
  FilterOutlined,
  InfoCircleTwoTone,
  LockOutlined,
  SyncOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import {
  Button,
  Divider,
  InputNumber,
  message,
  Popconfirm,
  Popover,
  Select,
  Space,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import A3BlueTitle from 'src/components/A3BlueTitle';
import Section2Service from '../../../services/a3/section2Service';
import DeliverablePageService from '../../../services/pages/deliverableService';
import TableLayout from '../../shared/tableLayout';
import { userIsProjectTeamLeaderOrSponsor } from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { deliverableStatus, formatOfDate, projectStatus } from '../../shared/utils/constants';
import { updateNotificationBell } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import './section2.scss';

const { Text } = Typography;
const { Option } = Select;

// For deliverable table cell colors
const checkDeliverableCellColor = (status, deliverable, delay) => {
  if (delay) {
    if (status === 3 || status === 5) {
      if (deliverable.statusID === deliverableStatus.aperto) {
        //red stripe
        return 'repeating-linear-gradient(45deg,#ffffff 1px,#fdfdfd 3px,#ff0000 1px,#d90101 7px )';
      } else if (deliverable.statusID === deliverableStatus.chiuso) {
        //strong gray stripe
        return 'repeating-linear-gradient(45deg,#ffffff 1px,#ffffff 3px,#a2a2a2 1px,rgb(142, 142, 142) 7px)';
      }
    }
  } else {
    if (status === 1) {
      if (deliverable.statusID === deliverableStatus.chiuso) {
        return '#858585'; //strong gray
      } else {
        return '#22c55e'; //green
      }
    } else if (status === 2) {
      return '#3b82f6'; //blue
    } else if (status === 3) {
      // if (deliverable.statusDescription === "Aperto") {
      if (deliverable.statusID === deliverableStatus.aperto) {
        return '#ef4444'; //red
      }
      // else if (deliverable.statusDescription === "Chiuso") {
      else if (deliverable.statusID === deliverableStatus.chiuso) {
        // return "#dc2626";//red
        return '#a3a3a3'; //strong gray
      }

      // else if (deliverable.statusDescription === "Sospeso") {
      else if (deliverable.statusID === deliverableStatus.sospeso) {
        return '##f59e0b'; // yellow-500
      }
    } else if (status === 4) {
      return '##f59e0b'; //yellow-500
    } else if (status === 5) {
      if (deliverable.statusID === deliverableStatus.aperto) {
        //red strip
        return 'repeating-linear-gradient(45deg,#f87171 1px,#f87171 3px,#f87171 1px,#f87171 7px )';
      } else if (deliverable.statusID === deliverableStatus.chiuso) {
        //strong gray stripe
        return 'repeating-linear-gradient(45deg,#e5e5e5 1px,#e5e5e5 3px,#e5e5e5 1px,#e5e5e5 7px)';
      } else {
        return 'white';
      }
    } else {
      return '#FFFFFF';
    }
  }
};

const gantDeliverableTooltipData = (data, t) => {
  return (
    <Space
      direction="vertical"
      style={{ maxWidth: 400 }}>
      <Text
        underline
        strong>
        {data.name}
      </Text>
      <Divider style={{ margin: 2 }} />
      <Text>
        {t('general.stato')}: {data.statusDescription}
      </Text>
      <Text>
        {t('general.start')}: {moment(data.startDate).format('DD/MM/YYYY')}
      </Text>
      <Text>
        {t('general.end')}: {moment(data.endDate).format('DD/MM/YYYY')}
      </Text>
      <Text>
        {t('general.responsabile')}: {data.fullName}
      </Text>
    </Space>
  );
};

const prsDeliverableTooltipData = (data, t) => {
  return (
    <Space
      direction="vertical"
      className="tw-max-w-[300px] tw-min-w-[200px]">
      <Text
        underline
        strong>
        {data.deliverableSubtitle}
      </Text>
      <Text>
        {t('general.stato')}: {data.statusDescription}
      </Text>
      <Text>
        {t('general.start')}: {moment(data.startDate).format(formatOfDate)}
      </Text>
      <Text>
        {t('general.end')}: {moment(data.endDate).format(formatOfDate)}
      </Text>
      <Text>
        {t('general.responsabile')}: {data.fullName}
      </Text>
    </Space>
  );
};

const Section2 = ({ id, hasUserAccess, isModalOpen, isPresentationMode, t, currentProject, userData, showGantt }) => {
  const [loadingFastClose, setLoadingFastClose] = useState(false);

  const [activeDeliverable, setDeliverableActive] = useState(true);
  const [monthsToDisplay, setMonthsToDisplay] = useState(currentProject ? currentProject.masterplanDuration : 12);
  const [masterPlanStart, setMasterPlanStart] = useState(2);

  const [gantChartDeliverableData, setGantChartDeliverableData] = useState(null);
  const [gantChartTableColumns, setGantChartTableColumns] = useState([]);
  const [loadingGantTable, setLoadingGantTable] = useState(false);

  const [prsDeliverableData, setPrsDeliverableData] = useState([]);
  const [prsTableColumns, setPrsTableColumns] = useState([]);
  const [loadingPRSTable, setLoadingPRSTable] = useState(false);

  let currentMonth = moment().format('MMM YYYY');
  let projectMonths = Math.round(moment(currentProject.endDate).diff(moment(currentProject.startDate), 'months', true));

  useEffect(() => {
    let localMonthDuration = localStorage.getItem('monthDuration');
    let localRangeStart = localStorage.getItem('rangeStart');

    if (localMonthDuration) {
      setMonthsToDisplay(parseInt(localMonthDuration));
      localStorage.setItem('monthDuration', localMonthDuration);
    } else {
      localStorage.setItem('monthDuration', monthsToDisplay);
    }

    if (localRangeStart) {
      setMasterPlanStart(parseInt(localRangeStart));
      localStorage.setItem('rangeStart', localRangeStart);
    } else {
      localStorage.setItem('rangeStart', masterPlanStart);
    }

    retrieveMasterGanttDeliverable(id);
    // retrieveMasterPlanPrsDeliverable(id);
  }, []);

  useEffect(() => {
    retrieveMasterPlanPrsDeliverable(id);
  }, [activeDeliverable]);

  const retrieveMasterGanttDeliverable = (id) => {
    let monthsToDisplayLocal = localStorage.getItem('monthDuration');
    let masterPlanStartLocal = localStorage.getItem('rangeStart');

    setLoadingGantTable(true);
    Section2Service.getMasterPlanParentTEST(id, monthsToDisplayLocal, masterPlanStartLocal)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respDataPrs = resp.responseObject.value;

          if (respDataPrs && respDataPrs.length > 0) {
            let deliverableMonths = respDataPrs.find(
              (element) => element.deliverableMonthList != null && element.deliverableMonthList !== '',
            );
            let monthList = JSON.parse(deliverableMonths.deliverableMonthList);

            let gantChartTableColumns = generateGantTableColumns(respDataPrs, t, monthList);
            setGantChartTableColumns(gantChartTableColumns);
            setGantChartDeliverableData(respDataPrs);
            setLoadingGantTable(false);
          } else {
            setGantChartTableColumns([]);
            setGantChartDeliverableData([]);
            setLoadingGantTable(false);
          }
        } else {
          message.error(notifyMessages.retrieveFailed);
          setLoadingGantTable(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        setLoadingGantTable(false);
      });
  };

  const retrieveMasterPlanPrsDeliverable = (id) => {
    setLoadingPRSTable(true);
    let monthsToDisplayLocal = localStorage.getItem('monthDuration');
    let masterPlanStartLocal = localStorage.getItem('rangeStart');

    Section2Service.getMasterPlanDeliverable(id, monthsToDisplayLocal, masterPlanStartLocal, activeDeliverable)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;

          if (respData && respData.length > 0) {
            let prsDeliverableProcessedData = groupPrsDeliverablesByMonth(respData);
            setPrsDeliverableData(prsDeliverableProcessedData);
            let tableColumns = generatePRStableColumns(prsDeliverableProcessedData, t);
            setPrsTableColumns(tableColumns);
            setLoadingPRSTable(false);
          } else {
            setPrsDeliverableData([]);
            setPrsTableColumns([]);
            setLoadingPRSTable(false);
          }
        } else {
          message.error(notifyMessages.retrieveFailed);
          setLoadingPRSTable(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        setLoadingPRSTable(false);
      });
  };

  const onMaterPlanStartChange = (option) => {
    localStorage.setItem('rangeStart', option);
    setMasterPlanStart(option);
  };

  const onMonthsToDisplayChange = (value) => {
    localStorage.setItem('monthDuration', value);
    setMonthsToDisplay(value);
  };

  const onFilter = () => {
    retrieveMasterGanttDeliverable(id);
    retrieveMasterPlanPrsDeliverable(id);
  };

  const generateGantTableColumns = (deliverableData, t, monthList) => {
    if (!deliverableData || deliverableData.length === 0) return [];

    let columns = [
      {
        title: `${t('a3Page.macroAttivita')}`,
        dataIndex: 'name',
        key: 'name',
        fixed: 'left',
        width: '400px',
        render: (text, record) => (
          <Popover content={gantDeliverableTooltipData(record, t)}>
            <Link
              className="tw-line-clamp-1 tw-min-w-[300px]"
              to={{ pathname: `/deliverables/id/${record.deliverableID}` }}>
              {text?.toUpperCase()}
            </Link>
          </Popover>
        ),
      },
    ];

    // Process each month in the monthList
    monthList.forEach((item) => {
      const currentRecordMonth = item.Month;

      // Format month and year for display
      const formattedMonth = moment(currentRecordMonth).format('MMM YYYY');
      const displayMonth = moment(currentRecordMonth).format('MMM');
      const displayYear = moment(currentRecordMonth).format('YYYY');

      // Construct the month name component
      const monthNameComponent = (
        <div className="tw-flex tw-flex-col tw-gap-0">
          <p className="tw-text-lg">{displayYear}</p>
          <span className="tw-text-zinc-400 tw-uppercase">{displayMonth}</span>
        </div>
      );

      // Add the month column to the columns array
      columns.push({
        dataIndex: currentRecordMonth,
        className: formattedMonth === currentMonth ? 'currentMonthIndicator' : '',
        title: monthNameComponent,
        render(text, record) {
          const deliverableMonths = JSON.parse(record.deliverableMonthList);
          const deliverableMonth = deliverableMonths.find((m) => m.Month === currentRecordMonth);

          // If no deliverable month data exists, return empty
          if (!deliverableMonth) return '';

          const monthValue = deliverableMonth.Value || null;
          const delay = deliverableMonth.Delay || null;
          const isLastDeliverableMonth = checkIfLastMonthOfDeliverable(deliverableMonths);

          // Determine the cell style and content based on deliverable values
          const cellBackgroundColor = checkDeliverableCellColor(monthValue, record, delay);

          return {
            props: {
              className: cellBackgroundColor === '#FFFFFF' ? 'empty' : 'filled',
              style: {
                borderTop: '4px solid white',
                borderBottom: '2px solid white',
                background: cellBackgroundColor,
                textAlign: 'right',
              },
            },
            children: delay ? (
              <Tooltip title={`+${delay} ${t('general.gg')}`}>
                <InfoCircleTwoTone
                  twoToneColor="#b1beca"
                  style={{ fontSize: 20 }}
                />
              </Tooltip>
            ) : (
              <Text>
                {isLastDeliverableMonth === currentRecordMonth &&
                  monthValue &&
                  record.statusID === deliverableStatus.chiuso && (
                    <Tooltip title={t('a3Page.statusClosed')}>
                      <CheckCircleOutlined style={{ fontSize: 24, color: '#ffffffad' }} />
                    </Tooltip>
                  )}
              </Text>
            ),
          };
        },
      });
    });

    return columns;
  };

  const generatePRStableColumns = (paretDeliverableData, t) => {
    if (!paretDeliverableData || paretDeliverableData.length === 0) return [];

    return [
      {
        title: t('general.anno'),
        dataIndex: 'monthValue',
        key: 'monthValue',
        align: 'left',
        width: '200px',
        render: (text) => <Text>{moment(text).format('MMM YYYY')}</Text>,
      },
      {
        title: t('bachechaPersonalePage.macroAttivitaDeliverable'),
        align: 'left',
        dataIndex: 'items',
        key: 'items',
        render: (text, record) => <>{record.items.map((item, i) => generateDeliverableInfo(item, i))}</>,
      },
    ];
  };

  const checkIfLastMonthOfDeliverable = (months) => {
    if (!months || months.length === 0) return undefined; // Handle empty or undefined array

    let deliverableLastMonth = undefined;

    months.forEach((item) => {
      if (item?.Value) {
        deliverableLastMonth = item.Month;
      }
    });

    return deliverableLastMonth;
  };

  const groupPrsDeliverablesByMonth = (data) => {
    // create an array of project months that HAVE deliverables groupet by month.
    const groupedData = data.reduce((acc, item) => {
      const key = moment(item.monthValue).format('YYYY-MM-DD');
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(item);
      return acc;
    }, {});

    const orderedListByMonth = Object.keys(groupedData)
      .sort()
      .map((key) => ({ monthValue: key, items: groupedData[key] }));
    return orderedListByMonth;
  };

  const DeliverableName = ({ name }) => <p className="tw-text-lg">{name ? `(${name})` : name}</p>;

  const DeliverableLink = ({ deliverable, styles }) => {
    return (
      <Link
        to={{ pathname: `/deliverables/id/${deliverable.deliverableID}` }}
        style={styles}>
        {deliverable.deliverableSubtitle}
      </Link>
    );
  };

  const DeliverableOption = ({ deliverable }) => {
    let quickClose = deliverable.status !== deliverableStatus.chiuso ? true : false;
    let hasUserRights = userIsProjectTeamLeaderOrSponsor(
      userData,
      currentProject.teamLeaderID,
      currentProject.sponsorID,
    );

    return (
      <div className="tw-flex tw-items-center tw-gap-2">
        {(deliverable.hasAccess || hasUserRights) && (
          <Button
            icon={<EditOutlined />}
            href={`/deliverables/id/${deliverable.deliverableID}`}></Button>
        )}

        {quickClose && (
          <>
            {(deliverable.hasAccess || hasUserRights) && (
              <Popconfirm
                key="close"
                placement="topRight"
                title={t('deliverablePage.chiudiDeliverable')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => handelDeliverableFastClose(deliverable)}>
                <Tooltip title={t('buttons.chiusuraRapida')}>
                  {loadingFastClose ? <Button icon={<SyncOutlined spin />} /> : <Button icon={<CheckOutlined />} />}
                </Tooltip>
              </Popconfirm>
            )}
          </>
        )}
      </div>
    );
  };

  const handelDeliverableFastClose = (deliverable) => {
    let payload = {};

    payload['deliverableID'] = deliverable.deliverableID;
    payload['status'] = deliverableStatus.chiuso;
    payload['ownerID'] = deliverable.ownerID;
    payload['projectID'] = id;

    setLoadingFastClose(true);
    DeliverablePageService.fastCloseDeliverable(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          setLoadingFastClose(false);
          retrieveMasterPlanPrsDeliverable(id);
          updateNotificationBell();
        } else {
          message.error(notifyMessages.updateFailed);
          setLoadingFastClose(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        setLoadingFastClose(false);
      });
  };

  const deliverableNameColor = (status) => {
    if (currentProject.status === projectStatus.sospeso && status === 1) {
      return '#696969';
    } else if (status === 1) {
      return '#22c55e'; //green
    } else if (status === 2) {
      return '#22c55e';
    } else if (status === 3) {
      return '#ef4444'; //red
    } else if (status === 4) {
      return '#ef4444';
    } else if (status === 5) {
      return '#f59e0b';
    } else if (status === 6) {
      // return '#FFFFFF'
      return '#a3a3a3';
    }
  };

  const generateDeliverableInfo = (deliverable, i) => {
    const { deliverableTitle, deliverableSubtitle, deliverableID, deliverableStatus, status } = deliverable;

    // Helper to render a lock icon and deliverable link
    const renderDeliverableLink = (content, isLocked) => (
      <Space direction="horizontal">
        {isLocked && <LockOutlined />}
        {deliverableTitle ? (
          <DeliverableLink
            deliverable={deliverable}
            styles={{ color: deliverableNameColor(deliverableStatus) }}
          />
        ) : (
          <Link
            to={{ pathname: `/deliverables/id/${deliverableID}` }}
            style={{ color: deliverableNameColor(deliverableStatus), fontSize: '16px' }}>
            {content}
          </Link>
        )}
      </Space>
    );

    // Main structure for the deliverable item, keeping buttons and content in the same div
    const renderDeliverableItem = (title, subtitle, isLocked) => (
      <div
        key={i}
        className="tw-flex tw-w-full tw-justify-between tw-items-center">
        <Space direction="vertical">
          <Popover content={prsDeliverableTooltipData(deliverable, t)}>
            {renderDeliverableLink(t('a3Page.fineMacroAttivita'), isLocked)}
          </Popover>
          <DeliverableName name={title || subtitle} />
        </Space>
        <div className="tw-flex tw-items-center">
          <DeliverableOption deliverable={deliverable} />
        </div>
      </div>
    );

    // Handle case when deliverableSubtitle is present
    if (deliverableSubtitle !== '') {
      const isLocked = status === 4;
      return deliverableTitle
        ? renderDeliverableItem(deliverableTitle, deliverableSubtitle, isLocked)
        : renderDeliverableItem(null, deliverableSubtitle, isLocked);
    }

    // Handle case when deliverableSubtitle is not present
    return (
      <di
        key={i}
        className="tw-flex tw-w-full tw-justify-between tw-items-center">
        <Link to={{ pathname: `/deliverables/id/${deliverableID}` }}>{deliverableTitle}</Link>
        <div className="tw-flex tw-items-center">
          <DeliverableOption deliverable={deliverable} />
        </div>
      </di>
    );
  };

  return (
    <div className={`${isModalOpen ? 'section-opened' : 'section section-two-card'} section2Over`}>
      {isPresentationMode ? (
        <h1 className={'tw-font-semibold !tw-text-3xl'}>{t('a3Page.masterPlan')}</h1>
      ) : (
        <h1 className="tw-text-3xl tw-mt-8 tw-mb-4">{t('a3Page.masterPlan')}</h1>
      )}

      <TableLayout
        title={
          <A3BlueTitle
            title={t('a3Page.projectReviewSystem')}
            href={`/progetti/id/${id}?tab=2`}
          />
        }>
        <TableLayout.Actions>
          <Space direction="horizontal">
            <Tooltip title={t('a3Page.monthsNumber')}>
              <InputNumber
                value={monthsToDisplay}
                min={0}
                max={projectMonths}
                disabled={loadingGantTable}
                onChange={onMonthsToDisplayChange}
              />
            </Tooltip>

            <Select
              value={masterPlanStart}
              style={{ width: '100%' }}
              placeholder={t('a3Page.startMonthDisplay')}
              onChange={(option) => onMaterPlanStartChange(option)}
              disabled={loadingGantTable}>
              <>
                <Option
                  value={2}
                  key={2}>
                  {t('a3Page.startYear')}
                </Option>
                <Option
                  value={1}
                  key={1}>
                  {t('a3Page.startProject')}
                </Option>
                <Option
                  value={3}
                  key={3}>
                  {t('a3Page.endProject')}
                </Option>
              </>
            </Select>

            <Tooltip title={t('general.filter')}>
              <Button
                onClick={onFilter}
                icon={<FilterOutlined />}></Button>
            </Tooltip>
          </Space>
        </TableLayout.Actions>
        <TableLayout.Content>
          {showGantt && (
            <Table
              columns={gantChartTableColumns}
              dataSource={gantChartDeliverableData}
              size="small"
              rowKey={(obj) => obj.deliverableID + obj.name}
              pagination={false}
              loading={loadingGantTable}
              style={{ overflowX: 'scroll' }}
            />
          )}
        </TableLayout.Content>
      </TableLayout>

      {/* <TableLayout
        className="tw-mt-4"
        title={
          <h2 className="tw-text-xl tw-flex tw-items-center tw-gap-2">{t('a3Page.projectReviewSystem')}</h2>
        }>
        <TableLayout.Actions>
          <ButtonGroup>
            <Button onClick={onFilterPRSbyActiveDeliverable}>{t('a3Page.openDeliverable')}</Button>
            <Button onClick={onFilterPRSbyAllDeliverable}>{t('a3Page.allDeliverables')}</Button>
          </ButtonGroup>
        </TableLayout.Actions>

        <TableLayout.Content>
          <Table
            columns={prsTableColumns}
            dataSource={prsDeliverableData}
            size="small"
            rowKey={(obj) => obj.monthValue}
            pagination={false}
            loading={loadingPRSTable}
            rowClassName={(obj) =>
              moment(obj.monthValue).format(formatOfDate) === currentMonth ? 'currentMonthIndicatorRow' : ''
            }
          />
        </TableLayout.Content>
      </TableLayout> */}
    </div>
  );
};

export default Section2;

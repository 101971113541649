import { Flex, Spin, Table, Tooltip, message } from 'antd';
import moment from 'moment';
import { Component, createRef } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import CommonService from '../../../services/commonService';
import MatrixService from '../../../services/matrix/matrixService';
import DashboardService from '../../../services/pages/dashboardService';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import {
  BudgetTooltip,
  ProjectBudgetSemaphore,
  ProjectQualitySemaphore,
  ProjectTimeSemaphore,
  QualityTooltip,
  TimeTooltip,
  checkSemaphoreColor,
} from '../../shared/semaphores/semaphores';
import { projectStatus, statusPickListCodes } from '../../shared/utils/constants';
import { alignCurentMonthProjectSintesi, capitalizeFirstLetter } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import './projectTimeline.scss';

import { CalendarOutlined, CloseOutlined, FilterOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import SintesiFilters from './projectTimelineFilters';

import { XmatrixFilter } from '../../shared/components/xMatrixFilter/xMatrixFilter';

let appliedFilters = {
  teamLider: null,
  sponsor: null,
  status: projectStatus.attivo,
  division: null,
  committie: null,
  projectCode: null,
  showFiltersModal: false,
  selectNotRelatedProjects: true,
};

class ProjectsTimeline extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expandedKeys: [],
      tableColums: [],
      projects: [],
      filters: {
        teamLeaders: [],
        sponsor: [],
        status: [],
        division: [],
        committie: [],
        semaphores: [],
        projectCode: [],
      },
      loadingData: false,
      filtersLoaded: false,
      sortField: 'projectCode',
      sortType: 'Sort A to Z',
      projectSintesiDataList: null,
      sortFields: [
        { label: `${this.props.t('proggetiPage.nomeProgetto')}`, value: 'title' },
        { label: `${this.props.t('proggetiPage.codiceProgetto')}`, value: 'projectCode' },
        { label: `${this.props.t('general.start')}`, value: 'startDate' },
        { label: `${this.props.t('general.end')}`, value: 'endDate' },
      ],
      sortTypes: ['Sort A to Z', 'Sort Z to A'],
      expanded: false,
      selectedXmatrix: null,
      activeXmatrixInfo: null,
      selectedFiltersLabel: [
        { value: 'statusFilter', label: this.props.t('dashboardProgettiPage.statoProgetto') },
        { value: 'selectNotRelatedProjects', label: this.props.t('proggetiPage.progettiSenzaRelazione') },
      ],
      xmatrixPicklist: null,
      loadingPicklist: true,
    };
    this.filtersComp = createRef();
    this.columnRef = createRef();
  }

  componentDidMount() {
    this.retrieveComponentData();
  }

  retrieveComponentData = async () => {
    this.setState({ loading: true });

    try {
      await this.getActiveXmatrix();
      await this.xmatrixPicklist();
      await this.getProjectSintesiDataList();
    } finally {
      this.setState({ loading: false });
    }
  };

  async getProjectSintesiDataList(xMatrixId) {
    const { activeXmatrixInfo } = this.state;
    let xmId = xMatrixId ? xMatrixId : activeXmatrixInfo;

    console.log(appliedFilters);

    try {
      const response = await ProgettiService.getProjectSintesi(
        appliedFilters,
        xmId,
        appliedFilters.selectNotRelatedProjects,
      );
      const resp = response.data;

      if (resp.success) {
        let res = resp.responseObject.value;

        const result = res.map((item) => {
          const tempItem = { ...item };
          if (tempItem.children.length === 0) {
            delete tempItem.children;
          }
          return tempItem;
        });

        this.setState({ projectSintesiDataList: result });

        let tableColumns = this.generateTableColumns(res, this.props.t, this.props.companyData);
        this.setState({ tableColums: tableColumns }, () => {
          alignCurentMonthProjectSintesi();
        });
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
      console.error(error);
    }
  }

  async getActiveXmatrix() {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          this.setState({ activeXmatrixInfo: respData.xMatrixID });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveTeamLeaders() {
    const { filters } = { ...this.state };
    let t = this.props.t;

    await DashboardService.getTeamLaderFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          usr.push({ value: null, label: t('general.seleziona') + '...', key: 'teamLiderNan' });

          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          filters.teamLeaders = usr;
          this.setState({ filters });
        }
      });
  }

  async retrieveSponsor() {
    const { filters } = { ...this.state };
    await DashboardService.getSponsorFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          filters.sponsor = resp.responseObject.value;
          this.setState({ filters });
        }
      });
  }

  async retrieveProjectCode() {
    const { filters } = { ...this.state };
    await DashboardService.getProjectCodeData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          filters.projectCode = resp.responseObject.value;
          this.setState({ filters });
        }
      });
  }

  async retrieveDivisions() {
    const { filters } = { ...this.state };
    await DashboardService.getDivisionFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          filters.division = resp.responseObject.value;
          this.setState({ filters });
        }
      });
  }

  async retrieveCommitties() {
    const { filters } = { ...this.state };
    await DashboardService.getCommittiesFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          filters.committie = resp.responseObject.value;
          this.setState({ filters });
        }
      });
  }

  async retrieveStatus() {
    const { filters } = { ...this.state };
    const objectCode = statusPickListCodes.statusProjects;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          filters.status = resp.responseObject.value;
          this.setState({ filters });
        }
      });
  }

  async retrieveSemaphores() {
    const { filters } = { ...this.state };
    await DashboardService.getSemaphoreFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          filters.semaphores = resp.responseObject.value;
          this.setState({ filters });
        } else {
        }
      })
      .catch((error) => {});
  }

  onFiltersChanged = (obj) => {
    switch (obj.filter) {
      case 'teamLider':
        appliedFilters.teamLider = obj.value;
        break;
      case 'sponsor':
        appliedFilters.sponsor = obj.value;
        break;
      case 'status':
        appliedFilters.status = obj.value;
        break;
      case 'division':
        appliedFilters.division = obj.value;
        break;
      case 'committie':
        appliedFilters.committie = obj.value;
        break;
      case 'projectCode':
        appliedFilters.projectCode = obj.value;
        break;
      case 'selectNotRelatedProjects':
        appliedFilters.selectNotRelatedProjects = obj.value;
        break;
      default:
        break;
    }
  };

  onFilterApplied = (filtersLabel) => {
    this.setState({ selectedFiltersLabel: filtersLabel });
    this.handelModalFiltersClose();
    this.getProjectSintesiDataList();
  };

  onFilterReset = () => {
    this.handelModalFiltersClose();
    appliedFilters.teamLider = null;
    appliedFilters.sponsor = null;
    appliedFilters.status = null;
    appliedFilters.division = null;
    appliedFilters.committie = null;
    appliedFilters.selectNotRelatedProjects = false;
    this.setState({ selectedFiltersLabel: [] });
    appliedFilters.projectCode = null;
    this.getProjectSintesiDataList();
  };

  handelModalFiltersClose = () => {
    this.setState({ showFiltersModal: false });
    this.setState({ loadingButton: false });
  };

  toggleFiltersModal = () => {
    this.setState({ showFiltersModal: true });

    if (!this.state.filtersLoaded) {
      this.retrieveTeamLeaders();
      this.retrieveSponsor();
      this.retrieveStatus();
      this.retrieveDivisions();
      this.retrieveSemaphores();
      this.retrieveCommitties();
      this.retrieveProjectCode();

      this.setState({ filtersLoaded: true });
    }
  };

  clearSelectedFilterOnLabelList = (filterName) => {
    let { selectedFiltersLabel } = this.state;
    if (filterName || filterName.length > 0) {
      let clearedFilteredList = selectedFiltersLabel.filter(function (el) {
        return el.value !== filterName;
      });
      this.setState({ selectedFiltersLabel: clearedFilteredList });
      this.filtersComp.current.handelFilterLabelsClear(clearedFilteredList, filterName);
    }
  };

  removeSelectedFilter = (filter) => {
    switch (filter) {
      case 'teamLeaderFilter':
        appliedFilters.teamLider = null;
        break;
      case 'sponsorFilter':
        appliedFilters.sponsor = null;
        break;
      case 'statusFilter':
        appliedFilters.status = null;
        break;
      case 'divisionFilter':
        appliedFilters.division = null;
        break;
      case 'committieFilter':
        appliedFilters.committie = null;
        break;
      case 'projectCodeFilter':
        appliedFilters.projectCode = null;
        break;
      case 'selectNotRelatedProjects':
        appliedFilters.selectNotRelatedProjects = false;
        break;
      default:
        break;
    }

    this.getProjectSintesiDataList();
    this.clearSelectedFilterOnLabelList(filter);
  };

  generateTableColumns = (projectSintesiDataList, t, companyData) => {
    let columns = [];

    if (projectSintesiDataList && projectSintesiDataList.length > 0) {
      let mergedProjectList = projectSintesiDataList;
      columns = [
        {
          title: `${t('proggetiPage.codice')}`,
          dataIndex: 'projectCode',
          fixed: 'left',
          className: 'sticky',
          width: '150px',
          sorter: (a, b) => {
            a = a.projectCode || '';
            b = b.projectCode || '';
            return a.localeCompare(b);
          },
          showSorterTooltip: [true],
          defaultSortOrder: 'ascend',
          render: (text, record) => <Link to={{ pathname: `/progetti/id/${record.projectID}` }}>{text}</Link>,
        },
        {
          title: `${t('general.nome')}`,
          dataIndex: 'name',
          width: '500px',
          fixed: 'left',
          sorter: (a, b) => {
            return a.name.localeCompare(b.name);
          },
          showSorterTooltip: [false],
        },
        {
          title: `${t('kpiDashboard.semaphore')}`,
          dataIndex: 'semaforo',
          key: 'semaforo',
          className: 'sticky',
          width: '100px',
          fixed: 'left',
          ellipsis: {
            showTitle: false,
          },
          render: (title, record) => (
            <Flex gap={4}>
              <Tooltip
                placement="top"
                title={
                  <TimeTooltip
                    data={record}
                    t={t}
                  />
                }>
                <div
                  className={'gutter-row lower-section header-border b-l ' + checkSemaphoreColor(record.timeSemaphore)}>
                  <ProjectTimeSemaphore data={record} />
                </div>
              </Tooltip>

              <Tooltip
                placement="top"
                title={
                  <BudgetTooltip
                    data={record}
                    t={t}
                    companyData={companyData}
                  />
                }>
                <div
                  className={'gutter-row lower-section header-border ' + checkSemaphoreColor(record.budgetSemaphore)}>
                  <ProjectBudgetSemaphore data={record} />
                </div>
              </Tooltip>

              <Tooltip
                placement="top"
                title={
                  <QualityTooltip
                    data={record}
                    t={t}
                  />
                }>
                <div className={'gutter-row lower-section b-r ' + checkSemaphoreColor(record.qualitySemaphore)}>
                  <ProjectQualitySemaphore data={record} />
                </div>
              </Tooltip>
            </Flex>
          ),
        },
        // {
        //   title: `${t('general.stato')}`,
        //   dataIndex: 'statusDescription',
        // },
        // {
        //   title: `${t('general.teamLeader')}`,
        //   dataIndex: 'teamLeaderFullName',
        // },
      ];

      let monthList = mergedProjectList.find(
        (element) =>
          Object.keys(element.projectMonthList).length !== null && Object.keys(element.projectMonthList).length !== '',
      );
      let montListToJson = JSON.parse(monthList.projectMonthList);

      let years = [];
      montListToJson.map((item) => {
        let year = moment(item.Month).format('YYYY');
        let checkIfYearsExist = years.includes(year);
        if (!checkIfYearsExist) {
          years.push(year);
        }
      });

      years.map((item) => {
        let yearCol = { title: '', children: [] };
        let yearMonths = montListToJson.filter((x) => moment(x.Month).format('YYYY') === item);

        yearCol.title = item;

        yearMonths.map((col, i) => {
          let currentMonth = moment().format('MMM YYYY');
          let colMonth = moment(col.Month).format('MMM');
          let colMonthTooltipTitle = moment(col.Month).format('MMM YYYY');

          yearCol.children.push({
            dataIndex: col.Month,
            className: colMonthTooltipTitle === currentMonth ? 'currentMonthLineIndicatorSintesi' : '',
            align: 'center',
            title: capitalizeFirstLetter(colMonth),
            width: '100px',
            render(text, record) {
              let projectMonthList = JSON.parse(record.projectMonthList);
              let activeMonth = projectMonthList.find((x) => x.Month === col.Month);
              return {
                props: {
                  className: '!tw-p-0 tw-text-transparent tw-select-none',
                },
                children: (
                  <div
                    className={`
                      ${activeMonth && activeMonth.Value ? 'block w-[50px] tw-bg-primary tw-h-[30px]' : ''}
                    `}>
                    -
                  </div>
                ),
              };
            },
          });
        });

        columns.push(yearCol);
      });

      return columns;
    } else {
      return columns;
    }
  };

  onRow = (key) => this.state.expandedKeys.includes(key.projectID) && { className: 'expande-rows-parent' };

  onExpand = (expanded, key) => {
    const keys = this.state.expandedKeys;
    if (key.children) {
      const expandedKeys = expanded ? keys.concat(key.children.map((a) => a.projectID)) : keys.filter((k) => k !== key);
      this.setState({ expandedKeys });
    }
  };

  getProjectsBySelectedXmatrix = (optionId) => {
    this.setState({ activeXmatrixInfo: optionId });
    this.getProjectSintesiDataList(optionId);
  };

  async xmatrixPicklist() {
    await MatrixService.getXmatrixPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ xmatrixPicklist: resp.responseObject.value }, () => {
            this.setState({ loadingPicklist: false });
            this.getDefaultXmatrix(resp.responseObject.value);
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  getDefaultXmatrix = (xmatrixPicklist) => {
    let defaultXmatrix = xmatrixPicklist
      ? xmatrixPicklist.find((x) => x.status === 1 && x.parentXMatrixID === null).xMatrixID
      : null;

    this.setState({ activeXmatrixInfo: defaultXmatrix });
  };

  render() {
    const {
      projectSintesiDataList,
      filters,
      sortField,
      sortType,
      showFiltersModal,
      loadingData,
      tableColums,
      activeXmatrixInfo,
      xmatrixPicklist,
      loadingPicklist,
    } = this.state;

    let t = this.props.t;
    let { selectedFiltersLabel } = this.state;

    if (this.state.loading && this.state.projectSintesiDataList) {
      return (
        <div className="tw-flex tw-size-full tw-justify-center tw-items-center">
          <Spin />
        </div>
      );
    }

    return (
      <div className="tw-overflow-hidden tw-flex tw-flex-col tw-h-full dashboard projectSintesi">
        {/* Dialog */}
        <SintesiFilters
          showFiltersModal={showFiltersModal}
          handelModalFiltersClose={this.handelModalFiltersClose}
          t={t}
          filters={filters}
          filtersChanged={this.onFiltersChanged}
          applyFilters={this.onFilterApplied}
          resetFilters={this.onFilterReset}
          setFilters={(values) => this.setState({ filters: values })}
          sortField={sortField}
          sortType={sortType}
          setSortField={this.setSortField}
          setSortType={this.setSortType}
          ref={this.filtersComp}
        />

        <div className="tw-flex tw-flex-row tw-items-center">
          <XmatrixFilter
            loadingPicklist={loadingPicklist}
            xmatrixPicklist={xmatrixPicklist}
            selectedXmatrixValue={activeXmatrixInfo}
            onXmatrixChange={this.getProjectsBySelectedXmatrix}
          />

          <Button
            type="text"
            className="filter-button tw-shrink-0"
            onClick={() => this.toggleFiltersModal()}>
            {t('dashboardProgettiPage.dashboardFilter')} <FilterOutlined />
          </Button>

          <div className="tw-flex tw-flex-row tw-items-center tw-overflow-x-auto tw-px-2">
            {selectedFiltersLabel.map((item, index) => (
              <Button
                key={index}
                type="text"
                className="fixed-grey-button">
                {item.label} <CloseOutlined onClick={() => this.removeSelectedFilter(item.value)} />
              </Button>
            ))}
          </div>

          <span className="tw-ml-auto tw-shrink-0">
            <CalendarOutlined style={{ color: 'black' }} />{' '}
            <span style={{ color: 'black' }}>
              {t('dashboardProgettiPage.settimanaCorrente')}: {moment(moment(), 'MMDDYYYY').isoWeek()}{' '}
            </span>
          </span>
        </div>

        <div className="tw-max-h-full tw-overflow-auto tw-grow">
          <Table
            sticky
            id={'xx'}
            loading={loadingData}
            columns={tableColums}
            dataSource={projectSintesiDataList}
            rowKey={(obj) => obj.projectID}
            pagination={false}
            size="small"
            onRow={this.onRow}
            onExpand={this.onExpand}
            rowClassName={(record, index) => ((record.children && record.children.length) > 0 ? 'parentRow' : '')}
            scroll={{ x: 'max-content' }}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default withTranslation()(connect(mapStateToProps)(ProjectsTimeline));

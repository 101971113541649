import { Button, Card, Divider, Modal, Space, Typography } from 'antd';
import moment from 'moment';
import React from 'react';
import '../../deliverables/deliverable.scss';

import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommonService from '../../../services/commonService';
import ObiettiviAnnoService from '../../../services/pages/obiettiviAnnoService';
import { bowlingChartRowName, kpiCalculationType, userRolesCodes } from '../../shared/utils/constants';
import AnualKpiValoreFattoNonFatto from './anualKpiValoreFattoNonFatto';
import AnnualKpiValoreMassimo from './anualKpiValoreMassimo';
import AnualKpiValoreMinimo from './anualKpiValoreMinimo';
import AnualKpiValorePercentuale from './anualKpiValorePercentuale';

const { Text, Title } = Typography;

const ModalHeader = (props) => {
  return (
    <Space direction="vertical">
      <Title
        style={{ marginBottom: 1 }}
        level={5}>
        {props.t('general.kpi')}: <span style={{ color: '#1463b5' }}>{props.data.name}</span>
      </Title>
      <span>
        <Text style={{ fontSize: 14 }}>
          {' '}
          <span className="header-text"> {props.t('kpiPage.cumulatoPuntuale')}: </span>{' '}
          <u>{props.data.parentTypeDescription}</u>
        </Text>
        <Divider
          type="vertical"
          style={{ borderColor: '#067af3' }}
        />
        <Text style={{ fontSize: 14 }}>
          {' '}
          <span className="header-text"> {props.t('kpiPage.tipoCalcolo')}: </span>
          <u>{props.data.calculationTypeDescription}</u>
        </Text>

        {props.data.responsibleID && (
          <>
            <Divider
              type="vertical"
              style={{ borderColor: '#067af3' }}
            />
            <Text style={{ fontSize: 14 }}>
              {' '}
              <span className="header-text"> {props.t('general.responsabile')}: </span>
              <u>{props.data.responsibleName}</u>
            </Text>
          </>
        )}
      </span>
    </Space>
  );
};

class EditKpiObAnnoModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      kpiYear: '2024',
      responsabileUsersList: null,
    };
  }

  componentDidMount() {
    this.checkAnnualGoalYear();
    this.retrieveResponsibleUsersList();
  }

  retrieveResponsibleUsersList = async () => {
    await CommonService.getUsersPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          let usersNoTechAdminRole = respData.filter((item) => item.roleCode !== userRolesCodes.techAdmin);

          usersNoTechAdminRole.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          this.setState({ responsabileUsersList: usr });
        } else {
        }
      })
      .catch((error) => {});
  };

  async checkAnnualGoalYear() {
    const { kpiRowData } = this.props;
    await ObiettiviAnnoService.checkAnnualGoalYear(kpiRowData.annualGoalKPIRelationShipID, 'ANNUAL_GOAL')
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let year = resp.responseObject.value.year.toString();
          this.setState({ kpiYear: year }, () => {
            // this.getKpiBowlingChart();
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  onDataSave = (
    bowlingChartData,
    jumpOffPointValue,
    annualGoalKPIRelationShipID,
    calculationType,
    finalTarget,
    showInA3,
    definedTarget,
    responsibleID,
  ) => {
    let payload = {};
    let tableData = [];

    let targetRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.target;
    })[0];
    let actualRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.actual;
    })[0];
    let actualYtdRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.actualYtd;
    })[0];
    let targetYtdRow = bowlingChartData.filter(function (item) {
      return item.Name === bowlingChartRowName.targetYtd;
    })[0];

    let isTargetInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var el in targetRow) {
        if (targetRow.hasOwnProperty(el) && (targetRow[el] > 100 || targetRow[el] < 0)) {
          isTargetInvalid = true;
        }
      }
    }
    // else{
    //     for (var prop in targetRow) {
    //         if (targetRow.hasOwnProperty(prop) && targetRow[prop] < 0) {
    //             isTargetInvalid = true
    //         }
    //     }
    // }

    let isActualInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var act in actualRow) {
        if (actualRow.hasOwnProperty(act) && (actualRow[act] > 100 || actualRow[act] < 0)) {
          isActualInvalid = true;
        }
      }
    }
    // else {
    //     for (var prop in actualRow) {
    //         if (actualRow.hasOwnProperty(prop) && actualRow[prop] < 0) {
    //             isActualInvalid = true
    //         }
    //     }
    // }

    let targetYtdInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var targetYtd in targetYtdRow) {
        if (targetYtdRow.hasOwnProperty(targetYtd) && (targetYtdRow[targetYtd] > 100 || targetYtdRow[targetYtd] < 0)) {
          targetYtdInvalid = true;
        }
      }
    }
    // else {
    //     for (var prop in targetYtdRow) {
    //         if (targetYtdRow.hasOwnProperty(prop) && targetYtdRow[prop] < 0) {
    //             targetYtdInvalid = true
    //         }
    //     }
    // }

    let actualYtdInvalid = false;
    if (calculationType === kpiCalculationType.valorePercentuale) {
      for (var actualYtd in actualYtdRow) {
        if (actualYtdRow.hasOwnProperty(actualYtd) && (actualYtdRow[actualYtd] > 100 || actualYtdRow[actualYtd] < 0)) {
          actualYtdInvalid = true;
        }
      }
    }
    // else {
    //     for (var prop in actualYtdRow) {
    //         if (actualYtdRow.hasOwnProperty(prop) && actualYtdRow[prop] < 0) {
    //             actualYtdInvalid = true
    //         }
    //     }
    // }

    if (targetRow || actualRow) {
      if (!isActualInvalid && !isTargetInvalid && !targetYtdInvalid && !actualYtdInvalid) {
        for (let key in targetRow) {
          if (key !== 'Name') {
            let formatedDate = moment(key).format('YYYY-MM-DD');
            let item = `${formatedDate},${actualRow[key]},${targetRow[key]}`;
            tableData.push(item);
          }
        }
        payload['jumpOffPoint'] = jumpOffPointValue;
        payload['showInA3'] = showInA3;
        payload['finalTarget'] = finalTarget;
        payload['annualGoalKPIRelationShipID'] = annualGoalKPIRelationShipID;
        payload['changedList'] = tableData;
        payload['freezeTargetValues'] = definedTarget;
        payload['responsibleID'] = responsibleID;

        this.props.updateObAnualKpi(payload);
      }
    }
  };

  render() {
    const {
      showEditKpiModal,
      handelEditKpiModalClose,
      annualGoalID,
      kpiRowData,
      loadingButton,
      t,
      companyData,
      userData,
      annualGoalData,
    } = this.props;
    const { kpiYear, responsabileUsersList } = this.state;

    return (
      <Modal
        className="edit-kpi-modal"
        width={'90%'}
        title={
          <ModalHeader
            data={kpiRowData}
            t={t}
          />
        }
        open={showEditKpiModal}
        destroyOnClose={true}
        onCancel={handelEditKpiModalClose}
        footer={[
          <div key="close_buttons">
            <Button onClick={handelEditKpiModalClose}>{t('buttons.chiudi')}</Button>
          </div>,
        ]}>
        <Card className="overflow">
          {/* {(kpiYear && kpiRowData && kpiRowData.calculationType === kpiCalculationType.valoreMassimo) && */}
          {kpiYear && kpiRowData && kpiRowData.calculationType === kpiCalculationType.valoreMassimo && (
            <AnnualKpiValoreMassimo
              annualGoalData={annualGoalData}
              responsabileUsersList={responsabileUsersList}
              userData={userData}
              companyData={companyData}
              t={t}
              kpiYear={kpiYear}
              kpiRowData={kpiRowData}
              annualGoalID={annualGoalID}
              onSave={this.onDataSave}
              loadingButton={loadingButton}
              calculationType={kpiRowData.calculationType}
              isGlobalKpiBowlingChart={false}
            />
          )}

          {kpiYear && kpiRowData && kpiRowData.calculationType === kpiCalculationType.valoreMinimo && (
            <AnualKpiValoreMinimo
              annualGoalData={annualGoalData}
              responsabileUsersList={responsabileUsersList}
              userData={userData}
              companyData={companyData}
              t={t}
              kpiYear={kpiYear}
              kpiRowData={kpiRowData}
              annualGoalID={annualGoalID}
              onSave={this.onDataSave}
              loadingButton={loadingButton}
              calculationType={kpiRowData.calculationType}
            />
          )}

          {kpiYear && kpiRowData && kpiRowData.calculationType === kpiCalculationType.valorePercentuale && (
            <AnualKpiValorePercentuale
              annualGoalData={annualGoalData}
              responsabileUsersList={responsabileUsersList}
              userData={userData}
              companyData={companyData}
              t={t}
              kpiYear={kpiYear}
              kpiRowData={kpiRowData}
              annualGoalID={annualGoalID}
              onSave={this.onDataSave}
              loadingButton={loadingButton}
              calculationType={kpiRowData.calculationType}
            />
          )}

          {kpiYear && kpiRowData && kpiRowData.calculationType === kpiCalculationType.valoreFatoNonFato && (
            <AnualKpiValoreFattoNonFatto
              annualGoalData={annualGoalData}
              responsabileUsersList={responsabileUsersList}
              userData={userData}
              companyData={companyData}
              t={t}
              kpiYear={kpiYear}
              kpiRowData={kpiRowData}
              annualGoalID={annualGoalID}
              onSave={this.onDataSave}
              loadingButton={loadingButton}
              calculationType={kpiRowData.calculationType}
            />
          )}
        </Card>
      </Modal>
    );
  }
}

// export default EditKpiObAnnoModal

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default withTranslation()(connect(mapStateToProps)(EditKpiObAnnoModal));

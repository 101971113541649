import {
  CheckOutlined,
  CloseOutlined,
  DeleteFilled,
  DownloadOutlined,
  EditOutlined,
  SearchOutlined,
  UploadOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Button, Input, message, Popconfirm, Space, Table } from 'antd';
import * as FileSaver from 'file-saver';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import XlsxPopulate from 'xlsx-populate';
import { history } from '../../App';
import UsersService from '../../services/pages/usersService';
import TableLayout from '../shared/tableLayout';
import {
  checkUserIsLenovysOrCompanyAdmin,
  checkUserIsLenovysOrTechAdmin,
  checkUserIsLenovysTechAdmin,
} from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { notifyMessages } from '../shared/utils/notifyMessages';
import UploadUsers from './uploadUsers';
import './userPage.scss';

const fileExtension = '.xlsx';

class UserListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadUsersModal: false,
      loadingData: true,
      loadingDownloadUsers: false,
      searchText: '',
      searchedColumn: '',
      usersList: null,
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  onNew = () => {
    history.push('/user/new');
  };

  componentDidMount() {
    this.retrieveUserListData();
  }

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  //Retrieve users data
  async retrieveUserListData() {
    this?.setState({ loadingData: true });
    await UsersService.getUsersListData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ usersList: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this?.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this?.setState({ loadingData: false });
      });
  }

  getSheetData(data, header) {
    var fields = Object.keys(data[0]);
    var sheetData = data.map(function (row) {
      return fields.map(function (fieldName) {
        return row[fieldName] ? row[fieldName] : '';
      });
    });
    sheetData.unshift(header);

    return sheetData;
  }

  exportToCSV2 = () => {
    this.setState({ loadingDownloadUsers: true });

    const { usersList } = this.state;
    const { userData } = this.props;

    const newUserList = usersList.map(
      ({
        availability,
        dailyRate,
        divisionID,
        department,
        divisionName,
        profilePhoto,
        roleCode,
        userID,
        userInitials,
        type,

        ...rest
      }) => {
        return rest;
      },
    );

    const fileName = `Users-${userData.lastLoggedInCompanyName}`;

    let header = ['Type', 'Name', 'Job Title', 'Email', 'Company', 'Role', 'Is Disabled'];

    XlsxPopulate.fromBlankAsync().then(async (workbook) => {
      const sheet1 = workbook.sheet(0);
      const sheetData = this.getSheetData(newUserList, header);
      const totalColumns = sheetData[0].length;

      sheet1.cell('A1').value(sheetData);
      const range = sheet1.usedRange();
      const endColumn = String.fromCharCode(64 + totalColumns);
      sheet1.row(1).style('bold', true);
      sheet1.range('A1:' + endColumn + '1').style('fill', 'BFBFBF');

      sheet1.column('A').width(15);
      sheet1.column('B').width(30);
      sheet1.column('C').width(30);
      sheet1.column('D').width(35);
      sheet1.column('E').width(30);
      sheet1.column('F').width(25);
      sheet1.column('G').width(15);

      return workbook.outputAsync().then((res) => {
        FileSaver.saveAs(res, fileName + fileExtension);
      });
    });

    this.setState({ loadingDownloadUsers: false });
  };

  onRemoveUser = (userId) => {
    UsersService.removeUser(userId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.retrieveUserListData(true);
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  render() {
    const { usersList, loadingData, loadingDownloadUsers } = this.state;
    const { userData } = this.props;

    let t = this.props.t;

    let columns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'fullName',
        ellipsis: {
          showTitle: true,
        },
        sorter: (a, b) => {
          a = a.fullName || '';
          b = b.fullName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('fullName'),
      },
      {
        title: `${t('utentiPage.titoloLavoro')}`,
        dataIndex: 'jobTitle',
        sorter: (a, b) => {
          a = a.jobTitle || '';
          b = b.jobTitle || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('jobTitle'),
      },
      {
        title: `${t('general.email')}`,
        dataIndex: 'emailAddress',
        sorter: (a, b) => {
          a = a.emailAddress || '';
          b = b.emailAddress || '';
          return a.localeCompare(b);
        },
        ellipsis: {
          showTitle: true,
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('emailAddress'),
      },
      {
        title: `${t('general.ruolo')}`,
        dataIndex: 'role',
        showSorterTooltip: [false],
        sorter: (a, b) => {
          a = a.role || '';
          b = b.role || '';
          return a.localeCompare(b);
        },
        ...this.getColumnSearchProps('role'),
      },
      {
        title: `${t('utentiPage.statoAccesso')}`,
        dataIndex: 'isDisabled',
        filters: [
          { text: `${t('general.si')}`, value: false },
          { text: `${t('general.no')}`, value: true },
        ],
        sorter: (a, b) => {
          return a.isDisabled.toString().localeCompare(b.isDisabled.toString());
        },
        onFilter: (value, record) => record.isDisabled === value,
        showSorterTooltip: [false],
        render: (text, record, index) => {
          if (!record.isDisabled) {
            return <CheckOutlined style={{ color: '#16a34a' }} />;
          } else {
            return <CloseOutlined style={{ color: '#ec1a24' }} />;
          }
        },
      },
      {
        key: 'action',
        width: '50px',
        render: (text, record) => {
          if (checkUserIsLenovysOrCompanyAdmin(userData)) {
            return (
              <Button
                icon={<EditOutlined />}
                href={`/profile/${record.userID}`}></Button>
            );
          }

          // return (
          //   userData.roleCode === userRolesCodes.lenovysAdmin ?
          //     <Space >
          //       <Link
          //         to={{
          //           pathname: `/profile/${record.userID}`
          //         }}>
          //         <EditOutlined  />
          //       </Link>
          //     </Space>
          //     :
          //     ""
          // )
        },
      },
      {
        key: 'action2',
        width: '50px',
        render: (_, record) => {
          if (!checkUserIsLenovysOrTechAdmin(userData)) {
            return (
              <Popconfirm
                key="remove"
                title={t('utentiPage.removeUser')}
                icon={<WarningOutlined />}
                okText={t('general.si')}
                cancelText={t('general.no')}
                onConfirm={() => this.onRemoveUser(record.userID)}>
                <Button icon={<DeleteFilled style={{ color: '#878787' }} />}></Button>
              </Popconfirm>
            );
          }
        },
      },
    ];

    let isRoleTechOrLenovysAdmin =
      userData && Object.keys(userData).length > 0 ? checkUserIsLenovysTechAdmin(userData) : false;

    return (
      <TableLayout title={t('utentiPage.utenti')}>
        <TableLayout.Actions>
          {isRoleTechOrLenovysAdmin && (
            <>
              <UploadUsers
                onCancel={() => this.setState({ uploadUsersModal: false })}
                onOk={this.retrieveUserListData}
                open={this.state.uploadUsersModal}
              />
              <Button
                icon={<UploadOutlined />}
                onClick={() => this.setState({ uploadUsersModal: true })}>
                {t('buttons.carica')}
              </Button>
              <Button
                loading={loadingDownloadUsers}
                icon={<DownloadOutlined />}
                onClick={() => this.exportToCSV2()}>
                {t('buttons.scaricaUtenti')}
              </Button>
            </>
          )}
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            columns={columns}
            size="small"
            dataSource={usersList}
            rowKey={(obj) => obj.userID}
            loading={loadingData}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(UserListPage));

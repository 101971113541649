import {
  CheckSquareOutlined,
  EditOutlined,
  ExportOutlined,
  MoreOutlined,
  PlusCircleOutlined,
  PrinterOutlined,
} from '@ant-design/icons';
import { Button, Drawer, Menu, message } from 'antd';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import MatrixService from '../../services/matrix/matrixService';
import { checkUserIsLenovysAdmin, userIsAdminOrSteering } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { notifyMessages } from '../shared/utils/notifyMessages';
import EditXmatrixModal from './editXmatrixModal';
import AddNewXmatrix from './insertXmatrix';
import AddXmatrixNextYear from './newNextYearXmatrix';

let createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const fileExtension = '.xlsx';
const fileName = 'X-Matrix';

class XmatrixOptions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      xmatrixPicklist: null,
      selectedXmatrixValue: null,
      loadingPicklist: true,
      showModal: false,
      showModalXmatrix: false,
      xMatrixInfo: {},
      lastXmatrixYear: null,
      optionPanelVisibility: false,
      xMatrixYearsList: [],
      showEditModalXmatrix: false,
      loadingExport: false,
    };
  }

  componentDidMount() {
    this.xmatrixPicklist();
  }

  toggleModal = () => {
    this.setState({ showModal: !this.state.showModal });
  };

  toggleModalXmatrix = () => {
    this.setState({ showModalXmatrix: !this.state.showModalXmatrix });
  };

  toggleEditModalXmatrix = () => {
    this.setState({ showEditModalXmatrix: !this.state.showEditModalXmatrix });
  };

  handleModalClose = () => {
    this.setState({ showModal: false });
    this.setState({ loadingButton: false });
  };

  handleModalXmatrixClose = () => {
    this.setState({ showModalXmatrix: false });
    this.setState({ loadingButton: false });
  };

  handleEditModalXmatrixClose = () => {
    this.setState({ showEditModalXmatrix: false });
    this.setState({ loadingButton: false });
  };

  addXmatrixNextYearData = (values) => {
    this.setState({ loadingButton: true });
    MatrixService.addNewXmatrixNextYear(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.handleModalClose();
          this.hidePanel();
          this.setState({ loadingPicklist: true });
          localStorage.setItem('xmFilters', JSON.stringify({}));
          history.push(`/xmatrix/${resp.responseObject.value.xMatrixID}`);
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  addXmatrixData = (values) => {
    this.setState({ loadingButton: true });
    MatrixService.addNewXmatrix(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.handleModalXmatrixClose();
          this.hidePanel();
          this.setState({ loadingPicklist: true });
          localStorage.setItem('xmFilters', JSON.stringify({}));
          history.push(`/xmatrix`);
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  async xmatrixPicklist() {
    await MatrixService.getXmatrixPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ xmatrixPicklist: resp.responseObject.value }, () => {
            this.setState({ loadingPicklist: false });
          });
          this.setState({ lastXmatrixYear: resp.responseObject.value[0].lastInsertedYear });

          if (resp.responseObject.value.length > 0) {
            let xmatrixYearArray = [];
            let parentXmatrixList = resp.responseObject.value.filter(function (item) {
              return item.parentXMatrixID == null;
            });
            parentXmatrixList.map((item) => xmatrixYearArray.push(item.referencePeriod));
            this.setState({ xMatrixYearsList: xmatrixYearArray });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  saveEditXmatrixData = (values) => {
    this.setState({ loadingButton: true });
    MatrixService.updateXmatrixData(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.handleEditModalXmatrixClose();
          this.hidePanel();
          this.setState({ loadingPicklist: true });
          this.props.reloadMatrixOnEdit();
          this.props.reloadXmInfo();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  changeXmatrixStatus = async (xMatrixID) => {
    this.setState({ loadingButton: true });
    MatrixService.updateXmatrixStatus(xMatrixID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
          this.props.reloadMatrix();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  deleteXmatrix = async (xMatrixID) => {
    this.setState({ loadingButton: true });
    MatrixService.deleteXmatrixDraft(xMatrixID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.setState({ loadingButton: false });
          localStorage.setItem('xmFilters', JSON.stringify({}));
          history.push(`/xmatrix`);
        } else {
          message.error(notifyMessages.deleteFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  showPanel = () => {
    this.setState({ optionPanelVisibility: true });
  };

  hidePanel = () => {
    this.setState({ optionPanelVisibility: false });
  };

  onPrint = () => {
    this.setState({ optionPanelVisibility: false }, () => {
      setTimeout(function () {
        window.print();
      }, 700);
    });
  };

  itemRelationsName = (relation) => {
    const { t } = this.props;
    if (relation === 1) {
      return t('xMatrixPage.primaria');
    } else if (relation === 2) {
      return t('xMatrixPage.secondaria');
    } else {
      return t('xMatrixPage.nessuna');
    }
  };

  exportXmatrixData = () => {
    const { xmatrixData, t, selectedXmatrixInfo } = this.props;
    const wb = XLSX.utils.book_new();

    this.setState({ loadingExport: true });
    if (xmatrixData) {
      //generate long terms data sheet
      let longTermsDataList = [];
      let longTermsHeaders = [
        ['X-Matrix', 'Numero', 'Nome', 'Stato', 'Categoria', 'Tipo', 'Sponsor', 'Coerenza Envisioning'],
      ];
      xmatrixData.longTermGoals.map((item) => {
        longTermsDataList.push({
          xmatrix: selectedXmatrixInfo.hkCode,
          orderingValue: item.orderingValue,
          title: item.title,
          statusDescription: item.statusDescription,
          category: item.category,
          type: item.longTermGoalType,
          sponsor: item.sponsor,
          coerenza: item.isRelatedVMV ? 'True' : 'False',
        });
      });
      const longTermSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(longTermSheet, longTermsDataList, { origin: 'A2', skipHeader: true });
      XLSX.utils.sheet_add_aoa(longTermSheet, longTermsHeaders);
      XLSX.utils.book_append_sheet(wb, longTermSheet, 'Strategia Lungo Periodo');

      //generate long terms - annual goal relation data sheet
      let annulLongRelationData = [];
      let annulLongrrelationsOnly = xmatrixData.longTermAnnualGoalRelationships.filter(
        (obj) => obj.correlationCoefficient !== null,
      );
      let annulLongRelationHeaders = [['X-Matrix', 'Numero SLP', 'Nome SLP', 'Numero OA', 'Nome OA', 'Relazione']];
      annulLongrrelationsOnly.map((item) => {
        if (
          xmatrixData.annualGoals.some(function (el) {
            return el.annualGoalID === item.highestIDValue;
          }) &&
          xmatrixData.longTermGoals.some(function (el) {
            return el.longTermGoalID === item.lowestIDValue;
          })
        ) {
          annulLongRelationData.push({
            xmatrix: selectedXmatrixInfo.hkCode,
            numeroSLP: item.lowestNumberValue,
            nomeSLP: item.lowestNameValue,
            numeroOA: item.highestNumberValue,
            nomeOA: item.highestNameValue,
            correlationCoefficient: this.itemRelationsName(item.correlationCoefficient),
          });
        }
      });
      const annulLongRelationSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(annulLongRelationSheet, annulLongRelationData, { origin: 'A2', skipHeader: true });
      XLSX.utils.sheet_add_aoa(annulLongRelationSheet, annulLongRelationHeaders);
      XLSX.utils.book_append_sheet(wb, annulLongRelationSheet, 'REL_SLP_OA');

      //generate annual goal data sheet
      let annualGoalDataList = [];
      let annualGoalHeaders = [['X-Matrix', 'Numero', 'Nome', 'Stato', 'Responsabile']];
      xmatrixData.annualGoals.map((item) => {
        annualGoalDataList.push({
          xmatrix: selectedXmatrixInfo.hkCode,
          orderingValue: item.orderingValue,
          title: item.title,
          statusDescription: item.statusDescription,
          responsible: item.responsabile,
        });
      });
      const annualGoalSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(annualGoalSheet, annualGoalDataList, { origin: 'A2', skipHeader: true });
      XLSX.utils.sheet_add_aoa(annualGoalSheet, annualGoalHeaders);
      XLSX.utils.book_append_sheet(wb, annualGoalSheet, 'Obiettivi anno');

      //generate projects data sheet
      let projectsDataList = [];
      let projectsHeaders = [
        [
          'X-Matrix',
          'Codice',
          'Nome',
          'Comitato',
          'Divisione',
          'Stato',
          'Data Inizio',
          'Data Fine',
          'Team Leader',
          'Sponsor',
          'Progetto Principale',
          'Commenti',
        ],
      ];
      xmatrixData.projects.map((item) => {
        projectsDataList.push({
          xmatrix: selectedXmatrixInfo.hkCode,
          projectCode: item.projectCode,
          title: item.title,
          committeeName: item.committeeName,
          divisionName: item.divisionName,
          statusDescription: item.statusDescription,
          startDate: item.projectStartDate ? moment(item.projectStartDate).format('DD/MM/YYYY') : '',
          endDate: item.projectEndDate ? moment(item.projectEndDate).format('DD/MM/YYYY') : '',
          teamLeaderFullName: item.teamLeaderFullName,
          sponsorFullName: item.sponsorFullName,
          parentProject: item.parentProject,
          notes: item.note,
        });
      });
      const projectsSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(projectsSheet, projectsDataList, { origin: 'A2', skipHeader: true });
      XLSX.utils.sheet_add_aoa(projectsSheet, projectsHeaders);
      XLSX.utils.book_append_sheet(wb, projectsSheet, 'Progetti');

      //generate annual goal - projects relation data sheet
      let annulProjectRelationData = [];
      let annulProjectrelationsOnly = xmatrixData.goalProjectRelationship.filter(
        (obj) => obj.correlationCoefficient !== null,
      );
      let annulProjectHeaders = [['X-Matrix', 'Numero OA', 'Nome OA', 'Codice Progetto', 'Nome Progetto', 'Relazione']];
      annulProjectrelationsOnly.map((item) => {
        if (
          xmatrixData.annualGoals.some(function (el) {
            return el.annualGoalID === item.lowestIDValue;
          }) &&
          xmatrixData.projects.some(function (el) {
            return el.projectID === item.highestIDValue;
          })
        ) {
          annulProjectRelationData.push({
            xmatrix: selectedXmatrixInfo.hkCode,
            numeroSLP: item.lowestNumberValue,
            nomeSLP: item.lowestNameValue,
            projectCode: item.highestObjectCode,
            projectName: item.highestNameValue,
            correlationCoefficient: this.itemRelationsName(item.correlationCoefficient),
          });
        }
      });
      const annulProjectsRelationSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(annulProjectsRelationSheet, annulProjectRelationData, {
        origin: 'A2',
        skipHeader: true,
      });
      XLSX.utils.sheet_add_aoa(annulProjectsRelationSheet, annulProjectHeaders);
      XLSX.utils.book_append_sheet(wb, annulProjectsRelationSheet, 'REL_OA_PRJ');

      let wsColsWidthFormatter = [];
      for (let i = 0; i < 30; i++) {
        wsColsWidthFormatter.push({ wpx: 150 });
      }
      longTermSheet['!cols'] = wsColsWidthFormatter;
      annualGoalSheet['!cols'] = wsColsWidthFormatter;
      annulLongRelationSheet['!cols'] = wsColsWidthFormatter;
      projectsSheet['!cols'] = wsColsWidthFormatter;
      annulProjectsRelationSheet['!cols'] = wsColsWidthFormatter;

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(data, fileName + fileExtension);
      this.setState({ loadingExport: false });
    } else {
      this.setState({ loadingExport: false });
      message.warn(t('general.noDataFound'));
    }
  };

  exportSecondLevelXmatrixData = () => {
    const { xmatrixData, t, selectedXmatrixInfo } = this.props;
    const wb = XLSX.utils.book_new();

    this.setState({ loadingExport: true });
    if (xmatrixData) {
      //generate long terms data sheet
      let longTermsDataList = [];
      let longTermsHeaders = [
        ['X-Matrix', 'Numero', 'Nome', 'Stato', 'Categoria', 'Tipo', 'Sponsor', 'Coerenza Envisioning'],
      ];
      xmatrixData.longTermGoals.map((item) => {
        longTermsDataList.push({
          xmatrix: selectedXmatrixInfo.hkCode,
          orderingValue: item.orderingValue,
          title: item.title,
          statusDescription: item.statusDescription,
          category: item.category,
          type: item.longTermGoalType,
          sponsor: item.sponsor,
          coerenza: item.isRelatedVMV ? 'True' : 'False',
        });
      });
      const longTermSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(longTermSheet, longTermsDataList, { origin: 'A2', skipHeader: true });
      XLSX.utils.sheet_add_aoa(longTermSheet, longTermsHeaders);
      XLSX.utils.book_append_sheet(wb, longTermSheet, 'Strategia Lungo Periodo');

      //generate annual goal data sheet
      let annualGoalDataList = [];
      let annualGoalHeaders = [['X-Matrix', 'Numero', 'Nome', 'Stato', 'Responsabile']];
      xmatrixData.annualGoals.map((item) => {
        annualGoalDataList.push({
          xmatrix: selectedXmatrixInfo.hkCode,
          orderingValue: item.orderingValue,
          title: item.title,
          statusDescription: item.statusDescription,
          responsible: item.responsabile,
        });
      });
      const annualGoalSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(annualGoalSheet, annualGoalDataList, { origin: 'A2', skipHeader: true });
      XLSX.utils.sheet_add_aoa(annualGoalSheet, annualGoalHeaders);
      XLSX.utils.book_append_sheet(wb, annualGoalSheet, 'Obiettivi anno');

      //generate annual goal - project relation data sheet
      let annualProjectRelationData = [];
      let annualProjectRelationsOnly = xmatrixData.goalProjectRelationship.filter(
        (obj) => obj.correlationCoefficient !== null,
      );
      let annualProjectRelationHeaders = [
        ['X-Matrix', 'Numero OA', 'Nome OA', 'Codice Progetto', 'Nome Progetto', 'Relazione'],
      ];
      annualProjectRelationsOnly.map((item) => {
        if (
          xmatrixData.annualGoals.some(function (el) {
            return el.annualGoalID === item.lowestIDValue;
          }) &&
          xmatrixData.projects.some(function (el) {
            return el.projectID === item.highestIDValue;
          })
        ) {
          annualProjectRelationData.push({
            xmatrix: selectedXmatrixInfo.hkCode,
            numeroOA: item.lowestNumberValue,
            nomeOA: item.lowestNameValue,
            projectCode: item.highestObjectCode,
            nomeProject: item.highestNameValue,
            correlationCoefficient: this.itemRelationsName(item.correlationCoefficient),
          });
        }
      });
      const annualProjectRelationSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(annualProjectRelationSheet, annualProjectRelationData, {
        origin: 'A2',
        skipHeader: true,
      });
      XLSX.utils.sheet_add_aoa(annualProjectRelationSheet, annualProjectRelationHeaders);
      XLSX.utils.book_append_sheet(wb, annualProjectRelationSheet, 'REL_OA_PRJ');

      //generate projects data sheet
      let projectsDataList = [];
      let projectsHeaders = [
        [
          'X-Matrix',
          'Codice',
          'Nome',
          'Comitato',
          'Divisione',
          'Stato',
          'Data Inizio',
          'Data Fine',
          'Team Leader',
          'Sponsor',
          'Progetto Principale',
          'Commenti',
        ],
      ];
      xmatrixData.projects.map((item) => {
        projectsDataList.push({
          xmatrix: selectedXmatrixInfo.hkCode,
          projectCode: item.projectCode,
          title: item.title,
          committeeName: item.committeeName,
          divisionName: item.divisionName,
          statusDescription: item.statusDescription,
          startDate: item.projectStartDate ? moment(item.projectStartDate).format('DD/MM/YYYY') : '',
          endDate: item.projectEndDate ? moment(item.projectEndDate).format('DD/MM/YYYY') : '',
          teamLeaderFullName: item.teamLeaderFullName,
          sponsorFullName: item.sponsorFullName,
          parentProject: item.parentProject,
          notes: item.note,
        });
      });
      const projectsSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(projectsSheet, projectsDataList, { origin: 'A2', skipHeader: true });
      XLSX.utils.sheet_add_aoa(projectsSheet, projectsHeaders);
      XLSX.utils.book_append_sheet(wb, projectsSheet, 'Iniziative');

      //generate CHILD PROJECTS data sheet
      let childProjectsDataList = [];
      let childProjectsHeaders = [
        [
          'X-Matrix',
          'Codice',
          'Nome',
          'Comitato',
          'Divisione',
          'Stato',
          'Data Inizio',
          'Data Fine',
          'Team Leader',
          'Sponsor',
          'Progetto Principale',
          'Commenti',
        ],
      ];
      xmatrixData.projectChild.map((item) => {
        childProjectsDataList.push({
          xmatrix: selectedXmatrixInfo.hkCode,
          projectCode: item.projectCode,
          title: item.title,
          committeeName: item.committeeName,
          division: item.divisionName,
          statusDescription: item.statusDescription,
          startDate: item.subProjectStartDate ? moment(item.subProjectStartDate).format('DD/MM/YYYY') : '',
          endDate: item.subProjectEndDate ? moment(item.subProjectEndDate).format('DD/MM/YYYY') : '',
          teamLeaderFullName: item.teamLeaderFullName,
          sponsor: item.sponsorFullName,
          parentProject: item.subProjectParentProject,
          notes: item.note,
        });
      });
      const childProjectsSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(childProjectsSheet, childProjectsDataList, { origin: 'A2', skipHeader: true });
      XLSX.utils.sheet_add_aoa(childProjectsSheet, childProjectsHeaders);
      XLSX.utils.book_append_sheet(wb, childProjectsSheet, 'Progetti Strategici');

      //generate project - child projects relation data sheet
      let projectChildProjectsRelationData = [];
      let projectChildProjectsRelationsOnly = xmatrixData.projectChildProjectRelationship.filter(
        (obj) => obj.correlationCoefficient !== null,
      );
      let projectChildProjectsHeaders = [
        [
          'X-Matrix',
          'Codice Iniziative',
          'Nome Iniziative',
          'Codice Progetti Strategici',
          'Nome Progetti Strategici',
          'Relazione',
        ],
      ];
      projectChildProjectsRelationsOnly.map((item) => {
        if (
          xmatrixData.projectChild.some(function (el) {
            return el.projectID === item.highestIDValue;
          }) &&
          xmatrixData.projects.some(function (el) {
            return el.projectID === item.lowestIDValue;
          })
        ) {
          projectChildProjectsRelationData.push({
            xmatrix: selectedXmatrixInfo.hkCode,
            numberIniziative: item.lowestObjectCode,
            nameIniziative: item.lowestNameValue,
            numberProgettiStrategici: item.highestObjectCode,
            nameProgettiStrategici: item.highestNameValue,
            correlationCoefficient: this.itemRelationsName(item.correlationCoefficient),
          });
        }
      });
      const projectChildProjectsRelationSheet = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(projectChildProjectsRelationSheet, projectChildProjectsRelationData, {
        origin: 'A2',
        skipHeader: true,
      });
      XLSX.utils.sheet_add_aoa(projectChildProjectsRelationSheet, projectChildProjectsHeaders);
      XLSX.utils.book_append_sheet(wb, projectChildProjectsRelationSheet, 'REL_PRJ_PRJ_STRATEGICI');

      let wsColsWidthFormatter = [];
      for (let i = 0; i < 30; i++) {
        wsColsWidthFormatter.push({ wpx: 150 });
      }
      longTermSheet['!cols'] = wsColsWidthFormatter;
      annualGoalSheet['!cols'] = wsColsWidthFormatter;
      annualProjectRelationSheet['!cols'] = wsColsWidthFormatter;
      projectsSheet['!cols'] = wsColsWidthFormatter;
      projectChildProjectsRelationSheet['!cols'] = wsColsWidthFormatter;
      childProjectsSheet['!cols'] = wsColsWidthFormatter;

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: fileType });

      FileSaver.saveAs(data, fileName + fileExtension);
      this.setState({ loadingExport: false });
    } else {
      this.setState({ loadingExport: false });
      message.warn(t('general.noDataFound'));
    }
  };

  onDataExport = (secondLevelXmatrix) => {
    if (!secondLevelXmatrix) {
      this.exportXmatrixData();
    } else {
      this.exportSecondLevelXmatrixData();
    }
  };

  render() {
    const {
      showModal,
      showModalXmatrix,
      lastXmatrixYear,
      loadingButton,
      optionPanelVisibility,
      xMatrixYearsList,
      showEditModalXmatrix,
    } = this.state;
    const { userData, selectedXmatrixInfo, t, isSecondLevelXmatrix } = this.props;

    return (
      <>
        {showModal && (
          <AddXmatrixNextYear
            loadingButton={loadingButton}
            showModal={this.state.showModal}
            handleModalClose={this.handleModalClose}
            handleModalSave={this.addXmatrixNextYearData}
            selectedXmatrixValue={selectedXmatrixInfo.xMatrixID}
            lastXmatrixYear={lastXmatrixYear}
            t={t}
            xMatrixYearsList={xMatrixYearsList}
          />
        )}

        {showModalXmatrix && (
          <AddNewXmatrix
            loadingButton={loadingButton}
            showModalXmatrix={this.state.showModalXmatrix}
            handleModalXmatrixClose={this.handleModalXmatrixClose}
            handleModalXmatrixSave={this.addXmatrixData}
            selectedXmatrixValue={selectedXmatrixInfo.xMatrixID}
            lastXmatrixYear={lastXmatrixYear}
            t={t}
            xMatrixYearsList={xMatrixYearsList}
          />
        )}

        {showEditModalXmatrix && (
          <EditXmatrixModal
            loadingButton={loadingButton}
            showEditModalXmatrix={this.state.showEditModalXmatrix}
            handleEditModalXmatrixClose={this.handleEditModalXmatrixClose}
            handleEditModalXmatrixSave={this.saveEditXmatrixData}
            selectedXmatrixValue={selectedXmatrixInfo.xMatrixID}
            lastXmatrixYear={lastXmatrixYear}
            t={t}
            xMatrixYearsList={xMatrixYearsList}
          />
        )}

        {userIsAdminOrSteering(userData) && (
          <>
            <Button
              type="text"
              onClick={this.showPanel}
              className="leftPanelButton"
              data-testid="openOptionsBtn">
              <MoreOutlined style={{ fontSize: 20, verticalAlign: 'top' }} />
            </Button>

            <Drawer
              title={t('general.opzioni')}
              placement="right"
              onClose={this.hidePanel}
              open={optionPanelVisibility}
              className="menu-drawer">
              <Menu>
                <Menu.Item
                  icon={<PlusCircleOutlined />}
                  onClick={this.toggleModal}>
                  {t('xMatrixPage.nuovaAnnoSuccesivoXm')}
                </Menu.Item>

                <Menu.Item
                  onClick={this.toggleModalXmatrix}
                  icon={<CheckSquareOutlined />}>
                  {t('xMatrixPage.nuovoCicloXm')}
                </Menu.Item>

                <Menu.Item
                  onClick={this.toggleEditModalXmatrix}
                  icon={<EditOutlined />}>
                  {t('xMatrixPage.modificaXmatrix')}
                </Menu.Item>

                <Menu.Item
                  onClick={() => this.onPrint()}
                  icon={<PrinterOutlined />}>
                  {t('xMatrixPage.stampaXmatrix')}
                </Menu.Item>

                {checkUserIsLenovysAdmin(userData) && (
                  <Menu.Item
                    onClick={() => this.onDataExport(isSecondLevelXmatrix)}
                    icon={<ExportOutlined />}>
                    {t('xMatrixPage.exportData')}
                  </Menu.Item>
                )}
              </Menu>
            </Drawer>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  selectedXmatrix: state.selectedXmatrix.selectedXmatrix,
  userData: state.userData.userData,
});

export default connect(mapStateToProps)(XmatrixOptions);

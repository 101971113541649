import { Button } from 'antd';
import BraftEditor from 'braft-editor';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { richTectEditoControlls } from '../../../shared/utils/constants';
import 'braft-editor/dist/index.css';

class TextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: BraftEditor.createEditorState(props.data.fieldValue),
    };
  }

  updateField = () => {
    const { editorState } = this.state;
    const { handelEditorSave } = this.props;

    if (editorState.isEmpty()) {
      return;
    }

    handelEditorSave(editorState.toHTML());
  };

  handleEditorChange = (editorState) => {
    this.setState({ editorState });
  };

  render() {
    const { loadingEditorButton, t } = this.props;
    const { editorState } = this.state;

    return (
      <div className="tw-flex tw-flex-col tw-gap-2">
        <BraftEditor
          style={{ height: '400px', overflowY: 'hidden', overflowX: 'hidden' }}
          contentFormat="html"
          language="en"
          id="editor-text"
          controls={richTectEditoControlls}
          value={editorState}
          onChange={this.handleEditorChange}
        />

        <div className="tw-flex tw-justify-end">
          <Button
            type="primary"
            onClick={this.updateField}
            loading={loadingEditorButton}>
            {t('buttons.salva')}
          </Button>
        </div>
      </div>
    );
  }
}

export default withTranslation()(TextEditor);

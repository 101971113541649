import { Form } from 'antd';
import { useEffect, useState } from 'react';

import { Card, Col, DatePicker, Input, Modal, Row, Select } from 'antd';
import moment from 'moment';
import { useTranslation, withTranslation } from 'react-i18next';
import CommonService from '../../../../services/commonService';
import MatrixService from '../../../../services/matrix/matrixService';
import CommitieService from '../../../../services/pages/commitieServices';
import { ModalFooterButtons } from '../../../shared/components/modal/modalFooterButtons';
import UserSelectDropDown from '../../../shared/components/userSelect/userSelect';
import { activityStatus, formItemLayout, formatOfDate, statusPickListCodes } from '../../../shared/utils/constants';
import { areDatesValid, showErrorNotification } from '../../../shared/utils/functions';
import { requiredFields } from '../../../shared/utils/notifyMessages';
const { Option } = Select;
const { TextArea } = Input;

const NewComitieActivity = ({
  showModal,
  committeeID,
  handelModalClose,
  saveActivity,
  loadingSave,
  comitieDetails,
  ...props
}) => {
  const { t } = useTranslation();
  let currentDate = moment();

  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);

  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [activityStatusList, setActivityStatusList] = useState(null);
  const [comitieProjectsAndSubs, setComitieProjectsAndSubs] = useState(null);

  useEffect(() => {
    getComitieProjects();
  }, [activeXmatrixInfo]);

  useEffect(() => {
    getActiveXmatrix();
    retrieveStatus();
  }, []);

  const getActiveXmatrix = async () => {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setActiveXmatrixInfo(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.activityStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          setActivityStatusList(respData);
        } else {
        }
      })
      .catch((error) => {});
  };

  const getComitieProjects = async () => {
    if (activeXmatrixInfo) {
      let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

      await CommitieService.getComitieProjectsAndSubProjetcst(committeeID, '', xmatrix)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            let respData = resp.responseObject.value;
            setComitieProjectsAndSubs(respData);
          } else {
          }
        })
        .catch((error) => {});
    } else {
    }
  };

  const checkValidityBeforeSave = (values) => {
    if (areDatesValid(values.startDate, values.endDate)) {
      saveActivity(values);
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  return (
    <Modal
      className="add-kpi-modal"
      width={900}
      title={t('leMieAttivitaPage.aggiungiNuovaAttivita')}
      open={showModal}
      destroyOnClose={true}
      maskClosable={false}
      onCancel={handelModalClose}
      footer={[
        <ModalFooterButtons
          disabled={!fieldsChanged}
          loadingButton={loadingSave}
          modalClose={handelModalClose}
          formId={'newComitieActivity'}
        />,
      ]}>
      <Card>
        <Form
          id="newComitieActivity"
          layout="vertical"
          {...formItemLayout}
          onFinish={(values) => checkValidityBeforeSave(values)}
          onValuesChange={() => {
            setFieldsChanged(true);
          }}>
          <Row
            gutter={{ lg: 24 }}
            className="row-margin">
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Form.Item
                label={t('leMieAttivitaPage.soggetto')}
                name="subject"
                rules={[{ required: true, message: requiredFields.required }]}>
                <TextArea
                  rows={4}
                  autoSize={true}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <UserSelectDropDown
                disabled={false}
                allowClear={false}
                fieldLabel={t('general.responsabile')}
                t={t}
                formName={'ownerID'}
                required={true}
                initValue={null}
              />
              {/* <Form.Item
                                labelAlign="left"
                                label={t('general.responsabile')}
                                name="ownerID"
                                rules={[{ required: true, message: requiredFields.required }]}
                            >
                                <Select
                                    showSearch
                                    placeholder={t('general.seleziona')}
                                    optionFilterProp="children"
                                    filterOption={(input, option) =>
                                        option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }
                                    style={{ width: "100%" }}
                                >
                                    {responsabileUserList && responsabileUserList.map(item =>
                                        <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                    )}
                                </Select>
                            </Form.Item> */}
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <Form.Item
                label={t('general.stato')}
                name="status"
                hasFeedback
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={activityStatus.active}>
                <Select
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}>
                  {activityStatusList &&
                    activityStatusList.map((item) => (
                      <Option
                        value={item.statusID}
                        key={item.statusID}>
                        {item.statusDescription}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <Form.Item
                label={t('general.start')}
                name="startDate"
                rules={[{ required: true, message: requiredFields.required }]}
                initialValue={currentDate}>
                <DatePicker
                  style={{ width: '100%' }}
                  format={formatOfDate}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 12 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <Form.Item
                className="main-container"
                label={t('general.end')}
                name="endDate"
                rules={[{ required: true, message: requiredFields.required }]}>
                <DatePicker
                  style={{ width: '100%' }}
                  format={formatOfDate}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <Form.Item
                label={t('general.progetto')}
                name="projectID"
                hasFeedback>
                <Select
                  style={{ width: '100%' }}
                  placeholder={t('general.seleziona')}
                  showSearch
                  optionFilterProp="children"
                  filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                  {comitieProjectsAndSubs &&
                    comitieProjectsAndSubs.map((item) => (
                      <Option
                        value={item.projectID}
                        key={item.projectID}>
                        {item.projectName}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <Form.Item label={t('general.comitato')}>
                <Input
                  disabled={true}
                  value={comitieDetails.name}
                />
              </Form.Item>
            </Col>

            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <Form.Item
                className="main-container"
                label={t('general.nota')}
                name="description"
                initialValue={comitieDetails.name + '  ' + currentDate.format(formatOfDate)}>
                <TextArea
                  rows={4}
                  autoSize={true}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default withTranslation()(NewComitieActivity);

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import MatrixService from '../../../services/matrix/matrixService';
import DashboardService from '../../../services/pages/dashboardService';
import Loader2 from '../../shared/components/loader2/loader2';
import { perStatusProgettiChartOptions } from '../chartsConfig';

let appliedFilters = {
  teamLider: null,
  sponsor: null,
  status: null,
  division: null,
  committie: null,
  timeSemaphore: null,
  budgetSemaphore: null,
  qualitySemaphore: null,
  comboSemaphore: null,
  parentProject: null,
  filterCriteria: null,
  projectCode: null,
  showFiltersModal: false,
};

const PerStatusProgettiChart = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [perStatusChart, setPerStatusChart] = useState(perStatusProgettiChartOptions);
  const [activeXmatrix, setActiveXmatrix] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    retrieveDefaultXmatrix();
  }, []);

  // Effect that triggers when activeXmatrix is set
  useEffect(() => {
    if (activeXmatrix) {
      getPerStatusProgettiChartData();
    }
  }, [activeXmatrix]);

  const retrieveDefaultXmatrix = async () => {
    try {
      const response = await MatrixService.getXmatrixPicklist();
      const data = response.data;
      if (data.success) {
        const defaultXmatrix = data.responseObject.value?.find((x) => x.status === 1 && x.parentXMatrixID === null);
        if (defaultXmatrix) {
          setActiveXmatrix(defaultXmatrix); // set the active X matrix
        }
      }
    } catch (error) {
      console.error('Error retrieving Xmatrix', error);
    }
  };

  const getPerStatusProgettiChartData = async () => {
    if (!activeXmatrix) {
      return;
    }

    setLoadingData(true);
    let selectNotRelatedProjects = true;
    try {
      const response = await DashboardService.getDashboardData(
        appliedFilters,
        activeXmatrix.xMatrixID,
        selectNotRelatedProjects,
      );
      const resp = response.data;
      if (resp.success) {
        let data = resp.responseObject.value;

        let seriesObj = {};
        let dataObj = [];
        seriesObj['name'] = `${t('bachechaPersonalePage.progetti')}`;

        let result = Object.values(
          data.reduce((c, { status, statusDescription }) => {
            c[status] = c[status] || { name: statusDescription, value: 0 };
            c[status].value++;
            return c;
          }, {}),
        );

        result.map((item) => dataObj.push({ name: item.name, y: item.value }));

        seriesObj['data'] = dataObj;

        setPerStatusChart((prevChart) => ({ ...prevChart, series: seriesObj }));
      }
    } catch (error) {
      console.error('Error retrieving project status data', error);
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <>
      {loadingData && <Loader2 />}
      {perStatusChart && (
        <HighchartsReact
          highcharts={Highcharts}
          options={perStatusChart}
        />
      )}
      {!perStatusChart && <p>No data</p>}
    </>
  );
};

export default PerStatusProgettiChart;

import { Modal } from 'antd';
import React from 'react';
import '../mainA3.scss';
import './modalBox.scss';

export const ModalBox = ({ visible, closeHandler, children }) => {
  return (
    <Modal
      centered
      open={visible}
      onOk={closeHandler}
      onCancel={closeHandler}
      width={'95%'}
      className="a3ModalBox">
      {children}
    </Modal>
  );
};

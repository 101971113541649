import { Form } from 'antd';
import React from 'react';
import './companies.scss';

import { Col, DatePicker, Modal, Row, Select } from 'antd';
import moment from 'moment';
import { withTranslation } from 'react-i18next';
import Loader from '../../shared/components/loader/loader';
import { ModalFooterButtons } from '../../shared/components/modal/modalFooterButtons';
import { companyLiceseTypes } from '../../shared/utils/constants';
import { requiredFields } from '../../shared/utils/notifyMessages';

let createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class EditCompanies extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
    };
  }

  componentDidMount() {}

  backFunction = () => {
    history.goBack();
  };

  updateCompany = (values, companyId, fromForm) => {
    this.props.updateCompany(values, companyId, fromForm);
  };

  render() {
    const { showEditCompanyModal, handelEditCompanyClose, companyData, loadingButton } = this.props;
    const liceseTypeList = companyLiceseTypes;
    let t = this.props.t;

    return (
      <Modal
        className="add-critic-modal"
        width={800}
        title={`${t('societaPage.modificaAzienda')}: ${companyData.companyName}`}
        open={showEditCompanyModal}
        destroyOnClose={true}
        onCancel={handelEditCompanyClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handelEditCompanyClose}
            formId={'editCompanyLicenseForm'}
          />,
        ]}>
        <Loader />
        {companyData && (
          <Form
            id="editCompanyLicenseForm"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.updateCompany(values, companyData.companyID, 0)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Row
                  gutter={{ lg: 24 }}
                  className="mt10">
                  <Col
                    className="gutter-row-values"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}>
                    <Form.Item
                      className="main-container"
                      label={t('general.start')}
                      name="startDate"
                      rules={[{ required: true, message: requiredFields.required }]}
                      initialValue={companyData.startDate ? moment(companyData.startDate) : companyData.startDate}>
                      <DatePicker
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    className="gutter-row-values"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}>
                    <Form.Item
                      className="main-container"
                      label={t('general.end')}
                      name="endDate"
                      //rules={[{ required: true, message: requiredFields.required }]}
                      initialValue={companyData.endDate ? moment(companyData.endDate) : companyData.endDate}>
                      <DatePicker
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  gutter={{ lg: 24 }}
                  className="mt10">
                  <Col
                    className="gutter-row-values"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}>
                    <Form.Item
                      className="main-container"
                      label={t('societaPage.dataAttivazione')}
                      name="activationDate"
                      rules={[{ required: true, message: requiredFields.required }]}
                      initialValue={
                        companyData.activationDate ? moment(companyData.activationDate) : companyData.activationDate
                      }>
                      <DatePicker
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </Col>

                  <Col
                    className="gutter-row-values"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}>
                    <Form.Item
                      className="main-container"
                      label={t('societaPage.dataDisattivazione')}
                      name="deactivationDate"
                      //rules={[{ required: true, message: requiredFields.required }]}
                      initialValue={
                        companyData.deactivationDate
                          ? moment(companyData.deactivationDate)
                          : companyData.deactivationDate
                      }>
                      <DatePicker
                        style={{ width: '100%' }}
                        format="DD/MM/YYYY"
                      />
                    </Form.Item>
                  </Col>
                </Row>

                <Row
                  gutter={{ lg: 24 }}
                  className="mt10">
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 12 }}
                    lg={{ span: 12 }}>
                    <Form.Item
                      className="main-container"
                      style={{ marginBottom: 5 }}
                      label={t('societaPage.tipoLicenza')}
                      name="licenseType"
                      hasFeedback
                      rules={[{ required: true, message: requiredFields.required }]}
                      initialValue={companyData.licenseType}>
                      <Select
                        style={{ width: '100%' }}
                        placeholder="Seleziona">
                        {liceseTypeList &&
                          liceseTypeList.map((item) => (
                            <Option
                              value={item.key}
                              key={item.key}>
                              {item.name}
                            </Option>
                          ))}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        )}
      </Modal>
    );
  }
}

export default withTranslation()(EditCompanies);

import moment from 'moment';
import { PrsActive, PrsClosed, PrsDraft, PrsPause } from '../../../../../assets/icons';

// Flattens a nested array structure, optionally adding the parent deliverable name to each item
export function flatArray(arr, parentDeliverableName = null) {
  return arr.reduce((acc, item) => {
    const { children, name, ...rest } = item;
    const currentItem = { ...rest, name, parentDeliverableName };

    acc.push(currentItem);

    if (children) {
      // Recursively flatten children
      acc.push(...flatArray(children, name));
    }

    return acc;
  }, []);
}

// Determines the type of an item (task, deliverable, or phase) based on its attributes
const getItemType = (item) => {
  if (item?.activityType === 'activity') return 'task';
  if (item?.parentDeliverableID) return 'deliverable';
  return 'phase';
};

// Converts an array of items into KanBan-compatible format
export const toKanBanItems = (items) =>
  items.map((item) => ({
    ...item,
    title: item?.name ?? item?.subject,
    itemType: getItemType(item),
    ownerId: item.owner,
    ownerFullName: item.ownerFullName ?? item.fullName,
    ownerAvatar: `theUrl/${item.id}`,
  }));

// Filters items by the specified item type (e.g., task, deliverable, phase)
export const filterByItemType = (items, itemType) => {
  return items?.filter((item) => item.itemType === itemType);
};

// Calculates the months between two dates, inclusive, formatted as "MMM YYYY"
export function getMonthsBetween(minDate, maxDate) {
  if (!minDate.isValid() || !maxDate.isValid()) {
    throw new Error('Invalid dates provided');
  }

  let start = moment(minDate);
  let end = moment(maxDate);
  let months = [];

  // Loop through months until the maxDate is reached
  while (start.isSameOrBefore(end, 'month')) {
    months.push(start.format('MMM YYYY')); // Format each month as "Month Year"
    start.add(1, 'month'); // Move to the next month
  }

  return months;
}

// Returns an icon component based on the provided status
export const getIconFromStatus = (status) => {
  switch (status) {
    case 1:
      return <PrsDraft />;
    case 2:
      return <PrsActive />;
    case 3:
      return <PrsPause />;
    case 4:
      return <PrsClosed />;
    default:
      return null;
  }
};

export const matchItemsWithCompanyUsers = (items, users) => {
  if (!users?.length || !items?.length) return;

  const userLookup = Object.fromEntries(users.map((user) => [user.userID, user.profilePhoto]));

  return items.map((item) => ({
    ...item,
    ownerAvatar: userLookup[item.owner] || null,
  }));
};

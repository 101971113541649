import { Form } from 'antd';
import React, { Component } from 'react';

import { DatePicker, Modal } from 'antd';
import moment from 'moment';
import MatrixService from '../../services/matrix/matrixService';
import { ModalFooterButtons } from '../shared/components/modal/modalFooterButtons';
import { requiredFields } from '../shared/utils/notifyMessages';
import './matrix.scss';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddNewXmatrix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      loadingButton: false,
      longTermGoalList: [],
      loadingLongTermGoalList: false,
      longTermGoalTargetKeys: [],
    };
  }

  async retrieveLongTermGoalsPicklist() {
    this.setState({ loadingLongTermGoalList: true });
    await MatrixService.getLongTermGoals()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          const mockData = [];
          resp.responseObject.value.map((item, index) => {
            const dataOjb = {
              key: item.longTermGoalID,
              description: item.title,
            };
            mockData.push(dataOjb);
          });

          this.setState({ longTermGoalList: mockData });
          this.setState({ loadingLongTermGoalList: false });
        } else {
          this.setState({ loadingLongTermGoalList: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingLongTermGoalList: false });
      });
  }

  handleChangeLongTermGoal = (targetKeys) => {
    this.setState({ longTermGoalTargetKeys: targetKeys });
  };

  disablePreviousYears = (current, lastXmatrixYear) => {
    const { xMatrixYearsList } = this.props;
    let curr = moment(current).year().toString();
    return xMatrixYearsList.find((dt) => dt === curr);
  };

  addData = (values) => {
    const { longTermGoalTargetKeys } = this.state;
    values.year = moment(values.year).startOf('year').format('YYYY-MM-DD');
    values['longtermGoalID'] = longTermGoalTargetKeys;
    this.props.handleModalXmatrixSave(values);
  };

  render() {
    const { showModalXmatrix, handleModalXmatrixClose, loadingButton, lastXmatrixYear, t } = this.props;

    return (
      <Modal
        className="mew_xmatrix_modal"
        width={800}
        title={t('xMatrixPage.confermaChiusuraXmCrezioneNuova')}
        open={showModalXmatrix}
        destroyOnClose={true}
        onCancel={handleModalXmatrixClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleModalXmatrixClose}
            formId={'newXmatrix'}
          />,
        ]}>
        <Form
          id="newXmatrix"
          name="add_xmatrix"
          layout="vertical"
          {...formItemLayout}
          onFinish={this.addData}
          onValuesChange={() => {
            this.setState({ fieldsChanged: true });
          }}>
          <Form.Item
            label={t('general.anno')}
            name="year"
            hasFeedback
            rules={[{ required: true, message: requiredFields.required }]}>
            <DatePicker
              placeholder={t('general.seleziona')}
              picker="year"
              style={{ width: '100%' }}
              format="YYYY"
              disabledDate={(current) => this.disablePreviousYears(current, lastXmatrixYear)}
            />
          </Form.Item>
        </Form>
      </Modal>
    );
  }
}

export default AddNewXmatrix;

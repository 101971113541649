import { SaveOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Result, Row } from 'antd';
import React, { Component } from 'react';

class EndDateConfirmationModal extends Component {
  render() {
    const { showModal, t, handelConfirmResponse, handelDeclineResponse, deliverableFormValues } = this.props;

    return (
      <Modal
        width={800}
        title={''}
        closable={false}
        maskClosable={false}
        open={showModal}
        destroyOnClose={true}
        footer={[]}>
        <Row
          className="mb-row"
          gutter={{ lg: 24 }}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}>
            <Result
              status="warning"
              title={t('deliverablePage.endDateGreaterThanWarning')}
              extra={
                <>
                  <Button
                    icon={<SaveOutlined />}
                    onClick={() => handelConfirmResponse(deliverableFormValues, true)}>
                    {t('deliverablePage.extendDate')}
                  </Button>

                  <Button
                    type="primary"
                    onClick={() => handelDeclineResponse(deliverableFormValues, false)}>
                    {t('general.no')}
                  </Button>
                </>
              }
            />
          </Col>
        </Row>
      </Modal>
    );
  }
}

export default EndDateConfirmationModal;

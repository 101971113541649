import { Col, Divider, message, Row, Spin, Typography } from 'antd';
import classNames from 'classnames';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import CommonService from '../../services/commonService';
import MatrixService from '../../services/matrix/matrixService';
import RelationsService from '../../services/matrix/relationsService';
import DashboardService from '../../services/pages/dashboardService';
import { addQueryParam, addQueryParams, getQueryParam, removeQueryParam } from '../../utils/url-utils';
import { statusPickListCodes, xmatrixStatus } from '../shared/utils/constants';
import { notifyMessages } from '../shared/utils/notifyMessages';
import Center from './data_tables/center/center';
import Kpi from './data_tables/kpi/kpi';
import BigObjectives from './data_tables/longObjectives/longObjectives';
import Projects from './data_tables/projects/projects';
import UsersXmatrix from './data_tables/users/users';
import YearObjectives from './data_tables/yearObjective/yearObjectives';
import FiltersMatrixLocal from './filtersMatrixLocal';
import './matrix.scss';
import ProjectUserRelation from './relation_tables/projects_user';
import RelationTable from './relation_tables/relation-table';

class Matrix extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton1: false,
      loadingButton2: false,
      loading: true,
      loadingMatrix: false,
      commitiePickList: null,
      projetStatusList: null,
      goalStatusList: null,
      divisionsList: null,
      matrixData: {},
      usersList: null,

      activeXmatrixInfo: null,
      selectedXmatrixInfo: null,

      selectedObRow: null,

      xmatrixRootList: null,
      loadingRootList: false,
      isSecondLevelXmatrix: false,

      userSectionVisibility: false,
      kpiSectionVisibility: false,

      xmFilters: {},
    };
    this.filterComp = React.createRef();
  }

  componentDidMount() {
    const localFilters = localStorage.getItem('xmFilters');

    if (localFilters) {
      this.setState({ xmFilters: JSON.parse(localFilters) }, () => {
        this.retrieveComponentData();
      });
    } else {
      this.retrieveComponentData();
    }

    this.retrieveCommities();
    this.retrieveProjectStatuses();
    this.retrieveGoalStatuses();
    this.retrieveDivisions();
  }

  retrieveComponentData = async () => {
    await this.getActiveXmatrix();
    await this.getXmatrixRootList();
    await this.retrievexMatrixData();
    await this.getSelectedXmatrixInfo();

    // Retrive from qp the selected item
    const selection = getQueryParam('selection');
    const selectedId = getQueryParam('selectedId');

    switch (selection) {
      case 'projects':
        this.selectProject(selectedId);
        break;
      case 'users':
        this.selectUser(selectedId);
        break;
      case 'kpis':
        this.selectKpi(selectedId);
        break;
      case 'longTermGoals':
        this.selectLongTermObjective(selectedId);
        break;
      case 'annualObjectives':
        this.selectAnnualObjective(selectedId);
        break;

      default:
        break;
    }

    // Sync kpi & user visibility with qp
    const userSectionVisibility = getQueryParam('userSectionVisibility') === 'true';
    const kpiSectionVisibility = getQueryParam('kpiSectionVisibility') === 'true';
    this.setState({ userSectionVisibility, kpiSectionVisibility });

    this.setState({ loading: false });
  };

  async getSelectedXmatrixInfo() {
    const { activeXmatrixInfo } = this.state;
    const { id } = this.props.match.params;
    if (activeXmatrixInfo) {
      let xmId = id ? id : activeXmatrixInfo?.xMatrixID;

      await MatrixService.getSelectedXmatrixInfo(xmId)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            this.setState({ selectedXmatrixInfo: resp.responseObject.value });
          } else {
          }
        })
        .catch((error) => {});
    }
  }

  async retrieveCommities() {
    await CommonService.getCommitiePicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ commitiePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve list of divisions
  async retrieveDivisions() {
    await DashboardService.getDivisionFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ divisionsList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  //retrieve project status
  async retrieveProjectStatuses() {
    const objectCode = statusPickListCodes.statusProjects;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projetStatusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveGoalStatuses() {
    const objectCode = statusPickListCodes.statusObAnnoLong;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ goalStatusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getUsers() {
    await MatrixService.getUsersXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ usersList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  updateAnnualProjectRelation = (selection, rowId, colId) => {
    const payload = {
      correlationCoefficient: selection.correlationCoefficient,
      xMatrixID: this.state.selectedXmatrixInfo.xMatrixID,
      annualGoalID: colId,
      projectID: rowId,
    };

    // this.setState({ loadingIcon: true });
    RelationsService.updateAnnualProjectRelation(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          // this.setState({ loadingIcon: false });
          this.onReload();
        } else {
          message.error(notifyMessages.updateFailed);
          // this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        // this.setState({ loadingIcon: false });
      });
  };

  updateProjectKpiRelation = (selection, rowId, colId) => {
    const payload = {
      correlationCoefficient: selection.correlationCoefficient,
      xMatrixID: this.state.selectedXmatrixInfo.xMatrixID,
      kpiid: colId,
      projectID: rowId,
    };

    // this.setState({ loadingIcon: true });
    RelationsService.updateProjectKpiRelation(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          // this.setState({ loadingIcon: false });
          this.onReload();
        } else {
          message.error(notifyMessages.updateFailed);
          // this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        // this.setState({ loadingIcon: false });
      });
  };

  updateLongGoalKpiRelation = (selection, rowId, colId) => {
    const payload = {
      correlationCoefficient: selection.correlationCoefficient,
      xMatrixID: this.state.selectedXmatrixInfo.xMatrixID,
      kpiid: colId,
      longTermGoalID: rowId,
    };

    // this.setState({ loadingIcon: true });
    RelationsService.updateLongGoalKpiRelation(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          // this.setState({ loadingIcon: false });
          this.onReload();
        } else {
          message.error(notifyMessages.updateFailed);
          // this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        // this.setState({ loadingIcon: false });
      });
  };

  updateLongAnnualRelation = (selection, rowId, colId) => {
    const payload = {
      correlationCoefficient: selection.correlationCoefficient,
      xMatrixID: this.state.selectedXmatrixInfo.xMatrixID,
      annualGoalID: colId,
      longTermGoalID: rowId,
    };

    // this.setState({ loadingIcon: true });
    RelationsService.updateLongAnnualRelation(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          // this.setState({ loadingIcon: false });
          this.onReload();
        } else {
          message.error(notifyMessages.updateFailed);
          // this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        // this.setState({ loadingIcon: false });
      });
  };

  updateProjectUserRelation = (selection, rowId, colId, text) => {
    const payload = {
      correlationCoefficient: selection.correlationCoefficient,
      xMatrixID: this.state.selectedXmatrixInfo.xMatrixID,
      userID: colId,
      projectID: rowId,
    };

    // this.setState({ loadingIcon: true });
    RelationsService.updateProjectUserRelation(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          // this.setState({ loadingIcon: false });
          this.onReload();
        } else {
          message.error(notifyMessages.updateFailed);
          // this.setState({ loadingIcon: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        // this.setState({ loadingIcon: false });
      });
  };

  onReload = () => {
    this.retrievexMatrixData();
  };

  reloadAfterStatusChange = async () => {
    await this.getActiveXmatrix();
    await this.retrievexMatrixData();
    await this.getSelectedXmatrixInfo();
  };

  async retrievexMatrixData() {
    const { activeXmatrixInfo, xmFilters } = this.state;
    const { id } = this.props.match.params;

    if (id) {
      let xmFilterObj = { filter: 'XMatrixID', value: id };
      this.manageFiltersChanged(xmFilterObj);
    } else {
      let xmFilterObj = { filter: 'XMatrixID', value: activeXmatrixInfo?.xMatrixID };
      this.manageFiltersChanged(xmFilterObj);
    }

    this.setState({ loadingMatrix: true });
    const locakStorageFilters = xmFilters;

    await MatrixService.getMatrixData(locakStorageFilters)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ matrixData: resp.responseObject.value });
          this.setState({ loading: false });
          this.setState({ loadingMatrix: false });
          this.setState({ loadingButton1: false });
          this.setState({ loadingButton2: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingMatrix: false });
          this.setState({ loadingButton1: false });
          this.setState({ loadingButton2: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingMatrix: false });
        this.setState({ loadingButton1: false });
        this.setState({ loadingButton2: false });
      });
  }

  async getActiveXmatrix() {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          this.setState({ activeXmatrixInfo: respData }, () => {});
        } else {
        }
      })
      .catch((error) => {});
  }

  async getXmatrixRootList() {
    this.setState({ loadingRootList: true });
    await MatrixService.getXmatrixDivisionRootList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ loadingRootList: false });
          this.setState({ xmatrixRootList: resp.responseObject.value });
        } else {
          this.setState({ loadingRootList: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingRootList: false });
      });
  }

  manageFilterReset = () => {
    this.setState({ xmFilters: {} }, () => {
      localStorage.setItem('xmFilters', JSON.stringify({}));
      this.retrievexMatrixData();
      this.setState({ loadingButton2: true });
    });
  };

  manageFiltersChanged = (obj) => {
    const { xmFilters } = this.state;
    if (obj.value === '' || obj.value?.length === 0) {
      delete xmFilters[obj.filter];
    } else {
      xmFilters[obj.filter] = obj.value;
    }
    this.setState({ xmFilters });
    localStorage.setItem('xmFilters', JSON.stringify(xmFilters));
  };

  manageFilterApplied = () => {
    this.retrievexMatrixData();
    this.setState({ loadingButton1: true });
  };

  selectedRow = (record, rowIndex) => {
    const { matrixData } = this.state;

    let annualGoals = matrixData.annualGoals;
    let longTermGoals = matrixData.longTermGoals;
    let relationList = matrixData.longTermAnnualGoalRelationships;

    //remove previous selected rows
    let allRows = document.getElementsByClassName('selected-row-bg');
    for (var i = allRows.length - 1; i >= 0; --i) {
      allRows[i].classList.remove('selected-row-bg');
    }

    //selected obiettivo anno row
    let rootRow = document.querySelector(`[data-row-key=${'xz' + record.longTermGoalID}]`);
    rootRow.classList.add('selected-row-bg');

    // selected obiettivi anno / obittivi lungo relations
    let rows = document.querySelectorAll(`[data-row-key=${'x' + record.longTermGoalID}]`);
    for (i = 0; i < rows.length; i++) {
      let cells = rows[i].cells;
      for (i = 0; i < cells.length; i++) {
        let cellClass = cells[i].childNodes[0].firstChild.className;
        if (cellClass === 'relationFull' || cellClass === 'relationPartial') {
          cells[i].classList.add('selected-row-bg');
        }
      }
    }

    let cell = [];

    longTermGoals.forEach((row) => {
      const dataRow = [];

      annualGoals.forEach((col) => {
        if (
          relationList.find(
            (item) =>
              item.highestIDValue === col.annualGoalID &&
              item.lowestIDValue === row.longTermGoalID &&
              item.correlationCoefficient === 1,
          )
        ) {
          dataRow[col.annualGoalID] = 1;
          dataRow['rowID'] = row.longTermGoalID;
        } else if (
          relationList.find(
            (item) =>
              item.highestIDValue === col.annualGoalID &&
              item.lowestIDValue === row.longTermGoalID &&
              item.correlationCoefficient === 2,
          )
        ) {
          dataRow[col.annualGoalID] = 2;
          dataRow['rowID'] = row.longTermGoalID;
        } else {
          dataRow[col.annualGoalID] = 3;
          dataRow['rowID'] = row.longTermGoalID;
        }
      });
      cell.push(dataRow);
    });
  };

  toggleUsersSection = () => {
    const { userSectionVisibility } = this.state;
    this.setState({ userSectionVisibility: !userSectionVisibility });
    addQueryParam('userSectionVisibility', !userSectionVisibility);
  };

  toggleKpiSection = () => {
    const { kpiSectionVisibility } = this.state;
    this.setState({ kpiSectionVisibility: !kpiSectionVisibility });
    addQueryParam('kpiSectionVisibility', !kpiSectionVisibility);
  };

  clearSelection = (selectionId) => {
    if (getQueryParam('selectedId') === selectionId) {
      removeQueryParam('selection');
      removeQueryParam('selectedId');
      this.setState({
        selection: null,
        selectedProjectIds: [],
        selectedKpiIds: [],
        selectedUserIds: [],
        selectedAnnualObjectiveIds: [],
        selectedLongTermObjectiveIds: [],
      });

      return true;
    }

    return false;
  };

  filterIds(filterArray, fullArray) {
    return filterArray.filter((id) => fullArray.includes(id));
  }

  selectAnnualObjectives(selection = { projectIds: [], longTermGoalIds: [] }) {
    const selectedAnnualObjectiveIds = this.filterIds(
      this.state.matrixData.annualGoals.map(({ annualGoalID }) => annualGoalID),
      [
        ...this.state.matrixData.goalProjectRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.projectIds?.includes(ag.highestIDValue))
          .map(({ lowestIDValue }) => lowestIDValue),
        ...this.state.matrixData.longTermAnnualGoalRelationships
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.longTermGoalIds?.includes(ag.lowestIDValue))
          .map(({ highestIDValue }) => highestIDValue),
      ],
    );

    this.setState({ selectedAnnualObjectiveIds });
    return selectedAnnualObjectiveIds;
  }

  selectLongTermObjectives(selection = { annualGoalIds: [], kpiIds: [] }) {
    const selectedLongTermObjectiveIds = this.filterIds(
      this.state.matrixData.longTermGoals.map(({ longTermGoalID }) => longTermGoalID),
      [
        ...this.state.matrixData.longTermAnnualGoalRelationships
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.annualGoalIds?.includes(ag?.highestIDValue))
          .map(({ lowestIDValue }) => lowestIDValue),
        ...this.state.matrixData.goalKPIRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.kpiIds?.includes(ag.highestIDValue))
          .map(({ lowestIDValue }) => lowestIDValue),
      ],
    );

    this.setState({ selectedLongTermObjectiveIds });
    return selectedLongTermObjectiveIds;
  }

  selectProjects(selection = { annualGoalIds: [], kpiIds: [], userIds: [] }) {
    const selectedProjectIds = this.filterIds(
      this.state.matrixData.projects.map(({ projectID }) => projectID),
      [
        ...this.state.matrixData.goalProjectRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.annualGoalIds?.includes(ag?.lowestIDValue))
          .map(({ highestIDValue }) => highestIDValue),
        ...this.state.matrixData.kpiProjectRelationships
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.kpiIds?.includes(ag.highestIDValue))
          .map(({ lowestIDValue }) => lowestIDValue),
        ...this.state.matrixData.userProjectRelationship
          ?.filter((ag) => selection.userIds?.includes(ag.highestIDValue))
          .map(({ lowestIDValue }) => lowestIDValue),
      ],
    );

    this.setState({ selectedProjectIds });
    return selectedProjectIds;
  }

  selectKpis(selection = { projectIds: [], longTermGoalIds: [] }) {
    const selectedKpiIds = this.filterIds(
      this.state.matrixData.kpIs.map(({ kpiid }) => kpiid),
      [
        ...this.state.matrixData.kpiProjectRelationships
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.projectIds?.includes(ag.lowestIDValue))
          .map(({ highestIDValue }) => highestIDValue),
        ...this.state.matrixData.goalKPIRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 3 && selection.longTermGoalIds?.includes(ag.lowestIDValue))
          .map(({ highestIDValue }) => highestIDValue),
      ],
    );

    this.setState({ selectedKpiIds });
    return selectedKpiIds;
  }

  selectUsers(selection = { projectIds: [] }) {
    const selectedUserIds = this.filterIds(
      this.state.matrixData.users.map(({ userID }) => userID),
      [
        ...this.state.matrixData.userProjectRelationship
          ?.filter((ag) => ag.correlationCoefficient !== 4 && selection.projectIds?.includes(ag.lowestIDValue))
          .map(({ highestIDValue }) => highestIDValue),
      ],
    );

    this.setState({ selectedUserIds });
    return selectedUserIds;
  }

  selectKpi = (selectedKpiId) => {
    if (this.clearSelection(selectedKpiId)) return;

    this.setState({ selection: 'kpis', selectedKpiIds: [selectedKpiId] });
    addQueryParams({
      selection: 'kpis',
      selectedId: selectedKpiId,
    });

    const longTermGoalIds1 = this.selectLongTermObjectives({ kpiIds: [selectedKpiId] });
    const annualGoalIds1 = this.selectAnnualObjectives({ longTermGoalIds: longTermGoalIds1 });
    const projectIds1 = this.selectAnnualObjectives({ annualGoalIds: annualGoalIds1 });

    const projectIds2 = this.selectProjects({ kpiIds: [selectedKpiId], annualGoalIds: annualGoalIds1 });
    const annualGoalIds2 = this.selectAnnualObjectives({ projectIds: projectIds2, longTermGoalIds: longTermGoalIds1 });
    const longTermGoalIds2 = this.selectLongTermObjectives({ annualGoalIds: annualGoalIds2, kpiIds: [selectedKpiId] });
  };

  selectUser = (selectedUserId) => {
    if (this.clearSelection(selectedUserId)) return;

    this.setState({ selection: 'users', selectedUserIds: [selectedUserId] });
    addQueryParams({
      selection: 'users',
      selectedId: selectedUserId,
    });

    const projectIds = this.selectProjects({ userIds: [selectedUserId] });
    const selectedKpiIds = this.selectKpis({ projectIds });
    const annualGoalIds = this.selectAnnualObjectives({ projectIds });
    const longTermGoalIds = this.selectLongTermObjectives({ annualGoalIds });
  };

  selectProject = (selectedProjectId) => {
    if (this.clearSelection(selectedProjectId)) return;

    this.setState({ selection: 'projects', selectedProjectIds: [selectedProjectId] });
    addQueryParams({
      selection: 'projects',
      selectedId: selectedProjectId,
    });

    const kpiIds1 = this.selectKpis({ projectIds: [selectedProjectId] });
    const longTermGoalIds1 = this.selectLongTermObjectives({ kpiIds: kpiIds1 });
    const annualGoalIds1 = this.selectAnnualObjectives({ longTermGoalIds: longTermGoalIds1 });

    const selectedUserIds = this.selectUsers({ projectIds: [selectedProjectId] });

    const annualGoalIds2 = this.selectAnnualObjectives({ projectIds: [selectedProjectId] });
    const longTermGoalIds2 = this.selectLongTermObjectives({
      annualGoalIds: annualGoalIds2,
      kpiIds: kpiIds1,
    });
    const selectedKpiIds2 = this.selectKpis({ projectIds: [selectedProjectId], longTermGoalIds: longTermGoalIds2 });
  };

  selectAnnualObjective = (selectedAnnualObjectiveId) => {
    if (this.clearSelection(selectedAnnualObjectiveId)) return;

    this.setState({ selection: 'annualObjectives', selectedAnnualObjectiveIds: [selectedAnnualObjectiveId] });
    addQueryParams({
      selection: 'annualObjectives',
      selectedId: selectedAnnualObjectiveId,
    });

    const projectIds = this.selectProjects({ annualGoalIds: [selectedAnnualObjectiveId] });
    const selectedKpiIds = this.selectKpis({ projectIds });
    const selectedUserIds = this.selectUsers({ projectIds });

    const selectLongTermObjectiveIds = this.selectLongTermObjectives({ annualGoalIds: [selectedAnnualObjectiveId] });
  };

  selectLongTermObjective = (selectedLongTermObjectiveId) => {
    if (this.clearSelection(selectedLongTermObjectiveId)) return;

    this.setState({ selection: 'longTermGoals', selectedLongTermObjectiveIds: [selectedLongTermObjectiveId] });
    addQueryParams({
      selection: 'longTermGoals',
      selectedId: selectedLongTermObjectiveId,
    });

    const annualGoalIds = this.selectAnnualObjectives({ longTermGoalIds: [selectedLongTermObjectiveId] });
    const projectIds = this.selectProjects({ annualGoalIds });
    const kpiIds = this.selectKpis({ projectIds, longTermGoalIds: [selectedLongTermObjectiveId] });
    const userIds = this.selectUsers({ projectIds });
  };

  render() {
    const {
      matrixData,
      loading,
      loadingButton1,
      loadingButton2,
      commitiePickList,
      projetStatusList,
      goalStatusList,
      divisionsList,
      activeXmatrixInfo,
      selectedXmatrixInfo,
      loadingRootList,
      xmatrixRootList,
      isSecondLevelXmatrix,
      userSectionVisibility,
      kpiSectionVisibility,
      xmFilters,
    } = this.state;
    let t = this.props.t;

    return (
      <div className="x-matrix tw-flex tw-flex-col tw-h-full tw-overflow-hidden">
        {/* for print */}
        <Row
          className="matrixNameOnPrint"
          gutter={{ lg: 24 }}>
          <Col
            className="gutter-row"
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}>
            <Typography.Text
              level={2}
              className={'menu xmatrixTitle'}>
              {selectedXmatrixInfo ? selectedXmatrixInfo.hkCode : ''}{' '}
            </Typography.Text>
            <Divider
              type="vertical"
              className="xmatrixTitleDivider"
            />

            {selectedXmatrixInfo && selectedXmatrixInfo.xMatrixName && (
              <>
                <Typography.Text
                  level={2}
                  className={'menu xmatrixTitle'}>
                  {selectedXmatrixInfo ? selectedXmatrixInfo.xMatrixName : ''}{' '}
                </Typography.Text>
                <Divider
                  type="vertical"
                  className="xmatrixTitleDivider"
                />
              </>
            )}
            {selectedXmatrixInfo && (
              <>
                {selectedXmatrixInfo.status === xmatrixStatus.active && (
                  <>
                    <Typography.Text className="activeText xmatrixTitle">
                      {selectedXmatrixInfo.statusDescription}
                    </Typography.Text>
                  </>
                )}
                {selectedXmatrixInfo.status !== xmatrixStatus.active && (
                  <>
                    <Typography.Text className="inactiveText xmatrixTitle">
                      {selectedXmatrixInfo.statusDescription}
                    </Typography.Text>
                  </>
                )}
              </>
            )}
          </Col>
        </Row>

        {selectedXmatrixInfo && (
          <FiltersMatrixLocal
            // xmatrix filters props
            ref={this.filterComp}
            manageFilterReset={this.manageFilterReset}
            loadingButton1={loadingButton1}
            loadingButton2={loadingButton2}
            manageFilterApplied={this.manageFilterApplied}
            manageFiltersChanged={this.manageFiltersChanged}
            t={t}
            //xmatrix header props
            reloadMatrix={this.reloadAfterStatusChange}
            selectedXmatrixInfo={selectedXmatrixInfo}
            isSecondLevelXmatrix={isSecondLevelXmatrix}
            reloadMatrixOnEdit={this.onReload}
            reloadXmInfo={() => this.getSelectedXmatrixInfo()}
            //left panel xmatrix list props
            activeXmatrixInfo={activeXmatrixInfo}
            loadingRootList={loadingRootList}
            xmatrixRootList={xmatrixRootList}
            filters={xmFilters}
            xmatrixData={matrixData}
          />
        )}

        {loading && (
          <div className="tw-min-h-full empty:tw-hidden tw-w-full tw-flex tw-justify-center tw-items-center">
            <Spin
              size="large"
              tip={t('xMatrixPage.xmatrixLoading')}></Spin>
          </div>
        )}

        {matrixData && selectedXmatrixInfo && !loading && (
          <div className="tw-grow tw-p-4 tw-overflow-auto tw-flex">
            <div className={`grid-container tw-overflow-hidden tw-shrink-0 tw-m-auto`}>
              {/* row 1 */}

              <div id="annualObjectiveProjectRelation">
                <RelationTable
                  colList={matrixData.annualGoals}
                  rowList={matrixData.projects}
                  colItemId={'annualGoalID'}
                  rowItemId={'projectID'}
                  origin={'topLeft'}
                  direction={'bottomRight'}
                  relationList={matrixData.goalProjectRelationship}
                  selectedXmatrix={selectedXmatrixInfo.xMatrixID}
                  colSelectionIds={this.state.selectedAnnualObjectiveIds}
                  rowSelectionIds={this.state.selectedProjectIds}
                  onUpdateRelation={this.updateAnnualProjectRelation}
                />
              </div>

              <div
                id="projects"
                className="projects">
                <Projects
                  t={t}
                  projects={matrixData.projects}
                  reloadMatrix={this.onReload}
                  comittieList={commitiePickList}
                  statusList={projetStatusList}
                  divisionsList={divisionsList}
                  selectedXmatrix={selectedXmatrixInfo.xMatrixID}
                  selectedXmatrixInfoData={selectedXmatrixInfo}
                  isSecondLevelXmatrix={isSecondLevelXmatrix}
                  onRowClick={this.selectProject}
                  selectedIds={this.state.selectedProjectIds}
                  selection={this.state.selection === 'projects' ? 'primary' : 'secondary'}
                />
              </div>

              {kpiSectionVisibility && (
                <div id="projectKpiRelation">
                  <RelationTable
                    t={t}
                    colList={matrixData.kpIs}
                    rowList={matrixData.projects}
                    colItemId={'kpiid'}
                    rowItemId={'projectID'}
                    origin={'topLeft'}
                    direction={'bottomLeft'}
                    relationList={matrixData.kpiProjectRelationships}
                    colSelectionIds={this.state.selectedKpiIds}
                    rowSelectionIds={this.state.selectedProjectIds}
                    onUpdateRelation={this.updateProjectKpiRelation}
                  />
                </div>
              )}

              <div
                className="tw-w-8"
                id="space"></div>

              {userSectionVisibility && (
                <div id="projectUserRelation">
                  <ProjectUserRelation
                    t={t}
                    colList={matrixData.users}
                    rowList={matrixData.projects}
                    colItemId={'userID'}
                    rowItemId={'projectID'}
                    origin={'topLeft'}
                    direction={'bottomLeft'}
                    relationList={matrixData.userProjectRelationship}
                    colSelectionIds={this.state.selectedUserIds}
                    rowSelectionIds={this.state.selectedProjectIds}
                    onUpdateRelation={this.updateProjectUserRelation}
                    reloadMatrix={this.onReload}
                    selectedXmatrix={selectedXmatrixInfo.xMatrixID}
                  />
                </div>
              )}

              {/* row 2 */}

              <div
                id="yearObjectives"
                className="rotateYear">
                <YearObjectives
                  t={t}
                  annualGoals={matrixData.annualGoals}
                  reloadMatrix={this.onReload}
                  goalStatusList={goalStatusList}
                  selectedXmatrix={selectedXmatrixInfo.xMatrixID}
                  selectedIds={this.state.selectedAnnualObjectiveIds}
                  selection={this.state.selection === 'annualObjectives' ? 'primary' : 'secondary'}
                  onRowClick={this.selectAnnualObjective}
                />
              </div>

              <div id="center">
                <Center
                  reloadMatrix={this.onReload}
                  comittieList={commitiePickList}
                  projectStatusList={projetStatusList}
                  goalStatusList={goalStatusList}
                  divisionsList={divisionsList}
                  toggleUsersSection={this.toggleUsersSection}
                  toggleKpiSection={this.toggleKpiSection}
                  isKpiSectionVisible={this.state.kpiSectionVisibility}
                  isUsersSectionVisible={this.state.userSectionVisibility}
                  selectedXmatrix={selectedXmatrixInfo.xMatrixID}
                  selectedXmatrixInfo={selectedXmatrixInfo}
                  isSecondLevelXmatrix={isSecondLevelXmatrix}
                  selectedKpiIds={this.state.selectedKpiIds}
                  selectedProjectIds={this.state.selectedProjectIds}
                  selectedUserIds={this.state.selectedUserIds}
                  selectedAnnualObjectiveIds={this.state.selectedAnnualObjectiveIds}
                  selectedLongTermObjectiveIds={this.state.selectedLongTermObjectiveIds}
                  annualGoals={matrixData.annualGoals}
                  longTermGoals={matrixData.longTermGoals}
                  projects={matrixData.projects}
                />
              </div>

              {kpiSectionVisibility && (
                <div
                  id="kpi"
                  className={classNames('rotateKpi')}>
                  <Kpi
                    t={t}
                    kpi={matrixData.kpIs}
                    reloadMatrix={this.onReload}
                    selectedXmatrix={selectedXmatrixInfo.xMatrixID}
                    onRowClick={this.selectKpi}
                    selectedIds={this.state.selectedKpiIds}
                    selection={this.state.selection === 'kpis' ? 'primary' : 'secondary'}
                  />
                </div>
              )}

              <div
                className="tw-w-8"
                id="space"></div>

              {/* <div
                  id="userCenter"
                  className="grid-item tw-border-2 tw-border-emerald-500">
                  <UserCenter
                    t={t}
                    onUserVisibilityChange={this.toggleUsersSection}
                  />
                </div> */}

              {userSectionVisibility && (
                <div
                  id="usersXmatrix"
                  className={classNames('rotateUsers')}>
                  <UsersXmatrix
                    usersXmatrix={matrixData.users}
                    selectedIds={this.state.selectedUserIds}
                    selection={this.state.selection === 'users' ? 'primary' : 'secondary'}
                    onRowClick={this.selectUser}
                  />
                </div>
              )}

              {/* row 3 */}

              <div id="longAnnualObjectiveRelation">
                <RelationTable
                  colList={matrixData.annualGoals}
                  rowList={matrixData.longTermGoals}
                  colItemId={'annualGoalID'}
                  rowItemId={'longTermGoalID'}
                  origin={'topLeft'}
                  direction={'topRight'}
                  relationList={matrixData.longTermAnnualGoalRelationships}
                  colSelectionIds={this.state.selectedAnnualObjectiveIds}
                  rowSelectionIds={this.state.selectedLongTermObjectiveIds}
                  onUpdateRelation={this.updateLongAnnualRelation}
                />
              </div>

              <div
                id="bigObjectives"
                className="big-objectives">
                <BigObjectives
                  t={t}
                  longTermGoals={matrixData.longTermGoals}
                  reloadMatrix={this.onReload}
                  selectedXmatrix={selectedXmatrixInfo.xMatrixID}
                  selectedIds={this.state.selectedLongTermObjectiveIds}
                  selection={this.state.selection === 'longTermGoals' ? 'primary' : 'secondary'}
                  onRowClick={this.selectLongTermObjective}
                />
              </div>

              {kpiSectionVisibility && (
                <div
                  id="kpiLongObjectivesRelation"
                  className={classNames('', {})}>
                  <RelationTable
                    t={t}
                    colList={matrixData.kpIs}
                    rowList={matrixData.longTermGoals}
                    colItemId={'kpiid'}
                    rowItemId={'longTermGoalID'}
                    origin={'topLeft'}
                    direction={'topLeft'}
                    relationList={matrixData.goalKPIRelationship}
                    colSelectionIds={this.state.selectedKpiIds}
                    rowSelectionIds={this.state.selectedLongTermObjectiveIds}
                    onUpdateRelation={this.updateLongGoalKpiRelation}
                  />
                </div>
              )}

              <div
                className="tw-w-8"
                id="space"></div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default withTranslation()(Matrix);

import { Form } from 'antd';
import React, { useState } from 'react';

import { BorderOuterOutlined, SaveOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, Row, Segmented, Space, Tooltip } from 'antd';
import BraftEditor from 'braft-editor';
import FontSizeChanger from 'react-font-size-changer';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { formItemLayout, longOrAnnualGoalFieldUpdate, richTectEditoControlls } from '../../shared/utils/constants';
import { SegmentTitle } from '../../shared/utils/functions';

const { Panel } = Collapse;

const WorkshopPresentation = ({
  annualGoalData,
  annualGoalId,
  t,
  isRoleTeamMemberOrTeamLeader,
  loadingSaveField,
  updateFields,
}) => {
  const handleFullscreen = useFullScreenHandle();
  const [comentEditiorValue, setComentEditiorValue] = useState(
    annualGoalData.getAnnualGoalDto.notes ? annualGoalData.getAnnualGoalDto.notes : '',
  );
  const [dynamicFontSize, setDynamicFontSize] = useState(20);
  const [dynamicFontClass, setDynamicFontClass] = useState('dynaFontSize20');

  const handleCommentEditorChange = (editorState) => {
    setComentEditiorValue(editorState);
  };

  const handleCommentEditorSave = async () => {
    let payload = {};
    let fieldValue = BraftEditor.createEditorState(comentEditiorValue).toHTML();
    payload['itemID'] = annualGoalId;
    payload['nameOrNote'] = fieldValue;
    payload['updateCase'] = longOrAnnualGoalFieldUpdate.anualGoalNotes;
    if (!isRoleTeamMemberOrTeamLeader) {
      updateFields(payload);
    }
  };

  const handelPresentationMode = () => {
    handleFullscreen.enter();
  };

  return (
    <>
      <FullScreen handle={handleFullscreen}>
        <div className="presSegment">
          <Row
            className="mb-row"
            gutter={{ lg: 24 }}
            style={{ padding: '0px 20px' }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <Segmented
                style={{ width: '200px' }}
                block
                options={[
                  {
                    value: 'tab1',
                    label: <SegmentTitle title={t('obiettiviLungoPage.workShopComments')} />,
                  },
                ]}
              />
            </Col>

            <Col
              className="gutter-row sizeChangerButtons"
              style={{ display: 'flex', justifyContent: 'flex-end' }}
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}>
              <Space direction="horizontal">
                <FontSizeChanger
                  targets={['.presContent']}
                  onChange={(element, newValue, oldValue) => {
                    setDynamicFontSize(newValue);
                    setDynamicFontClass('dynaFontSize' + newValue.toString());
                  }}
                  options={{
                    stepSize: 2,
                    range: 8,
                  }}
                  customButtons={{
                    up: (
                      <Button
                        disabled={dynamicFontSize < 36 ? false : true}
                        shape="circle">
                        A+
                      </Button>
                    ),
                    down: (
                      <Button
                        disabled={dynamicFontSize > 20 ? false : true}
                        shape="circle">
                        A-
                      </Button>
                    ),
                    style: {
                      border: 'none',
                      WebkitBoxSizing: 'none',
                    },
                  }}
                />

                <Tooltip title={t('a3Page.fullscreenPage')}>
                  <BorderOuterOutlined
                    onClick={handelPresentationMode}
                    style={{ fontSize: 22, color: '#959697' }}
                  />
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>

        <Row
          gutter={{ lg: 24 }}
          className="presContent">
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}>
            <Collapse
              defaultActiveKey={'1'}
              expandIconPosition={'start'}
              className={`${dynamicFontClass} ` + ' cus-collapse-title'}>
              <Panel
                header={t('general.commenti')}
                key="1"
                className="zoomSecHeight"
                extra={
                  !isRoleTeamMemberOrTeamLeader ? (
                    <Form.Item>
                      <Tooltip title={t('buttons.salva')}>
                        <Button
                          htmlType="submit"
                          loading={loadingSaveField}
                          icon={<SaveOutlined />}
                          style={{ marginRight: 10 }}
                          type="primary"></Button>
                      </Tooltip>
                    </Form.Item>
                  ) : (
                    ''
                  )
                }>
                <Form
                  name="add_ob_lungo"
                  layout="vertical"
                  {...formItemLayout}
                  onFinish={handleCommentEditorSave}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Form.Item label="">
                        <BraftEditor
                          contentFormat="html"
                          language="en"
                          id="editor-text"
                          controls={richTectEditoControlls}
                          value={BraftEditor.createEditorState(comentEditiorValue)}
                          onChange={handleCommentEditorChange}
                          onBlur={handleCommentEditorSave}
                          disabled={isRoleTeamMemberOrTeamLeader}
                        />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </Panel>
            </Collapse>
          </Col>
        </Row>
      </FullScreen>
    </>
  );
};
export default WorkshopPresentation;

import { Form } from 'antd';
import React, { Component } from 'react';

import { CheckCircleOutlined } from '@ant-design/icons';
import { Button, Col, Modal, Row } from 'antd';
import BraftEditor from 'braft-editor';
// import "antd/dist/antd.css";
import 'braft-editor/dist/index.css';
import { withTranslation } from 'react-i18next';
import { richTectEditoControlls } from '../../../shared/utils/constants';
import { requiredFields } from '../../../shared/utils/notifyMessages';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class EditorModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
    };
  }

  updateField = (values) => {
    this.props.handelEditorSave(values);
  };

  render() {
    const { show, handelEditorClose, handelEditorSave, data, loadingEditorButton } = this.props;
    let t = this.props.t;
    return (
      <>
        {data && (
          <Modal
            className="edit_ob_modal"
            width={800}
            title={`${data.fieldName}`}
            open={show}
            // destroyOnClose={true}
            // onCancel={handelEditorSave}
            destroyOnClose={true}
            onCancel={handelEditorClose}
            closable={true}
            maskClosable={false}
            footer={[
              <div
                key="buttons"
                className="footer-editor-modal-buttons">
                <Button
                  form="editorForm"
                  icon={<CheckCircleOutlined />}
                  type="primary"
                  htmlType="submit"
                  key="submit"
                  loading={loadingEditorButton}>
                  {t('buttons.conferma')}
                </Button>

                <Button onClick={handelEditorClose}>{t('buttons.chiudi')}</Button>
              </div>,
            ]}>
            <Form
              id="editorForm"
              name="project_field"
              layout="vertical"
              {...formItemLayout}
              onFinish={(values) => this.updateField(values)}>
              <Row gutter={{ lg: 24 }}>
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <Form.Item
                    name={data.fieldKey}
                    rules={[{ required: true, message: requiredFields.required }]}
                    initialValue={BraftEditor.createEditorState(data.fieldValue)}>
                    <BraftEditor
                      style={{ maxHeight: '250px', overflowY: 'auto', overflowX: 'hidden' }}
                      contentFormat="html"
                      language="en"
                      id="editor-text"
                      controls={richTectEditoControlls}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Modal>
        )}
      </>
    );
  }
}

export default withTranslation()(EditorModal);

import { Button, Dropdown } from 'antd';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingBar from 'react-top-loading-bar';
import { LifebuoyIcon } from '../../../../../assets/icons';

export default function HelpSection({ companyData }) {
  const ref = useRef(null);
  const { t } = useTranslation();
  const supportEmailLink = `mailto:${companyData?.supportMail}?subject=Neym ["${companyData?.companyName}"] - ${t('headerMenu.subject')}`;
  const redirectLinkURL = companyData?.redirectLink;

  const items = [
    {
      label: (
        <a
          href={redirectLinkURL}
          target="blank">
          {t('headerMenu.tutorial')}
        </a>
      ),
      key: 2,
    },
    { label: <a href={supportEmailLink}>{t('headerMenu.contact')}</a>, key: 1 },
  ];

  return (
    <div className="tw-items-center tw-flex navbar-button">
      <LoadingBar
        color="white"
        ref={ref}
      />
      <Dropdown menu={{ items }}>
        <Button
          style={{ color: 'white' }}
          onClick={(e) => e.preventDefault()}
          type="ghost"
          icon={<LifebuoyIcon />}></Button>
      </Dropdown>
    </div>
  );
}

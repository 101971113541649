import {
  CalendarOutlined,
  CheckOutlined,
  ClockCircleOutlined,
  CloseOutlined,
  EuroOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import { Button, Col, Modal, Radio, Row, Select, Space, Switch } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import './dashboard.scss';

const { Option } = Select;

const DashboardFiltersModal = ({
  showFiltersModal,
  handelModalFiltersClose,
  filters,
  t,
  dashboardFilters,
  addFilterLabel,
  manageFiltersChanged,
  applyFilters,
  resetFilters,
}) => {
  const [filterIsChanged, setFilterIsChanged] = useState(false);

  useEffect(() => {}, []);

  const handleChange = (filter, val, filterLabelObject) => {
    setFilterIsChanged(true);
    if (val) {
      const filterObj = { filter: filter, value: val?.value ? String(val.value) : '' };
      manageFiltersChanged(filterObj);
      addFilterLabel(filterLabelObject);
    }
  };

  const handleProjectRelationFilterChange = (filter, val, filterLabelObject) => {
    setFilterIsChanged(true);
    const filterObj = { filter: filter, value: val };
    manageFiltersChanged(filterObj);
    addFilterLabel(filterLabelObject);
  };

  const handleSubmit = () => {
    setFilterIsChanged(false);
    applyFilters();
  };

  const handelClear = () => {
    setFilterIsChanged(false);
    resetFilters();
  };

  return (
    <>
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('dashboardProgettiPage.dashboardFilter')}
        open={showFiltersModal}
        onCancel={filterIsChanged ? () => handleSubmit() : handelModalFiltersClose}
        footer={
          <div className="tw-flex tw-items-center tw-justify-between">
            <span>
              <CalendarOutlined style={{ color: 'black' }} />
              <span style={{ color: 'black' }}>
                {t('dashboardProgettiPage.settimanaCorrente')} {moment(moment(), 'MMDDYYYY').isoWeek()}
              </span>
            </span>
            <div className="tw-flex tw-gap-2">
              <Button onClick={() => handelClear()}>{t('general.clearFilters')}</Button>
              <Button
                disabled={!filterIsChanged}
                type="primary"
                onClick={() => handleSubmit()}>
                {t('general.filter')}
              </Button>
            </div>
          </div>
        }
        maskClosable={false}>
        <Row s>
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('dashboardProgettiPage.teamLeader')}</span>
            <Select
              id="team_leader_filter"
              value={dashboardFilters && dashboardFilters.teamLider ? dashboardFilters.teamLider : []}
              style={{ width: '100%' }}
              placeholder={t('general.seleziona')}
              onChange={(event, val) =>
                handleChange('teamLider', val, {
                  value: 'teamLider',
                  label: t('dashboardProgettiPage.teamLeader'),
                })
              }
              options={filters.teamLeaders}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('dashboardProgettiPage.statoProgetto')}</span>
            <Select
              // value={this.state.status}
              value={dashboardFilters && dashboardFilters.status ? dashboardFilters.status : []}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(event, val) =>
                handleChange('status', val, {
                  value: 'status',
                  label: t('dashboardProgettiPage.statoProgetto'),
                })
              }
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              <Option
                value={0}
                key="statusNan"
                style={{ color: '#afaeae' }}>
                {t('general.seleziona')}...
              </Option>
              {filters.status.map((item) => (
                <Option
                  value={item.statusID}
                  key={item.statusID}>
                  {item.statusDescription}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('proggetiPage.codiceProgetto')}</span>
            <Select
              // value={this.state.projectCode}
              value={dashboardFilters && dashboardFilters.projectCode ? dashboardFilters.projectCode : []}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(event, val) =>
                handleChange('projectCode', val, {
                  value: 'projectCode',
                  label: t('proggetiPage.codiceProgetto'),
                })
              }
              filterOption={(input, option) =>
                option.children?.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              <Option
                value={''}
                key="projectCode"
                style={{ color: '#afaeae' }}>
                {t('general.seleziona')}...
              </Option>
              {filters.projectCode.map((item) => (
                <Option
                  value={item.projectCode}
                  key={item.projectCode}>
                  {item.projectCode}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('dashboardProgettiPage.divisioniReparti')}</span>
            <Select
              // value={this.state.division}
              value={dashboardFilters && dashboardFilters.division ? dashboardFilters.division : []}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(event, val) =>
                handleChange('division', val, {
                  value: 'division',
                  label: t('dashboardProgettiPage.divisioniReparti'),
                })
              }
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              <Option
                value={''}
                key="divisionNan"
                style={{ color: '#afaeae' }}>
                {t('general.seleziona')}...
              </Option>
              {filters.division.map((item) => (
                <Option
                  disabled={item.hasAccess === 1 ? false : true}
                  value={item.divisionID}
                  key={item.divisionID}>
                  {item.divisionName}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('general.commitato')}</span>
            <Select
              // value={this.state.committie}
              value={dashboardFilters && dashboardFilters.committie ? dashboardFilters.committie : []}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(event, val) =>
                handleChange('committie', val, { value: 'committie', label: t('general.commitato') })
              }
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              <Option
                value={''}
                key="committiesNan"
                style={{ color: '#afaeae' }}>
                {t('general.seleziona')}...
              </Option>
              {filters.committie.map((item) => (
                <Option
                  disabled={item.hasAccess === 1 ? false : true}
                  value={item.committeeID}
                  key={item.committeeID}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('dashboardProgettiPage.analisiAllarmi')}</span>
            <Select
              // value={this.state.comboSemaphore}
              value={dashboardFilters && dashboardFilters.comboSemaphore ? dashboardFilters.comboSemaphore : []}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(event, val) =>
                handleChange('comboSemaphore', val, {
                  value: 'comboSemaphore',
                  label: t('dashboardProgettiPage.analisiAllarmi'),
                })
              }
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              <Option
                value={0}
                key="comboSemaphoreNan"
                style={{ color: '#afaeae' }}>
                {t('general.seleziona')}...
              </Option>
              {filters.semaphores.map((item) => (
                <Option
                  value={item.objectCodeListID}
                  key={item.objectCodeListID}>
                  {item.description}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            className="tw-my-4"
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}>
            <span>
              <ClockCircleOutlined style={{ fontSize: '17px', color: 'black' }} />
            </span>
            <Radio.Group
              style={{ width: '100%' }}
              defaultValue={dashboardFilters.timeSemaphore ? dashboardFilters.timeSemaphore : null}
              onChange={(e) =>
                handleChange('timeSemaphore', e.target, {
                  value: 'timeSemaphore',
                  label: t('dashboardProgettiPage.tempoFilter'),
                })
              }>
              <Space direction="vertical">
                {filters.semaphores.map((item) => (
                  <Radio
                    value={item.objectCodeListID}
                    key={item.objectCodeListID}>
                    {item.description}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Col>

          <Col
            className="tw-my-4"
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}>
            <span>
              <EuroOutlined style={{ fontSize: '17px', color: 'black' }} />
            </span>
            <Radio.Group
              style={{ width: '100%' }}
              onChange={(e) =>
                handleChange('budgetSemaphore', e.target, {
                  value: 'budgetSemaphore',
                  label: t('dashboardProgettiPage.budgetFilter'),
                })
              }
              defaultValue={dashboardFilters.budgetSemaphore ? dashboardFilters.budgetSemaphore : null}>
              <Space direction="vertical">
                {filters.semaphores.map((item) => (
                  <Radio
                    value={item.objectCodeListID}
                    key={item.objectCodeListID}>
                    {item.description}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Col>

          <Col
            className="tw-my-4"
            xs={{ span: 12 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 8 }}>
            <span>
              <LineChartOutlined style={{ fontSize: '18px', color: 'black' }} />
            </span>
            <Radio.Group
              style={{ width: '100%' }}
              onChange={(e) =>
                handleChange('qualitySemaphore', e.target, {
                  value: 'qualitySemaphore',
                  label: t('dashboardProgettiPage.qualitaFilter'),
                })
              }
              defaultValue={dashboardFilters.qualitySemaphore ? dashboardFilters.qualitySemaphore : null}>
              <Space direction="vertical">
                {filters.semaphores.map((item) => (
                  <Radio
                    value={item.objectCodeListID}
                    key={item.objectCodeListID}>
                    {item.description}
                  </Radio>
                ))}
              </Space>
            </Radio.Group>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('dashboardProgettiPage.tipologiaProgetti')}</span>
            <Select
              // value={this.state.filterCriteria}
              value={dashboardFilters && dashboardFilters.filterCriteria ? dashboardFilters.filterCriteria : []}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(event, val) =>
                handleChange('filterCriteria', val, {
                  value: 'filterCriteria',
                  label: t('dashboardProgettiPage.tipologiaProgetti'),
                })
              }
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              <Option
                value={''}
                key="filterCriteriaNan"
                style={{ color: '#afaeae' }}>
                {t('general.seleziona')}...
              </Option>
              {filters.filterCriteria.map((item) => (
                <Option
                  value={item.objectCodeListID}
                  key={item.objectCodeListID}>
                  {item.description}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('general.progetto')}</span>
            <Select
              // value={this.state.parentProject}
              value={dashboardFilters && dashboardFilters.parentProject ? dashboardFilters.parentProject : []}
              style={{ width: '100%' }}
              showSearch
              placeholder={t('general.seleziona')}
              optionFilterProp="children"
              onChange={(event, val) =>
                handleChange('parentProject', val, { value: 'parentProject', label: t('general.progetto') })
              }
              filterOption={(input, option) =>
                option.children.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
              }>
              <Option
                value={''}
                key="parentProjectNan"
                style={{ color: '#afaeae' }}>
                {t('general.seleziona')}...
              </Option>
              {filters.parentProject.map((item) => (
                <Option
                  value={item.projectID}
                  key={item.projectID}>
                  {item.name}
                </Option>
              ))}
            </Select>
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('general.sponsor')}</span>
            <Select
              value={dashboardFilters && dashboardFilters.sponsor ? dashboardFilters.sponsor : []}
              style={{ width: '100%' }}
              placeholder={t('general.seleziona')}
              onChange={(event, val) => handleChange('sponsor', val, { value: 'sponsor', label: t('general.sponsor') })}
              options={filters.sponsor}
              showSearch
              filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
            />
          </Col>

          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 12 }}
            lg={{ span: 12 }}>
            <span>{t('proggetiPage.progettiSenzaRelazione')}</span>
            <br />
            <Switch
              checkedChildren={<CheckOutlined />}
              unCheckedChildren={<CloseOutlined />}
              // checked={this.state.selectNotRelatedProjects}
              checked={dashboardFilters.selectNotRelatedProjects}
              onChange={(checked) =>
                handleProjectRelationFilterChange('selectNotRelatedProjects', checked, {
                  value: 'selectNotRelatedProjects',
                  label: t('proggetiPage.progettiSenzaRelazione'),
                })
              }
            />
          </Col>
        </Row>
      </Modal>
    </>
  );
};
export default DashboardFiltersModal;

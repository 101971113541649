import { FilterOutlined, LockOutlined, MinusSquareOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Col, DatePicker, Table, Tooltip, message } from 'antd';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Section2Service from '../../../services/a3/section2Service';
import { translatedMonths, translatedMonths2 } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import '../section2/section2.scss';

const Section2PrintPreview = ({
  openHandler,
  id,
  hasUserAccess,
  isModalOpen,
  isPresentationMode,
  t,
  languageCode,
  currentProject,
}) => {
  const [fullScreen, setFullscreen] = useState(false);
  const [parentDeliverableData, setParentDeliverableData] = useState([]);
  const [deliverableData, setDeliverableData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(null);
  const [clearFilter, setClearFilters] = useState(false);
  const [addOneYear, setAddOneYear] = useState(false);
  const [deliverable, setDeliverableActive] = useState(true);
  const [clearFilterDeliverable, setClearFiltersDeliverable] = useState(false);

  useEffect(() => {
    retrieveMasterPlanParent(id);
    retrieveMasterPlanDeliverable(id);
  }, []);

  const retrieveMasterPlanParent = (id, resetYear = false) => {
    let currentYear = new Date().getFullYear();

    let formatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;
    if (resetYear) formatedYear = 2022;

    Section2Service.getMasterPlanParent(id, formatedYear, addOneYear)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setParentDeliverableData(resp.responseObject.value);
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  const retrieveMasterPlanDeliverable = (id, resetYear = false) => {
    let currentYear = new Date().getFullYear();
    let formatedYear = selectedYear ? moment(selectedYear).format('YYYY') : currentYear;
    if (resetYear) formatedYear = 2022;
    Section2Service.getMasterPlanDeliverable(id, formatedYear, addOneYear, deliverable)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setDeliverableData(resp.responseObject.value);
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  const getDataObject = (month, status, item) => {
    const data = { backgroundColor: '#fff', borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' };
    data.backgroundColor = getBackgroundParentColorValue(status, item);
    return data;
  };

  const months = translatedMonths(languageCode);

  const vals = translatedMonths2(languageCode);

  const arr = ['gen', 'feb', 'mar', 'apr', 'mag', 'giu', 'lug', 'ago', 'set', 'ott', 'nov', 'dic'];

  const STATUS = ['Completato', 'Pianificato', 'In Ritardo/Sospeso', 'Pianificazione Attesa'];

  const STATUS1 = ['Draft', 'Aperto', 'Sospeso', 'Chiuso'];

  let year = '';

  const map = new Map();
  const headerMonths = new Map();
  parentDeliverableData.forEach((deliverable) => {
    map.set(
      deliverable.name,
      Object.keys(deliverable.months)
        .map((key) => {
          const val = deliverable.months[key];
          const cur = new Date(key).getMonth() + 1;
          const obj = vals.find((v) => v.key === cur);
          year = new Date(key).getFullYear() || '2022';
          if (!headerMonths.has(year)) {
            headerMonths.set(year, new Set());
          }

          const data = { ...obj, year };
          const exists = [...headerMonths.get(year)].find((el) => el.year === data.year && el.text === data.text);
          if (!exists) {
            headerMonths.get(year).add(data);
          }

          return {
            date: key,
            name: obj.text + '_' + year,
            status: STATUS1[val - 1],
            year: new Date(key).getFullYear(),
          };
        })
        .filter((obj) => obj),
    );
  });

  const sortedHeaderMonths = new Map([...headerMonths.entries()].sort());
  sortedHeaderMonths.forEach((value, key) => {
    sortedHeaderMonths.set(
      key,
      new Set(
        [...value]
          .map((header) => {
            header.index = header.key;
            header.year = key;
            return header;
          })
          .sort((a, b) => a.index - b.index)
          .map((header) => {
            header.monthNumber = header.index;
            header.key = header.text + '_' + key;
            header.title = header.title + ' ' + header.year;
            return header;
          }),
      ),
    );
  });

  const onYearChange = (year) => {
    setSelectedYear(year);
  };

  const handleAddOneYear = () => {
    setAddOneYear(true);
  };

  useEffect(() => {
    //  if(!addOneYear) {
    retrieveMasterPlanParent(id);
    retrieveMasterPlanDeliverable(id);
    // }
  }, [addOneYear]);

  const onFilter = () => {
    setClearFilters(false);
    retrieveMasterPlanParent(id);
    retrieveMasterPlanDeliverable(id);
  };

  const onClearFilter = () => {
    setClearFilters(true);
    setSelectedYear(null);
    setAddOneYear(false);
    retrieveMasterPlanParent(id, true);
    retrieveMasterPlanDeliverable(id, true);
  };

  let headers = [];
  sortedHeaderMonths.forEach((months, key) => {
    headers = headers.concat([...months]);
  });
  const currentMonthParent = new Date().getMonth() + 1;
  const currentYear = new Date().getFullYear();

  const currentMonth = new Date().getMonth();

  const highlighted = headers.map((month, index) => {
    if (currentMonthParent === month.monthNumber && month.year === currentYear) {
      return { ...month, title: <div className="highlight">{month.title}</div> };
    } else {
      return { ...month, title: month.title };
    }
  });

  const sliced = highlighted;

  const getBackgroundColorValue = (status) => {
    if (currentProject.status === 2 && status === 1) {
      return '#696969';
    } else if (status === 1) {
      return '#13761e'; //green
    } else if (status === 2) {
      return '#13761e';
    } else if (status === 3) {
      return '#B30000'; //red
    } else if (status === 4) {
      return '#B30000';
    } else if (status === 5) {
      return '#FF8000';
    } else if (status === 6) {
      return '#FFFFFF';
    }
  };

  const getBackgroundParentColorValue = (status, deliverable) => {
    if (status === 1) {
      return '#b7eb8f'; //green
    } else if (status === 2) {
      return '#40a9ff'; //blue
    } else if (status === 3) {
      if (deliverable.statusDescription === 'Aperto') {
        return '#ff0000'; //red
      } else if (deliverable.statusDescription === 'Chiuso') {
        return '#ff0000'; //red
      } else if (deliverable.statusDescription === 'Sospeso') {
        return '#808080'; //gray
      }
    } else if (status === 4) {
      return '#ffff00'; //yellow
    } else {
      return '#FFFFFF';
    }
  };

  const monthParent = [];

  const data = [];
  for (let i = 0; i < parentDeliverableData.length; i++) {
    const parentDataObject = {
      key: i,
      name: (
        <div
          className="col-bottom1"
          style={{ display: 'inline' }}>
          <div
            style={{
              marginRight: '10px',
              padding: ' 4px 6px',
              display: 'inline-block',
              color: '#4f92da',
              fontSize: '13px',
            }}>
            {i + 1}
          </div>
          <div
            className="links"
            style={{ fontSize: '13px', display: 'inline', textalign: 'center' }}>
            <Link
              to={{ pathname: `/deliverables/id/${parentDeliverableData[i].deliverableID}` }}
              style={{ color: '#4f92da' }}>
              {parentDeliverableData[i].name}
            </Link>
          </div>
          {/* <span className="name" style={{ width: '100px', fontSize: '10px', height: '0px' }}>{parentDeliverableData[i].name}</span> */}
        </div>
      ),
      // gen: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
      // feb: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
      // mar: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
      // apr: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
      // mag: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
      // giu: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
      // lug: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
      // ago: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
      // set: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
      // ott: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
      // nov: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
      // dic: { backgroundColor: "#fff", borderRightColor: '#FFFFFF', borderLeftColor: '#FFFFFF' },
    };

    sliced.forEach((month) => {
      parentDataObject[month.key] = {
        backgroundColor: '#fff',
        borderRightColor: '#FFFFFF',
        borderLeftColor: '#FFFFFF',
      };
    });

    const monthsObj = parentDeliverableData[i].months;
    const dates = Object.keys(monthsObj);
    for (let j = 0; j < dates.length; j++) {
      const dateValue = dates[j];
      const date = new Date(dateValue);
      const monthValue = date.getMonth();
      const projectValue = monthsObj[dateValue];
      if (projectValue) {
        monthParent.push(monthValue);
      }
      if (monthsObj[dates[j]]) {
        // parentDataObject[months[monthValue]["key"]] = getDataObject(monthValue + 1, monthsObj[dates[j]]);
        parentDataObject[months[monthValue]['key'] + '_' + date.getFullYear()] = getDataObject(
          monthValue + 1,
          monthsObj[dates[j]],
          parentDeliverableData[i],
        );
      }
    }
    data.push(parentDataObject);
  }

  let monthsBelow = [];
  let six = [];
  let rest = [];
  if (deliverableData.length) {
    const starts = deliverableData.map((datum) => moment(datum.projectStartDate));
    const ends = deliverableData.map((datum) => moment(datum.projectEndDate));
    const earliest = starts.sort((a, b) => a - b);
    const first = earliest[0];
    const latest = ends.sort((a, b) => b - a);
    const last = latest[0];
    const lastYear = last.year();
    const lastMonth = last.month();

    let yearBelow = first.year();
    let monthBelow = first.month();

    let stringBelow = `${yearBelow}-${first.format('MM')}-01`;
    let momentBelow = moment(stringBelow);
    let key = 1;
    const titleString = momentBelow.format('MMM YYYY');
    const thisYear = yearBelow === currentYear;
    const thisMonth = monthBelow === currentMonth;
    const title = thisYear && thisMonth ? <div className="highlight">{titleString}</div> : titleString;
    let columnBelow = {
      title,
      key,
      year: yearBelow,
      month: monthBelow,
    };

    monthsBelow.push(columnBelow);

    const yearBehind = yearBelow < lastYear;
    const monthBehind = monthBelow < lastMonth;
    let behind = yearBehind || monthBehind;
    while (behind) {
      momentBelow.add(1, 'M');
      yearBelow = momentBelow.year();
      monthBelow = momentBelow.month();
      const titleString = momentBelow.format('MMM YYYY');
      const thisYear = yearBelow === currentYear;
      const thisMonth = monthBelow === currentMonth;
      const title = thisYear && thisMonth ? <div className="highlight">{titleString}</div> : titleString;
      let columnBelow = {
        title,
        key,
        year: yearBelow,
        month: monthBelow,
      };

      monthsBelow.push(columnBelow);
      const yearBehind = yearBelow < lastYear;
      const monthBehind = monthBelow < lastMonth;
      behind = yearBehind || monthBehind;
    }
    const filterYear = parseInt(moment(selectedYear).format('YYYY'), 10) || new Date().getFullYear();
    monthsBelow = monthsBelow.filter(
      (data) => data.year === filterYear || (addOneYear && data.year === filterYear + 1),
    );

    six = monthsBelow.slice(0, 6);
    rest = monthsBelow.slice(6, addOneYear ? 24 : 12);
  }

  const columns = [
    {
      title: `${t('a3Page.macroAttivita')}`,
      dataIndex: 'name',
      key: 'name',
      width: 200,
      backgroundColor: 'red',
      ellipsis: {
        showTitle: false,
      },
      render: (title) => (
        <Tooltip
          color="white"
          title={title}>
          {title}
        </Tooltip>
      ),
    },
    ...sliced.map((mon) => ({
      ...mon,
      dataIndex: mon.key,
      width: 40,
      render: (text, name, index, mon) => {
        let title = '';
        let res = [];

        for (const [key, value] of Object.entries(name)) {
          if (arr.includes(key.split('_')[0])) {
            if (value.backgroundColor !== '#FFFFFF' && value.backgroundColor !== '#fff') {
              const deliverable = map.get(parentDeliverableData[index].name);

              if (deliverable && deliverable.length) {
                const m = deliverable.find((d) => d.name === key);
                if (m) {
                  let col = {};
                  title =
                    parentDeliverableData[index].name +
                    '\n' +
                    `${t('general.stato')}: ` +
                    parentDeliverableData[index].statusDescription +
                    '\n' +
                    `${t('general.start')}: ` +
                    moment(parentDeliverableData[index].startDate).format('DD/MM/YYYY') +
                    '\n' +
                    `${t('general.end')}: ` +
                    moment(parentDeliverableData[index].endDate).format('DD/MM/YYYY') +
                    '\n' +
                    `${t('proggetiPage.owner')}: ` +
                    parentDeliverableData[index].fullName;
                  col.backgroundColor = value.backgroundColor;
                  col.title = title;
                  res.push(col);
                }
              }
            }
          }
        }
        let selectedColor = res.find((r) => r.backgroundColor === text.backgroundColor);
        const cellColor = (index + 1) % 2 === 0 ? '#f2f3f4' : '#fff';
        const tooltip = (selectedColor && selectedColor.title) || '';
        const propsCus = {
          style: {
            background: selectedColor ? text.backgroundColor : cellColor,
            borderRight: `0px solid ${text.borderRightColor}`,
            borderLeft: `0px solid ${text.borderLeftColor}`,
            borderBottom: `0px solid #ffffff `,
            borderTop: `0px solid #ffffff`,
            '&:hover': { background: 'black' },
          },
          className: tooltip && 'hover-highlight',
          title: tooltip,
        };
        return {
          props: propsCus,
        };
      },
    })),
  ];

  const monthsBottom3 = [];
  const dataBottom = [{ key: 1 }];

  for (let i = 0; i < deliverableData.length; i++) {
    const monthsObj = deliverableData[i].monthValue;
    const date = new Date(monthsObj);
    const monthValue = date.getMonth();
    const yearValue = date.getFullYear();
    const sixMonth = six.find((month) => {
      const rightMonth = month.month === monthValue;
      const rightYear = month.year === yearValue;

      return rightYear && rightMonth;
    });
    if (sixMonth) {
      var text2 = '';
      const deliverable = deliverableData[i];
      const tooltip = ` ${t('proggetiPage.owner')}:  ${deliverable.fullName} \n ${t('general.stato')}:  ${STATUS1[deliverable.status - 1]} \n ${t('general.start')}:  ${moment(deliverable.startDate).format('DD/MM/YYYY')} \n ${t('general.end')}:  ${moment(deliverable.endDate).format('DD/MM/YYYY')}`;

      if (deliverableData[i].deliverableSubtitle !== '') {
        if (deliverableData[i].status === '4') {
          text2 = (
            <div
              title={tooltip}
              style={{ padding: '5px' }}>
              <LockOutlined />
              <Link
                to={{ pathname: `/deliverables/id/${deliverableData[i].deliverableID}` }}
                style={{
                  color: getBackgroundColorValue(deliverableData[i].deliverableStatus),
                  size: '20px',
                  textDecoration: 'line-through',
                  fontWeight: 'bold',
                }}>
                {deliverableData[i].deliverableSubtitle}
              </Link>

              <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{deliverableData[i].deliverableTitle}</p>
            </div>
          );
        } else {
          if (deliverableData[i].deliverableStatus === '4') {
            text2 = (
              <div title={tooltip}>
                <Link
                  to={{ pathname: `/deliverables/id/${deliverableData[i].deliverableID}` }}
                  style={{
                    color: getBackgroundColorValue(deliverableData[i].deliverableStatus),
                    size: '20px',
                    textDecoration: 'line-through',
                    fontWeight: 'bold',
                  }}>
                  {deliverableData[i].deliverableSubtitle}
                </Link>

                <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{deliverableData[i].deliverableTitle}</p>
              </div>
            );
          } else {
            text2 = (
              <div title={tooltip}>
                <Link
                  to={{ pathname: `/deliverables/id/${deliverableData[i].deliverableID}` }}
                  style={{
                    color: getBackgroundColorValue(deliverableData[i].deliverableStatus),
                    size: '20px',
                    textDecoration: 'underline',
                    fontWeight: 'bold',
                  }}>
                  {deliverableData[i].deliverableSubtitle}
                </Link>

                <p style={{ fontWeight: 'bold', textAlign: 'center' }}>{deliverableData[i].deliverableTitle}</p>
              </div>
            );
          }
        }
      } else {
        text2 = (
          <div title={tooltip}>
            <Link
              to={{ pathname: `/deliverables/id/${deliverableData[i].deliverableID}` }}
              style={{ color: 'black', size: '20px', textDecoration: 'underline', fontWeight: 'bold' }}>
              {deliverableData[i].deliverableTitle}
            </Link>
          </div>
        );
      }

      const element = { text: text2, color: getBackgroundColorValue(deliverableData[i].deliverableStatus) };
      const monthName = sixMonth['title'];
      monthsBottom3.push(monthName);

      const empty = dataBottom.find((row) => {
        const already = row[monthName];
        return !already;
      });

      if (empty) {
        empty[monthName] = element;
      } else {
        const row = { key: dataBottom.length + 1 };
        row[monthName] = element;
        dataBottom.push(row);
      }
    }
  }

  const columnsBottom = [
    {
      color: '#FF0000',
      children: [
        ...six.map((mon, i) => ({
          ...mon,
          dataIndex: mon.title,
          key: mon.title,
          width: '5%',
          render: (text, name, index) => {
            const children = text && (
              <div
                className="col-bottom"
                id="hoverId">
                <div className="col-bottom1">
                  <p style={{ paddingLeft: '-2px', fontSize: '9px' }}>{text.text}</p>
                </div>
              </div>
            );

            return children;
          },
        })),
      ],
    },
  ];

  const monthsBottom4 = [];
  const dataBottom1 = [{ key: 1 }];
  let element = '';
  for (let i = 0; i < deliverableData.length; i++) {
    const monthsObj = deliverableData[i].monthValue;
    const date = new Date(monthsObj);
    const monthValue = date.getMonth();
    const yearValue = date.getFullYear();
    const restMonth = rest.find((month) => {
      const rightMonth = month.month === monthValue;
      const rightYear = month.year === yearValue;

      return rightYear && rightMonth;
    });

    if (restMonth) {
      const deliverable = deliverableData[i];
      const tooltip =
        deliverable &&
        ` ${t('proggetiPage.owner')}:  ${deliverable.fullName} \n ${t('general.stato')}:  ${STATUS1[deliverable.status - 1]} \n ${t('general.start')}:  ${moment(deliverable.startDate).format('DD/MM/YYYY')} \n ${t('general.end')}: ${moment(deliverable.endDate).format('DD/MM/YYYY')}`;
      if (deliverableData[i].status === '4') {
        const text2 = (
          <div title={tooltip}>
            <Link
              to={{ pathname: `/deliverables/id/${deliverableData[i].deliverableID}` }}
              style={{
                color: getBackgroundColorValue(deliverableData[i].deliverableStatus),
                size: '20px',
                textDecoration: 'line-through',
                fontWeight: 'bold',
              }}>
              {deliverableData[i].deliverableSubtitle}
            </Link>

            <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{deliverableData[i].deliverableTitle}</p>
          </div>
        );
        element = { text: text2, color: getBackgroundColorValue(deliverableData[i].deliverableStatus) };
      } else {
        const text2 = (
          <div title={tooltip}>
            <Link
              to={{ pathname: `/deliverables/id/${deliverableData[i].deliverableID}` }}
              style={{
                color: getBackgroundColorValue(deliverableData[i].deliverableStatus),
                size: '20px',
                textDecoration: 'underline',
                fontWeight: 'bold',
              }}>
              {deliverableData[i].deliverableSubtitle}
            </Link>

            <p style={{ fontWeight: 'bold', textAlign: 'left' }}>{deliverableData[i].deliverableTitle}</p>
          </div>
        );
        element = { text: text2, color: getBackgroundColorValue(deliverableData[i].deliverableStatus) };
      }
      const monthName = restMonth['title'];
      monthsBottom4.push(monthName);
      const empty = dataBottom1.find((row) => !row[monthName]);

      if (empty) {
        empty[monthName] = element;
      } else {
        const row = { key: dataBottom1.length + 1 };
        row[monthName] = element;
        dataBottom1.push(row);
      }
    }
  }

  const monthIndexes3 = monthsBottom4.map((month2) => {
    const index = months.findIndex((month) => {
      const match = month.title === month2;
      return match;
    });
    return index;
  });

  const min3 = Math.min(...monthIndexes3);
  const max3 = Math.max(...monthIndexes3);
  const monthsColumns3 = months.slice(min3, max3 + 1);
  const monthsColumns4 = [];
  monthsColumns3.forEach((t, index) => {
    if (index + 6 === currentMonth) {
      const title = <div className="highlight">{t.title}</div>;
      monthsColumns4.push({ title: title, key: t.key });
    } else {
      monthsColumns4.push({ title: t.title, key: t.key });
    }
  });

  const columnsBottom1 = [
    {
      children: [
        ...rest.map((mon) => {
          return {
            ...mon,
            dataIndex: mon.title,
            key: mon.title,
            render: (text, name, index) => {
              const children = text && (
                <div
                  className="col-bottom"
                  id="hoverId">
                  <div className="col-bottom1">
                    <p style={{ marginLeft: '5px', fontSize: '9px' }}>{text.text}</p>
                  </div>
                </div>
              );

              return children;
            },
          };
        }),
      ],
    },
  ];

  const width = columnsBottom1[0].children.length / 6;
  const percent = `${width * 100}%`;

  return (
    <div className="height600">
      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}
        id="date-filter">
        <DatePicker
          placeholder={t('general.seleziona')}
          picker="year"
          value={selectedYear}
          style={{ width: '30%', marginBottom: '30px' }}
          format="YYYY"
          onChange={onYearChange}
        />
        <Tooltip title={t('general.filter')}>
          <FilterOutlined
            style={{ margin: '0 5px', color: 'grey' }}
            onClick={() => onFilter()}
          />
        </Tooltip>

        <Tooltip title={t('general.clearFilters')}>
          {/* TODO check disabled icon */}
          <MinusSquareOutlined
            style={{ margin: '0 5px', color: selectedYear ? 'grey' : '#ccc' }}
            onClick={() => {
              if (selectedYear) {
                onClearFilter();
              }
            }}
          />
        </Tooltip>

        <Tooltip title={t('a3Page.aggiungiAnno')}>
          <Button
            type="dashed"
            icon={<PlusSquareOutlined />}
            disabled={selectedYear ? false : true}
            onClick={() => handleAddOneYear()}
          />
        </Tooltip>
      </Col>

      <Table
        className="custom-table masterplan"
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
        style={{ overflowX: 'scroll', overflowY: 'scroll' }}
      />
      <div className="reviewSystemPrint">
        <br />
        <h2 className="deliver">{t('a3Page.projectReviewSystem')} </h2>
        <br />
        <Table
          className="custom-table custom-table product-review"
          columns={columnsBottom}
          size="small"
          dataSource={dataBottom}
          pagination={false}
        />

        <Table
          className="custom-table product-review"
          columns={columnsBottom1}
          dataSource={dataBottom1}
          size="small"
          pagination={false}
          style={{ width: percent }}
        />
      </div>
    </div>
  );
};

export default Section2PrintPreview;

import { trackPromise } from 'react-promise-tracker';
import { A3PageApi, KPIApi, ProjectApi, UpdateGoalGroundActA3RequestDto } from 'src/connectors/backend';
import { authenticatedApi } from 'src/config/connectors';

const Section1Service = {
  // get goal around to act a3
  getGoal: async function (projectId: string) {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageGoalGroundtoActA3(projectId));
  },

  getProjectRoutine: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectRoutine(projectId));
  },

  updateSection1: async function (payload: UpdateGoalGroundActA3RequestDto) {
    const api = await authenticatedApi(A3PageApi);

    return trackPromise(api.a3PageUpdateGoalGroundtoActA3(payload));
  },

  getKPI: async function (projectId: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPISectionA3(projectId));
  },
};

export default Section1Service;

import { Form } from 'antd';
import React, { Component } from 'react';

import { DeleteOutlined, SelectOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Modal, Popconfirm, Row, Select } from 'antd';
import { Link } from 'react-router-dom';
import { ModalFooterButtons } from '../../../shared/components/modal/modalFooterButtons';
import { formItemLayout } from '../../../shared/utils/constants';
import { requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';

const { Option } = Select;

class EditYearObjectiveModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      openModal: false,
      statusList: null,
    };
  }

  updateData = (values, id) => {
    this.props.handleSave(values, id);
  };

  removeAnualGoal = (id) => {
    this.props.removeAnualGoal(id);
  };

  render() {
    const { show, handleModalCancel, data, loadingButton, goalStatusList, t, selectedXmatrix } = this.props;

    return (
      <Modal
        className="edit_ob_modal"
        width={800}
        title={`${data.title}`}
        open={show}
        destroyOnClose={true}
        onCancel={handleModalCancel}
        footer={[
          <div key="buttonsObAnno">
            <Link
              to={{
                pathname: `/obiettiviannuali/details/id/${data.annualGoalID}/2/${selectedXmatrix}`,
              }}>
              <Button
                type="dashed"
                icon={<SelectOutlined />}
              />
            </Link>

            <ModalFooterButtons
              disabled={!this.state.fieldsChanged}
              loadingButton={loadingButton}
              modalClose={handleModalCancel}
              formId={'editObAnnoXmatrix'}
            />
          </div>,
        ]}>
        <Card>
          <Form
            id="editObAnnoXmatrix"
            name="edit_ob_anno"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.updateData(values, data.annualGoalID)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row mod-buttons"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Popconfirm
                  title={t('obiettiviAnnoPage.eliminareObjAnnoXmatrix')}
                  icon={<WarningOutlined />}
                  okText={t('general.si')}
                  cancelText={t('general.no')}
                  onConfirm={() => this.removeAnualGoal(data.annualGoalID)}>
                  <Button
                    style={{ marginBottom: 10 }}
                    loading={loadingButton}
                    icon={<DeleteOutlined />}
                    type="dashed">
                    {t('buttons.rimozioneObiettivoAnnuale')}
                  </Button>
                </Popconfirm>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  label={t('general.nome')}
                  name="description"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={data.title}>
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}>
                <Form.Item
                  label={t('general.stato')}
                  name="status"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={data.status}>
                  <Select
                    style={{ width: '100%' }}
                    label={t('general.seleziona')}>
                    {goalStatusList &&
                      goalStatusList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}>
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default EditYearObjectiveModal;

import { Tooltip } from 'antd';
import { RiFlashlightFill } from 'react-icons/ri';
import { GradienterIcon } from '../../../../../../assets/icons';
import { FlagOutlined } from '@ant-design/icons';

export const TaskIcon = () => {
  return (
    <Tooltip title="Task">
      <div className="tw-size-6 tw-shrink-0 tw-rounded-md tw-bg-teal-500 tw-flex tw-items-center tw-justify-center">
        <RiFlashlightFill color="white" />
      </div>
    </Tooltip>
  );
};

export const DeliverableIcon = () => {
  return (
    <Tooltip title="Deliverable">
      <div className="tw-size-6 tw-shrink-0 tw-rounded-md tw-bg-lime-500 tw-flex tw-items-center tw-justify-center">
        <GradienterIcon />
      </div>
    </Tooltip>
  );
};

export const PhaseIcon = () => {
  return (
    <Tooltip title="Fase">
      <div className="tw-size-6 tw-shrink-0 tw-rounded-md tw-flex tw-bg-purple-500 tw-items-center tw-justify-center">
        <FlagOutlined style={{ color: 'white' }} />
      </div>
    </Tooltip>
  );
};

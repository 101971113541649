import { Select, message } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { EyeLineIcon, FlagLineIcon, HeartLineIcon } from '../../../../../assets/icons';
import UsersService from '../../../../../services/pages/usersService';
import { userRolesCodes } from '../../../../shared/utils/constants';
import { notifyMessages } from '../../../../shared/utils/notifyMessages';
import '../header.scss';

const UserNameCompany = (props) => {
  const [companyList, setCompanyList] = useState([]);

  const userData = useSelector((state) => state.userData.userData);
  const [loadingIcon, setLoadingIcon] = useState(false);

  const { companyData } = props;
  const { t } = useTranslation();

  const [companyPanelHidden, setCompanyPanelHidden] = useState(true);

  let hoverTimeout;

  useEffect(() => {
    generateCompanyList();
  }, [userData]);

  const checkIfUserHasRoles = (user) => {
    const roles = [
      userRolesCodes.techAdmin,
      userRolesCodes.lenovysAdmin,
      userRolesCodes.externalReviewer,
      userRolesCodes.teamMember,
      userRolesCodes.teamLeader,
      userRolesCodes.steering,
      userRolesCodes.companyAdmin,
    ];
    if (user) {
      return roles.indexOf(user.roleCode) > -1;
    }
  };

  const onCompanySelect = (companyId) => {
    let payload = {};
    payload['userID'] = userData?.userId;
    payload['companyID'] = companyId;

    setLoadingIcon(true);
    UsersService.updateUserLogedInCompany(payload)
      .then((response) => response?.data)
      .then((resp) => {
        if (resp?.success) {
          // message.success(notifyMessages.changeCompanySuccess);
          setLoadingIcon(false);
          window.location.replace('/');
        } else {
          message.error(notifyMessages.changeCompanyFailed);
          setLoadingIcon(false);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.changeCompanyFailed);
        setLoadingIcon(false);
      });
  };

  // const userCompaniesContent = () => {
  //   let items = [];
  //   if (userData && userData !== undefined) {
  //     userData.userCompanies.map((item, index) =>
  //       items.push({
  //         label: (
  //           <Text
  //             key={item.companyID}
  //             onClick={() => onCompanySelect(item.companyID)}>
  //             {loadingIcon && item.isLastLoggedInCompany === 1 && (
  //               <SyncOutlined
  //                 style={{ color: 'white' }}
  //                 spin
  //               />
  //             )}
  //             {item.isLastLoggedInCompany === 1 && <CheckOutlined style={{ color: '#48cc05', marginRight: 5 }} />}
  //             {item.companyName}
  //           </Text>
  //         ),
  //         key: index,
  //       }),
  //     );
  //     return { items };
  //   }
  // };

  // const userCompanies = (data) => {
  //   return (
  //     <Dropdown menu={userCompaniesContent()}>
  //       <a
  //         className="ant-dropdown-link"
  //         onClick={(e) => e.preventDefault()}>
  //         {data.filter((obj) => obj.isLastLoggedInCompany === 1)[0].companyName} <DownOutlined />
  //       </a>
  //     </Dropdown>
  //   );
  // };

  const hasRole = checkIfUserHasRoles(userData);

  const generateCompanyList = () => {
    let comList = [];

    if (userData && userData !== undefined && Object.keys(userData).length > 0) {
      userData.userCompanies.map((item, index) =>
        comList.push({
          label: item.companyName,
          value: item.companyID,
        }),
      );
      setCompanyList(comList);
    }
  };

  const labelRender = (props) => {
    const { label, value } = props;

    if (label) {
      return (
        <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
          <div className="company-info tw-size-6 tw-flex tw-items-center tw-justify-center tw-shrink-0 tw-rounded tw-border tw-border-blue-900">
            <img
              className="tw-box-border tw-bg-white tw-size-full tw-object-contain tw-bg-no-repeat"
              alt="company logo"
              src={
                userData.userCompanies?.find((c) => c.companyID === value)?.companyLogoUrl ??
                companyData?.logo ??
                'not-found'
              }
              onError={(e) => {
                e.target.classList.add('!tw-hidden');
              }}
            />
          </div>
          <span className="tw-truncate !tw-text-white">{label}</span>
        </div>
      );
    } else {
      return <div></div>;
    }
  };

  return (
    <>
      {hasRole && (
        <Select
          onMouseEnter={() => {
            hoverTimeout = setTimeout(() => {
              setCompanyPanelHidden(false);
            }, 1500);
          }}
          // loading={loadingIcon}
          onMouseLeave={() => {
            clearTimeout(hoverTimeout);
            setCompanyPanelHidden(true);
          }}
          onClick={() => {
            clearTimeout(hoverTimeout);
            setCompanyPanelHidden(true);
          }}
          className="tw-w-44 company-select"
          showSearch
          listHeight={700}
          labelRender={labelRender}
          defaultValue={userData.lastLoggedInCompanyID}
          optionFilterProp="children"
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          options={companyList}
          onChange={(value) => onCompanySelect(value)}
        />
      )}
      {!hasRole && userData?.companyName}

      <div
        className={classNames('company-blurred-panel tw-fixed tw-z-[999] tw-top-16 tw-transition-all', {
          'tw-hidden': companyPanelHidden,
        })}>
        <div className="tw-flex tw-flex-col tw-divide-y tw-pb-3">
          <div className="tw-px-4 [&_.company-info>img]:!tw-bg-none [&_.company-info]:!tw-border-zinc-400 [&_.company-info]:!tw-size-8">
            {labelRender({ label: companyData?.companyName, value: companyData?.companyID })}
          </div>
          <div className="tw-flex tw-flex-col tw-gap-6 tw-px-4 tw-py-3">
            <div className="tw-flex tw-flex-col tw-gap-2">
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-leading-6 tw-text-lg tw-font-semibold">
                <EyeLineIcon />
                {t('general.vision')}
              </div>

              <div className="tw-leading-5 tw-ml-7 tw-line-clamp-4 tw-text-sm">{companyData?.vision ?? '-'}</div>
            </div>

            <div className="tw-flex tw-flex-col tw-gap-2">
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-leading-6 tw-text-lg tw-font-semibold">
                <FlagLineIcon />
                {t('general.mission')}
              </div>

              <div className="tw-leading-5 tw-ml-7 tw-line-clamp-4 tw-text-sm">{companyData?.mission ?? '-'}</div>
            </div>

            <div className="tw-flex tw-flex-col tw-gap-2">
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-3 tw-leading-6 tw-text-lg tw-font-semibold">
                <HeartLineIcon />
                {t('general.values')}
              </div>

              <div className="tw-leading-5 tw-ml-7 tw-line-clamp-4 tw-text-sm">{companyData?.values ?? '-'}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UserNameCompany;

import moment from 'moment';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './App';
import { LocaleProvider } from './contexts/LocaleContext';
import './i18n';
import { store } from './redux/store';
import reportWebVitals from './reportWebVitals';
import './styles/dist/tailwind-built.min.css';
import './styles/index.scss';

moment.locale('en_US', {
  week: { dow: 1 },
});

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <React.StrictMode>
    <Suspense>
      <Provider store={store}>
        <LocaleProvider>
          <App />
        </LocaleProvider>
      </Provider>
    </Suspense>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { BorderInnerOutlined, ContainerOutlined, FolderOpenOutlined } from '@ant-design/icons';
import { Button, Space, Table, Tooltip } from 'antd';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import UsersService from '../../services/pages/usersService';

class UserComities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingComities: false,
      userCommities: null,
    };
  }

  componentDidMount() {
    this.retrieveComponentData();
  }

  retrieveComponentData = async () => {
    await this.retrieveUserCommities();
  };

  async retrieveUserCommities() {
    const { userData, userId } = this.props;

    if (userData && Object.keys(userData).length > 0) {
      this.setState({ loadingComities: true });
      await UsersService.getUserCommities(userId)
        .then((response) => response.data)
        .then((resp) => {
          if (resp.success) {
            this.setState({ userCommities: resp.responseObject.value });
            this.setState({ loadingComities: false });
          } else {
            this.setState({ loadingComities: false });
          }
        })
        .catch((error) => {
          this.setState({ loadingComities: false });
        });
    }
  }

  render() {
    const { userCommities, loadingComities } = this.state;
    let t = this.props.t;

    const userCommitteColumns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'committeeName',
        ellipsis: {
          showTitle: true,
        },
        sorter: (a, b) => {
          return a.committeeName.localeCompare(b.committeeName);
        },
        showSorterTooltip: [true],
        defaultSortOrder: 'ascend',
        render: (text, record) => (
          <Link
            to={{ pathname: `/committieDas/id/${record.committeeID}` }}
            style={{ color: '#323232' }}>
            {record.committeeName}
          </Link>
        ),
      },
      {
        key: 'action3',
        width: '50px',
        render: (text, record) => (
          <Space>
            <Tooltip title={t('dashboardProgettiPage.dashboardProgetti')}>
              <Button
                icon={<BorderInnerOutlined />}
                href={`/progettihoshinkanri/${record.committeeID}`}></Button>
            </Tooltip>
          </Space>
        ),
      },
      {
        key: 'action2',
        width: '50px',
        render: (text, record) => (
          <Space>
            <Tooltip title={t('comitatiPage.goToComitieDashboard')}>
              <Button
                icon={<FolderOpenOutlined />}
                href={`/committieDas/id/${record.committeeID}`}></Button>
            </Tooltip>
          </Space>
        ),
      },
      {
        key: 'action2',
        width: '50px',
        render: (text, record) => {
          return (
            <>
              {record.repositoryLink && record.repositoryLink !== '' && (
                <Space>
                  <Tooltip title={t('comitatiPage.repositoryLink')}>
                    <Button
                      href={record.repositoryLink}
                      target="_blank"
                      icon={<ContainerOutlined />}
                      rel="noreferrer"></Button>
                  </Tooltip>
                </Space>
              )}
            </>
          );
        },
      },
    ];

    return (
      <Table
        columns={userCommitteColumns}
        dataSource={userCommities}
        rowKey={(obj) => obj.committeeID}
        size="small"
        loading={loadingComities}
        pagination={{ pageSize: 5 }}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(UserComities));

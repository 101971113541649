import { FilterOutlined, MinusSquareOutlined } from '@ant-design/icons';
import { Button, Select } from 'antd';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import MatrixService from '../../../services/matrix/matrixService';
import '../matrix.scss';
import XMatrixHeader from '../xMatrixHeader';
import XMatrixLeftPanel from '../xMatrixLeftPanel/xMatrixLeftPanel';
import XmatrixOptions from '../xmatrixOptions';

const { Option } = Select;

class SecondLevelFiltersMatrixLocal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filterHasValue: false,
      selectedXmatrixValue: props.selectedXmatrix.xMatrixID,
      annualGoalsPicklist: [],
      teamLeaderPicklist: [],
      committiePicklist: [],
      projectPicklist: [],
      sponsorPicklist: [],
      secondaryProjectPicklist: [],
      selectedFiltersLabelList: [],
    };
  }

  componentDidMount() {
    const { selectedXmatrixInfo } = this.props;
    let localFiltersData = JSON.parse(localStorage.getItem('xmFiltersL2'));
    let xmatrix = localFiltersData.XMatrixID ? localFiltersData.XMatrixID : selectedXmatrixInfo.xMatrixID;
    this.retrieveFilterComponentsData(xmatrix, localFiltersData);
  }

  retrieveFilterComponentsData = (xmatrix, localFiltersData) => {
    this.retrieveAnnualGoalsPicklist(xmatrix);
    this.retrieveCommitties(xmatrix);
    this.retrieveProjects(xmatrix);
    this.retrieveTeamLeaders();
    this.retrieveSponsors();
    this.retrieveSecondaryProject(xmatrix);
    this.preCheckLocalFilters(localFiltersData);
  };

  preCheckLocalFilters = (localFiltersData) => {
    for (const filter in localFiltersData) {
      if (filter === 'XMatrixID') {
        continue;
      } else if (localFiltersData[filter].length && localFiltersData[filter].length > 0) {
        let filterObj = { value: filter, label: this.checkFilterLabelName(filter) };
        this.addItemToFilterLabelList(filterObj);
      } else if (localFiltersData[filter] === true) {
        let filterObj = { value: filter, label: this.checkFilterLabelName(filter) };
        this.addItemToFilterLabelList(filterObj);
      } else {
        continue;
      }
    }
  };

  handleSubmit = () => {
    this.props.manageFilterApplied();
  };

  handelClear = () => {
    this.setState({ selectedFiltersLabelList: [] });
    this.props.manageFilterReset();
  };

  async retrieveAnnualGoalsPicklist(xmatrix) {
    await MatrixService.getAnnualGoalsXmatrixFilter(xmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ annualGoalsPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveCommitties(xmatrix) {
    await MatrixService.getCommittieXmatrixFilter(xmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ committiePicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveProjects(xmatrix) {
    await MatrixService.getProjectXmatrixFilter(xmatrix)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projectPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveTeamLeaders() {
    const { selectedXmatrix } = this.props;
    await MatrixService.getTeamLaderSecondLevelFilterData(selectedXmatrix.divisionID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ teamLeaderPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveSponsors() {
    const { selectedXmatrix } = this.props;
    await MatrixService.getSponsorSecondLevelFilterData(selectedXmatrix.divisionID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ sponsorPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveSecondaryProject(xmatrix) {
    const { selectedXmatrix } = this.props;
    await MatrixService.getSecondaryProjectSecondLevelFilterData(xmatrix, selectedXmatrix.divisionID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ secondaryProjectPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  checkFilterExistOnFilterLabelsList = (filterObj) => {
    let { selectedFiltersLabelList } = this.state;
    const found = selectedFiltersLabelList.some((el) => el.value === filterObj.value);
    return found;
  };

  addItemToFilterLabelList = (object) => {
    let { selectedFiltersLabelList } = this.state;
    if (!this.checkFilterExistOnFilterLabelsList(object)) {
      selectedFiltersLabelList.push(object);
    }
  };

  onFilterOptionsSelect = (values, filterName, filterLabel) => {
    if (values && values.length < 1) {
      this.removeSelectedFilter(filterLabel.value);
    }
    this.addItemToFilterLabelList(filterLabel);
    const filterObj = { filter: filterName, value: values };
    this.props.manageFiltersChanged(filterObj);
  };

  onFilterCheckOptionsSelect = (values, filterName, filterLabel) => {
    if (!values) {
      this.removeSelectedFilter(filterLabel.value);
    }
    this.addItemToFilterLabelList(filterLabel);
    const filterObj = { filter: filterName, value: values };
    this.props.manageFiltersChanged(filterObj);
  };

  clearSelectedFilterOnLabelList = (filterName) => {
    let { selectedFiltersLabelList } = this.state;
    if (filterName || filterName.length > 0) {
      let clearedFilteredList = selectedFiltersLabelList.filter(function (el) {
        return el.value !== filterName;
      });
      this.setState({ selectedFiltersLabelList: clearedFilteredList });
    }
  };

  checkFilterLabelName = (filter) => {
    const { t } = this.props;
    switch (filter) {
      case 'secondaryProject':
        return t('xMatrixPage.iniziative');
      case 'annualGoalId':
        return t('general.obbietiviAnuali');
      case 'projectID':
        return t('general.progetti');
      case 'committeeId':
        return t('general.comitato');
      case 'teamLeaderId':
        return t('general.teamLeader');
      case 'sponsorId':
        return t('general.sponsor');
      case 'includeSubProject':
        return t('xMatrixPage.includiSottoprogetti');
      case 'showAllUsers':
        return t('xMatrixPage.mostraTuitiUtenti');
      default:
        return '-';
    }
  };

  removeSelectedFilter = (filter) => {
    switch (filter) {
      case 'annualGoalId':
        const annualGoalFilter = { filter: 'annualGoalId', value: [] };
        this.props.manageFiltersChanged(annualGoalFilter);
        break;
      case 'projectID':
        const projectFilter = { filter: 'projectID', value: [] };
        this.props.manageFiltersChanged(projectFilter);
        break;
      case 'committeeId':
        const commitieFilter = { filter: 'committeeId', value: [] };
        this.props.manageFiltersChanged(commitieFilter);
        break;
      case 'secondaryProject':
        const secondaryProjectFilter = { filter: 'secondaryProject', value: [] };
        this.props.manageFiltersChanged(secondaryProjectFilter);
        break;
      case 'teamLeaderId':
        const temLeaderFilter = { filter: 'teamLeaderId', value: [] };
        this.props.manageFiltersChanged(temLeaderFilter);
        break;
      case 'sponsorId':
        const sponsorFilter = { filter: 'sponsorId', value: [] };
        this.props.manageFiltersChanged(sponsorFilter);
        break;
      case 'showAllUsers':
        const allUsersFilter = { filter: 'showAllUsers', value: false };
        this.props.manageFiltersChanged(allUsersFilter);
        break;
      case 'includeSubProject':
        const subProjectsFilter = { filter: 'includeSubProject', value: false };
        this.props.manageFiltersChanged(subProjectsFilter);
        break;
      default:
        break;
    }

    this.clearSelectedFilterOnLabelList(filter);
    this.handleSubmit();
  };

  render() {
    const {
      filterHasValue,
      annualGoalsPicklist,
      teamLeaderPicklist,
      projectPicklist,
      sponsorPicklist,
      secondaryProjectPicklist,
      committiePicklist,
      selectedFiltersLabelList,
    } = this.state;
    const {
      loadingButton1,
      loadingButton2,
      t,
      reloadMatrix,
      selectedXmatrixInfo,
      isSecondLevelXmatrix,
      activeXmatrixInfo,
      loadingRootList,
      xmatrixRootList,
      reloadMatrixOnEdit,
      reloadXmInfo,
      filters,
      xmatrixData,
      userData,
    } = this.props;

    return (
      <>
        <div className="tw-flex tw-flex-col tw-w-full tw-shrink-0">
          <div className="tw-flex tw-py-2 tw-px-2 tw-border-b tw-bg-white tw-w-full tw-gap-8 tw-justify-between tw-items-center">
            <div className="tw-flex tw-gap-2 tw-items-center tw-shrink-0">
              <XMatrixLeftPanel
                activeXmatrixInfo={activeXmatrixInfo}
                loadingRootList={loadingRootList}
                xmatrixRootList={xmatrixRootList}
                t={t}
                localFilters={filters}
                userData={userData}
              />
              <XMatrixHeader
                reloadMatrix={reloadMatrix}
                selectedXmatrixInfo={selectedXmatrixInfo}
                isSecondLevelXmatrix={isSecondLevelXmatrix}
                t={t}
              />
            </div>

            <div className="tw-flex tw-items-center tw-flex-1 tw-justify-end tw-gap-2">
              <Select
                value={filters && filters.annualGoalId ? filters.annualGoalId : []}
                style={{ width: '100%' }}
                showSearch
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                placeholder={t('general.obbietiviAnuali')}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(selectedRowKeys) =>
                  this.onFilterOptionsSelect(selectedRowKeys, 'annualGoalId', {
                    value: 'annualGoalId',
                    label: t('general.obbietiviAnuali'),
                  })
                }>
                {annualGoalsPicklist &&
                  annualGoalsPicklist.map((item) => (
                    <Option
                      value={item.annualGoalId}
                      key={item.annualGoalId}>
                      {item.description}
                    </Option>
                  ))}
              </Select>
              <Select
                value={filters && filters.projectID ? filters.projectID : []}
                style={{ width: '100%' }}
                showSearch
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                placeholder={t('xMatrixPage.iniziative')}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(selectedRowKeys) =>
                  this.onFilterOptionsSelect(selectedRowKeys, 'projectID', {
                    value: 'projectID',
                    label: t('general.progetti'),
                  })
                }>
                {projectPicklist &&
                  projectPicklist.map((item) => (
                    <Option
                      value={item.projectID}
                      key={item.projectID}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
              <Select
                value={filters && filters.committeeId ? filters.committeeId : []}
                style={{ width: '100%' }}
                showSearch
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                placeholder={t('general.comitato')}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(selectedRowKeys) =>
                  this.onFilterOptionsSelect(selectedRowKeys, 'committeeId', {
                    value: 'committeeId',
                    label: t('general.comitato'),
                  })
                }>
                {committiePicklist &&
                  committiePicklist.map((item) => (
                    <Option
                      disabled={item.hasAccess === 1 ? false : true}
                      value={item.committeeID}
                      key={item.committeeID}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
              <Select
                value={filters && filters.secondaryProject ? filters.secondaryProject : []}
                style={{ width: '100%' }}
                showSearch
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                placeholder={t('xMatrixPage.progettiMiglioramenti')}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(selectedRowKeys) =>
                  this.onFilterOptionsSelect(selectedRowKeys, 'secondaryProject', {
                    value: 'secondaryProject',
                    label: t('xMatrixPage.iniziative'),
                  })
                }>
                {secondaryProjectPicklist &&
                  secondaryProjectPicklist.map((item) => (
                    <Option
                      value={item.projectID}
                      key={item.projectID}>
                      {item.name}
                    </Option>
                  ))}
              </Select>
              <Select
                value={filters && filters.teamLeaderId ? filters.teamLeaderId : []}
                style={{ width: '100%' }}
                showSearch
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                placeholder={t('general.teamLeader')}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(selectedRowKeys) =>
                  this.onFilterOptionsSelect(selectedRowKeys, 'teamLeaderId', {
                    value: 'teamLeaderId',
                    label: t('general.teamLeader'),
                  })
                }>
                {teamLeaderPicklist &&
                  teamLeaderPicklist.map((item) => (
                    <Option
                      value={item.userID}
                      key={item.userID}>
                      {item.fullName}
                    </Option>
                  ))}
              </Select>
              <Select
                value={filters && filters.sponsorId ? filters.sponsorId : []}
                style={{ width: '100%' }}
                showSearch
                mode="multiple"
                maxTagCount="responsive"
                allowClear
                placeholder={t('general.sponsor')}
                optionFilterProp="children"
                filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                onChange={(selectedRowKeys) =>
                  this.onFilterOptionsSelect(selectedRowKeys, 'sponsorId', {
                    value: 'sponsorId',
                    label: t('general.sponsor'),
                  })
                }>
                {sponsorPicklist &&
                  sponsorPicklist.map((item) => (
                    <Option
                      value={item.userID}
                      key={item.userID}>
                      {item.fullName}
                    </Option>
                  ))}
              </Select>
              {/* <div>
                <span>{t('xMatrixPage.includiSottoprogetti')}</span>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={filters && filters.includeSubProject ? filters.includeSubProject : false}
                  onChange={(checked) =>
                    this.onFilterCheckOptionsSelect(checked, 'includeSubProject', {
                      value: 'includeSubProject',
                      label: t('xMatrixPage.includiSottoprogetti'),
                    })
                  }
                />
              </div> */}
              {/* <div>
                <span>{t('xMatrixPage.mostraTuitiUtenti')}</span>
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                  checked={filters && filters.showAllUsers ? filters.showAllUsers : false}
                  onChange={(checked) =>
                    this.onFilterCheckOptionsSelect(checked, 'showAllUsers', {
                      value: 'showAllUsers',
                      label: t('xMatrixPage.mostraTuitiUtenti'),
                    })
                  }
                />
              </div> */}
              <div className="tw-flex tw-items-center tw-gap-2">
                <Button
                  loading={loadingButton1}
                  disabled={!filterHasValue ? false : true}
                  type="primary"
                  icon={<FilterOutlined />}
                  onClick={() => this.handleSubmit()}>
                  {/* {t('general.filter')} */}
                </Button>
                <Button
                  loading={loadingButton2}
                  icon={<MinusSquareOutlined />}
                  disabled={!filterHasValue ? false : true}
                  onClick={() => this.handelClear()}>
                  {/* {t('general.clearFilters')} */}
                </Button>
              </div>
              <XmatrixOptions
                reloadMatrix={reloadMatrix}
                selectedXmatrixInfo={selectedXmatrixInfo}
                isSecondLevelXmatrix={isSecondLevelXmatrix}
                t={t}
                reloadMatrixOnEdit={reloadMatrixOnEdit}
                reloadXmInfo={reloadXmInfo}
                xmatrixData={xmatrixData}
              />
            </div>
          </div>

          {/* <div className="fixed-grey-container">
            <Col
              className="gutter-row"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              {selectedFiltersLabelList.map((item) => (
                <Button
                  type="text"
                  className="fixed-grey-button"
                  key={item.value}>
                  {item.label} <CloseOutlined onClick={() => this.removeSelectedFilter(item.value)} />
                </Button>
              ))}
            </Col>
          </div> */}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default connect(mapStateToProps)(SecondLevelFiltersMatrixLocal);

import { EditFilled, FolderOpenOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Input, Space, Table, Tooltip, message } from 'antd';
import React from 'react';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import CommitieService from '../../services/pages/commitieServices';
import Loader from '../shared/components/loader/loader';
import TableLayout from '../shared/tableLayout';
import { userRolesCodes } from '../shared/utils/constants';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './commitie.scss';

class CommitieListPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchedColumn: '',
      committieList: null,
      adminRoles: [userRolesCodes.techAdmin, userRolesCodes.lenovysAdmin, userRolesCodes.companyAdmin],
    };
  }

  componentDidMount() {
    this.retrieveCommitieList();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',

    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  async retrieveCommitieList() {
    let withSecurity = this.state.adminRoles?.includes(this.props.currentUser.roleCode) ? false : true;
    await CommitieService.getCommitiesList(withSecurity)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ committieList: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  }

  onNew = () => {
    this.props.history.push('/committie/new');
  };

  render() {
    const { committieList, adminRoles } = this.state;
    let t = this.props.t;

    const { currentUser } = this.props;

    const columns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'name',
        ellipsis: {
          showTitle: true,
        },
        width: '100%',
        sorter: (a, b) => {
          return a.name.localeCompare(b.name);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('name'),
        render: (text, record) => (
          <Link
            to={{ pathname: `/committie/id/${record.committeeID}` }}
            style={{ color: '#323232' }}>
            {record.name}
          </Link>
        ),
      },

      {
        key: 'action',
        width: '100px',
        render: (text, record) => (
          <div className="tw-flex tw-items-center tw-gap-2">
            {/* <Tooltip title={t('dashboardProgettiPage.dashboardProgetti')}>
              <Button
                type="text"
                icon={<OrderedListOutlined />}
                href={`/progettihoshinkanri/${record.committeeID}`}></Button>
            </Tooltip> */}
            <Tooltip title={t('comitatiPage.goToComitieDashboard')}>
              <Button
                type="ghost"
                icon={<FolderOpenOutlined />}
                href={`/committieDas/id/${record.committeeID}`}></Button>
            </Tooltip>
            {adminRoles?.includes(currentUser?.roleCode) && (
              <Button
                icon={<EditFilled />}
                href={`/committie/id/${record.committeeID}`}></Button>
            )}
          </div>
        ),
      },
    ];

    return (
      <>
        <Loader />
        {committieList && (
          <Card>
            <TableLayout title={t('comitatiPage.listaComitati')}>
              <TableLayout.Actions>
                {adminRoles?.includes(currentUser?.roleCode) && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={this.onNew}>
                    <PlusOutlined />
                    {t('buttons.aggiungiNuovo')}
                  </Button>
                )}
              </TableLayout.Actions>
              <TableLayout.Content>
                <Table
                  className="table-height"
                  columns={columns}
                  size="small"
                  dataSource={committieList}
                  rowKey={(obj) => obj.committeeID}
                  pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
                />
              </TableLayout.Content>
            </TableLayout>
          </Card>
        )}
      </>
    );
  }
}

export default withTranslation()(CommitieListPage);

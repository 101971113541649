import { RightOutlined } from '@ant-design/icons';
import { Button, Card, Typography } from 'antd';
import React from 'react';
import notFoundImg from '../../../../assets/notFound.png';

const { Paragraph } = Typography;

class NF404 extends React.Component {
  render() {
    return (
      <div className="tw-h-screen tw-fixed tw-bg-white tw-inset-0 tw-z-[9999] tw-flex tw-items-center tw-justify-center">
        <Card className="tw-max-w-[450px]">
          <div className="tw-flex tw-items-center tw-justify-center tw-flex-col">
            <div>
              <Typography.Title level={2}>404 - Pagina non trovata</Typography.Title>
              <Paragraph>
                Sorry but the page you are looking for does not exist, have been removed, name changed or is temporarily
                unavailable!
              </Paragraph>
              <Button
                href="/"
                iconPosition="end"
                type="primary"
                icon={<RightOutlined />}>
                Torna alla home
              </Button>
            </div>

            <img
              className="tw-mt-8 tw-max-w-[200px]"
              src={notFoundImg}
              alt="Page not found"
            />
          </div>
        </Card>
      </div>
    );
  }
}

export default NF404;

import { trackPromise } from 'react-promise-tracker';
import i18n from '../../../i18n';
import { DeliverableApi, InsertKPIProjectRequestDto, KPIApi, ProjectApi, UpdateGlobalKPIBowlingChartRequestDto, UpdateKPIBowlingChartRequestDto } from 'src/connectors/backend';
import { authenticatedApi } from 'src/config/connectors';

const DeliverableService = {
  getProjectDeliverables: async function (projectId: string) {
    const currentLanguageCode = i18n.language;
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectDeliverable(projectId, currentLanguageCode));
  },

  getProjectKpi: async function (projectId: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIByProject(projectId));
  },

  // add new kpi to project
  insertKpiProject: async function (payload: InsertKPIProjectRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectInsertKPIProject(payload));
  },

  // get project kpi bowling chart
  getKpiBowlingChart: async function (kpiProjectRelationShipId: string, projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetKPIBowlingChart(kpiProjectRelationShipId, projectId));
  },

  getGlobalKpiBowlingChart: async function (kpiDetailId: string, year: string, isRollup: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetGlobalKPIBowlingChart(kpiDetailId, year, isRollup));
  },

  updateKpiBowlingChart: async function (payload: UpdateKPIBowlingChartRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateKPIBowlingChart(payload));
  },

  updateGlobalKpiBowlingChart: async function (payload: UpdateGlobalKPIBowlingChartRequestDto) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateGlobalKPIBowlingChart(payload));
  },

  getDeliverableProjectPicklist: async function (projectId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetDeliverableProjectPicklist(projectId));
  },

  getParentDeliverableProjectPicklist: async function (projectId: string, deliverableId: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetProjectDeliverableParentPicklist(projectId, deliverableId));
  },

  // delete a kpi
  removeKpi: async function (kpiProjectRelationShipID: string) {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectDeleteKPIRelationshipBowlingChart(kpiProjectRelationShipID));
  },

  // delete deliverable from project
  removeDeliverable: async function (deliverableId: string) {
    const api = await authenticatedApi(DeliverableApi);

    return trackPromise(api.deliverableDeleteDeliverable(deliverableId));
  },

  // get bowling chart data
  getBowlingChartListData: async function (projectId: string, selectedYear: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIBowlingChartListPage(projectId, selectedYear));
  },

  // get annual goal bowling chart data
  getAnnualBowlingChartListData: async function (selectedAnnualGoalId: string, selectedYear: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetAnnualGoalKPIBowlingChartListPage(selectedAnnualGoalId, selectedYear));
  },
};

export default DeliverableService;

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { perStatusDeliverableChartOptions } from '../chartsConfig';

function createSeriesFromPhases(deliverables) {
  const { onTime, late } = deliverables.reduce(
    (acc, d) => {
      if (d.onTime) acc.onTime++;
      if (d.late) acc.late++;
      return acc;
    },
    { onTime: 0, late: 0 },
  );

  return [
    {
      name: 'In tempo',
      y: onTime,
    },
    {
      name: 'In ritardo',
      y: late,
    },
  ];
}

const PerTimePhasesChart = ({ phases }) => {
  const [perStatusChart, setPerStatusChart] = useState(perStatusDeliverableChartOptions);

  const { t } = useTranslation();

  useEffect(() => {
    setPerStatusChart((prevChart) => {
      const chartOptions = {
        ...prevChart,
        series: [
          {
            name: `${t('general.phases')}`,
            data: createSeriesFromPhases(phases),
          },
        ],
      };

      return chartOptions;
    });
  }, [t, phases]);

  return (
    <>
      {phases.length ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={perStatusChart}
        />
      ) : (
        <p>No data</p>
      )}
    </>
  );
};

export default PerTimePhasesChart;

import { Form } from 'antd';
import React, { useState } from 'react';

import { SelectOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Modal, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import { ModalFooterButtons } from '../../../shared/components/modal/modalFooterButtons';
import { requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';

interface EditLongObjectiveModalProps {
  show: boolean;
  handleModalCancel: () => void;
  data: any;
  loadingButton: boolean;
  selectedXmatrix: any;
  handleSave: (values: any, id: any) => void;
}

const EditLongObjectiveModal: React.FC<EditLongObjectiveModalProps> = ({
  show,
  handleModalCancel,
  data,
  loadingButton,
  selectedXmatrix,
  handleSave,
}) => {
  const [fieldsChanged, setFieldsChanged] = useState<boolean>(false);
  const { t } = useTranslation();

  const updateData = (values: any, id: any) => {
    handleSave(values, id);
  };

  return (
    <Modal
      className="edit_ob_modal"
      width={800}
      title={`${data.title}`}
      open={show}
      destroyOnClose={true}
      onCancel={handleModalCancel}
      footer={
        <div className="tw-flex tw-justify-between tw-w-full">
          <Button
            href={`/obiettivialungoperiodo/details/id/${data.longTermGoalID}/2/${selectedXmatrix}`}
            type="dashed"
            icon={<SelectOutlined />}
          />

          <ModalFooterButtons
            disabled={!fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleModalCancel}
            formId={'editObLungoXmatrix'}
          />
        </div>
      }>
      <Card>
        <Form
          id="editObLungoXmatrix"
          name="edit_ob_lungo"
          layout="vertical"
          onFinish={(values) => updateData(values, data.longTermGoalID)}
          onValuesChange={() => {
            setFieldsChanged(true);
          }}>
          <Row gutter={{ lg: 24 }}>
            <Col
              className="gutter-row"
              xs={{ span: 24 }}>
              <Form.Item
                label={t('general.nome')}
                name="title"
                rules={[{ required: true, message: (requiredFields as any).required }]}
                initialValue={data.title}>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Card>
    </Modal>
  );
};

export default EditLongObjectiveModal;

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import i18nextHttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

export const languageList = [
  { code: 'it', name: 'Italian' },
  { code: 'en', name: 'English' },
];

const languages = languageList.map((item) => item.code);

i18n
  .use(i18nextHttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: languages,
    lng: i18n.language,
    fallbackLng: 'it',
    interpolation: {
      escapeValue: false,
    },
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
  });

export default i18n;

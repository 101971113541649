import { trackPromise } from 'react-promise-tracker';
import { acquireToken } from '../../config/auth/clientAuthProvider';
import axiosInstance from '../../config/axiosConfig/interceptors';
import {
  authenticatedApi,
  InsertKPIDetailRequestDto,
  InsertUserFavouriteKPIRequest,
  KPIApi,
  UpdateCommentForKPIRequestDto,
  UpdateGlobalKPIBowlingChartImportRequestDto,
  UpdateKPIDetailRequestDto,
  UpdateSpecificAnnualGoalKPIBowlingRequestDto,
  UpdateSpecificKPIBowlingChartImportRequestDto,
  XMatrixApi,
} from 'src/config/connectors';

const KpiService = {
  // get kpi list data
  getKpiList: async function (xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetKPIXMAtrixPicklist(xmatrixId));
  },
  //get to populate kpi table
  getKpiListData: async function () {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIs());
  },

  // get kpi data by id
  getKpiByiD: async function (id: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIDetail(id));
  },

  // update kpi
  updateKpi: async function (updateKpiPayload: UpdateKPIDetailRequestDto) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateKPIDetail(updateKpiPayload));
  },

  // add new kpi
  addKpi: async function (payload: InsertKPIDetailRequestDto) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIInsertKPIDetail(payload));
  },

  //delete kpi
  deleteKPI: async function (id: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIDeleteKPI(id));
  },

  // check if kpi name exist
  checkIfKpiNameExist: async function (name: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPICheckDuplicateKPI(name));
  },

  getKpiSpecificoExcelData: async function (kPIProjectRelationShipID: string, projectID: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID, projectID));
  },

  getKpiGlobaleExcelData: async function (kPIDetailID: string, year: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetGlobalKPIBowlingChartExport(kPIDetailID, year));
  },

  updateGlobalKpiFromExcel: async function (payload: Array<UpdateGlobalKPIBowlingChartImportRequestDto>) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateGlobalKPIBowlingChartImport(payload));
  },

  updateSpecificlKpiFromExcel: async function (payload: Array<UpdateSpecificKPIBowlingChartImportRequestDto>) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateSpecificKPIBowlingChartImport(payload));
  },

  getKpiSpecificoExcelDataAnualGoal: async function (annualGoalKPIRelationShipID: string, year: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID, year));
  },

  updateSpecificlKpiFromExcelAnualGoal: async function (payload: Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(payload));
  },

  // get kpi child Data
  getKpiChildList: async function (parentKpiId?: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetRollupKPIChild(parentKpiId));
  },

  // get kpi child Data
  getAnnualGoalList: async function (kpiDetailId: string, xmatrixId: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetAnnualGoalByKPI(kpiDetailId, xmatrixId));
  },

  // get kpi list data
  getKpiProjectPicklist: async function (parentKpiId: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIChildPicklist(parentKpiId));
  },

  insertChildKpi: async function (parentKpiId: string, childKpiId: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIInsertKPIChild(parentKpiId, childKpiId));
  },

  setKpiPrefered: async function (payload: InsertUserFavouriteKPIRequest) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIInsertUserFavouriteKPI(payload));
  },

  removeKpiPrefered: async function (kpiId: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIDeleteUserFavouriteKPI(kpiId));
  },

  getKpiProjectSintesi: async function (month: number, projectId: string, xmatrixId: string, year: number) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIDashboardSintesiByProject(month, projectId, xmatrixId, year));
  },

  getKpiAnualGoalSintesi: async function (month: number, annualGoalId: string, xmatrixId: string, year: number) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIDashboardSintesiByAnnualGoal(month, annualGoalId, xmatrixId, year));
  },

  getKpiRelatedProjectsBowlingChart: async function (kpiId: string, type: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIGerarchicoInfo(kpiId, type));
  },

  getKpiDetailsPastYears: async function (filters: { [s: string]: unknown } | ArrayLike<unknown>) {
    const { accessToken } = (await acquireToken()) as any;
    const queryString = Object.entries({ ...filters })
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const optionsVal = {
      method: 'GET',
      url: `api/KPI/KPIDetailsPastYears?${queryString}`,
      data: filters,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return trackPromise(axiosInstance(optionsVal));
  },

  getKpiGraph: async function (filters: { [s: string]: unknown } | ArrayLike<unknown>) {
    const { accessToken } = (await acquireToken()) as any;
    const queryString = Object.entries({ ...filters })
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const optionsVal = {
      method: 'GET',
      url: `api/KPI/DetailsKPI?${queryString}`,
      data: filters,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return trackPromise(axiosInstance(optionsVal));
  },

  getKpiGraphAsInA3: async function (filters: { [s: string]: unknown } | ArrayLike<unknown>) {
    const { accessToken } = (await acquireToken()) as any;
    const queryString = Object.entries({ ...filters })
      .filter(([_, value]) => value !== null && value !== undefined)
      .map(([key, value]) => `${key}=${value}`)
      .join('&');
    const optionsVal = {
      method: 'GET',
      url: `api/KPI/KPIDetailsGraficoA3?${queryString}`,
      data: filters,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    };
    return trackPromise(axiosInstance(optionsVal));
  },

  getKpiComments: async function (kpiDetailId: string, projectAnnualGoalId: string, type: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetCommentForKPI(kpiDetailId, projectAnnualGoalId, type));
  },

  onCommentSave: async function (payload: UpdateCommentForKPIRequestDto) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIUpdateCommentForKPI(payload));
  },

  onKpiChildRemove: async function (kpiId: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIRemoveKpiChildParentRelations(kpiId));
  },

  checkKpiYear: async function (kpiId: string, type: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPICheckKpiTypePlanningStartDate(kpiId, type));
  },

  getKPIPlanningByID: async function (projectId: string, annualGoalId: string, year: number, kpiId: string) {
    const api = await authenticatedApi(KPIApi);

    return trackPromise(api.kPIGetKPIPlanningByID(projectId, annualGoalId, year, kpiId));
  },
};

export default KpiService;

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PersonalDashboardService from '../../../services/pages/personalDashboardService';
import Loader2 from '../../shared/components/loader2/loader2';
import { perStatusDeliverableChartOptions } from '../chartsConfig';

const PerStatusDeliverableChart = () => {
  const [loadingData, setLoadingData] = useState(false);
  const [perStatusChart, setPerStatusChart] = useState(perStatusDeliverableChartOptions);
  const { t } = useTranslation();

  useEffect(() => {
    getPerStatusChartData();
  }, []);

  const getPerStatusChartData = async () => {
    setLoadingData(true);
    try {
      const response = await PersonalDashboardService.getDeliverablePerStatusChartData();
      const resp = response.data;
      if (resp.success) {
        const seriesObj = {
          name: `${t('bachechaPersonalePage.deliverable')}`,
          data: resp.responseObject.value.map((item) => ({
            name: item.statusDescription,
            y: item.deliverableNumber,
          })),
        };
        setPerStatusChart((prevChart) => ({
          ...prevChart,
          series: seriesObj,
        }));
      }
    } catch (error) {
      // handle error
    } finally {
      setLoadingData(false);
    }
  };

  return (
    <>
      {loadingData && <Loader2 />}
      {perStatusChart ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={perStatusChart}
        />
      ) : (
        <p>No data</p>
      )}
    </>
  );
};

export default PerStatusDeliverableChart;

import { Form } from 'antd';
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import '../../projects/project.scss';
import '../deliverable.scss';

import {
  EditOutlined,
  FolderOpenOutlined,
  PlusOutlined,
  RollbackOutlined,
  SaveOutlined,
  SearchOutlined,
  SelectOutlined,
} from '@ant-design/icons';
import {
  Button,
  Card,
  Col,
  DatePicker,
  Input,
  InputNumber,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tooltip,
  message,
} from 'antd';
import BraftEditor from 'braft-editor';
import Parser from 'html-react-parser';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';
import CommonService from '../../../services/commonService';
import DeliverablePageService from '../../../services/pages/deliverableService';
import DeliverableService from '../../../services/pages/projectServices/deliverableServices';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import Loader2 from '../../shared/components/loader2/loader2';
import Feed from '../../shared/feed/feed';
import {
  deliverableStatus,
  feedObjectType,
  feedRegardingObjectType,
  objectCodes,
  richTectEditoControlls,
  statusPickListCodes,
} from '../../shared/utils/constants';
import { areDatesValid, showErrorNotification, updateNotificationBell } from '../../shared/utils/functions';
import { notifyMessages, requiredFields } from '../../shared/utils/notifyMessages';
import EndDateConfirmationModal from './endDateConfirmationModal';

let createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

const { Option } = Select;
const { TextArea } = Input;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class ViewEditDeliverable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      fieldsChanged: false,
      loadingButtonDelete: false,
      currentDeliverable: props.deliverableData,
      responsiblePickList: null,
      projectPickList: null,
      deliverablePickList: null,
      statusList: null,
      priorityPicklist: null,
      hasParent: false,
      parentEndDate: null,
      projectData: null,
      dataFinePrevista: props.deliverableData.rescheduledEndDate,
      isGenericResponsabileField: props.deliverableData.isGenericResponsabile,
      showGenericResponsabileField: props.deliverableData.isGenericResponsabile,
      showIsGenericResponsabileField: true,
      endDateConfirmationModal: false,
      formValues: null,

      selectedDeliverableData: null,
      selectedProjectData: null,
      selectedParentDeliverable: props.deliverableData.parentDeliverableID,
      selectedResponsabile: props.deliverableData.ownerID,

      currentPageUrl: window.location.pathname,
      showCommentEditor: false,
      statusListFilter: [],
    };
  }

  componentDidMount() {
    this.retrieveStatus();
    this.retrievePriority();
    this.retrieveProjectPickList();
    this.retrieveResponsibleUsersList(this.state.currentDeliverable.projectID);
    this.retrieveDeliverablePickList(this.state.currentDeliverable.projectID);
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  async retrieveStatus() {
    const objectCode = statusPickListCodes.delivarbleStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let obj = [];
          resp.responseObject.value.map((item) => {
            obj.push({ text: item.statusDescription, value: item.statusDescription });
          });
          this.setState({ statusListFilter: obj });
          this.setState({ statusList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrievePriority() {
    const objectCode = objectCodes.deliverablePriority;
    await CommonService.getPicklist(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ priorityPicklist: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveResponsibleUsersList(projectId) {
    await CommonService.getResponsibleUserList(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          this.setState({ responsiblePickList: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  // Retrieve list of projects
  async retrieveProjectPickList() {
    const { currentDeliverable } = this.state;
    await ProgettiService.getProjectPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;

          let checkDeliverableProject = respData.some(function (el) {
            return el.projectID === currentDeliverable.projectID;
          });

          if (!checkDeliverableProject) {
            respData.push({ name: currentDeliverable.projectName, projectID: currentDeliverable.projectID });
          }
          this.setState({ projectPickList: respData });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retrieveDeliverablePickList(projectId) {
    const { currentDeliverable } = this.state;
    let deliverableId = currentDeliverable.deliverableID;
    await DeliverableService.getParentDeliverableProjectPicklist(projectId, deliverableId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ deliverablePickList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  backFunction = () => {
    const { currentDeliverable } = this.state;
    const url = `/progetti/id/${currentDeliverable.projectID}/2`;
    history.push(url);
  };

  backFunctionButton = () => {
    history.goBack();
  };

  handleDateChange(date) {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  updateData = (values, id, confirmationResponse) => {
    const {
      selectedParentDeliverable,
      selectedResponsabile,
      currentDeliverable,
      dataFinePrevista,
      isGenericResponsabileField,
    } = this.state;

    let editorContentToHtml = BraftEditor.createEditorState(values['comment']).toHTML();
    values['comment'] = editorContentToHtml;
    values['updateParentEndDate'] = confirmationResponse;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);
    values['rescheduledEndDate'] = this.handleDateChange(dataFinePrevista);
    values.actualEndDate = this.handleDateChange(values.actualEndDate);
    values['deliverableID'] = id;

    values['parentDeliverableID'] = selectedParentDeliverable;
    values['ownerID'] = selectedResponsabile;
    values['isGenericResponsabile'] = isGenericResponsabileField;
    values['ownerName'] = this.props.deliverableData.ownerName;
    if (isGenericResponsabileField == false) {
      values['genericResponsible'] = '';
    }

    if (currentDeliverable.status === deliverableStatus.chiuso) {
      if (currentDeliverable.status !== values.status) {
        values['actualEndDate'] = null;
      }
    }

    this.setState({ loadingButton: true });
    DeliverablePageService.updateDeliverable(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          updateNotificationBell();
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingButton: false });
          window.location.reload();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  onProjectSelect = (option) => {
    this.setState({ selectedParentDeliverable: null });
    this.setState({ selectedResponsabile: null });
    this.setState({ responsiblePickList: null });
    this.retrieveDeliverablePickList(option);
    this.retrieveResponsibleUsersList(option);
    this.getSelectedProjectData(option);
    this.setState({ selectedDeliverableData: null });
  };

  onParentDeliverableChange = (option) => {
    if (option) {
      this.setState({ fieldsChanged: true });
      this.setState({ selectedParentDeliverable: option });
      this.getSelectedDeliverableData(option);
    }
  };

  getSelectedDeliverableData = (id) => {
    DeliverablePageService.getDeliverableById(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedDeliverableData: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };

  getSelectedProjectData = (id) => {
    ProgettiService.getParentProjectByID(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedProjectData: resp.responseObject.value });

          if (resp.responseObject.value.teamLeaderID == null) {
            this.setState({ showIsGenericResponsabileField: false });
            this.setState({ isGenericResponsabileField: false });
            this.setState({ showGenericResponsabileField: false });
          } else {
            this.setState({ showIsGenericResponsabileField: true });
          }
        } else {
        }
      })
      .catch((error) => {});
  };

  onParentDeliverableClear = () => {
    this.setState({ fieldsChanged: true });
    this.setState({ selectedParentDeliverable: null });
    this.setState({ selectedDeliverableData: null });
  };

  onResponsabileClear = () => {
    this.setState({ selectedResponsabile: null });
  };

  onReponsabileChange = (option) => {
    if (option) {
      this.setState({ fieldsChanged: true });
      this.setState({ selectedResponsabile: option });
    }
  };

  disabledDate(current) {
    const { selectedDeliverableData, selectedProjectData, currentDeliverable } = this.state;

    if (selectedProjectData && !selectedDeliverableData) {
      let curr = moment(current).endOf('day');
      let startDate = moment(selectedProjectData.startDate).endOf('day');
      let endDate = moment(selectedProjectData.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else if (selectedProjectData && selectedDeliverableData) {
      let curr = moment(current).endOf('day');
      let startDate = moment(selectedProjectData.startDate).endOf('day');
      let endDate = moment(selectedProjectData.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else if (selectedDeliverableData) {
      let curr = moment(current).endOf('day');
      let startDate = moment(currentDeliverable.projectStartDate).endOf('day');
      let endDate = moment(currentDeliverable.projectEndDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else {
      let curr = moment(current).endOf('day');
      let startDate = moment(currentDeliverable.projectStartDate).endOf('day');
      let endDate = moment(currentDeliverable.projectEndDate).endOf('day');
      return curr < startDate || curr > endDate;
    }
  }

  disableStartDate(current) {
    const { selectedDeliverableData, selectedProjectData, currentDeliverable } = this.state;

    if (selectedProjectData && !selectedDeliverableData) {
      let curr = moment(current).endOf('day');
      let startDate = moment(selectedProjectData.startDate).endOf('day');
      let endDate = moment(selectedProjectData.endDate).endOf('day');
      return curr < startDate || curr > endDate;
    } else if (selectedProjectData && selectedDeliverableData) {
      if (selectedDeliverableData.parentDeliverableID) {
        let curr = moment(current).endOf('day');
        let startDate = moment(selectedDeliverableData.parentDeliverableStartDate).endOf('day');
        let endDate = moment(selectedDeliverableData.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        let curr = moment(current).endOf('day');
        let startDate = moment(selectedProjectData.startDate).endOf('day');
        let endDate = moment(selectedProjectData.endDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    } else if (selectedDeliverableData) {
      if (selectedDeliverableData.parentDeliverableID) {
        let curr = moment(current).endOf('day');
        let startDate = moment(selectedDeliverableData.parentDeliverableStartDate).endOf('day');
        let endDate = moment(selectedDeliverableData.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        let curr = moment(current).endOf('day');
        let startDate = moment(selectedDeliverableData.startDate).endOf('day');
        let endDate = moment(selectedDeliverableData.endDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    } else {
      if (currentDeliverable.parentDeliverableID) {
        let curr = moment(current).endOf('day');
        let startDate = moment(currentDeliverable.parentDeliverableStartDate).endOf('day');
        let endDate = moment(currentDeliverable.parentDeliverableEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
      if (currentDeliverable) {
        let curr = moment(current).endOf('day');
        let startDate = moment(currentDeliverable.projectStartDate).endOf('day');
        let endDate = moment(currentDeliverable.projectEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      } else {
        let curr = moment(current).endOf('day');
        let startDate = moment(currentDeliverable.projectStartDate).endOf('day');
        let endDate = moment(currentDeliverable.projectEndDate).endOf('day');
        return curr < startDate || curr > endDate;
      }
    }
  }

  onStatusChange = (option) => {
    if (option === deliverableStatus.chiuso) {
      this.setState({ dataFinePrevista: null });
    }
  };

  onDataPrevistaChange = (date) => {
    this.setState({ fieldsChanged: true });
    if (date) {
      let convertedDate = moment(date);
      this.setState({ dataFinePrevista: convertedDate });
    } else {
      this.setState({ dataFinePrevista: null });
    }
  };

  checkDeliverableEndDateValidity = (selectedDeliverableData, currentDeliverable, values) => {
    let selectedDate = values.endDate;
    if (selectedDeliverableData) {
      if (moment(selectedDeliverableData.endDate) >= selectedDate) {
        return true;
      } else {
        return false;
      }
    } else if (
      currentDeliverable &&
      currentDeliverable.parentDeliverableID &&
      selectedDate > moment(currentDeliverable.parentDeliverableEndDate)
    ) {
      return false;
    } else {
      return true;
    }
  };

  checkDatesValidity = (values, deliverableID) => {
    const { t } = this.props;
    const { selectedResponsabile, selectedDeliverableData, currentDeliverable } = this.state;
    if (areDatesValid(values.startDate, values.endDate)) {
      if (selectedResponsabile) {
        if (!this.checkDeliverableEndDateValidity(selectedDeliverableData, currentDeliverable, values)) {
          this.toggleEndDateModal(values);
        } else {
          this.updateData(values, deliverableID, false);
        }
      } else {
        showErrorNotification(`${t('notifications.generalError')}`, requiredFields.responsabileRequired);
      }
    } else {
      showErrorNotification(`${t('notifications.generalError')}`, requiredFields.validateEndDate);
    }
  };

  showHideCommentEdit = () => {
    const { showCommentEditor } = this.state;
    this.setState({ showCommentEditor: !showCommentEditor });
  };

  commentLabel = () => {
    const { t } = this.props;
    return (
      <span>
        {t('general.commenti')} <EditOutlined onClick={() => this.showHideCommentEdit()} />
      </span>
    );
  };

  onGenericCheck = (checked) => {
    this.setState({ fieldsChanged: true });
    const { selectedProjectData, isGenericResponsabileField, currentDeliverable } = this.state;
    this.setState({ isGenericResponsabileField: !isGenericResponsabileField });

    let selectedResponsible =
      (selectedProjectData && selectedProjectData.teamLeaderID) !== null
        ? selectedProjectData.teamLeaderID
        : currentDeliverable.ownerID;

    if (selectedProjectData && selectedProjectData.teamLeaderID) {
      this.setState({ isGenericResponsabileField: false });
    }

    if (checked) {
      this.setState({ isGenericResponsabileField: true });
      this.setState({ showGenericResponsabileField: true });
      this.setState({ selectedResponsabile: selectedResponsible });
    } else {
      this.setState({ isGenericResponsabileField: false });
      this.setState({ showGenericResponsabileField: false });
      this.setState({ selectedResponsabile: selectedResponsible });
    }
  };

  handelModalEndDateClsose = () => {
    this.setState({ endDateConfirmationModal: false });
  };

  toggleEndDateModal = (values) => {
    this.setState({ formValues: values }, () => {
      this.setState({ endDateConfirmationModal: true });
    });
  };

  handelConfirmResponse = (values, response) => {
    const { currentDeliverable } = this.state;
    this.handelModalEndDateClsose();
    this.updateData(values, currentDeliverable.deliverableID, response);
  };

  handelDeclineResponse = (values, response) => {
    const { currentDeliverable, selectedDeliverableData } = this.state;
    if (selectedDeliverableData) {
      values.endDate = this.handleDateChange(selectedDeliverableData.endDate);
      this.handelModalEndDateClsose();
      this.updateData(values, currentDeliverable.deliverableID, response);
    } else {
      values.endDate = this.handleDateChange(currentDeliverable.parentDeliverableEndDate);
      this.handelModalEndDateClsose();
      this.updateData(values, currentDeliverable.deliverableID, response);
    }
  };

  render() {
    const {
      currentDeliverable,
      loadingButton,
      responsiblePickList,
      statusList,
      priorityPicklist,
      projectPickList,
      deliverablePickList,
      dataFinePrevista,
      selectedParentDeliverable,
      selectedResponsabile,
      showCommentEditor,
      isGenericResponsabileField,
      showGenericResponsabileField,
      showIsGenericResponsabileField,
      statusListFilter,
      formValues,
    } = this.state;
    const { userData, t } = this.props;

    const childDeliverableColumns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'deliverableName',
        ellipsis: {
          showTitle: true,
        },
        width: '30%',
        sorter: (a, b) => {
          a = a.deliverableName || '';
          b = b.deliverableName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('deliverableName'),
        render: (title) => <Tooltip title={title}>{title}</Tooltip>,
      },
      {
        title: `${t('general.responsabile')}`,
        dataIndex: 'ownerName',
        sorter: (a, b) => {
          a = a.ownerName || '';
          b = b.ownerName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('ownerName'),
      },

      {
        title: `${t('general.stato')}`,
        dataIndex: 'statusDescription',
        sorter: (a, b) => {
          a = a.statusDescription || '';
          b = b.statusDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        filters: statusListFilter,
        onFilter: (value, record) => (record.statusDescription ? record.statusDescription.includes(value) : ''),
      },
      {
        key: 'action',
        dataIndex: 'action',
        width: '50px',
        render: (text, record) => (
          <Link
            to={{
              pathname: `/deliverables/id/${record.deliverableID}`,
            }}>
            <EditOutlined />
          </Link>
        ),
      },
    ];

    return (
      <>
        {!currentDeliverable && <Loader2 />}
        {currentDeliverable && (
          <>
            {this.state.endDateConfirmationModal && (
              <EndDateConfirmationModal
                showModal={this.state.endDateConfirmationModal}
                handelModalClose={this.handelModalEndDateClsose}
                handelConfirmResponse={this.handelConfirmResponse}
                handelDeclineResponse={this.handelDeclineResponse}
                t={t}
                deliverableFormValues={formValues}
              />
            )}

            <Form
              {...formItemLayout}
              name="deliverable_details_formData"
              onFinish={(values) => this.checkDatesValidity(values, currentDeliverable.deliverableID)}
              onValuesChange={() => {
                this.setState({ fieldsChanged: true });
              }}>
              <Row
                gutter={{ lg: 24 }}
                style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Col>
                  <div className="tw-flex tw-items-center tw-gap-2">
                    <Button
                      icon={<RollbackOutlined />}
                      onClick={this.backFunction}>
                      {t('deliverablePage.goToPrs')}
                    </Button>

                    <Button
                      icon={<FolderOpenOutlined />}
                      href={`/progetti/id/${currentDeliverable.projectID}`}>
                      {t('proggetiPage.passaProgetto')}
                    </Button>

                    {currentDeliverable.parentDeliverableID && (
                      <Button
                        icon={<SelectOutlined />}
                        href={`/deliverables/id/${currentDeliverable.parentDeliverableID}`}>
                        {t('deliverablePage.goToMacroAttivita')}
                      </Button>
                    )}
                  </div>
                </Col>

                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 12 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}>
                  <Form.Item key="save">
                    <Button
                      loading={loadingButton}
                      icon={<SaveOutlined />}
                      type="primary"
                      htmlType="submit"
                      className="tw-float-end"
                      disabled={!this.state.fieldsChanged}>
                      {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                </Col>
              </Row>

              <Row
                className="mb-row"
                gutter={{ lg: 24 }}
                style={{ marginTop: 20 }}>
                <Col
                  className="gutter-row tw-mb-4"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}>
                  <Card
                    className="card-body-bacground"
                    title={t('general.detagli')}>
                    <Row
                      className="mb-row"
                      gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          className="main-container"
                          style={{ marginBottom: 5 }}
                          labelAlign="left"
                          label={t('general.nome')}
                          name="name"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={currentDeliverable.deliverableName}>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          className="main-container"
                          style={{ marginBottom: 5 }}
                          labelAlign="left"
                          label={t('deliverablePage.macroAttivita')}
                          //name="parentDeliverableID"
                          hasFeedback>
                          <Select
                            value={selectedParentDeliverable}
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            allowClear
                            onChange={(option) => this.onParentDeliverableChange(option)}
                            onClear={(option) => this.onParentDeliverableClear(option)}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {deliverablePickList &&
                              deliverablePickList.map((item) => (
                                <Option
                                  value={item.deliverableID}
                                  key={item.deliverableID}>
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          className="main-container"
                          style={{ marginBottom: 5 }}
                          labelAlign="left"
                          label={t('general.progetto')}
                          name="projectID"
                          rules={[{ required: true, message: requiredFields.required }]}
                          hasFeedback
                          initialValue={currentDeliverable.projectID}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onSelect={(option) => this.onProjectSelect(option)}
                            showSearch
                            optionFilterProp="children"
                            filterOption={(input, option) =>
                              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }>
                            {projectPickList &&
                              projectPickList.map((item) => (
                                <Option
                                  value={item.projectID}
                                  key={item.projectID}>
                                  {item.name}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <Form.Item
                          className="main-container"
                          style={{ marginBottom: 5 }}
                          labelAlign="left"
                          label={t('general.responsabile')}
                          name="ownerID"
                          initialValue={currentDeliverable.ownerName}
                          required={true}>
                          <Select
                            value={selectedResponsabile}
                            placeholder={t('general.seleziona')}
                            style={{ width: '100%' }}
                            onChange={(option) => this.onReponsabileChange(option)}
                            onClear={() => this.onResponsabileClear()}
                            options={responsiblePickList}
                            showSearch
                            filterOption={(input, option) =>
                              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                            }
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        {showIsGenericResponsabileField && (
                          <Form.Item
                            className="main-container"
                            label={t('deliverablePage.isGenericResponsible')}>
                            <Switch
                              checked={isGenericResponsabileField}
                              value={currentDeliverable.isGenericResponsabile}
                              onChange={(checked) => this.onGenericCheck(checked)}
                            />
                          </Form.Item>
                        )}
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        {showGenericResponsabileField && (
                          <Form.Item
                            className="main-container"
                            style={{ marginBottom: 5 }}
                            labelAlign="left"
                            label={t('deliverablePage.genericResponsabile')}
                            name="genericResponsible"
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={currentDeliverable.genericResponsible}>
                            <Input />
                          </Form.Item>
                        )}
                      </Col>
                    </Row>

                    <Row
                      className="mb-row"
                      gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          className="main-container"
                          style={{ marginBottom: 5 }}
                          labelAlign="left"
                          label={t('general.stato')}
                          name="status"
                          hasFeedback
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={currentDeliverable.status}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}
                            onChange={(option) => this.onStatusChange(option)}>
                            {statusList &&
                              statusList.map((item) => (
                                <Option
                                  value={item.statusID}
                                  key={item.statusID}>
                                  {item.statusDescription}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          className="main-container"
                          style={{ marginBottom: 5 }}
                          labelAlign="left"
                          label={t('general.priorita')}
                          name="priority"
                          hasFeedback
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={currentDeliverable.priority}>
                          <Select
                            style={{ width: '100%' }}
                            placeholder={t('general.seleziona')}>
                            {priorityPicklist &&
                              priorityPicklist.map((item) => (
                                <Option
                                  value={item.objectCodeListID}
                                  key={item.objectCodeListID}>
                                  {item.description}
                                </Option>
                              ))}
                          </Select>
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      className="mb-row"
                      gutter={{ lg: 24 }}
                      style={{ marginTop: '6px' }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          className="main-container"
                          style={{ marginBottom: 5 }}
                          label={t('general.start')}
                          name="startDate"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={
                            currentDeliverable.startDate
                              ? moment(currentDeliverable.startDate)
                              : currentDeliverable.startDate
                          }>
                          <DatePicker
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            disabledDate={(current) => this.disableStartDate(current)}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          className="main-container"
                          style={{ marginBottom: 5 }}
                          label={t('general.end')}
                          name="endDate"
                          rules={[{ required: true, message: requiredFields.required }]}
                          initialValue={
                            currentDeliverable.endDate ? moment(currentDeliverable.endDate) : currentDeliverable.endDate
                          }>
                          <DatePicker
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            disabledDate={(current) => this.disabledDate(current)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      className="mb-row"
                      gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          style={{ marginBottom: 5 }}
                          label={t('general.dataChiusura')}
                          name="actualEndDate"
                          initialValue={
                            currentDeliverable.actualEndDate
                              ? moment(currentDeliverable.actualEndDate)
                              : currentDeliverable.actualEndDate
                          }>
                          <DatePicker
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          className="main-container"
                          style={{ marginBottom: 5 }}
                          label={t('deliverablePage.dataFinePrevista')}>
                          <DatePicker
                            style={{ width: '100%' }}
                            format="DD/MM/YYYY"
                            value={dataFinePrevista ? moment(dataFinePrevista) : null}
                            onChange={(date) => this.onDataPrevistaChange(date)}
                          />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      className="mb-row"
                      gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          style={{ marginBottom: 5 }}
                          label={t('general.budget')}
                          name="budget"
                          initialValue={currentDeliverable.budget}>
                          <InputNumber
                            min={0}
                            style={{ width: '100%' }}
                          />
                        </Form.Item>
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 12 }}
                        lg={{ span: 12 }}>
                        <Form.Item
                          style={{ marginBottom: 5 }}
                          label={t('deliverablePage.etichetta')}
                          name="tag"
                          initialValue={currentDeliverable.tag}>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>

                    <Row
                      className="mb-row"
                      gutter={{ lg: 24 }}>
                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}
                        style={{
                          border: '1px solid #e9e9e9',
                          margin: '14px 14px 14px 14px',
                          maxWidth: '97%',
                        }}>
                        {showCommentEditor && (
                          <Form.Item
                            className="main-container"
                            label={this.commentLabel()}
                            name="comment"
                            initialValue={BraftEditor.createEditorState(currentDeliverable.comment)}>
                            <BraftEditor
                              contentFormat="html"
                              language="en"
                              id="editor-text"
                              controls={richTectEditoControlls}
                            />
                          </Form.Item>
                        )}

                        {!showCommentEditor && (
                          <Form.Item
                            className="main-container"
                            label={this.commentLabel()}
                            name="comment"
                            initialValue={BraftEditor.createEditorState(currentDeliverable.comment)}>
                            {Parser(currentDeliverable.comment ? currentDeliverable.comment : '')}
                          </Form.Item>
                        )}
                      </Col>

                      <Col
                        className="gutter-row"
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Form.Item
                          className="main-container"
                          style={{ marginBottom: 5 }}
                          labelAlign="left"
                          label={t('general.nota')}
                          name="notes"
                          initialValue={currentDeliverable.notes}>
                          <TextArea
                            rows={5}
                            allowClear
                          />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Card>
                </Col>

                <Col
                  className="gutter-row tw-mb-4"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 12 }}
                  lg={{ span: 12 }}>
                  <Row
                    className="mb-row"
                    gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row tw-mb-4"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Card
                        className="!tw-shadow-none"
                        styles={{ header: { padding: 0 }, body: { padding: 0 } }}
                        bordered={false}
                        title={t('deliverablePage.deliverableFigli')}
                        extra={
                          <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            data-testid="newChildDeliverable"
                            href={`/deliverables/new/pid/${currentDeliverable.deliverableID}/${currentDeliverable.projectID}`}>
                            {t('buttons.aggiungiNuovo')}
                          </Button>
                        }>
                        <Table
                          columns={childDeliverableColumns}
                          dataSource={currentDeliverable.childs}
                          size="small"
                          rowKey={(obj) => obj.deliverableID}
                          // scroll={{ x: 'calc(450px + 50%)' }}
                          pagination={false}
                        />
                      </Card>
                    </Col>
                    <Col
                      className="gutter-row tw-mb-4"
                      style={{ marginTop: 20 }}
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Feed
                        objectId={currentDeliverable.deliverableID}
                        userData={userData}
                        objectType={feedObjectType.deliverableFeed}
                        isProject={0}
                        isDeliverable={true}
                        feedRegardingObjType={feedRegardingObjectType.deliverable}
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ViewEditDeliverable));

import { useEffect, useState } from 'react';
import { perStatusDeliverableChartOptions } from '../chartsConfig';

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useTranslation } from 'react-i18next';

function createSeriesFromPhases(deliverables) {
  // TODO: Translate these
  const statusMap = {
    1: 'Aperto',
    2: 'Chiuso',
    3: 'Draft',
    4: 'Sospeso',
  };

  const statusCount = deliverables.reduce((acc, deliverable) => {
    const { status } = deliverable;
    if (!acc[status]) {
      acc[status] = { count: 0, description: statusMap[status] };
    }
    acc[status].count += 1;
    return acc;
  }, {});

  return Object.entries(statusCount).map(([status, { count }]) => ({
    name: statusMap[status],
    y: count,
  }));
}

const PerStatusPhasesChart = ({ phases }) => {
  const [perStatusChart, setPerStatusChart] = useState(perStatusDeliverableChartOptions);
  const { t } = useTranslation();

  useEffect(() => {
    setPerStatusChart((prevChart) => {
      const chartOptions = {
        ...prevChart,
        series: [
          {
            name: `${t('general.phases')}`,
            data: createSeriesFromPhases(phases),
          },
        ],
      };

      return chartOptions;
    });
  }, [phases]);

  return (
    <>
      {phases.length ? (
        <HighchartsReact
          highcharts={Highcharts}
          options={perStatusChart}
        />
      ) : (
        <p>No data</p>
      )}
    </>
  );
};

export default PerStatusPhasesChart;

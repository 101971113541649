import { Form } from 'antd';
import React from 'react';
import './listCategoryPage.scss';

import { SaveOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Row, Select, message } from 'antd';
import { withTranslation } from 'react-i18next';
import CategoryService from '../../services/pages/categoryService';
import Loader from '../shared/components/loader/loader';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';
import CategoryIcon from '../shared/CategoryIcon';

let createBrowserHistory = require('history').createBrowserHistory;
const history = createBrowserHistory({ forceRefresh: true });

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class EditCategory extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      currentData: null,
      iconList: null,
    };
  }

  componentDidMount() {
    const { id } = this.props.match.params;
    this.getCategoryById(id);
    this.retrieveIcons();
  }

  getCategoryById = (id) => {
    CategoryService.getCategoryByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentData: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  // Retrieve list of icons
  retrieveIcons() {
    CategoryService.getIconList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ iconList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  backFunction = () => {
    history.goBack();
  };

  updateData = (values, id) => {
    values['goalCategoryID'] = id;

    CategoryService.updateCategory(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  render() {
    const { currentData, iconList } = this.state;
    let t = this.props.t;

    return (
      <div className="edit-category-wrapper">
        <Loader />

        {currentData && (
          <>
            <div className="site-card-wrapper">
              <h2 className="objective-item">{currentData.name}</h2>
              <p> {t('categoriePage.categorie')} </p>
            </div>

            <Card>
              <Form
                name="listCategory"
                layout="vertical"
                {...formItemLayout}
                onFinish={(values) => this.updateData(values, currentData.goalCategoryID)}
                onValuesChange={() => {
                  this.setState({ fieldsChanged: true });
                }}>
                <Row gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row"
                    xs={{ span: 4 }}
                    sm={{ span: 4 }}
                    md={{ span: 4 }}
                    lg={{ span: 4 }}>
                    {/* <Button
                      //htmlType="submit"
                      onClick={this.backFunction}
                      className="backButton">
                      <RollbackOutlined />
                      {t('buttons.indietro')}
                    </Button> */}
                  </Col>
                  <Col
                    className="gutter-row"
                    xs={{ span: 20 }}
                    sm={{ span: 20 }}
                    md={{ span: 20 }}
                    lg={{ span: 20 }}>
                    <div className="button-wrapper">
                      <Form.Item wrapperCol={{ span: 24 }}>
                        <Button
                          type="primary"
                          htmlType="submit"
                          className="tw-float-end"
                          disabled={!this.state.fieldsChanged}>
                          <SaveOutlined /> {t('buttons.salva')}
                        </Button>
                      </Form.Item>
                    </div>
                  </Col>
                </Row>

                <Row gutter={{ lg: 24 }}>
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 12 }}>
                    <Card title={t('general.informazioniGenerali')}>
                      <Row
                        gutter={{ lg: 24 }}
                        className="mt10">
                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 16 }}>
                          <Form.Item
                            label={t('general.nome')}
                            name="name"
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={currentData.name}>
                            <Input />
                          </Form.Item>
                        </Col>

                        <Col
                          className="gutter-row"
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 8 }}>
                          <Form.Item
                            label={t('categoriePage.icona')}
                            name="goalCategoryIconID"
                            hasFeedback
                            rules={[{ required: true, message: requiredFields.required }]}
                            initialValue={currentData.goalCategoryIconID}>
                            <Select
                              style={{ width: '100%' }}
                              placeholder={t('general.seleziona')}>
                              {iconList &&
                                iconList.map((item) => (
                                  <Option
                                    value={item.goalCategoryIconID}
                                    key={item.goalCategoryIconID}>
                                    <CategoryIcon iconName={item.iconSource} />
                                  </Option>
                                ))}
                            </Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Card>
                  </Col>
                </Row>
              </Form>
            </Card>
          </>
        )}
      </div>
    );
  }
}

export default withTranslation()(EditCategory);

import { Col, Row } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React from 'react';
import KpiService from '../../../services/pages/kpiService';
import '../../kpiListPage/kpi.scss';
import { kpiDetailsPasYearsChartOptions } from '../../personalDashbard/chartsConfig';
import Loader2 from '../../shared/components/loader2/loader2';

class PreviousYearChart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      chartData: kpiDetailsPasYearsChartOptions,
      chartHasData: false,
      loadingChart: false,
    };
  }

  componentDidMount() {
    this.getChartData();
  }

  getChartData = () => {
    const { xmatrix, kpiDetailId, projectId, month, year, annualId, t } = this.props;

    let filters = {
      XMatrixID: xmatrix,
      KPIDetailID: kpiDetailId,
      year: year,
      ProjectID: projectId && projectId !== '0' ? projectId : null,
      AnnualGoalID: annualId && annualId !== '0' ? annualId : null,
    };

    KpiService.getKpiDetailsPastYears(filters)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;

          let series = [];

          let seriesObjActual = {};
          let dataActual = [];

          let seriesObjTarget = {};
          let dataTarget = [];

          let xAxisCategories = [];
          let xAxisObject = {};

          xAxisCategories = respData.map((a) => a.year);
          xAxisObject['categories'] = xAxisCategories;

          respData.map((item, index) => {
            dataActual.push({ name: item.year, y: item.actual });
            dataTarget.push({ name: item.year, y: item.target });
          });
          seriesObjActual['name'] = 'Actual';
          seriesObjActual['data'] = dataActual;

          seriesObjTarget['name'] = 'Target';
          seriesObjTarget['data'] = dataTarget;

          series.push(seriesObjActual);
          series.push(seriesObjTarget);

          this.setState({ chartHasData: respData }, () => {
            this.setState({ chartData: { ...this.state.chartData, series: series, xAxis: xAxisObject } });
          });

          this.setState({ chartHasData: true });
          this.setState({ loadingChart: false });
        } else {
          this.setState({ loadingChart: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingChart: false });
      });
  };

  render() {
    const { chartData, chartHasData, loadingChart } = this.state;

    return (
      <>
        {!loadingChart && !chartHasData && <Loader2 />}

        {chartHasData && (
          <Row
            className="mb-row"
            gutter={{ lg: 24 }}>
            <Col
              className="gutter-row tw-mb-4"
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartData}
              />
            </Col>
          </Row>
        )}
      </>
    );
  }
}

export default PreviousYearChart;

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import FeedService from '../../services/pages/projectServices/feedServices';
import { GetFeedMonthlyAlertByUserResponseDto } from 'src/connectors/backend';

interface FeedNotificationsState {
  loading: boolean;
  feedNotificationsList: GetFeedMonthlyAlertByUserResponseDto[] | null | undefined;
  error: string | null;
}

const initialState: FeedNotificationsState = {
  loading: false,
  feedNotificationsList: [],
  error: null,
};

export const retrieveFeedNotificationList = createAsyncThunk(
  'feedNotifications/retrieveFeedNotificationList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await FeedService.getFeedNotificationBellItems();
      const { success, responseObject } = response.data;

      if (!success) {
        throw new Error('Failed to fetch company notifications');
      }

      return responseObject?.value;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  },
);

const feedNotificationsSlice = createSlice({
  name: 'feedNotifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveFeedNotificationList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        retrieveFeedNotificationList.fulfilled,
        (state, action: PayloadAction<GetFeedMonthlyAlertByUserResponseDto[] | null | undefined>) => {
          state.loading = false;
          state.feedNotificationsList = action.payload;
        },
      )
      .addCase(retrieveFeedNotificationList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = (action.payload as string) || 'Unknown error';
      });
  },
});

export default feedNotificationsSlice.reducer;

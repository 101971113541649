import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import LayoutService from '../../services/layoutService';
import { GetMonthlyAlertByUserResponseDto } from 'src/connectors/backend';

interface NotificationState {
  loading: boolean;
  notificationsList: GetMonthlyAlertByUserResponseDto[] | null | undefined;
  error: string | null;
}

const initialState: NotificationState = {
  loading: false,
  notificationsList: [],
  error: null,
};

export const retrieveNotificationList = createAsyncThunk(
  'notifications/retrieveNotificationList',
  async (_, { rejectWithValue }) => {
    try {
      const response = await LayoutService.getNotificationBellItems();
      const { success, responseObject } = response.data;

      if (!success) {
        throw new Error('Failed to fetch company notifications');
      }

      return responseObject?.value;
    } catch (error: any) {
      return rejectWithValue(error.message || 'Unknown error');
    }
  },
);

const notificationSlice = createSlice({
  name: 'notifications',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(retrieveNotificationList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        retrieveNotificationList.fulfilled,
        (state, action: PayloadAction<GetMonthlyAlertByUserResponseDto[] | null | undefined>) => {
          state.loading = false;
          state.notificationsList = action.payload;
        },
      )
      .addCase(retrieveNotificationList.rejected, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = (action.payload as string) || 'Unknown error';
      });
  },
});

export default notificationSlice.reducer;

import classNames from 'classnames';
import React, { Component } from 'react';
import { getQueryParam, hasQueryParam } from '../../../utils/url-utils';
import './baseLayout.scss';
import AdminNavbar from './header/navbar';

class AppLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fullScreenMode: false,
    };

    this.handleQueryParamChange = this.handleQueryParamChange.bind(this);
  }

  componentDidMount() {
    // Initial check for query parameters on mount
    this.updateFullScreenMode();

    // Listen to the 'popstate' event which is triggered on back/forward navigation or when the history state changes
    window.addEventListener('popstate', this.handleQueryParamChange);

    // Optional: Listen to the history state changes (if query params are changed programmatically)
    this.originalPushState = window.history.pushState;
    this.originalReplaceState = window.history.replaceState;

    window.history.pushState = (...args) => {
      this.originalPushState.apply(window.history, args);
      this.handleQueryParamChange(); // Handle state change
    };

    window.history.replaceState = (...args) => {
      this.originalReplaceState.apply(window.history, args);
      this.handleQueryParamChange(); // Handle state change
    };
  }

  componentWillUnmount() {
    // Clean up the event listener when the component is unmounted
    window.removeEventListener('popstate', this.handleQueryParamChange);

    // Restore original history methods
    window.history.pushState = this.originalPushState;
    window.history.replaceState = this.originalReplaceState;
  }

  handleQueryParamChange() {
    // Call the function to update the state based on the query params
    this.updateFullScreenMode();
  }

  updateFullScreenMode() {
    const isPresentationMode = hasQueryParam('presentationMode');
    const isFullScreenMode = getQueryParam('fullScreenMode') === 'true';

    if (isPresentationMode && isFullScreenMode) {
      this.setState({ fullScreenMode: true }, () => this.enterFullScreen());
    } else {
      this.setState({ fullScreenMode: false }, () => this.exitFullScreen());
    }
  }

  enterFullScreen() {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen().catch((err) => {
        console.error(`Error attempting to enable full-screen mode: ${err.message}`);
      });
    }
  }

  exitFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen().catch((err) => {
        console.error(`Error attempting to exit full-screen mode: ${err.message}`);
      });
    }
  }

  render() {
    return (
      <div
        className={classNames('tw-h-screen tw-absolute tw-inset-0 tw-overflow-hidden tw-flex tw-flex-col', {
          '[&_>.ant-layout-header]:tw-hidden': this.state.fullScreenMode,
        })}>
        <AdminNavbar authData={this.props.data} />
        <div className="tw-relative tw-overflow-y-auto tw-grow tw-w-full">{this.props.children}</div>
      </div>
    );
  }
}

export default AppLayout;

import React from 'react';
import Feed from '../../shared/feed/feed';
import { feedObjectType, feedRegardingObjectType } from '../../shared/utils/constants';

const LongTermFeed = ({
  longTermData,
  longTermId,
  t,
  defaultCheckedFeedType,
  userData,
  commentFeedsOnly,
  fileFeedsOnly,
  othersFeedsOnly,
}) => {
  return (
    <Feed
      commentFeedsOnly={commentFeedsOnly}
      fileFeedsOnly={fileFeedsOnly}
      othersFeedsOnly={othersFeedsOnly}
      objectId={longTermId}
      defaultCheckedFeedType={defaultCheckedFeedType}
      userData={userData}
      objectType={feedObjectType.obLongTermFeed}
      isProject={0}
      feedRegardingObjType={feedRegardingObjectType.longTermGoal}
    />
  );
};
export default LongTermFeed;

import { trackPromise } from 'react-promise-tracker';
import i18n from '../i18n';
import { authenticatedApi } from 'src/config/connectors';
import { GeneralApi, ProjectApi } from 'src/connectors/backend';

const LayoutService = {
  getMenuItem: async function () {
    const languageCode = i18n.language;
    const api = await authenticatedApi(GeneralApi);

    return trackPromise(api.generalGetMenuItem(languageCode));
  },

  getNotificationBellItems: async function () {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectGetMonthlyAlertByUser());
  },

  updateNotificationBell: async function () {
    const api = await authenticatedApi(ProjectApi);

    return trackPromise(api.projectUpdateMonthlyAlert());
  },
};

export default LayoutService;

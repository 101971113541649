import React, { Component } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Divider, Form, Input, Modal, Row, Select, Space, Table, Tooltip } from 'antd';
import Highlighter from 'react-highlight-words';
import { ModalFooterButtons } from '../../../shared/components/modal/modalFooterButtons';
import { annualGoalStatus } from '../../../shared/utils/constants';
import { requiredFields } from '../../../shared/utils/notifyMessages';
import '../../matrix.scss';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddObietivoAnnoModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      statusList: null,
      searchText: '',
      searchedColumn: '',
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  addData = (values) => {
    this.props.handleObAnnoSave(values);
  };

  render() {
    const { showObAnno, handleObAnnoClose, loadingButton, statusPickList, t, annualGoals } = this.props;

    const columns = [
      {
        title: `${t('general.nome')}`,
        width: '100%',
        ellipsis: {
          showTitle: true,
        },
        dataIndex: 'title',
        sorter: (a, b) => {
          a = a.title || '';
          b = b.title || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        render: (title) => (
          <Tooltip
            placement="bottom"
            title={title}>
            {title}
          </Tooltip>
        ),
        ...this.getColumnSearchProps('title'),
      },
    ];

    return (
      <Modal
        className="add_ob_modal"
        width={800}
        title={t('obiettiviAnnoPage.aggioungiObjAanuali')}
        open={showObAnno}
        destroyOnClose={true}
        onCancel={handleObAnnoClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleObAnnoClose}
            formId={'newObAnnoXmatrix'}
          />,
        ]}>
        <Card>
          <Form
            id="newObAnnoXmatrix"
            name="add_ob_lungo"
            layout="vertical"
            {...formItemLayout}
            onFinish={this.addData}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 20 }}>
                <Form.Item
                  label={t('general.nome')}
                  name="descripton"
                  rules={[{ required: true, message: requiredFields.required }]}>
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 8 }}>
                <Form.Item
                  label={t('general.stato')}
                  name="status"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={annualGoalStatus.active}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}>
                    {statusPickList &&
                      statusPickList.map((item) => (
                        <Option
                          value={item.statusID}
                          key={item.statusID}>
                          {item.statusDescription}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              {annualGoals && annualGoals.length > 0 && (
                <>
                  <Divider />
                  <Col
                    className="gutter-row"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Card title={t('xMatrixPage.objAnno')}>
                      <Table
                        size="small"
                        className="table-height"
                        columns={columns}
                        dataSource={annualGoals}
                        bordered={true}
                        rowKey={(obj) => obj.annualGoalID}
                      />
                    </Card>
                  </Col>
                </>
              )}
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default AddObietivoAnnoModal;

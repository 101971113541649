import { Form } from 'antd';
import React, { Component } from 'react';

import { CheckOutlined, CloseOutlined, InfoCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, Modal, Row, Select, Space, Switch, Table, Tooltip, Typography, message } from 'antd';
import moment from 'moment';

import Highlighter from 'react-highlight-words';
import CommonService from '../../../../../services/commonService';
import { ModalFooterButtons } from '../../../../shared/components/modal/modalFooterButtons';
import { projectTeamMembersColums, userRolesCodes } from '../../../../shared/utils/constants';
import { capitalizeFirstLetter } from '../../../../shared/utils/functions';
import { notifyMessages } from '../../../../shared/utils/notifyMessages';
import { withTranslation } from 'react-i18next';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddMemberModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingUsersAvailability: false,
      userIds: [],
      userAvailabilityList: [],
      selectedRowKeys: props.teamMembers.map((a) => a.userID),
    };
  }

  componentWillMount() {
    this.retrieveUsersAvailability();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  // Retrieve list of users availability
  async retrieveUsersAvailability() {
    const { projectId } = this.props;
    this.setState({ loadingUsersAvailability: true });
    await CommonService.getUsersAvailability(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          resp.responseObject.value.map((item, index) => {
            let disponibility = 0;
            for (let col in item) {
              if (
                col !== projectTeamMembersColums.fullName &&
                col !== projectTeamMembersColums.jobTitle &&
                col !== projectTeamMembersColums.userID
              ) {
                disponibility += item[col];
              }
            }
            item['Disponibility'] = disponibility;
            item['key'] = index.toString();
          });

          this.setState({ userAvailabilityList: resp.responseObject.value });
          this.setState({ loadingUsersAvailability: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingUsersAvailability: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingUsersAvailability: false });
      });
  }

  updateMembers = (values) => {
    this.props.handelTeamMemberModalSave(values);
  };

  isTeamMember = (userId) => {
    const { teamMembers } = this.props;

    if (teamMembers.length > 0) {
      let usersTeamId = teamMembers.map((u) => u.userID);
      return usersTeamId.indexOf(userId) > -1;
    }
    return false;
  };

  renderColumns = (userAvailabilityList) => {
    let columns = [];
    let { t } = this.props;

    if (userAvailabilityList && userAvailabilityList.length > 0) {
      let firstRow = userAvailabilityList[0];

      for (const col in firstRow) {
        if (col === projectTeamMembersColums.fullName) {
          columns.unshift({
            title: `${t('general.nome')}`,
            dataIndex: 'FullName',
            key: 'FullName',
            width: 170,
            fixed: 'left',
            sorter: (a, b) => {
              a = a.FullName || '';
              b = b.FullName || '';
              return a.localeCompare(b);
            },
            showSorterTooltip: [false],
            ellipsis: {
              showTitle: false,
            },
            render: (title) => <Tooltip title={title}>{title}</Tooltip>,
            ...this.getColumnSearchProps('FullName'),
          });
        } else if (col === projectTeamMembersColums.jobTitle) {
          columns.push({
            title: `${t('utentiPage.titoloLavoro')}`,
            dataIndex: 'JobTitle',
            key: 'JobTitle',
            width: 170,
            sorter: (a, b) => {
              a = a.JobTitle || '';
              b = b.JobTitle || '';
              return a.localeCompare(b);
            },
            showSorterTooltip: [false],
            ellipsis: {
              showTitle: false,
            },
            render: (title) => <Tooltip title={title}>{title}</Tooltip>,
          });
        } else if (col === projectTeamMembersColums.disponibility) {
          columns.splice(2, 0, {
            title: `${t('utentiPage.disponibilita')}`,
            dataIndex: 'Disponibility',
          });
        } else if (
          col === projectTeamMembersColums.userID ||
          col === projectTeamMembersColums.key ||
          col === projectTeamMembersColums.hasOpenActivities ||
          col === projectTeamMembersColums.isDeleted
        ) {
          continue;
        } else {
          let month = moment(col).format('MMM YYYY');
          columns.push({
            dataIndex: col,
            title: capitalizeFirstLetter(month),
          });
        }
      }
      return columns;
    }
    return columns;
  };

  checkUsersDisponibility = (checked, sponsorAndTeamLider) => {
    const { userAvailabilityList } = this.state;
    if (userAvailabilityList) {
      if (checked) {
        let filteredData = userAvailabilityList.filter(function (item) {
          return item.Disponibility > 0 || sponsorAndTeamLider.indexOf(item.UserID) !== -1;
        });
        this.setState({ userAvailabilityList: filteredData });
      } else {
        this.retrieveUsersAvailability();
      }
    }
  };

  onSelectChange = (selectedRowKeys) => {
    this.setState({ selectedRowKeys });
  };

  render() {
    const { showTeamMemberModal, handelTeamMemberModalClose, teamMembers, loadingButton, t } = this.props;
    const { userAvailabilityList, loadingUsersAvailability, selectedRowKeys } = this.state;

    let sponsorAndTeamLider = [];

    if (teamMembers.length > 0) {
      sponsorAndTeamLider = teamMembers
        .filter((obj) => obj.roleCode === userRolesCodes.sponsor || obj.roleCode === userRolesCodes.teamLeader)
        .map((u) => u.userID);
    }

    let users = [];

    userAvailabilityList.forEach((item) => {
      const value = item.UserID;
      users.push({
        value,
        disabled: sponsorAndTeamLider.indexOf(item.UserID) !== -1 || item.HasOpenActivities || item.IsDeleted,
        key: item.UserID,
        username: item.UserID,
        // label: (sponsorAndTeamLider.indexOf(item.UserID) !== -1 && item.HasOpenActivities) ? item.FullName : <MemberName data={item} t={t} />,
        label: item.FullName,
        title:
          sponsorAndTeamLider.indexOf(item.UserID) !== -1 && item.HasOpenActivities
            ? item.FullName
            : item.HasOpenActivities
              ? t('proggetiPage.hasActivity')
              : item.FullName,
      });
    });

    const rowSelection = {
      selectedRowKeys,
      onChange: this.onSelectChange,
      hideSelectAll: true,
      getCheckboxProps: (record) => ({
        disabled: sponsorAndTeamLider.indexOf(record.UserID) !== -1 || record.HasOpenActivities || record.IsDeleted,
      }),
    };

    return (
      <Modal
        className="team_modal"
        width={1100}
        title={t('proggetiPage.gestireMembriProgetto')}
        open={showTeamMemberModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handelTeamMemberModalClose}
        footer={[
          <ModalFooterButtons
            disabled={selectedRowKeys && selectedRowKeys.length > 0 ? false : true}
            loadingButton={loadingButton}
            modalClose={handelTeamMemberModalClose}
            formId={'projectMembersForm'}
          />,
        ]}>
        <Card className="sub450-card-body">
          {userAvailabilityList && (
            <Form
              id="projectMembersForm"
              name="add_ob_lungo"
              layout="vertical"
              {...formItemLayout}
              onFinish={(values) => this.updateMembers(selectedRowKeys)}>
              <Row
                gutter={{ lg: 24 }}
                className="no-padding">
                <Col
                  className="gutter-row"
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <div className="ant-row ant-form-item">
                    <div className="ant-col ant-col-24 ant-form-item-label">
                      <label title={t('proggetiPage.membriProgetto')}>{t('proggetiPage.membriProgetto')}</label>
                    </div>
                    <div className="ant-col ant-col-24 ant-form-item-control">
                      <Select
                        value={loadingUsersAvailability ? [] : selectedRowKeys}
                        onChange={this.onSelectChange}
                        options={users}
                        showSearch
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder={t('general.seleziona')}
                        optionFilterProp="children"
                        filterOption={(input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0}
                        loading={loadingUsersAvailability}></Select>
                    </div>
                  </div>
                </Col>
              </Row>

              <Row
                gutter={{ lg: 24 }}
                className="no-padding">
                <Col
                  className="gutter-row"
                  style={{ marginBottom: 10 }}
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <span>{t('proggetiPage.soloDisponibili')} </span>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    defaultChecked={false}
                    onChange={(checked) => this.checkUsersDisponibility(checked, sponsorAndTeamLider)}
                  />
                </Col>
              </Row>

              {/* {loadingUsersAvailability && <Loader2 />} */}
              <Row
                gutter={{ lg: 24 }}
                className="no-padding">
                {userAvailabilityList && (
                  <Col
                    className="gutter-row overflow-user-list"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Table
                      rowSelection={rowSelection}
                      dataSource={userAvailabilityList}
                      columns={this.renderColumns(userAvailabilityList)}
                      bordered={true}
                      pagination={false}
                      scroll={{ x: 'auto' }}
                      size="small"
                      rowKey={(obj) => obj.UserID}
                      loading={loadingUsersAvailability}
                    />
                  </Col>
                )}
              </Row>
            </Form>
          )}
        </Card>
      </Modal>
    );
  }
}

export default withTranslation()(AddMemberModal);

import { Button } from 'antd';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { RiExpandLeftRightLine } from 'react-icons/ri';
import KanBanItem, { ItemTypes } from './kanban-item';
import { useDrop } from 'react-dnd';

const KanBanColumn = ({
  canCollapse = true,
  colGroupFn = undefined,
  icon,
  id,
  isDndEnabled = false,
  items,
  onDropFn,
  title,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false); // Stato per gestire l'animazione degli item
  const [groups, setGroups] = useState([]);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ItemTypes.CARD,
    canDrop: (item) => true,
    drop: (item) => onDropFn(item.colItem, id),
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  }));

  useEffect(() => {
    if (items.length) {
      setGroups([{ items }]);
      if (colGroupFn) {
        setGroups(colGroupFn(items));
      }
    } else {
      setGroups([{ items }]);
    }
    if (canCollapse) setIsCollapsed(items.length === 0);
  }, [colGroupFn, items, canCollapse]);

  const handleCollapseToggle = () => {
    if (isCollapsed) {
      // Se la colonna è già collassata, apri la colonna prima e poi mostra gli item
      setIsCollapsed(false);
      setTimeout(() => setIsAnimating(false), 300); // Rendi gli item visibili dopo che la colonna si è espansa
    } else {
      // Prima nascondi gli item, poi collassa la colonna
      setIsAnimating(true);
      setTimeout(() => setIsCollapsed(true), 300); // Dopo 300ms collassa la colonna
    }
  };

  return (
    <div
      ref={drop}
      className={classNames(
        'tw-flex tw-relative tw-flex-col tw-rounded-lg tw-shrink-0 tw-w-[335px] tw-p-2 tw-bg-zinc-100 tw-transition-all tw-duration-500 tw-ease-in-out',
        { '!tw-w-[40px]': isCollapsed },
      )}>
      {isCollapsed ? (
        <Button
          className="tw-mt-[4px]"
          onClick={handleCollapseToggle}
          type="text"
          size="small"
          icon={<RiExpandLeftRightLine />}></Button>
      ) : (
        <div className="tw-shrink-0 tw-justify-between tw-flex tw-gap-2 tw-items-center tw-pb-2 tw-z-30 tw-text-base tw-py-1 tw-bg-zinc-100 tw-w-full">
          <div
            className={classNames('tw-flex tw-capitalize tw-gap-2 tw-font-semibold', {
              'tw-opacity-0 tw-pointer-events-none': isAnimating, // Quando sta per collassare, nascondi gli item
              'tw-opacity-100': !isAnimating && !isCollapsed, // Quando si espande e la colonna è aperta, mostra gli item
            })}>
            {icon}
            <div className="tw-flex tw-truncate tw-gap-2">
              {title}
              <span className="tw-text-zinc-400">{items.length ? items.length : null}</span>
            </div>
          </div>
          <Button
            onClick={handleCollapseToggle}
            type="text"
            size="small"
            icon={<RiExpandLeftRightLine />}></Button>
        </div>
      )}

      <div
        className={classNames(
          'tw-overflow-y-auto tw-gap-[6px] tw-flex tw-flex-col tw-grow tw-transition-opacity tw-duration-300 tw-ease-in-out',
          {
            'tw-opacity-0 tw-pointer-events-none': isAnimating, // Quando sta per collassare, nascondi gli item
            'tw-opacity-100': !isAnimating && !isCollapsed, // Quando si espande e la colonna è aperta, mostra gli item
          },
        )}>
        {groups?.map((group, i) => (
          <div
            key={i}
            className="tw-flex tw-flex-col tw-gap-2">
            {group.labelContent ? group.labelContent : null}

            <div className="tw-w-full tw-flex tw-flex-col tw-gap-2">
              {group.items.length ? (
                group.items?.map((item, j) => (
                  <KanBanItem
                    key={j}
                    isDndEnabled={isDndEnabled}
                    item={item}
                  />
                ))
              ) : (
                <div
                  className={classNames(
                    'tw-h-[200px] tw-text-rest tw-flex tw-items-center tw-justify-center tw-text-center',
                    {
                      'tw-hidden': isCollapsed,
                      'tw-opacity-0 tw-pointer-events-none': isAnimating, // Quando sta per collassare, nascondi gli item
                      'tw-opacity-100': !isAnimating && !isCollapsed, // Quando si espande e la colonna è aperta, mostra gli item
                    },
                  )}>
                  Nessun elemento
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      {isOver && (
        <div className="tw-absolute tw-rounded-lg tw-inset-0 tw-bg-primary/10 tw-border-2 tw-border-dashed tw-border-primary tw-z-50"></div>
      )}
    </div>
  );
};

export default KanBanColumn;

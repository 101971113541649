import { EyeOutlined, MailOutlined, PhoneOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Card, Col, Divider, message, Popconfirm, Popover, Space, Tooltip, Typography } from 'antd';
import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import { isTeamleader } from '../utils/functions';
import { notifyMessages } from '../utils/notifyMessages';
import './userBadge.scss';

const { Meta } = Card;
const { Text } = Typography;

const OnCallUserContent = ({ member, userData, setUserOnCallAction }) => {
  // let isRoleTeamLeader = userData && Object.keys(userData).length > 0 ? isTeamleader(userData) : false;
  const { t } = useTranslation();

  return (
    <span>
      <>
        {member.onCall === 1 && (
          <Popconfirm
            placement="topRight"
            title={t('utentiPage.rimuovereOnCall')}
            // onConfirm={() => this.setUserOnCall(member.userID, 0)}
            onConfirm={() => setUserOnCallAction(member.userID, 0)}
            okText={t('general.si')}
            cancelText={t('general.no')}>
            {/* <Tooltip  title={t('utentiPage.utenteOnCal')}>
                        </Tooltip> */}
            <Tooltip title={t('utentiPage.utenteOnCal')}>
              <PhoneOutlined style={{ color: '#16a34a' }} />
            </Tooltip>
          </Popconfirm>
        )}

        {member.onCall !== 1 && (
          <Popconfirm
            placement="topRight"
            title={t('utentiPage.vuoiImpostareOnCall')}
            // onConfirm={() => this.setUserOnCall(member.userID, 1)}
            onConfirm={() => setUserOnCallAction(member.userID, 1)}
            okText={t('general.si')}
            cancelText={t('general.no')}>
            <Tooltip title={t('utentiPage.impostareOnCall')}>
              <PhoneOutlined style={{ color: '#606060' }} />
            </Tooltip>
          </Popconfirm>
        )}
      </>
    </span>
  );
};

class UserBadge extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      membersList: [],
      ellipsis: true,
    };
  }

  componentDidMount() {}

  userPositionClass = (role) => {
    if (role === 'Sponsor') {
      return 'positionBarSponsor';
    } else if (role === 'Team Leader') {
      return 'positionBarLeader';
    } else {
      return 'positionBarMember';
    }
  };

  setUserOnCall = (userId, onCall) => {
    const { projectId } = this.props;
    let payload = {};
    payload['userID'] = userId;
    payload['projectID'] = projectId;
    payload['onCall'] = onCall;
    ProgettiService.setUserOnCall(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          //this.retrieveUsersAvailability();
        } else {
          message.error(notifyMessages.updateFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
      });
  };

  popOverContent = (member) => {
    const { userData } = this.props;
    let isRoleTeamLeader = userData && Object.keys(userData).length > 0 ? isTeamleader(userData) : false;
    let t = this.props.t;

    return (
      <div>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 4 }}>
          {this.userAvatarImg()}
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 24 }}
          md={{ span: 24 }}
          lg={{ span: 20 }}>
          <Space
            direction="vertical"
            style={{ lineHeight: '1' }}>
            <Text strong>{member.fullName}</Text>
            <Text>{member.role}</Text>
            <Text>{member.emailAddress}</Text>
            <Divider style={{ margin: '5px 0' }} />
            <span>
              <MailOutlined className="usrIcons" />
              <UserOutlined className="usrIcons" />

              {/* { isRoleTeamLeader &&  */}
              {/* <>
                            {member.onCall === 1 && (
                                <Popconfirm
                                    placement="topRight"
                                    title={t('utentiPage.rimuovereOnCall')}
                                    onConfirm={() => this.setUserOnCall(member.userID, 0)}
                                    okText={t('general.si')}
                                    cancelText={t('general.no')}
                                >
                                    <Tooltip  title={t('utentiPage.utenteOnCal')}><Button type="dashed" icon={<PhoneOutlined style={{ color: "#0dcc2d" }} />}></Button></Tooltip>
                                </Popconfirm>
                            )
                            }

                            { member.onCall !== 1 && (
                                <Popconfirm
                                    placement="topRight"
                                    title={t('utentiPage.vuoiImpostareOnCall')}
                                    onConfirm={() => this.setUserOnCall(member.userID, 1)}
                                    okText={t('general.si')}
                                    cancelText={t('general.no')}
                                >
                                    <Tooltip  title={t('utentiPage.impostareOnCall')}><Button type="dashed" icon={<PhoneOutlined style={{ color: "#cecece" }} />}></Button></Tooltip>
                                </Popconfirm>
                            )
                            }
                            </> */}
              {/* } */}
            </span>
          </Space>
        </Col>
      </div>
    );
  };

  userAvatarImg = () => {
    const { user } = this.props;

    return user.profilePhoto ? <Avatar src={user.profilePhoto} /> : <Avatar>{user.initials}</Avatar>;
  };

  render() {
    const { user, userData } = this.props;
    const { ellipsis } = this.state;
    let t = this.props.t;

    return (
      <div className="user-badge">
        <Card
          actions={[
            // user.onCall === 1 ?
            // <Tooltip  title={t('utentiPage.utenteOnCal')}>
            //     <PhoneOutlined style={{ color: "#0dcc2d" }} key="oncall" />
            // </Tooltip> : "",
            <OnCallUserContent
              member={user}
              userData={userData}
              t={t}
              setUserOnCallAction={this.setUserOnCall}
            />,
            <Popover
              trigger="hover"
              placement="left"
              content={() => this.popOverContent(user)}>
              <EyeOutlined key="view" />
            </Popover>,
          ]}>
          <Meta
            avatar={this.userAvatarImg()}
            title={
              <Text
                style={ellipsis ? { width: '100%' } : undefined}
                ellipsis={ellipsis ? { tooltip: user.fullName } : false}>
                {user.fullName}
              </Text>
            }
            description={user.role}
          />
          {/* <div className='actions'>
                        <PhoneOutlined />
                        <EyeOutlined style={{marginLeft: '10px'}}/>
                    </div> */}
          {/* <CardActions user={user} popOverContent={() => this.popOverContent(user)}  t={t}/> */}
        </Card>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(UserBadge));

import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi } from 'src/config/connectors';
import { InsertGoalCategoryRequestDto, LongTermGoalApi, UpdateGoalCategoryRequestDto } from 'src/connectors/backend';
import i18n from '../../i18n';

const CategoryService = {
  // get category list
  getCategoryList: async function () {
    const api = await authenticatedApi(LongTermGoalApi);
    const currentLang = i18n.language;

    return trackPromise(api.longTermGoalGetGoalCategoryList(currentLang));
  },

  // get kpi data by id
  getCategoryByiD: async function (id: string) {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalGetGoalCategoryByID(id));
  },

  updateCategory: async function (payload: UpdateGoalCategoryRequestDto) {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalUpdateGoalCategory(payload));
  },

  addCategory: async function (payload: InsertGoalCategoryRequestDto) {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalInsertGoalCategory(payload));
  },

  // get icon list
  getIconList: async function () {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalCategoryIconlist());
  },
};

export default CategoryService;

import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Unauthorized from '../components/shared/errorPages/unauthorized/unauthorized';
import AuthWrappedRoutes from './auth/authWrappedRoutes';

export class Routers extends Component {
  render() {
    return (
      <>
        <Router>
          <Switch>
            {/* <Route exact path='/unauthorized' component={Unauthorized} /> */}

            <Route
              exact
              path="/unauthorized"
              component={Unauthorized}
            />
            <AuthWrappedRoutes />
          </Switch>
        </Router>
      </>
    );
  }
}

export default Routers;

import { DownOutlined } from '@ant-design/icons';
import { Button, Dropdown } from 'antd';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import LoadingBar from 'react-top-loading-bar';
import { languageList } from '../../../../../i18n';

const getFlagEmoji = (code) => {
  switch (code) {
    case 'it':
      return '🇮🇹';
    case 'en':
      return '🇺🇸';
    default:
      return '';
  }
};
export default function LanguageSwitcher() {
  const ref = useRef(null);

  const { i18n } = useTranslation();
  const getCurrentLng = i18n.language;
  const [languageName, setLanguageName] = React.useState(
    languageList?.find((item) => item.code === getCurrentLng)?.name,
  );

  const changeLanguage = (lng) => {
    ref.current.continuousStart();
    i18n.changeLanguage(lng);
    let lang = languageList?.find((item) => item.code === lng)?.name;
    setLanguageName(lang);
    window.location.reload();
  };

  const items = [
    {
      label: (
        <li
          className="tw-w-full"
          onClick={() => changeLanguage('it')}>
          {getFlagEmoji('it')} IT
        </li>
      ),
      key: 2,
    },
    {
      label: (
        <li
          className="tw-w-full"
          onClick={() => changeLanguage('en')}>
          {getFlagEmoji('en')} EN
        </li>
      ),
      key: 1,
    },
  ];

  return (
    <div className="tw-flex tw-items-center navbar-button">
      <LoadingBar
        color="white"
        ref={ref}
      />
      <Dropdown
        menu={{ items }}
        placement="bottom"
        arrow>
        <Button
          type="text"
          style={{ color: 'white' }}
          iconPosition="end"
          onClick={(e) => e.preventDefault()}
          icon={<DownOutlined />}>
          {languageName?.slice(0, 2).toUpperCase()}
          <span className="tw-mr-1"> {getFlagEmoji(languageName.slice(0, 2).toLowerCase())}</span>{' '}
        </Button>
      </Dropdown>
    </div>
  );
}

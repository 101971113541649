import moment from 'moment';
import 'moment/min/locales';
import React, { createContext, useLayoutEffect } from 'react';

const LocaleContext = createContext();

export const LocaleProvider = ({ children }) => {
  useLayoutEffect(() => {
    moment.locale(localStorage.getItem('i18nextLng') || 'en');
  }, []);

  const switchLocale = (newLocale) => {
    moment.locale(newLocale);
  };

  return <LocaleContext.Provider value={{ switchLocale }}>{children}</LocaleContext.Provider>;
};

export default LocaleContext;

import React from 'react';
import './projectList.scss';

import {
  ContainerOutlined,
  DownOutlined,
  DownloadOutlined,
  EditFilled,
  PlusOutlined,
  SearchOutlined,
} from '@ant-design/icons';
import { Button, Dropdown, Input, Space, Table, Tooltip, message } from 'antd';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import MatrixService from '../../../services/matrix/matrixService';
import ObProgettiService from '../../../services/matrix/obProgettiService';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import { addQueryParam, getQueryParam } from '../../../utils/url-utils';
import { XmatrixFilter } from '../../shared/components/xMatrixFilter/xMatrixFilter';
import TableLayout from '../../shared/tableLayout';
import { formatOfDate, projectStatus } from '../../shared/utils/constants';
import { handleDateConvert, isTeamMember } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import QuickAddProgettiModal from '../../xMatrix/data_tables/center/addQuickProgettiModal';
import ProjectsTimeline from '../projectsTimelineViewPage/projectsTimeline';

const optionsMenu = (props) => {
  let items = [
    {
      label: <li onClick={props.onNew}>{props.t('proggetiPage.aggiungiNuovoProgetto')}</li>,
      key: 1,
    },
    {
      label: <li onClick={props.onFastNew}>{props.t('xMatrixPage.quickAddProject')}</li>,
      key: 2,
    },
  ];
  return { items };
};

const NewProjectOptions = (props) => (
  <Dropdown
    key="more"
    overlayClassName="new-project-dropdown"
    menu={optionsMenu(props)}
    className="matrixComponentsPrint">
    <Button
      icon={<PlusOutlined />}
      type="primary">
      {props.t('buttons.aggiungiNuovo')}
    </Button>
  </Dropdown>
);

class ProjectListIndex extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'timeline',
      loadingData: false,
      searchText: '',
      searchedColumn: '',
      projectList: null,
      // projectRelationCheck: false,
      projectRelationCheck: true,
      selectedXmatrixInfo: null,
      selectedXmatrixValue: null,
      loadingExport: false,
      showQuickProjectModal: false,
      loadingQuickSave: false,
      activeXmatrixInfo: null,
      xmatrixPicklist: null,
      loadingPicklist: true,
    };
  }

  componentDidMount() {
    this.retrieveComponentData();

    this.setState({ view: getQueryParam('list') || 'timeline' });
  }

  retrieveComponentData = async () => {
    await this.getActiveXmatrix();
    await this.xmatrixPicklist();
    await this.retrieveProjectList();
  };

  exportProjectList = (projectList) => {
    const t = this.props.t;

    if (!projectList?.length) {
      message.warning(t('general.noDataFound'));

      return;
    }

    this.setState({ loadingExport: true });

    const formattedData = projectList.map((item) => ({
      projectCode: item.projectCode,
      name: item.name,
      parentProjectCode: item.parentProjectCode,
      parentName: item.parentName,
      committeeName: item.committeeName,
      divisionName: item.divisionName,
      statusDescription: item.statusDescription,
      startDate: item.startDate ? moment(item.startDate).format('DD/MM/YYYY') : '',
      endDate: item.endDate ? moment(item.endDate).format('DD/MM/YYYY') : '',
      teamLeaderFullName: item.teamLeaderFullName,
      teamLeaderEmailAddress: item.teamLeaderEmailAddress,
      sponsorFullName: item.sponsorFullName,
      sponsorEmailAddress: item.sponsorEmailAddress,
      teamMembersName: item.teamMembersName,
      teamMembersEmail: item.teamMembersEmail,
    }));

    const headers = [
      [
        'Codice',
        'Nome',
        'Codice Padre',
        'Nome Padre',
        'Comitato',
        'Divisione',
        'Stato',
        'Data Inizio',
        'Data Fine',
        'Team Leader',
        'Team Leader Email',
        'Sponsor',
        'Sponsor Email',
        'Nome Team Members',
        'Email Team Members',
      ],
    ];

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const fileName = t('general.progetti');

    const ws = XLSX.utils.json_to_sheet([]);
    XLSX.utils.sheet_add_json(ws, formattedData, { origin: 'A2', skipHeader: true });
    XLSX.utils.sheet_add_aoa(ws, headers);
    ws['!cols'] = Array.from({ length: 30 }, () => ({ wpx: 120 }));

    const wb = { Sheets: { Progetti: ws }, SheetNames: ['Progetti'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, fileName + fileExtension);
    this.setState({ loadingExport: false });
  };

  getDefaultXmatrix = (xmatrixPicklist) => {
    const defaultXmatrix = xmatrixPicklist?.find((x) => x.status === 1 && x.parentXMatrixID === null)?.xMatrixID;

    this.setState({ activeXmatrixInfo: defaultXmatrix });
  };

  async xmatrixPicklist() {
    await MatrixService.getXmatrixPicklist()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ xmatrixPicklist: resp.responseObject.value }, () => {
            this.setState({ loadingPicklist: false });
            this.getDefaultXmatrix(resp.responseObject.value);
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getActiveXmatrix() {
    await MatrixService.getActiveXmatrix()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          this.setState({ activeXmatrixInfo: respData.xMatrixID });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getSelectedXmatrixInfo(xMatrixId) {
    await MatrixService.getSelectedXmatrixInfo(xMatrixId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ selectedXmatrixInfo: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
          {/* <Button
                type="link"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button> */}
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    // onFilterDropdownVisibleChange: visible => {
    //   if (visible) {
    //     setTimeout(() => this.searchInput.select(), 100);
    //   }
    // },
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  // Retrieve project list
  async retrieveProjectList(xMatrixId) {
    const { projectRelationCheck, activeXmatrixInfo } = this.state;
    let xmId = xMatrixId ? xMatrixId : activeXmatrixInfo;

    if (!xmId) {
      return;
    }

    this.setState({ loadingData: true });

    await ProgettiService.getProgettiList(projectRelationCheck, xmId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projectList: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  onNew = () => {
    const { activeXmatrixInfo, selectedXmatrixValue } = this.state;
    let xmId = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;
    this.props.history.push(`/progetti/new/${xmId}`);
  };

  onChecked = (checked) => {
    const { selectedXmatrixValue } = this.state;
    this.setState({ projectRelationCheck: checked }, () => {
      this.retrieveProjectList(selectedXmatrixValue);
    });
  };

  getProjectsBySelectedXmatrix = (xMatrixId) => {
    this.setState({ activeXmatrixInfo: xMatrixId });
    this.setState({ selectedXmatrixValue: xMatrixId }, () => {
      this.retrieveProjectList(xMatrixId);
      this.getSelectedXmatrixInfo(xMatrixId);
    });
  };

  handleQuickProgettiClose = () => {
    this.setState({ showQuickProjectModal: false });
    this.setState({ loadingQuickSave: false });
  };

  toggleQuickObProgettiModal = () => {
    this.setState({ showQuickProjectModal: !this.state.showQuickProjectModal });
  };

  addQuickProject = (values) => {
    const { activeXmatrixInfo } = this.state;
    let xmId = activeXmatrixInfo?.xMatrixID;

    values['xMatrixID'] = xmId;
    values['secondaryProject'] = false;
    values['status'] = projectStatus.draft;
    values['fastCreation'] = true;
    values['hasExternalReview'] = false;

    const startDateXm = moment(activeXmatrixInfo.referencePeriod).startOf('year');
    const endDateXm = moment(activeXmatrixInfo.referencePeriod).endOf('year');
    values['startDate'] = handleDateConvert(startDateXm);
    values['endDate'] = handleDateConvert(endDateXm);

    this.setState({ loadingQuickSave: true });
    ObProgettiService.addObProgetti(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.handleQuickProgettiClose();
          this.retrieveProjectList();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingQuickSave: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingQuickSave: false });
      });
  };

  viewOptions = () => {
    let items = [
      {
        label: (
          <li
            onClick={() => {
              addQueryParam('list', 'default');
              this.setState({ view: 'default' });
            }}>
            Lista
          </li>
        ),
        key: 'list',
      },
      {
        label: (
          <li
            onClick={() => {
              addQueryParam('list', 'timeline');
              this.setState({ view: 'timeline' });
            }}>
            Timeline
          </li>
        ),
        key: 'timeline',
      },
      {
        label: <li type="secondary">Kanban</li>,
        disabled: true,
        key: 'kanban',
      },
    ];
    return { items };
  };

  render() {
    const {
      projectList,
      loadingData,
      selectedXmatrixInfo,
      loadingExport,
      showQuickProjectModal,
      loadingQuickSave,
      activeXmatrixInfo,
      loadingPicklist,
      xmatrixPicklist,
    } = this.state;

    const { userData, companyData } = this.props;

    let t = this.props.t;

    const columns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'name',
        sorter: (a, b) => {
          a = a.name || '';
          b = b.name || '';
          return a.localeCompare(b);
        },
        fixed: 'left',
        width: '400px',
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('name'),
      },
      {
        title: `${t('proggetiPage.codice')}`,
        dataIndex: 'projectCode',
        // width: "7%",
        // sorter: (a, b) => { return a.projectCode.localeCompare(b.projectCode) },
        sorter: (a, b) => {
          a = a.projectCode || '';
          b = b.projectCode || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        ...this.getColumnSearchProps('projectCode'),
        defaultSortOrder: 'ascend',
      },
      {
        title: `${t('proggetiPage.inizio')}`,
        dataIndex: 'startDate',
        // width: "7%",
        sorter: (a, b) => {
          a = a.startDate || '';
          b = b.startDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text, record) => moment(text).format(formatOfDate),
      },
      {
        title: `${t('proggetiPage.fine')}`,
        dataIndex: 'endDate',
        // width: "5%",
        sorter: (a, b) => {
          a = a.endDate || '';
          b = b.endDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        render: (text, record) => moment(text).format(formatOfDate),
      },
      {
        title: `${t('general.stato')}`,
        dataIndex: 'statusDescription',
        // sorter: (a, b) => { return a.projectCode.localeCompare(b.projectCode) },
        sorter: (a, b) => {
          a = a.statusDescription || '';
          b = b.statusDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        ...this.getColumnSearchProps('statusDescription'),
      },
      {
        title: `${t('general.comitato')}`,
        dataIndex: 'committeeName',
        ellipsis: {
          showTitle: true,
        },
        // sorter: (a, b) => { return a.teamLeaderFullName.localeCompare(b.teamLeaderFullName) },
        sorter: (a, b) => {
          a = a.committeeName || '';
          b = b.committeeName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        ...this.getColumnSearchProps('committeeName'),
      },
      {
        title: `${t('general.teamLeader')}`,
        dataIndex: 'teamLeaderFullName',
        // sorter: (a, b) => { return a.teamLeaderFullName.localeCompare(b.teamLeaderFullName) },
        sorter: (a, b) => {
          a = a.teamLeaderFullName || '';
          b = b.teamLeaderFullName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        ...this.getColumnSearchProps('teamLeaderFullName'),
      },
      {
        title: `${t('general.sponsor')}`,
        dataIndex: 'sponsorFullName',
        // sorter: (a, b) => { return a.sponsorFullName.localeCompare(b.sponsorFullName) },
        sorter: (a, b) => {
          a = a.sponsorFullName || '';
          b = b.sponsorFullName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        ...this.getColumnSearchProps('sponsorFullName'),
      },
      {
        title: `${t('general.divisione')}`,
        dataIndex: 'divisionName',
        // sorter: (a, b) => { return a.teamLeaderFullName.localeCompare(b.teamLeaderFullName) },
        sorter: (a, b) => {
          a = a.divisionName || '';
          b = b.divisionName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        ...this.getColumnSearchProps('divisionName'),
      },
      //  {
      //     key: 'semaphores',
      //     title: `${t('general.indicators')}`,
      //     width: '150px',
      //     render: (text, record) => (
      //       <div className="tw-flex tw-gap-2 tw-w-fit tw-items-center">
      //         <Tooltip
      //           placement="top"
      //           title={
      //             <TimeTooltip
      //               data={record}
      //               t={t}
      //             />
      //           }>
      //           <Col
      //             className="semaphore-section"
      //             xs={8}
      //             sm={8}>
      //             <TimeSemaphore
      //               className="green-color-bgr"
      //               data={record}
      //             />
      //           </Col>
      //         </Tooltip>
      //         <Tooltip
      //           placement="top"
      //           title={
      //             <BudgetTooltip
      //               data={record}
      //               t={t}
      //               companyData={companyData}
      //             />
      //           }>
      //           <Col
      //             className="semaphore-section"
      //             xs={8}
      //             sm={8}>
      //             <BudgetSemaphore data={record} />
      //           </Col>
      //         </Tooltip>
      //         <Tooltip
      //           placement="top"
      //           title={
      //             <QualityTooltip
      //               data={record}
      //               t={t}
      //             />
      //           }>
      //           <Col
      //             className="gutter-row semaphore-section no-border red-color-bgr"
      //             xs={8}
      //             sm={8}>
      //             <QualitySemaphore data={record} />
      //           </Col>
      //         </Tooltip>
      //       </div>
      //     ),
      //   },
      {
        key: 'action',
        render: (text, record) => {
          return (
            <>
              <div className="tw-flex tw-justify-end tw-flex-row tw-gap-2">
                {record.projectRepository && (
                  <Tooltip title={t('a3Page.repositoriProgetto')}>
                    <Button
                      type="text"
                      icon={<ContainerOutlined />}
                      href={record.projectRepository}
                      target="_blank"
                      rel="noreferrer"></Button>
                  </Tooltip>
                )}
                <Button
                  type="dashed"
                  icon={<EditFilled />}
                  href={`/progetti/id/${record.projectID}`}
                />
              </div>
            </>
          );
        },
      },
    ];

    let isRoleTeamMember = userData && Object.keys(userData).length > 0 ? isTeamMember(userData) : false;

    return (
      <>
        <QuickAddProgettiModal
          showQuickObProgetti={showQuickProjectModal}
          handleQuickObProgettiClose={this.handleQuickProgettiClose}
          handleQuickProgettiSave={this.addQuickProject}
          loadingButton={loadingQuickSave}
          selectedXmatrixInfo={selectedXmatrixInfo}
          t={t}
        />

        <TableLayout
          title={t('headerMenu.progetti')}
          className="[&_.ant-select]:tw-h-[40px] tw-p-6 tw-flex tw-flex-col tw-h-full tw-overflow-hidden [&_.ant-select-selector]:!tw-rounded-lg">
          <TableLayout.Actions>
            {/* <Form.Item label={t('proggetiPage.progettiSenzaRelazione')}>
                    <Switch
                      checkedChildren={<CheckOutlined />}
                      unCheckedChildren={<CloseOutlined />}
                      defaultChecked={false}
                      onChange={(checked) => this.onChecked(checked)}
                    />
                  </Form.Item> */}

            <Dropdown
              key="more"
              placement="bottomLeft"
              menu={this.viewOptions(this.setState)}>
              <Button data-testid="set-view">
                Imposta vista
                <DownOutlined />
              </Button>
            </Dropdown>

            {!isRoleTeamMember && (
              <NewProjectOptions
                onNew={this.onNew}
                onFastNew={this.toggleQuickObProgettiModal}
                t={t}
              />
            )}
          </TableLayout.Actions>
          <TableLayout.SubHeader>
            <div className="tw-flex tw-items-center tw-gap-2">
              {this.state.view !== 'timeline' && (
                <>
                  <XmatrixFilter
                    loadingPicklist={loadingPicklist}
                    xmatrixPicklist={xmatrixPicklist}
                    selectedXmatrixValue={activeXmatrixInfo}
                    onXmatrixChange={this.getProjectsBySelectedXmatrix}
                  />
                  <Button
                    loading={loadingExport}
                    onClick={() => this.exportProjectList(projectList)}
                    style={{ paddingLeft: '8px' }}
                    icon={<DownloadOutlined />}>
                    {t('proggetiPage.exportProjectList')}
                  </Button>
                </>
              )}
            </div>
          </TableLayout.SubHeader>
          <TableLayout.Content>
            {this.state.view === 'timeline' ? (
              <ProjectsTimeline
                companyData={this.state.companyData}
                xmatrixPicklist={this.state.xmatrixPicklist}
                activeXmatrixInfo={this.state.activeXmatrixInfo}
              />
            ) : (
              <Table
                sticky
                size="small"
                columns={columns}
                data-test="projects-table"
                dataSource={projectList}
                rowKey={(obj) => obj.projectID}
                scroll={{ x: 'calc(600px + 50%)' }}
                loading={loadingData}
                pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
              />
            )}
          </TableLayout.Content>
        </TableLayout>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ProjectListIndex));

import { trackPromise } from 'react-promise-tracker';
import { InsertProjectXMatrixRequestDto, UpdateProjectXMatrixRequestDto, XMatrixApi } from 'src/connectors/backend';
import { authenticatedApi } from 'src/config/connectors';

const ObProgettiService = {
  // update ob projects in matrix
  updateObProgetti: async function (payload: UpdateProjectXMatrixRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateProjectXMatrix(payload));
  },

  // add projects in matrix
  addObProgetti: async function (payload: InsertProjectXMatrixRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixInsertProjectXMatrix(payload));
  },

  removeProjectXmatrix: async function (projectId: string, xmatrixCode: string, deleteProject: boolean) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixDeleteProjectXMatrixRelation(projectId, xmatrixCode, deleteProject));
  },

  removeProjectDraftXmatrix: async function (projectId: string, xmatrixCode: string, deleteProject: boolean) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixDeleteProjectXMatrixDraftRelation(projectId, xmatrixCode, deleteProject));
  },
};

export default ObProgettiService;

import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi, GeneralApi, AdminInsertCompanyRequestDto } from 'src/config/connectors';

const CompaniesListPageService = {
  // get companies
  getCompaniesList: async function () {
    const api = await authenticatedApi(GeneralApi);
    return trackPromise(api.generalGetCompanyListByUserID());
  },

  // get company by ID
  getCompanyByiD: async function (id: string) {
    const api = await authenticatedApi(GeneralApi);
    return trackPromise(api.generalGetCompanyByID(id));
  },

  // add company
  addCompany: async function (payload: AdminInsertCompanyRequestDto) {
    const api = await authenticatedApi(GeneralApi);
    return trackPromise(api.generalInsertCompany(payload));
  },
};

export default CompaniesListPageService;

import confStaging from './configurations-staging.json';
import confProd from './configurations-prod.json';
import confGecko from './configurations-gecko.json';
import deployment from '../deploymentConfigs';
import { PublicClientApplication } from '@azure/msal-browser';

const configMap = {
  production: confProd,
  staging: confStaging,
  dev: confGecko,
  ci: confGecko,
};

const conf =
  configMap[deployment.env] ||
  (() => {
    throw new Error('Unsupported REACT_APP_ENV');
  })();

export const acquireToken = async (loginRedirectWhenNotAuthenticated = true) => {
  const testToken = localStorage.getItem('testToken');
  if (testToken) {
    return { accessToken: testToken };
  }

  await msalApp.initialize();
  const result = await msalApp.handleRedirectPromise();

  if (result) {
    msalApp.setActiveAccount(result.account);
  }

  const accounts = msalApp.getAllAccounts();

  if (accounts.length === 0 && loginRedirectWhenNotAuthenticated) {
    await msalApp.loginRedirect({ scopes: conf.scopes });
    return undefined;
  }

  const user = msalApp.getActiveAccount();
  const tokenRequest = {
    scopes: conf.scopes.filter((scope) => scope.startsWith('api://')),
    user,
  };

  try {
    return await msalApp.acquireTokenSilent(tokenRequest);
  } catch (error) {
    if ('errorCode' in error && error.errorCode === 'login_required') {
      await msalApp.acquireTokenRedirect({ ...tokenRequest, prompt: 'select_account' });
      return undefined;
    }

    throw error;
  }
};

export const msalApp = new PublicClientApplication({
  auth: {
    clientId: conf.clientId,
    authority: conf.authority,
    validateAuthority: conf.validateAuthority,
    postLogoutRedirectUri: deployment.client.href,
    redirectUri: deployment.client.href,
    navigateToLoginRequestUrl: conf.navigateToLoginRequestUrl,
  },
  cache: {
    cacheLocation: conf.cacheLocation,
    storeAuthStateInCookie: conf.storeAuthStateInCookie,
  },
});

export default msalApp;

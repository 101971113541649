import { CalendarOutlined, CloseOutlined, FilterOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Button, Card, Select, Tooltip } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import CommonService from '../../services/commonService';
import MatrixService from '../../services/matrix/matrixService';
import DashboardService from '../../services/pages/dashboardService';
import XMatrixFilterPanel from '../projects/xMatrixFilterPanel';
import Loader from '../shared/components/loader/loader';
import TableLayout from '../shared/tableLayout.jsx';
import { objectCodes, projectStatus, statusPickListCodes } from '../shared/utils/constants';
import './dashboard.scss';
import DashboardFiltersModal from './dashboardFiltersModal';
import ProjectCard from './projectCard';

const { Option } = Select;

const Dashboard = (props) => {
  const { t } = useTranslation();
  const [filters, setFilters] = useState({
    teamLeaders: [],
    sponsor: [],
    status: [],
    division: [],
    committie: [],
    semaphores: [],
    parentProject: [],
    filterCriteria: [],
    projectCode: [],
  });
  const [sortField, setSortField] = useState('projectCode');
  const [sortType, setSortType] = useState('Sort A to Z');
  const [dashboardData, setDashboardData] = useState(undefined);
  const [sortFields] = useState([
    { label: `${props.t('proggetiPage.nomeProgetto')}`, value: 'title' },
    { label: `${props.t('proggetiPage.codiceProgetto')}`, value: 'projectCode' },
    { label: `${props.t('general.start')}`, value: 'startDate' },
    { label: `${props.t('general.end')}`, value: 'endDate' },
  ]);
  const [sortTypes] = useState(['Sort A to Z', 'Sort Z to A']);
  const [expanded, setExpanded] = useState(false);
  const [selectedXmatrixValue, setSelectedXmatrixValue] = useState(null);
  const [activeXmatrixInfo, setActiveXmatrixInfo] = useState(null);
  const [selectedFiltersLabel, setSelectedFiltersLabel] = useState([]);
  const [dashboardFilters, setDashboardFilters] = useState({});
  const [showFiltersModal, setShowFiltersModal] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  useEffect(() => {
    let defaultFilterLabels = [
      { value: 'status', label: props.t('dashboardProgettiPage.statoProgetto') },
      { value: 'selectNotRelatedProjects', label: props.t('proggetiPage.progettiSenzaRelazione') },
    ];

    const localFilters = JSON.parse(localStorage.getItem('dashboardFilters'));
    const preSelectedComittie = props.match.params.committee;

    if (localFilters && Object.keys(localFilters).length > 0) {
      let actualFilters = localFilters;

      if (preSelectedComittie) {
        actualFilters['committie'] = preSelectedComittie;
      }

      setDashboardFilters(actualFilters);
      retrieveComponentData();
      preCheckLocalFiltersLabels(actualFilters);
    } else {
      manageFiltersChanged({ filter: 'status', value: projectStatus.attivo });
      manageFiltersChanged({ filter: 'selectNotRelatedProjects', value: true });

      if (preSelectedComittie) {
        manageFiltersChanged({ filter: 'committie', value: preSelectedComittie });
        defaultFilterLabels.push({ value: 'committieFilter', label: props.t('general.commitato') });
      }

      setSelectedFiltersLabel(defaultFilterLabels);
      retrieveComponentData();
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      if (activeXmatrixInfo) {
        await retrieveDashboardData();
        await retrieveParentProject();

        retrieveTeamLeaders();
        retrieveSponsor();
        retrieveStatus();
        retrieveDivisions();
        retrieveSemaphores();
        retrieveProjectsOption();
        retrieveCommitties();
        retrieveProjectCode();
      }
    };

    fetch();
  }, [activeXmatrixInfo]);

  const addFilterLabel = (filterLabelObject) => {
    addItemToFilterLabelList(filterLabelObject);
  };

  const manageFiltersChanged = (obj) => {
    let updatedFilters = { ...dashboardFilters };
    if (obj.value === '') {
      delete updatedFilters[obj.filter];
    } else {
      if (
        obj.filter === 'status' ||
        obj.filter === 'timeSemaphore' ||
        obj.filter === 'qualitySemaphore' ||
        obj.filter === 'budgetSemaphore' ||
        obj.filter === 'comboSemaphore'
      ) {
        updatedFilters[obj.filter] = parseInt(obj.value);
      } else {
        updatedFilters[obj.filter] = obj.value;
      }
    }
    setDashboardFilters(updatedFilters);
    localStorage.setItem('dashboardFilters', JSON.stringify(updatedFilters));
  };

  const checkFilterLabelName = (filter) => {
    switch (filter) {
      case 'status':
        return t('dashboardProgettiPage.statoProgetto');
      case 'teamLider':
        return t('dashboardProgettiPage.teamLeader');
      case 'projectCode':
        return t('proggetiPage.codiceProgetto');
      case 'division':
        return t('dashboardProgettiPage.divisioniReparti');
      case 'committie':
        return t('general.commitato');
      case 'comboSemaphore':
        return t('dashboardProgettiPage.analisiAllarmi');
      case 'timeSemaphore':
        return t('dashboardProgettiPage.tempoFilter');
      case 'budgetSemaphore':
        return t('dashboardProgettiPage.budgetFilter');
      case 'qualitySemaphore':
        return t('dashboardProgettiPage.qualitaFilter');
      case 'filterCriteria':
        return t('dashboardProgettiPage.tipologiaProgetti');
      case 'parentProject':
        return t('general.progetto');
      case 'sponsor':
        return t('general.sponsor');
      case 'selectNotRelatedProjects':
        return t('proggetiPage.progettiSenzaRelazione');
      default:
        return '-';
    }
  };

  const checkFilterExistOnFilterLabelsList = (filterObj) => {
    return selectedFiltersLabel.some((el) => el.value === filterObj.value);
  };

  const addItemToFilterLabelList = (object) => {
    if (!checkFilterExistOnFilterLabelsList(object)) {
      setSelectedFiltersLabel((prevSelectedFiltersLabel) => [...prevSelectedFiltersLabel, object]);
    }
  };

  const preCheckLocalFiltersLabels = (localFiltersData) => {
    for (const filter in localFiltersData) {
      if (filter === 'XMatrixID') {
        continue;
      } else {
        let filterObj = { value: filter, label: checkFilterLabelName(filter) };
        addItemToFilterLabelList(filterObj);
      }
    }
  };

  const retrieveComponentData = async () => {
    await getActiveXmatrix();
  };

  const getActiveXmatrix = async () => {
    try {
      const response = await MatrixService.getActiveXmatrix();
      const resp = response.data;
      if (resp.success) {
        let respData = resp.responseObject.value;
        setActiveXmatrixInfo(respData);
      }
    } catch (error) {
      // gestione dell'errore, se necessaria
    }
  };

  const retrieveTeamLeaders = async () => {
    await DashboardService.getTeamLaderFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          usr.push({ value: '', label: t('general.seleziona') + '...', key: 'teamLiderNan' });

          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          setFilters((prevFilters) => ({ ...prevFilters, teamLeaders: usr }));
        }
      });
  };

  const retrieveSponsor = async () => {
    await DashboardService.getSponsorFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let usr = [];
          let respData = resp.responseObject.value;
          usr.push({ value: '', label: t('general.seleziona') + '...', key: 'sponsorNan' });

          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });

          setFilters((prevFilters) => ({ ...prevFilters, sponsor: usr }));
        }
      });
  };

  const retrieveProjectCode = async () => {
    await DashboardService.getProjectCodeData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setFilters((prevFilters) => ({ ...prevFilters, projectCode: resp.responseObject.value }));
        }
      });
  };

  const retrieveDivisions = async () => {
    await DashboardService.getDivisionFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setFilters((prevFilters) => ({ ...prevFilters, division: resp.responseObject.value }));
        }
      });
  };

  const retrieveCommitties = async () => {
    await DashboardService.getCommittiesFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setFilters((prevFilters) => ({ ...prevFilters, committie: resp.responseObject.value }));
        }
      });
  };

  const retrieveStatus = async () => {
    const objectCode = statusPickListCodes.statusProjects;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setFilters((prevFilters) => ({ ...prevFilters, status: resp.responseObject.value }));
        }
      });
  };

  const retrieveSemaphores = async () => {
    await DashboardService.getSemaphoreFilterData()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setFilters((prevFilters) => ({ ...prevFilters, semaphores: resp.responseObject.value }));
        }
      })
      .catch((error) => {});
  };

  const retrieveParentProject = async (xMatrixId) => {
    const xmId = xMatrixId ? xMatrixId : activeXmatrixInfo?.xMatrixID;
    await DashboardService.getParentProjectFilterData(xmId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setFilters((prevFilters) => ({ ...prevFilters, parentProject: resp.responseObject.value }));
        }
      })
      .catch((error) => {});
  };

  const retrieveProjectsOption = async () => {
    const objectCode = objectCodes.projectsOption;
    await CommonService.getProjectsOptionData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          setFilters((prevFilters) => ({ ...prevFilters, filterCriteria: resp.responseObject.value }));
        }
      })
      .catch((error) => {});
  };

  const retrieveDashboardData = async (xMatrixId) => {
    const xmId = xMatrixId ? xMatrixId : activeXmatrixInfo?.xMatrixID;
    const response = await DashboardService.getDashboardData(
      dashboardFilters,
      xmId,
      dashboardFilters.selectNotRelatedProjects,
    );
    const data = response.data;
    if (data.success) {
      setDashboardData(data.responseObject.value);
    }
  };

  const onFilterApplied = () => {
    handelModalFiltersClose();
    retrieveDashboardData();
  };

  const onFilterReset = () => {
    handelModalFiltersClose();
    setDashboardFilters({});
    localStorage.setItem('dashboardFilters', JSON.stringify({}));
    retrieveComponentData();
    setSelectedFiltersLabel([]);
  };

  const getSortedDashboard = () => {
    let tempDashboardData = [...dashboardData];
    if (sortField === 'title') {
      tempDashboardData = dashboardData?.map((project) => ({
        ...project,
        title: project['projectCode'] + project['name'],
      }));
    }
    return tempDashboardData.sort((a, b) => {
      if (sortType === 'Sort A to Z') {
        return a[sortField] > b[sortField] ? 1 : -1;
      } else {
        return a[sortField] > b[sortField] ? -1 : 1;
      }
    });
  };

  const clearSelectedFilterOnLabelList = (filterName) => {
    if (filterName || filterName.length > 0) {
      const clearedFilteredList = selectedFiltersLabel.filter((el) => el.value !== filterName);
      setSelectedFiltersLabel(clearedFilteredList);
    }
  };

  const removeSelectedFilter = (filter) => {
    switch (filter) {
      case 'teamLider':
        manageFiltersChanged({ filter: 'teamLider', value: '' });
        break;
      case 'sponsor':
        manageFiltersChanged({ filter: 'sponsor', value: '' });
        break;
      case 'status':
        manageFiltersChanged({ filter: 'status', value: '' });
        break;
      case 'division':
        manageFiltersChanged({ filter: 'division', value: '' });
        break;
      case 'committie':
        manageFiltersChanged({ filter: 'committie', value: '' });
        break;
      case 'timeSemaphore':
        manageFiltersChanged({ filter: 'timeSemaphore', value: '' });
        break;
      case 'budgetSemaphore':
        manageFiltersChanged({ filter: 'budgetSemaphore', value: '' });
        break;
      case 'qualitySemaphore':
        manageFiltersChanged({ filter: 'qualitySemaphore', value: '' });
        break;
      case 'comboSemaphore':
        manageFiltersChanged({ filter: 'comboSemaphore', value: '' });
        break;
      case 'parentProject':
        manageFiltersChanged({ filter: 'parentProject', value: '' });
        break;
      case 'filterCriteria':
        manageFiltersChanged({ filter: 'filterCriteria', value: '' });
        break;
      case 'projectCode':
        manageFiltersChanged({ filter: 'projectCode', value: '' });
        break;
      case 'selectNotRelatedProjects':
        manageFiltersChanged({ filter: 'selectNotRelatedProjects', value: false });
        break;
      default:
        break;
    }

    retrieveDashboardData();
    clearSelectedFilterOnLabelList(filter);
  };

  const getProjectsBySelectedXmatrix = (xMatrixId) => {
    setSelectedXmatrixValue(xMatrixId);
    retrieveDashboardData(xMatrixId);
    retrieveParentProject(xMatrixId);
  };

  const handelModalFiltersClose = () => {
    setShowFiltersModal(false);
  };

  const toggleFiltersModal = () => {
    setShowFiltersModal(true);
  };

  const sortedDashboardData = dashboardData?.length > 0 ? getSortedDashboard() : [];

  return (
    <Card
      className="!tw-shadow-none"
      bordered={false}>
      <TableLayout
        className="dashboard [&_.ant-select]:tw-h-[38px] [&_.ant-select-selector]:!tw-rounded-lg"
        title={
          <div className="tw-flex tw-items-center tw-gap-2">
            <XMatrixFilterPanel
              activeXmatrixInfo={activeXmatrixInfo}
              onXmatrixSelect={getProjectsBySelectedXmatrix}
            />
            <h2 className="tw-text-2xl tw-flex-1 tw-font-medium">{t('dashboardProgettiPage.dashboardProgetti')}</h2>
          </div>
        }>
        <TableLayout.Actions>
          <DashboardFiltersModal
            key={'filters'}
            showFiltersModal={showFiltersModal}
            handelModalFiltersClose={handelModalFiltersClose}
            t={t}
            filters={filters}
            applyFilters={onFilterApplied}
            resetFilters={onFilterReset}
            manageFiltersChanged={manageFiltersChanged}
            dashboardFilters={dashboardFilters}
            addFilterLabel={addFilterLabel}
          />

          <Select
            value={sortField}
            placeholder={t('dashboardProgettiPage.ordinaPer')}
            onChange={(val) => setSortField(val)}>
            {sortFields.map((item) => (
              <Option
                value={item.value}
                key={item.value}>
                {item.label}
              </Option>
            ))}
          </Select>

          <Select
            value={sortType}
            placeholder={t('dashboardProgettiPage.tipoOrdinamento')}
            onChange={(val) => setSortType(val)}>
            {sortTypes.map((item) => (
              <Option
                value={item}
                key={item}>
                {item}
              </Option>
            ))}
          </Select>
          <Button
            icon={<FilterOutlined />}
            onClick={() => toggleFiltersModal()}>
            {t('dashboardProgettiPage.dashboardFilter')}
          </Button>

          <Tooltip title={t('dashboardProgettiPage.fullScreen')}>
            <Button
              icon={<FullscreenOutlined />}
              onClick={() => handleToggle()}></Button>
          </Tooltip>
        </TableLayout.Actions>

        <TableLayout.SubHeader>
          <div className="tw-border-b tw-mt-4 tw-flex tw-justify-between tw-pb-4 tw-items-center">
            <div className="tw-flex tw-items-center tw-gap-2">
              {selectedFiltersLabel.map((item, index) => (
                <Button
                  key={index}
                  className="fixed-grey-button">
                  {item.label} <CloseOutlined onClick={() => removeSelectedFilter(item.value)} />
                </Button>
              ))}
            </div>
            <span className="tw-flex tw-gap-2">
              <CalendarOutlined />
              {t('dashboardProgettiPage.settimanaCorrente')}: {moment(moment(), 'MMDDYYYY').isoWeek()}
            </span>
          </div>
        </TableLayout.SubHeader>

        <TableLayout.Content>
          <Loader />
          {!dashboardData}

          <div className="tw-grid tw-gap-2 md:tw-grid-cols-2 lg:tw-grid-cols-3 xl:tw-grid-cols-4">
            {dashboardData &&
              sortedDashboardData.map((project, index) => {
                return (
                  <ProjectCard
                    project={project}
                    key={index}
                    expanded={expanded}
                    t={t}
                  />
                );
              })}
          </div>
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(Dashboard));

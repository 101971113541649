import { Button, message } from 'antd';
import BraftEditor from 'braft-editor';
import 'braft-editor/dist/index.css';
import { useState } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { richTectEditoControlls } from '../../shared/utils/constants';
import { notifyMessages, requiredFields } from '../../shared/utils/notifyMessages';

import ProgettiService from '../../../services/pages/projectServices/progettiService';

import classNames from 'classnames';

const ProjectGoalEditor = (props) => {
  const [isTouched, setIsTouched] = useState(false);
  const [loadingEditorButton, setLoadingEditorButton] = useState(false);
  const [editorState, setEditorState] = useState(BraftEditor.createEditorState(props.currentProject.projectGoal));

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!editorState || editorState.isEmpty()) {
      message.error(requiredFields.required);
      return;
    }
    const values = { projectGoal: editorState.toHTML() };
    updateFieldToObject(values);
    setIsTouched(false);
  };

  const updateFieldToObject = (values) => {
    setLoadingEditorButton(true);

    var fieldName = Object.keys(values);
    let editorContentToHtml = BraftEditor.createEditorState(values[fieldName]).toHTML();
    const { currentProject } = props;

    let payloadObj = {};
    payloadObj['projectId'] = currentProject.projectID;
    payloadObj['projectFieldName'] = fieldName[0];
    payloadObj['projectFieldValue'] = editorContentToHtml;

    ProgettiService.updateEditorField(payloadObj)
      .then(() => {
        setLoadingEditorButton(false);
        message.success(notifyMessages.updateSuccess);
      })
      .catch(() => {
        setLoadingEditorButton(false);
      });
  };

  const { currentProject, isPresentationMode, isEditable } = props;
  const { t } = useTranslation();

  return (
    <div className="tw-h-full">
      {currentProject && (
        <form
          className="tw-h-full tw-flex tw-flex-col"
          data-testid="expectedResultForm"
          id="projectEditorForm"
          onSubmit={handleSubmit}>
          <h1
            className={classNames('tw-text-xl tw-mb-2 tw-font-semibold', {
              '!tw-text-3xl !tw-mb-4': isPresentationMode,
            })}>
            {t('proggetiPage.risultatoAtteso')}
          </h1>
          <div className="tw-rounded-lg tw-grow tw-overflow-hidden">
            <BraftEditor
              className={classNames({
                '[&_.bf-content]:tw-h-full tw-min-h-full [&_.bf-content]:tw-text-3xl': isPresentationMode,
              })}
              contentFormat="html"
              readOnly={isPresentationMode || !isEditable}
              language="en"
              id="goal-editor-text"
              value={editorState}
              onChange={(newState) => {
                setEditorState(newState);
                setIsTouched(true);
              }}
              controls={isPresentationMode ? [] : richTectEditoControlls}
            />
          </div>

          {!isPresentationMode && (
            <div
              key="buttons"
              className="tw-ml-auto tw-w-fit tw-mt-4">
              <Button
                type="primary"
                htmlType="submit"
                key="submit"
                disabled={!isTouched}
                loading={loadingEditorButton}>
                {t('buttons.salva')}
              </Button>
            </div>
          )}
        </form>
      )}
    </div>
  );
};

export default withTranslation()(ProjectGoalEditor);

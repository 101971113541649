import { AiFillCheckCircle } from 'react-icons/ai';
import { BsFillRecordCircleFill } from 'react-icons/bs';

import {
  CheckOutlined,
  DeleteOutlined,
  DownOutlined,
  EditFilled,
  EditOutlined,
  ExportOutlined,
  PauseCircleFilled,
  PlusOutlined,
  SearchOutlined,
  SyncOutlined,
  WarningOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Input, message, Popconfirm, Space, Table, Tag, Tooltip } from 'antd';
import ButtonGroup from 'antd/es/button/button-group';
import moment from 'moment';
import { Component } from 'react';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';
import { AiFillPlayCircle } from 'react-icons/ai';
import { RiCollapseDiagonal2Fill, RiExpandDiagonal2Fill } from 'react-icons/ri';
import { KanBanView, List, Summary } from '../../../../assets/icons';
import CommonService from '../../../../services/commonService';
import ActivityService from '../../../../services/pages/activitiesServices';
import DeliverablePageService from '../../../../services/pages/deliverableService';
import DeliverableService from '../../../../services/pages/projectServices/deliverableServices';
import { addQueryParam, getQueryParam } from '../../../../utils/url-utils';
import TableLayout from '../../../shared/tableLayout';
import { userIsProjectTeamLeaderOrSponsor } from '../../../shared/utils/authRolesProvilege/authRolesPrivilege';
import { activityStatus, deliverableStatus, formatOfDate, statusPickListCodes } from '../../../shared/utils/constants';
import { checkDeliverableStatus, generateUserInitials, updateNotificationBell } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import EditProjectActivity from '../projectActivities/editProjectActivity';
import { DeliverableIcon, PhaseIcon, TaskIcon } from './kanban-view/components/icons';
import PrsKanBanView from './kanban-view/index';
import NewProjectDeliverable from './newDeliverable';

const getRandomId = () => crypto.randomUUID();

class ProjectDeliverable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      searchText: '',
      searchedColumn: '',
      loadingButton: false,
      loadingDeliverables: false,
      showNewDeliverableModal: false,
      loadingKpi: false,
      loadingFastClose: false,
      deliverablesList: [],
      expandedKeys: [],
      view: 'list',
      selectedDeliverableData: null,
      statusListFilter: [],
      activeStatusFilters: [
        `${props.t('status.draft')}`,
        `${props.t('status.aperto')}`,
        `${props.t('status.sospeso')}`,
      ],
    };
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            size="small"
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  componentWillMount() {
    void this.retrieveDeliverables();
    void this.retrieveStatusList();

    this.setState({ view: getQueryParam('view') || 'list' });
  }

  componentWillUnmount() {
    this.setState({ loadingDeliverables: false });
  }

  handleDateChange(date) {
    if (date) {
      let formatedDate = moment(date).format('YYYY-MM-DD');
      return formatedDate;
    }
    return null;
  }

  onExpand = async (expanded, record) => {
    const { expandedKeys } = this.state;

    if (expanded) {
      this.setState({ expandedKeys: [...expandedKeys, record.key] });
    } else {
      const index = expandedKeys.indexOf(record.key);
      if (index > -1) {
        expandedKeys.splice(index, 1);
        this.setState({ expandedKeys: [...expandedKeys] });
      }
    }

    // if (record?.children?.length === 0 && record.childrenCount > 1) {
    //   // get deliverable activities
    //   const activities = await this.retrieveProjectActivityListByDeliverable(record.key);

    //   // replace data with the activities list
    //   this.setState({
    //     deliverablesList: this.state.deliverablesList.map((deliverable) => {
    //       if ('children' in deliverable) {
    //         let expandedRow = deliverable.children.find((d) => d.key === record.key);

    //         if (expandedRow) {
    //           expandedRow.children = activities;
    //         }
    //       }

    //       return deliverable;
    //     }),
    //   });
    // }
  };

  toggleExpandAll = () => {
    const { expandedKeys, deliverablesList } = this.state;

    const getAllKeys = (list) => {
      let keys = [];

      list?.forEach((child) => {
        keys.push(child.key);
        if ('children' in child && child.children?.length > 0) {
          keys = keys.concat(getAllKeys(child.children));
        }
      });

      return keys;
    };

    const allKeys = getAllKeys(deliverablesList);

    if (expandedKeys.length === 0) {
      this.setState({ expandedKeys: allKeys });
    } else {
      this.setState({ expandedKeys: [] });
    }
  };

  onNewDeliverable = (projectId) => {
    this.props.history.push(`/progetti/${projectId}/deliverable/new`);
  };

  async retrieveDeliverables() {
    this.setState({ loadingDeliverables: true });

    const ctx = this;
    const { projectId } = this.props;

    try {
      const response = await DeliverableService.getProjectDeliverables(projectId);
      const resp = response.data;

      if (resp.success) {
        const result = await Promise.all(
          resp.responseObject.value.map(async (currentItem) => {
            const item = { ...currentItem };
            item.key = getRandomId();

            // get tasks activities for each phase
            const activities = await ctx.retrieveProjectActivityListByDeliverable(item.deliverableID);
            const activitiesUnderPhase = activities
              .filter((activity) => !activity.isGenerated)
              .map((activity) => {
                activity.key = getRandomId();
                activity.activityType = 'activity';
                return activity;
              });

            if (activitiesUnderPhase.length > 0) {
              item.children = [...item.children, ...activitiesUnderPhase];
            }

            item.children = await Promise.all(
              item.children.map(async (child) => {
                child.key = getRandomId();

                if (child.childrenCount > 1) {
                  const activities = await ctx.retrieveProjectActivityListByDeliverable(child.deliverableID);
                  child.children = activities
                    .filter((activity) => !activity.isGenerated)
                    .map((activity) => {
                      activity.key = getRandomId();
                      return activity;
                    });
                } else {
                  delete child.children;
                }

                return child;
              }),
            );

            console.log(item, item.children.length);

            if (item.children.length === 0) {
              delete item.children;
            }

            return item;
          }),
        );

        this.setState({ deliverablesList: [...result] });
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      this.setState({ loadingDeliverables: false });
    }
  }

  async retrieveProjectActivityListByDeliverable(deliverableID) {
    const { projectId } = this.props;

    try {
      this.setState({ loadingActivities: true });
      const response = await ActivityService.getProjectActivities(projectId, undefined, undefined, deliverableID);
      const resp = response.data;

      if (resp.success) {
        return resp.responseObject.value;
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      this.setState({ loadingActivities: false });
    }
  }

  toggleNewDeliverableModal = () => {
    this.setState({ showNewDeliverableModal: true });
  };

  handelNewDeliverableModalClose = () => {
    this.setState({ showNewDeliverableModal: false });
    this.setState({ loadingButton: false });
  };

  addProjectDeliverable = (values, projectId, deliverableDefaultStartDate) => {
    // values.startDate = this.handleDateChange(values.startDate)
    values['startDate'] = this.handleDateChange(deliverableDefaultStartDate);
    values.endDate = this.handleDateChange(values.endDate);
    values['projectID'] = projectId;

    this.setState({ loadingButton: true });
    DeliverablePageService.insertDeliverable(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          void this.handelNewDeliverableModalClose();
          void this.retrieveDeliverables();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  removeDeliverable = (deliverableId) => {
    this.setState({ loadingButtonDelete: true });
    DeliverableService.removeDeliverable(deliverableId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          void this.retrieveDeliverables();
        } else {
          message.error(notifyMessages.deleteFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
      });
  };

  async retrieveStatusList() {
    const objectCode = statusPickListCodes.delivarbleStatus;
    await CommonService.getStatusData(objectCode)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let obj = [];
          resp.responseObject.value.map((item) => {
            obj.push({ text: item.statusDescription, value: item.statusDescription });
          });
          console.log('🚀 ~ retrieveStatusList ~ obj:', obj);

          this.setState({ statusListFilter: obj });
        } else {
        }
      })
      .catch((error) => {});
  }

  handleChange = (pagination, filters, sorter) => {
    this.setState({
      activeStatusFilters: filters.statusDescription || [], // Ensure it updates based on the applied filters
    });
  };

  handelDeliverableFastClose = (record) => {
    const { projectId } = this.props;
    let payload = {};

    payload['deliverableID'] = record.deliverableID;
    payload['status'] = deliverableStatus.chiuso;
    payload['ownerID'] = record.owner;
    payload['projectID'] = projectId;

    this.setState({ loadingFastClose: true });
    DeliverablePageService.fastCloseDeliverable(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.updateSuccess);
          this.setState({ loadingFastClose: false });
          void this.retrieveDeliverables();
          updateNotificationBell();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingFastClose: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingFastClose: false });
      });
  };

  onRemoveActivity = async (activityID) => {
    try {
      const { data } = await ActivityService.removeActivity(activityID);
      
      if (!data.success) {
        throw 'Delete Failed'
      }

      void this.retrieveDeliverables();
      void this.retrieveStatusList();

      message.success(notifyMessages.deleteSuccess);
    } catch {
      message.error(notifyMessages.deleteFailed);
    }
  };

  handleCloseProjectActivity = (record) => {
    const { projectId } = this.props;
    let values = {};

    values['activityID'] = record.activityID;
    values['deliverableID'] = record.deliverableID;
    values['description'] = record.description;
    values['endDate'] = this.handleDateChange(record.endDate);
    values['ownerID'] = record.ownerID;
    values['projectID'] = projectId;
    values['startDate'] = this.handleDateChange(record.startDate);
    values['status'] = activityStatus.closed;
    values['subject'] = record.subject;

    this.setState({ loadingButton: true });
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ loadingButton: false });
          message.success(notifyMessages.updateSuccess);
          void this.retrieveDeliverables();
          void this.retrieveStatusList();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  handelCloseProjectActivity = (record) => {
    const { projectId } = this.props;
    let values = {
      ...record,
      status: activityStatus.closed,
      projectId,
    };

    this.setState({ loadingButton: true });
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ loadingButton: false });
          message.success(notifyMessages.updateSuccess);
          void this.retrieveDeliverables();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  toggleEditProjectAtivityModal = (rowData) => {
    this.setState({ showEditModal: true });
    this.setState({ modalData: rowData });
  };

  handelEditModalClose = () => {
    this.setState({ showEditModal: false });
    this.setState({ loadingButton: false });
  };

  handelUpdateProjectActivity = (values, activityID, projectID) => {
    values['activityID'] = activityID;
    values['projectID'] = projectID;
    values.startDate = this.handleDateChange(values.startDate);
    values.endDate = this.handleDateChange(values.endDate);
    this.setState({ loadingButton: true });
    ActivityService.updateActivity(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handelEditModalClose();
          message.success(notifyMessages.updateSuccess);
          void this.retrieveDeliverables();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  render() {
    const {
      deliverablesList,
      loadingDeliverables,
      loadingButton,
      showNewDeliverableModal,
      loadingFastClose,
      expandedKeys,
    } = this.state;
    const { projectId, projectName, currentProject, hasUserAccess, t, userData, isPresentationMode } = this.props;

    const deliverableColumns = [
      {
        title: (
          <div className="tw-flex tw-gap-2 tw-items-center">
            <Summary />
            Summary
          </div>
        ),
        width: '400px',
        dataIndex: 'name',
        ellipsis: {
          showTitle: true,
        },
        key: 'name',
        // ellipsis: {
        //     showTitle: false,
        // },
        sorter: (a, b) => {
          a = a.name || '';
          b = b.name || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        ...this.getColumnSearchProps('name'),
        render(title, record) {
          return {
            children: (
              <div className="tw-flex tw-flex-row tw-items-center tw-gap-2 tw-ml-5">
                {record.activityType === 'activity' ? (
                  <>
                    <Tooltip title="Task">
                      <TaskIcon />
                    </Tooltip>
                    <span className="tw-truncate">{record.subject}</span>
                  </>
                ) : record.parentDeliverableID ? (
                  <Tooltip title="Deliverable">
                    <DeliverableIcon />
                  </Tooltip>
                ) : (
                  <Tooltip title="Fase">
                    <PhaseIcon />
                  </Tooltip>
                )}
                <span className="tw-truncate">{title?.toUpperCase()}</span>
                {record.activityType !== 'activity' && (
                  <>
                    {/* <Tooltip
                  title={t(
                    ['proggetiPage.draft', 'proggetiPage.aperto', 'proggetiPage.sospeso', 'proggetiPage.chiuso'].at(
                      record.status,
                    ),
                  )}> */}
                    <DownOutlined
                      className="tw-ml-auto tw-scale-125 tw-mr-3"
                      style={{ color: checkDeliverableStatus(record, 'projectDeliverable') }}
                    />
                  </>
                )}
              </div>
            ),
          };
        },
      },
      {
        title: `${t('general.start')}`,
        dataIndex: 'startDate',
        key: 'startDate',
        width: '120px',
        ellipsis: {
          showTitle: false,
        },
        sorter: (a, b) => {
          a = a.startDate || '';
          b = b.startDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        render: (title) => (
          <Tooltip title={title ? moment(title).format(formatOfDate) : ''}>
            {title ? moment(title).format(formatOfDate) : ''}
          </Tooltip>
        ),
      },
      {
        title: `${t('general.end')}`,
        dataIndex: 'endDate',
        key: 'endDate',
        width: '120px',
        sorter: (a, b) => {
          a = a.endDate || '';
          b = b.endDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        ellipsis: {
          showTitle: false,
        },
        render: (title) => (
          <Tooltip title={title ? moment(title).format(formatOfDate) : ''}>
            {title ? moment(title).format(formatOfDate) : ''}
          </Tooltip>
        ),
      },
      {
        title: `${t('general.stato')}`,
        dataIndex: 'statusDescription',
        key: 'statusDescription',
        sorter: (a, b) => {
          a = a.statusDescription || '';
          b = b.statusDescription || '';
          return a.localeCompare(b);
        },
        width: '120px',
        showSorterTooltip: [true],
        filters: this.state.statusListFilter,
        filteredValue: this.state.activeStatusFilters,
        onFilter: (value, record) => {
          return record.statusDescription ? record.statusDescription.includes(value) : '';
        },
        render(text, record) {
          let tag;

          switch (record.statusDescription) {
            case 'Draft':
              tag = (
                <Tag
                  className="tw-flex tw-items-center tw-gap-2 tw-w-full"
                  icon={<BsFillRecordCircleFill size="15" />}
                  color="blue">
                  {t('general.draft')}
                </Tag>
              );
              break;
            case 'Aperto':
            case 'Attivo':
            case 'Open':
            case 'Active':
              tag = (
                <Tag
                  className="tw-flex tw-items-center tw-gap-2 tw-w-full"
                  icon={<AiFillPlayCircle size="15" />}
                  color="success">
                  {t('general.opened')}
                </Tag>
              );
              break;
            case 'Sospeso':
            case 'Suspended':
              tag = (
                <Tag
                  className="tw-flex tw-items-center tw-w-full"
                  icon={<PauseCircleFilled />}
                  color="warning">
                  {t('general.closed')}
                </Tag>
              );
              break;
            case 'Chiuso':
            case 'Closed':
              tag = (
                <Tag
                  className="tw-flex tw-items-center tw-gap-2 tw-w-full"
                  icon={<AiFillCheckCircle size="15" />}
                  color="red">
                  {t('general.chiuso')}
                </Tag>
              );
              break;

            default:
              tag = <Tag>{text}</Tag>;
          }

          return {
            children: tag,
          };
        },
      },
      // {
      //   title: `${t('general.budget')}`,
      //   dataIndex: 'budget',
      //   key: 'budget',
      //   sorter: (a, b) => {
      //     a = a.budget || 0;
      //     b = b.budget || 0;
      //     return a - b;
      //   },
      //   showSorterTooltip: [true],
      //   ...this.getColumnSearchProps('budget'),
      // },
      {
        title: `${t('general.responsabile')}`,
        dataIndex: 'ownerFullName',
        key: 'ownerFullName',
        width: '200px',
        ellipsis: {
          showTitle: false,
        },
        sorter: (a, b) => {
          a = a.ownerFullName || '';
          b = b.ownerFullName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
        ...this.getColumnSearchProps('ownerFullName'),
        render: (title, record) =>
          // <Popover
          //   content={
          //     <ResponsiblePopoverContent
          //       data={record}
          //       t={t}
          //     />
          //   }
          //   title={title}>
          record.activityType === 'activity' ? (
            <div className="table-avatar">
              <Avatar size="small">
                {generateUserInitials(record.fullName?.split(' ')?.at(0), record.fullName?.split(' ')?.at(1))}
              </Avatar>
              <span style={record.isGenericResponsabile === true ? { color: '#f2883b' } : {}}>{record.fullName}</span>
            </div>
          ) : (
            <div className="table-avatar">
              <Avatar size="small">{generateUserInitials(title?.split(' ')?.at(0), title?.split(' ')?.at(1))}</Avatar>
              <span style={record.isGenericResponsabile === true ? { color: '#f2883b' } : {}}>{title}</span>
            </div>
          ),
        // </Popover>
      },
      {
        title: `${t('general.dataChiusura')}`,
        dataIndex: 'actualEndDate',
        key: 'actualEndDate',
        width: '120px',
        ellipsis: {
          showTitle: false,
        },
        render: (title) => (
          <Tooltip title={title ? moment(title).format(formatOfDate) : ''}>
            {title ? moment(title).format(formatOfDate) : ''}
          </Tooltip>
        ),
        sorter: (a, b) => {
          a = a.actualEndDate || '';
          b = b.actualEndDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
      },
      {
        title: `${t('deliverablePage.dataFinePrevista')}`,
        dataIndex: 'rescheduledEndDate',
        key: 'rescheduledEndDate',
        width: '120px',
        ellipsis: {
          showTitle: false,
        },
        render: (title) => (
          <Tooltip title={title ? moment(title).format(formatOfDate) : ''}>
            {title ? moment(title).format(formatOfDate) : ''}
          </Tooltip>
        ),
        sorter: (a, b) => {
          a = a.rescheduledEndDate || '';
          b = b.rescheduledEndDate || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [true],
      },
      {
        title: '',
        key: 'action',
        width: '150px',
        render: (text, record) => {
          const hasAccess =
            record.hasAccess ||
            userIsProjectTeamLeaderOrSponsor(userData, currentProject.teamLeaderID, currentProject.sponsorID);

          if (!hasAccess) {
            return null; // no actions if there no permission
          }

          if (record.activityType === 'activity') {
            const isClosed = record.status === activityStatus.closed;
            const quickClose = record.status === activityStatus.active || record.status === activityStatus.draft;

            return (
              <div className="tw-flex tw-gap-2">
                {!isClosed && (
                  <Popconfirm
                    key="remove"
                    title={t('buttons.rimuoveAttivita')}
                    icon={<WarningOutlined />}
                    okText={t('general.si')}
                    cancelText={t('general.no')}
                    onConfirm={() => this.onRemoveActivity(record.activityID)}>
                    <Button
                      danger
                      size="default"
                      className="tw-text-red-500"
                      icon={<DeleteOutlined title={t('buttons.rimuoveAttivita')} />}
                    />
                  </Popconfirm>
                )}

                {record.deliverableID ? (
                  <Button
                    size="default"
                    icon={<ExportOutlined />}
                    href={`/deliverables/id/${record.deliverableID}`}
                  />
                ) : (
                  <Button
                    size="default"
                    icon={<EditFilled title={t('buttons.modificaAttivita')} />}
                    onClick={() => this.toggleEditProjectAtivityModal(record)}
                  />
                )}

                {quickClose && (
                  <Popconfirm
                    key="close"
                    placement="topRight"
                    title={t('leMieAttivitaPage.chiudiQuestaAttivita')}
                    icon={<WarningOutlined />}
                    okText={t('general.si')}
                    cancelText={t('general.no')}
                    onConfirm={() => this.handelCloseProjectActivity(record)}>
                    <Tooltip title={t('buttons.chiusuraRapida')}>
                      <Button
                        size="default"
                        icon={<CheckOutlined />}
                      />
                    </Tooltip>
                  </Popconfirm>
                )}
              </div>
            );
          }

          return (
            <div className="tw-flex tw-items-center tw-gap-2">
              <Popconfirm
                placement="topRight"
                title={t('proggetiPage.rimuoveProgettoDeliverable')}
                onConfirm={() => this.removeDeliverable(record.deliverableID)}
                okText={t('general.si')}
                cancelText={t('general.no')}>
                <Button
                  danger
                  onClick={(e) => e.stopPropagation()}
                  size="default"
                  icon={<DeleteOutlined />}
                />
              </Popconfirm>

              <Button
                size="default"
                onClick={(e) => e.stopPropagation()}
                icon={<EditOutlined />}
                href={`/deliverables/id/${record.deliverableID}`}
              />

              {record.status !== deliverableStatus.chiuso && (
                <Popconfirm
                  key="close"
                  placement="topRight"
                  title={t('deliverablePage.chiudiDeliverable')}
                  icon={<WarningOutlined />}
                  okText={t('general.si')}
                  cancelText={t('general.no')}
                  onConfirm={() => this.handelDeliverableFastClose(record)}>
                  {!loadingFastClose ? (
                    <Tooltip title={t('buttons.chiusuraRapida')}>
                      <Button
                        size="default"
                        onClick={(e) => e.stopPropagation()}
                        icon={<CheckOutlined />}
                      />
                    </Tooltip>
                  ) : (
                    <SyncOutlined spin />
                  )}
                </Popconfirm>
              )}
            </div>
          );
        },
      },
    ];

    return this.state.view === 'list' ? (
      <TableLayout title={'Project review system'}>
        <TableLayout.ExtraTitle>
          <Button
            className={'tw-bg-primary/10'}
            icon={<List className="tw-fill-primary" />}></Button>
          <ButtonGroup>
            <Tooltip title={t('general.boardView')}>
              <Button
                onClick={() => {
                  addQueryParam('view', 'board');
                  this.setState({ view: 'board' });
                }}
                icon={<KanBanView className={'tw-fill-black'} />}></Button>
            </Tooltip>
          </ButtonGroup>
        </TableLayout.ExtraTitle>
        <TableLayout.Actions>
          <NewProjectDeliverable
            showNewDeliverableModal={showNewDeliverableModal}
            handelNewDeliverableModalClose={this.handelNewDeliverableModalClose}
            addProjectDeliverable={this.addProjectDeliverable}
            loadingButton={loadingButton}
            projectId={projectId}
            projectName={projectName}
            currentProject={currentProject}
            t={t}
          />

          {this.state.showEditModal && (
            <EditProjectActivity
              showEditModal={this.state.showEditModal}
              projectActivitiesColumns
              handelEditModalClose={this.handelEditModalClose}
              handelUpdateProjectActivity={this.handelUpdateProjectActivity}
              loadingButton={loadingButton}
              modalData={this.state.modalData}
              projectID={projectId}
              projectName={projectName}
              currentProject={currentProject}
              t={t}
            />
          )}

          <Button
            icon={expandedKeys.length === 0 ? <RiExpandDiagonal2Fill /> : <RiCollapseDiagonal2Fill />}
            onClick={this.toggleExpandAll}>
            {expandedKeys.length === 0 ? t('buttons.expandAll') : t('buttons.collapseAll')}
          </Button>

          {!isPresentationMode && !hasUserAccess ? (
            <Button
              htmlType="submit"
              icon={<PlusOutlined />}
              type="primary"
              onClick={this.toggleNewDeliverableModal}>
              {t('buttons.aggiungiNuovo')}
            </Button>
          ) : null}
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            sticky
            className="tw-min-h-full"
            size="small"
            columns={
              hasUserAccess
                ? deliverableColumns.filter((el) => {
                    return el.key !== 'action2' && el.key !== 'action';
                  })
                : deliverableColumns
            }
            dataSource={deliverablesList}
            expandable={{
              expandedRowKeys: expandedKeys,
              onExpand: this.onExpand,
              expandRowByClick: true,
              indentSize: 10,
            }}
            loading={loadingDeliverables}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
            onChange={this.handleChange}
            rowClassName={(record, index) => ((record.children && record.children.length) > 0 ? 'parentRow' : '')}
          />
        </TableLayout.Content>
      </TableLayout>
    ) : (
      <PrsKanBanView
        projectId={projectId}
        updateDeliverables={() => this.retrieveDeliverables()}
        view={this.state.view}
        setView={(view) => this.setState({ view })}
        deliverablesList={deliverablesList}
        newBtn={
          <>
            <NewProjectDeliverable
              showNewDeliverableModal={showNewDeliverableModal}
              handelNewDeliverableModalClose={this.handelNewDeliverableModalClose}
              addProjectDeliverable={this.addProjectDeliverable}
              loadingButton={loadingButton}
              projectId={projectId}
              projectName={projectName}
              currentProject={currentProject}
              t={t}
            />
            {!isPresentationMode && !hasUserAccess ? (
              <Button
                htmlType="submit"
                icon={<PlusOutlined />}
                type="primary"
                onClick={this.toggleNewDeliverableModal}>
                {t('buttons.aggiungiNuovo')}
              </Button>
            ) : null}
          </>
        }
      />
    );
  }
}

export default withTranslation()(ProjectDeliverable);

/* tslint:disable */
/* eslint-disable */
/**
 * Lenovys - HTTP Api
 * The Lenovys HTTP Api
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface AddRemoveCommitteeIDProjectRequestDto
 */
export interface AddRemoveCommitteeIDProjectRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AddRemoveCommitteeIDProjectRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AddRemoveCommitteeIDProjectRequestDto
     */
    'projectID'?: string;
}
/**
 * 
 * @export
 * @interface AdminInsertCompanyRequestDto
 */
export interface AdminInsertCompanyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof AdminInsertCompanyRequestDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AdminInsertCompanyRequestDto
     */
    'companyShortName'?: string | null;
}
/**
 * 
 * @export
 * @interface AgendaItems
 */
export interface AgendaItems {
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'agendaHeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'orderString'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'frequency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AgendaItems
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'input'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'output'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AgendaItems
     */
    'accountable'?: string | null;
}
/**
 * 
 * @export
 * @interface AlertConfigurationResponseDto
 */
export interface AlertConfigurationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AlertConfigurationResponseDto
     */
    'alertID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlertConfigurationResponseDto
     */
    'companyID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof AlertConfigurationResponseDto
     */
    'allInOne'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AlertConfigurationResponseDto
     */
    'reminderInDaysFrequence'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof AlertConfigurationResponseDto
     */
    'sendViaMail'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof AlertConfigurationResponseDto
     */
    'sendViaNotificationBell'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof AlertConfigurationResponseDto
     */
    'periodo'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AlertConfigurationResponseDto
     */
    'lastEmailSent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AlertConfigurationResponseDto
     */
    'lastAlertSent'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnualGoal
 */
export interface AnnualGoal {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoal
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoal
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoal
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoal
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoal
     */
    'responsabile'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalKPIBowling
 */
export interface AnnualGoalKPIBowling {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'kpiDetailName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIBowling
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'typeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIBowling
     */
    'parentType'?: number;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIBowling
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'kpiProjectRelationshipID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof AnnualGoalKPIBowling
     */
    'isRollupKPI'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'rollupKpiName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'parentKPIProjectRelationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIBowling
     */
    'annualGoalName'?: string | null;
    /**
     * 
     * @type {{ [key: string]: number | null; }}
     * @memberof AnnualGoalKPIBowling
     */
    'monthsList'?: { [key: string]: number | null; } | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalKPIRelationship
 */
export interface AnnualGoalKPIRelationship {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalKPIRelationships
 */
export interface AnnualGoalKPIRelationships {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationships
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationships
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationships
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalKPIRelationships
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalKPIRelationships
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalPicklistResponseDto
 */
export interface AnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalPicklistResponseDto
     */
    'annualGoalId'?: string;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalPicklistResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoalPicklistResponseDto
     */
    'orderingValue'?: number | null;
}
/**
 * 
 * @export
 * @interface AnnualGoalResponse
 */
export interface AnnualGoalResponse {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalResponse
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoalResponse
     */
    'annualGoalName'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnualGoals
 */
export interface AnnualGoals {
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoals
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoals
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoals
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof AnnualGoals
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof AnnualGoals
     */
    'responsabile'?: string | null;
}
/**
 * 
 * @export
 * @interface AnnualObjData
 */
export interface AnnualObjData {
    /**
     * 
     * @type {Array<AnnualGoalResponse>}
     * @memberof AnnualObjData
     */
    'annualGoalNotRelated'?: Array<AnnualGoalResponse> | null;
    /**
     * 
     * @type {Array<AnnualGoalResponse>}
     * @memberof AnnualObjData
     */
    'annualGoalRelated'?: Array<AnnualGoalResponse> | null;
}
/**
 * 
 * @export
 * @interface CheckKpiTypePlanningStartDateResponseDto
 */
export interface CheckKpiTypePlanningStartDateResponseDto {
    /**
     * 
     * @type {number}
     * @memberof CheckKpiTypePlanningStartDateResponseDto
     */
    'year'?: number | null;
}
/**
 * 
 * @export
 * @interface CheckObjectSecurityUserRequestDto
 */
export interface CheckObjectSecurityUserRequestDto {
    /**
     * 
     * @type {number}
     * @memberof CheckObjectSecurityUserRequestDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CheckObjectSecurityUserRequestDto
     */
    'objectID'?: string | null;
}
/**
 * 
 * @export
 * @interface CheckObjectSecurityUserResponse
 */
export interface CheckObjectSecurityUserResponse {
    /**
     * 
     * @type {number}
     * @memberof CheckObjectSecurityUserResponse
     */
    'hasPrivilege'?: number | null;
}
/**
 * 
 * @export
 * @interface ChildCommitteeResponseDto
 */
export interface ChildCommitteeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ChildCommitteeResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildCommitteeResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface ChildList
 */
export interface ChildList {
    /**
     * 
     * @type {string}
     * @memberof ChildList
     */
    'xmatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildList
     */
    'hkCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildList
     */
    'parentXMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildList
     */
    'parentXMatrixHKCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ChildMenuItem
 */
export interface ChildMenuItem {
    /**
     * 
     * @type {string}
     * @memberof ChildMenuItem
     */
    'menuItemId'?: string;
    /**
     * 
     * @type {string}
     * @memberof ChildMenuItem
     */
    'menuItemDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ChildMenuItem
     */
    'menuItemLink'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ChildMenuItem
     */
    'menuItemIsDisabled'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof ChildMenuItem
     */
    'orderingValueMenuItem'?: number | null;
}
/**
 * 
 * @export
 * @interface CommitteMembers
 */
export interface CommitteMembers {
    /**
     * 
     * @type {string}
     * @memberof CommitteMembers
     */
    'userID'?: string;
    /**
     * 
     * @type {string}
     * @memberof CommitteMembers
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommitteMembers
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CommitteParticipants
 */
export interface CommitteParticipants {
    /**
     * 
     * @type {string}
     * @memberof CommitteParticipants
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommitteParticipants
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommitteParticipants
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommitteParticipants
     */
    'email'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CommitteParticipants
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface CommitteeResponse
 */
export interface CommitteeResponse {
    /**
     * 
     * @type {string}
     * @memberof CommitteeResponse
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommitteeResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<CommitteParticipants>}
     * @memberof CommitteeResponse
     */
    'participants'?: Array<CommitteParticipants> | null;
    /**
     * 
     * @type {Array<CommitteParticipants>}
     * @memberof CommitteeResponse
     */
    'revisori'?: Array<CommitteParticipants> | null;
    /**
     * 
     * @type {number}
     * @memberof CommitteeResponse
     */
    'usedInProject'?: number;
    /**
     * 
     * @type {string}
     * @memberof CommitteeResponse
     */
    'repositoryLink'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommitteeResponse
     */
    'activitiesDuration'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CommitteeResponse
     */
    'hasDefaultAgenda'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CommitteeResponse
     */
    'agendaID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommitteeResponse
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommitteeResponse
     */
    'commentTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface CommonProp
 */
export interface CommonProp {
    /**
     * 
     * @type {string}
     * @memberof CommonProp
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommonProp
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommonProp
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommonProp
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CommonProp
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommonProp
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CommonProp
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface CommonProps
 */
export interface CommonProps {
    /**
     * 
     * @type {string}
     * @memberof CommonProps
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CommonProps
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommonProps
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CommonProps
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CommonProps
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CommonProps
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CommonProps
     */
    'note'?: string | null;
}
/**
 * 
 * @export
 * @interface CompUsersListDto
 */
export interface CompUsersListDto {
    /**
     * 
     * @type {string}
     * @memberof CompUsersListDto
     */
    'roleCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompUsersListDto
     */
    'usersCount'?: number | null;
}
/**
 * 
 * @export
 * @interface CompanyByIdResponseDto
 */
export interface CompanyByIdResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyByIdResponseDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyByIdResponseDto
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyByIdResponseDto
     */
    'mission'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyByIdResponseDto
     */
    'values'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyByIdResponseDto
     */
    'vision'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyByIdResponseDto
     */
    'timeAlertEdge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyByIdResponseDto
     */
    'budgetAlertEdge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyByIdResponseDto
     */
    'qualityAlertEdge'?: number | null;
}
/**
 * 
 * @export
 * @interface CompanyResponseDto
 */
export interface CompanyResponseDto {
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'logo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'mission'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'values'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'vision'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'timeAlertEdge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'budgetAlertEdge'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'qualityAlertEdge'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'currency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'budgetAlertSelection'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDto
     */
    'sendNotification'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'daysOfNotice'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'noticeStatus'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'redirectLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CompanyResponseDto
     */
    'supportMail'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'implementationDaysOfNotice'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'nextStepsDaysOfNotice'?: number;
    /**
     * 
     * @type {number}
     * @memberof CompanyResponseDto
     */
    'kpiTolerance'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDto
     */
    'startFromCurrentMonth'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof CompanyResponseDto
     */
    'resetGlobalKPI'?: boolean;
}
/**
 * 
 * @export
 * @interface ControllerResponseOfAlertConfigurationResponseDto
 */
export interface ControllerResponseOfAlertConfigurationResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfAlertConfigurationResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfAlertConfigurationResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfAlertConfigurationResponseDtoResponseObject}
     * @memberof ControllerResponseOfAlertConfigurationResponseDto
     */
    'responseObject'?: ControllerResponseOfAlertConfigurationResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfAlertConfigurationResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfAlertConfigurationResponseDtoResponseObject = ResponseOfAlertConfigurationResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfBoolean
 */
export interface ControllerResponseOfBoolean {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfBoolean
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfBoolean
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfBooleanResponseObject}
     * @memberof ControllerResponseOfBoolean
     */
    'responseObject'?: ControllerResponseOfBooleanResponseObject | null;
}
/**
 * @type ControllerResponseOfBooleanResponseObject
 * @export
 */
export type ControllerResponseOfBooleanResponseObject = ResponseOfBoolean;

/**
 * 
 * @export
 * @interface ControllerResponseOfCheckKpiTypePlanningStartDateResponseDto
 */
export interface ControllerResponseOfCheckKpiTypePlanningStartDateResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfCheckKpiTypePlanningStartDateResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfCheckKpiTypePlanningStartDateResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfCheckKpiTypePlanningStartDateResponseDtoResponseObject}
     * @memberof ControllerResponseOfCheckKpiTypePlanningStartDateResponseDto
     */
    'responseObject'?: ControllerResponseOfCheckKpiTypePlanningStartDateResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfCheckKpiTypePlanningStartDateResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfCheckKpiTypePlanningStartDateResponseDtoResponseObject = ResponseOfCheckKpiTypePlanningStartDateResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfCheckObjectSecurityUserResponse
 */
export interface ControllerResponseOfCheckObjectSecurityUserResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfCheckObjectSecurityUserResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfCheckObjectSecurityUserResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfCheckObjectSecurityUserResponseResponseObject}
     * @memberof ControllerResponseOfCheckObjectSecurityUserResponse
     */
    'responseObject'?: ControllerResponseOfCheckObjectSecurityUserResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfCheckObjectSecurityUserResponseResponseObject
 * @export
 */
export type ControllerResponseOfCheckObjectSecurityUserResponseResponseObject = ResponseOfCheckObjectSecurityUserResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfCommitteeResponse
 */
export interface ControllerResponseOfCommitteeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfCommitteeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfCommitteeResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfCommitteeResponseResponseObject}
     * @memberof ControllerResponseOfCommitteeResponse
     */
    'responseObject'?: ControllerResponseOfCommitteeResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfCommitteeResponseResponseObject
 * @export
 */
export type ControllerResponseOfCommitteeResponseResponseObject = ResponseOfCommitteeResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfCompanyByIdResponseDto
 */
export interface ControllerResponseOfCompanyByIdResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfCompanyByIdResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfCompanyByIdResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfCompanyByIdResponseDtoResponseObject}
     * @memberof ControllerResponseOfCompanyByIdResponseDto
     */
    'responseObject'?: ControllerResponseOfCompanyByIdResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfCompanyByIdResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfCompanyByIdResponseDtoResponseObject = ResponseOfCompanyByIdResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfCompanyResponseDto
 */
export interface ControllerResponseOfCompanyResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfCompanyResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfCompanyResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfCompanyResponseDtoResponseObject}
     * @memberof ControllerResponseOfCompanyResponseDto
     */
    'responseObject'?: ControllerResponseOfCompanyResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfCompanyResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfCompanyResponseDtoResponseObject = ResponseOfCompanyResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfFastCloseDeliverableResponse
 */
export interface ControllerResponseOfFastCloseDeliverableResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfFastCloseDeliverableResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfFastCloseDeliverableResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfFastCloseDeliverableResponseResponseObject}
     * @memberof ControllerResponseOfFastCloseDeliverableResponse
     */
    'responseObject'?: ControllerResponseOfFastCloseDeliverableResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfFastCloseDeliverableResponseResponseObject
 * @export
 */
export type ControllerResponseOfFastCloseDeliverableResponseResponseObject = ResponseOfFastCloseDeliverableResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetAgendaResponseDto
 */
export interface ControllerResponseOfGetAgendaResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetAgendaResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetAgendaResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetAgendaResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetAgendaResponseDto
     */
    'responseObject'?: ControllerResponseOfGetAgendaResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetAgendaResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetAgendaResponseDtoResponseObject = ResponseOfGetAgendaResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetAnnualGoalDto
 */
export interface ControllerResponseOfGetAnnualGoalDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetAnnualGoalDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetAnnualGoalDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetAnnualGoalDtoResponseObject}
     * @memberof ControllerResponseOfGetAnnualGoalDto
     */
    'responseObject'?: ControllerResponseOfGetAnnualGoalDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetAnnualGoalDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetAnnualGoalDtoResponseObject = ResponseOfGetAnnualGoalDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetAnualGoalDetailsDto
 */
export interface ControllerResponseOfGetAnualGoalDetailsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetAnualGoalDetailsDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetAnualGoalDetailsDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetAnualGoalDetailsDtoResponseObject}
     * @memberof ControllerResponseOfGetAnualGoalDetailsDto
     */
    'responseObject'?: ControllerResponseOfGetAnualGoalDetailsDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetAnualGoalDetailsDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetAnualGoalDetailsDtoResponseObject = ResponseOfGetAnualGoalDetailsDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetCommitteeSummaryResponseDto
 */
export interface ControllerResponseOfGetCommitteeSummaryResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetCommitteeSummaryResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetCommitteeSummaryResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetCommitteeSummaryResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetCommitteeSummaryResponseDto
     */
    'responseObject'?: ControllerResponseOfGetCommitteeSummaryResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetCommitteeSummaryResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetCommitteeSummaryResponseDtoResponseObject = ResponseOfGetCommitteeSummaryResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetDeliverableResponseDto
 */
export interface ControllerResponseOfGetDeliverableResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetDeliverableResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetDeliverableResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetDeliverableResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetDeliverableResponseDto
     */
    'responseObject'?: ControllerResponseOfGetDeliverableResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetDeliverableResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetDeliverableResponseDtoResponseObject = ResponseOfGetDeliverableResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetDivisionResponseDto
 */
export interface ControllerResponseOfGetDivisionResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetDivisionResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetDivisionResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetDivisionResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetDivisionResponseDto
     */
    'responseObject'?: ControllerResponseOfGetDivisionResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetDivisionResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetDivisionResponseDtoResponseObject = ResponseOfGetDivisionResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetFullProfileDto
 */
export interface ControllerResponseOfGetFullProfileDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetFullProfileDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetFullProfileDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetFullProfileDtoResponseObject}
     * @memberof ControllerResponseOfGetFullProfileDto
     */
    'responseObject'?: ControllerResponseOfGetFullProfileDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetFullProfileDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetFullProfileDtoResponseObject = ResponseOfGetFullProfileDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetGoalCategoryByIDResponseDto
 */
export interface ControllerResponseOfGetGoalCategoryByIDResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetGoalCategoryByIDResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetGoalCategoryByIDResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetGoalCategoryByIDResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetGoalCategoryByIDResponseDto
     */
    'responseObject'?: ControllerResponseOfGetGoalCategoryByIDResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetGoalCategoryByIDResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetGoalCategoryByIDResponseDtoResponseObject = ResponseOfGetGoalCategoryByIDResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetGoalGroundtoActA3ResponseDto
 */
export interface ControllerResponseOfGetGoalGroundtoActA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetGoalGroundtoActA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetGoalGroundtoActA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetGoalGroundtoActA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetGoalGroundtoActA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfGetGoalGroundtoActA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetGoalGroundtoActA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetGoalGroundtoActA3ResponseDtoResponseObject = ResponseOfGetGoalGroundtoActA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetImplementationSectionA3ResponseDto
 */
export interface ControllerResponseOfGetImplementationSectionA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetImplementationSectionA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetImplementationSectionA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetImplementationSectionA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetImplementationSectionA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfGetImplementationSectionA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetImplementationSectionA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetImplementationSectionA3ResponseDtoResponseObject = ResponseOfGetImplementationSectionA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetInsertKPIResponseDto
 */
export interface ControllerResponseOfGetInsertKPIResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetInsertKPIResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetInsertKPIResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetInsertKPIResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetInsertKPIResponseDto
     */
    'responseObject'?: ControllerResponseOfGetInsertKPIResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetInsertKPIResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetInsertKPIResponseDtoResponseObject = ResponseOfGetInsertKPIResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetKPICommentResponse
 */
export interface ControllerResponseOfGetKPICommentResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetKPICommentResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetKPICommentResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetKPICommentResponseResponseObject}
     * @memberof ControllerResponseOfGetKPICommentResponse
     */
    'responseObject'?: ControllerResponseOfGetKPICommentResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfGetKPICommentResponseResponseObject
 * @export
 */
export type ControllerResponseOfGetKPICommentResponseResponseObject = ResponseOfGetKPICommentResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetKPIDetailDto
 */
export interface ControllerResponseOfGetKPIDetailDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetKPIDetailDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetKPIDetailDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetKPIDetailDtoResponseObject}
     * @memberof ControllerResponseOfGetKPIDetailDto
     */
    'responseObject'?: ControllerResponseOfGetKPIDetailDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetKPIDetailDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetKPIDetailDtoResponseObject = ResponseOfGetKPIDetailDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetKPIListPageDto
 */
export interface ControllerResponseOfGetKPIListPageDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetKPIListPageDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetKPIListPageDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetKPIListPageDtoResponseObject}
     * @memberof ControllerResponseOfGetKPIListPageDto
     */
    'responseObject'?: ControllerResponseOfGetKPIListPageDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetKPIListPageDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetKPIListPageDtoResponseObject = ResponseOfGetKPIListPageDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetKPIsDetailDto
 */
export interface ControllerResponseOfGetKPIsDetailDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetKPIsDetailDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetKPIsDetailDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetKPIsDetailDtoResponseObject}
     * @memberof ControllerResponseOfGetKPIsDetailDto
     */
    'responseObject'?: ControllerResponseOfGetKPIsDetailDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetKPIsDetailDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetKPIsDetailDtoResponseObject = ResponseOfGetKPIsDetailDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetLongTermGoalChecklistResponseDto
 */
export interface ControllerResponseOfGetLongTermGoalChecklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetLongTermGoalChecklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetLongTermGoalChecklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetLongTermGoalChecklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetLongTermGoalChecklistResponseDto
     */
    'responseObject'?: ControllerResponseOfGetLongTermGoalChecklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetLongTermGoalChecklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetLongTermGoalChecklistResponseDtoResponseObject = ResponseOfGetLongTermGoalChecklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetMacroProjectResponseDto
 */
export interface ControllerResponseOfGetMacroProjectResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetMacroProjectResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetMacroProjectResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetMacroProjectResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetMacroProjectResponseDto
     */
    'responseObject'?: ControllerResponseOfGetMacroProjectResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetMacroProjectResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetMacroProjectResponseDtoResponseObject = ResponseOfGetMacroProjectResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetMyAcivityResponseDto
 */
export interface ControllerResponseOfGetMyAcivityResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetMyAcivityResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetMyAcivityResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetMyAcivityResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetMyAcivityResponseDto
     */
    'responseObject'?: ControllerResponseOfGetMyAcivityResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetMyAcivityResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetMyAcivityResponseDtoResponseObject = ResponseOfGetMyAcivityResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetNextStepsSectionA3ResponseDto
 */
export interface ControllerResponseOfGetNextStepsSectionA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetNextStepsSectionA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetNextStepsSectionA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetNextStepsSectionA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetNextStepsSectionA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfGetNextStepsSectionA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetNextStepsSectionA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetNextStepsSectionA3ResponseDtoResponseObject = ResponseOfGetNextStepsSectionA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetProjectCostDetailResponseDto
 */
export interface ControllerResponseOfGetProjectCostDetailResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetProjectCostDetailResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetProjectCostDetailResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetProjectCostDetailResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetProjectCostDetailResponseDto
     */
    'responseObject'?: ControllerResponseOfGetProjectCostDetailResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetProjectCostDetailResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetProjectCostDetailResponseDtoResponseObject = ResponseOfGetProjectCostDetailResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetProjectHeaderA3ResponseDto
 */
export interface ControllerResponseOfGetProjectHeaderA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetProjectHeaderA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetProjectHeaderA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetProjectHeaderA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetProjectHeaderA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfGetProjectHeaderA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetProjectHeaderA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetProjectHeaderA3ResponseDtoResponseObject = ResponseOfGetProjectHeaderA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetProjectRepositoryList
 */
export interface ControllerResponseOfGetProjectRepositoryList {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetProjectRepositoryList
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetProjectRepositoryList
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetProjectRepositoryListResponseObject}
     * @memberof ControllerResponseOfGetProjectRepositoryList
     */
    'responseObject'?: ControllerResponseOfGetProjectRepositoryListResponseObject | null;
}
/**
 * @type ControllerResponseOfGetProjectRepositoryListResponseObject
 * @export
 */
export type ControllerResponseOfGetProjectRepositoryListResponseObject = ResponseOfGetProjectRepositoryList;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetProjectResponseDto
 */
export interface ControllerResponseOfGetProjectResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetProjectResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetProjectResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetProjectResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetProjectResponseDto
     */
    'responseObject'?: ControllerResponseOfGetProjectResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetProjectResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetProjectResponseDtoResponseObject = ResponseOfGetProjectResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetUserProjectCostAccessResponseDto
 */
export interface ControllerResponseOfGetUserProjectCostAccessResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetUserProjectCostAccessResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetUserProjectCostAccessResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetUserProjectCostAccessResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetUserProjectCostAccessResponseDto
     */
    'responseObject'?: ControllerResponseOfGetUserProjectCostAccessResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetUserProjectCostAccessResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetUserProjectCostAccessResponseDtoResponseObject = ResponseOfGetUserProjectCostAccessResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetXMatrixCycleByIDResponseDto
 */
export interface ControllerResponseOfGetXMatrixCycleByIDResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetXMatrixCycleByIDResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetXMatrixCycleByIDResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetXMatrixCycleByIDResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetXMatrixCycleByIDResponseDto
     */
    'responseObject'?: ControllerResponseOfGetXMatrixCycleByIDResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetXMatrixCycleByIDResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetXMatrixCycleByIDResponseDtoResponseObject = ResponseOfGetXMatrixCycleByIDResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetXMatrixInfoResponseDto
 */
export interface ControllerResponseOfGetXMatrixInfoResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetXMatrixInfoResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetXMatrixInfoResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetXMatrixInfoResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetXMatrixInfoResponseDto
     */
    'responseObject'?: ControllerResponseOfGetXMatrixInfoResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetXMatrixInfoResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetXMatrixInfoResponseDtoResponseObject = ResponseOfGetXMatrixInfoResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGetXMatrixSummaryResponseDto
 */
export interface ControllerResponseOfGetXMatrixSummaryResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGetXMatrixSummaryResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGetXMatrixSummaryResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGetXMatrixSummaryResponseDtoResponseObject}
     * @memberof ControllerResponseOfGetXMatrixSummaryResponseDto
     */
    'responseObject'?: ControllerResponseOfGetXMatrixSummaryResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfGetXMatrixSummaryResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfGetXMatrixSummaryResponseDtoResponseObject = ResponseOfGetXMatrixSummaryResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfGoalByProject
 */
export interface ControllerResponseOfGoalByProject {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfGoalByProject
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfGoalByProject
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfGoalByProjectResponseObject}
     * @memberof ControllerResponseOfGoalByProject
     */
    'responseObject'?: ControllerResponseOfGoalByProjectResponseObject | null;
}
/**
 * @type ControllerResponseOfGoalByProjectResponseObject
 * @export
 */
export type ControllerResponseOfGoalByProjectResponseObject = ResponseOfGoalByProject;

/**
 * 
 * @export
 * @interface ControllerResponseOfIListOfDeliverableDto
 */
export interface ControllerResponseOfIListOfDeliverableDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfIListOfDeliverableDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfIListOfDeliverableDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfIListOfDeliverableDtoResponseObject}
     * @memberof ControllerResponseOfIListOfDeliverableDto
     */
    'responseObject'?: ControllerResponseOfIListOfDeliverableDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfIListOfDeliverableDtoResponseObject
 * @export
 */
export type ControllerResponseOfIListOfDeliverableDtoResponseObject = ResponseOfIListOfDeliverableDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertAlertConfigurationResponseDto
 */
export interface ControllerResponseOfInsertAlertConfigurationResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertAlertConfigurationResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertAlertConfigurationResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertAlertConfigurationResponseDtoResponseObject}
     * @memberof ControllerResponseOfInsertAlertConfigurationResponseDto
     */
    'responseObject'?: ControllerResponseOfInsertAlertConfigurationResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertAlertConfigurationResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfInsertAlertConfigurationResponseDtoResponseObject = ResponseOfInsertAlertConfigurationResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertKPIDetailResponse
 */
export interface ControllerResponseOfInsertKPIDetailResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertKPIDetailResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertKPIDetailResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertKPIDetailResponseResponseObject}
     * @memberof ControllerResponseOfInsertKPIDetailResponse
     */
    'responseObject'?: ControllerResponseOfInsertKPIDetailResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertKPIDetailResponseResponseObject
 * @export
 */
export type ControllerResponseOfInsertKPIDetailResponseResponseObject = ResponseOfInsertKPIDetailResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertLongTermGoalResponseDto
 */
export interface ControllerResponseOfInsertLongTermGoalResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertLongTermGoalResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertLongTermGoalResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertLongTermGoalResponseDtoResponseObject}
     * @memberof ControllerResponseOfInsertLongTermGoalResponseDto
     */
    'responseObject'?: ControllerResponseOfInsertLongTermGoalResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertLongTermGoalResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfInsertLongTermGoalResponseDtoResponseObject = ResponseOfInsertLongTermGoalResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertMyActivityResponseDto
 */
export interface ControllerResponseOfInsertMyActivityResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertMyActivityResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertMyActivityResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertMyActivityResponseDtoResponseObject}
     * @memberof ControllerResponseOfInsertMyActivityResponseDto
     */
    'responseObject'?: ControllerResponseOfInsertMyActivityResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertMyActivityResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfInsertMyActivityResponseDtoResponseObject = ResponseOfInsertMyActivityResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertNextYearXMatrixResponseDto
 */
export interface ControllerResponseOfInsertNextYearXMatrixResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertNextYearXMatrixResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertNextYearXMatrixResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertNextYearXMatrixResponseDtoResponseObject}
     * @memberof ControllerResponseOfInsertNextYearXMatrixResponseDto
     */
    'responseObject'?: ControllerResponseOfInsertNextYearXMatrixResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertNextYearXMatrixResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfInsertNextYearXMatrixResponseDtoResponseObject = ResponseOfInsertNextYearXMatrixResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertProjectCostDetailResponseDto
 */
export interface ControllerResponseOfInsertProjectCostDetailResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertProjectCostDetailResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertProjectCostDetailResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertProjectCostDetailResponseDtoResponseObject}
     * @memberof ControllerResponseOfInsertProjectCostDetailResponseDto
     */
    'responseObject'?: ControllerResponseOfInsertProjectCostDetailResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertProjectCostDetailResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfInsertProjectCostDetailResponseDtoResponseObject = ResponseOfInsertProjectCostDetailResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertProjectMemberResponseDto
 */
export interface ControllerResponseOfInsertProjectMemberResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertProjectMemberResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertProjectMemberResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertProjectMemberResponseDtoResponseObject}
     * @memberof ControllerResponseOfInsertProjectMemberResponseDto
     */
    'responseObject'?: ControllerResponseOfInsertProjectMemberResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertProjectMemberResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfInsertProjectMemberResponseDtoResponseObject = ResponseOfInsertProjectMemberResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertProjectRoutineResponseDto
 */
export interface ControllerResponseOfInsertProjectRoutineResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertProjectRoutineResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertProjectRoutineResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertProjectRoutineResponseDtoResponseObject}
     * @memberof ControllerResponseOfInsertProjectRoutineResponseDto
     */
    'responseObject'?: ControllerResponseOfInsertProjectRoutineResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertProjectRoutineResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfInsertProjectRoutineResponseDtoResponseObject = ResponseOfInsertProjectRoutineResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertProjectXMatrixResponse
 */
export interface ControllerResponseOfInsertProjectXMatrixResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertProjectXMatrixResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertProjectXMatrixResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertProjectXMatrixResponseResponseObject}
     * @memberof ControllerResponseOfInsertProjectXMatrixResponse
     */
    'responseObject'?: ControllerResponseOfInsertProjectXMatrixResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertProjectXMatrixResponseResponseObject
 * @export
 */
export type ControllerResponseOfInsertProjectXMatrixResponseResponseObject = ResponseOfInsertProjectXMatrixResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertUpdateCommitteeSessionResponseDto
 */
export interface ControllerResponseOfInsertUpdateCommitteeSessionResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertUpdateCommitteeSessionResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertUpdateCommitteeSessionResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertUpdateCommitteeSessionResponseDtoResponseObject}
     * @memberof ControllerResponseOfInsertUpdateCommitteeSessionResponseDto
     */
    'responseObject'?: ControllerResponseOfInsertUpdateCommitteeSessionResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertUpdateCommitteeSessionResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfInsertUpdateCommitteeSessionResponseDtoResponseObject = ResponseOfInsertUpdateCommitteeSessionResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertXMatrixDivisionResponseDto
 */
export interface ControllerResponseOfInsertXMatrixDivisionResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertXMatrixDivisionResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertXMatrixDivisionResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertXMatrixDivisionResponseDtoResponseObject}
     * @memberof ControllerResponseOfInsertXMatrixDivisionResponseDto
     */
    'responseObject'?: ControllerResponseOfInsertXMatrixDivisionResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertXMatrixDivisionResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfInsertXMatrixDivisionResponseDtoResponseObject = ResponseOfInsertXMatrixDivisionResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfInsertXMatrixResponseDto
 */
export interface ControllerResponseOfInsertXMatrixResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfInsertXMatrixResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfInsertXMatrixResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfInsertXMatrixResponseDtoResponseObject}
     * @memberof ControllerResponseOfInsertXMatrixResponseDto
     */
    'responseObject'?: ControllerResponseOfInsertXMatrixResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfInsertXMatrixResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfInsertXMatrixResponseDtoResponseObject = ResponseOfInsertXMatrixResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfAnnualGoalKPIBowling
 */
export interface ControllerResponseOfListOfAnnualGoalKPIBowling {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfAnnualGoalKPIBowling
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfAnnualGoalKPIBowling
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfAnnualGoalKPIBowlingResponseObject}
     * @memberof ControllerResponseOfListOfAnnualGoalKPIBowling
     */
    'responseObject'?: ControllerResponseOfListOfAnnualGoalKPIBowlingResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfAnnualGoalKPIBowlingResponseObject
 * @export
 */
export type ControllerResponseOfListOfAnnualGoalKPIBowlingResponseObject = ResponseOfListOfAnnualGoalKPIBowling;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfAnnualGoalPicklistResponseDto
 */
export interface ControllerResponseOfListOfAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfAnnualGoalPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfAnnualGoalPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfAnnualGoalPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfAnnualGoalPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfAnnualGoalPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfAnnualGoalPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfAnnualGoalPicklistResponseDtoResponseObject = ResponseOfListOfAnnualGoalPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfChildCommitteeResponseDto
 */
export interface ControllerResponseOfListOfChildCommitteeResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfChildCommitteeResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfChildCommitteeResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfChildCommitteeResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfChildCommitteeResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfChildCommitteeResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfChildCommitteeResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfChildCommitteeResponseDtoResponseObject = ResponseOfListOfChildCommitteeResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfCommitteMembers
 */
export interface ControllerResponseOfListOfCommitteMembers {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfCommitteMembers
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfCommitteMembers
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfCommitteMembersResponseObject}
     * @memberof ControllerResponseOfListOfCommitteMembers
     */
    'responseObject'?: ControllerResponseOfListOfCommitteMembersResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfCommitteMembersResponseObject
 * @export
 */
export type ControllerResponseOfListOfCommitteMembersResponseObject = ResponseOfListOfCommitteMembers;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfDetailsKPIResponseDto
 */
export interface ControllerResponseOfListOfDetailsKPIResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfDetailsKPIResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfDetailsKPIResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfDetailsKPIResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfDetailsKPIResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfDetailsKPIResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfDetailsKPIResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfDetailsKPIResponseDtoResponseObject = ResponseOfListOfDetailsKPIResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfExpandoObject
 */
export interface ControllerResponseOfListOfExpandoObject {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfExpandoObject
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfExpandoObject
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfExpandoObjectResponseObject}
     * @memberof ControllerResponseOfListOfExpandoObject
     */
    'responseObject'?: ControllerResponseOfListOfExpandoObjectResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfExpandoObjectResponseObject
 * @export
 */
export type ControllerResponseOfListOfExpandoObjectResponseObject = ResponseOfListOfExpandoObject;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfFilterbyXMatrixResponse
 */
export interface ControllerResponseOfListOfFilterbyXMatrixResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfFilterbyXMatrixResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfFilterbyXMatrixResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfFilterbyXMatrixResponseResponseObject}
     * @memberof ControllerResponseOfListOfFilterbyXMatrixResponse
     */
    'responseObject'?: ControllerResponseOfListOfFilterbyXMatrixResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfFilterbyXMatrixResponseResponseObject
 * @export
 */
export type ControllerResponseOfListOfFilterbyXMatrixResponseResponseObject = ResponseOfListOfFilterbyXMatrixResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetActivitySummaryperStatusResponseDto
 */
export interface ControllerResponseOfListOfGetActivitySummaryperStatusResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetActivitySummaryperStatusResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetActivitySummaryperStatusResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetActivitySummaryperStatusResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetActivitySummaryperStatusResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetActivitySummaryperStatusResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetActivitySummaryperStatusResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetActivitySummaryperStatusResponseDtoResponseObject = ResponseOfListOfGetActivitySummaryperStatusResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetActivitySummaryperTimeResponse
 */
export interface ControllerResponseOfListOfGetActivitySummaryperTimeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetActivitySummaryperTimeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetActivitySummaryperTimeResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetActivitySummaryperTimeResponseResponseObject}
     * @memberof ControllerResponseOfListOfGetActivitySummaryperTimeResponse
     */
    'responseObject'?: ControllerResponseOfListOfGetActivitySummaryperTimeResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetActivitySummaryperTimeResponseResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetActivitySummaryperTimeResponseResponseObject = ResponseOfListOfGetActivitySummaryperTimeResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetAgendaCalendarResponseDto
 */
export interface ControllerResponseOfListOfGetAgendaCalendarResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetAgendaCalendarResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetAgendaCalendarResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetAgendaCalendarResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetAgendaCalendarResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetAgendaCalendarResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetAgendaCalendarResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetAgendaCalendarResponseDtoResponseObject = ResponseOfListOfGetAgendaCalendarResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetAnnualGoalByKPIResponseDto
 */
export interface ControllerResponseOfListOfGetAnnualGoalByKPIResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetAnnualGoalByKPIResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetAnnualGoalByKPIResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetAnnualGoalByKPIResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetAnnualGoalByKPIResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetAnnualGoalByKPIResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetAnnualGoalByKPIResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetAnnualGoalByKPIResponseDtoResponseObject = ResponseOfListOfGetAnnualGoalByKPIResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetAnnualGoalDto
 */
export interface ControllerResponseOfListOfGetAnnualGoalDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetAnnualGoalDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetAnnualGoalDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetAnnualGoalDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetAnnualGoalDto
     */
    'responseObject'?: ControllerResponseOfListOfGetAnnualGoalDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetAnnualGoalDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetAnnualGoalDtoResponseObject = ResponseOfListOfGetAnnualGoalDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetAvailableChildCommitteesResponseDto
 */
export interface ControllerResponseOfListOfGetAvailableChildCommitteesResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetAvailableChildCommitteesResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetAvailableChildCommitteesResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetAvailableChildCommitteesResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetAvailableChildCommitteesResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetAvailableChildCommitteesResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetAvailableChildCommitteesResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetAvailableChildCommitteesResponseDtoResponseObject = ResponseOfListOfGetAvailableChildCommitteesResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetCalculationTypesByLanguageCodeResponseDto
 */
export interface ControllerResponseOfListOfGetCalculationTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetCalculationTypesByLanguageCodeResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetCalculationTypesByLanguageCodeResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetCalculationTypesByLanguageCodeResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetCalculationTypesByLanguageCodeResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetCalculationTypesByLanguageCodeResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetCalculationTypesByLanguageCodeResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetCalculationTypesByLanguageCodeResponseDtoResponseObject = ResponseOfListOfGetCalculationTypesByLanguageCodeResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetChildDeliverableResponseDto
 */
export interface ControllerResponseOfListOfGetChildDeliverableResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetChildDeliverableResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetChildDeliverableResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetChildDeliverableResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetChildDeliverableResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetChildDeliverableResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetChildDeliverableResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetChildDeliverableResponseDtoResponseObject = ResponseOfListOfGetChildDeliverableResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetCommitteeActivitiesResponseDto
 */
export interface ControllerResponseOfListOfGetCommitteeActivitiesResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetCommitteeActivitiesResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetCommitteeActivitiesResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetCommitteeActivitiesResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetCommitteeActivitiesResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetCommitteeActivitiesResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetCommitteeActivitiesResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetCommitteeActivitiesResponseDtoResponseObject = ResponseOfListOfGetCommitteeActivitiesResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetCommitteeAvailableProjectsResponseDto
 */
export interface ControllerResponseOfListOfGetCommitteeAvailableProjectsResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetCommitteeAvailableProjectsResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetCommitteeAvailableProjectsResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetCommitteeAvailableProjectsResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetCommitteeAvailableProjectsResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetCommitteeAvailableProjectsResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetCommitteeAvailableProjectsResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetCommitteeAvailableProjectsResponseDtoResponseObject = ResponseOfListOfGetCommitteeAvailableProjectsResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetCommitteeListPageResponseDto
 */
export interface ControllerResponseOfListOfGetCommitteeListPageResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetCommitteeListPageResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetCommitteeListPageResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetCommitteeListPageResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetCommitteeListPageResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetCommitteeListPageResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetCommitteeListPageResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetCommitteeListPageResponseDtoResponseObject = ResponseOfListOfGetCommitteeListPageResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetCommitteePicklistDto
 */
export interface ControllerResponseOfListOfGetCommitteePicklistDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetCommitteePicklistDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetCommitteePicklistDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetCommitteePicklistDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetCommitteePicklistDto
     */
    'responseObject'?: ControllerResponseOfListOfGetCommitteePicklistDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetCommitteePicklistDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetCommitteePicklistDtoResponseObject = ResponseOfListOfGetCommitteePicklistDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetCommitteeProjectsResponseDto
 */
export interface ControllerResponseOfListOfGetCommitteeProjectsResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetCommitteeProjectsResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetCommitteeProjectsResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetCommitteeProjectsResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetCommitteeProjectsResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetCommitteeProjectsResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetCommitteeProjectsResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetCommitteeProjectsResponseDtoResponseObject = ResponseOfListOfGetCommitteeProjectsResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetCommitteeSessionsResponseDto
 */
export interface ControllerResponseOfListOfGetCommitteeSessionsResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetCommitteeSessionsResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetCommitteeSessionsResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetCommitteeSessionsResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetCommitteeSessionsResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetCommitteeSessionsResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetCommitteeSessionsResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetCommitteeSessionsResponseDtoResponseObject = ResponseOfListOfGetCommitteeSessionsResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetCommitteeSummaryPerStatusResponse
 */
export interface ControllerResponseOfListOfGetCommitteeSummaryPerStatusResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetCommitteeSummaryPerStatusResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetCommitteeSummaryPerStatusResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetCommitteeSummaryPerStatusResponseResponseObject}
     * @memberof ControllerResponseOfListOfGetCommitteeSummaryPerStatusResponse
     */
    'responseObject'?: ControllerResponseOfListOfGetCommitteeSummaryPerStatusResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetCommitteeSummaryPerStatusResponseResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetCommitteeSummaryPerStatusResponseResponseObject = ResponseOfListOfGetCommitteeSummaryPerStatusResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetCommitteeSummaryPerTimeResponse
 */
export interface ControllerResponseOfListOfGetCommitteeSummaryPerTimeResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetCommitteeSummaryPerTimeResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetCommitteeSummaryPerTimeResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetCommitteeSummaryPerTimeResponseResponseObject}
     * @memberof ControllerResponseOfListOfGetCommitteeSummaryPerTimeResponse
     */
    'responseObject'?: ControllerResponseOfListOfGetCommitteeSummaryPerTimeResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetCommitteeSummaryPerTimeResponseResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetCommitteeSummaryPerTimeResponseResponseObject = ResponseOfListOfGetCommitteeSummaryPerTimeResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetCompanyListResponseDto
 */
export interface ControllerResponseOfListOfGetCompanyListResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetCompanyListResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetCompanyListResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetCompanyListResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetCompanyListResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetCompanyListResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetCompanyListResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetCompanyListResponseDtoResponseObject = ResponseOfListOfGetCompanyListResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetDeliverableListPageResponseDto
 */
export interface ControllerResponseOfListOfGetDeliverableListPageResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetDeliverableListPageResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetDeliverableListPageResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetDeliverableListPageResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetDeliverableListPageResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetDeliverableListPageResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetDeliverableListPageResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetDeliverableListPageResponseDtoResponseObject = ResponseOfListOfGetDeliverableListPageResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetDeliverablePicklistResponseDto
 */
export interface ControllerResponseOfListOfGetDeliverablePicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetDeliverablePicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetDeliverablePicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetDeliverablePicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetDeliverablePicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetDeliverablePicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetDeliverablePicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetDeliverablePicklistResponseDtoResponseObject = ResponseOfListOfGetDeliverablePicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDtoResponseObject = ResponseOfListOfGetDeliverableProjectPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetDeliverableSummaryperStatusResponseDto
 */
export interface ControllerResponseOfListOfGetDeliverableSummaryperStatusResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetDeliverableSummaryperStatusResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetDeliverableSummaryperStatusResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetDeliverableSummaryperStatusResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetDeliverableSummaryperStatusResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetDeliverableSummaryperStatusResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetDeliverableSummaryperStatusResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetDeliverableSummaryperStatusResponseDtoResponseObject = ResponseOfListOfGetDeliverableSummaryperStatusResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetDeliverableSummaryperTimeResponseDto
 */
export interface ControllerResponseOfListOfGetDeliverableSummaryperTimeResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetDeliverableSummaryperTimeResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetDeliverableSummaryperTimeResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetDeliverableSummaryperTimeResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetDeliverableSummaryperTimeResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetDeliverableSummaryperTimeResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetDeliverableSummaryperTimeResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetDeliverableSummaryperTimeResponseDtoResponseObject = ResponseOfListOfGetDeliverableSummaryperTimeResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetDivisionListPageResponseDto
 */
export interface ControllerResponseOfListOfGetDivisionListPageResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetDivisionListPageResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetDivisionListPageResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetDivisionListPageResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetDivisionListPageResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetDivisionListPageResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetDivisionListPageResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetDivisionListPageResponseDtoResponseObject = ResponseOfListOfGetDivisionListPageResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetDivisionPicklistDto
 */
export interface ControllerResponseOfListOfGetDivisionPicklistDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetDivisionPicklistDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetDivisionPicklistDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetDivisionPicklistDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetDivisionPicklistDto
     */
    'responseObject'?: ControllerResponseOfListOfGetDivisionPicklistDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetDivisionPicklistDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetDivisionPicklistDtoResponseObject = ResponseOfListOfGetDivisionPicklistDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetDivisionProjectCreationPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetDivisionProjectCreationPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetDivisionProjectCreationPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetDivisionProjectCreationPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetDivisionProjectCreationPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetDivisionProjectCreationPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetDivisionProjectCreationPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetDivisionProjectCreationPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetDivisionProjectCreationPicklistResponseDtoResponseObject = ResponseOfListOfGetDivisionProjectCreationPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetFeedActivityListResponseDto
 */
export interface ControllerResponseOfListOfGetFeedActivityListResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetFeedActivityListResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetFeedActivityListResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetFeedActivityListResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetFeedActivityListResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetFeedActivityListResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetFeedActivityListResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetFeedActivityListResponseDtoResponseObject = ResponseOfListOfGetFeedActivityListResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetFeedActivityResponseDto
 */
export interface ControllerResponseOfListOfGetFeedActivityResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetFeedActivityResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetFeedActivityResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetFeedActivityResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetFeedActivityResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetFeedActivityResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetFeedActivityResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetFeedActivityResponseDtoResponseObject = ResponseOfListOfGetFeedActivityResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetFeedMonthlyAlertByUserResponseDto
 */
export interface ControllerResponseOfListOfGetFeedMonthlyAlertByUserResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetFeedMonthlyAlertByUserResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetFeedMonthlyAlertByUserResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetFeedMonthlyAlertByUserResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetFeedMonthlyAlertByUserResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetFeedMonthlyAlertByUserResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetFeedMonthlyAlertByUserResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetFeedMonthlyAlertByUserResponseDtoResponseObject = ResponseOfListOfGetFeedMonthlyAlertByUserResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetFeedSummaryResponseDto
 */
export interface ControllerResponseOfListOfGetFeedSummaryResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetFeedSummaryResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetFeedSummaryResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetFeedSummaryResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetFeedSummaryResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetFeedSummaryResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetFeedSummaryResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetFeedSummaryResponseDtoResponseObject = ResponseOfListOfGetFeedSummaryResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetFollowUpSectionA3ResponseDto
 */
export interface ControllerResponseOfListOfGetFollowUpSectionA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetFollowUpSectionA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetFollowUpSectionA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetFollowUpSectionA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetFollowUpSectionA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetFollowUpSectionA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetFollowUpSectionA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetFollowUpSectionA3ResponseDtoResponseObject = ResponseOfListOfGetFollowUpSectionA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetGoalCategoryResponseDto
 */
export interface ControllerResponseOfListOfGetGoalCategoryResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetGoalCategoryResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetGoalCategoryResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetGoalCategoryResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetGoalCategoryResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetGoalCategoryResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetGoalCategoryResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetGoalCategoryResponseDtoResponseObject = ResponseOfListOfGetGoalCategoryResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetGoalTypePicklistDto
 */
export interface ControllerResponseOfListOfGetGoalTypePicklistDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetGoalTypePicklistDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetGoalTypePicklistDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetGoalTypePicklistDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetGoalTypePicklistDto
     */
    'responseObject'?: ControllerResponseOfListOfGetGoalTypePicklistDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetGoalTypePicklistDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetGoalTypePicklistDtoResponseObject = ResponseOfListOfGetGoalTypePicklistDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIAnnualGoalPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetKPIAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIAnnualGoalPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIAnnualGoalPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIAnnualGoalPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIAnnualGoalPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIAnnualGoalPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIAnnualGoalPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIAnnualGoalPicklistResponseDtoResponseObject = ResponseOfListOfGetKPIAnnualGoalPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIByAnnualGoalResponseDto
 */
export interface ControllerResponseOfListOfGetKPIByAnnualGoalResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIByAnnualGoalResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIByAnnualGoalResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIByAnnualGoalResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIByAnnualGoalResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIByAnnualGoalResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIByAnnualGoalResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIByAnnualGoalResponseDtoResponseObject = ResponseOfListOfGetKPIByAnnualGoalResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIByProjectResponseDto
 */
export interface ControllerResponseOfListOfGetKPIByProjectResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIByProjectResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIByProjectResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIByProjectResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIByProjectResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIByProjectResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIByProjectResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIByProjectResponseDtoResponseObject = ResponseOfListOfGetKPIByProjectResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIChildPicklistResponse
 */
export interface ControllerResponseOfListOfGetKPIChildPicklistResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIChildPicklistResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIChildPicklistResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIChildPicklistResponseResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIChildPicklistResponse
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIChildPicklistResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIChildPicklistResponseResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIChildPicklistResponseResponseObject = ResponseOfListOfGetKPIChildPicklistResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDto
 */
export interface ControllerResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDtoResponseObject = ResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIDashboardSintesiByProjectResponseDto
 */
export interface ControllerResponseOfListOfGetKPIDashboardSintesiByProjectResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIDashboardSintesiByProjectResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIDashboardSintesiByProjectResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIDashboardSintesiByProjectResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIDashboardSintesiByProjectResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIDashboardSintesiByProjectResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIDashboardSintesiByProjectResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIDashboardSintesiByProjectResponseDtoResponseObject = ResponseOfListOfGetKPIDashboardSintesiByProjectResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIGerarchicoInfoDTO
 */
export interface ControllerResponseOfListOfGetKPIGerarchicoInfoDTO {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIGerarchicoInfoDTO
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIGerarchicoInfoDTO
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIGerarchicoInfoDTOResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIGerarchicoInfoDTO
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIGerarchicoInfoDTOResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIGerarchicoInfoDTOResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIGerarchicoInfoDTOResponseObject = ResponseOfListOfGetKPIGerarchicoInfoDTO;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIGraphicResponseDto
 */
export interface ControllerResponseOfListOfGetKPIGraphicResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIGraphicResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIGraphicResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIGraphicResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIGraphicResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIGraphicResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIGraphicResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIGraphicResponseDtoResponseObject = ResponseOfListOfGetKPIGraphicResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIListDto
 */
export interface ControllerResponseOfListOfGetKPIListDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIListDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIListDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIListDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIListDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIListDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIListDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIListDtoResponseObject = ResponseOfListOfGetKPIListDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIPicklistA3ResponseDto
 */
export interface ControllerResponseOfListOfGetKPIPicklistA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIPicklistA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIPicklistA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIPicklistA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIPicklistA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIPicklistA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIPicklistA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIPicklistA3ResponseDtoResponseObject = ResponseOfListOfGetKPIPicklistA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIProjectPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetKPIProjectPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIProjectPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIProjectPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIProjectPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIProjectPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIProjectPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIProjectPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIProjectPicklistResponseDtoResponseObject = ResponseOfListOfGetKPIProjectPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPISectionA3ResponseDto
 */
export interface ControllerResponseOfListOfGetKPISectionA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPISectionA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPISectionA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPISectionA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPISectionA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPISectionA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPISectionA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPISectionA3ResponseDtoResponseObject = ResponseOfListOfGetKPISectionA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPISummaryResponseDto
 */
export interface ControllerResponseOfListOfGetKPISummaryResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPISummaryResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPISummaryResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPISummaryResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPISummaryResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPISummaryResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPISummaryResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPISummaryResponseDtoResponseObject = ResponseOfListOfGetKPISummaryResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetKPIXMatrixPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetKPIXMatrixPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetKPIXMatrixPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetKPIXMatrixPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetKPIXMatrixPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetKPIXMatrixPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetKPIXMatrixPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetKPIXMatrixPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetKPIXMatrixPicklistResponseDtoResponseObject = ResponseOfListOfGetKPIXMatrixPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetLicenseTypesByLanguageCodeResponseDto
 */
export interface ControllerResponseOfListOfGetLicenseTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetLicenseTypesByLanguageCodeResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetLicenseTypesByLanguageCodeResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetLicenseTypesByLanguageCodeResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetLicenseTypesByLanguageCodeResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetLicenseTypesByLanguageCodeResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetLicenseTypesByLanguageCodeResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetLicenseTypesByLanguageCodeResponseDtoResponseObject = ResponseOfListOfGetLicenseTypesByLanguageCodeResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetLongTermGoalByKPIResponseDto
 */
export interface ControllerResponseOfListOfGetLongTermGoalByKPIResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetLongTermGoalByKPIResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetLongTermGoalByKPIResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetLongTermGoalByKPIResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetLongTermGoalByKPIResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetLongTermGoalByKPIResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetLongTermGoalByKPIResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetLongTermGoalByKPIResponseDtoResponseObject = ResponseOfListOfGetLongTermGoalByKPIResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDto
 */
export interface ControllerResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDtoResponseObject = ResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetMasterPlanDeliverableResponseDto
 */
export interface ControllerResponseOfListOfGetMasterPlanDeliverableResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetMasterPlanDeliverableResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetMasterPlanDeliverableResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetMasterPlanDeliverableResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetMasterPlanDeliverableResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetMasterPlanDeliverableResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetMasterPlanDeliverableResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetMasterPlanDeliverableResponseDtoResponseObject = ResponseOfListOfGetMasterPlanDeliverableResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetMasterplanParentDeliverableResponseDto
 */
export interface ControllerResponseOfListOfGetMasterplanParentDeliverableResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetMasterplanParentDeliverableResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetMasterplanParentDeliverableResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetMasterplanParentDeliverableResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetMasterplanParentDeliverableResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetMasterplanParentDeliverableResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetMasterplanParentDeliverableResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetMasterplanParentDeliverableResponseDtoResponseObject = ResponseOfListOfGetMasterplanParentDeliverableResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetMasterplanParentDeliverableTestResponseDto
 */
export interface ControllerResponseOfListOfGetMasterplanParentDeliverableTestResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetMasterplanParentDeliverableTestResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetMasterplanParentDeliverableTestResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetMasterplanParentDeliverableTestResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetMasterplanParentDeliverableTestResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetMasterplanParentDeliverableTestResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetMasterplanParentDeliverableTestResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetMasterplanParentDeliverableTestResponseDtoResponseObject = ResponseOfListOfGetMasterplanParentDeliverableTestResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetMembersListA3ResponseDto
 */
export interface ControllerResponseOfListOfGetMembersListA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetMembersListA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetMembersListA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetMembersListA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetMembersListA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetMembersListA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetMembersListA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetMembersListA3ResponseDtoResponseObject = ResponseOfListOfGetMembersListA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetMenuItemResponse
 */
export interface ControllerResponseOfListOfGetMenuItemResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetMenuItemResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetMenuItemResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetMenuItemResponseResponseObject}
     * @memberof ControllerResponseOfListOfGetMenuItemResponse
     */
    'responseObject'?: ControllerResponseOfListOfGetMenuItemResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetMenuItemResponseResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetMenuItemResponseResponseObject = ResponseOfListOfGetMenuItemResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetMonthlyAlertByUserResponseDto
 */
export interface ControllerResponseOfListOfGetMonthlyAlertByUserResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetMonthlyAlertByUserResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetMonthlyAlertByUserResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetMonthlyAlertByUserResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetMonthlyAlertByUserResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetMonthlyAlertByUserResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetMonthlyAlertByUserResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetMonthlyAlertByUserResponseDtoResponseObject = ResponseOfListOfGetMonthlyAlertByUserResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetMyActivityDeliverableFormResponseDto
 */
export interface ControllerResponseOfListOfGetMyActivityDeliverableFormResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetMyActivityDeliverableFormResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetMyActivityDeliverableFormResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetMyActivityDeliverableFormResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetMyActivityDeliverableFormResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetMyActivityDeliverableFormResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetMyActivityDeliverableFormResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetMyActivityDeliverableFormResponseDtoResponseObject = ResponseOfListOfGetMyActivityDeliverableFormResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetMyActivityMenuFormPerUserResponseDto
 */
export interface ControllerResponseOfListOfGetMyActivityMenuFormPerUserResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetMyActivityMenuFormPerUserResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetMyActivityMenuFormPerUserResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetMyActivityMenuFormPerUserResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetMyActivityMenuFormPerUserResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetMyActivityMenuFormPerUserResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetMyActivityMenuFormPerUserResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetMyActivityMenuFormPerUserResponseDtoResponseObject = ResponseOfListOfGetMyActivityMenuFormPerUserResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetMyActivityMenuFormResponseDto
 */
export interface ControllerResponseOfListOfGetMyActivityMenuFormResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetMyActivityMenuFormResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetMyActivityMenuFormResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetMyActivityMenuFormResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetMyActivityMenuFormResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetMyActivityMenuFormResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetMyActivityMenuFormResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetMyActivityMenuFormResponseDtoResponseObject = ResponseOfListOfGetMyActivityMenuFormResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetMyActivityProjectFormrResponseDto
 */
export interface ControllerResponseOfListOfGetMyActivityProjectFormrResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetMyActivityProjectFormrResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetMyActivityProjectFormrResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetMyActivityProjectFormrResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetMyActivityProjectFormrResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetMyActivityProjectFormrResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetMyActivityProjectFormrResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetMyActivityProjectFormrResponseDtoResponseObject = ResponseOfListOfGetMyActivityProjectFormrResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetNewDivisionSponsorPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetNewDivisionSponsorPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetNewDivisionSponsorPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetNewDivisionSponsorPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetNewDivisionSponsorPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetNewDivisionSponsorPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetNewDivisionSponsorPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetNewDivisionSponsorPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetNewDivisionSponsorPicklistResponseDtoResponseObject = ResponseOfListOfGetNewDivisionSponsorPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDtoResponseObject = ResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDtoResponseObject = ResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDtoResponseObject = ResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDtoResponseObject = ResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDtoResponseObject = ResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetNewXmatrixProjectPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetNewXmatrixProjectPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetNewXmatrixProjectPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetNewXmatrixProjectPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetNewXmatrixProjectPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetNewXmatrixProjectPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetNewXmatrixProjectPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetNewXmatrixProjectPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetNewXmatrixProjectPicklistResponseDtoResponseObject = ResponseOfListOfGetNewXmatrixProjectPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetNoCommitteeProjectsResponseDto
 */
export interface ControllerResponseOfListOfGetNoCommitteeProjectsResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetNoCommitteeProjectsResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetNoCommitteeProjectsResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetNoCommitteeProjectsResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetNoCommitteeProjectsResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetNoCommitteeProjectsResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetNoCommitteeProjectsResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetNoCommitteeProjectsResponseDtoResponseObject = ResponseOfListOfGetNoCommitteeProjectsResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetParentProjectPicklistDto
 */
export interface ControllerResponseOfListOfGetParentProjectPicklistDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetParentProjectPicklistDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetParentProjectPicklistDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetParentProjectPicklistDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetParentProjectPicklistDto
     */
    'responseObject'?: ControllerResponseOfListOfGetParentProjectPicklistDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetParentProjectPicklistDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetParentProjectPicklistDtoResponseObject = ResponseOfListOfGetParentProjectPicklistDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectChildsResponseDto
 */
export interface ControllerResponseOfListOfGetProjectChildsResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectChildsResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectChildsResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectChildsResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectChildsResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectChildsResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectChildsResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectChildsResponseDtoResponseObject = ResponseOfListOfGetProjectChildsResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectCodesResponseDto
 */
export interface ControllerResponseOfListOfGetProjectCodesResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectCodesResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectCodesResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectCodesResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectCodesResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectCodesResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectCodesResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectCodesResponseDtoResponseObject = ResponseOfListOfGetProjectCodesResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectCompletedActivitiesA3ResponseDto
 */
export interface ControllerResponseOfListOfGetProjectCompletedActivitiesA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectCompletedActivitiesA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectCompletedActivitiesA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectCompletedActivitiesA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectCompletedActivitiesA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectCompletedActivitiesA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectCompletedActivitiesA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectCompletedActivitiesA3ResponseDtoResponseObject = ResponseOfListOfGetProjectCompletedActivitiesA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectCreationPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetProjectCreationPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectCreationPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectCreationPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectCreationPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectCreationPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectCreationPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectCreationPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectCreationPicklistResponseDtoResponseObject = ResponseOfListOfGetProjectCreationPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectDeliverableNextStepA3ResponseDto
 */
export interface ControllerResponseOfListOfGetProjectDeliverableNextStepA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectDeliverableNextStepA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectDeliverableNextStepA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectDeliverableNextStepA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectDeliverableNextStepA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectDeliverableNextStepA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectDeliverableNextStepA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectDeliverableNextStepA3ResponseDtoResponseObject = ResponseOfListOfGetProjectDeliverableNextStepA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectDeliverablePicklistResponseDto
 */
export interface ControllerResponseOfListOfGetProjectDeliverablePicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectDeliverablePicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectDeliverablePicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectDeliverablePicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectDeliverablePicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectDeliverablePicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectDeliverablePicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectDeliverablePicklistResponseDtoResponseObject = ResponseOfListOfGetProjectDeliverablePicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectDeliverableTeamResponseDto
 */
export interface ControllerResponseOfListOfGetProjectDeliverableTeamResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectDeliverableTeamResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectDeliverableTeamResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectDeliverableTeamResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectDeliverableTeamResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectDeliverableTeamResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectDeliverableTeamResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectDeliverableTeamResponseDtoResponseObject = ResponseOfListOfGetProjectDeliverableTeamResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectFeedActivityListResponseDto
 */
export interface ControllerResponseOfListOfGetProjectFeedActivityListResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectFeedActivityListResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectFeedActivityListResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectFeedActivityListResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectFeedActivityListResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectFeedActivityListResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectFeedActivityListResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectFeedActivityListResponseDtoResponseObject = ResponseOfListOfGetProjectFeedActivityListResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectListPageResponseDto
 */
export interface ControllerResponseOfListOfGetProjectListPageResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectListPageResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectListPageResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectListPageResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectListPageResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectListPageResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectListPageResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectListPageResponseDtoResponseObject = ResponseOfListOfGetProjectListPageResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectMembersListResponseDto
 */
export interface ControllerResponseOfListOfGetProjectMembersListResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectMembersListResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectMembersListResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectMembersListResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectMembersListResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectMembersListResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectMembersListResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectMembersListResponseDtoResponseObject = ResponseOfListOfGetProjectMembersListResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectMembersResponseDto
 */
export interface ControllerResponseOfListOfGetProjectMembersResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectMembersResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectMembersResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectMembersResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectMembersResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectMembersResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectMembersResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectMembersResponseDtoResponseObject = ResponseOfListOfGetProjectMembersResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetProjectPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectPicklistResponseDtoResponseObject = ResponseOfListOfGetProjectPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetProjectStatusPicklistDto
 */
export interface ControllerResponseOfListOfGetProjectStatusPicklistDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetProjectStatusPicklistDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetProjectStatusPicklistDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetProjectStatusPicklistDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetProjectStatusPicklistDto
     */
    'responseObject'?: ControllerResponseOfListOfGetProjectStatusPicklistDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetProjectStatusPicklistDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetProjectStatusPicklistDtoResponseObject = ResponseOfListOfGetProjectStatusPicklistDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetResourceWorkloadSummaryResponseDto
 */
export interface ControllerResponseOfListOfGetResourceWorkloadSummaryResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetResourceWorkloadSummaryResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetResourceWorkloadSummaryResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetResourceWorkloadSummaryResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetResourceWorkloadSummaryResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetResourceWorkloadSummaryResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetResourceWorkloadSummaryResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetResourceWorkloadSummaryResponseDtoResponseObject = ResponseOfListOfGetResourceWorkloadSummaryResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetRiskPrecautionA3ResponseDto
 */
export interface ControllerResponseOfListOfGetRiskPrecautionA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetRiskPrecautionA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetRiskPrecautionA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetRiskPrecautionA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetRiskPrecautionA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetRiskPrecautionA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetRiskPrecautionA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetRiskPrecautionA3ResponseDtoResponseObject = ResponseOfListOfGetRiskPrecautionA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetRiskPrecautionResponseDto
 */
export interface ControllerResponseOfListOfGetRiskPrecautionResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetRiskPrecautionResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetRiskPrecautionResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetRiskPrecautionResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetRiskPrecautionResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetRiskPrecautionResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetRiskPrecautionResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetRiskPrecautionResponseDtoResponseObject = ResponseOfListOfGetRiskPrecautionResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetRolePicklistDto
 */
export interface ControllerResponseOfListOfGetRolePicklistDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetRolePicklistDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetRolePicklistDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetRolePicklistDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetRolePicklistDto
     */
    'responseObject'?: ControllerResponseOfListOfGetRolePicklistDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetRolePicklistDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetRolePicklistDtoResponseObject = ResponseOfListOfGetRolePicklistDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetRollupKPIChildResponseDto
 */
export interface ControllerResponseOfListOfGetRollupKPIChildResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetRollupKPIChildResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetRollupKPIChildResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetRollupKPIChildResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetRollupKPIChildResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetRollupKPIChildResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetRollupKPIChildResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetRollupKPIChildResponseDtoResponseObject = ResponseOfListOfGetRollupKPIChildResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetRootXMatrixDivisionListResponseDto
 */
export interface ControllerResponseOfListOfGetRootXMatrixDivisionListResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetRootXMatrixDivisionListResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetRootXMatrixDivisionListResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetRootXMatrixDivisionListResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetRootXMatrixDivisionListResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetRootXMatrixDivisionListResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetRootXMatrixDivisionListResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetRootXMatrixDivisionListResponseDtoResponseObject = ResponseOfListOfGetRootXMatrixDivisionListResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDto
 */
export interface ControllerResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDtoResponseObject = ResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetSemaphorePicklistDto
 */
export interface ControllerResponseOfListOfGetSemaphorePicklistDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetSemaphorePicklistDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetSemaphorePicklistDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetSemaphorePicklistDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetSemaphorePicklistDto
     */
    'responseObject'?: ControllerResponseOfListOfGetSemaphorePicklistDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetSemaphorePicklistDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetSemaphorePicklistDtoResponseObject = ResponseOfListOfGetSemaphorePicklistDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetSintesiComitatiResponse
 */
export interface ControllerResponseOfListOfGetSintesiComitatiResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetSintesiComitatiResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetSintesiComitatiResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetSintesiComitatiResponseResponseObject}
     * @memberof ControllerResponseOfListOfGetSintesiComitatiResponse
     */
    'responseObject'?: ControllerResponseOfListOfGetSintesiComitatiResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetSintesiComitatiResponseResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetSintesiComitatiResponseResponseObject = ResponseOfListOfGetSintesiComitatiResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetSponsorPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetSponsorPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetSponsorPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetSponsorPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetSponsorPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetSponsorPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetSponsorPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetSponsorPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetSponsorPicklistResponseDtoResponseObject = ResponseOfListOfGetSponsorPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetStatusTypeByLanguageCodeResponseDto
 */
export interface ControllerResponseOfListOfGetStatusTypeByLanguageCodeResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetStatusTypeByLanguageCodeResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetStatusTypeByLanguageCodeResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetStatusTypeByLanguageCodeResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetStatusTypeByLanguageCodeResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetStatusTypeByLanguageCodeResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetStatusTypeByLanguageCodeResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetStatusTypeByLanguageCodeResponseDtoResponseObject = ResponseOfListOfGetStatusTypeByLanguageCodeResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDto
 */
export interface ControllerResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDtoResponseObject = ResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetTeamLeaderActivityMenuFormResponseDto
 */
export interface ControllerResponseOfListOfGetTeamLeaderActivityMenuFormResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetTeamLeaderActivityMenuFormResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetTeamLeaderActivityMenuFormResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetTeamLeaderActivityMenuFormResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetTeamLeaderActivityMenuFormResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetTeamLeaderActivityMenuFormResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetTeamLeaderActivityMenuFormResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetTeamLeaderActivityMenuFormResponseDtoResponseObject = ResponseOfListOfGetTeamLeaderActivityMenuFormResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetTeamLeaderPicklistDto
 */
export interface ControllerResponseOfListOfGetTeamLeaderPicklistDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetTeamLeaderPicklistDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetTeamLeaderPicklistDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetTeamLeaderPicklistDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetTeamLeaderPicklistDto
     */
    'responseObject'?: ControllerResponseOfListOfGetTeamLeaderPicklistDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetTeamLeaderPicklistDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetTeamLeaderPicklistDtoResponseObject = ResponseOfListOfGetTeamLeaderPicklistDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetTeamLeaderProjectResponseDto
 */
export interface ControllerResponseOfListOfGetTeamLeaderProjectResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetTeamLeaderProjectResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetTeamLeaderProjectResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetTeamLeaderProjectResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetTeamLeaderProjectResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetTeamLeaderProjectResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetTeamLeaderProjectResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetTeamLeaderProjectResponseDtoResponseObject = ResponseOfListOfGetTeamLeaderProjectResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetTotalActiveUsersByRoleResponseDto
 */
export interface ControllerResponseOfListOfGetTotalActiveUsersByRoleResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetTotalActiveUsersByRoleResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetTotalActiveUsersByRoleResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetTotalActiveUsersByRoleResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetTotalActiveUsersByRoleResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetTotalActiveUsersByRoleResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetTotalActiveUsersByRoleResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetTotalActiveUsersByRoleResponseDtoResponseObject = ResponseOfListOfGetTotalActiveUsersByRoleResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetUserCommitteeDto
 */
export interface ControllerResponseOfListOfGetUserCommitteeDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetUserCommitteeDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetUserCommitteeDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetUserCommitteeDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetUserCommitteeDto
     */
    'responseObject'?: ControllerResponseOfListOfGetUserCommitteeDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetUserCommitteeDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetUserCommitteeDtoResponseObject = ResponseOfListOfGetUserCommitteeDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetUserProjectResponseDto
 */
export interface ControllerResponseOfListOfGetUserProjectResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetUserProjectResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetUserProjectResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetUserProjectResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetUserProjectResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetUserProjectResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetUserProjectResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetUserProjectResponseDtoResponseObject = ResponseOfListOfGetUserProjectResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetUserSelectResponseDto
 */
export interface ControllerResponseOfListOfGetUserSelectResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetUserSelectResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetUserSelectResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetUserSelectResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetUserSelectResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetUserSelectResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetUserSelectResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetUserSelectResponseDtoResponseObject = ResponseOfListOfGetUserSelectResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetXMatrixCycleResponseDto
 */
export interface ControllerResponseOfListOfGetXMatrixCycleResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetXMatrixCycleResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetXMatrixCycleResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetXMatrixCycleResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetXMatrixCycleResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetXMatrixCycleResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetXMatrixCycleResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetXMatrixCycleResponseDtoResponseObject = ResponseOfListOfGetXMatrixCycleResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDtoResponseObject = ResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetXMatrixPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetXMatrixPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetXMatrixPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetXMatrixPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetXMatrixPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetXMatrixPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetXMatrixPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetXMatrixPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetXMatrixPicklistResponseDtoResponseObject = ResponseOfListOfGetXMatrixPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetXMatrixUsersResponseDto
 */
export interface ControllerResponseOfListOfGetXMatrixUsersResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetXMatrixUsersResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetXMatrixUsersResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetXMatrixUsersResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetXMatrixUsersResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetXMatrixUsersResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetXMatrixUsersResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetXMatrixUsersResponseDtoResponseObject = ResponseOfListOfGetXMatrixUsersResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDtoResponseObject = ResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGetXmatrixDivisionPicklistResponseDto
 */
export interface ControllerResponseOfListOfGetXmatrixDivisionPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGetXmatrixDivisionPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGetXmatrixDivisionPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGetXmatrixDivisionPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGetXmatrixDivisionPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGetXmatrixDivisionPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGetXmatrixDivisionPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGetXmatrixDivisionPicklistResponseDtoResponseObject = ResponseOfListOfGetXmatrixDivisionPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfGoalCategoryIconlistResponseDto
 */
export interface ControllerResponseOfListOfGoalCategoryIconlistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfGoalCategoryIconlistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfGoalCategoryIconlistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfGoalCategoryIconlistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfGoalCategoryIconlistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfGoalCategoryIconlistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfGoalCategoryIconlistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfGoalCategoryIconlistResponseDtoResponseObject = ResponseOfListOfGoalCategoryIconlistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfKPIBowlingChartResponse
 */
export interface ControllerResponseOfListOfKPIBowlingChartResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfKPIBowlingChartResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfKPIBowlingChartResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfKPIBowlingChartResponseResponseObject}
     * @memberof ControllerResponseOfListOfKPIBowlingChartResponse
     */
    'responseObject'?: ControllerResponseOfListOfKPIBowlingChartResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfKPIBowlingChartResponseResponseObject
 * @export
 */
export type ControllerResponseOfListOfKPIBowlingChartResponseResponseObject = ResponseOfListOfKPIBowlingChartResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfKPIDetailsDto
 */
export interface ControllerResponseOfListOfKPIDetailsDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfKPIDetailsDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfKPIDetailsDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfKPIDetailsDtoResponseObject}
     * @memberof ControllerResponseOfListOfKPIDetailsDto
     */
    'responseObject'?: ControllerResponseOfListOfKPIDetailsDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfKPIDetailsDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfKPIDetailsDtoResponseObject = ResponseOfListOfKPIDetailsDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfKPIDetailsGraficoA3ResponseDto
 */
export interface ControllerResponseOfListOfKPIDetailsGraficoA3ResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfKPIDetailsGraficoA3ResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfKPIDetailsGraficoA3ResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfKPIDetailsGraficoA3ResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfKPIDetailsGraficoA3ResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfKPIDetailsGraficoA3ResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfKPIDetailsGraficoA3ResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfKPIDetailsGraficoA3ResponseDtoResponseObject = ResponseOfListOfKPIDetailsGraficoA3ResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfKPIDetailsPastYearsResponseDto
 */
export interface ControllerResponseOfListOfKPIDetailsPastYearsResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfKPIDetailsPastYearsResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfKPIDetailsPastYearsResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfKPIDetailsPastYearsResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfKPIDetailsPastYearsResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfKPIDetailsPastYearsResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfKPIDetailsPastYearsResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfKPIDetailsPastYearsResponseDtoResponseObject = ResponseOfListOfKPIDetailsPastYearsResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfKPIPicklistResponseDto
 */
export interface ControllerResponseOfListOfKPIPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfKPIPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfKPIPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfKPIPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfKPIPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfKPIPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfKPIPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfKPIPicklistResponseDtoResponseObject = ResponseOfListOfKPIPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfLongTermGoalPicklistResponseDto
 */
export interface ControllerResponseOfListOfLongTermGoalPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfLongTermGoalPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfLongTermGoalPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfLongTermGoalPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfLongTermGoalPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfLongTermGoalPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfLongTermGoalPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfLongTermGoalPicklistResponseDtoResponseObject = ResponseOfListOfLongTermGoalPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfLongTermObjectiveDto
 */
export interface ControllerResponseOfListOfLongTermObjectiveDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfLongTermObjectiveDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfLongTermObjectiveDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfLongTermObjectiveDtoResponseObject}
     * @memberof ControllerResponseOfListOfLongTermObjectiveDto
     */
    'responseObject'?: ControllerResponseOfListOfLongTermObjectiveDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfLongTermObjectiveDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfLongTermObjectiveDtoResponseObject = ResponseOfListOfLongTermObjectiveDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfObjectCodePicklistResponseDto
 */
export interface ControllerResponseOfListOfObjectCodePicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfObjectCodePicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfObjectCodePicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfObjectCodePicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfObjectCodePicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfObjectCodePicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfObjectCodePicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfObjectCodePicklistResponseDtoResponseObject = ResponseOfListOfObjectCodePicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfProjectDashboards
 */
export interface ControllerResponseOfListOfProjectDashboards {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfProjectDashboards
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfProjectDashboards
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfProjectDashboardsResponseObject}
     * @memberof ControllerResponseOfListOfProjectDashboards
     */
    'responseObject'?: ControllerResponseOfListOfProjectDashboardsResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfProjectDashboardsResponseObject
 * @export
 */
export type ControllerResponseOfListOfProjectDashboardsResponseObject = ResponseOfListOfProjectDashboards;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfProjectDeliverableParent
 */
export interface ControllerResponseOfListOfProjectDeliverableParent {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfProjectDeliverableParent
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfProjectDeliverableParent
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfProjectDeliverableParentResponseObject}
     * @memberof ControllerResponseOfListOfProjectDeliverableParent
     */
    'responseObject'?: ControllerResponseOfListOfProjectDeliverableParentResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfProjectDeliverableParentResponseObject
 * @export
 */
export type ControllerResponseOfListOfProjectDeliverableParentResponseObject = ResponseOfListOfProjectDeliverableParent;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfProjectRoutineResponseDto
 */
export interface ControllerResponseOfListOfProjectRoutineResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfProjectRoutineResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfProjectRoutineResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfProjectRoutineResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfProjectRoutineResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfProjectRoutineResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfProjectRoutineResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfProjectRoutineResponseDtoResponseObject = ResponseOfListOfProjectRoutineResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfProjectSummary
 */
export interface ControllerResponseOfListOfProjectSummary {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfProjectSummary
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfProjectSummary
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfProjectSummaryResponseObject}
     * @memberof ControllerResponseOfListOfProjectSummary
     */
    'responseObject'?: ControllerResponseOfListOfProjectSummaryResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfProjectSummaryResponseObject
 * @export
 */
export type ControllerResponseOfListOfProjectSummaryResponseObject = ResponseOfListOfProjectSummary;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfStatusPicklistResponseDto
 */
export interface ControllerResponseOfListOfStatusPicklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfStatusPicklistResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfStatusPicklistResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfStatusPicklistResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfStatusPicklistResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfStatusPicklistResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfStatusPicklistResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfStatusPicklistResponseDtoResponseObject = ResponseOfListOfStatusPicklistResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfUpdateAnnualGoalResponse
 */
export interface ControllerResponseOfListOfUpdateAnnualGoalResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfUpdateAnnualGoalResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfUpdateAnnualGoalResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfUpdateAnnualGoalResponseResponseObject}
     * @memberof ControllerResponseOfListOfUpdateAnnualGoalResponse
     */
    'responseObject'?: ControllerResponseOfListOfUpdateAnnualGoalResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfUpdateAnnualGoalResponseResponseObject
 * @export
 */
export type ControllerResponseOfListOfUpdateAnnualGoalResponseResponseObject = ResponseOfListOfUpdateAnnualGoalResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfUserInfoResponseDto
 */
export interface ControllerResponseOfListOfUserInfoResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfUserInfoResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfUserInfoResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfUserInfoResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfUserInfoResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfUserInfoResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfUserInfoResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfUserInfoResponseDtoResponseObject = ResponseOfListOfUserInfoResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfListOfUserListPageResponseDto
 */
export interface ControllerResponseOfListOfUserListPageResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfListOfUserListPageResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfListOfUserListPageResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfListOfUserListPageResponseDtoResponseObject}
     * @memberof ControllerResponseOfListOfUserListPageResponseDto
     */
    'responseObject'?: ControllerResponseOfListOfUserListPageResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfListOfUserListPageResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfListOfUserListPageResponseDtoResponseObject = ResponseOfListOfUserListPageResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfLongTermObjectiveByIdResponse
 */
export interface ControllerResponseOfLongTermObjectiveByIdResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfLongTermObjectiveByIdResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfLongTermObjectiveByIdResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfLongTermObjectiveByIdResponseResponseObject}
     * @memberof ControllerResponseOfLongTermObjectiveByIdResponse
     */
    'responseObject'?: ControllerResponseOfLongTermObjectiveByIdResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfLongTermObjectiveByIdResponseResponseObject
 * @export
 */
export type ControllerResponseOfLongTermObjectiveByIdResponseResponseObject = ResponseOfLongTermObjectiveByIdResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfObject
 */
export interface ControllerResponseOfObject {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfObject
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfObject
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfObjectResponseObject}
     * @memberof ControllerResponseOfObject
     */
    'responseObject'?: ControllerResponseOfObjectResponseObject | null;
}
/**
 * @type ControllerResponseOfObjectResponseObject
 * @export
 */
export type ControllerResponseOfObjectResponseObject = ResponseOfObject;

/**
 * 
 * @export
 * @interface ControllerResponseOfUpdateAnnualGoalKPIRelationShipResponseDto
 */
export interface ControllerResponseOfUpdateAnnualGoalKPIRelationShipResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfUpdateAnnualGoalKPIRelationShipResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfUpdateAnnualGoalKPIRelationShipResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfUpdateAnnualGoalKPIRelationShipResponseDtoResponseObject}
     * @memberof ControllerResponseOfUpdateAnnualGoalKPIRelationShipResponseDto
     */
    'responseObject'?: ControllerResponseOfUpdateAnnualGoalKPIRelationShipResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfUpdateAnnualGoalKPIRelationShipResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfUpdateAnnualGoalKPIRelationShipResponseDtoResponseObject = ResponseOfUpdateAnnualGoalKPIRelationShipResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfUpdateDeliverableResponse
 */
export interface ControllerResponseOfUpdateDeliverableResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfUpdateDeliverableResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfUpdateDeliverableResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfUpdateDeliverableResponseResponseObject}
     * @memberof ControllerResponseOfUpdateDeliverableResponse
     */
    'responseObject'?: ControllerResponseOfUpdateDeliverableResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfUpdateDeliverableResponseResponseObject
 * @export
 */
export type ControllerResponseOfUpdateDeliverableResponseResponseObject = ResponseOfUpdateDeliverableResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfUpdateLongTermGoalResponse
 */
export interface ControllerResponseOfUpdateLongTermGoalResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfUpdateLongTermGoalResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfUpdateLongTermGoalResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfUpdateLongTermGoalResponseResponseObject}
     * @memberof ControllerResponseOfUpdateLongTermGoalResponse
     */
    'responseObject'?: ControllerResponseOfUpdateLongTermGoalResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfUpdateLongTermGoalResponseResponseObject
 * @export
 */
export type ControllerResponseOfUpdateLongTermGoalResponseResponseObject = ResponseOfUpdateLongTermGoalResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfUpdateMyActivityResponseDto
 */
export interface ControllerResponseOfUpdateMyActivityResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfUpdateMyActivityResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfUpdateMyActivityResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfUpdateMyActivityResponseDtoResponseObject}
     * @memberof ControllerResponseOfUpdateMyActivityResponseDto
     */
    'responseObject'?: ControllerResponseOfUpdateMyActivityResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfUpdateMyActivityResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfUpdateMyActivityResponseDtoResponseObject = ResponseOfUpdateMyActivityResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfUpdateProjectSecondaryProjectRelationShipResponse
 */
export interface ControllerResponseOfUpdateProjectSecondaryProjectRelationShipResponse {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfUpdateProjectSecondaryProjectRelationShipResponse
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfUpdateProjectSecondaryProjectRelationShipResponse
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfUpdateProjectSecondaryProjectRelationShipResponseResponseObject}
     * @memberof ControllerResponseOfUpdateProjectSecondaryProjectRelationShipResponse
     */
    'responseObject'?: ControllerResponseOfUpdateProjectSecondaryProjectRelationShipResponseResponseObject | null;
}
/**
 * @type ControllerResponseOfUpdateProjectSecondaryProjectRelationShipResponseResponseObject
 * @export
 */
export type ControllerResponseOfUpdateProjectSecondaryProjectRelationShipResponseResponseObject = ResponseOfUpdateProjectSecondaryProjectRelationShipResponse;

/**
 * 
 * @export
 * @interface ControllerResponseOfUserDetailsRespose
 */
export interface ControllerResponseOfUserDetailsRespose {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfUserDetailsRespose
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfUserDetailsRespose
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfUserDetailsResposeResponseObject}
     * @memberof ControllerResponseOfUserDetailsRespose
     */
    'responseObject'?: ControllerResponseOfUserDetailsResposeResponseObject | null;
}
/**
 * @type ControllerResponseOfUserDetailsResposeResponseObject
 * @export
 */
export type ControllerResponseOfUserDetailsResposeResponseObject = ResponseOfUserDetailsRespose;

/**
 * 
 * @export
 * @interface ControllerResponseOfUserProfileResponseDto
 */
export interface ControllerResponseOfUserProfileResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfUserProfileResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfUserProfileResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfUserProfileResponseDtoResponseObject}
     * @memberof ControllerResponseOfUserProfileResponseDto
     */
    'responseObject'?: ControllerResponseOfUserProfileResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfUserProfileResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfUserProfileResponseDtoResponseObject = ResponseOfUserProfileResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfXMatrixDataByIDResponseDto
 */
export interface ControllerResponseOfXMatrixDataByIDResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfXMatrixDataByIDResponseDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfXMatrixDataByIDResponseDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfXMatrixDataByIDResponseDtoResponseObject}
     * @memberof ControllerResponseOfXMatrixDataByIDResponseDto
     */
    'responseObject'?: ControllerResponseOfXMatrixDataByIDResponseDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfXMatrixDataByIDResponseDtoResponseObject
 * @export
 */
export type ControllerResponseOfXMatrixDataByIDResponseDtoResponseObject = ResponseOfXMatrixDataByIDResponseDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfXMatrixDto
 */
export interface ControllerResponseOfXMatrixDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfXMatrixDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfXMatrixDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfXMatrixDtoResponseObject}
     * @memberof ControllerResponseOfXMatrixDto
     */
    'responseObject'?: ControllerResponseOfXMatrixDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfXMatrixDtoResponseObject
 * @export
 */
export type ControllerResponseOfXMatrixDtoResponseObject = ResponseOfXMatrixDto;

/**
 * 
 * @export
 * @interface ControllerResponseOfXMatrixSecondLevelDto
 */
export interface ControllerResponseOfXMatrixSecondLevelDto {
    /**
     * 
     * @type {boolean}
     * @memberof ControllerResponseOfXMatrixSecondLevelDto
     */
    'success'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof ControllerResponseOfXMatrixSecondLevelDto
     */
    'errorMessage'?: string | null;
    /**
     * 
     * @type {ControllerResponseOfXMatrixSecondLevelDtoResponseObject}
     * @memberof ControllerResponseOfXMatrixSecondLevelDto
     */
    'responseObject'?: ControllerResponseOfXMatrixSecondLevelDtoResponseObject | null;
}
/**
 * @type ControllerResponseOfXMatrixSecondLevelDtoResponseObject
 * @export
 */
export type ControllerResponseOfXMatrixSecondLevelDtoResponseObject = ResponseOfXMatrixSecondLevelDto;

/**
 * 
 * @export
 * @interface CreatePrecaution
 */
export interface CreatePrecaution {
    /**
     * 
     * @type {string}
     * @memberof CreatePrecaution
     */
    'precautionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreatePrecaution
     */
    'precautionDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface CreateProjectRequestDto
 */
export interface CreateProjectRequestDto {
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectRequestDto
     */
    'status'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProjectRequestDto
     */
    'hasExternalReview'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof CreateProjectRequestDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof CreateProjectRequestDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof CreateProjectRequestDto
     */
    'fastCreation'?: boolean | null;
}
/**
 * 
 * @export
 * @interface DeliverableDto
 */
export interface DeliverableDto {
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'deliverableID'?: string;
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof DeliverableDto
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverableDto
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof DeliverableDto
     */
    'onTime'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof DeliverableDto
     */
    'late'?: boolean;
}
/**
 * 
 * @export
 * @interface DeliverableListObject
 */
export interface DeliverableListObject {
    /**
     * 
     * @type {string}
     * @memberof DeliverableListObject
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DeliverableListObject
     */
    'deliverableName'?: string | null;
}
/**
 * 
 * @export
 * @interface DetailsKPIResponseDto
 */
export interface DetailsKPIResponseDto {
    /**
     * 
     * @type {string}
     * @memberof DetailsKPIResponseDto
     */
    'month'?: string;
    /**
     * 
     * @type {number}
     * @memberof DetailsKPIResponseDto
     */
    'sumActual'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DetailsKPIResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof DetailsKPIResponseDto
     */
    'target'?: number | null;
}
/**
 * 
 * @export
 * @interface FastCloseDeliverableRequestDto
 */
export interface FastCloseDeliverableRequestDto {
    /**
     * 
     * @type {string}
     * @memberof FastCloseDeliverableRequestDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof FastCloseDeliverableRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof FastCloseDeliverableRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FastCloseDeliverableRequestDto
     */
    'ownerID'?: string | null;
}
/**
 * 
 * @export
 * @interface FastCloseDeliverableResponse
 */
export interface FastCloseDeliverableResponse {
    /**
     * 
     * @type {string}
     * @memberof FastCloseDeliverableResponse
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface FilterbyXMatrixResponse
 */
export interface FilterbyXMatrixResponse {
    /**
     * 
     * @type {string}
     * @memberof FilterbyXMatrixResponse
     */
    'userID'?: string;
    /**
     * 
     * @type {string}
     * @memberof FilterbyXMatrixResponse
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {Array<StatusResponnse>}
     * @memberof FilterbyXMatrixResponse
     */
    'statusList'?: Array<StatusResponnse> | null;
}
/**
 * 
 * @export
 * @interface GetActivitySummaryperStatusResponseDto
 */
export interface GetActivitySummaryperStatusResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetActivitySummaryperStatusResponseDto
     */
    'activityNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetActivitySummaryperStatusResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetActivitySummaryperStatusResponseDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetActivitySummaryperTimeResponse
 */
export interface GetActivitySummaryperTimeResponse {
    /**
     * 
     * @type {number}
     * @memberof GetActivitySummaryperTimeResponse
     */
    'activityCount'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetActivitySummaryperTimeResponse
     */
    'activityStatus'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetActivitySummaryperTimeResponse
     */
    'activityStatusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAgendaCalendarResponseDto
 */
export interface GetAgendaCalendarResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'sessions'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAgendaCalendarResponseDto
     */
    'isSubCommittee'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaCalendarResponseDto
     */
    'divisionName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAgendaResponseDto
 */
export interface GetAgendaResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'scope'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAgendaResponseDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'frequency'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'sessionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'sessionDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'agendaItems'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'committeeProjects'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'subCommitteeProjects'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAgendaResponseDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalByKPIResponseDto
 */
export interface GetAnnualGoalByKPIResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByKPIResponseDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByKPIResponseDto
     */
    'annualGoalDesription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalByLongTermGoalDto
 */
export interface GetAnnualGoalByLongTermGoalDto {
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'correlationDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalByLongTermGoalDto
     */
    'ownerName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetAnnualGoalDto
 */
export interface GetAnnualGoalDto {
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAnnualGoalDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetAnnualGoalDto
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetAnnualGoalDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetAnnualGoalDto
     */
    'canBeDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetAnualGoalDetailsDto
 */
export interface GetAnualGoalDetailsDto {
    /**
     * 
     * @type {Array<GetProjectByAnnualGoalDto>}
     * @memberof GetAnualGoalDetailsDto
     */
    'projectByAnnualGoal'?: Array<GetProjectByAnnualGoalDto> | null;
    /**
     * 
     * @type {Array<LongTermGoalByAnnualGoalResponseDto>}
     * @memberof GetAnualGoalDetailsDto
     */
    'longTermGoalByAnnualGoal'?: Array<LongTermGoalByAnnualGoalResponseDto> | null;
    /**
     * 
     * @type {ResponseOfGetAnnualGoalDtoValue}
     * @memberof GetAnualGoalDetailsDto
     */
    'getAnnualGoalDto'?: ResponseOfGetAnnualGoalDtoValue | null;
}
/**
 * 
 * @export
 * @interface GetAvailableChildCommitteesResponseDto
 */
export interface GetAvailableChildCommitteesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetAvailableChildCommitteesResponseDto
     */
    'committeeID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetAvailableChildCommitteesResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCalculationTypesByLanguageCodeResponseDto
 */
export interface GetCalculationTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCalculationTypesByLanguageCodeResponseDto
     */
    'calculationTypeID'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCalculationTypesByLanguageCodeResponseDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCalculationTypesByLanguageCodeResponseDto
     */
    'calculationType_Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCalculationTypesByLanguageCodeResponseDto
     */
    'language_Code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCalculationTypesByLanguageCodeResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetChildDeliverableResponseDto
 */
export interface GetChildDeliverableResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetChildDeliverableResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetChildDeliverableResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetChildDeliverableResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'priorityDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetChildDeliverableResponseDto
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeActivitiesResponseDto
 */
export interface GetCommitteeActivitiesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'isMirrorActivity'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeActivitiesResponseDto
     */
    'committeeName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeAvailableProjectsResponseDto
 */
export interface GetCommitteeAvailableProjectsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeAvailableProjectsResponseDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeAvailableProjectsResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeAvailableProjectsResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeAvailableProjectsResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetCommitteeAvailableProjectsResponseDto
     */
    'subCommitteeProject'?: boolean;
}
/**
 * 
 * @export
 * @interface GetCommitteeListPageResponseDto
 */
export interface GetCommitteeListPageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeListPageResponseDto
     */
    'committeeID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeListPageResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteePicklistDto
 */
export interface GetCommitteePicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetCommitteePicklistDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteePicklistDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteePicklistDto
     */
    'hasAccess'?: number;
}
/**
 * 
 * @export
 * @interface GetCommitteeProjectsResponseDto
 */
export interface GetCommitteeProjectsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeProjectsResponseDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSessionsResponseDto
 */
export interface GetCommitteeSessionsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'sessionID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'codice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'stateID'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'stateName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'agendaID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSessionsResponseDto
     */
    'agendaStatus'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSintesiListRequest
 */
export interface GetCommitteeSintesiListRequest {
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCommitteeSintesiListRequest
     */
    'committeeIDList'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSintesiListRequest
     */
    'xmatrixID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSintesiListRequest
     */
    'dateFrom'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSintesiListRequest
     */
    'dateTo'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSintesiListRequest
     */
    'periodType'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof GetCommitteeSintesiListRequest
     */
    'statusList'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryPerStatusResponse
 */
export interface GetCommitteeSummaryPerStatusResponse {
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryPerStatusResponse
     */
    'activityNumber'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryPerStatusResponse
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSummaryPerStatusResponse
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryPerTimeResponse
 */
export interface GetCommitteeSummaryPerTimeResponse {
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryPerTimeResponse
     */
    'activityCount'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryPerTimeResponse
     */
    'activityStatus'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSummaryPerTimeResponse
     */
    'activityStatusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCommitteeSummaryResponseDto
 */
export interface GetCommitteeSummaryResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryResponseDto
     */
    'plannedCommitee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryResponseDto
     */
    'closedCommittee'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCommitteeSummaryResponseDto
     */
    'openActivities'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCommitteeSummaryResponseDto
     */
    'nextCommittee'?: string | null;
}
/**
 * 
 * @export
 * @interface GetCompanyListResponseDto
 */
export interface GetCompanyListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'companyID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'databaseName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetCompanyListResponseDto
     */
    'licenseType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetCompanyListResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'licenseTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'redirectLink'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetCompanyListResponseDto
     */
    'supportMail'?: string | null;
    /**
     * 
     * @type {Array<CompUsersListDto>}
     * @memberof GetCompanyListResponseDto
     */
    'companyCountUsers'?: Array<CompUsersListDto> | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableListPageResponseDto
 */
export interface GetDeliverableListPageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'deliverableID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableListPageResponseDto
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'ownerID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableListPageResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetDeliverableListPageResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetDeliverablePicklistResponseDto
 */
export interface GetDeliverablePicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDeliverablePicklistResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverablePicklistResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableProjectPicklistResponseDto
 */
export interface GetDeliverableProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableProjectPicklistResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableProjectPicklistResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableResponseDto
 */
export interface GetDeliverableResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'ownerID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'priorityDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {Array<GetChildDeliverableResponseDto>}
     * @memberof GetDeliverableResponseDto
     */
    'childs'?: Array<GetChildDeliverableResponseDto> | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'parentDeliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'parentDeliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetDeliverableResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableSummaryperStatusResponseDto
 */
export interface GetDeliverableSummaryperStatusResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableSummaryperStatusResponseDto
     */
    'deliverableNumber'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableSummaryperStatusResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableSummaryperStatusResponseDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDeliverableSummaryperTimeResponseDto
 */
export interface GetDeliverableSummaryperTimeResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableSummaryperTimeResponseDto
     */
    'deliverableStatus'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetDeliverableSummaryperTimeResponseDto
     */
    'deliverableCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetDeliverableSummaryperTimeResponseDto
     */
    'deliverableStatusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDivisionListPageResponseDto
 */
export interface GetDivisionListPageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDivisionListPageResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionListPageResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionListPageResponseDto
     */
    'image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionListPageResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionListPageResponseDto
     */
    'responsabileDivisione'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDivisionPicklistDto
 */
export interface GetDivisionPicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetDivisionPicklistDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionPicklistDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetDivisionPicklistDto
     */
    'hasAccess'?: number;
}
/**
 * 
 * @export
 * @interface GetDivisionProjectCreationPicklistResponseDto
 */
export interface GetDivisionProjectCreationPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDivisionProjectCreationPicklistResponseDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionProjectCreationPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionProjectCreationPicklistResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetDivisionResponseDto
 */
export interface GetDivisionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetDivisionResponseDto
     */
    'divisionID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionResponseDto
     */
    'image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetDivisionResponseDto
     */
    'responsabileDivisione'?: string | null;
}
/**
 * 
 * @export
 * @interface GetFeedActivityListResponseDto
 */
export interface GetFeedActivityListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'createdOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'activityType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'activityTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'documentURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'scheduledStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'scheduledEnd'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'totLikes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'isLikedByMe'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'initials'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedActivityListResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {Array<UsersObject>}
     * @memberof GetFeedActivityListResponseDto
     */
    'users'?: Array<UsersObject> | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'participationTypeMask'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityListResponseDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedActivityListResponseDto
     */
    'integrateInOutlook'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedActivityListResponseDto
     */
    'integrateInTeams'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityListResponseDto
     */
    'monthlyAlerts'?: string | null;
}
/**
 * 
 * @export
 * @interface GetFeedActivityResponseDto
 */
export interface GetFeedActivityResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'createdOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedActivityResponseDto
     */
    'activityType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'activityTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedActivityResponseDto
     */
    'initials'?: string | null;
}
/**
 * 
 * @export
 * @interface GetFeedMonthlyAlertByUserResponseDto
 */
export interface GetFeedMonthlyAlertByUserResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'monthlyAlertID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'userID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'createdOn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'isReadNotification'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'hasExpired'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedMonthlyAlertByUserResponseDto
     */
    'regardinObjectTypeCode'?: number | null;
}
/**
 * 
 * @export
 * @interface GetFeedSummaryResponseDto
 */
export interface GetFeedSummaryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedSummaryResponseDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'createdOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedSummaryResponseDto
     */
    'activityType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'activityTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'documentURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'scheduledStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'scheduledEnd'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedSummaryResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedSummaryResponseDto
     */
    'totLikes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFeedSummaryResponseDto
     */
    'isLikedByMe'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetFeedSummaryResponseDto
     */
    'initials'?: string | null;
}
/**
 * 
 * @export
 * @interface GetFilterbyXMatrixRequest
 */
export interface GetFilterbyXMatrixRequest {
    /**
     * 
     * @type {Array<string | null>}
     * @memberof GetFilterbyXMatrixRequest
     */
    'userID'?: Array<string | null> | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof GetFilterbyXMatrixRequest
     */
    'divisionID'?: Array<string | null> | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof GetFilterbyXMatrixRequest
     */
    'committeeID'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof GetFilterbyXMatrixRequest
     */
    'languageCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFilterbyXMatrixRequest
     */
    'userRole'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetFilterbyXMatrixRequest
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface GetFollowUpSectionA3ResponseDto
 */
export interface GetFollowUpSectionA3ResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'monthReference'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'ytd'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'calculationType'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetFollowUpSectionA3ResponseDto
     */
    'parentType'?: number;
}
/**
 * 
 * @export
 * @interface GetFullProfileDto
 */
export interface GetFullProfileDto {
    /**
     * 
     * @type {ResponseOfUserProfileResponseDtoValue}
     * @memberof GetFullProfileDto
     */
    'userProfile'?: ResponseOfUserProfileResponseDtoValue | null;
}
/**
 * 
 * @export
 * @interface GetGoalCategoryByIDResponseDto
 */
export interface GetGoalCategoryByIDResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryByIDResponseDto
     */
    'goalCategoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryByIDResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryByIDResponseDto
     */
    'goalCategoryIconID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryByIDResponseDto
     */
    'iconSource'?: string | null;
}
/**
 * 
 * @export
 * @interface GetGoalCategoryResponseDto
 */
export interface GetGoalCategoryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryResponseDto
     */
    'goalCategoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryResponseDto
     */
    'goalCategoryIconID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalCategoryResponseDto
     */
    'iconSource'?: string | null;
}
/**
 * 
 * @export
 * @interface GetGoalGroundtoActA3ResponseDto
 */
export interface GetGoalGroundtoActA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetGoalGroundtoActA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalGroundtoActA3ResponseDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalGroundtoActA3ResponseDto
     */
    'groundtoAct'?: string | null;
}
/**
 * 
 * @export
 * @interface GetGoalTypePicklistDto
 */
export interface GetGoalTypePicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetGoalTypePicklistDto
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetGoalTypePicklistDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GetImplementationSectionA3ResponseDto
 */
export interface GetImplementationSectionA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'timeSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'budgetSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetImplementationSectionA3ResponseDto
     */
    'timeSemaphoreDenominator'?: number | null;
}
/**
 * 
 * @export
 * @interface GetInsertKPIResponseDto
 */
export interface GetInsertKPIResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetInsertKPIResponseDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetInsertKPIResponseDto
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetInsertKPIResponseDto
     */
    'jumpOffPoint'?: number | null;
}
/**
 * 
 * @export
 * @interface GetKPIAnnualGoalPicklistResponseDto
 */
export interface GetKPIAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIAnnualGoalPicklistResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIAnnualGoalPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIAnnualGoalPicklistResponseDto
     */
    'globaleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIAnnualGoalPicklistResponseDto
     */
    'puntualeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIAnnualGoalPicklistResponseDto
     */
    'isFavourite'?: boolean;
}
/**
 * 
 * @export
 * @interface GetKPIByAnnualGoalResponseDto
 */
export interface GetKPIByAnnualGoalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'annualGoalKPIRelationShipID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'parentType'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'calculationType'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'calculationTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'kpiType'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'kpiTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'updateYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'parentKPIDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'toDisplay'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'isFavourite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'freezeTargetValues'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'firstYearOfPlanning'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'responsibleID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByAnnualGoalResponseDto
     */
    'responsibleName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIByLongTermGoalResponseDto
 */
export interface GetKPIByLongTermGoalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'correlationDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByLongTermGoalResponseDto
     */
    'isFavourite'?: boolean;
}
/**
 * 
 * @export
 * @interface GetKPIByProjectResponseDto
 */
export interface GetKPIByProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'updateYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'kpiProjectRelationShipID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'calculationTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'kpiType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'kpiTypeDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByProjectResponseDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByProjectResponseDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'parentKPIDetailId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'kpiDetailID'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'toDisplay'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByProjectResponseDto
     */
    'isFavourite'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIByProjectResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIByProjectResponseDto
     */
    'freezeTargetValues'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'semaforo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'responsibleID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIByProjectResponseDto
     */
    'responsibleName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIChildPicklistResponse
 */
export interface GetKPIChildPicklistResponse {
    /**
     * 
     * @type {string}
     * @memberof GetKPIChildPicklistResponse
     */
    'kpiDetailID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIChildPicklistResponse
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPICommentResponse
 */
export interface GetKPICommentResponse {
    /**
     * 
     * @type {string}
     * @memberof GetKPICommentResponse
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIDashboardSintesiByAnnualGoalResponseDto
 */
export interface GetKPIDashboardSintesiByAnnualGoalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'kpiDetailID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'kpiName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'measureUnitName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'finalTarget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'semaforo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'parentID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'toDisplay'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'kpiType'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'kpiTypeDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIDashboardSintesiByProjectResponseDto
 */
export interface GetKPIDashboardSintesiByProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'kpiDetailID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'kpiName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'measureUnitName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'finalTarget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'semaforo'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'parentID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'toDisplay'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'kpiType'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDashboardSintesiByProjectResponseDto
     */
    'kpiTypeDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIDetailDto
 */
export interface GetKPIDetailDto {
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'additionalNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'calculationTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'typeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'toBeDeleted'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIDetailDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIDetailDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIDetailDto
     */
    'freezeTargetValues'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIDetailDto
     */
    'firstYearOfPlanning'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'responsibleID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIDetailDto
     */
    'responsibleName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIDetailDto
     */
    'isVisible'?: boolean;
}
/**
 * 
 * @export
 * @interface GetKPIGerarchicoInfoDTO
 */
export interface GetKPIGerarchicoInfoDTO {
    /**
     * 
     * @type {string}
     * @memberof GetKPIGerarchicoInfoDTO
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGerarchicoInfoDTO
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGerarchicoInfoDTO
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIGraphicResponseDto
 */
export interface GetKPIGraphicResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIGraphicResponseDto
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGraphicResponseDto
     */
    'kpiDetailID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGraphicResponseDto
     */
    'kpiName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGraphicResponseDto
     */
    'measureUnitName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIGraphicResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIGraphicResponseDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIGraphicResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIGraphicResponseDto
     */
    'finalTarget'?: number | null;
}
/**
 * 
 * @export
 * @interface GetKPIListDto
 */
export interface GetKPIListDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIListDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListDto
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListDto
     */
    'calculationTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListDto
     */
    'typeDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIListDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIListDto
     */
    'isFavourite'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIListDto
     */
    'isVisible'?: boolean;
}
/**
 * 
 * @export
 * @interface GetKPIListPageDto
 */
export interface GetKPIListPageDto {
    /**
     * 
     * @type {number}
     * @memberof GetKPIListPageDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'additionalNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIListPageDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'parentKPIID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'parentKPIName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIListPageDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIListPageDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPIListPageDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'jumpOffPoint'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIListPageDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIPicklistA3ResponseDto
 */
export interface GetKPIPicklistA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIPicklistA3ResponseDto
     */
    'kpiDetailID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIPicklistA3ResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIPicklistA3ResponseDto
     */
    'isFavourite'?: boolean;
}
/**
 * 
 * @export
 * @interface GetKPIProjectPicklistResponseDto
 */
export interface GetKPIProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'kpiDetailID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'globaleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'puntualeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIProjectPicklistResponseDto
     */
    'isFavourite'?: boolean;
}
/**
 * 
 * @export
 * @interface GetKPISectionA3ResponseDto
 */
export interface GetKPISectionA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'updateYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'kpiProjectRelationShipID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'showInA3'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'parentKPIDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'toDisplay'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPISectionA3ResponseDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISectionA3ResponseDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISectionA3ResponseDto
     */
    'semaforo'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPISummaryResponseDto
 */
export interface GetKPISummaryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPISummaryResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISummaryResponseDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISummaryResponseDto
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISummaryResponseDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetKPISummaryResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPISummaryResponseDto
     */
    'kpiid'?: string | null;
}
/**
 * 
 * @export
 * @interface GetKPIXMatrixPicklistResponseDto
 */
export interface GetKPIXMatrixPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetKPIXMatrixPicklistResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIXMatrixPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIXMatrixPicklistResponseDto
     */
    'globaleType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetKPIXMatrixPicklistResponseDto
     */
    'puntualeType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetKPIXMatrixPicklistResponseDto
     */
    'isFavourite'?: boolean;
}
/**
 * 
 * @export
 * @interface GetKPIsDetailDto
 */
export interface GetKPIsDetailDto {
    /**
     * 
     * @type {Array<GetProjectByKPIResponseDto>}
     * @memberof GetKPIsDetailDto
     */
    'getProjectByKPI'?: Array<GetProjectByKPIResponseDto> | null;
    /**
     * 
     * @type {Array<GetLongTermGoalByKPIResponseDto>}
     * @memberof GetKPIsDetailDto
     */
    'getLongTermGoalByKPI'?: Array<GetLongTermGoalByKPIResponseDto> | null;
    /**
     * 
     * @type {ResponseOfGetKPIDetailDtoValue}
     * @memberof GetKPIsDetailDto
     */
    'getKPIDetail'?: ResponseOfGetKPIDetailDtoValue | null;
}
/**
 * 
 * @export
 * @interface GetLicenseTypesByLanguageCodeResponseDto
 */
export interface GetLicenseTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetLicenseTypesByLanguageCodeResponseDto
     */
    'licenseID'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetLicenseTypesByLanguageCodeResponseDto
     */
    'licenseType'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetLicenseTypesByLanguageCodeResponseDto
     */
    'licenseName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLicenseTypesByLanguageCodeResponseDto
     */
    'language_Code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLicenseTypesByLanguageCodeResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalByKPIResponseDto
 */
export interface GetLongTermGoalByKPIResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'longTermGoalDesription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalByKPIResponseDto
     */
    'correlationDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalChecklistResponseDto
 */
export interface GetLongTermGoalChecklistResponseDto {
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalChecklistResponseDto
     */
    'miglioramentoSignificativo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalChecklistResponseDto
     */
    'raggiungimentoVision'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalChecklistResponseDto
     */
    'strategiaSostenibile'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalChecklistResponseDto
     */
    'innovazioneBusiness'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalChecklistResponseDto
     */
    'vincoliNormative'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetLongTermGoalTypeByLanguageCodeResponseDto
 */
export interface GetLongTermGoalTypeByLanguageCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'longTermGoalTypeID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'longTermGoalID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'longTermGoal_Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'language_Code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetMacroProjectResponseDto
 */
export interface GetMacroProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMacroProjectResponseDto
     */
    'macroProjectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMacroProjectResponseDto
     */
    'macroProjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMacroProjectResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMacroProjectResponseDto
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface GetMasterPlanDeliverableResponseDto
 */
export interface GetMasterPlanDeliverableResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'deliverableTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'deliverableSubtitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'monthValue'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'deliverableStatus'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'projectStartDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'projectEndDate'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMasterPlanDeliverableResponseDto
     */
    'hasAccess'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetMasterplanParentDeliverableResponseDto
 */
export interface GetMasterplanParentDeliverableResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {{ [key: string]: any; }}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'months'?: { [key: string]: any; } | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'deliverableStatus'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMasterplanParentDeliverableResponseDto
     */
    'statusID'?: number | null;
}
/**
 * 
 * @export
 * @interface GetMasterplanParentDeliverableTestResponseDto
 */
export interface GetMasterplanParentDeliverableTestResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'deliverableID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'monthValue'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'ownerID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'statusID'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMasterplanParentDeliverableTestResponseDto
     */
    'deliverableMonthList'?: string | null;
}
/**
 * 
 * @export
 * @interface GetMembersListA3ResponseDto
 */
export interface GetMembersListA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMembersListA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMembersListA3ResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMembersListA3ResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMembersListA3ResponseDto
     */
    'onCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMembersListA3ResponseDto
     */
    'userInitials'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMembersListA3ResponseDto
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface GetMenuItemResponse
 */
export interface GetMenuItemResponse {
    /**
     * 
     * @type {string}
     * @memberof GetMenuItemResponse
     */
    'menuID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMenuItemResponse
     */
    'menuDisplayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMenuItemResponse
     */
    'menuLink'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMenuItemResponse
     */
    'orderingValueMenu'?: number | null;
    /**
     * 
     * @type {Array<ChildMenuItem>}
     * @memberof GetMenuItemResponse
     */
    'menuItems'?: Array<ChildMenuItem> | null;
}
/**
 * 
 * @export
 * @interface GetMonthlyAlertByUserResponseDto
 */
export interface GetMonthlyAlertByUserResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'monthlyAlertID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'userID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'createdOn'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'isReadNotification'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'hasExpired'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMonthlyAlertByUserResponseDto
     */
    'regardinObjectTypeCode'?: number | null;
}
/**
 * 
 * @export
 * @interface GetMyAcivityResponseDto
 */
export interface GetMyAcivityResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyAcivityResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyAcivityResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyAcivityResponseDto
     */
    'isGenerated'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetMyActivityDeliverableFormResponseDto
 */
export interface GetMyActivityDeliverableFormResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'isRelatedToProject'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'activityType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityDeliverableFormResponseDto
     */
    'parentDeliverableID'?: string | null;
}
/**
 * 
 * @export
 * @interface GetMyActivityMenuFormPerUserResponseDto
 */
export interface GetMyActivityMenuFormPerUserResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'isRelatedToProject'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'deliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'deliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'parentDeliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'parentDeliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormPerUserResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetMyActivityMenuFormResponseDto
 */
export interface GetMyActivityMenuFormResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'isRelatedToProject'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'deliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'deliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'parentDeliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'parentDeliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityMenuFormResponseDto
     */
    'activityType'?: string | null;
}
/**
 * 
 * @export
 * @interface GetMyActivityProjectFormrResponseDto
 */
export interface GetMyActivityProjectFormrResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'isRelatedToProject'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'deliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'deliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'parentDeliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'parentDeliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'activityType'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetMyActivityProjectFormrResponseDto
     */
    'hasAccess'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionSponsorPicklistResponseDto
 */
export interface GetNewDivisionSponsorPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionSponsorPicklistResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionSponsorPicklistResponseDto
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionTeamLeaderPicklistResponseDto
 */
export interface GetNewDivisionTeamLeaderPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionTeamLeaderPicklistResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionTeamLeaderPicklistResponseDto
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixAnnualGoalPicklistResponseDto
 */
export interface GetNewDivisionXMatrixAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixAnnualGoalPicklistResponseDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixAnnualGoalPicklistResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetNewDivisionXMatrixAnnualGoalPicklistResponseDto
     */
    'orderingValue'?: number;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixProjectPicklistResponseDto
 */
export interface GetNewDivisionXMatrixProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixProjectPicklistResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixProjectPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixProjectPicklistResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
 */
export interface GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNewXmatrixAnnualGoalPicklistResponseDto
 */
export interface GetNewXmatrixAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixAnnualGoalPicklistResponseDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixAnnualGoalPicklistResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetNewXmatrixAnnualGoalPicklistResponseDto
     */
    'orderingValue'?: number;
}
/**
 * 
 * @export
 * @interface GetNewXmatrixProjectPicklistResponseDto
 */
export interface GetNewXmatrixProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixProjectPicklistResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixProjectPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNewXmatrixProjectPicklistResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNextStepsSectionA3ResponseDto
 */
export interface GetNextStepsSectionA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNextStepsSectionA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNextStepsSectionA3ResponseDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNextStepsSectionA3ResponseDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNextStepsSectionA3ResponseDto
     */
    'nextStepsNote'?: string | null;
}
/**
 * 
 * @export
 * @interface GetNoCommitteeProjectsResponseDto
 */
export interface GetNoCommitteeProjectsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetNoCommitteeProjectsResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNoCommitteeProjectsResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNoCommitteeProjectsResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNoCommitteeProjectsResponseDto
     */
    'teamLeaderId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetNoCommitteeProjectsResponseDto
     */
    'teamLeaderName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetParentProjectPicklistDto
 */
export interface GetParentProjectPicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetParentProjectPicklistDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetParentProjectPicklistDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectByAnnualGoalDto
 */
export interface GetProjectByAnnualGoalDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByAnnualGoalDto
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectByAnnualGoalDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByAnnualGoalDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'groundtoAct'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'nextStepsNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByAnnualGoalDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByAnnualGoalDto
     */
    'correlationDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectByKPIResponseDto
 */
export interface GetProjectByKPIResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByKPIResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectByKPIResponseDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByKPIResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'groundtoAct'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'nextStepsNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByKPIResponseDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByKPIResponseDto
     */
    'correlationDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectByLongTermGoalDto
 */
export interface GetProjectByLongTermGoalDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByLongTermGoalDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectByLongTermGoalDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectByLongTermGoalDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'groundtoAct'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'nextStepsNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectByLongTermGoalDto
     */
    'projectID'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectChildsResponseDto
 */
export interface GetProjectChildsResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectChildsResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectChildsResponseDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectChildsResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectCodesResponseDto
 */
export interface GetProjectCodesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectCodesResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectCompletedActivitiesA3ResponseDto
 */
export interface GetProjectCompletedActivitiesA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'ownerFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'ownerFullNameInitials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'deliverableStatus'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectCompletedActivitiesA3ResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetProjectCostDetailResponseDto
 */
export interface GetProjectCostDetailResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectCostDetailResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCostDetailResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectCostDetailResponseDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCostDetailResponseDto
     */
    'typeDescription'?: string | null;
    /**
     * 
     * @type {Array<DeliverableListObject>}
     * @memberof GetProjectCostDetailResponseDto
     */
    'deliverables'?: Array<DeliverableListObject> | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCostDetailResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCostDetailResponseDto
     */
    'deliverableName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectCreationPicklistResponseDto
 */
export interface GetProjectCreationPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectCreationPicklistResponseDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCreationPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectCreationPicklistResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverableNextStepA3ResponseDto
 */
export interface GetProjectDeliverableNextStepA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'ownerFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'ownerFullNameInitials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'deliverableStatus'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectDeliverableNextStepA3ResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverablePicklistResponseDto
 */
export interface GetProjectDeliverablePicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverablePicklistResponseDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverablePicklistResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverableResponseDto
 */
export interface GetProjectDeliverableResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'deliverableID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectDeliverableResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectDeliverableResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectDeliverableResponseDto
     */
    'childrenCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'ownerFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectDeliverableResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectDeliverableResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectDeliverableResponseDto
     */
    'hasAccess'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetProjectDeliverableTeamResponseDto
 */
export interface GetProjectDeliverableTeamResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableTeamResponseDto
     */
    'userID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectDeliverableTeamResponseDto
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectFeedActivityListResponseDto
 */
export interface GetProjectFeedActivityListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'createdOn'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'activityType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'activityTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'documentURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'scheduledStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'scheduledEnd'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'totLikes'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'isLikedByMe'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectFeedActivityListResponseDto
     */
    'initials'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectHeaderA3ResponseDto
 */
export interface GetProjectHeaderA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectHeaderA3ResponseDto
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectListPageResponseDto
 */
export interface GetProjectListPageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectListPageResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'projectRepository'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'parentProjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'parentName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'teamMembersName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'teamMembersEmail'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'teamLeaderEmailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectListPageResponseDto
     */
    'sponsorEmailAddress'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectMembersListResponseDto
 */
export interface GetProjectMembersListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersListResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersListResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersListResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectMembersListResponseDto
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface GetProjectMembersResponseDto
 */
export interface GetProjectMembersResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectMembersResponseDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'initials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectMembersResponseDto
     */
    'roleCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectMembersResponseDto
     */
    'onCall'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectMembersResponseDto
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface GetProjectPicklistResponseDto
 */
export interface GetProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectPicklistResponseDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectPicklistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectPicklistResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectPicklistResponseDto
     */
    'isProjectRelatedXMatrix'?: number;
}
/**
 * 
 * @export
 * @interface GetProjectRepositoryList
 */
export interface GetProjectRepositoryList {
    /**
     * 
     * @type {string}
     * @memberof GetProjectRepositoryList
     */
    'projectRepositoryID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectRepositoryList
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetProjectRepositoryList
     */
    'link'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectRepositoryList
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GetProjectResponseDto
 */
export interface GetProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectResponseDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'groundtoAct'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'nextStepsNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'timeSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'budgetSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'timeSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'parentProjectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectResponseDto
     */
    'parentProjectEndDate'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectResponseDto
     */
    'secondaryProject'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetProjectResponseDto
     */
    'fastCreation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof GetProjectResponseDto
     */
    'masterplanDuration'?: number;
}
/**
 * 
 * @export
 * @interface GetProjectStatusPicklistDto
 */
export interface GetProjectStatusPicklistDto {
    /**
     * 
     * @type {number}
     * @memberof GetProjectStatusPicklistDto
     */
    'statusID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetProjectStatusPicklistDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetResourceWorkloadSummaryRequest
 */
export interface GetResourceWorkloadSummaryRequest {
    /**
     * 
     * @type {Array<string | null>}
     * @memberof GetResourceWorkloadSummaryRequest
     */
    'userID'?: Array<string | null> | null;
    /**
     * 
     * @type {Array<string | null>}
     * @memberof GetResourceWorkloadSummaryRequest
     */
    'divisionID'?: Array<string | null> | null;
    /**
     * 
     * @type {string}
     * @memberof GetResourceWorkloadSummaryRequest
     */
    'year'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetResourceWorkloadSummaryRequest
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface GetResourceWorkloadSummaryResponseDto
 */
export interface GetResourceWorkloadSummaryResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetResourceWorkloadSummaryResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetResourceWorkloadSummaryResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetResourceWorkloadSummaryResponseDto
     */
    'allocated'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetResourceWorkloadSummaryResponseDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetResourceWorkloadSummaryResponseDto
     */
    'consuntivo'?: number | null;
}
/**
 * 
 * @export
 * @interface GetRiskPrecautionA3ResponseDto
 */
export interface GetRiskPrecautionA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'riskID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'riskDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'severity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'riskSeverityDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'riskStatusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'riskComment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'precautionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionA3ResponseDto
     */
    'precautionDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetRiskPrecautionResponseDto
 */
export interface GetRiskPrecautionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'riskID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'riskDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetRiskPrecautionResponseDto
     */
    'severity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'riskSeverityDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetRiskPrecautionResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'riskStatusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'riskComment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'precautionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRiskPrecautionResponseDto
     */
    'precautionDescription'?: string | null;
    /**
     * 
     * @type {Array<CreatePrecaution>}
     * @memberof GetRiskPrecautionResponseDto
     */
    'precautions'?: Array<CreatePrecaution> | null;
}
/**
 * 
 * @export
 * @interface GetRolePicklistDto
 */
export interface GetRolePicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetRolePicklistDto
     */
    'roleID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRolePicklistDto
     */
    'roleDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetRollupKPIChildResponseDto
 */
export interface GetRollupKPIChildResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetRollupKPIChildResponseDto
     */
    'kpiDetailID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRollupKPIChildResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetRootXMatrixDivisionListResponseDto
 */
export interface GetRootXMatrixDivisionListResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'xmatrixID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'hkCode'?: string | null;
    /**
     * 
     * @type {Array<ChildList>}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'childList'?: Array<ChildList> | null;
    /**
     * 
     * @type {string}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'xMatrixYear'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'parentXMatrixYear'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetRootXMatrixDivisionListResponseDto
     */
    'parentXMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface GetSecondLevelMacroProjectsRelationshipResponseDto
 */
export interface GetSecondLevelMacroProjectsRelationshipResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetSecondLevelMacroProjectsRelationshipResponseDto
     */
    'secondLevelXmatrixID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetSecondLevelMacroProjectsRelationshipResponseDto
     */
    'macroProjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetSecondLevelMacroProjectsRelationshipResponseDto
     */
    'correlationCoefficient'?: number | null;
}
/**
 * 
 * @export
 * @interface GetSemaphorePicklistDto
 */
export interface GetSemaphorePicklistDto {
    /**
     * 
     * @type {number}
     * @memberof GetSemaphorePicklistDto
     */
    'objectCodeListID'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetSemaphorePicklistDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface GetSintesiComitatiResponse
 */
export interface GetSintesiComitatiResponse {
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'teamLeaderName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'projectSessions'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetSintesiComitatiResponse
     */
    'projectSessionCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'total'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSintesiComitatiResponse
     */
    'children'?: string | null;
}
/**
 * 
 * @export
 * @interface GetSponsorPicklistResponseDto
 */
export interface GetSponsorPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetSponsorPicklistResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSponsorPicklistResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetSponsorPicklistResponseDto
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface GetStatusTypeByLanguageCodeResponseDto
 */
export interface GetStatusTypeByLanguageCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetStatusTypeByLanguageCodeResponseDto
     */
    'statusTypeID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusTypeByLanguageCodeResponseDto
     */
    'statusID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetStatusTypeByLanguageCodeResponseDto
     */
    'status_Name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetStatusTypeByLanguageCodeResponseDto
     */
    'language_Code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetStatusTypeByLanguageCodeResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetSummaryFeedTypesByLanguageCodeResponseDto
 */
export interface GetSummaryFeedTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'summaryID'?: string;
    /**
     * 
     * @type {number}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'summaryFeedType'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'summaryFeedName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'language_Code'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderActivityMenuFormResponseDto
 */
export interface GetTeamLeaderActivityMenuFormResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'isRelatedToProject'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'actualCloseDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'deliverableName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'isGenerated'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'deliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'deliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'parentDeliverableStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'parentDeliverableEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderActivityMenuFormResponseDto
     */
    'activityType'?: string | null;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderPicklistDto
 */
export interface GetTeamLeaderPicklistDto {
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderPicklistDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderPicklistDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetTeamLeaderPicklistDto
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface GetTeamLeaderProjectResponseDto
 */
export interface GetTeamLeaderProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderProjectResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetTeamLeaderProjectResponseDto
     */
    'projectID'?: string | null;
}
/**
 * 
 * @export
 * @interface GetTotalActiveUsersByRoleResponseDto
 */
export interface GetTotalActiveUsersByRoleResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalSteeringUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalTeamLeaderUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalTeamMemberUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalCompanyAdminUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalExternalReviewerUsers'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalUsersWithoutTeamMemberEssential'?: number;
    /**
     * 
     * @type {number}
     * @memberof GetTotalActiveUsersByRoleResponseDto
     */
    'totalTeamMemberEssential'?: number;
}
/**
 * 
 * @export
 * @interface GetUserCommitteeDto
 */
export interface GetUserCommitteeDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserCommitteeDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserCommitteeDto
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserCommitteeDto
     */
    'repositoryLink'?: string | null;
}
/**
 * 
 * @export
 * @interface GetUserProjectCostAccessResponseDto
 */
export interface GetUserProjectCostAccessResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectCostAccessResponseDto
     */
    'hasAccess'?: number | null;
}
/**
 * 
 * @export
 * @interface GetUserProjectResponseDto
 */
export interface GetUserProjectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'timeSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'budgetSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'timeSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'userRole'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetUserProjectResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserProjectResponseDto
     */
    'projectRepository'?: string | null;
}
/**
 * 
 * @export
 * @interface GetUserSelectResponseDto
 */
export interface GetUserSelectResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'roleID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'roleDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetUserSelectResponseDto
     */
    'roleCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof GetUserSelectResponseDto
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface GetXMatrixCycleByIDResponseDto
 */
export interface GetXMatrixCycleByIDResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleByIDResponseDto
     */
    'xMatrixCycleID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleByIDResponseDto
     */
    'companyID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetXMatrixCycleByIDResponseDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleByIDResponseDto
     */
    'cycleName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixCycleResponseDto
 */
export interface GetXMatrixCycleResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleResponseDto
     */
    'xMatrixCycleID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleResponseDto
     */
    'companyID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof GetXMatrixCycleResponseDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixCycleResponseDto
     */
    'cycleName'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixInfoResponseDto
 */
export interface GetXMatrixInfoResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'hkCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'parentXMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixInfoResponseDto
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'image'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'xMatrixCycleID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'xMatrixCycleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixInfoResponseDto
     */
    'referencePeriod'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixLongTermGoalPicklistResponseDto
 */
export interface GetXMatrixLongTermGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixLongTermGoalPicklistResponseDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixLongTermGoalPicklistResponseDto
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixPicklistResponseDto
 */
export interface GetXMatrixPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'xMatrixID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'hkCode'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'lastInsertedYear'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'parentXMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixPicklistResponseDto
     */
    'referencePeriod'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixSummaryResponseDto
 */
export interface GetXMatrixSummaryResponseDto {
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixSummaryResponseDto
     */
    'totalProjectMembers'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixSummaryResponseDto
     */
    'totalDays'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixSummaryResponseDto
     */
    'totalActiveProject'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixSummaryResponseDto
     */
    'totalActiveLongTermGoal'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixSummaryResponseDto
     */
    'totalActiveAnnualGoal'?: number | null;
}
/**
 * 
 * @export
 * @interface GetXMatrixUsersResponseDto
 */
export interface GetXMatrixUsersResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixUsersResponseDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixUsersResponseDto
     */
    'userID'?: string;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixUsersResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GetXMatrixUsersResponseDto
     */
    'roleCode'?: number;
    /**
     * 
     * @type {string}
     * @memberof GetXMatrixUsersResponseDto
     */
    'roleDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXmatrixDivisionParentProjectPicklistResponseDto
 */
export interface GetXmatrixDivisionParentProjectPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXmatrixDivisionParentProjectPicklistResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXmatrixDivisionParentProjectPicklistResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface GetXmatrixDivisionPicklistResponseDto
 */
export interface GetXmatrixDivisionPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GetXmatrixDivisionPicklistResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GetXmatrixDivisionPicklistResponseDto
     */
    'divisionName'?: string | null;
}
/**
 * 
 * @export
 * @interface GoalByProject
 */
export interface GoalByProject {
    /**
     * 
     * @type {Array<ProjectAnnualGoal>}
     * @memberof GoalByProject
     */
    'annualGoal'?: Array<ProjectAnnualGoal> | null;
    /**
     * 
     * @type {Array<ProjectLongTermGoal>}
     * @memberof GoalByProject
     */
    'longTermGoal'?: Array<ProjectLongTermGoal> | null;
}
/**
 * 
 * @export
 * @interface GoalCategoryIconlistResponseDto
 */
export interface GoalCategoryIconlistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof GoalCategoryIconlistResponseDto
     */
    'goalCategoryIconID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalCategoryIconlistResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalCategoryIconlistResponseDto
     */
    'iconSource'?: string | null;
}
/**
 * 
 * @export
 * @interface GoalKPIRelationship
 */
export interface GoalKPIRelationship {
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalKPIRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalKPIRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalKPIRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalKPIRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalKPIRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GoalProjectRelationship
 */
export interface GoalProjectRelationship {
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface GoalProjectRelationships
 */
export interface GoalProjectRelationships {
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationships
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationships
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationships
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof GoalProjectRelationships
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof GoalProjectRelationships
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface InserXMatrixDivisionRequestDto
 */
export interface InserXMatrixDivisionRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'longtermGoalID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'annualGoalID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'projectID'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'year'?: string;
    /**
     * 
     * @type {string}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'parentXMatrixID'?: string;
    /**
     * 
     * @type {string}
     * @memberof InserXMatrixDivisionRequestDto
     */
    'divisionID'?: string;
}
/**
 * 
 * @export
 * @interface InsertActivityPlanningImportRequestDto
 */
export interface InsertActivityPlanningImportRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertActivityPlanningImportRequestDto
     */
    'userID'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsertActivityPlanningImportRequestDto
     */
    'monthlyTotal'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertActivityPlanningImportRequestDto
     */
    'referencePeriod'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsertActivityPlanningImportRequestDto
     */
    'annualBudget'?: number;
}
/**
 * 
 * @export
 * @interface InsertAlertConfigurationRequestDto
 */
export interface InsertAlertConfigurationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'companyID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'allInOne'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'reminderInDaysFrequence'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'sendViaMail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'sendViaNotificationBell'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof InsertAlertConfigurationRequestDto
     */
    'periodo'?: number | null;
}
/**
 * 
 * @export
 * @interface InsertAlertConfigurationResponseDto
 */
export interface InsertAlertConfigurationResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertAlertConfigurationResponseDto
     */
    'alertID'?: string;
}
/**
 * 
 * @export
 * @interface InsertAnnualGoalRequestDto
 */
export interface InsertAnnualGoalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertAnnualGoalRequestDto
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalRequestDto
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalRequestDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalRequestDto
     */
    'notes'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertAnnualGoalXRequestDto
 */
export interface InsertAnnualGoalXRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalXRequestDto
     */
    'descripton'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertAnnualGoalXRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertAnnualGoalXRequestDto
     */
    'xMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface InsertCommitteeRequestDto
 */
export interface InsertCommitteeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertCommitteeRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertCommitteeRequestDto
     */
    'users'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertCommitteeRequestDto
     */
    'revisoriMembers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InsertCommitteeRequestDto
     */
    'repositoryLink'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertCommitteeRequestDto
     */
    'activitiesDuration'?: number;
}
/**
 * 
 * @export
 * @interface InsertDeliverableRequestDto
 */
export interface InsertDeliverableRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertDeliverableRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertDeliverableRequestDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertDeliverableRequestDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertDeliverableRequestDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertDeliverableRequestDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertDeliverableRequestDto
     */
    'updateParentEndDate'?: boolean | null;
}
/**
 * 
 * @export
 * @interface InsertFeedActivityLikeRequestDto
 */
export interface InsertFeedActivityLikeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertFeedActivityLikeRequestDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedActivityLikeRequestDto
     */
    'userID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertFeedCommentRequestDto
 */
export interface InsertFeedCommentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertFeedCommentRequestDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertFeedCommentRequestDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedCommentRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedCommentRequestDto
     */
    'createdBy'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedCommentRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedCommentRequestDto
     */
    'parentActivityID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertFeedCommentRequestDto
     */
    'priority'?: boolean | null;
}
/**
 * 
 * @export
 * @interface InsertFeedFileRequestDto
 */
export interface InsertFeedFileRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertFeedFileRequestDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'parentActivityID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertFeedFileRequestDto
     */
    'priority'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedFileRequestDto
     */
    'documentURL'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertFeedMeetingRequestDto
 */
export interface InsertFeedMeetingRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertFeedMeetingRequestDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'parentActivityID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertFeedMeetingRequestDto
     */
    'priority'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'scheduledStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'scheduledEnd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {Array<UsersObject>}
     * @memberof InsertFeedMeetingRequestDto
     */
    'users'?: Array<UsersObject> | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertFeedMeetingRequestDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertFeedMeetingRequestDto
     */
    'integrateInOutlook'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertFeedMeetingRequestDto
     */
    'integrateInTeams'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedMeetingRequestDto
     */
    'timezone'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertFeedNotificationRequestDto
 */
export interface InsertFeedNotificationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertFeedNotificationRequestDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertFeedNotificationRequestDto
     */
    'regardingObjectTypeCode'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedNotificationRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedNotificationRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertFeedNotificationRequestDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertGoalCategoryRequestDto
 */
export interface InsertGoalCategoryRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertGoalCategoryRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertGoalCategoryRequestDto
     */
    'goalCategoryIconID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertKPIDetailRequestDto
 */
export interface InsertKPIDetailRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertKPIDetailRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertKPIDetailRequestDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertKPIDetailRequestDto
     */
    'additionalNote'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIDetailRequestDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIDetailRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIDetailRequestDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIDetailRequestDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIDetailRequestDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertKPIDetailRequestDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertKPIDetailRequestDto
     */
    'isVisible'?: boolean | null;
}
/**
 * 
 * @export
 * @interface InsertKPIDetailResponse
 */
export interface InsertKPIDetailResponse {
    /**
     * 
     * @type {string}
     * @memberof InsertKPIDetailResponse
     */
    'kpiDetailID'?: string;
}
/**
 * 
 * @export
 * @interface InsertKPIProjectRequestDto
 */
export interface InsertKPIProjectRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertKPIProjectRequestDto
     */
    'kpiDetailID'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertKPIProjectRequestDto
     */
    'projectID'?: string;
}
/**
 * 
 * @export
 * @interface InsertKPIXMatrixRequestDto
 */
export interface InsertKPIXMatrixRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'xMatrixID'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertKPIXMatrixRequestDto
     */
    'parentType'?: number | null;
}
/**
 * 
 * @export
 * @interface InsertLongTermGoalRequestDto
 */
export interface InsertLongTermGoalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalRequestDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalRequestDto
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertLongTermGoalRequestDto
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof InsertLongTermGoalRequestDto
     */
    'status'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertLongTermGoalRequestDto
     */
    'goalCategory'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InsertLongTermGoalResponseDto
 */
export interface InsertLongTermGoalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalResponseDto
     */
    'longTermGoalID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertLongTermGoalXRequestDto
 */
export interface InsertLongTermGoalXRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalXRequestDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertLongTermGoalXRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertLongTermGoalXRequestDto
     */
    'xMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface InsertMacroProjectRequest
 */
export interface InsertMacroProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof InsertMacroProjectRequest
     */
    'macroProjectCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertMacroProjectRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertMacroProjectRequest
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface InsertMeetingParticipantRequestDto
 */
export interface InsertMeetingParticipantRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertMeetingParticipantRequestDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMeetingParticipantRequestDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertMeetingParticipantRequestDto
     */
    'participiantTypeMask'?: number | null;
}
/**
 * 
 * @export
 * @interface InsertMyActivityRequestDto
 */
export interface InsertMyActivityRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertMyActivityRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityRequestDto
     */
    'committeeID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertMyActivityResponseDto
 */
export interface InsertMyActivityResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertMyActivityResponseDto
     */
    'activityID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertNextYearXMatrixRequestDto
 */
export interface InsertNextYearXMatrixRequestDto {
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertNextYearXMatrixRequestDto
     */
    'annualGoalID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertNextYearXMatrixRequestDto
     */
    'projectIDs'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof InsertNextYearXMatrixRequestDto
     */
    'year'?: string;
}
/**
 * 
 * @export
 * @interface InsertNextYearXMatrixResponseDto
 */
export interface InsertNextYearXMatrixResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertNextYearXMatrixResponseDto
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectCostDetailRequestDto
 */
export interface InsertProjectCostDetailRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectCostDetailRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectCostDetailRequestDto
     */
    'ownerID'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectCostDetailRequestDto
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectCostDetailRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertProjectCostDetailRequestDto
     */
    'deliverables'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InsertProjectCostDetailResponseDto
 */
export interface InsertProjectCostDetailResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectCostDetailResponseDto
     */
    'projectCostDetailID'?: string;
}
/**
 * 
 * @export
 * @interface InsertProjectDeliverableRequestDto
 */
export interface InsertProjectDeliverableRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'budget'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'priority'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectDeliverableRequestDto
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectMemberRequestDto
 */
export interface InsertProjectMemberRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectMemberRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectMemberRequestDto
     */
    'userID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectMemberResponseDto
 */
export interface InsertProjectMemberResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectMemberResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectRepositoryRequestDto
 */
export interface InsertProjectRepositoryRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRepositoryRequestDto
     */
    'projectRepositoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRepositoryRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRepositoryRequestDto
     */
    'link'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRepositoryRequestDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectRoutineMeetingRequestDto
 */
export interface InsertProjectRoutineMeetingRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineMeetingRequestDto
     */
    'projectRoutineID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineMeetingRequestDto
     */
    'regardingObjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineMeetingRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineMeetingRequestDto
     */
    'createdBy'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineMeetingRequestDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectRoutineRequestDto
 */
export interface InsertProjectRoutineRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'schedule'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'who'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectRoutineRequestDto
     */
    'integrateInOutlook'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectRoutineRequestDto
     */
    'integrateInTeams'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'frequenceType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'frequence'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectRoutineRequestDto
     */
    'dayOfWeek'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineRequestDto
     */
    'timezone'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertProjectRoutineRequestDto
     */
    'extraUsersRoutine'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InsertProjectRoutineResponseDto
 */
export interface InsertProjectRoutineResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineResponseDto
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineResponseDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectRoutineResponseDto
     */
    'projectDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertProjectXMatrixRequestDto
 */
export interface InsertProjectXMatrixRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'xMatrixID'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'secondaryProject'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof InsertProjectXMatrixRequestDto
     */
    'fastCreation'?: boolean;
}
/**
 * 
 * @export
 * @interface InsertProjectXMatrixResponse
 */
export interface InsertProjectXMatrixResponse {
    /**
     * 
     * @type {string}
     * @memberof InsertProjectXMatrixResponse
     */
    'projectID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertRiskRequestDto
 */
export interface InsertRiskRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertRiskRequestDto
     */
    'riskDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertRiskRequestDto
     */
    'riskComment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertRiskRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof InsertRiskRequestDto
     */
    'severity'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof InsertRiskRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertRiskRequestDto
     */
    'precautions'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InsertSecondLevelMacroProjectsRelationshipRequestDto
 */
export interface InsertSecondLevelMacroProjectsRelationshipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertSecondLevelMacroProjectsRelationshipRequestDto
     */
    'secondLevelXmatrixID'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertSecondLevelMacroProjectsRelationshipRequestDto
     */
    'macroProjectID'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsertSecondLevelMacroProjectsRelationshipRequestDto
     */
    'correlationCoefficient'?: number;
}
/**
 * 
 * @export
 * @interface InsertSystemUserRequestDto
 */
export interface InsertSystemUserRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'userID'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'committeeMembersID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertSystemUserRequestDto
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'surname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertSystemUserRequestDto
     */
    'availability'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertSystemUserRequestDto
     */
    'dailyRate'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertSystemUserRequestDto
     */
    'roleId'?: string;
}
/**
 * 
 * @export
 * @interface InsertUpdateAgendaRequestDto
 */
export interface InsertUpdateAgendaRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'agendaID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'scope'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'duration'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'frequency'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'stateID'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'committeeID'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'sessionID'?: string | null;
    /**
     * 
     * @type {Array<AgendaItems>}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'agendaItemsType'?: Array<AgendaItems> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof InsertUpdateAgendaRequestDto
     */
    'projects'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface InsertUpdateCommitteeSessionRequestDto
 */
export interface InsertUpdateCommitteeSessionRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'committeeID'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'sessionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'codice'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'date'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'startTime'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'endTime'?: string;
    /**
     * 
     * @type {number}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'duration'?: number;
    /**
     * 
     * @type {number}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'state'?: number;
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionRequestDto
     */
    'xMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface InsertUpdateCommitteeSessionResponseDto
 */
export interface InsertUpdateCommitteeSessionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertUpdateCommitteeSessionResponseDto
     */
    'sessionID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertUserCommitteeRequestDto
 */
export interface InsertUserCommitteeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertUserCommitteeRequestDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof InsertUserCommitteeRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof InsertUserCommitteeRequestDto
     */
    'isRevisori'?: boolean;
}
/**
 * 
 * @export
 * @interface InsertUserFavouriteKPIRequest
 */
export interface InsertUserFavouriteKPIRequest {
    /**
     * 
     * @type {string}
     * @memberof InsertUserFavouriteKPIRequest
     */
    'kpiDetailID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertXMatrixDivisionResponseDto
 */
export interface InsertXMatrixDivisionResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertXMatrixDivisionResponseDto
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertXMatrixRequestDto
 */
export interface InsertXMatrixRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertXMatrixRequestDto
     */
    'year'?: string;
    /**
     * 
     * @type {string}
     * @memberof InsertXMatrixRequestDto
     */
    'xMatrixName'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertXMatrixResponseDto
 */
export interface InsertXMatrixResponseDto {
    /**
     * 
     * @type {string}
     * @memberof InsertXMatrixResponseDto
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface InsertXmatrixCycleRequestDto
 */
export interface InsertXmatrixCycleRequestDto {
    /**
     * 
     * @type {string}
     * @memberof InsertXmatrixCycleRequestDto
     */
    'companyID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof InsertXmatrixCycleRequestDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof InsertXmatrixCycleRequestDto
     */
    'cycleName'?: string | null;
}
/**
 * 
 * @export
 * @interface KPI
 */
export interface KPI {
    /**
     * 
     * @type {string}
     * @memberof KPI
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPI
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPI
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPI
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPI
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPI
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof KPI
     */
    'isFavourite'?: boolean | null;
}
/**
 * 
 * @export
 * @interface KPIBowlingChartResponse
 */
export interface KPIBowlingChartResponse {
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'kpiDetailName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIBowlingChartResponse
     */
    'type'?: number;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'typeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIBowlingChartResponse
     */
    'parentType'?: number;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'parentTypeDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIBowlingChartResponse
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'kpiProjectRelationshipID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof KPIBowlingChartResponse
     */
    'isRollupKPI'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'rollupKpiName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'parentKPIProjectRelationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIBowlingChartResponse
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {{ [key: string]: number | null; }}
     * @memberof KPIBowlingChartResponse
     */
    'monthsList'?: { [key: string]: number | null; } | null;
}
/**
 * 
 * @export
 * @interface KPIDetailsDto
 */
export interface KPIDetailsDto {
    /**
     * 
     * @type {string}
     * @memberof KPIDetailsDto
     */
    'longTermGoalID'?: string;
}
/**
 * 
 * @export
 * @interface KPIDetailsGraficoA3ResponseDto
 */
export interface KPIDetailsGraficoA3ResponseDto {
    /**
     * 
     * @type {string}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'kpiProjectRelationShipID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'monthReference'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'targetYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'ytd'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsGraficoA3ResponseDto
     */
    'parentType'?: number | null;
}
/**
 * 
 * @export
 * @interface KPIDetailsPastYearsResponseDto
 */
export interface KPIDetailsPastYearsResponseDto {
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsPastYearsResponseDto
     */
    'year'?: number;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsPastYearsResponseDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIDetailsPastYearsResponseDto
     */
    'target'?: number | null;
}
/**
 * 
 * @export
 * @interface KPIPicklistResponseDto
 */
export interface KPIPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof KPIPicklistResponseDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIPicklistResponseDto
     */
    'name'?: string | null;
}
/**
 * 
 * @export
 * @interface KPIProjectRelationship
 */
export interface KPIProjectRelationship {
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface KPIProjectRelationships
 */
export interface KPIProjectRelationships {
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationships
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationships
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationships
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIProjectRelationships
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIProjectRelationships
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface KPIs
 */
export interface KPIs {
    /**
     * 
     * @type {string}
     * @memberof KPIs
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIs
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIs
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof KPIs
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof KPIs
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'target'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof KPIs
     */
    'targetYTD'?: number | null;
}
/**
 * 
 * @export
 * @interface LongTermAnnualGoalRelationship
 */
export interface LongTermAnnualGoalRelationship {
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermAnnualGoalRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermAnnualGoalRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermAnnualGoalRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermAnnualGoalRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermAnnualGoalRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermGoal
 */
export interface LongTermGoal {
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoal
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoal
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoal
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'sponsor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LongTermGoal
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoal
     */
    'longTermGoalType'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermGoalByAnnualGoalResponseDto
 */
export interface LongTermGoalByAnnualGoalResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'goalTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalByAnnualGoalResponseDto
     */
    'correlationDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermGoalData
 */
export interface LongTermGoalData {
    /**
     * 
     * @type {Array<LongTermGoalResponse>}
     * @memberof LongTermGoalData
     */
    'longTermGoalNotRelated'?: Array<LongTermGoalResponse> | null;
    /**
     * 
     * @type {Array<LongTermGoalResponse>}
     * @memberof LongTermGoalData
     */
    'longTermGoalRelated'?: Array<LongTermGoalResponse> | null;
}
/**
 * 
 * @export
 * @interface LongTermGoalPicklistResponseDto
 */
export interface LongTermGoalPicklistResponseDto {
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalPicklistResponseDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalPicklistResponseDto
     */
    'title'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermGoalResponse
 */
export interface LongTermGoalResponse {
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalResponse
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoalResponse
     */
    'longTermGoalName'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermGoals
 */
export interface LongTermGoals {
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoals
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoals
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermGoals
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'category'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'sponsor'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof LongTermGoals
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermGoals
     */
    'longTermGoalType'?: string | null;
}
/**
 * 
 * @export
 * @interface LongTermObjectiveByIdResponse
 */
export interface LongTermObjectiveByIdResponse {
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'goalTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermObjectiveByIdResponse
     */
    'status'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LongTermObjectiveByIdResponse
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'iconSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'goalCategoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'categoryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveByIdResponse
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {Array<GetAnnualGoalByLongTermGoalDto>}
     * @memberof LongTermObjectiveByIdResponse
     */
    'annualGoalList'?: Array<GetAnnualGoalByLongTermGoalDto> | null;
    /**
     * 
     * @type {Array<GetProjectByLongTermGoalDto>}
     * @memberof LongTermObjectiveByIdResponse
     */
    'projectList'?: Array<GetProjectByLongTermGoalDto> | null;
    /**
     * 
     * @type {Array<GetKPIByLongTermGoalResponseDto>}
     * @memberof LongTermObjectiveByIdResponse
     */
    'kpiList'?: Array<GetKPIByLongTermGoalResponseDto> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof LongTermObjectiveByIdResponse
     */
    'goalCategories'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface LongTermObjectiveDto
 */
export interface LongTermObjectiveDto {
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'goalTypeDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof LongTermObjectiveDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof LongTermObjectiveDto
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'iconSource'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'goalCategoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'categoryName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof LongTermObjectiveDto
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {Array<GetAnnualGoalByLongTermGoalDto>}
     * @memberof LongTermObjectiveDto
     */
    'annualGoalList'?: Array<GetAnnualGoalByLongTermGoalDto> | null;
    /**
     * 
     * @type {Array<GetProjectByLongTermGoalDto>}
     * @memberof LongTermObjectiveDto
     */
    'projectList'?: Array<GetProjectByLongTermGoalDto> | null;
    /**
     * 
     * @type {Array<GetKPIByLongTermGoalResponseDto>}
     * @memberof LongTermObjectiveDto
     */
    'kpiList'?: Array<GetKPIByLongTermGoalResponseDto> | null;
    /**
     * 
     * @type {Array<GetGoalCategoryResponseDto>}
     * @memberof LongTermObjectiveDto
     */
    'goalCategories'?: Array<GetGoalCategoryResponseDto> | null;
}
/**
 * 
 * @export
 * @interface Month
 */
export interface Month {
    /**
     * 
     * @type {string}
     * @memberof Month
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Month
     */
    'value'?: number | null;
}
/**
 * 
 * @export
 * @interface MonthBudget
 */
export interface MonthBudget {
    /**
     * 
     * @type {string}
     * @memberof MonthBudget
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonthBudget
     */
    'value'?: number | null;
}
/**
 * 
 * @export
 * @interface MonthsList
 */
export interface MonthsList {
    /**
     * 
     * @type {string}
     * @memberof MonthsList
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof MonthsList
     */
    'budget'?: number | null;
}
/**
 * 
 * @export
 * @interface MonthsListObj
 */
export interface MonthsListObj {
    /**
     * 
     * @type {string}
     * @memberof MonthsListObj
     */
    'referencePeriod'?: string;
    /**
     * 
     * @type {number}
     * @memberof MonthsListObj
     */
    'valueBudget'?: number | null;
}
/**
 * 
 * @export
 * @interface ObjectCodePicklistResponseDto
 */
export interface ObjectCodePicklistResponseDto {
    /**
     * 
     * @type {number}
     * @memberof ObjectCodePicklistResponseDto
     */
    'objectCodeListID'?: number;
    /**
     * 
     * @type {string}
     * @memberof ObjectCodePicklistResponseDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface Project
 */
export interface Project {
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Project
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Project
     */
    'fastCreation'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Project
     */
    'parentProject'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectAnnualGoal
 */
export interface ProjectAnnualGoal {
    /**
     * 
     * @type {string}
     * @memberof ProjectAnnualGoal
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectAnnualGoal
     */
    'annualGoalDesription'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectChild
 */
export interface ProjectChild {
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChild
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChild
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChild
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectChild
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'subProjectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'subProjectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChild
     */
    'subProjectParentProject'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectChildRelationship
 */
export interface ProjectChildRelationship {
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChildRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChildRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChildRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectChildRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectChildRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectDashboards
 */
export interface ProjectDashboards {
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'timeSemaphore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'budgetSemaphore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'qualitySemaphore'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDashboards
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {Array<User>}
     * @memberof ProjectDashboards
     */
    'users'?: Array<User> | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDashboards
     */
    'timeSemaphoreDenominator'?: number | null;
}
/**
 * 
 * @export
 * @interface ProjectData
 */
export interface ProjectData {
    /**
     * 
     * @type {Array<ProjectResponse>}
     * @memberof ProjectData
     */
    'projectNotRelated'?: Array<ProjectResponse> | null;
    /**
     * 
     * @type {Array<ProjectResponse>}
     * @memberof ProjectData
     */
    'projectRelated'?: Array<ProjectResponse> | null;
}
/**
 * 
 * @export
 * @interface ProjectDeliverableParent
 */
export interface ProjectDeliverableParent {
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'deliverableID'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDeliverableParent
     */
    'budget'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDeliverableParent
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDeliverableParent
     */
    'childrenCount'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'ownerFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectDeliverableParent
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectDeliverableParent
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDeliverableParent
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectDeliverableParent
     */
    'hasAccess'?: boolean | null;
    /**
     * 
     * @type {Array<GetProjectDeliverableResponseDto>}
     * @memberof ProjectDeliverableParent
     */
    'children'?: Array<GetProjectDeliverableResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ProjectLongTermGoal
 */
export interface ProjectLongTermGoal {
    /**
     * 
     * @type {string}
     * @memberof ProjectLongTermGoal
     */
    'longTermGoalDesription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectLongTermGoal
     */
    'longTermGoalID'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectResponse
 */
export interface ProjectResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'projectName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectResponse
     */
    'projectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectRoutineResponseDto
 */
export interface ProjectRoutineResponseDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'projectRoutineID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'who'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'whoDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'schedule'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'scheduleDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRoutineResponseDto
     */
    'integrateInOutlook'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ProjectRoutineResponseDto
     */
    'integrateInTeams'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'frequenceType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'frequence'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectRoutineResponseDto
     */
    'dayOfWeek'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectRoutineResponseDto
     */
    'extraUsers'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectSummary
 */
export interface ProjectSummary {
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'timeSemaphore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'budgetSemaphore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'qualitySemaphore'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {Array<UserProject>}
     * @memberof ProjectSummary
     */
    'users'?: Array<UserProject> | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummary
     */
    'timeSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'projectMonthList'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummary
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {Array<ProjectSummaryDto>}
     * @memberof ProjectSummary
     */
    'children'?: Array<ProjectSummaryDto> | null;
}
/**
 * 
 * @export
 * @interface ProjectSummaryDto
 */
export interface ProjectSummaryDto {
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderJobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderDepartment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderEmailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderProfilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderInitials'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'teamLeaderOnCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorJobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorDepartment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorEmailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorProfilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'sponsorInitials'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'sponsorOnCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'roleTeamLeader'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'roleSponsor'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'company'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'timeSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'budgetSemaphore'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'qualitySemaphore'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'lastUpdated'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryDto
     */
    'timeSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryDto
     */
    'projectMonthList'?: string | null;
}
/**
 * 
 * @export
 * @interface ProjectSummaryResponse
 */
export interface ProjectSummaryResponse {
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryResponse
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryResponse
     */
    'timeSemaphore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryResponse
     */
    'budgetSemaphore'?: number;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryResponse
     */
    'qualitySemaphore'?: number;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'lastUpdated'?: string;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'parentProjectName'?: string | null;
    /**
     * 
     * @type {Array<UserProject>}
     * @memberof ProjectSummaryResponse
     */
    'users'?: Array<UserProject> | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryResponse
     */
    'budgetSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryResponse
     */
    'budgetSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryResponse
     */
    'qualitySemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryResponse
     */
    'qualitySemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryResponse
     */
    'timeSemaphoreNumerator'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ProjectSummaryResponse
     */
    'timeSemaphoreDenominator'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'projectMonthList'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ProjectSummaryResponse
     */
    'teamLeaderFullName'?: string | null;
}
/**
 * 
 * @export
 * @interface Projects
 */
export interface Projects {
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Projects
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Projects
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Projects
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'teamLeaderFullName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof Projects
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'committeeName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'projectStartDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'projectEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Projects
     */
    'parentProject'?: string | null;
}
/**
 * 
 * @export
 * @interface RelationshipsProp
 */
export interface RelationshipsProp {
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProp
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationshipsProp
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationshipsProp
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProp
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProp
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProp
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProp
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProp
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationshipsProp
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationshipsProp
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProp
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProp
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface RelationshipsProps
 */
export interface RelationshipsProps {
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProps
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationshipsProps
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationshipsProps
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProps
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProps
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProps
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProps
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProps
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof RelationshipsProps
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof RelationshipsProps
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProps
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof RelationshipsProps
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface ResponseOfAlertConfigurationResponseDto
 */
export interface ResponseOfAlertConfigurationResponseDto {
    /**
     * 
     * @type {ResponseOfAlertConfigurationResponseDtoValue}
     * @memberof ResponseOfAlertConfigurationResponseDto
     */
    'value'?: ResponseOfAlertConfigurationResponseDtoValue | null;
}
/**
 * @type ResponseOfAlertConfigurationResponseDtoValue
 * @export
 */
export type ResponseOfAlertConfigurationResponseDtoValue = AlertConfigurationResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfBoolean
 */
export interface ResponseOfBoolean {
    /**
     * 
     * @type {boolean}
     * @memberof ResponseOfBoolean
     */
    'value'?: boolean;
}
/**
 * 
 * @export
 * @interface ResponseOfCheckKpiTypePlanningStartDateResponseDto
 */
export interface ResponseOfCheckKpiTypePlanningStartDateResponseDto {
    /**
     * 
     * @type {ResponseOfCheckKpiTypePlanningStartDateResponseDtoValue}
     * @memberof ResponseOfCheckKpiTypePlanningStartDateResponseDto
     */
    'value'?: ResponseOfCheckKpiTypePlanningStartDateResponseDtoValue | null;
}
/**
 * @type ResponseOfCheckKpiTypePlanningStartDateResponseDtoValue
 * @export
 */
export type ResponseOfCheckKpiTypePlanningStartDateResponseDtoValue = CheckKpiTypePlanningStartDateResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfCheckObjectSecurityUserResponse
 */
export interface ResponseOfCheckObjectSecurityUserResponse {
    /**
     * 
     * @type {ResponseOfCheckObjectSecurityUserResponseValue}
     * @memberof ResponseOfCheckObjectSecurityUserResponse
     */
    'value'?: ResponseOfCheckObjectSecurityUserResponseValue | null;
}
/**
 * @type ResponseOfCheckObjectSecurityUserResponseValue
 * @export
 */
export type ResponseOfCheckObjectSecurityUserResponseValue = CheckObjectSecurityUserResponse;

/**
 * 
 * @export
 * @interface ResponseOfCommitteeResponse
 */
export interface ResponseOfCommitteeResponse {
    /**
     * 
     * @type {ResponseOfCommitteeResponseValue}
     * @memberof ResponseOfCommitteeResponse
     */
    'value'?: ResponseOfCommitteeResponseValue | null;
}
/**
 * @type ResponseOfCommitteeResponseValue
 * @export
 */
export type ResponseOfCommitteeResponseValue = CommitteeResponse;

/**
 * 
 * @export
 * @interface ResponseOfCompanyByIdResponseDto
 */
export interface ResponseOfCompanyByIdResponseDto {
    /**
     * 
     * @type {ResponseOfCompanyByIdResponseDtoValue}
     * @memberof ResponseOfCompanyByIdResponseDto
     */
    'value'?: ResponseOfCompanyByIdResponseDtoValue | null;
}
/**
 * @type ResponseOfCompanyByIdResponseDtoValue
 * @export
 */
export type ResponseOfCompanyByIdResponseDtoValue = CompanyByIdResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfCompanyResponseDto
 */
export interface ResponseOfCompanyResponseDto {
    /**
     * 
     * @type {ResponseOfCompanyResponseDtoValue}
     * @memberof ResponseOfCompanyResponseDto
     */
    'value'?: ResponseOfCompanyResponseDtoValue | null;
}
/**
 * @type ResponseOfCompanyResponseDtoValue
 * @export
 */
export type ResponseOfCompanyResponseDtoValue = CompanyResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfFastCloseDeliverableResponse
 */
export interface ResponseOfFastCloseDeliverableResponse {
    /**
     * 
     * @type {ResponseOfFastCloseDeliverableResponseValue}
     * @memberof ResponseOfFastCloseDeliverableResponse
     */
    'value'?: ResponseOfFastCloseDeliverableResponseValue | null;
}
/**
 * @type ResponseOfFastCloseDeliverableResponseValue
 * @export
 */
export type ResponseOfFastCloseDeliverableResponseValue = FastCloseDeliverableResponse;

/**
 * 
 * @export
 * @interface ResponseOfGetAgendaResponseDto
 */
export interface ResponseOfGetAgendaResponseDto {
    /**
     * 
     * @type {ResponseOfGetAgendaResponseDtoValue}
     * @memberof ResponseOfGetAgendaResponseDto
     */
    'value'?: ResponseOfGetAgendaResponseDtoValue | null;
}
/**
 * @type ResponseOfGetAgendaResponseDtoValue
 * @export
 */
export type ResponseOfGetAgendaResponseDtoValue = GetAgendaResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetAnnualGoalDto
 */
export interface ResponseOfGetAnnualGoalDto {
    /**
     * 
     * @type {ResponseOfGetAnnualGoalDtoValue}
     * @memberof ResponseOfGetAnnualGoalDto
     */
    'value'?: ResponseOfGetAnnualGoalDtoValue | null;
}
/**
 * @type ResponseOfGetAnnualGoalDtoValue
 * @export
 */
export type ResponseOfGetAnnualGoalDtoValue = GetAnnualGoalDto;

/**
 * 
 * @export
 * @interface ResponseOfGetAnualGoalDetailsDto
 */
export interface ResponseOfGetAnualGoalDetailsDto {
    /**
     * 
     * @type {ResponseOfGetAnualGoalDetailsDtoValue}
     * @memberof ResponseOfGetAnualGoalDetailsDto
     */
    'value'?: ResponseOfGetAnualGoalDetailsDtoValue | null;
}
/**
 * @type ResponseOfGetAnualGoalDetailsDtoValue
 * @export
 */
export type ResponseOfGetAnualGoalDetailsDtoValue = GetAnualGoalDetailsDto;

/**
 * 
 * @export
 * @interface ResponseOfGetCommitteeSummaryResponseDto
 */
export interface ResponseOfGetCommitteeSummaryResponseDto {
    /**
     * 
     * @type {ResponseOfGetCommitteeSummaryResponseDtoValue}
     * @memberof ResponseOfGetCommitteeSummaryResponseDto
     */
    'value'?: ResponseOfGetCommitteeSummaryResponseDtoValue | null;
}
/**
 * @type ResponseOfGetCommitteeSummaryResponseDtoValue
 * @export
 */
export type ResponseOfGetCommitteeSummaryResponseDtoValue = GetCommitteeSummaryResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetDeliverableResponseDto
 */
export interface ResponseOfGetDeliverableResponseDto {
    /**
     * 
     * @type {ResponseOfGetDeliverableResponseDtoValue}
     * @memberof ResponseOfGetDeliverableResponseDto
     */
    'value'?: ResponseOfGetDeliverableResponseDtoValue | null;
}
/**
 * @type ResponseOfGetDeliverableResponseDtoValue
 * @export
 */
export type ResponseOfGetDeliverableResponseDtoValue = GetDeliverableResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetDivisionResponseDto
 */
export interface ResponseOfGetDivisionResponseDto {
    /**
     * 
     * @type {ResponseOfGetDivisionResponseDtoValue}
     * @memberof ResponseOfGetDivisionResponseDto
     */
    'value'?: ResponseOfGetDivisionResponseDtoValue | null;
}
/**
 * @type ResponseOfGetDivisionResponseDtoValue
 * @export
 */
export type ResponseOfGetDivisionResponseDtoValue = GetDivisionResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetFullProfileDto
 */
export interface ResponseOfGetFullProfileDto {
    /**
     * 
     * @type {ResponseOfGetFullProfileDtoValue}
     * @memberof ResponseOfGetFullProfileDto
     */
    'value'?: ResponseOfGetFullProfileDtoValue | null;
}
/**
 * @type ResponseOfGetFullProfileDtoValue
 * @export
 */
export type ResponseOfGetFullProfileDtoValue = GetFullProfileDto;

/**
 * 
 * @export
 * @interface ResponseOfGetGoalCategoryByIDResponseDto
 */
export interface ResponseOfGetGoalCategoryByIDResponseDto {
    /**
     * 
     * @type {ResponseOfGetGoalCategoryByIDResponseDtoValue}
     * @memberof ResponseOfGetGoalCategoryByIDResponseDto
     */
    'value'?: ResponseOfGetGoalCategoryByIDResponseDtoValue | null;
}
/**
 * @type ResponseOfGetGoalCategoryByIDResponseDtoValue
 * @export
 */
export type ResponseOfGetGoalCategoryByIDResponseDtoValue = GetGoalCategoryByIDResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetGoalGroundtoActA3ResponseDto
 */
export interface ResponseOfGetGoalGroundtoActA3ResponseDto {
    /**
     * 
     * @type {ResponseOfGetGoalGroundtoActA3ResponseDtoValue}
     * @memberof ResponseOfGetGoalGroundtoActA3ResponseDto
     */
    'value'?: ResponseOfGetGoalGroundtoActA3ResponseDtoValue | null;
}
/**
 * @type ResponseOfGetGoalGroundtoActA3ResponseDtoValue
 * @export
 */
export type ResponseOfGetGoalGroundtoActA3ResponseDtoValue = GetGoalGroundtoActA3ResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetImplementationSectionA3ResponseDto
 */
export interface ResponseOfGetImplementationSectionA3ResponseDto {
    /**
     * 
     * @type {ResponseOfGetImplementationSectionA3ResponseDtoValue}
     * @memberof ResponseOfGetImplementationSectionA3ResponseDto
     */
    'value'?: ResponseOfGetImplementationSectionA3ResponseDtoValue | null;
}
/**
 * @type ResponseOfGetImplementationSectionA3ResponseDtoValue
 * @export
 */
export type ResponseOfGetImplementationSectionA3ResponseDtoValue = GetImplementationSectionA3ResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetInsertKPIResponseDto
 */
export interface ResponseOfGetInsertKPIResponseDto {
    /**
     * 
     * @type {ResponseOfGetInsertKPIResponseDtoValue}
     * @memberof ResponseOfGetInsertKPIResponseDto
     */
    'value'?: ResponseOfGetInsertKPIResponseDtoValue | null;
}
/**
 * @type ResponseOfGetInsertKPIResponseDtoValue
 * @export
 */
export type ResponseOfGetInsertKPIResponseDtoValue = GetInsertKPIResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetKPICommentResponse
 */
export interface ResponseOfGetKPICommentResponse {
    /**
     * 
     * @type {ResponseOfGetKPICommentResponseValue}
     * @memberof ResponseOfGetKPICommentResponse
     */
    'value'?: ResponseOfGetKPICommentResponseValue | null;
}
/**
 * @type ResponseOfGetKPICommentResponseValue
 * @export
 */
export type ResponseOfGetKPICommentResponseValue = GetKPICommentResponse;

/**
 * 
 * @export
 * @interface ResponseOfGetKPIDetailDto
 */
export interface ResponseOfGetKPIDetailDto {
    /**
     * 
     * @type {ResponseOfGetKPIDetailDtoValue}
     * @memberof ResponseOfGetKPIDetailDto
     */
    'value'?: ResponseOfGetKPIDetailDtoValue | null;
}
/**
 * @type ResponseOfGetKPIDetailDtoValue
 * @export
 */
export type ResponseOfGetKPIDetailDtoValue = GetKPIDetailDto;

/**
 * 
 * @export
 * @interface ResponseOfGetKPIListPageDto
 */
export interface ResponseOfGetKPIListPageDto {
    /**
     * 
     * @type {ResponseOfGetKPIListPageDtoValue}
     * @memberof ResponseOfGetKPIListPageDto
     */
    'value'?: ResponseOfGetKPIListPageDtoValue | null;
}
/**
 * @type ResponseOfGetKPIListPageDtoValue
 * @export
 */
export type ResponseOfGetKPIListPageDtoValue = GetKPIListPageDto;

/**
 * 
 * @export
 * @interface ResponseOfGetKPIsDetailDto
 */
export interface ResponseOfGetKPIsDetailDto {
    /**
     * 
     * @type {ResponseOfGetKPIsDetailDtoValue}
     * @memberof ResponseOfGetKPIsDetailDto
     */
    'value'?: ResponseOfGetKPIsDetailDtoValue | null;
}
/**
 * @type ResponseOfGetKPIsDetailDtoValue
 * @export
 */
export type ResponseOfGetKPIsDetailDtoValue = GetKPIsDetailDto;

/**
 * 
 * @export
 * @interface ResponseOfGetLongTermGoalChecklistResponseDto
 */
export interface ResponseOfGetLongTermGoalChecklistResponseDto {
    /**
     * 
     * @type {ResponseOfGetLongTermGoalChecklistResponseDtoValue}
     * @memberof ResponseOfGetLongTermGoalChecklistResponseDto
     */
    'value'?: ResponseOfGetLongTermGoalChecklistResponseDtoValue | null;
}
/**
 * @type ResponseOfGetLongTermGoalChecklistResponseDtoValue
 * @export
 */
export type ResponseOfGetLongTermGoalChecklistResponseDtoValue = GetLongTermGoalChecklistResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetMacroProjectResponseDto
 */
export interface ResponseOfGetMacroProjectResponseDto {
    /**
     * 
     * @type {ResponseOfGetMacroProjectResponseDtoValue}
     * @memberof ResponseOfGetMacroProjectResponseDto
     */
    'value'?: ResponseOfGetMacroProjectResponseDtoValue | null;
}
/**
 * @type ResponseOfGetMacroProjectResponseDtoValue
 * @export
 */
export type ResponseOfGetMacroProjectResponseDtoValue = GetMacroProjectResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetMyAcivityResponseDto
 */
export interface ResponseOfGetMyAcivityResponseDto {
    /**
     * 
     * @type {ResponseOfGetMyAcivityResponseDtoValue}
     * @memberof ResponseOfGetMyAcivityResponseDto
     */
    'value'?: ResponseOfGetMyAcivityResponseDtoValue | null;
}
/**
 * @type ResponseOfGetMyAcivityResponseDtoValue
 * @export
 */
export type ResponseOfGetMyAcivityResponseDtoValue = GetMyAcivityResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetNextStepsSectionA3ResponseDto
 */
export interface ResponseOfGetNextStepsSectionA3ResponseDto {
    /**
     * 
     * @type {ResponseOfGetNextStepsSectionA3ResponseDtoValue}
     * @memberof ResponseOfGetNextStepsSectionA3ResponseDto
     */
    'value'?: ResponseOfGetNextStepsSectionA3ResponseDtoValue | null;
}
/**
 * @type ResponseOfGetNextStepsSectionA3ResponseDtoValue
 * @export
 */
export type ResponseOfGetNextStepsSectionA3ResponseDtoValue = GetNextStepsSectionA3ResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetProjectCostDetailResponseDto
 */
export interface ResponseOfGetProjectCostDetailResponseDto {
    /**
     * 
     * @type {ResponseOfGetProjectCostDetailResponseDtoValue}
     * @memberof ResponseOfGetProjectCostDetailResponseDto
     */
    'value'?: ResponseOfGetProjectCostDetailResponseDtoValue | null;
}
/**
 * @type ResponseOfGetProjectCostDetailResponseDtoValue
 * @export
 */
export type ResponseOfGetProjectCostDetailResponseDtoValue = GetProjectCostDetailResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetProjectHeaderA3ResponseDto
 */
export interface ResponseOfGetProjectHeaderA3ResponseDto {
    /**
     * 
     * @type {ResponseOfGetProjectHeaderA3ResponseDtoValue}
     * @memberof ResponseOfGetProjectHeaderA3ResponseDto
     */
    'value'?: ResponseOfGetProjectHeaderA3ResponseDtoValue | null;
}
/**
 * @type ResponseOfGetProjectHeaderA3ResponseDtoValue
 * @export
 */
export type ResponseOfGetProjectHeaderA3ResponseDtoValue = GetProjectHeaderA3ResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetProjectRepositoryList
 */
export interface ResponseOfGetProjectRepositoryList {
    /**
     * 
     * @type {ResponseOfGetProjectRepositoryListValue}
     * @memberof ResponseOfGetProjectRepositoryList
     */
    'value'?: ResponseOfGetProjectRepositoryListValue | null;
}
/**
 * @type ResponseOfGetProjectRepositoryListValue
 * @export
 */
export type ResponseOfGetProjectRepositoryListValue = GetProjectRepositoryList;

/**
 * 
 * @export
 * @interface ResponseOfGetProjectResponseDto
 */
export interface ResponseOfGetProjectResponseDto {
    /**
     * 
     * @type {ResponseOfGetProjectResponseDtoValue}
     * @memberof ResponseOfGetProjectResponseDto
     */
    'value'?: ResponseOfGetProjectResponseDtoValue | null;
}
/**
 * @type ResponseOfGetProjectResponseDtoValue
 * @export
 */
export type ResponseOfGetProjectResponseDtoValue = GetProjectResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetUserProjectCostAccessResponseDto
 */
export interface ResponseOfGetUserProjectCostAccessResponseDto {
    /**
     * 
     * @type {ResponseOfGetUserProjectCostAccessResponseDtoValue}
     * @memberof ResponseOfGetUserProjectCostAccessResponseDto
     */
    'value'?: ResponseOfGetUserProjectCostAccessResponseDtoValue | null;
}
/**
 * @type ResponseOfGetUserProjectCostAccessResponseDtoValue
 * @export
 */
export type ResponseOfGetUserProjectCostAccessResponseDtoValue = GetUserProjectCostAccessResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetXMatrixCycleByIDResponseDto
 */
export interface ResponseOfGetXMatrixCycleByIDResponseDto {
    /**
     * 
     * @type {ResponseOfGetXMatrixCycleByIDResponseDtoValue}
     * @memberof ResponseOfGetXMatrixCycleByIDResponseDto
     */
    'value'?: ResponseOfGetXMatrixCycleByIDResponseDtoValue | null;
}
/**
 * @type ResponseOfGetXMatrixCycleByIDResponseDtoValue
 * @export
 */
export type ResponseOfGetXMatrixCycleByIDResponseDtoValue = GetXMatrixCycleByIDResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetXMatrixInfoResponseDto
 */
export interface ResponseOfGetXMatrixInfoResponseDto {
    /**
     * 
     * @type {ResponseOfGetXMatrixInfoResponseDtoValue}
     * @memberof ResponseOfGetXMatrixInfoResponseDto
     */
    'value'?: ResponseOfGetXMatrixInfoResponseDtoValue | null;
}
/**
 * @type ResponseOfGetXMatrixInfoResponseDtoValue
 * @export
 */
export type ResponseOfGetXMatrixInfoResponseDtoValue = GetXMatrixInfoResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGetXMatrixSummaryResponseDto
 */
export interface ResponseOfGetXMatrixSummaryResponseDto {
    /**
     * 
     * @type {ResponseOfGetXMatrixSummaryResponseDtoValue}
     * @memberof ResponseOfGetXMatrixSummaryResponseDto
     */
    'value'?: ResponseOfGetXMatrixSummaryResponseDtoValue | null;
}
/**
 * @type ResponseOfGetXMatrixSummaryResponseDtoValue
 * @export
 */
export type ResponseOfGetXMatrixSummaryResponseDtoValue = GetXMatrixSummaryResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfGoalByProject
 */
export interface ResponseOfGoalByProject {
    /**
     * 
     * @type {ResponseOfGoalByProjectValue}
     * @memberof ResponseOfGoalByProject
     */
    'value'?: ResponseOfGoalByProjectValue | null;
}
/**
 * @type ResponseOfGoalByProjectValue
 * @export
 */
export type ResponseOfGoalByProjectValue = GoalByProject;

/**
 * 
 * @export
 * @interface ResponseOfIListOfDeliverableDto
 */
export interface ResponseOfIListOfDeliverableDto {
    /**
     * 
     * @type {Array<DeliverableDto>}
     * @memberof ResponseOfIListOfDeliverableDto
     */
    'value'?: Array<DeliverableDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfInsertAlertConfigurationResponseDto
 */
export interface ResponseOfInsertAlertConfigurationResponseDto {
    /**
     * 
     * @type {ResponseOfInsertAlertConfigurationResponseDtoValue}
     * @memberof ResponseOfInsertAlertConfigurationResponseDto
     */
    'value'?: ResponseOfInsertAlertConfigurationResponseDtoValue | null;
}
/**
 * @type ResponseOfInsertAlertConfigurationResponseDtoValue
 * @export
 */
export type ResponseOfInsertAlertConfigurationResponseDtoValue = InsertAlertConfigurationResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfInsertKPIDetailResponse
 */
export interface ResponseOfInsertKPIDetailResponse {
    /**
     * 
     * @type {ResponseOfInsertKPIDetailResponseValue}
     * @memberof ResponseOfInsertKPIDetailResponse
     */
    'value'?: ResponseOfInsertKPIDetailResponseValue | null;
}
/**
 * @type ResponseOfInsertKPIDetailResponseValue
 * @export
 */
export type ResponseOfInsertKPIDetailResponseValue = InsertKPIDetailResponse;

/**
 * 
 * @export
 * @interface ResponseOfInsertLongTermGoalResponseDto
 */
export interface ResponseOfInsertLongTermGoalResponseDto {
    /**
     * 
     * @type {ResponseOfInsertLongTermGoalResponseDtoValue}
     * @memberof ResponseOfInsertLongTermGoalResponseDto
     */
    'value'?: ResponseOfInsertLongTermGoalResponseDtoValue | null;
}
/**
 * @type ResponseOfInsertLongTermGoalResponseDtoValue
 * @export
 */
export type ResponseOfInsertLongTermGoalResponseDtoValue = InsertLongTermGoalResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfInsertMyActivityResponseDto
 */
export interface ResponseOfInsertMyActivityResponseDto {
    /**
     * 
     * @type {ResponseOfInsertMyActivityResponseDtoValue}
     * @memberof ResponseOfInsertMyActivityResponseDto
     */
    'value'?: ResponseOfInsertMyActivityResponseDtoValue | null;
}
/**
 * @type ResponseOfInsertMyActivityResponseDtoValue
 * @export
 */
export type ResponseOfInsertMyActivityResponseDtoValue = InsertMyActivityResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfInsertNextYearXMatrixResponseDto
 */
export interface ResponseOfInsertNextYearXMatrixResponseDto {
    /**
     * 
     * @type {ResponseOfInsertNextYearXMatrixResponseDtoValue}
     * @memberof ResponseOfInsertNextYearXMatrixResponseDto
     */
    'value'?: ResponseOfInsertNextYearXMatrixResponseDtoValue | null;
}
/**
 * @type ResponseOfInsertNextYearXMatrixResponseDtoValue
 * @export
 */
export type ResponseOfInsertNextYearXMatrixResponseDtoValue = InsertNextYearXMatrixResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfInsertProjectCostDetailResponseDto
 */
export interface ResponseOfInsertProjectCostDetailResponseDto {
    /**
     * 
     * @type {ResponseOfInsertProjectCostDetailResponseDtoValue}
     * @memberof ResponseOfInsertProjectCostDetailResponseDto
     */
    'value'?: ResponseOfInsertProjectCostDetailResponseDtoValue | null;
}
/**
 * @type ResponseOfInsertProjectCostDetailResponseDtoValue
 * @export
 */
export type ResponseOfInsertProjectCostDetailResponseDtoValue = InsertProjectCostDetailResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfInsertProjectMemberResponseDto
 */
export interface ResponseOfInsertProjectMemberResponseDto {
    /**
     * 
     * @type {ResponseOfInsertProjectMemberResponseDtoValue}
     * @memberof ResponseOfInsertProjectMemberResponseDto
     */
    'value'?: ResponseOfInsertProjectMemberResponseDtoValue | null;
}
/**
 * @type ResponseOfInsertProjectMemberResponseDtoValue
 * @export
 */
export type ResponseOfInsertProjectMemberResponseDtoValue = InsertProjectMemberResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfInsertProjectRoutineResponseDto
 */
export interface ResponseOfInsertProjectRoutineResponseDto {
    /**
     * 
     * @type {ResponseOfInsertProjectRoutineResponseDtoValue}
     * @memberof ResponseOfInsertProjectRoutineResponseDto
     */
    'value'?: ResponseOfInsertProjectRoutineResponseDtoValue | null;
}
/**
 * @type ResponseOfInsertProjectRoutineResponseDtoValue
 * @export
 */
export type ResponseOfInsertProjectRoutineResponseDtoValue = InsertProjectRoutineResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfInsertProjectXMatrixResponse
 */
export interface ResponseOfInsertProjectXMatrixResponse {
    /**
     * 
     * @type {ResponseOfInsertProjectXMatrixResponseValue}
     * @memberof ResponseOfInsertProjectXMatrixResponse
     */
    'value'?: ResponseOfInsertProjectXMatrixResponseValue | null;
}
/**
 * @type ResponseOfInsertProjectXMatrixResponseValue
 * @export
 */
export type ResponseOfInsertProjectXMatrixResponseValue = InsertProjectXMatrixResponse;

/**
 * 
 * @export
 * @interface ResponseOfInsertUpdateCommitteeSessionResponseDto
 */
export interface ResponseOfInsertUpdateCommitteeSessionResponseDto {
    /**
     * 
     * @type {ResponseOfInsertUpdateCommitteeSessionResponseDtoValue}
     * @memberof ResponseOfInsertUpdateCommitteeSessionResponseDto
     */
    'value'?: ResponseOfInsertUpdateCommitteeSessionResponseDtoValue | null;
}
/**
 * @type ResponseOfInsertUpdateCommitteeSessionResponseDtoValue
 * @export
 */
export type ResponseOfInsertUpdateCommitteeSessionResponseDtoValue = InsertUpdateCommitteeSessionResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfInsertXMatrixDivisionResponseDto
 */
export interface ResponseOfInsertXMatrixDivisionResponseDto {
    /**
     * 
     * @type {ResponseOfInsertXMatrixDivisionResponseDtoValue}
     * @memberof ResponseOfInsertXMatrixDivisionResponseDto
     */
    'value'?: ResponseOfInsertXMatrixDivisionResponseDtoValue | null;
}
/**
 * @type ResponseOfInsertXMatrixDivisionResponseDtoValue
 * @export
 */
export type ResponseOfInsertXMatrixDivisionResponseDtoValue = InsertXMatrixDivisionResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfInsertXMatrixResponseDto
 */
export interface ResponseOfInsertXMatrixResponseDto {
    /**
     * 
     * @type {ResponseOfInsertXMatrixResponseDtoValue}
     * @memberof ResponseOfInsertXMatrixResponseDto
     */
    'value'?: ResponseOfInsertXMatrixResponseDtoValue | null;
}
/**
 * @type ResponseOfInsertXMatrixResponseDtoValue
 * @export
 */
export type ResponseOfInsertXMatrixResponseDtoValue = InsertXMatrixResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfListOfAnnualGoalKPIBowling
 */
export interface ResponseOfListOfAnnualGoalKPIBowling {
    /**
     * 
     * @type {Array<AnnualGoalKPIBowling>}
     * @memberof ResponseOfListOfAnnualGoalKPIBowling
     */
    'value'?: Array<AnnualGoalKPIBowling> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfAnnualGoalPicklistResponseDto
 */
export interface ResponseOfListOfAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {Array<AnnualGoalPicklistResponseDto>}
     * @memberof ResponseOfListOfAnnualGoalPicklistResponseDto
     */
    'value'?: Array<AnnualGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfChildCommitteeResponseDto
 */
export interface ResponseOfListOfChildCommitteeResponseDto {
    /**
     * 
     * @type {Array<ChildCommitteeResponseDto>}
     * @memberof ResponseOfListOfChildCommitteeResponseDto
     */
    'value'?: Array<ChildCommitteeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfCommitteMembers
 */
export interface ResponseOfListOfCommitteMembers {
    /**
     * 
     * @type {Array<CommitteMembers>}
     * @memberof ResponseOfListOfCommitteMembers
     */
    'value'?: Array<CommitteMembers> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfDetailsKPIResponseDto
 */
export interface ResponseOfListOfDetailsKPIResponseDto {
    /**
     * 
     * @type {Array<DetailsKPIResponseDto>}
     * @memberof ResponseOfListOfDetailsKPIResponseDto
     */
    'value'?: Array<DetailsKPIResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfExpandoObject
 */
export interface ResponseOfListOfExpandoObject {
    /**
     * 
     * @type {Array<any>}
     * @memberof ResponseOfListOfExpandoObject
     */
    'value'?: Array<any> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfFilterbyXMatrixResponse
 */
export interface ResponseOfListOfFilterbyXMatrixResponse {
    /**
     * 
     * @type {Array<FilterbyXMatrixResponse>}
     * @memberof ResponseOfListOfFilterbyXMatrixResponse
     */
    'value'?: Array<FilterbyXMatrixResponse> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetActivitySummaryperStatusResponseDto
 */
export interface ResponseOfListOfGetActivitySummaryperStatusResponseDto {
    /**
     * 
     * @type {Array<GetActivitySummaryperStatusResponseDto>}
     * @memberof ResponseOfListOfGetActivitySummaryperStatusResponseDto
     */
    'value'?: Array<GetActivitySummaryperStatusResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetActivitySummaryperTimeResponse
 */
export interface ResponseOfListOfGetActivitySummaryperTimeResponse {
    /**
     * 
     * @type {Array<GetActivitySummaryperTimeResponse>}
     * @memberof ResponseOfListOfGetActivitySummaryperTimeResponse
     */
    'value'?: Array<GetActivitySummaryperTimeResponse> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetAgendaCalendarResponseDto
 */
export interface ResponseOfListOfGetAgendaCalendarResponseDto {
    /**
     * 
     * @type {Array<GetAgendaCalendarResponseDto>}
     * @memberof ResponseOfListOfGetAgendaCalendarResponseDto
     */
    'value'?: Array<GetAgendaCalendarResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetAnnualGoalByKPIResponseDto
 */
export interface ResponseOfListOfGetAnnualGoalByKPIResponseDto {
    /**
     * 
     * @type {Array<GetAnnualGoalByKPIResponseDto>}
     * @memberof ResponseOfListOfGetAnnualGoalByKPIResponseDto
     */
    'value'?: Array<GetAnnualGoalByKPIResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetAnnualGoalDto
 */
export interface ResponseOfListOfGetAnnualGoalDto {
    /**
     * 
     * @type {Array<GetAnnualGoalDto>}
     * @memberof ResponseOfListOfGetAnnualGoalDto
     */
    'value'?: Array<GetAnnualGoalDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetAvailableChildCommitteesResponseDto
 */
export interface ResponseOfListOfGetAvailableChildCommitteesResponseDto {
    /**
     * 
     * @type {Array<GetAvailableChildCommitteesResponseDto>}
     * @memberof ResponseOfListOfGetAvailableChildCommitteesResponseDto
     */
    'value'?: Array<GetAvailableChildCommitteesResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetCalculationTypesByLanguageCodeResponseDto
 */
export interface ResponseOfListOfGetCalculationTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {Array<GetCalculationTypesByLanguageCodeResponseDto>}
     * @memberof ResponseOfListOfGetCalculationTypesByLanguageCodeResponseDto
     */
    'value'?: Array<GetCalculationTypesByLanguageCodeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetChildDeliverableResponseDto
 */
export interface ResponseOfListOfGetChildDeliverableResponseDto {
    /**
     * 
     * @type {Array<GetChildDeliverableResponseDto>}
     * @memberof ResponseOfListOfGetChildDeliverableResponseDto
     */
    'value'?: Array<GetChildDeliverableResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetCommitteeActivitiesResponseDto
 */
export interface ResponseOfListOfGetCommitteeActivitiesResponseDto {
    /**
     * 
     * @type {Array<GetCommitteeActivitiesResponseDto>}
     * @memberof ResponseOfListOfGetCommitteeActivitiesResponseDto
     */
    'value'?: Array<GetCommitteeActivitiesResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetCommitteeAvailableProjectsResponseDto
 */
export interface ResponseOfListOfGetCommitteeAvailableProjectsResponseDto {
    /**
     * 
     * @type {Array<GetCommitteeAvailableProjectsResponseDto>}
     * @memberof ResponseOfListOfGetCommitteeAvailableProjectsResponseDto
     */
    'value'?: Array<GetCommitteeAvailableProjectsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetCommitteeListPageResponseDto
 */
export interface ResponseOfListOfGetCommitteeListPageResponseDto {
    /**
     * 
     * @type {Array<GetCommitteeListPageResponseDto>}
     * @memberof ResponseOfListOfGetCommitteeListPageResponseDto
     */
    'value'?: Array<GetCommitteeListPageResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetCommitteePicklistDto
 */
export interface ResponseOfListOfGetCommitteePicklistDto {
    /**
     * 
     * @type {Array<GetCommitteePicklistDto>}
     * @memberof ResponseOfListOfGetCommitteePicklistDto
     */
    'value'?: Array<GetCommitteePicklistDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetCommitteeProjectsResponseDto
 */
export interface ResponseOfListOfGetCommitteeProjectsResponseDto {
    /**
     * 
     * @type {Array<GetCommitteeProjectsResponseDto>}
     * @memberof ResponseOfListOfGetCommitteeProjectsResponseDto
     */
    'value'?: Array<GetCommitteeProjectsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetCommitteeSessionsResponseDto
 */
export interface ResponseOfListOfGetCommitteeSessionsResponseDto {
    /**
     * 
     * @type {Array<GetCommitteeSessionsResponseDto>}
     * @memberof ResponseOfListOfGetCommitteeSessionsResponseDto
     */
    'value'?: Array<GetCommitteeSessionsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetCommitteeSummaryPerStatusResponse
 */
export interface ResponseOfListOfGetCommitteeSummaryPerStatusResponse {
    /**
     * 
     * @type {Array<GetCommitteeSummaryPerStatusResponse>}
     * @memberof ResponseOfListOfGetCommitteeSummaryPerStatusResponse
     */
    'value'?: Array<GetCommitteeSummaryPerStatusResponse> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetCommitteeSummaryPerTimeResponse
 */
export interface ResponseOfListOfGetCommitteeSummaryPerTimeResponse {
    /**
     * 
     * @type {Array<GetCommitteeSummaryPerTimeResponse>}
     * @memberof ResponseOfListOfGetCommitteeSummaryPerTimeResponse
     */
    'value'?: Array<GetCommitteeSummaryPerTimeResponse> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetCompanyListResponseDto
 */
export interface ResponseOfListOfGetCompanyListResponseDto {
    /**
     * 
     * @type {Array<GetCompanyListResponseDto>}
     * @memberof ResponseOfListOfGetCompanyListResponseDto
     */
    'value'?: Array<GetCompanyListResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetDeliverableListPageResponseDto
 */
export interface ResponseOfListOfGetDeliverableListPageResponseDto {
    /**
     * 
     * @type {Array<GetDeliverableListPageResponseDto>}
     * @memberof ResponseOfListOfGetDeliverableListPageResponseDto
     */
    'value'?: Array<GetDeliverableListPageResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetDeliverablePicklistResponseDto
 */
export interface ResponseOfListOfGetDeliverablePicklistResponseDto {
    /**
     * 
     * @type {Array<GetDeliverablePicklistResponseDto>}
     * @memberof ResponseOfListOfGetDeliverablePicklistResponseDto
     */
    'value'?: Array<GetDeliverablePicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetDeliverableProjectPicklistResponseDto
 */
export interface ResponseOfListOfGetDeliverableProjectPicklistResponseDto {
    /**
     * 
     * @type {Array<GetDeliverableProjectPicklistResponseDto>}
     * @memberof ResponseOfListOfGetDeliverableProjectPicklistResponseDto
     */
    'value'?: Array<GetDeliverableProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetDeliverableSummaryperStatusResponseDto
 */
export interface ResponseOfListOfGetDeliverableSummaryperStatusResponseDto {
    /**
     * 
     * @type {Array<GetDeliverableSummaryperStatusResponseDto>}
     * @memberof ResponseOfListOfGetDeliverableSummaryperStatusResponseDto
     */
    'value'?: Array<GetDeliverableSummaryperStatusResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetDeliverableSummaryperTimeResponseDto
 */
export interface ResponseOfListOfGetDeliverableSummaryperTimeResponseDto {
    /**
     * 
     * @type {Array<GetDeliverableSummaryperTimeResponseDto>}
     * @memberof ResponseOfListOfGetDeliverableSummaryperTimeResponseDto
     */
    'value'?: Array<GetDeliverableSummaryperTimeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetDivisionListPageResponseDto
 */
export interface ResponseOfListOfGetDivisionListPageResponseDto {
    /**
     * 
     * @type {Array<GetDivisionListPageResponseDto>}
     * @memberof ResponseOfListOfGetDivisionListPageResponseDto
     */
    'value'?: Array<GetDivisionListPageResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetDivisionPicklistDto
 */
export interface ResponseOfListOfGetDivisionPicklistDto {
    /**
     * 
     * @type {Array<GetDivisionPicklistDto>}
     * @memberof ResponseOfListOfGetDivisionPicklistDto
     */
    'value'?: Array<GetDivisionPicklistDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetDivisionProjectCreationPicklistResponseDto
 */
export interface ResponseOfListOfGetDivisionProjectCreationPicklistResponseDto {
    /**
     * 
     * @type {Array<GetDivisionProjectCreationPicklistResponseDto>}
     * @memberof ResponseOfListOfGetDivisionProjectCreationPicklistResponseDto
     */
    'value'?: Array<GetDivisionProjectCreationPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetFeedActivityListResponseDto
 */
export interface ResponseOfListOfGetFeedActivityListResponseDto {
    /**
     * 
     * @type {Array<GetFeedActivityListResponseDto>}
     * @memberof ResponseOfListOfGetFeedActivityListResponseDto
     */
    'value'?: Array<GetFeedActivityListResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetFeedActivityResponseDto
 */
export interface ResponseOfListOfGetFeedActivityResponseDto {
    /**
     * 
     * @type {Array<GetFeedActivityResponseDto>}
     * @memberof ResponseOfListOfGetFeedActivityResponseDto
     */
    'value'?: Array<GetFeedActivityResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetFeedMonthlyAlertByUserResponseDto
 */
export interface ResponseOfListOfGetFeedMonthlyAlertByUserResponseDto {
    /**
     * 
     * @type {Array<GetFeedMonthlyAlertByUserResponseDto>}
     * @memberof ResponseOfListOfGetFeedMonthlyAlertByUserResponseDto
     */
    'value'?: Array<GetFeedMonthlyAlertByUserResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetFeedSummaryResponseDto
 */
export interface ResponseOfListOfGetFeedSummaryResponseDto {
    /**
     * 
     * @type {Array<GetFeedSummaryResponseDto>}
     * @memberof ResponseOfListOfGetFeedSummaryResponseDto
     */
    'value'?: Array<GetFeedSummaryResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetFollowUpSectionA3ResponseDto
 */
export interface ResponseOfListOfGetFollowUpSectionA3ResponseDto {
    /**
     * 
     * @type {Array<GetFollowUpSectionA3ResponseDto>}
     * @memberof ResponseOfListOfGetFollowUpSectionA3ResponseDto
     */
    'value'?: Array<GetFollowUpSectionA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetGoalCategoryResponseDto
 */
export interface ResponseOfListOfGetGoalCategoryResponseDto {
    /**
     * 
     * @type {Array<GetGoalCategoryResponseDto>}
     * @memberof ResponseOfListOfGetGoalCategoryResponseDto
     */
    'value'?: Array<GetGoalCategoryResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetGoalTypePicklistDto
 */
export interface ResponseOfListOfGetGoalTypePicklistDto {
    /**
     * 
     * @type {Array<GetGoalTypePicklistDto>}
     * @memberof ResponseOfListOfGetGoalTypePicklistDto
     */
    'value'?: Array<GetGoalTypePicklistDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIAnnualGoalPicklistResponseDto
 */
export interface ResponseOfListOfGetKPIAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {Array<GetKPIAnnualGoalPicklistResponseDto>}
     * @memberof ResponseOfListOfGetKPIAnnualGoalPicklistResponseDto
     */
    'value'?: Array<GetKPIAnnualGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIByAnnualGoalResponseDto
 */
export interface ResponseOfListOfGetKPIByAnnualGoalResponseDto {
    /**
     * 
     * @type {Array<GetKPIByAnnualGoalResponseDto>}
     * @memberof ResponseOfListOfGetKPIByAnnualGoalResponseDto
     */
    'value'?: Array<GetKPIByAnnualGoalResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIByProjectResponseDto
 */
export interface ResponseOfListOfGetKPIByProjectResponseDto {
    /**
     * 
     * @type {Array<GetKPIByProjectResponseDto>}
     * @memberof ResponseOfListOfGetKPIByProjectResponseDto
     */
    'value'?: Array<GetKPIByProjectResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIChildPicklistResponse
 */
export interface ResponseOfListOfGetKPIChildPicklistResponse {
    /**
     * 
     * @type {Array<GetKPIChildPicklistResponse>}
     * @memberof ResponseOfListOfGetKPIChildPicklistResponse
     */
    'value'?: Array<GetKPIChildPicklistResponse> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDto
 */
export interface ResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDto {
    /**
     * 
     * @type {Array<GetKPIDashboardSintesiByAnnualGoalResponseDto>}
     * @memberof ResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDto
     */
    'value'?: Array<GetKPIDashboardSintesiByAnnualGoalResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIDashboardSintesiByProjectResponseDto
 */
export interface ResponseOfListOfGetKPIDashboardSintesiByProjectResponseDto {
    /**
     * 
     * @type {Array<GetKPIDashboardSintesiByProjectResponseDto>}
     * @memberof ResponseOfListOfGetKPIDashboardSintesiByProjectResponseDto
     */
    'value'?: Array<GetKPIDashboardSintesiByProjectResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIGerarchicoInfoDTO
 */
export interface ResponseOfListOfGetKPIGerarchicoInfoDTO {
    /**
     * 
     * @type {Array<GetKPIGerarchicoInfoDTO>}
     * @memberof ResponseOfListOfGetKPIGerarchicoInfoDTO
     */
    'value'?: Array<GetKPIGerarchicoInfoDTO> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIGraphicResponseDto
 */
export interface ResponseOfListOfGetKPIGraphicResponseDto {
    /**
     * 
     * @type {Array<GetKPIGraphicResponseDto>}
     * @memberof ResponseOfListOfGetKPIGraphicResponseDto
     */
    'value'?: Array<GetKPIGraphicResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIListDto
 */
export interface ResponseOfListOfGetKPIListDto {
    /**
     * 
     * @type {Array<GetKPIListDto>}
     * @memberof ResponseOfListOfGetKPIListDto
     */
    'value'?: Array<GetKPIListDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIPicklistA3ResponseDto
 */
export interface ResponseOfListOfGetKPIPicklistA3ResponseDto {
    /**
     * 
     * @type {Array<GetKPIPicklistA3ResponseDto>}
     * @memberof ResponseOfListOfGetKPIPicklistA3ResponseDto
     */
    'value'?: Array<GetKPIPicklistA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIProjectPicklistResponseDto
 */
export interface ResponseOfListOfGetKPIProjectPicklistResponseDto {
    /**
     * 
     * @type {Array<GetKPIProjectPicklistResponseDto>}
     * @memberof ResponseOfListOfGetKPIProjectPicklistResponseDto
     */
    'value'?: Array<GetKPIProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPISectionA3ResponseDto
 */
export interface ResponseOfListOfGetKPISectionA3ResponseDto {
    /**
     * 
     * @type {Array<GetKPISectionA3ResponseDto>}
     * @memberof ResponseOfListOfGetKPISectionA3ResponseDto
     */
    'value'?: Array<GetKPISectionA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPISummaryResponseDto
 */
export interface ResponseOfListOfGetKPISummaryResponseDto {
    /**
     * 
     * @type {Array<GetKPISummaryResponseDto>}
     * @memberof ResponseOfListOfGetKPISummaryResponseDto
     */
    'value'?: Array<GetKPISummaryResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetKPIXMatrixPicklistResponseDto
 */
export interface ResponseOfListOfGetKPIXMatrixPicklistResponseDto {
    /**
     * 
     * @type {Array<GetKPIXMatrixPicklistResponseDto>}
     * @memberof ResponseOfListOfGetKPIXMatrixPicklistResponseDto
     */
    'value'?: Array<GetKPIXMatrixPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetLicenseTypesByLanguageCodeResponseDto
 */
export interface ResponseOfListOfGetLicenseTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {Array<GetLicenseTypesByLanguageCodeResponseDto>}
     * @memberof ResponseOfListOfGetLicenseTypesByLanguageCodeResponseDto
     */
    'value'?: Array<GetLicenseTypesByLanguageCodeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetLongTermGoalByKPIResponseDto
 */
export interface ResponseOfListOfGetLongTermGoalByKPIResponseDto {
    /**
     * 
     * @type {Array<GetLongTermGoalByKPIResponseDto>}
     * @memberof ResponseOfListOfGetLongTermGoalByKPIResponseDto
     */
    'value'?: Array<GetLongTermGoalByKPIResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDto
 */
export interface ResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDto {
    /**
     * 
     * @type {Array<GetLongTermGoalTypeByLanguageCodeResponseDto>}
     * @memberof ResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDto
     */
    'value'?: Array<GetLongTermGoalTypeByLanguageCodeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetMasterPlanDeliverableResponseDto
 */
export interface ResponseOfListOfGetMasterPlanDeliverableResponseDto {
    /**
     * 
     * @type {Array<GetMasterPlanDeliverableResponseDto>}
     * @memberof ResponseOfListOfGetMasterPlanDeliverableResponseDto
     */
    'value'?: Array<GetMasterPlanDeliverableResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetMasterplanParentDeliverableResponseDto
 */
export interface ResponseOfListOfGetMasterplanParentDeliverableResponseDto {
    /**
     * 
     * @type {Array<GetMasterplanParentDeliverableResponseDto>}
     * @memberof ResponseOfListOfGetMasterplanParentDeliverableResponseDto
     */
    'value'?: Array<GetMasterplanParentDeliverableResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetMasterplanParentDeliverableTestResponseDto
 */
export interface ResponseOfListOfGetMasterplanParentDeliverableTestResponseDto {
    /**
     * 
     * @type {Array<GetMasterplanParentDeliverableTestResponseDto>}
     * @memberof ResponseOfListOfGetMasterplanParentDeliverableTestResponseDto
     */
    'value'?: Array<GetMasterplanParentDeliverableTestResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetMembersListA3ResponseDto
 */
export interface ResponseOfListOfGetMembersListA3ResponseDto {
    /**
     * 
     * @type {Array<GetMembersListA3ResponseDto>}
     * @memberof ResponseOfListOfGetMembersListA3ResponseDto
     */
    'value'?: Array<GetMembersListA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetMenuItemResponse
 */
export interface ResponseOfListOfGetMenuItemResponse {
    /**
     * 
     * @type {Array<GetMenuItemResponse>}
     * @memberof ResponseOfListOfGetMenuItemResponse
     */
    'value'?: Array<GetMenuItemResponse> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetMonthlyAlertByUserResponseDto
 */
export interface ResponseOfListOfGetMonthlyAlertByUserResponseDto {
    /**
     * 
     * @type {Array<GetMonthlyAlertByUserResponseDto>}
     * @memberof ResponseOfListOfGetMonthlyAlertByUserResponseDto
     */
    'value'?: Array<GetMonthlyAlertByUserResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetMyActivityDeliverableFormResponseDto
 */
export interface ResponseOfListOfGetMyActivityDeliverableFormResponseDto {
    /**
     * 
     * @type {Array<GetMyActivityDeliverableFormResponseDto>}
     * @memberof ResponseOfListOfGetMyActivityDeliverableFormResponseDto
     */
    'value'?: Array<GetMyActivityDeliverableFormResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetMyActivityMenuFormPerUserResponseDto
 */
export interface ResponseOfListOfGetMyActivityMenuFormPerUserResponseDto {
    /**
     * 
     * @type {Array<GetMyActivityMenuFormPerUserResponseDto>}
     * @memberof ResponseOfListOfGetMyActivityMenuFormPerUserResponseDto
     */
    'value'?: Array<GetMyActivityMenuFormPerUserResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetMyActivityMenuFormResponseDto
 */
export interface ResponseOfListOfGetMyActivityMenuFormResponseDto {
    /**
     * 
     * @type {Array<GetMyActivityMenuFormResponseDto>}
     * @memberof ResponseOfListOfGetMyActivityMenuFormResponseDto
     */
    'value'?: Array<GetMyActivityMenuFormResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetMyActivityProjectFormrResponseDto
 */
export interface ResponseOfListOfGetMyActivityProjectFormrResponseDto {
    /**
     * 
     * @type {Array<GetMyActivityProjectFormrResponseDto>}
     * @memberof ResponseOfListOfGetMyActivityProjectFormrResponseDto
     */
    'value'?: Array<GetMyActivityProjectFormrResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetNewDivisionSponsorPicklistResponseDto
 */
export interface ResponseOfListOfGetNewDivisionSponsorPicklistResponseDto {
    /**
     * 
     * @type {Array<GetNewDivisionSponsorPicklistResponseDto>}
     * @memberof ResponseOfListOfGetNewDivisionSponsorPicklistResponseDto
     */
    'value'?: Array<GetNewDivisionSponsorPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDto
 */
export interface ResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDto {
    /**
     * 
     * @type {Array<GetNewDivisionTeamLeaderPicklistResponseDto>}
     * @memberof ResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDto
     */
    'value'?: Array<GetNewDivisionTeamLeaderPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDto
 */
export interface ResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {Array<GetNewDivisionXMatrixAnnualGoalPicklistResponseDto>}
     * @memberof ResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDto
     */
    'value'?: Array<GetNewDivisionXMatrixAnnualGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDto
 */
export interface ResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDto {
    /**
     * 
     * @type {Array<GetNewDivisionXMatrixProjectPicklistResponseDto>}
     * @memberof ResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDto
     */
    'value'?: Array<GetNewDivisionXMatrixProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
 */
export interface ResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDto {
    /**
     * 
     * @type {Array<GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto>}
     * @memberof ResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDto
     */
    'value'?: Array<GetNewDivisionXMatrixSecondaryProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDto
 */
export interface ResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDto {
    /**
     * 
     * @type {Array<GetNewXmatrixAnnualGoalPicklistResponseDto>}
     * @memberof ResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDto
     */
    'value'?: Array<GetNewXmatrixAnnualGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetNewXmatrixProjectPicklistResponseDto
 */
export interface ResponseOfListOfGetNewXmatrixProjectPicklistResponseDto {
    /**
     * 
     * @type {Array<GetNewXmatrixProjectPicklistResponseDto>}
     * @memberof ResponseOfListOfGetNewXmatrixProjectPicklistResponseDto
     */
    'value'?: Array<GetNewXmatrixProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetNoCommitteeProjectsResponseDto
 */
export interface ResponseOfListOfGetNoCommitteeProjectsResponseDto {
    /**
     * 
     * @type {Array<GetNoCommitteeProjectsResponseDto>}
     * @memberof ResponseOfListOfGetNoCommitteeProjectsResponseDto
     */
    'value'?: Array<GetNoCommitteeProjectsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetParentProjectPicklistDto
 */
export interface ResponseOfListOfGetParentProjectPicklistDto {
    /**
     * 
     * @type {Array<GetParentProjectPicklistDto>}
     * @memberof ResponseOfListOfGetParentProjectPicklistDto
     */
    'value'?: Array<GetParentProjectPicklistDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectChildsResponseDto
 */
export interface ResponseOfListOfGetProjectChildsResponseDto {
    /**
     * 
     * @type {Array<GetProjectChildsResponseDto>}
     * @memberof ResponseOfListOfGetProjectChildsResponseDto
     */
    'value'?: Array<GetProjectChildsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectCodesResponseDto
 */
export interface ResponseOfListOfGetProjectCodesResponseDto {
    /**
     * 
     * @type {Array<GetProjectCodesResponseDto>}
     * @memberof ResponseOfListOfGetProjectCodesResponseDto
     */
    'value'?: Array<GetProjectCodesResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectCompletedActivitiesA3ResponseDto
 */
export interface ResponseOfListOfGetProjectCompletedActivitiesA3ResponseDto {
    /**
     * 
     * @type {Array<GetProjectCompletedActivitiesA3ResponseDto>}
     * @memberof ResponseOfListOfGetProjectCompletedActivitiesA3ResponseDto
     */
    'value'?: Array<GetProjectCompletedActivitiesA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectCreationPicklistResponseDto
 */
export interface ResponseOfListOfGetProjectCreationPicklistResponseDto {
    /**
     * 
     * @type {Array<GetProjectCreationPicklistResponseDto>}
     * @memberof ResponseOfListOfGetProjectCreationPicklistResponseDto
     */
    'value'?: Array<GetProjectCreationPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectDeliverableNextStepA3ResponseDto
 */
export interface ResponseOfListOfGetProjectDeliverableNextStepA3ResponseDto {
    /**
     * 
     * @type {Array<GetProjectDeliverableNextStepA3ResponseDto>}
     * @memberof ResponseOfListOfGetProjectDeliverableNextStepA3ResponseDto
     */
    'value'?: Array<GetProjectDeliverableNextStepA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectDeliverablePicklistResponseDto
 */
export interface ResponseOfListOfGetProjectDeliverablePicklistResponseDto {
    /**
     * 
     * @type {Array<GetProjectDeliverablePicklistResponseDto>}
     * @memberof ResponseOfListOfGetProjectDeliverablePicklistResponseDto
     */
    'value'?: Array<GetProjectDeliverablePicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectDeliverableTeamResponseDto
 */
export interface ResponseOfListOfGetProjectDeliverableTeamResponseDto {
    /**
     * 
     * @type {Array<GetProjectDeliverableTeamResponseDto>}
     * @memberof ResponseOfListOfGetProjectDeliverableTeamResponseDto
     */
    'value'?: Array<GetProjectDeliverableTeamResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectFeedActivityListResponseDto
 */
export interface ResponseOfListOfGetProjectFeedActivityListResponseDto {
    /**
     * 
     * @type {Array<GetProjectFeedActivityListResponseDto>}
     * @memberof ResponseOfListOfGetProjectFeedActivityListResponseDto
     */
    'value'?: Array<GetProjectFeedActivityListResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectListPageResponseDto
 */
export interface ResponseOfListOfGetProjectListPageResponseDto {
    /**
     * 
     * @type {Array<GetProjectListPageResponseDto>}
     * @memberof ResponseOfListOfGetProjectListPageResponseDto
     */
    'value'?: Array<GetProjectListPageResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectMembersListResponseDto
 */
export interface ResponseOfListOfGetProjectMembersListResponseDto {
    /**
     * 
     * @type {Array<GetProjectMembersListResponseDto>}
     * @memberof ResponseOfListOfGetProjectMembersListResponseDto
     */
    'value'?: Array<GetProjectMembersListResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectMembersResponseDto
 */
export interface ResponseOfListOfGetProjectMembersResponseDto {
    /**
     * 
     * @type {Array<GetProjectMembersResponseDto>}
     * @memberof ResponseOfListOfGetProjectMembersResponseDto
     */
    'value'?: Array<GetProjectMembersResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectPicklistResponseDto
 */
export interface ResponseOfListOfGetProjectPicklistResponseDto {
    /**
     * 
     * @type {Array<GetProjectPicklistResponseDto>}
     * @memberof ResponseOfListOfGetProjectPicklistResponseDto
     */
    'value'?: Array<GetProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetProjectStatusPicklistDto
 */
export interface ResponseOfListOfGetProjectStatusPicklistDto {
    /**
     * 
     * @type {Array<GetProjectStatusPicklistDto>}
     * @memberof ResponseOfListOfGetProjectStatusPicklistDto
     */
    'value'?: Array<GetProjectStatusPicklistDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetResourceWorkloadSummaryResponseDto
 */
export interface ResponseOfListOfGetResourceWorkloadSummaryResponseDto {
    /**
     * 
     * @type {Array<GetResourceWorkloadSummaryResponseDto>}
     * @memberof ResponseOfListOfGetResourceWorkloadSummaryResponseDto
     */
    'value'?: Array<GetResourceWorkloadSummaryResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetRiskPrecautionA3ResponseDto
 */
export interface ResponseOfListOfGetRiskPrecautionA3ResponseDto {
    /**
     * 
     * @type {Array<GetRiskPrecautionA3ResponseDto>}
     * @memberof ResponseOfListOfGetRiskPrecautionA3ResponseDto
     */
    'value'?: Array<GetRiskPrecautionA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetRiskPrecautionResponseDto
 */
export interface ResponseOfListOfGetRiskPrecautionResponseDto {
    /**
     * 
     * @type {Array<GetRiskPrecautionResponseDto>}
     * @memberof ResponseOfListOfGetRiskPrecautionResponseDto
     */
    'value'?: Array<GetRiskPrecautionResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetRolePicklistDto
 */
export interface ResponseOfListOfGetRolePicklistDto {
    /**
     * 
     * @type {Array<GetRolePicklistDto>}
     * @memberof ResponseOfListOfGetRolePicklistDto
     */
    'value'?: Array<GetRolePicklistDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetRollupKPIChildResponseDto
 */
export interface ResponseOfListOfGetRollupKPIChildResponseDto {
    /**
     * 
     * @type {Array<GetRollupKPIChildResponseDto>}
     * @memberof ResponseOfListOfGetRollupKPIChildResponseDto
     */
    'value'?: Array<GetRollupKPIChildResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetRootXMatrixDivisionListResponseDto
 */
export interface ResponseOfListOfGetRootXMatrixDivisionListResponseDto {
    /**
     * 
     * @type {Array<GetRootXMatrixDivisionListResponseDto>}
     * @memberof ResponseOfListOfGetRootXMatrixDivisionListResponseDto
     */
    'value'?: Array<GetRootXMatrixDivisionListResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDto
 */
export interface ResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDto {
    /**
     * 
     * @type {Array<GetSecondLevelMacroProjectsRelationshipResponseDto>}
     * @memberof ResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDto
     */
    'value'?: Array<GetSecondLevelMacroProjectsRelationshipResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetSemaphorePicklistDto
 */
export interface ResponseOfListOfGetSemaphorePicklistDto {
    /**
     * 
     * @type {Array<GetSemaphorePicklistDto>}
     * @memberof ResponseOfListOfGetSemaphorePicklistDto
     */
    'value'?: Array<GetSemaphorePicklistDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetSintesiComitatiResponse
 */
export interface ResponseOfListOfGetSintesiComitatiResponse {
    /**
     * 
     * @type {Array<GetSintesiComitatiResponse>}
     * @memberof ResponseOfListOfGetSintesiComitatiResponse
     */
    'value'?: Array<GetSintesiComitatiResponse> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetSponsorPicklistResponseDto
 */
export interface ResponseOfListOfGetSponsorPicklistResponseDto {
    /**
     * 
     * @type {Array<GetSponsorPicklistResponseDto>}
     * @memberof ResponseOfListOfGetSponsorPicklistResponseDto
     */
    'value'?: Array<GetSponsorPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetStatusTypeByLanguageCodeResponseDto
 */
export interface ResponseOfListOfGetStatusTypeByLanguageCodeResponseDto {
    /**
     * 
     * @type {Array<GetStatusTypeByLanguageCodeResponseDto>}
     * @memberof ResponseOfListOfGetStatusTypeByLanguageCodeResponseDto
     */
    'value'?: Array<GetStatusTypeByLanguageCodeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDto
 */
export interface ResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDto {
    /**
     * 
     * @type {Array<GetSummaryFeedTypesByLanguageCodeResponseDto>}
     * @memberof ResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDto
     */
    'value'?: Array<GetSummaryFeedTypesByLanguageCodeResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetTeamLeaderActivityMenuFormResponseDto
 */
export interface ResponseOfListOfGetTeamLeaderActivityMenuFormResponseDto {
    /**
     * 
     * @type {Array<GetTeamLeaderActivityMenuFormResponseDto>}
     * @memberof ResponseOfListOfGetTeamLeaderActivityMenuFormResponseDto
     */
    'value'?: Array<GetTeamLeaderActivityMenuFormResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetTeamLeaderPicklistDto
 */
export interface ResponseOfListOfGetTeamLeaderPicklistDto {
    /**
     * 
     * @type {Array<GetTeamLeaderPicklistDto>}
     * @memberof ResponseOfListOfGetTeamLeaderPicklistDto
     */
    'value'?: Array<GetTeamLeaderPicklistDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetTeamLeaderProjectResponseDto
 */
export interface ResponseOfListOfGetTeamLeaderProjectResponseDto {
    /**
     * 
     * @type {Array<GetTeamLeaderProjectResponseDto>}
     * @memberof ResponseOfListOfGetTeamLeaderProjectResponseDto
     */
    'value'?: Array<GetTeamLeaderProjectResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetTotalActiveUsersByRoleResponseDto
 */
export interface ResponseOfListOfGetTotalActiveUsersByRoleResponseDto {
    /**
     * 
     * @type {Array<GetTotalActiveUsersByRoleResponseDto>}
     * @memberof ResponseOfListOfGetTotalActiveUsersByRoleResponseDto
     */
    'value'?: Array<GetTotalActiveUsersByRoleResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetUserCommitteeDto
 */
export interface ResponseOfListOfGetUserCommitteeDto {
    /**
     * 
     * @type {Array<GetUserCommitteeDto>}
     * @memberof ResponseOfListOfGetUserCommitteeDto
     */
    'value'?: Array<GetUserCommitteeDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetUserProjectResponseDto
 */
export interface ResponseOfListOfGetUserProjectResponseDto {
    /**
     * 
     * @type {Array<GetUserProjectResponseDto>}
     * @memberof ResponseOfListOfGetUserProjectResponseDto
     */
    'value'?: Array<GetUserProjectResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetUserSelectResponseDto
 */
export interface ResponseOfListOfGetUserSelectResponseDto {
    /**
     * 
     * @type {Array<GetUserSelectResponseDto>}
     * @memberof ResponseOfListOfGetUserSelectResponseDto
     */
    'value'?: Array<GetUserSelectResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetXMatrixCycleResponseDto
 */
export interface ResponseOfListOfGetXMatrixCycleResponseDto {
    /**
     * 
     * @type {Array<GetXMatrixCycleResponseDto>}
     * @memberof ResponseOfListOfGetXMatrixCycleResponseDto
     */
    'value'?: Array<GetXMatrixCycleResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDto
 */
export interface ResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDto {
    /**
     * 
     * @type {Array<GetXMatrixLongTermGoalPicklistResponseDto>}
     * @memberof ResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDto
     */
    'value'?: Array<GetXMatrixLongTermGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetXMatrixPicklistResponseDto
 */
export interface ResponseOfListOfGetXMatrixPicklistResponseDto {
    /**
     * 
     * @type {Array<GetXMatrixPicklistResponseDto>}
     * @memberof ResponseOfListOfGetXMatrixPicklistResponseDto
     */
    'value'?: Array<GetXMatrixPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetXMatrixUsersResponseDto
 */
export interface ResponseOfListOfGetXMatrixUsersResponseDto {
    /**
     * 
     * @type {Array<GetXMatrixUsersResponseDto>}
     * @memberof ResponseOfListOfGetXMatrixUsersResponseDto
     */
    'value'?: Array<GetXMatrixUsersResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDto
 */
export interface ResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDto {
    /**
     * 
     * @type {Array<GetXmatrixDivisionParentProjectPicklistResponseDto>}
     * @memberof ResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDto
     */
    'value'?: Array<GetXmatrixDivisionParentProjectPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGetXmatrixDivisionPicklistResponseDto
 */
export interface ResponseOfListOfGetXmatrixDivisionPicklistResponseDto {
    /**
     * 
     * @type {Array<GetXmatrixDivisionPicklistResponseDto>}
     * @memberof ResponseOfListOfGetXmatrixDivisionPicklistResponseDto
     */
    'value'?: Array<GetXmatrixDivisionPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfGoalCategoryIconlistResponseDto
 */
export interface ResponseOfListOfGoalCategoryIconlistResponseDto {
    /**
     * 
     * @type {Array<GoalCategoryIconlistResponseDto>}
     * @memberof ResponseOfListOfGoalCategoryIconlistResponseDto
     */
    'value'?: Array<GoalCategoryIconlistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfKPIBowlingChartResponse
 */
export interface ResponseOfListOfKPIBowlingChartResponse {
    /**
     * 
     * @type {Array<KPIBowlingChartResponse>}
     * @memberof ResponseOfListOfKPIBowlingChartResponse
     */
    'value'?: Array<KPIBowlingChartResponse> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfKPIDetailsDto
 */
export interface ResponseOfListOfKPIDetailsDto {
    /**
     * 
     * @type {Array<KPIDetailsDto>}
     * @memberof ResponseOfListOfKPIDetailsDto
     */
    'value'?: Array<KPIDetailsDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfKPIDetailsGraficoA3ResponseDto
 */
export interface ResponseOfListOfKPIDetailsGraficoA3ResponseDto {
    /**
     * 
     * @type {Array<KPIDetailsGraficoA3ResponseDto>}
     * @memberof ResponseOfListOfKPIDetailsGraficoA3ResponseDto
     */
    'value'?: Array<KPIDetailsGraficoA3ResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfKPIDetailsPastYearsResponseDto
 */
export interface ResponseOfListOfKPIDetailsPastYearsResponseDto {
    /**
     * 
     * @type {Array<KPIDetailsPastYearsResponseDto>}
     * @memberof ResponseOfListOfKPIDetailsPastYearsResponseDto
     */
    'value'?: Array<KPIDetailsPastYearsResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfKPIPicklistResponseDto
 */
export interface ResponseOfListOfKPIPicklistResponseDto {
    /**
     * 
     * @type {Array<KPIPicklistResponseDto>}
     * @memberof ResponseOfListOfKPIPicklistResponseDto
     */
    'value'?: Array<KPIPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfLongTermGoalPicklistResponseDto
 */
export interface ResponseOfListOfLongTermGoalPicklistResponseDto {
    /**
     * 
     * @type {Array<LongTermGoalPicklistResponseDto>}
     * @memberof ResponseOfListOfLongTermGoalPicklistResponseDto
     */
    'value'?: Array<LongTermGoalPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfLongTermObjectiveDto
 */
export interface ResponseOfListOfLongTermObjectiveDto {
    /**
     * 
     * @type {Array<LongTermObjectiveDto>}
     * @memberof ResponseOfListOfLongTermObjectiveDto
     */
    'value'?: Array<LongTermObjectiveDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfObjectCodePicklistResponseDto
 */
export interface ResponseOfListOfObjectCodePicklistResponseDto {
    /**
     * 
     * @type {Array<ObjectCodePicklistResponseDto>}
     * @memberof ResponseOfListOfObjectCodePicklistResponseDto
     */
    'value'?: Array<ObjectCodePicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfProjectDashboards
 */
export interface ResponseOfListOfProjectDashboards {
    /**
     * 
     * @type {Array<ProjectDashboards>}
     * @memberof ResponseOfListOfProjectDashboards
     */
    'value'?: Array<ProjectDashboards> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfProjectDeliverableParent
 */
export interface ResponseOfListOfProjectDeliverableParent {
    /**
     * 
     * @type {Array<ProjectDeliverableParent>}
     * @memberof ResponseOfListOfProjectDeliverableParent
     */
    'value'?: Array<ProjectDeliverableParent> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfProjectRoutineResponseDto
 */
export interface ResponseOfListOfProjectRoutineResponseDto {
    /**
     * 
     * @type {Array<ProjectRoutineResponseDto>}
     * @memberof ResponseOfListOfProjectRoutineResponseDto
     */
    'value'?: Array<ProjectRoutineResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfProjectSummary
 */
export interface ResponseOfListOfProjectSummary {
    /**
     * 
     * @type {Array<ProjectSummary>}
     * @memberof ResponseOfListOfProjectSummary
     */
    'value'?: Array<ProjectSummary> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfStatusPicklistResponseDto
 */
export interface ResponseOfListOfStatusPicklistResponseDto {
    /**
     * 
     * @type {Array<StatusPicklistResponseDto>}
     * @memberof ResponseOfListOfStatusPicklistResponseDto
     */
    'value'?: Array<StatusPicklistResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfUpdateAnnualGoalResponse
 */
export interface ResponseOfListOfUpdateAnnualGoalResponse {
    /**
     * 
     * @type {Array<UpdateAnnualGoalResponse>}
     * @memberof ResponseOfListOfUpdateAnnualGoalResponse
     */
    'value'?: Array<UpdateAnnualGoalResponse> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfUserInfoResponseDto
 */
export interface ResponseOfListOfUserInfoResponseDto {
    /**
     * 
     * @type {Array<UserInfoResponseDto>}
     * @memberof ResponseOfListOfUserInfoResponseDto
     */
    'value'?: Array<UserInfoResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfListOfUserListPageResponseDto
 */
export interface ResponseOfListOfUserListPageResponseDto {
    /**
     * 
     * @type {Array<UserListPageResponseDto>}
     * @memberof ResponseOfListOfUserListPageResponseDto
     */
    'value'?: Array<UserListPageResponseDto> | null;
}
/**
 * 
 * @export
 * @interface ResponseOfLongTermObjectiveByIdResponse
 */
export interface ResponseOfLongTermObjectiveByIdResponse {
    /**
     * 
     * @type {ResponseOfLongTermObjectiveByIdResponseValue}
     * @memberof ResponseOfLongTermObjectiveByIdResponse
     */
    'value'?: ResponseOfLongTermObjectiveByIdResponseValue | null;
}
/**
 * @type ResponseOfLongTermObjectiveByIdResponseValue
 * @export
 */
export type ResponseOfLongTermObjectiveByIdResponseValue = LongTermObjectiveByIdResponse;

/**
 * 
 * @export
 * @interface ResponseOfObject
 */
export interface ResponseOfObject {
    /**
     * 
     * @type {any}
     * @memberof ResponseOfObject
     */
    'value'?: any | null;
}
/**
 * 
 * @export
 * @interface ResponseOfUpdateAnnualGoalKPIRelationShipResponseDto
 */
export interface ResponseOfUpdateAnnualGoalKPIRelationShipResponseDto {
    /**
     * 
     * @type {ResponseOfUpdateAnnualGoalKPIRelationShipResponseDtoValue}
     * @memberof ResponseOfUpdateAnnualGoalKPIRelationShipResponseDto
     */
    'value'?: ResponseOfUpdateAnnualGoalKPIRelationShipResponseDtoValue | null;
}
/**
 * @type ResponseOfUpdateAnnualGoalKPIRelationShipResponseDtoValue
 * @export
 */
export type ResponseOfUpdateAnnualGoalKPIRelationShipResponseDtoValue = UpdateAnnualGoalKPIRelationShipResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfUpdateDeliverableResponse
 */
export interface ResponseOfUpdateDeliverableResponse {
    /**
     * 
     * @type {ResponseOfUpdateDeliverableResponseValue}
     * @memberof ResponseOfUpdateDeliverableResponse
     */
    'value'?: ResponseOfUpdateDeliverableResponseValue | null;
}
/**
 * @type ResponseOfUpdateDeliverableResponseValue
 * @export
 */
export type ResponseOfUpdateDeliverableResponseValue = UpdateDeliverableResponse;

/**
 * 
 * @export
 * @interface ResponseOfUpdateLongTermGoalResponse
 */
export interface ResponseOfUpdateLongTermGoalResponse {
    /**
     * 
     * @type {ResponseOfUpdateLongTermGoalResponseValue}
     * @memberof ResponseOfUpdateLongTermGoalResponse
     */
    'value'?: ResponseOfUpdateLongTermGoalResponseValue | null;
}
/**
 * @type ResponseOfUpdateLongTermGoalResponseValue
 * @export
 */
export type ResponseOfUpdateLongTermGoalResponseValue = UpdateLongTermGoalResponse;

/**
 * 
 * @export
 * @interface ResponseOfUpdateMyActivityResponseDto
 */
export interface ResponseOfUpdateMyActivityResponseDto {
    /**
     * 
     * @type {ResponseOfUpdateMyActivityResponseDtoValue}
     * @memberof ResponseOfUpdateMyActivityResponseDto
     */
    'value'?: ResponseOfUpdateMyActivityResponseDtoValue | null;
}
/**
 * @type ResponseOfUpdateMyActivityResponseDtoValue
 * @export
 */
export type ResponseOfUpdateMyActivityResponseDtoValue = UpdateMyActivityResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfUpdateProjectSecondaryProjectRelationShipResponse
 */
export interface ResponseOfUpdateProjectSecondaryProjectRelationShipResponse {
    /**
     * 
     * @type {ResponseOfUpdateProjectSecondaryProjectRelationShipResponseValue}
     * @memberof ResponseOfUpdateProjectSecondaryProjectRelationShipResponse
     */
    'value'?: ResponseOfUpdateProjectSecondaryProjectRelationShipResponseValue | null;
}
/**
 * @type ResponseOfUpdateProjectSecondaryProjectRelationShipResponseValue
 * @export
 */
export type ResponseOfUpdateProjectSecondaryProjectRelationShipResponseValue = UpdateProjectSecondaryProjectRelationShipResponse;

/**
 * 
 * @export
 * @interface ResponseOfUserDetailsRespose
 */
export interface ResponseOfUserDetailsRespose {
    /**
     * 
     * @type {ResponseOfUserDetailsResposeValue}
     * @memberof ResponseOfUserDetailsRespose
     */
    'value'?: ResponseOfUserDetailsResposeValue | null;
}
/**
 * @type ResponseOfUserDetailsResposeValue
 * @export
 */
export type ResponseOfUserDetailsResposeValue = UserDetailsRespose;

/**
 * 
 * @export
 * @interface ResponseOfUserProfileResponseDto
 */
export interface ResponseOfUserProfileResponseDto {
    /**
     * 
     * @type {ResponseOfUserProfileResponseDtoValue}
     * @memberof ResponseOfUserProfileResponseDto
     */
    'value'?: ResponseOfUserProfileResponseDtoValue | null;
}
/**
 * @type ResponseOfUserProfileResponseDtoValue
 * @export
 */
export type ResponseOfUserProfileResponseDtoValue = UserProfileResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfXMatrixDataByIDResponseDto
 */
export interface ResponseOfXMatrixDataByIDResponseDto {
    /**
     * 
     * @type {ResponseOfXMatrixDataByIDResponseDtoValue}
     * @memberof ResponseOfXMatrixDataByIDResponseDto
     */
    'value'?: ResponseOfXMatrixDataByIDResponseDtoValue | null;
}
/**
 * @type ResponseOfXMatrixDataByIDResponseDtoValue
 * @export
 */
export type ResponseOfXMatrixDataByIDResponseDtoValue = XMatrixDataByIDResponseDto;

/**
 * 
 * @export
 * @interface ResponseOfXMatrixDto
 */
export interface ResponseOfXMatrixDto {
    /**
     * 
     * @type {ResponseOfXMatrixDtoValue}
     * @memberof ResponseOfXMatrixDto
     */
    'value'?: ResponseOfXMatrixDtoValue | null;
}
/**
 * @type ResponseOfXMatrixDtoValue
 * @export
 */
export type ResponseOfXMatrixDtoValue = XMatrixDto;

/**
 * 
 * @export
 * @interface ResponseOfXMatrixSecondLevelDto
 */
export interface ResponseOfXMatrixSecondLevelDto {
    /**
     * 
     * @type {ResponseOfXMatrixSecondLevelDtoValue}
     * @memberof ResponseOfXMatrixSecondLevelDto
     */
    'value'?: ResponseOfXMatrixSecondLevelDtoValue | null;
}
/**
 * @type ResponseOfXMatrixSecondLevelDtoValue
 * @export
 */
export type ResponseOfXMatrixSecondLevelDtoValue = XMatrixSecondLevelDto;

/**
 * 
 * @export
 * @interface StatusPicklistResponseDto
 */
export interface StatusPicklistResponseDto {
    /**
     * 
     * @type {number}
     * @memberof StatusPicklistResponseDto
     */
    'statusID'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusPicklistResponseDto
     */
    'statusDescription'?: string | null;
}
/**
 * 
 * @export
 * @interface StatusResponnse
 */
export interface StatusResponnse {
    /**
     * 
     * @type {number}
     * @memberof StatusResponnse
     */
    'projectStatus'?: number;
    /**
     * 
     * @type {string}
     * @memberof StatusResponnse
     */
    'projectStatusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof StatusResponnse
     */
    'projectNumber'?: number | null;
}
/**
 * 
 * @export
 * @interface SwapTeamMembersActivitiesRequest
 */
export interface SwapTeamMembersActivitiesRequest {
    /**
     * 
     * @type {string}
     * @memberof SwapTeamMembersActivitiesRequest
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof SwapTeamMembersActivitiesRequest
     */
    'teamMemberIn'?: string;
    /**
     * 
     * @type {string}
     * @memberof SwapTeamMembersActivitiesRequest
     */
    'teamMemberOut'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAlertConfigurationRequestDto
 */
export interface UpdateAlertConfigurationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'alertID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'companyID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'alertOn'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'allInOne'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'reminderInDaysFrequence'?: number;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'sendViaMail'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'sendViaNotificationBell'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'periodo'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'lastEmailSent'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAlertConfigurationRequestDto
     */
    'lastAlertSent'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalKPIRelationShipRequestDto
 */
export interface UpdateAnnualGoalKPIRelationShipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalKPIRelationShipRequestDto
     */
    'annualGoalID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalKPIRelationShipRequestDto
     */
    'kpiid'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnualGoalKPIRelationShipRequestDto
     */
    'correlationCoefficient'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalKPIRelationShipRequestDto
     */
    'xMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalKPIRelationShipResponseDto
 */
export interface UpdateAnnualGoalKPIRelationShipResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalKPIRelationShipResponseDto
     */
    'kpiName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalRequestDto
 */
export interface UpdateAnnualGoalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalRequestDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnualGoalRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalRequestDto
     */
    'referencePeriod'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalRequestDto
     */
    'owner'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalResponse
 */
export interface UpdateAnnualGoalResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalResponse
     */
    'ownerFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalResponse
     */
    'status'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalResponse
     */
    'longTermGoalID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateAnnualGoalXRequestDto
 */
export interface UpdateAnnualGoalXRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalXRequestDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualGoalXRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnualGoalXRequestDto
     */
    'status'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateAnnualOrLongTermGoalRequestDto
 */
export interface UpdateAnnualOrLongTermGoalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualOrLongTermGoalRequestDto
     */
    'itemID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateAnnualOrLongTermGoalRequestDto
     */
    'nameOrNote'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateAnnualOrLongTermGoalRequestDto
     */
    'updateCase'?: number;
}
/**
 * 
 * @export
 * @interface UpdateCommentForKPIRequestDto
 */
export interface UpdateCommentForKPIRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentForKPIRequestDto
     */
    'kpiDetailID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentForKPIRequestDto
     */
    'objectTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentForKPIRequestDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommentForKPIRequestDto
     */
    'type'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCommitteeCommentRequestDto
 */
export interface UpdateCommitteeCommentRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCommitteeCommentRequestDto
     */
    'committeeID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommitteeCommentRequestDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommitteeCommentRequestDto
     */
    'commentTitle'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateCommitteeRequestDto
 */
export interface UpdateCommitteeRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCommitteeRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommitteeRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCommitteeRequestDto
     */
    'teamMember'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateCommitteeRequestDto
     */
    'revisoriMembers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCommitteeRequestDto
     */
    'repositoryLink'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCommitteeRequestDto
     */
    'activitiesDuration'?: number;
}
/**
 * 
 * @export
 * @interface UpdateCompanyRequestDto
 */
export interface UpdateCompanyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequestDto
     */
    'companyID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequestDto
     */
    'activationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequestDto
     */
    'deactivationDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateCompanyRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateCompanyRequestDto
     */
    'licenseType'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateCompanyRequestDto
     */
    'deactivateLicense'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateDeliverableRequestDto
 */
export interface UpdateDeliverableRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliverableRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliverableRequestDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateDeliverableRequestDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'rescheduledEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'actualEndDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'genericResponsible'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDeliverableRequestDto
     */
    'isGenericResponsabile'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableRequestDto
     */
    'ownerName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateDeliverableRequestDto
     */
    'updateParentEndDate'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateDeliverableResponse
 */
export interface UpdateDeliverableResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateDeliverableResponse
     */
    'fullName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateFeedActivityRequestDto
 */
export interface UpdateFeedActivityRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'activityID'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedActivityRequestDto
     */
    'activityType'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedActivityRequestDto
     */
    'priority'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'documentURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'scheduledStart'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'scheduledEnd'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateFeedActivityRequestDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeedActivityRequestDto
     */
    'integrateInOutlook'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateFeedActivityRequestDto
     */
    'integrateInTeams'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateFeedActivityRequestDto
     */
    'timezone'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateGlobalKPIBowlingChartImportRequestDto
 */
export interface UpdateGlobalKPIBowlingChartImportRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGlobalKPIBowlingChartImportRequestDto
     */
    'kpiDetailID'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalKPIBowlingChartImportRequestDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGlobalKPIBowlingChartImportRequestDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGlobalKPIBowlingChartImportRequestDto
     */
    'referencePeriod'?: string;
}
/**
 * 
 * @export
 * @interface UpdateGlobalKPIBowlingChartRequestDto
 */
export interface UpdateGlobalKPIBowlingChartRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'tableData'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'finalTarget'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateGlobalKPIBowlingChartRequestDto
     */
    'freezeTargetValues'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateGoalCategoryRequestDto
 */
export interface UpdateGoalCategoryRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalCategoryRequestDto
     */
    'goalCategoryID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalCategoryRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalCategoryRequestDto
     */
    'goalCategoryIconID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateGoalGroundActA3RequestDto
 */
export interface UpdateGoalGroundActA3RequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalGroundActA3RequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalGroundActA3RequestDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalGroundActA3RequestDto
     */
    'groundToAct'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateGoalKPIRelationShipRequestDto
 */
export interface UpdateGoalKPIRelationShipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalKPIRelationShipRequestDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalKPIRelationShipRequestDto
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGoalKPIRelationShipRequestDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalKPIRelationShipRequestDto
     */
    'xMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface UpdateGoalProjectRelationShipDto
 */
export interface UpdateGoalProjectRelationShipDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalProjectRelationShipDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalProjectRelationShipDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateGoalProjectRelationShipDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateGoalProjectRelationShipDto
     */
    'xMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface UpdateImplementationSectionA3RequestDto
 */
export interface UpdateImplementationSectionA3RequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateImplementationSectionA3RequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateImplementationSectionA3RequestDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateImplementationSectionA3RequestDto
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateKPIAnnualGoalBowlingChartRequestDto
 */
export interface UpdateKPIAnnualGoalBowlingChartRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'annualGoalKPIRelationShipID'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'changedList'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'finalTarget'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'freezeTargetValues'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIAnnualGoalBowlingChartRequestDto
     */
    'responsibleID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateKPIBowlingChartRequestDto
 */
export interface UpdateKPIBowlingChartRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'kpiProjectRelationShipID'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'tableData'?: Array<string> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'finalTarget'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'showInA3'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'freezeTargetValues'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIBowlingChartRequestDto
     */
    'responsibleID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateKPIDetailRequestDto
 */
export interface UpdateKPIDetailRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIDetailRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIDetailRequestDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIDetailRequestDto
     */
    'additionalNote'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIDetailRequestDto
     */
    'calculationType'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIDetailRequestDto
     */
    'kpiDetailID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIDetailRequestDto
     */
    'jumpOffPoint'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIDetailRequestDto
     */
    'actualYTD'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIDetailRequestDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIDetailRequestDto
     */
    'parentType'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIDetailRequestDto
     */
    'isRollupKPI'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIDetailRequestDto
     */
    'responsibleID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateKPIDetailRequestDto
     */
    'isVisible'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UpdateKPIProjectRelationShipRequestDto
 */
export interface UpdateKPIProjectRelationShipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIProjectRelationShipRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIProjectRelationShipRequestDto
     */
    'kpiid'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateKPIProjectRelationShipRequestDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateKPIProjectRelationShipRequestDto
     */
    'xMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface UpdateLongTermAnnualGoalRelationShipDto
 */
export interface UpdateLongTermAnnualGoalRelationShipDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermAnnualGoalRelationShipDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermAnnualGoalRelationShipDto
     */
    'annualGoalID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLongTermAnnualGoalRelationShipDto
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermAnnualGoalRelationShipDto
     */
    'xMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface UpdateLongTermGoalChecklistRequestDto
 */
export interface UpdateLongTermGoalChecklistRequestDto {
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'miglioramentoSignificativo'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'raggiungimentoVision'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'strategiaSostenibile'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'innovazioneBusiness'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'vincoliNormative'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalChecklistRequestDto
     */
    'xMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface UpdateLongTermGoalRequestDto
 */
export interface UpdateLongTermGoalRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'goalTypeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'isRelatedVMV'?: boolean | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLongTermGoalRequestDto
     */
    'goalCategories'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateLongTermGoalResponse
 */
export interface UpdateLongTermGoalResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalResponse
     */
    'sponsorFullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalResponse
     */
    'status'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateLongTermGoalResponse
     */
    'annualGoalID'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateLongTermGoalXRequestDto
 */
export interface UpdateLongTermGoalXRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalXRequestDto
     */
    'longTermGoalID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateLongTermGoalXRequestDto
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateLongTermGoalXRequestDto
     */
    'status'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateMacroProjectRequest
 */
export interface UpdateMacroProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateMacroProjectRequest
     */
    'macroProjectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMacroProjectRequest
     */
    'macroProjectCode'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateMacroProjectRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateMacroProjectRequest
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface UpdateMyActivityRequestDto
 */
export interface UpdateMyActivityRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'activityID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'subject'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateMyActivityRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityRequestDto
     */
    'ownerID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateMyActivityResponseDto
 */
export interface UpdateMyActivityResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateMyActivityResponseDto
     */
    'activityID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateNextStepsSectionA3RequestDto
 */
export interface UpdateNextStepsSectionA3RequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateNextStepsSectionA3RequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateNextStepsSectionA3RequestDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateNextStepsSectionA3RequestDto
     */
    'comment'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateOnCallPropertyRequestDto
 */
export interface UpdateOnCallPropertyRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateOnCallPropertyRequestDto
     */
    'userID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateOnCallPropertyRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateOnCallPropertyRequestDto
     */
    'onCall'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdatePlaningProjectRequest
 */
export interface UpdatePlaningProjectRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdatePlaningProjectRequest
     */
    'id'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdatePlaningProjectRequest
     */
    'type'?: string | null;
    /**
     * 
     * @type {Array<MonthBudget>}
     * @memberof UpdatePlaningProjectRequest
     */
    'months'?: Array<MonthBudget> | null;
}
/**
 * 
 * @export
 * @interface UpdatePrecautionRequestModel
 */
export interface UpdatePrecautionRequestModel {
    /**
     * 
     * @type {string}
     * @memberof UpdatePrecautionRequestModel
     */
    'precautionDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdatePrecautionRequestModel
     */
    'precautionID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectCostDetailRequestDto
 */
export interface UpdateProjectCostDetailRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectCostDetailRequestDto
     */
    'projectCostDetailID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectCostDetailRequestDto
     */
    'ownerID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectCostDetailRequestDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectCostDetailRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectCostDetailRequestDto
     */
    'deliverableList'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectCostRequest
 */
export interface UpdateProjectCostRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectCostRequest
     */
    'projectCostDetailID'?: string;
    /**
     * 
     * @type {Array<MonthsListObj>}
     * @memberof UpdateProjectCostRequest
     */
    'months'?: Array<MonthsListObj> | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectCostRequest
     */
    'type'?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectCostRequest
     */
    'deliverableList'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectDeliverableRequestDto
 */
export interface UpdateProjectDeliverableRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'parentDeliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'endDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'startDate'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'status'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'tag'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'notes'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'priority'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'owner'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'deliverableID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'rescheduledEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'actualEndDate'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectDeliverableRequestDto
     */
    'projectID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectFieldsRequestDto
 */
export interface UpdateProjectFieldsRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectFieldsRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectFieldsRequestDto
     */
    'projectFieldName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectFieldsRequestDto
     */
    'projectFieldValue'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectRequestDto
 */
export interface UpdateProjectRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectRequestDto
     */
    'hasExternalReview'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRequestDto
     */
    'budget'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'projectGoal'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'groundtoAct'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'nextStepsNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'obtainedBenefit'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'finishedActivitiesNote'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'hanseiLearnedLessons'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'parentProjectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRequestDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectRequestDto
     */
    'fastCreation'?: boolean | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRequestDto
     */
    'masterplanDuration'?: number | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectRoutineRequestDto
 */
export interface UpdateProjectRoutineRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'startDate'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'endDate'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'schedule'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'duration'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'who'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'projectRoutineID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'startTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'endTime'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'integrateInOutlook'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'integrateInTeams'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'outlookEventId'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'frequenceType'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'frequence'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'dayOfWeek'?: number | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'extraUsers'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectRoutineRequestDto
     */
    'timezone'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectSecondaryProjectRelationShipRequestDto
 */
export interface UpdateProjectSecondaryProjectRelationShipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectSecondaryProjectRelationShipRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectSecondaryProjectRelationShipRequestDto
     */
    'secondaryProjectID'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectSecondaryProjectRelationShipRequestDto
     */
    'correlationCoefficient'?: number;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectSecondaryProjectRelationShipRequestDto
     */
    'xMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProjectSecondaryProjectRelationShipResponse
 */
export interface UpdateProjectSecondaryProjectRelationShipResponse {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectSecondaryProjectRelationShipResponse
     */
    'projectName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateProjectTotalCostRequest
 */
export interface UpdateProjectTotalCostRequest {
    /**
     * 
     * @type {Array<MonthsList>}
     * @memberof UpdateProjectTotalCostRequest
     */
    'months'?: Array<MonthsList> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectTotalCostRequest
     */
    'projectID'?: string;
}
/**
 * 
 * @export
 * @interface UpdateProjectUserRelationShipRequestDto
 */
export interface UpdateProjectUserRelationShipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectUserRelationShipRequestDto
     */
    'projectID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectUserRelationShipRequestDto
     */
    'userID'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectUserRelationShipRequestDto
     */
    'correlationCoefficient'?: number;
}
/**
 * 
 * @export
 * @interface UpdateProjectXMatrixRequestDto
 */
export interface UpdateProjectXMatrixRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'projectID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'projectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'teamLeaderID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'sponsorID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'committeeID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'hasExternalReview'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'note'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateProjectXMatrixRequestDto
     */
    'xMatrixID'?: string;
}
/**
 * 
 * @export
 * @interface UpdateRiskRequestDto
 */
export interface UpdateRiskRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateRiskRequestDto
     */
    'riskID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRiskRequestDto
     */
    'riskDescription'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateRiskRequestDto
     */
    'riskComment'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateRiskRequestDto
     */
    'status'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UpdateRiskRequestDto
     */
    'severity'?: number | null;
    /**
     * 
     * @type {Array<UpdatePrecautionRequestModel>}
     * @memberof UpdateRiskRequestDto
     */
    'precautions'?: Array<UpdatePrecautionRequestModel> | null;
}
/**
 * 
 * @export
 * @interface UpdateSecondLevelMacroProjectsRelationshipRequestDto
 */
export interface UpdateSecondLevelMacroProjectsRelationshipRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSecondLevelMacroProjectsRelationshipRequestDto
     */
    'secondLevelXmatrixID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateSecondLevelMacroProjectsRelationshipRequestDto
     */
    'macroProjectID'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSecondLevelMacroProjectsRelationshipRequestDto
     */
    'correlationCoefficient'?: number;
}
/**
 * 
 * @export
 * @interface UpdateSpecificAnnualGoalKPIBowlingRequestDto
 */
export interface UpdateSpecificAnnualGoalKPIBowlingRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificAnnualGoalKPIBowlingRequestDto
     */
    'annualGoalKPIRelationshipId'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSpecificAnnualGoalKPIBowlingRequestDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificAnnualGoalKPIBowlingRequestDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificAnnualGoalKPIBowlingRequestDto
     */
    'referencePeriod'?: string;
}
/**
 * 
 * @export
 * @interface UpdateSpecificKPIBowlingChartImportRequestDto
 */
export interface UpdateSpecificKPIBowlingChartImportRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificKPIBowlingChartImportRequestDto
     */
    'kpiProjectRelationShipID'?: string;
    /**
     * 
     * @type {number}
     * @memberof UpdateSpecificKPIBowlingChartImportRequestDto
     */
    'actual'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificKPIBowlingChartImportRequestDto
     */
    'unitOfMeasure'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateSpecificKPIBowlingChartImportRequestDto
     */
    'referencePeriod'?: string;
}
/**
 * 
 * @export
 * @interface UpdateTeamMembersRequest
 */
export interface UpdateTeamMembersRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateTeamMembersRequest
     */
    'projectID'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateTeamMembersRequest
     */
    'userIds'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface UpdateUserCompanyRequest
 */
export interface UpdateUserCompanyRequest {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCompanyRequest
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserCompanyRequest
     */
    'companyID'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateUserPlanning
 */
export interface UpdateUserPlanning {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserPlanning
     */
    'userID'?: string;
    /**
     * 
     * @type {Array<Month>}
     * @memberof UpdateUserPlanning
     */
    'months'?: Array<Month> | null;
}
/**
 * 
 * @export
 * @interface UpdateUserTimePlanning
 */
export interface UpdateUserTimePlanning {
    /**
     * 
     * @type {string}
     * @memberof UpdateUserTimePlanning
     */
    'userID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateUserTimePlanning
     */
    'projectID'?: string;
    /**
     * 
     * @type {Array<Month>}
     * @memberof UpdateUserTimePlanning
     */
    'months'?: Array<Month> | null;
}
/**
 * 
 * @export
 * @interface UpdateXMatrixByIDRequestDto
 */
export interface UpdateXMatrixByIDRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'xMatrixID'?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'projectIDList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'annualGoalIDList'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'longTermGoalIDList'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'xMatrixCycleID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixByIDRequestDto
     */
    'xMatrixName'?: string | null;
}
/**
 * 
 * @export
 * @interface UpdateXMatrixCycleRequestDto
 */
export interface UpdateXMatrixCycleRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixCycleRequestDto
     */
    'xMatrixCycleID'?: string;
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixCycleRequestDto
     */
    'companyID'?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UpdateXMatrixCycleRequestDto
     */
    'isDeleted'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UpdateXMatrixCycleRequestDto
     */
    'cycleName'?: string | null;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'initials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'company'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    'onCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    'userID'?: string | null;
}
/**
 * 
 * @export
 * @interface UserCompaniesResponseDto
 */
export interface UserCompaniesResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UserCompaniesResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCompaniesResponseDto
     */
    'companyID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCompaniesResponseDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCompaniesResponseDto
     */
    'companyShortName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserCompaniesResponseDto
     */
    'lastLoggedInCompany'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserCompaniesResponseDto
     */
    'isLastLoggedInCompany'?: number | null;
}
/**
 * 
 * @export
 * @interface UserDetailsRespose
 */
export interface UserDetailsRespose {
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'companyId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'userId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'roleCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'roleId'?: string;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'userInitials'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserDetailsRespose
     */
    'isLastLoggedInCompany'?: number;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'lastLoggedInCompanyID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDetailsRespose
     */
    'lastLoggedInCompanyName'?: string | null;
    /**
     * 
     * @type {Array<UserCompaniesResponseDto>}
     * @memberof UserDetailsRespose
     */
    'userCompanies'?: Array<UserCompaniesResponseDto> | null;
}
/**
 * 
 * @export
 * @interface UserInfoResponseDto
 */
export interface UserInfoResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserInfoResponseDto
     */
    'onCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInfoResponseDto
     */
    'roleID'?: string | null;
}
/**
 * 
 * @export
 * @interface UserInvitationRequestDto
 */
export interface UserInvitationRequestDto {
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'companyName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'roleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'surname'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserInvitationRequestDto
     */
    'userType'?: string | null;
}
/**
 * 
 * @export
 * @interface UserListPageResponseDto
 */
export interface UserListPageResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserListPageResponseDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'userType'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'userInitials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'role'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserListPageResponseDto
     */
    'availability'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserListPageResponseDto
     */
    'dailyRate'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserListPageResponseDto
     */
    'roleCode'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListPageResponseDto
     */
    'isDisabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserListPageResponseDto
     */
    'isDeleted'?: boolean;
}
/**
 * 
 * @export
 * @interface UserProfileResponseDto
 */
export interface UserProfileResponseDto {
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'phoneNumber'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'company'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'userInitials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'divisionID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'divisionName'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProfileResponseDto
     */
    'dailyRate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProfileResponseDto
     */
    'availability'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'userID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'surname'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProfileResponseDto
     */
    'type'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProfileResponseDto
     */
    'roleID'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileResponseDto
     */
    'isDisabled'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof UserProfileResponseDto
     */
    'isDeleted'?: boolean | null;
}
/**
 * 
 * @export
 * @interface UserProject
 */
export interface UserProject {
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'fullName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'jobTitle'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'department'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'emailAddress'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'profilePhoto'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'initials'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'role'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'company'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProject
     */
    'onCall'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProject
     */
    'userID'?: string | null;
}
/**
 * 
 * @export
 * @interface UserProjectRelationship
 */
export interface UserProjectRelationship {
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationship
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationship
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationship
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationship
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationship
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface UserProjectRelationships
 */
export interface UserProjectRelationships {
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationships
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationships
     */
    'correlationCoefficient'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'highestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'lowestIDValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'relationshipID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'highestNameValue'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'lowestNameValue'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationships
     */
    'highestNumberValue'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof UserProjectRelationships
     */
    'lowestNumberValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'highestObjectCode'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof UserProjectRelationships
     */
    'lowestObjectCode'?: string | null;
}
/**
 * 
 * @export
 * @interface Users
 */
export interface Users {
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'xMatrixID'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'title'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'area'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'status'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'statusDescription'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof Users
     */
    'orderingValue'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'note'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof Users
     */
    'userID'?: string | null;
}
/**
 * 
 * @export
 * @interface UsersObject
 */
export interface UsersObject {
    /**
     * 
     * @type {string}
     * @memberof UsersObject
     */
    'userID'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof UsersObject
     */
    'participationTypeMask'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof UsersObject
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface XMatrixDataByIDResponseDto
 */
export interface XMatrixDataByIDResponseDto {
    /**
     * 
     * @type {string}
     * @memberof XMatrixDataByIDResponseDto
     */
    'xMatrixName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XMatrixDataByIDResponseDto
     */
    'cycleName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof XMatrixDataByIDResponseDto
     */
    'xMatrixCycleID'?: string | null;
    /**
     * 
     * @type {XMatrixDataByIDResponseDtoProjectData}
     * @memberof XMatrixDataByIDResponseDto
     */
    'projectData'?: XMatrixDataByIDResponseDtoProjectData | null;
    /**
     * 
     * @type {XMatrixDataByIDResponseDtoAnnualObjData}
     * @memberof XMatrixDataByIDResponseDto
     */
    'annualObjData'?: XMatrixDataByIDResponseDtoAnnualObjData | null;
    /**
     * 
     * @type {XMatrixDataByIDResponseDtoLongTermGoalData}
     * @memberof XMatrixDataByIDResponseDto
     */
    'longTermGoalData'?: XMatrixDataByIDResponseDtoLongTermGoalData | null;
}
/**
 * @type XMatrixDataByIDResponseDtoAnnualObjData
 * @export
 */
export type XMatrixDataByIDResponseDtoAnnualObjData = AnnualObjData;

/**
 * @type XMatrixDataByIDResponseDtoLongTermGoalData
 * @export
 */
export type XMatrixDataByIDResponseDtoLongTermGoalData = LongTermGoalData;

/**
 * @type XMatrixDataByIDResponseDtoProjectData
 * @export
 */
export type XMatrixDataByIDResponseDtoProjectData = ProjectData;

/**
 * 
 * @export
 * @interface XMatrixDto
 */
export interface XMatrixDto {
    /**
     * 
     * @type {Array<Project>}
     * @memberof XMatrixDto
     */
    'projects'?: Array<Project> | null;
    /**
     * 
     * @type {Array<LongTermGoal>}
     * @memberof XMatrixDto
     */
    'longTermGoals'?: Array<LongTermGoal> | null;
    /**
     * 
     * @type {Array<AnnualGoal>}
     * @memberof XMatrixDto
     */
    'annualGoals'?: Array<AnnualGoal> | null;
    /**
     * 
     * @type {Array<KPI>}
     * @memberof XMatrixDto
     */
    'kpIs'?: Array<KPI> | null;
    /**
     * 
     * @type {Array<KPIProjectRelationship>}
     * @memberof XMatrixDto
     */
    'kpiProjectRelationships'?: Array<KPIProjectRelationship> | null;
    /**
     * 
     * @type {Array<GoalProjectRelationship>}
     * @memberof XMatrixDto
     */
    'goalProjectRelationship'?: Array<GoalProjectRelationship> | null;
    /**
     * 
     * @type {Array<LongTermAnnualGoalRelationship>}
     * @memberof XMatrixDto
     */
    'longTermAnnualGoalRelationships'?: Array<LongTermAnnualGoalRelationship> | null;
    /**
     * 
     * @type {Array<GoalKPIRelationship>}
     * @memberof XMatrixDto
     */
    'goalKPIRelationship'?: Array<GoalKPIRelationship> | null;
    /**
     * 
     * @type {Array<Users>}
     * @memberof XMatrixDto
     */
    'users'?: Array<Users> | null;
    /**
     * 
     * @type {Array<UserProjectRelationship>}
     * @memberof XMatrixDto
     */
    'userProjectRelationship'?: Array<UserProjectRelationship> | null;
    /**
     * 
     * @type {Array<AnnualGoalKPIRelationship>}
     * @memberof XMatrixDto
     */
    'annualGoalKPIRelationship'?: Array<AnnualGoalKPIRelationship> | null;
    /**
     * 
     * @type {Array<ProjectChildRelationship>}
     * @memberof XMatrixDto
     */
    'projectChildProjectRelationship'?: Array<ProjectChildRelationship> | null;
    /**
     * 
     * @type {Array<ProjectChild>}
     * @memberof XMatrixDto
     */
    'projectChild'?: Array<ProjectChild> | null;
}
/**
 * 
 * @export
 * @interface XMatrixFilters
 */
export interface XMatrixFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'annualGoalId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'longTermGoalId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'sponsorId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'teamLeaderId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'divisionId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'committeeId'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixFilters
     */
    'projectID'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XMatrixFilters
     */
    'includeSubProject'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof XMatrixFilters
     */
    'showAllUsers'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XMatrixFilters
     */
    'xMatrixID'?: string | null;
}
/**
 * 
 * @export
 * @interface XMatrixSecondLevelDto
 */
export interface XMatrixSecondLevelDto {
    /**
     * 
     * @type {Array<Projects>}
     * @memberof XMatrixSecondLevelDto
     */
    'projects'?: Array<Projects> | null;
    /**
     * 
     * @type {Array<LongTermGoals>}
     * @memberof XMatrixSecondLevelDto
     */
    'longTermGoals'?: Array<LongTermGoals> | null;
    /**
     * 
     * @type {Array<AnnualGoals>}
     * @memberof XMatrixSecondLevelDto
     */
    'annualGoals'?: Array<AnnualGoals> | null;
    /**
     * 
     * @type {Array<KPIs>}
     * @memberof XMatrixSecondLevelDto
     */
    'kpIs'?: Array<KPIs> | null;
    /**
     * 
     * @type {Array<KPIProjectRelationships>}
     * @memberof XMatrixSecondLevelDto
     */
    'kpiProjectRelationships'?: Array<KPIProjectRelationships> | null;
    /**
     * 
     * @type {Array<GoalProjectRelationships>}
     * @memberof XMatrixSecondLevelDto
     */
    'goalProjectRelationship'?: Array<GoalProjectRelationships> | null;
    /**
     * 
     * @type {Array<Users>}
     * @memberof XMatrixSecondLevelDto
     */
    'users'?: Array<Users> | null;
    /**
     * 
     * @type {Array<UserProjectRelationships>}
     * @memberof XMatrixSecondLevelDto
     */
    'userProjectRelationship'?: Array<UserProjectRelationships> | null;
    /**
     * 
     * @type {Array<AnnualGoalKPIRelationships>}
     * @memberof XMatrixSecondLevelDto
     */
    'annualGoalKPIRelationship'?: Array<AnnualGoalKPIRelationships> | null;
    /**
     * 
     * @type {Array<ProjectChildRelationship>}
     * @memberof XMatrixSecondLevelDto
     */
    'projectChildProjectRelationship'?: Array<ProjectChildRelationship> | null;
    /**
     * 
     * @type {Array<ProjectChild>}
     * @memberof XMatrixSecondLevelDto
     */
    'projectChild'?: Array<ProjectChild> | null;
}
/**
 * 
 * @export
 * @interface XMatrixSecondLevelFilters
 */
export interface XMatrixSecondLevelFilters {
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'annualGoalID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'teamLeaderID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'sponsorID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'committeeID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'projectID'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof XMatrixSecondLevelFilters
     */
    'secondaryProject'?: Array<string> | null;
    /**
     * 
     * @type {boolean}
     * @memberof XMatrixSecondLevelFilters
     */
    'showAllUsers'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof XMatrixSecondLevelFilters
     */
    'includeSubProject'?: boolean;
    /**
     * 
     * @type {string}
     * @memberof XMatrixSecondLevelFilters
     */
    'xMatrixID'?: string | null;
}

/**
 * A3PageApi - axios parameter creator
 * @export
 */
export const A3PageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetFollowUpSectionA3: async (projectID?: string | null, kpiDetailID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetFollowUpSectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (kpiDetailID !== undefined) {
                localVarQueryParameter['kpiDetailID'] = kpiDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetImplementationSectionA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetImplementationSectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetKPIPicklistA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetKPIPicklistA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterPlanDuration] 
         * @param {number | null} [masterPlanStart] 
         * @param {boolean} [deliverable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterPlanDeliverable: async (projectID?: string, masterPlanDuration?: number, masterPlanStart?: number | null, deliverable?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetMasterPlanDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (masterPlanDuration !== undefined) {
                localVarQueryParameter['MasterPlanDuration'] = masterPlanDuration;
            }

            if (masterPlanStart !== undefined) {
                localVarQueryParameter['MasterPlanStart'] = masterPlanStart;
            }

            if (deliverable !== undefined) {
                localVarQueryParameter['Deliverable'] = deliverable;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [year] 
         * @param {boolean} [addOneYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterplanParentDeliverable: async (projectID?: string | null, year?: string | null, addOneYear?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetMasterplanParentDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (addOneYear !== undefined) {
                localVarQueryParameter['AddOneYear'] = addOneYear;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterplanDuration] 
         * @param {number | null} [masterplanStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterplanParentDeliverableTest: async (projectID?: string, masterplanDuration?: number, masterplanStart?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetMasterplanParentDeliverableTest`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (masterplanDuration !== undefined) {
                localVarQueryParameter['MasterplanDuration'] = masterplanDuration;
            }

            if (masterplanStart !== undefined) {
                localVarQueryParameter['MasterplanStart'] = masterplanStart;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMembersListA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetMembersListA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetNextStepsSectionA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetNextStepsSectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [implementationDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectCompletedActivitiesA3: async (projectID?: string | null, implementationDaysOfNotice?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetProjectCompletedActivitiesA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (implementationDaysOfNotice !== undefined) {
                localVarQueryParameter['implementationDaysOfNotice'] = implementationDaysOfNotice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [nextStepDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectDeliverableNextStepA3: async (projectID?: string | null, nextStepDaysOfNotice?: number | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetProjectDeliverableNextStepA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (nextStepDaysOfNotice !== undefined) {
                localVarQueryParameter['nextStepDaysOfNotice'] = nextStepDaysOfNotice;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectHeaderA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetProjectHeaderA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetRiskPrecautionA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GetRiskPrecautionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGoalGroundtoActA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/A3Page/GoalGroundtoActA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGoalGroundActA3RequestDto} updateGoalGroundActA3RequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateGoalGroundtoActA3: async (updateGoalGroundActA3RequestDto: UpdateGoalGroundActA3RequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGoalGroundActA3RequestDto' is not null or undefined
            assertParamExists('a3PageUpdateGoalGroundtoActA3', 'updateGoalGroundActA3RequestDto', updateGoalGroundActA3RequestDto)
            const localVarPath = `/api/A3Page/UpdateGoalGroundtoActA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGoalGroundActA3RequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateImplementationSectionA3RequestDto} updateImplementationSectionA3RequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateImplementationSectionA3: async (updateImplementationSectionA3RequestDto: UpdateImplementationSectionA3RequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateImplementationSectionA3RequestDto' is not null or undefined
            assertParamExists('a3PageUpdateImplementationSectionA3', 'updateImplementationSectionA3RequestDto', updateImplementationSectionA3RequestDto)
            const localVarPath = `/api/A3Page/UpdateImplementationSectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateImplementationSectionA3RequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateNextStepsSectionA3RequestDto} updateNextStepsSectionA3RequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateNextStepsSectionA3: async (updateNextStepsSectionA3RequestDto: UpdateNextStepsSectionA3RequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateNextStepsSectionA3RequestDto' is not null or undefined
            assertParamExists('a3PageUpdateNextStepsSectionA3', 'updateNextStepsSectionA3RequestDto', updateNextStepsSectionA3RequestDto)
            const localVarPath = `/api/A3Page/UpdateNextStepsSectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateNextStepsSectionA3RequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * A3PageApi - functional programming interface
 * @export
 */
export const A3PageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = A3PageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetFollowUpSectionA3(projectID?: string | null, kpiDetailID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetFollowUpSectionA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetFollowUpSectionA3(projectID, kpiDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetFollowUpSectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetImplementationSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetImplementationSectionA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetImplementationSectionA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetImplementationSectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetKPIPicklistA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIPicklistA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetKPIPicklistA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetKPIPicklistA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterPlanDuration] 
         * @param {number | null} [masterPlanStart] 
         * @param {boolean} [deliverable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetMasterPlanDeliverable(projectID?: string, masterPlanDuration?: number, masterPlanStart?: number | null, deliverable?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetMasterPlanDeliverableResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetMasterPlanDeliverable(projectID, masterPlanDuration, masterPlanStart, deliverable, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetMasterPlanDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [year] 
         * @param {boolean} [addOneYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetMasterplanParentDeliverable(projectID?: string | null, year?: string | null, addOneYear?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetMasterplanParentDeliverableResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetMasterplanParentDeliverable(projectID, year, addOneYear, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetMasterplanParentDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterplanDuration] 
         * @param {number | null} [masterplanStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetMasterplanParentDeliverableTest(projectID?: string, masterplanDuration?: number, masterplanStart?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetMasterplanParentDeliverableTestResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetMasterplanParentDeliverableTest(projectID, masterplanDuration, masterplanStart, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetMasterplanParentDeliverableTest']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetMembersListA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetMembersListA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetMembersListA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetMembersListA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetNextStepsSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetNextStepsSectionA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetNextStepsSectionA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetNextStepsSectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [implementationDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetProjectCompletedActivitiesA3(projectID?: string | null, implementationDaysOfNotice?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectCompletedActivitiesA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetProjectCompletedActivitiesA3(projectID, implementationDaysOfNotice, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetProjectCompletedActivitiesA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [nextStepDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetProjectDeliverableNextStepA3(projectID?: string | null, nextStepDaysOfNotice?: number | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectDeliverableNextStepA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetProjectDeliverableNextStepA3(projectID, nextStepDaysOfNotice, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetProjectDeliverableNextStepA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetProjectHeaderA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetProjectHeaderA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetProjectHeaderA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetProjectHeaderA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGetRiskPrecautionA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetRiskPrecautionA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGetRiskPrecautionA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGetRiskPrecautionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageGoalGroundtoActA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetGoalGroundtoActA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageGoalGroundtoActA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageGoalGroundtoActA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateGoalGroundActA3RequestDto} updateGoalGroundActA3RequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto: UpdateGoalGroundActA3RequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageUpdateGoalGroundtoActA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateImplementationSectionA3RequestDto} updateImplementationSectionA3RequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto: UpdateImplementationSectionA3RequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageUpdateImplementationSectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateNextStepsSectionA3RequestDto} updateNextStepsSectionA3RequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto: UpdateNextStepsSectionA3RequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['A3PageApi.a3PageUpdateNextStepsSectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * A3PageApi - factory interface
 * @export
 */
export const A3PageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = A3PageApiFp(configuration)
    return {
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetFollowUpSectionA3(projectID?: string | null, kpiDetailID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetFollowUpSectionA3ResponseDto> {
            return localVarFp.a3PageGetFollowUpSectionA3(projectID, kpiDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetImplementationSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetImplementationSectionA3ResponseDto> {
            return localVarFp.a3PageGetImplementationSectionA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetKPIPicklistA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIPicklistA3ResponseDto> {
            return localVarFp.a3PageGetKPIPicklistA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterPlanDuration] 
         * @param {number | null} [masterPlanStart] 
         * @param {boolean} [deliverable] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterPlanDeliverable(projectID?: string, masterPlanDuration?: number, masterPlanStart?: number | null, deliverable?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetMasterPlanDeliverableResponseDto> {
            return localVarFp.a3PageGetMasterPlanDeliverable(projectID, masterPlanDuration, masterPlanStart, deliverable, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [year] 
         * @param {boolean} [addOneYear] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterplanParentDeliverable(projectID?: string | null, year?: string | null, addOneYear?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetMasterplanParentDeliverableResponseDto> {
            return localVarFp.a3PageGetMasterplanParentDeliverable(projectID, year, addOneYear, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {number} [masterplanDuration] 
         * @param {number | null} [masterplanStart] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMasterplanParentDeliverableTest(projectID?: string, masterplanDuration?: number, masterplanStart?: number | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetMasterplanParentDeliverableTestResponseDto> {
            return localVarFp.a3PageGetMasterplanParentDeliverableTest(projectID, masterplanDuration, masterplanStart, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetMembersListA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetMembersListA3ResponseDto> {
            return localVarFp.a3PageGetMembersListA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetNextStepsSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetNextStepsSectionA3ResponseDto> {
            return localVarFp.a3PageGetNextStepsSectionA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [implementationDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectCompletedActivitiesA3(projectID?: string | null, implementationDaysOfNotice?: number | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectCompletedActivitiesA3ResponseDto> {
            return localVarFp.a3PageGetProjectCompletedActivitiesA3(projectID, implementationDaysOfNotice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {number | null} [nextStepDaysOfNotice] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectDeliverableNextStepA3(projectID?: string | null, nextStepDaysOfNotice?: number | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectDeliverableNextStepA3ResponseDto> {
            return localVarFp.a3PageGetProjectDeliverableNextStepA3(projectID, nextStepDaysOfNotice, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetProjectHeaderA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetProjectHeaderA3ResponseDto> {
            return localVarFp.a3PageGetProjectHeaderA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGetRiskPrecautionA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetRiskPrecautionA3ResponseDto> {
            return localVarFp.a3PageGetRiskPrecautionA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageGoalGroundtoActA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetGoalGroundtoActA3ResponseDto> {
            return localVarFp.a3PageGoalGroundtoActA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGoalGroundActA3RequestDto} updateGoalGroundActA3RequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto: UpdateGoalGroundActA3RequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateImplementationSectionA3RequestDto} updateImplementationSectionA3RequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto: UpdateImplementationSectionA3RequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateNextStepsSectionA3RequestDto} updateNextStepsSectionA3RequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto: UpdateNextStepsSectionA3RequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * A3PageApi - object-oriented interface
 * @export
 * @class A3PageApi
 * @extends {BaseAPI}
 */
export class A3PageApi extends BaseAPI {
    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string | null} [kpiDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetFollowUpSectionA3(projectID?: string | null, kpiDetailID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetFollowUpSectionA3(projectID, kpiDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetImplementationSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetImplementationSectionA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetKPIPicklistA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetKPIPicklistA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {number} [masterPlanDuration] 
     * @param {number | null} [masterPlanStart] 
     * @param {boolean} [deliverable] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetMasterPlanDeliverable(projectID?: string, masterPlanDuration?: number, masterPlanStart?: number | null, deliverable?: boolean, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetMasterPlanDeliverable(projectID, masterPlanDuration, masterPlanStart, deliverable, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string | null} [year] 
     * @param {boolean} [addOneYear] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetMasterplanParentDeliverable(projectID?: string | null, year?: string | null, addOneYear?: boolean, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetMasterplanParentDeliverable(projectID, year, addOneYear, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {number} [masterplanDuration] 
     * @param {number | null} [masterplanStart] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetMasterplanParentDeliverableTest(projectID?: string, masterplanDuration?: number, masterplanStart?: number | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetMasterplanParentDeliverableTest(projectID, masterplanDuration, masterplanStart, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetMembersListA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetMembersListA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetNextStepsSectionA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetNextStepsSectionA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {number | null} [implementationDaysOfNotice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetProjectCompletedActivitiesA3(projectID?: string | null, implementationDaysOfNotice?: number | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetProjectCompletedActivitiesA3(projectID, implementationDaysOfNotice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {number | null} [nextStepDaysOfNotice] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetProjectDeliverableNextStepA3(projectID?: string | null, nextStepDaysOfNotice?: number | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetProjectDeliverableNextStepA3(projectID, nextStepDaysOfNotice, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetProjectHeaderA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetProjectHeaderA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGetRiskPrecautionA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGetRiskPrecautionA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageGoalGroundtoActA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageGoalGroundtoActA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGoalGroundActA3RequestDto} updateGoalGroundActA3RequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto: UpdateGoalGroundActA3RequestDto, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageUpdateGoalGroundtoActA3(updateGoalGroundActA3RequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateImplementationSectionA3RequestDto} updateImplementationSectionA3RequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto: UpdateImplementationSectionA3RequestDto, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageUpdateImplementationSectionA3(updateImplementationSectionA3RequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateNextStepsSectionA3RequestDto} updateNextStepsSectionA3RequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof A3PageApi
     */
    public a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto: UpdateNextStepsSectionA3RequestDto, options?: RawAxiosRequestConfig) {
        return A3PageApiFp(this.configuration).a3PageUpdateNextStepsSectionA3(updateNextStepsSectionA3RequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ActivityApi - axios parameter creator
 * @export
 */
export const ActivityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityDeleteMyActivity: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('activityDeleteMyActivity', 'id', id)
            const localVarPath = `/api/Activity/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivity: async (activityID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetMyActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activityID !== undefined) {
                localVarQueryParameter['activityID'] = activityID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [deliverableID] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityDeliverableForm: async (deliverableID?: string | null, startDate?: string | null, endDate?: string | null, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetMyActivityDeliverableForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = endDate;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityMenuForm: async (startDate?: string | null, endDate?: string | null, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetMyActivityMenuForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = endDate;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityMenuFormPerUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetMyActivityMenuFormPerUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {string | null} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityProjectForm: async (projectID?: string | null, startDate?: string | null, endDate?: string | null, languageCode?: string | null, deliverableID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetMyActivityProjectForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = endDate;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetTeamLeaderActivityMenuForm: async (startDate?: string | null, endDate?: string | null, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Activity/GetTeamLeaderActivityMenuForm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (startDate !== undefined) {
                localVarQueryParameter['StartDate'] = startDate;
            }

            if (endDate !== undefined) {
                localVarQueryParameter['EndDate'] = endDate;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<InsertActivityPlanningImportRequestDto>} insertActivityPlanningImportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityInsertActivityPlanningImport: async (insertActivityPlanningImportRequestDto: Array<InsertActivityPlanningImportRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertActivityPlanningImportRequestDto' is not null or undefined
            assertParamExists('activityInsertActivityPlanningImport', 'insertActivityPlanningImportRequestDto', insertActivityPlanningImportRequestDto)
            const localVarPath = `/api/Activity/InsertActivityPlanningImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertActivityPlanningImportRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertMyActivityRequestDto} insertMyActivityRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityInsertMyActivity: async (insertMyActivityRequestDto: InsertMyActivityRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertMyActivityRequestDto' is not null or undefined
            assertParamExists('activityInsertMyActivity', 'insertMyActivityRequestDto', insertMyActivityRequestDto)
            const localVarPath = `/api/Activity/InsertMyActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertMyActivityRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMyActivityRequestDto} updateMyActivityRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityUpdateMyActivity: async (updateMyActivityRequestDto: UpdateMyActivityRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMyActivityRequestDto' is not null or undefined
            assertParamExists('activityUpdateMyActivity', 'updateMyActivityRequestDto', updateMyActivityRequestDto)
            const localVarPath = `/api/Activity/UpdateMyActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMyActivityRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ActivityApi - functional programming interface
 * @export
 */
export const ActivityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ActivityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityDeleteMyActivity(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityDeleteMyActivity(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityDeleteMyActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetMyActivity(activityID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetMyAcivityResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetMyActivity(activityID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetMyActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [deliverableID] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetMyActivityDeliverableForm(deliverableID?: string | null, startDate?: string | null, endDate?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetMyActivityDeliverableFormResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetMyActivityDeliverableForm(deliverableID, startDate, endDate, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetMyActivityDeliverableForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetMyActivityMenuForm(startDate?: string | null, endDate?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetMyActivityMenuFormResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetMyActivityMenuForm(startDate, endDate, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetMyActivityMenuForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetMyActivityMenuFormPerUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetMyActivityMenuFormPerUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetMyActivityMenuFormPerUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetMyActivityMenuFormPerUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {string | null} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetMyActivityProjectForm(projectID?: string | null, startDate?: string | null, endDate?: string | null, languageCode?: string | null, deliverableID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetMyActivityProjectFormrResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetMyActivityProjectForm(projectID, startDate, endDate, languageCode, deliverableID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetMyActivityProjectForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityGetTeamLeaderActivityMenuForm(startDate?: string | null, endDate?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetTeamLeaderActivityMenuFormResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityGetTeamLeaderActivityMenuForm(startDate, endDate, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityGetTeamLeaderActivityMenuForm']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<InsertActivityPlanningImportRequestDto>} insertActivityPlanningImportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto: Array<InsertActivityPlanningImportRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityInsertActivityPlanningImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertMyActivityRequestDto} insertMyActivityRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityInsertMyActivity(insertMyActivityRequestDto: InsertMyActivityRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertMyActivityResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityInsertMyActivity(insertMyActivityRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityInsertMyActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateMyActivityRequestDto} updateMyActivityRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async activityUpdateMyActivity(updateMyActivityRequestDto: UpdateMyActivityRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfUpdateMyActivityResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.activityUpdateMyActivity(updateMyActivityRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ActivityApi.activityUpdateMyActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ActivityApi - factory interface
 * @export
 */
export const ActivityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ActivityApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityDeleteMyActivity(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.activityDeleteMyActivity(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivity(activityID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetMyAcivityResponseDto> {
            return localVarFp.activityGetMyActivity(activityID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [deliverableID] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityDeliverableForm(deliverableID?: string | null, startDate?: string | null, endDate?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetMyActivityDeliverableFormResponseDto> {
            return localVarFp.activityGetMyActivityDeliverableForm(deliverableID, startDate, endDate, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityMenuForm(startDate?: string | null, endDate?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetMyActivityMenuFormResponseDto> {
            return localVarFp.activityGetMyActivityMenuForm(startDate, endDate, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityMenuFormPerUser(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetMyActivityMenuFormPerUserResponseDto> {
            return localVarFp.activityGetMyActivityMenuFormPerUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {string | null} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetMyActivityProjectForm(projectID?: string | null, startDate?: string | null, endDate?: string | null, languageCode?: string | null, deliverableID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetMyActivityProjectFormrResponseDto> {
            return localVarFp.activityGetMyActivityProjectForm(projectID, startDate, endDate, languageCode, deliverableID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [startDate] 
         * @param {string | null} [endDate] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityGetTeamLeaderActivityMenuForm(startDate?: string | null, endDate?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetTeamLeaderActivityMenuFormResponseDto> {
            return localVarFp.activityGetTeamLeaderActivityMenuForm(startDate, endDate, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<InsertActivityPlanningImportRequestDto>} insertActivityPlanningImportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto: Array<InsertActivityPlanningImportRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertMyActivityRequestDto} insertMyActivityRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityInsertMyActivity(insertMyActivityRequestDto: InsertMyActivityRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertMyActivityResponseDto> {
            return localVarFp.activityInsertMyActivity(insertMyActivityRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateMyActivityRequestDto} updateMyActivityRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        activityUpdateMyActivity(updateMyActivityRequestDto: UpdateMyActivityRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfUpdateMyActivityResponseDto> {
            return localVarFp.activityUpdateMyActivity(updateMyActivityRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ActivityApi - object-oriented interface
 * @export
 * @class ActivityApi
 * @extends {BaseAPI}
 */
export class ActivityApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityDeleteMyActivity(id: string, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityDeleteMyActivity(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [activityID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetMyActivity(activityID?: string | null, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetMyActivity(activityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [deliverableID] 
     * @param {string | null} [startDate] 
     * @param {string | null} [endDate] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetMyActivityDeliverableForm(deliverableID?: string | null, startDate?: string | null, endDate?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetMyActivityDeliverableForm(deliverableID, startDate, endDate, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [startDate] 
     * @param {string | null} [endDate] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetMyActivityMenuForm(startDate?: string | null, endDate?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetMyActivityMenuForm(startDate, endDate, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetMyActivityMenuFormPerUser(options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetMyActivityMenuFormPerUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string | null} [startDate] 
     * @param {string | null} [endDate] 
     * @param {string | null} [languageCode] 
     * @param {string | null} [deliverableID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetMyActivityProjectForm(projectID?: string | null, startDate?: string | null, endDate?: string | null, languageCode?: string | null, deliverableID?: string | null, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetMyActivityProjectForm(projectID, startDate, endDate, languageCode, deliverableID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [startDate] 
     * @param {string | null} [endDate] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityGetTeamLeaderActivityMenuForm(startDate?: string | null, endDate?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityGetTeamLeaderActivityMenuForm(startDate, endDate, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<InsertActivityPlanningImportRequestDto>} insertActivityPlanningImportRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto: Array<InsertActivityPlanningImportRequestDto>, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityInsertActivityPlanningImport(insertActivityPlanningImportRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertMyActivityRequestDto} insertMyActivityRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityInsertMyActivity(insertMyActivityRequestDto: InsertMyActivityRequestDto, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityInsertMyActivity(insertMyActivityRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateMyActivityRequestDto} updateMyActivityRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ActivityApi
     */
    public activityUpdateMyActivity(updateMyActivityRequestDto: UpdateMyActivityRequestDto, options?: RawAxiosRequestConfig) {
        return ActivityApiFp(this.configuration).activityUpdateMyActivity(updateMyActivityRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * AnnualGoalApi - axios parameter creator
 * @export
 */
export const AnnualGoalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalDeleteAnnuaGoal: async (annualGoalID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/DeleteAnnuaGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnnualGoalById: async (annualGoalID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/GetAnnualGoalById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (annualGoalID !== undefined) {
                localVarQueryParameter['annualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [hasAnnualGoalRelation] 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnnualGoals: async (hasAnnualGoalRelation?: boolean, xMatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/GetAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (hasAnnualGoalRelation !== undefined) {
                localVarQueryParameter['hasAnnualGoalRelation'] = hasAnnualGoalRelation;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [annualGoalID] 
         * @param {string | null} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnualGoalDetails: async (annualGoalID?: string | null, xMatrix?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/GetAnualGoalDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (annualGoalID !== undefined) {
                localVarQueryParameter['annualGoalID'] = annualGoalID;
            }

            if (xMatrix !== undefined) {
                localVarQueryParameter['xMatrix'] = xMatrix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnualGoalPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/AnnualGoal/GetAnualGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertAnnualGoalRequestDto} insertAnnualGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalInsertAnnualGoal: async (insertAnnualGoalRequestDto: InsertAnnualGoalRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertAnnualGoalRequestDto' is not null or undefined
            assertParamExists('annualGoalInsertAnnualGoal', 'insertAnnualGoalRequestDto', insertAnnualGoalRequestDto)
            const localVarPath = `/api/AnnualGoal/InsertAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertAnnualGoalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertAnnualGoalXRequestDto} insertAnnualGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalInsertAnnualGoalXMatrix: async (insertAnnualGoalXRequestDto: InsertAnnualGoalXRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertAnnualGoalXRequestDto' is not null or undefined
            assertParamExists('annualGoalInsertAnnualGoalXMatrix', 'insertAnnualGoalXRequestDto', insertAnnualGoalXRequestDto)
            const localVarPath = `/api/AnnualGoal/InsertAnnualGoalXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertAnnualGoalXRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAnnualGoalRequestDto} updateAnnualGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualGoal: async (updateAnnualGoalRequestDto: UpdateAnnualGoalRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAnnualGoalRequestDto' is not null or undefined
            assertParamExists('annualGoalUpdateAnnualGoal', 'updateAnnualGoalRequestDto', updateAnnualGoalRequestDto)
            const localVarPath = `/api/AnnualGoal/UpdateAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnualGoalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAnnualGoalXRequestDto} updateAnnualGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualGoalXMatrix: async (updateAnnualGoalXRequestDto: UpdateAnnualGoalXRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAnnualGoalXRequestDto' is not null or undefined
            assertParamExists('annualGoalUpdateAnnualGoalXMatrix', 'updateAnnualGoalXRequestDto', updateAnnualGoalXRequestDto)
            const localVarPath = `/api/AnnualGoal/UpdateAnnualGoalXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnualGoalXRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAnnualOrLongTermGoalRequestDto} updateAnnualOrLongTermGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualOrLongTermGoal: async (updateAnnualOrLongTermGoalRequestDto: UpdateAnnualOrLongTermGoalRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAnnualOrLongTermGoalRequestDto' is not null or undefined
            assertParamExists('annualGoalUpdateAnnualOrLongTermGoal', 'updateAnnualOrLongTermGoalRequestDto', updateAnnualOrLongTermGoalRequestDto)
            const localVarPath = `/api/AnnualGoal/UpdateAnnualOrLongTermGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnualOrLongTermGoalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AnnualGoalApi - functional programming interface
 * @export
 */
export const AnnualGoalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AnnualGoalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalDeleteAnnuaGoal(annualGoalID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalDeleteAnnuaGoal(annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalDeleteAnnuaGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalGetAnnualGoalById(annualGoalID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetAnnualGoalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalGetAnnualGoalById(annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalGetAnnualGoalById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [hasAnnualGoalRelation] 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalGetAnnualGoals(hasAnnualGoalRelation?: boolean, xMatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetAnnualGoalDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalGetAnnualGoals(hasAnnualGoalRelation, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalGetAnnualGoals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [annualGoalID] 
         * @param {string | null} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalGetAnualGoalDetails(annualGoalID?: string | null, xMatrix?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetAnualGoalDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalGetAnualGoalDetails(annualGoalID, xMatrix, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalGetAnualGoalDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalGetAnualGoalPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfAnnualGoalPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalGetAnualGoalPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalGetAnualGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertAnnualGoalRequestDto} insertAnnualGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto: InsertAnnualGoalRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalInsertAnnualGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertAnnualGoalXRequestDto} insertAnnualGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto: InsertAnnualGoalXRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalInsertAnnualGoalXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateAnnualGoalRequestDto} updateAnnualGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto: UpdateAnnualGoalRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfUpdateAnnualGoalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalUpdateAnnualGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateAnnualGoalXRequestDto} updateAnnualGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto: UpdateAnnualGoalXRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalUpdateAnnualGoalXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateAnnualOrLongTermGoalRequestDto} updateAnnualOrLongTermGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto: UpdateAnnualOrLongTermGoalRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AnnualGoalApi.annualGoalUpdateAnnualOrLongTermGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AnnualGoalApi - factory interface
 * @export
 */
export const AnnualGoalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AnnualGoalApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalDeleteAnnuaGoal(annualGoalID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.annualGoalDeleteAnnuaGoal(annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnnualGoalById(annualGoalID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetAnnualGoalDto> {
            return localVarFp.annualGoalGetAnnualGoalById(annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [hasAnnualGoalRelation] 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnnualGoals(hasAnnualGoalRelation?: boolean, xMatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetAnnualGoalDto> {
            return localVarFp.annualGoalGetAnnualGoals(hasAnnualGoalRelation, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [annualGoalID] 
         * @param {string | null} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnualGoalDetails(annualGoalID?: string | null, xMatrix?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetAnualGoalDetailsDto> {
            return localVarFp.annualGoalGetAnualGoalDetails(annualGoalID, xMatrix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalGetAnualGoalPicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfAnnualGoalPicklistResponseDto> {
            return localVarFp.annualGoalGetAnualGoalPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertAnnualGoalRequestDto} insertAnnualGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto: InsertAnnualGoalRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertAnnualGoalXRequestDto} insertAnnualGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto: InsertAnnualGoalXRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAnnualGoalRequestDto} updateAnnualGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto: UpdateAnnualGoalRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfUpdateAnnualGoalResponse> {
            return localVarFp.annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAnnualGoalXRequestDto} updateAnnualGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto: UpdateAnnualGoalXRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAnnualOrLongTermGoalRequestDto} updateAnnualOrLongTermGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto: UpdateAnnualOrLongTermGoalRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AnnualGoalApi - object-oriented interface
 * @export
 * @class AnnualGoalApi
 * @extends {BaseAPI}
 */
export class AnnualGoalApi extends BaseAPI {
    /**
     * 
     * @param {string} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalDeleteAnnuaGoal(annualGoalID?: string, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalDeleteAnnuaGoal(annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalGetAnnualGoalById(annualGoalID?: string | null, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalGetAnnualGoalById(annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [hasAnnualGoalRelation] 
     * @param {string | null} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalGetAnnualGoals(hasAnnualGoalRelation?: boolean, xMatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalGetAnnualGoals(hasAnnualGoalRelation, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [annualGoalID] 
     * @param {string | null} [xMatrix] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalGetAnualGoalDetails(annualGoalID?: string | null, xMatrix?: string | null, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalGetAnualGoalDetails(annualGoalID, xMatrix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalGetAnualGoalPicklist(options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalGetAnualGoalPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertAnnualGoalRequestDto} insertAnnualGoalRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto: InsertAnnualGoalRequestDto, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalInsertAnnualGoal(insertAnnualGoalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertAnnualGoalXRequestDto} insertAnnualGoalXRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto: InsertAnnualGoalXRequestDto, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalInsertAnnualGoalXMatrix(insertAnnualGoalXRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAnnualGoalRequestDto} updateAnnualGoalRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto: UpdateAnnualGoalRequestDto, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalUpdateAnnualGoal(updateAnnualGoalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAnnualGoalXRequestDto} updateAnnualGoalXRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto: UpdateAnnualGoalXRequestDto, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalUpdateAnnualGoalXMatrix(updateAnnualGoalXRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAnnualOrLongTermGoalRequestDto} updateAnnualOrLongTermGoalRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AnnualGoalApi
     */
    public annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto: UpdateAnnualOrLongTermGoalRequestDto, options?: RawAxiosRequestConfig) {
        return AnnualGoalApiFp(this.configuration).annualGoalUpdateAnnualOrLongTermGoal(updateAnnualOrLongTermGoalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CommonApi - axios parameter creator
 * @export
 */
export const CommonApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonAddCommitteeChildRelation: async (committeeID?: string, childID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/AddCommitteeChildRelation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (childID !== undefined) {
                localVarQueryParameter['ChildID'] = childID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AddRemoveCommitteeIDProjectRequestDto} addRemoveCommitteeIDProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonAddRemoveCommitteeIDProject: async (addRemoveCommitteeIDProjectRequestDto: AddRemoveCommitteeIDProjectRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'addRemoveCommitteeIDProjectRequestDto' is not null or undefined
            assertParamExists('commonAddRemoveCommitteeIDProject', 'addRemoveCommitteeIDProjectRequestDto', addRemoveCommitteeIDProjectRequestDto)
            const localVarPath = `/api/Common/AddRemoveCommitteeIDProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(addRemoveCommitteeIDProjectRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CheckObjectSecurityUserRequestDto} checkObjectSecurityUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonCheckObjectSecurityUser: async (checkObjectSecurityUserRequestDto: CheckObjectSecurityUserRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'checkObjectSecurityUserRequestDto' is not null or undefined
            assertParamExists('commonCheckObjectSecurityUser', 'checkObjectSecurityUserRequestDto', checkObjectSecurityUserRequestDto)
            const localVarPath = `/api/Common/CheckObjectSecurityUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(checkObjectSecurityUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [agendaID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteAgenda: async (agendaID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/DeleteAgenda`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (agendaID !== undefined) {
                localVarQueryParameter['AgendaID'] = agendaID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteCommittee: async (committeeID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/DeleteCommittee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteCommitteeChild: async (committeeID?: string, childID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/DeleteCommitteeChild`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (childID !== undefined) {
                localVarQueryParameter['ChildID'] = childID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteCommitteeSession: async (sessionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/DeleteCommitteeSession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sessionID !== undefined) {
                localVarQueryParameter['SessionID'] = sessionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAgenda: async (committeeID?: string | null, sessionID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetAgenda`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (sessionID !== undefined) {
                localVarQueryParameter['SessionID'] = sessionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAgendaCalendar: async (committeeID?: string, month?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetAgendaCalendar`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (month !== undefined) {
                localVarQueryParameter['Month'] = (month as any instanceof Date) ?
                    (month as any).toISOString() :
                    month;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAvailableChildCommittees: async (committeeID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetAvailableChildCommittees`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [committeeID] 
         * @param {boolean} [revisori] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAvailableCommitteeMembers: async (committeeID?: string | null, revisori?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetAvailableCommitteeMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (revisori !== undefined) {
                localVarQueryParameter['Revisori'] = revisori;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetChildCommittee: async (committeeID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetChildCommittee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [languageCode] 
         * @param {boolean} [closedOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeActivities: async (committeeID?: string, languageCode?: string | null, closedOnly?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeActivities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }

            if (closedOnly !== undefined) {
                localVarQueryParameter['closedOnly'] = closedOnly;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {string | null} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeAvailableProjects: async (committeeID?: string, sessionID?: string | null, xmatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeAvailableProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }

            if (sessionID !== undefined) {
                localVarQueryParameter['SessionID'] = sessionID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['XmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeByID: async (committeeID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommittee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [withSecurity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeListPage: async (withSecurity?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeListPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (withSecurity !== undefined) {
                localVarQueryParameter['WithSecurity'] = withSecurity;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteePicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeProjects: async (committeeID?: string, xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeProjects`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['XmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSessions: async (committeeID?: string, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeSessions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['committeeID'] = committeeID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetCommitteeSintesiListRequest} getCommitteeSintesiListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSintesiList: async (getCommitteeSintesiListRequest: GetCommitteeSintesiListRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getCommitteeSintesiListRequest' is not null or undefined
            assertParamExists('commonGetCommitteeSintesiList', 'getCommitteeSintesiListRequest', getCommitteeSintesiListRequest)
            const localVarPath = `/api/Common/GetCommitteeSintesiList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getCommitteeSintesiListRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummary: async (committeeID?: string, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummaryPerStatus: async (committeeID?: string, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeSummaryPerStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummaryPerTime: async (committeeID?: string, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetCommitteeSummaryPerTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetKPIAnnualGoalPicklist: async (annualGoalID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetKPIAnnualGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (annualGoalID !== undefined) {
                localVarQueryParameter['annualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionSponsorPicklist: async (divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetNewDivisionSponsorPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionTeamLeaderPicklist: async (divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetNewDivisionTeamLeaderPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionXMatrixSecondaryProjectPicklist: async (xmatrixID?: string, divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetNewDivisionXMatrixSecondaryProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNoCommitteeProject: async (xMatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetNoCommitteeProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [objectCode] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetObjectCodePicklist: async (objectCode?: string | null, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetObjectCodePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (objectCode !== undefined) {
                localVarQueryParameter['objectCode'] = objectCode;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [objectCode] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetStatusPicklist: async (objectCode?: string | null, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetStatusPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (objectCode !== undefined) {
                localVarQueryParameter['objectCode'] = objectCode;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetUserSelect: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/GetUserSelect`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertCommitteeRequestDto} insertCommitteeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertCommittee: async (insertCommitteeRequestDto: InsertCommitteeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertCommitteeRequestDto' is not null or undefined
            assertParamExists('commonInsertCommittee', 'insertCommitteeRequestDto', insertCommitteeRequestDto)
            const localVarPath = `/api/Common/InsertCommittee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertCommitteeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertUpdateAgendaRequestDto} insertUpdateAgendaRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertUpdateAgenda: async (insertUpdateAgendaRequestDto: InsertUpdateAgendaRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertUpdateAgendaRequestDto' is not null or undefined
            assertParamExists('commonInsertUpdateAgenda', 'insertUpdateAgendaRequestDto', insertUpdateAgendaRequestDto)
            const localVarPath = `/api/Common/InsertUpdateAgenda`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertUpdateAgendaRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertUpdateCommitteeSessionRequestDto} insertUpdateCommitteeSessionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertUpdateCommitteeSession: async (insertUpdateCommitteeSessionRequestDto: InsertUpdateCommitteeSessionRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertUpdateCommitteeSessionRequestDto' is not null or undefined
            assertParamExists('commonInsertUpdateCommitteeSession', 'insertUpdateCommitteeSessionRequestDto', insertUpdateCommitteeSessionRequestDto)
            const localVarPath = `/api/Common/InsertUpdateCommitteeSession`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertUpdateCommitteeSessionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<InsertUserCommitteeRequestDto>} insertUserCommitteeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertUserCommittee: async (insertUserCommitteeRequestDto: Array<InsertUserCommitteeRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertUserCommitteeRequestDto' is not null or undefined
            assertParamExists('commonInsertUserCommittee', 'insertUserCommitteeRequestDto', insertUserCommitteeRequestDto)
            const localVarPath = `/api/Common/InsertUserCommittee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertUserCommitteeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonSendEmail: async (committeeID?: string, sessionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Common/SendEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (committeeID !== undefined) {
                localVarQueryParameter['CommitteeID'] = committeeID;
            }

            if (sessionID !== undefined) {
                localVarQueryParameter['SessionID'] = sessionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCommitteeRequestDto} updateCommitteeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonUpdateCommittee: async (updateCommitteeRequestDto: UpdateCommitteeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCommitteeRequestDto' is not null or undefined
            assertParamExists('commonUpdateCommittee', 'updateCommitteeRequestDto', updateCommitteeRequestDto)
            const localVarPath = `/api/Common/UpdateCommittee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommitteeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCommitteeCommentRequestDto} updateCommitteeCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonUpdateCommitteeComment: async (updateCommitteeCommentRequestDto: UpdateCommitteeCommentRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCommitteeCommentRequestDto' is not null or undefined
            assertParamExists('commonUpdateCommitteeComment', 'updateCommitteeCommentRequestDto', updateCommitteeCommentRequestDto)
            const localVarPath = `/api/Common/UpdateCommitteeComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommitteeCommentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommonApi - functional programming interface
 * @export
 */
export const CommonApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommonApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonAddCommitteeChildRelation(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonAddCommitteeChildRelation(committeeID, childID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonAddCommitteeChildRelation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AddRemoveCommitteeIDProjectRequestDto} addRemoveCommitteeIDProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto: AddRemoveCommitteeIDProjectRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonAddRemoveCommitteeIDProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CheckObjectSecurityUserRequestDto} checkObjectSecurityUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto: CheckObjectSecurityUserRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfCheckObjectSecurityUserResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonCheckObjectSecurityUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [agendaID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonDeleteAgenda(agendaID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonDeleteAgenda(agendaID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonDeleteAgenda']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonDeleteCommittee(committeeID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonDeleteCommittee(committeeID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonDeleteCommittee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonDeleteCommitteeChild(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonDeleteCommitteeChild(committeeID, childID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonDeleteCommitteeChild']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonDeleteCommitteeSession(sessionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonDeleteCommitteeSession(sessionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonDeleteCommitteeSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetAgenda(committeeID?: string | null, sessionID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetAgendaResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetAgenda(committeeID, sessionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetAgenda']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetAgendaCalendar(committeeID?: string, month?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetAgendaCalendarResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetAgendaCalendar(committeeID, month, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetAgendaCalendar']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetAvailableChildCommittees(committeeID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetAvailableChildCommitteesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetAvailableChildCommittees(committeeID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetAvailableChildCommittees']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [committeeID] 
         * @param {boolean} [revisori] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetAvailableCommitteeMembers(committeeID?: string | null, revisori?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfCommitteMembers>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetAvailableCommitteeMembers(committeeID, revisori, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetAvailableCommitteeMembers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetChildCommittee(committeeID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfChildCommitteeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetChildCommittee(committeeID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetChildCommittee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [languageCode] 
         * @param {boolean} [closedOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeActivities(committeeID?: string, languageCode?: string | null, closedOnly?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetCommitteeActivitiesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeActivities(committeeID, languageCode, closedOnly, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeActivities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {string | null} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeAvailableProjects(committeeID?: string, sessionID?: string | null, xmatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetCommitteeAvailableProjectsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeAvailableProjects(committeeID, sessionID, xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeAvailableProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeByID(committeeID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfCommitteeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeByID(committeeID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [withSecurity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeListPage(withSecurity?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetCommitteeListPageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeListPage(withSecurity, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeListPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteePicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetCommitteePicklistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteePicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeProjects(committeeID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetCommitteeProjectsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeProjects(committeeID, xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeProjects']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeSessions(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetCommitteeSessionsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeSessions(committeeID, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeSessions']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetCommitteeSintesiListRequest} getCommitteeSintesiListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeSintesiList(getCommitteeSintesiListRequest: GetCommitteeSintesiListRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetSintesiComitatiResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeSintesiList(getCommitteeSintesiListRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeSintesiList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeSummary(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetCommitteeSummaryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeSummary(committeeID, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeSummaryPerStatus(committeeID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetCommitteeSummaryPerStatusResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeSummaryPerStatus(committeeID, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeSummaryPerStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetCommitteeSummaryPerTime(committeeID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetCommitteeSummaryPerTimeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetCommitteeSummaryPerTime(committeeID, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetCommitteeSummaryPerTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetKPIAnnualGoalPicklist(annualGoalID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIAnnualGoalPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetKPIAnnualGoalPicklist(annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetKPIAnnualGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetNewDivisionSponsorPicklist(divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetNewDivisionSponsorPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetNewDivisionSponsorPicklist(divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetNewDivisionSponsorPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetNewDivisionTeamLeaderPicklist(divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetNewDivisionTeamLeaderPicklist(divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetNewDivisionTeamLeaderPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID?: string, divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID, divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetNewDivisionXMatrixSecondaryProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetNoCommitteeProject(xMatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetNoCommitteeProjectsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetNoCommitteeProject(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetNoCommitteeProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [objectCode] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetObjectCodePicklist(objectCode?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfObjectCodePicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetObjectCodePicklist(objectCode, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetObjectCodePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [objectCode] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetStatusPicklist(objectCode?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfStatusPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetStatusPicklist(objectCode, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetStatusPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonGetUserSelect(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetUserSelectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonGetUserSelect(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonGetUserSelect']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertCommitteeRequestDto} insertCommitteeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonInsertCommittee(insertCommitteeRequestDto: InsertCommitteeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonInsertCommittee(insertCommitteeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonInsertCommittee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertUpdateAgendaRequestDto} insertUpdateAgendaRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonInsertUpdateAgenda(insertUpdateAgendaRequestDto: InsertUpdateAgendaRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonInsertUpdateAgenda(insertUpdateAgendaRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonInsertUpdateAgenda']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertUpdateCommitteeSessionRequestDto} insertUpdateCommitteeSessionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto: InsertUpdateCommitteeSessionRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertUpdateCommitteeSessionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonInsertUpdateCommitteeSession']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<InsertUserCommitteeRequestDto>} insertUserCommitteeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonInsertUserCommittee(insertUserCommitteeRequestDto: Array<InsertUserCommitteeRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonInsertUserCommittee(insertUserCommitteeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonInsertUserCommittee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonSendEmail(committeeID?: string, sessionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonSendEmail(committeeID, sessionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonSendEmail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateCommitteeRequestDto} updateCommitteeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonUpdateCommittee(updateCommitteeRequestDto: UpdateCommitteeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonUpdateCommittee(updateCommitteeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonUpdateCommittee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateCommitteeCommentRequestDto} updateCommitteeCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async commonUpdateCommitteeComment(updateCommitteeCommentRequestDto: UpdateCommitteeCommentRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.commonUpdateCommitteeComment(updateCommitteeCommentRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CommonApi.commonUpdateCommitteeComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CommonApi - factory interface
 * @export
 */
export const CommonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommonApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonAddCommitteeChildRelation(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.commonAddCommitteeChildRelation(committeeID, childID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AddRemoveCommitteeIDProjectRequestDto} addRemoveCommitteeIDProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto: AddRemoveCommitteeIDProjectRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CheckObjectSecurityUserRequestDto} checkObjectSecurityUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto: CheckObjectSecurityUserRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfCheckObjectSecurityUserResponse> {
            return localVarFp.commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [agendaID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteAgenda(agendaID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.commonDeleteAgenda(agendaID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteCommittee(committeeID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.commonDeleteCommittee(committeeID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [childID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteCommitteeChild(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.commonDeleteCommitteeChild(committeeID, childID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonDeleteCommitteeSession(sessionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.commonDeleteCommitteeSession(sessionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAgenda(committeeID?: string | null, sessionID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetAgendaResponseDto> {
            return localVarFp.commonGetAgenda(committeeID, sessionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [month] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAgendaCalendar(committeeID?: string, month?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetAgendaCalendarResponseDto> {
            return localVarFp.commonGetAgendaCalendar(committeeID, month, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAvailableChildCommittees(committeeID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetAvailableChildCommitteesResponseDto> {
            return localVarFp.commonGetAvailableChildCommittees(committeeID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [committeeID] 
         * @param {boolean} [revisori] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetAvailableCommitteeMembers(committeeID?: string | null, revisori?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfCommitteMembers> {
            return localVarFp.commonGetAvailableCommitteeMembers(committeeID, revisori, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetChildCommittee(committeeID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfChildCommitteeResponseDto> {
            return localVarFp.commonGetChildCommittee(committeeID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [languageCode] 
         * @param {boolean} [closedOnly] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeActivities(committeeID?: string, languageCode?: string | null, closedOnly?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetCommitteeActivitiesResponseDto> {
            return localVarFp.commonGetCommitteeActivities(committeeID, languageCode, closedOnly, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [sessionID] 
         * @param {string | null} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeAvailableProjects(committeeID?: string, sessionID?: string | null, xmatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetCommitteeAvailableProjectsResponseDto> {
            return localVarFp.commonGetCommitteeAvailableProjects(committeeID, sessionID, xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeByID(committeeID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfCommitteeResponse> {
            return localVarFp.commonGetCommitteeByID(committeeID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [withSecurity] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeListPage(withSecurity?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetCommitteeListPageResponseDto> {
            return localVarFp.commonGetCommitteeListPage(withSecurity, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteePicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetCommitteePicklistDto> {
            return localVarFp.commonGetCommitteePicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeProjects(committeeID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetCommitteeProjectsResponseDto> {
            return localVarFp.commonGetCommitteeProjects(committeeID, xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSessions(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetCommitteeSessionsResponseDto> {
            return localVarFp.commonGetCommitteeSessions(committeeID, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetCommitteeSintesiListRequest} getCommitteeSintesiListRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSintesiList(getCommitteeSintesiListRequest: GetCommitteeSintesiListRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetSintesiComitatiResponse> {
            return localVarFp.commonGetCommitteeSintesiList(getCommitteeSintesiListRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummary(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetCommitteeSummaryResponseDto> {
            return localVarFp.commonGetCommitteeSummary(committeeID, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummaryPerStatus(committeeID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetCommitteeSummaryPerStatusResponse> {
            return localVarFp.commonGetCommitteeSummaryPerStatus(committeeID, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetCommitteeSummaryPerTime(committeeID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetCommitteeSummaryPerTimeResponse> {
            return localVarFp.commonGetCommitteeSummaryPerTime(committeeID, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetKPIAnnualGoalPicklist(annualGoalID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIAnnualGoalPicklistResponseDto> {
            return localVarFp.commonGetKPIAnnualGoalPicklist(annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionSponsorPicklist(divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetNewDivisionSponsorPicklistResponseDto> {
            return localVarFp.commonGetNewDivisionSponsorPicklist(divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionTeamLeaderPicklist(divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetNewDivisionTeamLeaderPicklistResponseDto> {
            return localVarFp.commonGetNewDivisionTeamLeaderPicklist(divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID?: string, divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetNewDivisionXMatrixSecondaryProjectPicklistResponseDto> {
            return localVarFp.commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID, divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetNoCommitteeProject(xMatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetNoCommitteeProjectsResponseDto> {
            return localVarFp.commonGetNoCommitteeProject(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [objectCode] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetObjectCodePicklist(objectCode?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfObjectCodePicklistResponseDto> {
            return localVarFp.commonGetObjectCodePicklist(objectCode, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [objectCode] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetStatusPicklist(objectCode?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfStatusPicklistResponseDto> {
            return localVarFp.commonGetStatusPicklist(objectCode, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonGetUserSelect(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetUserSelectResponseDto> {
            return localVarFp.commonGetUserSelect(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertCommitteeRequestDto} insertCommitteeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertCommittee(insertCommitteeRequestDto: InsertCommitteeRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.commonInsertCommittee(insertCommitteeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertUpdateAgendaRequestDto} insertUpdateAgendaRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertUpdateAgenda(insertUpdateAgendaRequestDto: InsertUpdateAgendaRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.commonInsertUpdateAgenda(insertUpdateAgendaRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertUpdateCommitteeSessionRequestDto} insertUpdateCommitteeSessionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto: InsertUpdateCommitteeSessionRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertUpdateCommitteeSessionResponseDto> {
            return localVarFp.commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<InsertUserCommitteeRequestDto>} insertUserCommitteeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonInsertUserCommittee(insertUserCommitteeRequestDto: Array<InsertUserCommitteeRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.commonInsertUserCommittee(insertUserCommitteeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [committeeID] 
         * @param {string} [sessionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonSendEmail(committeeID?: string, sessionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.commonSendEmail(committeeID, sessionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCommitteeRequestDto} updateCommitteeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonUpdateCommittee(updateCommitteeRequestDto: UpdateCommitteeRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.commonUpdateCommittee(updateCommitteeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCommitteeCommentRequestDto} updateCommitteeCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commonUpdateCommitteeComment(updateCommitteeCommentRequestDto: UpdateCommitteeCommentRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.commonUpdateCommitteeComment(updateCommitteeCommentRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommonApi - object-oriented interface
 * @export
 * @class CommonApi
 * @extends {BaseAPI}
 */
export class CommonApi extends BaseAPI {
    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [childID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonAddCommitteeChildRelation(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonAddCommitteeChildRelation(committeeID, childID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AddRemoveCommitteeIDProjectRequestDto} addRemoveCommitteeIDProjectRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto: AddRemoveCommitteeIDProjectRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonAddRemoveCommitteeIDProject(addRemoveCommitteeIDProjectRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CheckObjectSecurityUserRequestDto} checkObjectSecurityUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto: CheckObjectSecurityUserRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonCheckObjectSecurityUser(checkObjectSecurityUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [agendaID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonDeleteAgenda(agendaID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonDeleteAgenda(agendaID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonDeleteCommittee(committeeID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonDeleteCommittee(committeeID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [childID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonDeleteCommitteeChild(committeeID?: string, childID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonDeleteCommitteeChild(committeeID, childID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [sessionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonDeleteCommitteeSession(sessionID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonDeleteCommitteeSession(sessionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [committeeID] 
     * @param {string | null} [sessionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetAgenda(committeeID?: string | null, sessionID?: string | null, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetAgenda(committeeID, sessionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [month] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetAgendaCalendar(committeeID?: string, month?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetAgendaCalendar(committeeID, month, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetAvailableChildCommittees(committeeID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetAvailableChildCommittees(committeeID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [committeeID] 
     * @param {boolean} [revisori] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetAvailableCommitteeMembers(committeeID?: string | null, revisori?: boolean, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetAvailableCommitteeMembers(committeeID, revisori, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetChildCommittee(committeeID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetChildCommittee(committeeID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string | null} [languageCode] 
     * @param {boolean} [closedOnly] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeActivities(committeeID?: string, languageCode?: string | null, closedOnly?: boolean, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeActivities(committeeID, languageCode, closedOnly, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string | null} [sessionID] 
     * @param {string | null} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeAvailableProjects(committeeID?: string, sessionID?: string | null, xmatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeAvailableProjects(committeeID, sessionID, xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeByID(committeeID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeByID(committeeID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [withSecurity] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeListPage(withSecurity?: boolean, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeListPage(withSecurity, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteePicklist(options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteePicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeProjects(committeeID?: string, xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeProjects(committeeID, xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeSessions(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeSessions(committeeID, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetCommitteeSintesiListRequest} getCommitteeSintesiListRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeSintesiList(getCommitteeSintesiListRequest: GetCommitteeSintesiListRequest, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeSintesiList(getCommitteeSintesiListRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeSummary(committeeID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeSummary(committeeID, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeSummaryPerStatus(committeeID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeSummaryPerStatus(committeeID, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetCommitteeSummaryPerTime(committeeID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetCommitteeSummaryPerTime(committeeID, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetKPIAnnualGoalPicklist(annualGoalID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetKPIAnnualGoalPicklist(annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetNewDivisionSponsorPicklist(divisionID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetNewDivisionSponsorPicklist(divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetNewDivisionTeamLeaderPicklist(divisionID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetNewDivisionTeamLeaderPicklist(divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixID] 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID?: string, divisionID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetNewDivisionXMatrixSecondaryProjectPicklist(xmatrixID, divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetNoCommitteeProject(xMatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetNoCommitteeProject(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [objectCode] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetObjectCodePicklist(objectCode?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetObjectCodePicklist(objectCode, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [objectCode] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetStatusPicklist(objectCode?: string | null, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetStatusPicklist(objectCode, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonGetUserSelect(options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonGetUserSelect(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertCommitteeRequestDto} insertCommitteeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonInsertCommittee(insertCommitteeRequestDto: InsertCommitteeRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonInsertCommittee(insertCommitteeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertUpdateAgendaRequestDto} insertUpdateAgendaRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonInsertUpdateAgenda(insertUpdateAgendaRequestDto: InsertUpdateAgendaRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonInsertUpdateAgenda(insertUpdateAgendaRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertUpdateCommitteeSessionRequestDto} insertUpdateCommitteeSessionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto: InsertUpdateCommitteeSessionRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonInsertUpdateCommitteeSession(insertUpdateCommitteeSessionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<InsertUserCommitteeRequestDto>} insertUserCommitteeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonInsertUserCommittee(insertUserCommitteeRequestDto: Array<InsertUserCommitteeRequestDto>, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonInsertUserCommittee(insertUserCommitteeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [committeeID] 
     * @param {string} [sessionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonSendEmail(committeeID?: string, sessionID?: string, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonSendEmail(committeeID, sessionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCommitteeRequestDto} updateCommitteeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonUpdateCommittee(updateCommitteeRequestDto: UpdateCommitteeRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonUpdateCommittee(updateCommitteeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCommitteeCommentRequestDto} updateCommitteeCommentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommonApi
     */
    public commonUpdateCommitteeComment(updateCommitteeCommentRequestDto: UpdateCommitteeCommentRequestDto, options?: RawAxiosRequestConfig) {
        return CommonApiFp(this.configuration).commonUpdateCommitteeComment(updateCommitteeCommentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CompanyApi - axios parameter creator
 * @export
 */
export const CompanyApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetAlertConfiguration: async (companyID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/GetAlertConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyID !== undefined) {
                localVarQueryParameter['CompanyID'] = companyID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertAlertConfigurationRequestDto} insertAlertConfigurationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInsertAlertConfiguration: async (insertAlertConfigurationRequestDto: InsertAlertConfigurationRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertAlertConfigurationRequestDto' is not null or undefined
            assertParamExists('companyInsertAlertConfiguration', 'insertAlertConfigurationRequestDto', insertAlertConfigurationRequestDto)
            const localVarPath = `/api/Company/InsertAlertConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertAlertConfigurationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInsertNextYearUserActivityPlanning: async (year?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/InsertNextYearUserActivityPlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAlertConfigurationRequestDto} updateAlertConfigurationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateAlertConfiguration: async (updateAlertConfigurationRequestDto: UpdateAlertConfigurationRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAlertConfigurationRequestDto' is not null or undefined
            assertParamExists('companyUpdateAlertConfiguration', 'updateAlertConfigurationRequestDto', updateAlertConfigurationRequestDto)
            const localVarPath = `/api/Company/UpdateAlertConfiguration`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAlertConfigurationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateCompanyProfile: async (contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Company/UpdateCompanyProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
                if (headers) {
                localVarFormParams.append('Headers', headers.join(COLLECTION_FORMATS.csv));
            }

    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CompanyApi - functional programming interface
 * @export
 */
export const CompanyApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CompanyApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyGetAlertConfiguration(companyID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfAlertConfigurationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyGetAlertConfiguration(companyID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyGetAlertConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertAlertConfigurationRequestDto} insertAlertConfigurationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInsertAlertConfiguration(insertAlertConfigurationRequestDto: InsertAlertConfigurationRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertAlertConfigurationResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInsertAlertConfiguration(insertAlertConfigurationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyInsertAlertConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyInsertNextYearUserActivityPlanning(year?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyInsertNextYearUserActivityPlanning(year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyInsertNextYearUserActivityPlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateAlertConfigurationRequestDto} updateAlertConfigurationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto: UpdateAlertConfigurationRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyUpdateAlertConfiguration']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async companyUpdateCompanyProfile(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.companyUpdateCompanyProfile(contentType, contentDisposition, headers, length, name, fileName, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CompanyApi.companyUpdateCompanyProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CompanyApi - factory interface
 * @export
 */
export const CompanyApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CompanyApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyGetAlertConfiguration(companyID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfAlertConfigurationResponseDto> {
            return localVarFp.companyGetAlertConfiguration(companyID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertAlertConfigurationRequestDto} insertAlertConfigurationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInsertAlertConfiguration(insertAlertConfigurationRequestDto: InsertAlertConfigurationRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertAlertConfigurationResponseDto> {
            return localVarFp.companyInsertAlertConfiguration(insertAlertConfigurationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyInsertNextYearUserActivityPlanning(year?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.companyInsertNextYearUserActivityPlanning(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAlertConfigurationRequestDto} updateAlertConfigurationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto: UpdateAlertConfigurationRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        companyUpdateCompanyProfile(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.companyUpdateCompanyProfile(contentType, contentDisposition, headers, length, name, fileName, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CompanyApi - object-oriented interface
 * @export
 * @class CompanyApi
 * @extends {BaseAPI}
 */
export class CompanyApi extends BaseAPI {
    /**
     * 
     * @param {string} [companyID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyGetAlertConfiguration(companyID?: string, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyGetAlertConfiguration(companyID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertAlertConfigurationRequestDto} insertAlertConfigurationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyInsertAlertConfiguration(insertAlertConfigurationRequestDto: InsertAlertConfigurationRequestDto, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyInsertAlertConfiguration(insertAlertConfigurationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyInsertNextYearUserActivityPlanning(year?: string | null, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyInsertNextYearUserActivityPlanning(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAlertConfigurationRequestDto} updateAlertConfigurationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto: UpdateAlertConfigurationRequestDto, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyUpdateAlertConfiguration(updateAlertConfigurationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [contentType] 
     * @param {string | null} [contentDisposition] 
     * @param {Array<any> | null} [headers] 
     * @param {number} [length] 
     * @param {string | null} [name] 
     * @param {string | null} [fileName] 
     * @param {string | null} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CompanyApi
     */
    public companyUpdateCompanyProfile(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig) {
        return CompanyApiFp(this.configuration).companyUpdateCompanyProfile(contentType, contentDisposition, headers, length, name, fileName, data, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DeliverableApi - axios parameter creator
 * @export
 */
export const DeliverableApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableDeleteDeliverable: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deliverableDeleteDeliverable', 'id', id)
            const localVarPath = `/api/Deliverable/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {FastCloseDeliverableRequestDto} fastCloseDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableFastCloseDeliverable: async (fastCloseDeliverableRequestDto: FastCloseDeliverableRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fastCloseDeliverableRequestDto' is not null or undefined
            assertParamExists('deliverableFastCloseDeliverable', 'fastCloseDeliverableRequestDto', fastCloseDeliverableRequestDto)
            const localVarPath = `/api/Deliverable/FastCloseDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(fastCloseDeliverableRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetChildDeliverable: async (deliverableID?: string, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/GetChildDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverableByID: async (deliverableID?: string, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/GetDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverableListPage: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/GetDeliverableListPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverablePicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/GetDeliverablePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetProjectDeliverableTeam: async (deliverableID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Deliverable/GetProjectDeliverableTeam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertDeliverableRequestDto} insertDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableInsertDeliverable: async (insertDeliverableRequestDto: InsertDeliverableRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertDeliverableRequestDto' is not null or undefined
            assertParamExists('deliverableInsertDeliverable', 'insertDeliverableRequestDto', insertDeliverableRequestDto)
            const localVarPath = `/api/Deliverable/InsertDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertDeliverableRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateDeliverableRequestDto} updateDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableUpdateDeliverable: async (updateDeliverableRequestDto: UpdateDeliverableRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateDeliverableRequestDto' is not null or undefined
            assertParamExists('deliverableUpdateDeliverable', 'updateDeliverableRequestDto', updateDeliverableRequestDto)
            const localVarPath = `/api/Deliverable/UpdateDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateDeliverableRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DeliverableApi - functional programming interface
 * @export
 */
export const DeliverableApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DeliverableApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableDeleteDeliverable(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableDeleteDeliverable(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableDeleteDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {FastCloseDeliverableRequestDto} fastCloseDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto: FastCloseDeliverableRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfFastCloseDeliverableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableFastCloseDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableGetChildDeliverable(deliverableID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetChildDeliverableResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableGetChildDeliverable(deliverableID, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableGetChildDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableGetDeliverableByID(deliverableID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetDeliverableResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableGetDeliverableByID(deliverableID, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableGetDeliverableByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableGetDeliverableListPage(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetDeliverableListPageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableGetDeliverableListPage(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableGetDeliverableListPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableGetDeliverablePicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetDeliverablePicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableGetDeliverablePicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableGetDeliverablePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableGetProjectDeliverableTeam(deliverableID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectDeliverableTeamResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableGetProjectDeliverableTeam(deliverableID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableGetProjectDeliverableTeam']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertDeliverableRequestDto} insertDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableInsertDeliverable(insertDeliverableRequestDto: InsertDeliverableRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableInsertDeliverable(insertDeliverableRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableInsertDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateDeliverableRequestDto} updateDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deliverableUpdateDeliverable(updateDeliverableRequestDto: UpdateDeliverableRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfUpdateDeliverableResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deliverableUpdateDeliverable(updateDeliverableRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DeliverableApi.deliverableUpdateDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DeliverableApi - factory interface
 * @export
 */
export const DeliverableApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DeliverableApiFp(configuration)
    return {
        /**
         * 
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableDeleteDeliverable(id: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.deliverableDeleteDeliverable(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {FastCloseDeliverableRequestDto} fastCloseDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto: FastCloseDeliverableRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfFastCloseDeliverableResponse> {
            return localVarFp.deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetChildDeliverable(deliverableID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetChildDeliverableResponseDto> {
            return localVarFp.deliverableGetChildDeliverable(deliverableID, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverableByID(deliverableID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetDeliverableResponseDto> {
            return localVarFp.deliverableGetDeliverableByID(deliverableID, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverableListPage(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetDeliverableListPageResponseDto> {
            return localVarFp.deliverableGetDeliverableListPage(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetDeliverablePicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetDeliverablePicklistResponseDto> {
            return localVarFp.deliverableGetDeliverablePicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableGetProjectDeliverableTeam(deliverableID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectDeliverableTeamResponseDto> {
            return localVarFp.deliverableGetProjectDeliverableTeam(deliverableID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertDeliverableRequestDto} insertDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableInsertDeliverable(insertDeliverableRequestDto: InsertDeliverableRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.deliverableInsertDeliverable(insertDeliverableRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateDeliverableRequestDto} updateDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deliverableUpdateDeliverable(updateDeliverableRequestDto: UpdateDeliverableRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfUpdateDeliverableResponse> {
            return localVarFp.deliverableUpdateDeliverable(updateDeliverableRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DeliverableApi - object-oriented interface
 * @export
 * @class DeliverableApi
 * @extends {BaseAPI}
 */
export class DeliverableApi extends BaseAPI {
    /**
     * 
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableDeleteDeliverable(id: string, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableDeleteDeliverable(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {FastCloseDeliverableRequestDto} fastCloseDeliverableRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto: FastCloseDeliverableRequestDto, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableFastCloseDeliverable(fastCloseDeliverableRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [deliverableID] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableGetChildDeliverable(deliverableID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableGetChildDeliverable(deliverableID, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [deliverableID] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableGetDeliverableByID(deliverableID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableGetDeliverableByID(deliverableID, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableGetDeliverableListPage(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableGetDeliverableListPage(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableGetDeliverablePicklist(options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableGetDeliverablePicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [deliverableID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableGetProjectDeliverableTeam(deliverableID?: string, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableGetProjectDeliverableTeam(deliverableID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertDeliverableRequestDto} insertDeliverableRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableInsertDeliverable(insertDeliverableRequestDto: InsertDeliverableRequestDto, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableInsertDeliverable(insertDeliverableRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateDeliverableRequestDto} updateDeliverableRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeliverableApi
     */
    public deliverableUpdateDeliverable(updateDeliverableRequestDto: UpdateDeliverableRequestDto, options?: RawAxiosRequestConfig) {
        return DeliverableApiFp(this.configuration).deliverableUpdateDeliverable(updateDeliverableRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DivisionApi - axios parameter creator
 * @export
 */
export const DivisionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string | null} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionGetDivisionByID: async (divisionID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Division/GetDivision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (divisionID !== undefined) {
                localVarQueryParameter['DivisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionGetDivisionListPage: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Division/GetDivisionListPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionInsertDivision: async (contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Division/InsertDivision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
                if (headers) {
                localVarFormParams.append('Headers', headers.join(COLLECTION_FORMATS.csv));
            }

    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionUpdateDivision: async (contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Division/UpdateDivision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
                if (headers) {
                localVarFormParams.append('Headers', headers.join(COLLECTION_FORMATS.csv));
            }

    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DivisionApi - functional programming interface
 * @export
 */
export const DivisionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DivisionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string | null} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async divisionGetDivisionByID(divisionID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetDivisionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.divisionGetDivisionByID(divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DivisionApi.divisionGetDivisionByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async divisionGetDivisionListPage(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetDivisionListPageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.divisionGetDivisionListPage(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DivisionApi.divisionGetDivisionListPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async divisionInsertDivision(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.divisionInsertDivision(contentType, contentDisposition, headers, length, name, fileName, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DivisionApi.divisionInsertDivision']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async divisionUpdateDivision(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.divisionUpdateDivision(contentType, contentDisposition, headers, length, name, fileName, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DivisionApi.divisionUpdateDivision']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DivisionApi - factory interface
 * @export
 */
export const DivisionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DivisionApiFp(configuration)
    return {
        /**
         * 
         * @param {string | null} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionGetDivisionByID(divisionID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetDivisionResponseDto> {
            return localVarFp.divisionGetDivisionByID(divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionGetDivisionListPage(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetDivisionListPageResponseDto> {
            return localVarFp.divisionGetDivisionListPage(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionInsertDivision(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.divisionInsertDivision(contentType, contentDisposition, headers, length, name, fileName, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        divisionUpdateDivision(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.divisionUpdateDivision(contentType, contentDisposition, headers, length, name, fileName, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DivisionApi - object-oriented interface
 * @export
 * @class DivisionApi
 * @extends {BaseAPI}
 */
export class DivisionApi extends BaseAPI {
    /**
     * 
     * @param {string | null} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionApi
     */
    public divisionGetDivisionByID(divisionID?: string | null, options?: RawAxiosRequestConfig) {
        return DivisionApiFp(this.configuration).divisionGetDivisionByID(divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionApi
     */
    public divisionGetDivisionListPage(options?: RawAxiosRequestConfig) {
        return DivisionApiFp(this.configuration).divisionGetDivisionListPage(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [contentType] 
     * @param {string | null} [contentDisposition] 
     * @param {Array<any> | null} [headers] 
     * @param {number} [length] 
     * @param {string | null} [name] 
     * @param {string | null} [fileName] 
     * @param {string | null} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionApi
     */
    public divisionInsertDivision(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig) {
        return DivisionApiFp(this.configuration).divisionInsertDivision(contentType, contentDisposition, headers, length, name, fileName, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [contentType] 
     * @param {string | null} [contentDisposition] 
     * @param {Array<any> | null} [headers] 
     * @param {number} [length] 
     * @param {string | null} [name] 
     * @param {string | null} [fileName] 
     * @param {string | null} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DivisionApi
     */
    public divisionUpdateDivision(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig) {
        return DivisionApiFp(this.configuration).divisionUpdateDivision(contentType, contentDisposition, headers, length, name, fileName, data, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FeedApi - axios parameter creator
 * @export
 */
export const FeedApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [activityID] 
         * @param {number} [activityType] 
         * @param {string | null} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedDeleteActivityFeed: async (activityID?: string, activityType?: number, outlookEventId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/DeleteActivityFeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activityID !== undefined) {
                localVarQueryParameter['ActivityID'] = activityID;
            }

            if (activityType !== undefined) {
                localVarQueryParameter['ActivityType'] = activityType;
            }

            if (outlookEventId !== undefined) {
                localVarQueryParameter['OutlookEventId'] = outlookEventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedActivity: async (activityID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/GetFeedActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (activityID !== undefined) {
                localVarQueryParameter['ActivityID'] = activityID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [regardingObjectID] 
         * @param {string | null} [userID] 
         * @param {string | null} [isProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedActivityList: async (regardingObjectID?: string | null, userID?: string | null, isProject?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/GetFeedActivityList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regardingObjectID !== undefined) {
                localVarQueryParameter['RegardingObjectID'] = regardingObjectID;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (isProject !== undefined) {
                localVarQueryParameter['IsProject'] = isProject;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedMonthlyAlertByUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/GetFeedMonthlyAlertByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [regardingObjectID] 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetProjectFeedActivityList: async (regardingObjectID?: string | null, userID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/GetProjectFeedActivityList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (regardingObjectID !== undefined) {
                localVarQueryParameter['RegardingObjectID'] = regardingObjectID;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertFeedActivityLikeRequestDto} insertFeedActivityLikeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedActivityLike: async (insertFeedActivityLikeRequestDto: InsertFeedActivityLikeRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertFeedActivityLikeRequestDto' is not null or undefined
            assertParamExists('feedInsertFeedActivityLike', 'insertFeedActivityLikeRequestDto', insertFeedActivityLikeRequestDto)
            const localVarPath = `/api/Feed/InsertFeedActivityLike`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertFeedActivityLikeRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertFeedCommentRequestDto} insertFeedCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedComment: async (insertFeedCommentRequestDto: InsertFeedCommentRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertFeedCommentRequestDto' is not null or undefined
            assertParamExists('feedInsertFeedComment', 'insertFeedCommentRequestDto', insertFeedCommentRequestDto)
            const localVarPath = `/api/Feed/InsertFeedComment`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertFeedCommentRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertFeedFileRequestDto} insertFeedFileRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedFile: async (insertFeedFileRequestDto: InsertFeedFileRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertFeedFileRequestDto' is not null or undefined
            assertParamExists('feedInsertFeedFile', 'insertFeedFileRequestDto', insertFeedFileRequestDto)
            const localVarPath = `/api/Feed/InsertFeedFile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertFeedFileRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertFeedMeetingRequestDto} insertFeedMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedMeeting: async (insertFeedMeetingRequestDto: InsertFeedMeetingRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertFeedMeetingRequestDto' is not null or undefined
            assertParamExists('feedInsertFeedMeeting', 'insertFeedMeetingRequestDto', insertFeedMeetingRequestDto)
            const localVarPath = `/api/Feed/InsertFeedMeeting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertFeedMeetingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertFeedNotificationRequestDto} insertFeedNotificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedNotification: async (insertFeedNotificationRequestDto: InsertFeedNotificationRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertFeedNotificationRequestDto' is not null or undefined
            assertParamExists('feedInsertFeedNotification', 'insertFeedNotificationRequestDto', insertFeedNotificationRequestDto)
            const localVarPath = `/api/Feed/InsertFeedNotification`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertFeedNotificationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<InsertMeetingParticipantRequestDto>} insertMeetingParticipantRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertMeetingParticipant: async (insertMeetingParticipantRequestDto: Array<InsertMeetingParticipantRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertMeetingParticipantRequestDto' is not null or undefined
            assertParamExists('feedInsertMeetingParticipant', 'insertMeetingParticipantRequestDto', insertMeetingParticipantRequestDto)
            const localVarPath = `/api/Feed/InsertMeetingParticipant`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertMeetingParticipantRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateFeedActivityRequestDto} updateFeedActivityRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedUpdateFeedActivity: async (updateFeedActivityRequestDto: UpdateFeedActivityRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateFeedActivityRequestDto' is not null or undefined
            assertParamExists('feedUpdateFeedActivity', 'updateFeedActivityRequestDto', updateFeedActivityRequestDto)
            const localVarPath = `/api/Feed/UpdateFeedActivity`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateFeedActivityRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedUpdateMonthlyAlertFeed: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Feed/UpdateMonthlyAlertFeed`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FeedApi - functional programming interface
 * @export
 */
export const FeedApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FeedApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [activityID] 
         * @param {number} [activityType] 
         * @param {string | null} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedDeleteActivityFeed(activityID?: string, activityType?: number, outlookEventId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedDeleteActivityFeed(activityID, activityType, outlookEventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedDeleteActivityFeed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedGetFeedActivity(activityID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetFeedActivityResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedGetFeedActivity(activityID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedGetFeedActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [regardingObjectID] 
         * @param {string | null} [userID] 
         * @param {string | null} [isProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedGetFeedActivityList(regardingObjectID?: string | null, userID?: string | null, isProject?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetFeedActivityListResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedGetFeedActivityList(regardingObjectID, userID, isProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedGetFeedActivityList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedGetFeedMonthlyAlertByUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetFeedMonthlyAlertByUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedGetFeedMonthlyAlertByUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedGetFeedMonthlyAlertByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [regardingObjectID] 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedGetProjectFeedActivityList(regardingObjectID?: string | null, userID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectFeedActivityListResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedGetProjectFeedActivityList(regardingObjectID, userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedGetProjectFeedActivityList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertFeedActivityLikeRequestDto} insertFeedActivityLikeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto: InsertFeedActivityLikeRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertFeedActivityLike']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertFeedCommentRequestDto} insertFeedCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertFeedComment(insertFeedCommentRequestDto: InsertFeedCommentRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertFeedComment(insertFeedCommentRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertFeedComment']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertFeedFileRequestDto} insertFeedFileRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertFeedFile(insertFeedFileRequestDto: InsertFeedFileRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertFeedFile(insertFeedFileRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertFeedFile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertFeedMeetingRequestDto} insertFeedMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertFeedMeeting(insertFeedMeetingRequestDto: InsertFeedMeetingRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertFeedMeeting(insertFeedMeetingRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertFeedMeeting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertFeedNotificationRequestDto} insertFeedNotificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertFeedNotification(insertFeedNotificationRequestDto: InsertFeedNotificationRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertFeedNotification(insertFeedNotificationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertFeedNotification']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<InsertMeetingParticipantRequestDto>} insertMeetingParticipantRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedInsertMeetingParticipant(insertMeetingParticipantRequestDto: Array<InsertMeetingParticipantRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedInsertMeetingParticipant(insertMeetingParticipantRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedInsertMeetingParticipant']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateFeedActivityRequestDto} updateFeedActivityRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedUpdateFeedActivity(updateFeedActivityRequestDto: UpdateFeedActivityRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedUpdateFeedActivity(updateFeedActivityRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedUpdateFeedActivity']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async feedUpdateMonthlyAlertFeed(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.feedUpdateMonthlyAlertFeed(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FeedApi.feedUpdateMonthlyAlertFeed']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FeedApi - factory interface
 * @export
 */
export const FeedApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FeedApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [activityID] 
         * @param {number} [activityType] 
         * @param {string | null} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedDeleteActivityFeed(activityID?: string, activityType?: number, outlookEventId?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.feedDeleteActivityFeed(activityID, activityType, outlookEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [activityID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedActivity(activityID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetFeedActivityResponseDto> {
            return localVarFp.feedGetFeedActivity(activityID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [regardingObjectID] 
         * @param {string | null} [userID] 
         * @param {string | null} [isProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedActivityList(regardingObjectID?: string | null, userID?: string | null, isProject?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetFeedActivityListResponseDto> {
            return localVarFp.feedGetFeedActivityList(regardingObjectID, userID, isProject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetFeedMonthlyAlertByUser(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetFeedMonthlyAlertByUserResponseDto> {
            return localVarFp.feedGetFeedMonthlyAlertByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [regardingObjectID] 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedGetProjectFeedActivityList(regardingObjectID?: string | null, userID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectFeedActivityListResponseDto> {
            return localVarFp.feedGetProjectFeedActivityList(regardingObjectID, userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertFeedActivityLikeRequestDto} insertFeedActivityLikeRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto: InsertFeedActivityLikeRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertFeedCommentRequestDto} insertFeedCommentRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedComment(insertFeedCommentRequestDto: InsertFeedCommentRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.feedInsertFeedComment(insertFeedCommentRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertFeedFileRequestDto} insertFeedFileRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedFile(insertFeedFileRequestDto: InsertFeedFileRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.feedInsertFeedFile(insertFeedFileRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertFeedMeetingRequestDto} insertFeedMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedMeeting(insertFeedMeetingRequestDto: InsertFeedMeetingRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.feedInsertFeedMeeting(insertFeedMeetingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertFeedNotificationRequestDto} insertFeedNotificationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertFeedNotification(insertFeedNotificationRequestDto: InsertFeedNotificationRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.feedInsertFeedNotification(insertFeedNotificationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<InsertMeetingParticipantRequestDto>} insertMeetingParticipantRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedInsertMeetingParticipant(insertMeetingParticipantRequestDto: Array<InsertMeetingParticipantRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.feedInsertMeetingParticipant(insertMeetingParticipantRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateFeedActivityRequestDto} updateFeedActivityRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedUpdateFeedActivity(updateFeedActivityRequestDto: UpdateFeedActivityRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.feedUpdateFeedActivity(updateFeedActivityRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        feedUpdateMonthlyAlertFeed(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.feedUpdateMonthlyAlertFeed(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FeedApi - object-oriented interface
 * @export
 * @class FeedApi
 * @extends {BaseAPI}
 */
export class FeedApi extends BaseAPI {
    /**
     * 
     * @param {string} [activityID] 
     * @param {number} [activityType] 
     * @param {string | null} [outlookEventId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedDeleteActivityFeed(activityID?: string, activityType?: number, outlookEventId?: string | null, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedDeleteActivityFeed(activityID, activityType, outlookEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [activityID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedGetFeedActivity(activityID?: string | null, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedGetFeedActivity(activityID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [regardingObjectID] 
     * @param {string | null} [userID] 
     * @param {string | null} [isProject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedGetFeedActivityList(regardingObjectID?: string | null, userID?: string | null, isProject?: string | null, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedGetFeedActivityList(regardingObjectID, userID, isProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedGetFeedMonthlyAlertByUser(options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedGetFeedMonthlyAlertByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [regardingObjectID] 
     * @param {string | null} [userID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedGetProjectFeedActivityList(regardingObjectID?: string | null, userID?: string | null, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedGetProjectFeedActivityList(regardingObjectID, userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertFeedActivityLikeRequestDto} insertFeedActivityLikeRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto: InsertFeedActivityLikeRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertFeedActivityLike(insertFeedActivityLikeRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertFeedCommentRequestDto} insertFeedCommentRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertFeedComment(insertFeedCommentRequestDto: InsertFeedCommentRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertFeedComment(insertFeedCommentRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertFeedFileRequestDto} insertFeedFileRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertFeedFile(insertFeedFileRequestDto: InsertFeedFileRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertFeedFile(insertFeedFileRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertFeedMeetingRequestDto} insertFeedMeetingRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertFeedMeeting(insertFeedMeetingRequestDto: InsertFeedMeetingRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertFeedMeeting(insertFeedMeetingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertFeedNotificationRequestDto} insertFeedNotificationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertFeedNotification(insertFeedNotificationRequestDto: InsertFeedNotificationRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertFeedNotification(insertFeedNotificationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<InsertMeetingParticipantRequestDto>} insertMeetingParticipantRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedInsertMeetingParticipant(insertMeetingParticipantRequestDto: Array<InsertMeetingParticipantRequestDto>, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedInsertMeetingParticipant(insertMeetingParticipantRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateFeedActivityRequestDto} updateFeedActivityRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedUpdateFeedActivity(updateFeedActivityRequestDto: UpdateFeedActivityRequestDto, options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedUpdateFeedActivity(updateFeedActivityRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FeedApi
     */
    public feedUpdateMonthlyAlertFeed(options?: RawAxiosRequestConfig) {
        return FeedApiFp(this.configuration).feedUpdateMonthlyAlertFeed(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * GeneralApi - axios parameter creator
 * @export
 */
export const GeneralApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompany: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/GetCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompanyByID: async (companyId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/CompanyByID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyId !== undefined) {
                localVarQueryParameter['companyId'] = companyId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompanyListByUserID: async (userID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/GetCompanyList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetConnectionModel: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/GetConnectionString`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetMenuItem: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/General/GetMenuItem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {AdminInsertCompanyRequestDto} adminInsertCompanyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalInsertCompany: async (adminInsertCompanyRequestDto: AdminInsertCompanyRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'adminInsertCompanyRequestDto' is not null or undefined
            assertParamExists('generalInsertCompany', 'adminInsertCompanyRequestDto', adminInsertCompanyRequestDto)
            const localVarPath = `/api/General/AdminInsertCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(adminInsertCompanyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCompanyRequestDto} updateCompanyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalUpdateCompany: async (updateCompanyRequestDto: UpdateCompanyRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCompanyRequestDto' is not null or undefined
            assertParamExists('generalUpdateCompany', 'updateCompanyRequestDto', updateCompanyRequestDto)
            const localVarPath = `/api/General/UpdateCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCompanyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserCompanyRequest} updateUserCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalUpdateUserCompany: async (updateUserCompanyRequest: UpdateUserCompanyRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserCompanyRequest' is not null or undefined
            assertParamExists('generalUpdateUserCompany', 'updateUserCompanyRequest', updateUserCompanyRequest)
            const localVarPath = `/api/General/UpdateUserCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserCompanyRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * GeneralApi - functional programming interface
 * @export
 */
export const GeneralApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = GeneralApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalGetCompany(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfCompanyResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalGetCompany(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalGetCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalGetCompanyByID(companyId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfCompanyByIdResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalGetCompanyByID(companyId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalGetCompanyByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalGetCompanyListByUserID(userID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetCompanyListResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalGetCompanyListByUserID(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalGetCompanyListByUserID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalGetConnectionModel(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfUserDetailsRespose>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalGetConnectionModel(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalGetConnectionModel']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalGetMenuItem(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetMenuItemResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalGetMenuItem(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalGetMenuItem']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {AdminInsertCompanyRequestDto} adminInsertCompanyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalInsertCompany(adminInsertCompanyRequestDto: AdminInsertCompanyRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalInsertCompany(adminInsertCompanyRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalInsertCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateCompanyRequestDto} updateCompanyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalUpdateCompany(updateCompanyRequestDto: UpdateCompanyRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalUpdateCompany(updateCompanyRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalUpdateCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateUserCompanyRequest} updateUserCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async generalUpdateUserCompany(updateUserCompanyRequest: UpdateUserCompanyRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfUserDetailsRespose>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.generalUpdateUserCompany(updateUserCompanyRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['GeneralApi.generalUpdateUserCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * GeneralApi - factory interface
 * @export
 */
export const GeneralApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = GeneralApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompany(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfCompanyResponseDto> {
            return localVarFp.generalGetCompany(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [companyId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompanyByID(companyId?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfCompanyByIdResponseDto> {
            return localVarFp.generalGetCompanyByID(companyId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetCompanyListByUserID(userID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetCompanyListResponseDto> {
            return localVarFp.generalGetCompanyListByUserID(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetConnectionModel(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfUserDetailsRespose> {
            return localVarFp.generalGetConnectionModel(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalGetMenuItem(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetMenuItemResponse> {
            return localVarFp.generalGetMenuItem(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {AdminInsertCompanyRequestDto} adminInsertCompanyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalInsertCompany(adminInsertCompanyRequestDto: AdminInsertCompanyRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.generalInsertCompany(adminInsertCompanyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCompanyRequestDto} updateCompanyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalUpdateCompany(updateCompanyRequestDto: UpdateCompanyRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.generalUpdateCompany(updateCompanyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserCompanyRequest} updateUserCompanyRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        generalUpdateUserCompany(updateUserCompanyRequest: UpdateUserCompanyRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfUserDetailsRespose> {
            return localVarFp.generalUpdateUserCompany(updateUserCompanyRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * GeneralApi - object-oriented interface
 * @export
 * @class GeneralApi
 * @extends {BaseAPI}
 */
export class GeneralApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalGetCompany(options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalGetCompany(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [companyId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalGetCompanyByID(companyId?: string | null, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalGetCompanyByID(companyId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [userID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalGetCompanyListByUserID(userID?: string | null, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalGetCompanyListByUserID(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalGetConnectionModel(options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalGetConnectionModel(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalGetMenuItem(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalGetMenuItem(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {AdminInsertCompanyRequestDto} adminInsertCompanyRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalInsertCompany(adminInsertCompanyRequestDto: AdminInsertCompanyRequestDto, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalInsertCompany(adminInsertCompanyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCompanyRequestDto} updateCompanyRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalUpdateCompany(updateCompanyRequestDto: UpdateCompanyRequestDto, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalUpdateCompany(updateCompanyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserCompanyRequest} updateUserCompanyRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GeneralApi
     */
    public generalUpdateUserCompany(updateUserCompanyRequest: UpdateUserCompanyRequest, options?: RawAxiosRequestConfig) {
        return GeneralApiFp(this.configuration).generalUpdateUserCompany(updateUserCompanyRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * KPIApi - axios parameter creator
 * @export
 */
export const KPIApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string | null} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPICheckDuplicateKPI: async (name?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/CheckDuplicateKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (name !== undefined) {
                localVarQueryParameter['name'] = name;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [iD] 
         * @param {string | null} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPICheckKpiTypePlanningStartDate: async (iD?: string, type?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/CheckKpiTypePlanningStartDate`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (iD !== undefined) {
                localVarQueryParameter['ID'] = iD;
            }

            if (type !== undefined) {
                localVarQueryParameter['type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [annualGoalKPIRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteAnnualGoalKPIRelationshipBowlingChart: async (annualGoalKPIRelationShipID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/DeleteAnnualGoalKPIRelationshipBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (annualGoalKPIRelationShipID !== undefined) {
                localVarQueryParameter['annualGoalKPIRelationShipID'] = annualGoalKPIRelationShipID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteKPI: async (kPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/DeleteKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteUserFavouriteKPI: async (kPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/DeleteUserFavouriteKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDetailsKPI: async (kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/DetailsKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetAnnualGoalByKPI: async (kPIDetailID?: string, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetAnnualGoalByKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [annualGoalID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetAnnualGoalKPIBowlingChartListPage: async (annualGoalID?: string | null, year?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetAnnualGoalKPIBowlingChartListPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string | null} [projectAnnualGoalID] 
         * @param {string | null} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetCommentForKPI: async (kPIDetailID?: string, projectAnnualGoalID?: string | null, type?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetCommentForKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (projectAnnualGoalID !== undefined) {
                localVarQueryParameter['ProjectAnnualGoalID'] = projectAnnualGoalID;
            }

            if (type !== undefined) {
                localVarQueryParameter['Type'] = type;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetGlobalKPIBowlingChartExport: async (kPIDetailID?: string | null, year?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetGlobalKPIBowlingChartExport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIAnnualGoalBowlingChart: async (id?: string | null, year?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIAnnualGoalBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIBowlingChartListPage: async (projectID?: string | null, year?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIBowlingChartListPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string | null} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIByAnnualGoal: async (annualGoalID?: string, xMatrix?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIByAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (annualGoalID !== undefined) {
                localVarQueryParameter['annualGoalID'] = annualGoalID;
            }

            if (xMatrix !== undefined) {
                localVarQueryParameter['xMatrix'] = xMatrix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIById: async (id?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIByProject: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIByProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIChildPicklist: async (parentKPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIChildPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parentKPIDetailID !== undefined) {
                localVarQueryParameter['ParentKPIDetailID'] = parentKPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string | null} [annualGoalID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDashboardSintesiByAnnualGoal: async (month?: number, annualGoalID?: string | null, xMatrixID?: string | null, year?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIDashboardSintesiByAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string | null} [projectID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDashboardSintesiByProject: async (month?: number, projectID?: string | null, xMatrixID?: string | null, year?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIDashboardSintesiByProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDetail: async (kPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDetails: async (id?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIDetails`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string | null} [searchType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIGerarchicoInfo: async (kPIDetailID?: string, searchType?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIGerarchicoInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (searchType !== undefined) {
                localVarQueryParameter['searchType'] = searchType;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [xMatrixID] 
         * @param {string} [annualGoalID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIGraphic: async (month?: number, xMatrixID?: string, annualGoalID?: string, projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIGraphic`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {number} [year] 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIPlanningByID: async (projectID?: string | null, annualGoalID?: string | null, year?: number, kPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIPlanningByID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPISectionA3: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPISectionA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIs: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetKPIs`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetLongTermGoalByKPI: async (kPIDetailID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetLongTermGoalByKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetRollupKPIChild: async (parentKPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetRollupKPIChild`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parentKPIDetailID !== undefined) {
                localVarQueryParameter['ParentKPIDetailID'] = parentKPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [annualGoalKPIRelationShipID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetSpecificAnnualGoalKPIBowlingChartExport: async (annualGoalKPIRelationShipID?: string | null, year?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetSpecificAnnualGoalKPIBowlingChartExport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (annualGoalKPIRelationShipID !== undefined) {
                localVarQueryParameter['AnnualGoalKPIRelationShipID'] = annualGoalKPIRelationShipID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [kPIProjectRelationShipID] 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetSpecificKPIBowlingChartExport: async (kPIProjectRelationShipID?: string | null, projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/GetSpecificKPIBowlingChartExport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIProjectRelationShipID !== undefined) {
                localVarQueryParameter['KPIProjectRelationShipID'] = kPIProjectRelationShipID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIAnnualGoal: async (kPIDetailID?: string, annualGoalID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/InsertKPIAnnualGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {string} [childKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIChild: async (parentKPIDetailID?: string, childKPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/InsertKPIChild`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (parentKPIDetailID !== undefined) {
                localVarQueryParameter['ParentKPIDetailID'] = parentKPIDetailID;
            }

            if (childKPIDetailID !== undefined) {
                localVarQueryParameter['ChildKPIDetailID'] = childKPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertKPIDetailRequestDto} insertKPIDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIDetail: async (insertKPIDetailRequestDto: InsertKPIDetailRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertKPIDetailRequestDto' is not null or undefined
            assertParamExists('kPIInsertKPIDetail', 'insertKPIDetailRequestDto', insertKPIDetailRequestDto)
            const localVarPath = `/api/KPI/InsertKPIDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertKPIDetailRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertUserFavouriteKPIRequest} insertUserFavouriteKPIRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertUserFavouriteKPI: async (insertUserFavouriteKPIRequest: InsertUserFavouriteKPIRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertUserFavouriteKPIRequest' is not null or undefined
            assertParamExists('kPIInsertUserFavouriteKPI', 'insertUserFavouriteKPIRequest', insertUserFavouriteKPIRequest)
            const localVarPath = `/api/KPI/InsertUserFavouriteKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertUserFavouriteKPIRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [annualGoalID] 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIKPIDetailsGraficoA3: async (projectID?: string, annualGoalID?: string, kPIDetailID?: string, year?: number, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/KPIDetailsGraficoA3`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIKPIDetailsPastYears: async (kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/KPIDetailsPastYears`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIRemoveKpiChildParentRelations: async (kPIDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/KPI/RemoveKpiChildParentRelations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateCommentForKPIRequestDto} updateCommentForKPIRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateCommentForKPI: async (updateCommentForKPIRequestDto: UpdateCommentForKPIRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateCommentForKPIRequestDto' is not null or undefined
            assertParamExists('kPIUpdateCommentForKPI', 'updateCommentForKPIRequestDto', updateCommentForKPIRequestDto)
            const localVarPath = `/api/KPI/UpdateCommentForKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateCommentForKPIRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateGlobalKPIBowlingChartImportRequestDto>} updateGlobalKPIBowlingChartImportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateGlobalKPIBowlingChartImport: async (updateGlobalKPIBowlingChartImportRequestDto: Array<UpdateGlobalKPIBowlingChartImportRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGlobalKPIBowlingChartImportRequestDto' is not null or undefined
            assertParamExists('kPIUpdateGlobalKPIBowlingChartImport', 'updateGlobalKPIBowlingChartImportRequestDto', updateGlobalKPIBowlingChartImportRequestDto)
            const localVarPath = `/api/KPI/UpdateGlobalKPIBowlingChartImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGlobalKPIBowlingChartImportRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateKPIAnnualGoalBowlingChartRequestDto} updateKPIAnnualGoalBowlingChartRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateKPIAnnualGoalBowlingChart: async (updateKPIAnnualGoalBowlingChartRequestDto: UpdateKPIAnnualGoalBowlingChartRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateKPIAnnualGoalBowlingChartRequestDto' is not null or undefined
            assertParamExists('kPIUpdateKPIAnnualGoalBowlingChart', 'updateKPIAnnualGoalBowlingChartRequestDto', updateKPIAnnualGoalBowlingChartRequestDto)
            const localVarPath = `/api/KPI/UpdateKPIAnnualGoalBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKPIAnnualGoalBowlingChartRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateKPIDetailRequestDto} updateKPIDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateKPIDetail: async (updateKPIDetailRequestDto: UpdateKPIDetailRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateKPIDetailRequestDto' is not null or undefined
            assertParamExists('kPIUpdateKPIDetail', 'updateKPIDetailRequestDto', updateKPIDetailRequestDto)
            const localVarPath = `/api/KPI/UpdateKPIDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKPIDetailRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>} updateSpecificAnnualGoalKPIBowlingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateSpecificAnnualGoalKPIBowlingChartImport: async (updateSpecificAnnualGoalKPIBowlingRequestDto: Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSpecificAnnualGoalKPIBowlingRequestDto' is not null or undefined
            assertParamExists('kPIUpdateSpecificAnnualGoalKPIBowlingChartImport', 'updateSpecificAnnualGoalKPIBowlingRequestDto', updateSpecificAnnualGoalKPIBowlingRequestDto)
            const localVarPath = `/api/KPI/UpdateSpecificAnnualGoalKPIBowlingChartImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSpecificAnnualGoalKPIBowlingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UpdateSpecificKPIBowlingChartImportRequestDto>} updateSpecificKPIBowlingChartImportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateSpecificKPIBowlingChartImport: async (updateSpecificKPIBowlingChartImportRequestDto: Array<UpdateSpecificKPIBowlingChartImportRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSpecificKPIBowlingChartImportRequestDto' is not null or undefined
            assertParamExists('kPIUpdateSpecificKPIBowlingChartImport', 'updateSpecificKPIBowlingChartImportRequestDto', updateSpecificKPIBowlingChartImportRequestDto)
            const localVarPath = `/api/KPI/UpdateSpecificKPIBowlingChartImport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSpecificKPIBowlingChartImportRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * KPIApi - functional programming interface
 * @export
 */
export const KPIApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = KPIApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string | null} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPICheckDuplicateKPI(name?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPICheckDuplicateKPI(name, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPICheckDuplicateKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [iD] 
         * @param {string | null} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPICheckKpiTypePlanningStartDate(iD?: string, type?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfCheckKpiTypePlanningStartDateResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPICheckKpiTypePlanningStartDate(iD, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPICheckKpiTypePlanningStartDate']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [annualGoalKPIRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIDeleteAnnualGoalKPIRelationshipBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIDeleteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIDeleteKPI(kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIDeleteKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIDeleteUserFavouriteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIDeleteUserFavouriteKPI(kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIDeleteUserFavouriteKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIDetailsKPI(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfDetailsKPIResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIDetailsKPI(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIDetailsKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetAnnualGoalByKPI(kPIDetailID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetAnnualGoalByKPIResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetAnnualGoalByKPI(kPIDetailID, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetAnnualGoalByKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [annualGoalID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfAnnualGoalKPIBowling>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetAnnualGoalKPIBowlingChartListPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string | null} [projectAnnualGoalID] 
         * @param {string | null} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetCommentForKPI(kPIDetailID?: string, projectAnnualGoalID?: string | null, type?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetKPICommentResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetCommentForKPI(kPIDetailID, projectAnnualGoalID, type, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetCommentForKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetGlobalKPIBowlingChartExport(kPIDetailID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetGlobalKPIBowlingChartExport(kPIDetailID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetGlobalKPIBowlingChartExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIAnnualGoalBowlingChart(id?: string | null, year?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIAnnualGoalBowlingChart(id, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIAnnualGoalBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIBowlingChartListPage(projectID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfKPIBowlingChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIBowlingChartListPage(projectID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIBowlingChartListPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string | null} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIByAnnualGoal(annualGoalID?: string, xMatrix?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIByAnnualGoalResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIByAnnualGoal(annualGoalID, xMatrix, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIByAnnualGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIById(id?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetKPIListPageDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIById(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIByProject(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIByProjectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIByProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIByProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIChildPicklist(parentKPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIChildPicklistResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIChildPicklist(parentKPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIChildPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string | null} [annualGoalID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIDashboardSintesiByAnnualGoal(month?: number, annualGoalID?: string | null, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIDashboardSintesiByAnnualGoal(month, annualGoalID, xMatrixID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIDashboardSintesiByAnnualGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string | null} [projectID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIDashboardSintesiByProject(month?: number, projectID?: string | null, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIDashboardSintesiByProjectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIDashboardSintesiByProject(month, projectID, xMatrixID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIDashboardSintesiByProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIDetail(kPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetKPIsDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIDetail(kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIDetails(id?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetKPIDetailDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIDetails(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIDetails']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string | null} [searchType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIGerarchicoInfo(kPIDetailID?: string, searchType?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIGerarchicoInfoDTO>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIGerarchicoInfo(kPIDetailID, searchType, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIGerarchicoInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [xMatrixID] 
         * @param {string} [annualGoalID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIGraphic(month?: number, xMatrixID?: string, annualGoalID?: string, projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIGraphicResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIGraphic(month, xMatrixID, annualGoalID, projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIGraphic']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {number} [year] 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIPlanningByID(projectID?: string | null, annualGoalID?: string | null, year?: number, kPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfKPIBowlingChartResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIPlanningByID(projectID, annualGoalID, year, kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIPlanningByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPISectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPISectionA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPISectionA3(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPISectionA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetKPIs(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIListDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetKPIs(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetKPIs']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetLongTermGoalByKPI(kPIDetailID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetLongTermGoalByKPIResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetLongTermGoalByKPI(kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetLongTermGoalByKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetRollupKPIChild(parentKPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetRollupKPIChildResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetRollupKPIChild(parentKPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetRollupKPIChild']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [annualGoalKPIRelationShipID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetSpecificAnnualGoalKPIBowlingChartExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [kPIProjectRelationShipID] 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID?: string | null, projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID, projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIGetSpecificKPIBowlingChartExport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIInsertKPIAnnualGoal(kPIDetailID?: string, annualGoalID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIInsertKPIAnnualGoal(kPIDetailID, annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIInsertKPIAnnualGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {string} [childKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIInsertKPIChild(parentKPIDetailID?: string, childKPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIInsertKPIChild(parentKPIDetailID, childKPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIInsertKPIChild']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertKPIDetailRequestDto} insertKPIDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIInsertKPIDetail(insertKPIDetailRequestDto: InsertKPIDetailRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertKPIDetailResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIInsertKPIDetail(insertKPIDetailRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIInsertKPIDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertUserFavouriteKPIRequest} insertUserFavouriteKPIRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest: InsertUserFavouriteKPIRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIInsertUserFavouriteKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [annualGoalID] 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIKPIDetailsGraficoA3(projectID?: string, annualGoalID?: string, kPIDetailID?: string, year?: number, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfKPIDetailsGraficoA3ResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIKPIDetailsGraficoA3(projectID, annualGoalID, kPIDetailID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIKPIDetailsGraficoA3']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIKPIDetailsPastYears(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfKPIDetailsPastYearsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIKPIDetailsPastYears(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIKPIDetailsPastYears']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIRemoveKpiChildParentRelations(kPIDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIRemoveKpiChildParentRelations(kPIDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIRemoveKpiChildParentRelations']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateCommentForKPIRequestDto} updateCommentForKPIRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateCommentForKPI(updateCommentForKPIRequestDto: UpdateCommentForKPIRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateCommentForKPI(updateCommentForKPIRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateCommentForKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<UpdateGlobalKPIBowlingChartImportRequestDto>} updateGlobalKPIBowlingChartImportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto: Array<UpdateGlobalKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateGlobalKPIBowlingChartImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateKPIAnnualGoalBowlingChartRequestDto} updateKPIAnnualGoalBowlingChartRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto: UpdateKPIAnnualGoalBowlingChartRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateKPIAnnualGoalBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateKPIDetailRequestDto} updateKPIDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateKPIDetail(updateKPIDetailRequestDto: UpdateKPIDetailRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfKPIDetailsDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateKPIDetail(updateKPIDetailRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateKPIDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>} updateSpecificAnnualGoalKPIBowlingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto: Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateSpecificAnnualGoalKPIBowlingChartImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<UpdateSpecificKPIBowlingChartImportRequestDto>} updateSpecificKPIBowlingChartImportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto: Array<UpdateSpecificKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['KPIApi.kPIUpdateSpecificKPIBowlingChartImport']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * KPIApi - factory interface
 * @export
 */
export const KPIApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = KPIApiFp(configuration)
    return {
        /**
         * 
         * @param {string | null} [name] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPICheckDuplicateKPI(name?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.kPICheckDuplicateKPI(name, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [iD] 
         * @param {string | null} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPICheckKpiTypePlanningStartDate(iD?: string, type?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfCheckKpiTypePlanningStartDateResponseDto> {
            return localVarFp.kPICheckKpiTypePlanningStartDate(iD, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [annualGoalKPIRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.kPIDeleteKPI(kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDeleteUserFavouriteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.kPIDeleteUserFavouriteKPI(kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIDetailsKPI(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfDetailsKPIResponseDto> {
            return localVarFp.kPIDetailsKPI(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetAnnualGoalByKPI(kPIDetailID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetAnnualGoalByKPIResponseDto> {
            return localVarFp.kPIGetAnnualGoalByKPI(kPIDetailID, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [annualGoalID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfAnnualGoalKPIBowling> {
            return localVarFp.kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string | null} [projectAnnualGoalID] 
         * @param {string | null} [type] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetCommentForKPI(kPIDetailID?: string, projectAnnualGoalID?: string | null, type?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetKPICommentResponse> {
            return localVarFp.kPIGetCommentForKPI(kPIDetailID, projectAnnualGoalID, type, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetGlobalKPIBowlingChartExport(kPIDetailID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.kPIGetGlobalKPIBowlingChartExport(kPIDetailID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIAnnualGoalBowlingChart(id?: string | null, year?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.kPIGetKPIAnnualGoalBowlingChart(id, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIBowlingChartListPage(projectID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfKPIBowlingChartResponse> {
            return localVarFp.kPIGetKPIBowlingChartListPage(projectID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string | null} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIByAnnualGoal(annualGoalID?: string, xMatrix?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIByAnnualGoalResponseDto> {
            return localVarFp.kPIGetKPIByAnnualGoal(annualGoalID, xMatrix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIById(id?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetKPIListPageDto> {
            return localVarFp.kPIGetKPIById(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIByProject(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIByProjectResponseDto> {
            return localVarFp.kPIGetKPIByProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIChildPicklist(parentKPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIChildPicklistResponse> {
            return localVarFp.kPIGetKPIChildPicklist(parentKPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string | null} [annualGoalID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDashboardSintesiByAnnualGoal(month?: number, annualGoalID?: string | null, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIDashboardSintesiByAnnualGoalResponseDto> {
            return localVarFp.kPIGetKPIDashboardSintesiByAnnualGoal(month, annualGoalID, xMatrixID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string | null} [projectID] 
         * @param {string | null} [xMatrixID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDashboardSintesiByProject(month?: number, projectID?: string | null, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIDashboardSintesiByProjectResponseDto> {
            return localVarFp.kPIGetKPIDashboardSintesiByProject(month, projectID, xMatrixID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDetail(kPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetKPIsDetailDto> {
            return localVarFp.kPIGetKPIDetail(kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIDetails(id?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetKPIDetailDto> {
            return localVarFp.kPIGetKPIDetails(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string | null} [searchType] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIGerarchicoInfo(kPIDetailID?: string, searchType?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIGerarchicoInfoDTO> {
            return localVarFp.kPIGetKPIGerarchicoInfo(kPIDetailID, searchType, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [month] 
         * @param {string} [xMatrixID] 
         * @param {string} [annualGoalID] 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIGraphic(month?: number, xMatrixID?: string, annualGoalID?: string, projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIGraphicResponseDto> {
            return localVarFp.kPIGetKPIGraphic(month, xMatrixID, annualGoalID, projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIList(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIListDto> {
            return localVarFp.kPIGetKPIList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {number} [year] 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIPlanningByID(projectID?: string | null, annualGoalID?: string | null, year?: number, kPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfKPIBowlingChartResponse> {
            return localVarFp.kPIGetKPIPlanningByID(projectID, annualGoalID, year, kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPISectionA3(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPISectionA3ResponseDto> {
            return localVarFp.kPIGetKPISectionA3(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetKPIs(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIListDto> {
            return localVarFp.kPIGetKPIs(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetLongTermGoalByKPI(kPIDetailID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetLongTermGoalByKPIResponseDto> {
            return localVarFp.kPIGetLongTermGoalByKPI(kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetRollupKPIChild(parentKPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetRollupKPIChildResponseDto> {
            return localVarFp.kPIGetRollupKPIChild(parentKPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [annualGoalKPIRelationShipID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [kPIProjectRelationShipID] 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID?: string | null, projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID, projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {string} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIAnnualGoal(kPIDetailID?: string, annualGoalID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.kPIInsertKPIAnnualGoal(kPIDetailID, annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [parentKPIDetailID] 
         * @param {string} [childKPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIChild(parentKPIDetailID?: string, childKPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.kPIInsertKPIChild(parentKPIDetailID, childKPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertKPIDetailRequestDto} insertKPIDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertKPIDetail(insertKPIDetailRequestDto: InsertKPIDetailRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertKPIDetailResponse> {
            return localVarFp.kPIInsertKPIDetail(insertKPIDetailRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertUserFavouriteKPIRequest} insertUserFavouriteKPIRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest: InsertUserFavouriteKPIRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [annualGoalID] 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIKPIDetailsGraficoA3(projectID?: string, annualGoalID?: string, kPIDetailID?: string, year?: number, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfKPIDetailsGraficoA3ResponseDto> {
            return localVarFp.kPIKPIDetailsGraficoA3(projectID, annualGoalID, kPIDetailID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {number} [year] 
         * @param {string | null} [xMatrixID] 
         * @param {string | null} [projectID] 
         * @param {string | null} [annualGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIKPIDetailsPastYears(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfKPIDetailsPastYearsResponseDto> {
            return localVarFp.kPIKPIDetailsPastYears(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIRemoveKpiChildParentRelations(kPIDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.kPIRemoveKpiChildParentRelations(kPIDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateCommentForKPIRequestDto} updateCommentForKPIRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateCommentForKPI(updateCommentForKPIRequestDto: UpdateCommentForKPIRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.kPIUpdateCommentForKPI(updateCommentForKPIRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UpdateGlobalKPIBowlingChartImportRequestDto>} updateGlobalKPIBowlingChartImportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto: Array<UpdateGlobalKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateKPIAnnualGoalBowlingChartRequestDto} updateKPIAnnualGoalBowlingChartRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto: UpdateKPIAnnualGoalBowlingChartRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateKPIDetailRequestDto} updateKPIDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateKPIDetail(updateKPIDetailRequestDto: UpdateKPIDetailRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfKPIDetailsDto> {
            return localVarFp.kPIUpdateKPIDetail(updateKPIDetailRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>} updateSpecificAnnualGoalKPIBowlingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto: Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UpdateSpecificKPIBowlingChartImportRequestDto>} updateSpecificKPIBowlingChartImportRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto: Array<UpdateSpecificKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * KPIApi - object-oriented interface
 * @export
 * @class KPIApi
 * @extends {BaseAPI}
 */
export class KPIApi extends BaseAPI {
    /**
     * 
     * @param {string | null} [name] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPICheckDuplicateKPI(name?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPICheckDuplicateKPI(name, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [iD] 
     * @param {string | null} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPICheckKpiTypePlanningStartDate(iD?: string, type?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPICheckKpiTypePlanningStartDate(iD, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [annualGoalKPIRelationShipID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIDeleteAnnualGoalKPIRelationshipBowlingChart(annualGoalKPIRelationShipID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIDeleteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIDeleteKPI(kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIDeleteUserFavouriteKPI(kPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIDeleteUserFavouriteKPI(kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {number} [year] 
     * @param {string | null} [xMatrixID] 
     * @param {string | null} [projectID] 
     * @param {string | null} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIDetailsKPI(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIDetailsKPI(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetAnnualGoalByKPI(kPIDetailID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetAnnualGoalByKPI(kPIDetailID, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [annualGoalID] 
     * @param {string | null} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID?: string | null, year?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetAnnualGoalKPIBowlingChartListPage(annualGoalID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {string | null} [projectAnnualGoalID] 
     * @param {string | null} [type] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetCommentForKPI(kPIDetailID?: string, projectAnnualGoalID?: string | null, type?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetCommentForKPI(kPIDetailID, projectAnnualGoalID, type, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [kPIDetailID] 
     * @param {string | null} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetGlobalKPIBowlingChartExport(kPIDetailID?: string | null, year?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetGlobalKPIBowlingChartExport(kPIDetailID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [id] 
     * @param {string | null} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIAnnualGoalBowlingChart(id?: string | null, year?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIAnnualGoalBowlingChart(id, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string | null} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIBowlingChartListPage(projectID?: string | null, year?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIBowlingChartListPage(projectID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [annualGoalID] 
     * @param {string | null} [xMatrix] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIByAnnualGoal(annualGoalID?: string, xMatrix?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIByAnnualGoal(annualGoalID, xMatrix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIById(id?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIById(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIByProject(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIByProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [parentKPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIChildPicklist(parentKPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIChildPicklist(parentKPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [month] 
     * @param {string | null} [annualGoalID] 
     * @param {string | null} [xMatrixID] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIDashboardSintesiByAnnualGoal(month?: number, annualGoalID?: string | null, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIDashboardSintesiByAnnualGoal(month, annualGoalID, xMatrixID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [month] 
     * @param {string | null} [projectID] 
     * @param {string | null} [xMatrixID] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIDashboardSintesiByProject(month?: number, projectID?: string | null, xMatrixID?: string | null, year?: number, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIDashboardSintesiByProject(month, projectID, xMatrixID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIDetail(kPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIDetail(kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [id] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIDetails(id?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIDetails(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {string | null} [searchType] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIGerarchicoInfo(kPIDetailID?: string, searchType?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIGerarchicoInfo(kPIDetailID, searchType, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {number} [month] 
     * @param {string} [xMatrixID] 
     * @param {string} [annualGoalID] 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIGraphic(month?: number, xMatrixID?: string, annualGoalID?: string, projectID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIGraphic(month, xMatrixID, annualGoalID, projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIList(options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string | null} [annualGoalID] 
     * @param {number} [year] 
     * @param {string} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIPlanningByID(projectID?: string | null, annualGoalID?: string | null, year?: number, kPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIPlanningByID(projectID, annualGoalID, year, kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPISectionA3(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPISectionA3(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetKPIs(options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetKPIs(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetLongTermGoalByKPI(kPIDetailID?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetLongTermGoalByKPI(kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [parentKPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetRollupKPIChild(parentKPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetRollupKPIChild(parentKPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [annualGoalKPIRelationShipID] 
     * @param {string | null} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID?: string | null, year?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetSpecificAnnualGoalKPIBowlingChartExport(annualGoalKPIRelationShipID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [kPIProjectRelationShipID] 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID?: string | null, projectID?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIGetSpecificKPIBowlingChartExport(kPIProjectRelationShipID, projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {string} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIInsertKPIAnnualGoal(kPIDetailID?: string, annualGoalID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIInsertKPIAnnualGoal(kPIDetailID, annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [parentKPIDetailID] 
     * @param {string} [childKPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIInsertKPIChild(parentKPIDetailID?: string, childKPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIInsertKPIChild(parentKPIDetailID, childKPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertKPIDetailRequestDto} insertKPIDetailRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIInsertKPIDetail(insertKPIDetailRequestDto: InsertKPIDetailRequestDto, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIInsertKPIDetail(insertKPIDetailRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertUserFavouriteKPIRequest} insertUserFavouriteKPIRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest: InsertUserFavouriteKPIRequest, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIInsertUserFavouriteKPI(insertUserFavouriteKPIRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {string} [annualGoalID] 
     * @param {string} [kPIDetailID] 
     * @param {number} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIKPIDetailsGraficoA3(projectID?: string, annualGoalID?: string, kPIDetailID?: string, year?: number, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIKPIDetailsGraficoA3(projectID, annualGoalID, kPIDetailID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {number} [year] 
     * @param {string | null} [xMatrixID] 
     * @param {string | null} [projectID] 
     * @param {string | null} [annualGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIKPIDetailsPastYears(kPIDetailID?: string, year?: number, xMatrixID?: string | null, projectID?: string | null, annualGoalID?: string | null, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIKPIDetailsPastYears(kPIDetailID, year, xMatrixID, projectID, annualGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIRemoveKpiChildParentRelations(kPIDetailID?: string, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIRemoveKpiChildParentRelations(kPIDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateCommentForKPIRequestDto} updateCommentForKPIRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateCommentForKPI(updateCommentForKPIRequestDto: UpdateCommentForKPIRequestDto, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateCommentForKPI(updateCommentForKPIRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UpdateGlobalKPIBowlingChartImportRequestDto>} updateGlobalKPIBowlingChartImportRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto: Array<UpdateGlobalKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateGlobalKPIBowlingChartImport(updateGlobalKPIBowlingChartImportRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateKPIAnnualGoalBowlingChartRequestDto} updateKPIAnnualGoalBowlingChartRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto: UpdateKPIAnnualGoalBowlingChartRequestDto, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateKPIAnnualGoalBowlingChart(updateKPIAnnualGoalBowlingChartRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateKPIDetailRequestDto} updateKPIDetailRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateKPIDetail(updateKPIDetailRequestDto: UpdateKPIDetailRequestDto, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateKPIDetail(updateKPIDetailRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>} updateSpecificAnnualGoalKPIBowlingRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto: Array<UpdateSpecificAnnualGoalKPIBowlingRequestDto>, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateSpecificAnnualGoalKPIBowlingChartImport(updateSpecificAnnualGoalKPIBowlingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UpdateSpecificKPIBowlingChartImportRequestDto>} updateSpecificKPIBowlingChartImportRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof KPIApi
     */
    public kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto: Array<UpdateSpecificKPIBowlingChartImportRequestDto>, options?: RawAxiosRequestConfig) {
        return KPIApiFp(this.configuration).kPIUpdateSpecificKPIBowlingChartImport(updateSpecificKPIBowlingChartImportRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LanguageApi - axios parameter creator
 * @export
 */
export const LanguageApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetCalculationTypesByLanguageCode: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/GetCalculationTypesByLanguageCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetLicenseTypesByLanguageCode: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/GetLicenseTypesByLanguageCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetLongTermGoalTypeByLanguageCode: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/GetLongTermGoalTypeByLanguageCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetStatusTypeByLanguageCode: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/GetStatusTypeByLanguageCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetSummaryFeedTypesByLanguageCode: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Language/GetSummaryFeedTypesByLanguageCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['LanguageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LanguageApi - functional programming interface
 * @export
 */
export const LanguageApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LanguageApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languageGetCalculationTypesByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetCalculationTypesByLanguageCodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languageGetCalculationTypesByLanguageCode(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.languageGetCalculationTypesByLanguageCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languageGetLicenseTypesByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetLicenseTypesByLanguageCodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languageGetLicenseTypesByLanguageCode(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.languageGetLicenseTypesByLanguageCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languageGetLongTermGoalTypeByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languageGetLongTermGoalTypeByLanguageCode(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.languageGetLongTermGoalTypeByLanguageCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languageGetStatusTypeByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetStatusTypeByLanguageCodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languageGetStatusTypeByLanguageCode(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.languageGetStatusTypeByLanguageCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async languageGetSummaryFeedTypesByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.languageGetSummaryFeedTypesByLanguageCode(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LanguageApi.languageGetSummaryFeedTypesByLanguageCode']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LanguageApi - factory interface
 * @export
 */
export const LanguageApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LanguageApiFp(configuration)
    return {
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetCalculationTypesByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetCalculationTypesByLanguageCodeResponseDto> {
            return localVarFp.languageGetCalculationTypesByLanguageCode(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetLicenseTypesByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetLicenseTypesByLanguageCodeResponseDto> {
            return localVarFp.languageGetLicenseTypesByLanguageCode(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetLongTermGoalTypeByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetLongTermGoalTypeByLanguageCodeResponseDto> {
            return localVarFp.languageGetLongTermGoalTypeByLanguageCode(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetStatusTypeByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetStatusTypeByLanguageCodeResponseDto> {
            return localVarFp.languageGetStatusTypeByLanguageCode(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        languageGetSummaryFeedTypesByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetSummaryFeedTypesByLanguageCodeResponseDto> {
            return localVarFp.languageGetSummaryFeedTypesByLanguageCode(languageCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LanguageApi - object-oriented interface
 * @export
 * @class LanguageApi
 * @extends {BaseAPI}
 */
export class LanguageApi extends BaseAPI {
    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public languageGetCalculationTypesByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).languageGetCalculationTypesByLanguageCode(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public languageGetLicenseTypesByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).languageGetLicenseTypesByLanguageCode(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public languageGetLongTermGoalTypeByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).languageGetLongTermGoalTypeByLanguageCode(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public languageGetStatusTypeByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).languageGetStatusTypeByLanguageCode(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LanguageApi
     */
    public languageGetSummaryFeedTypesByLanguageCode(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return LanguageApiFp(this.configuration).languageGetSummaryFeedTypesByLanguageCode(languageCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LongTermGoalApi - axios parameter creator
 * @export
 */
export const LongTermGoalApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalCategoryIconlist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/CategoryIconlist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [longTermGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalDeleteLongTermGoal: async (longTermGoalID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/DeleteLongTermGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (longTermGoalID !== undefined) {
                localVarQueryParameter['LongTermGoalID'] = longTermGoalID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [goalCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalCategoryByID: async (goalCategoryID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetGoalCategoryByID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (goalCategoryID !== undefined) {
                localVarQueryParameter['goalCategoryID'] = goalCategoryID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalCategoryList: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetGoalCategoryList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalTypePicklist: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetGoalTypePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string> | null} [annualGoal] 
         * @param {Array<string> | null} [longTermGoal] 
         * @param {Array<string> | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetKPIPicklist: async (annualGoal?: Array<string> | null, longTermGoal?: Array<string> | null, projectID?: Array<string> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetKPIPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (annualGoal) {
                localVarQueryParameter['AnnualGoal'] = annualGoal;
            }

            if (longTermGoal) {
                localVarQueryParameter['LongTermGoal'] = longTermGoal;
            }

            if (projectID) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {string | null} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetLongTermGoalById: async (id?: string | null, xMatrix?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetLongTermGoalById`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (id !== undefined) {
                localVarQueryParameter['id'] = id;
            }

            if (xMatrix !== undefined) {
                localVarQueryParameter['xMatrix'] = xMatrix;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [xMatrixIDReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetLongTermGoals: async (xMatrixIDReq?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/LongTermGoal/GetLongTermGoals`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixIDReq !== undefined) {
                localVarQueryParameter['XMatrixIDReq'] = xMatrixIDReq;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertGoalCategoryRequestDto} insertGoalCategoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertGoalCategory: async (insertGoalCategoryRequestDto: InsertGoalCategoryRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertGoalCategoryRequestDto' is not null or undefined
            assertParamExists('longTermGoalInsertGoalCategory', 'insertGoalCategoryRequestDto', insertGoalCategoryRequestDto)
            const localVarPath = `/api/LongTermGoal/InsertGoalCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertGoalCategoryRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertLongTermGoalRequestDto} insertLongTermGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertLongTermGoal: async (insertLongTermGoalRequestDto: InsertLongTermGoalRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertLongTermGoalRequestDto' is not null or undefined
            assertParamExists('longTermGoalInsertLongTermGoal', 'insertLongTermGoalRequestDto', insertLongTermGoalRequestDto)
            const localVarPath = `/api/LongTermGoal/InsertLongTermGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertLongTermGoalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertLongTermGoalXRequestDto} insertLongTermGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertLongTermGoalXMatrix: async (insertLongTermGoalXRequestDto: InsertLongTermGoalXRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertLongTermGoalXRequestDto' is not null or undefined
            assertParamExists('longTermGoalInsertLongTermGoalXMatrix', 'insertLongTermGoalXRequestDto', insertLongTermGoalXRequestDto)
            const localVarPath = `/api/LongTermGoal/InsertLongTermGoalXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertLongTermGoalXRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGoalCategoryRequestDto} updateGoalCategoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateGoalCategory: async (updateGoalCategoryRequestDto: UpdateGoalCategoryRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGoalCategoryRequestDto' is not null or undefined
            assertParamExists('longTermGoalUpdateGoalCategory', 'updateGoalCategoryRequestDto', updateGoalCategoryRequestDto)
            const localVarPath = `/api/LongTermGoal/UpdateGoalCategory`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGoalCategoryRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLongTermGoalRequestDto} updateLongTermGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateLongTermGoal: async (updateLongTermGoalRequestDto: UpdateLongTermGoalRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLongTermGoalRequestDto' is not null or undefined
            assertParamExists('longTermGoalUpdateLongTermGoal', 'updateLongTermGoalRequestDto', updateLongTermGoalRequestDto)
            const localVarPath = `/api/LongTermGoal/UpdateLongTermGoal`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLongTermGoalRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLongTermGoalXRequestDto} updateLongTermGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateLongTermGoalXMatrix: async (updateLongTermGoalXRequestDto: UpdateLongTermGoalXRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLongTermGoalXRequestDto' is not null or undefined
            assertParamExists('longTermGoalUpdateLongTermGoalXMatrix', 'updateLongTermGoalXRequestDto', updateLongTermGoalXRequestDto)
            const localVarPath = `/api/LongTermGoal/UpdateLongTermGoalXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLongTermGoalXRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LongTermGoalApi - functional programming interface
 * @export
 */
export const LongTermGoalApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LongTermGoalApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalCategoryIconlist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGoalCategoryIconlistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalCategoryIconlist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalCategoryIconlist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [longTermGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalDeleteLongTermGoal(longTermGoalID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalDeleteLongTermGoal(longTermGoalID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalDeleteLongTermGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [goalCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetGoalCategoryByID(goalCategoryID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetGoalCategoryByIDResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetGoalCategoryByID(goalCategoryID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetGoalCategoryByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetGoalCategoryList(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetGoalCategoryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetGoalCategoryList(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetGoalCategoryList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetGoalTypePicklist(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetGoalTypePicklistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetGoalTypePicklist(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetGoalTypePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string> | null} [annualGoal] 
         * @param {Array<string> | null} [longTermGoal] 
         * @param {Array<string> | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetKPIPicklist(annualGoal?: Array<string> | null, longTermGoal?: Array<string> | null, projectID?: Array<string> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfKPIPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetKPIPicklist(annualGoal, longTermGoal, projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetKPIPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {string | null} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetLongTermGoalById(id?: string | null, xMatrix?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfLongTermObjectiveByIdResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetLongTermGoalById(id, xMatrix, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetLongTermGoalById']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [xMatrixIDReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalGetLongTermGoals(xMatrixIDReq?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfLongTermObjectiveDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalGetLongTermGoals(xMatrixIDReq, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalGetLongTermGoals']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertGoalCategoryRequestDto} insertGoalCategoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto: InsertGoalCategoryRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalInsertGoalCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertLongTermGoalRequestDto} insertLongTermGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto: InsertLongTermGoalRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertLongTermGoalResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalInsertLongTermGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertLongTermGoalXRequestDto} insertLongTermGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto: InsertLongTermGoalXRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalInsertLongTermGoalXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateGoalCategoryRequestDto} updateGoalCategoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto: UpdateGoalCategoryRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalUpdateGoalCategory']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateLongTermGoalRequestDto} updateLongTermGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto: UpdateLongTermGoalRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfUpdateLongTermGoalResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalUpdateLongTermGoal']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateLongTermGoalXRequestDto} updateLongTermGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto: UpdateLongTermGoalXRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LongTermGoalApi.longTermGoalUpdateLongTermGoalXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LongTermGoalApi - factory interface
 * @export
 */
export const LongTermGoalApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LongTermGoalApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalCategoryIconlist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGoalCategoryIconlistResponseDto> {
            return localVarFp.longTermGoalCategoryIconlist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [longTermGoalID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalDeleteLongTermGoal(longTermGoalID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.longTermGoalDeleteLongTermGoal(longTermGoalID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [goalCategoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalCategoryByID(goalCategoryID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetGoalCategoryByIDResponseDto> {
            return localVarFp.longTermGoalGetGoalCategoryByID(goalCategoryID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalCategoryList(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetGoalCategoryResponseDto> {
            return localVarFp.longTermGoalGetGoalCategoryList(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetGoalTypePicklist(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetGoalTypePicklistDto> {
            return localVarFp.longTermGoalGetGoalTypePicklist(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string> | null} [annualGoal] 
         * @param {Array<string> | null} [longTermGoal] 
         * @param {Array<string> | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetKPIPicklist(annualGoal?: Array<string> | null, longTermGoal?: Array<string> | null, projectID?: Array<string> | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfKPIPicklistResponseDto> {
            return localVarFp.longTermGoalGetKPIPicklist(annualGoal, longTermGoal, projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [id] 
         * @param {string | null} [xMatrix] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetLongTermGoalById(id?: string | null, xMatrix?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfLongTermObjectiveByIdResponse> {
            return localVarFp.longTermGoalGetLongTermGoalById(id, xMatrix, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [xMatrixIDReq] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalGetLongTermGoals(xMatrixIDReq?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfLongTermObjectiveDto> {
            return localVarFp.longTermGoalGetLongTermGoals(xMatrixIDReq, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertGoalCategoryRequestDto} insertGoalCategoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto: InsertGoalCategoryRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertLongTermGoalRequestDto} insertLongTermGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto: InsertLongTermGoalRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertLongTermGoalResponseDto> {
            return localVarFp.longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertLongTermGoalXRequestDto} insertLongTermGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto: InsertLongTermGoalXRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGoalCategoryRequestDto} updateGoalCategoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto: UpdateGoalCategoryRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLongTermGoalRequestDto} updateLongTermGoalRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto: UpdateLongTermGoalRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfUpdateLongTermGoalResponse> {
            return localVarFp.longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLongTermGoalXRequestDto} updateLongTermGoalXRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto: UpdateLongTermGoalXRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LongTermGoalApi - object-oriented interface
 * @export
 * @class LongTermGoalApi
 * @extends {BaseAPI}
 */
export class LongTermGoalApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalCategoryIconlist(options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalCategoryIconlist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [longTermGoalID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalDeleteLongTermGoal(longTermGoalID?: string, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalDeleteLongTermGoal(longTermGoalID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [goalCategoryID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetGoalCategoryByID(goalCategoryID?: string, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetGoalCategoryByID(goalCategoryID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetGoalCategoryList(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetGoalCategoryList(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetGoalTypePicklist(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetGoalTypePicklist(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string> | null} [annualGoal] 
     * @param {Array<string> | null} [longTermGoal] 
     * @param {Array<string> | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetKPIPicklist(annualGoal?: Array<string> | null, longTermGoal?: Array<string> | null, projectID?: Array<string> | null, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetKPIPicklist(annualGoal, longTermGoal, projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [id] 
     * @param {string | null} [xMatrix] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetLongTermGoalById(id?: string | null, xMatrix?: string | null, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetLongTermGoalById(id, xMatrix, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [xMatrixIDReq] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalGetLongTermGoals(xMatrixIDReq?: string | null, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalGetLongTermGoals(xMatrixIDReq, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertGoalCategoryRequestDto} insertGoalCategoryRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto: InsertGoalCategoryRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalInsertGoalCategory(insertGoalCategoryRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertLongTermGoalRequestDto} insertLongTermGoalRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto: InsertLongTermGoalRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalInsertLongTermGoal(insertLongTermGoalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertLongTermGoalXRequestDto} insertLongTermGoalXRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto: InsertLongTermGoalXRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalInsertLongTermGoalXMatrix(insertLongTermGoalXRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGoalCategoryRequestDto} updateGoalCategoryRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto: UpdateGoalCategoryRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalUpdateGoalCategory(updateGoalCategoryRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateLongTermGoalRequestDto} updateLongTermGoalRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto: UpdateLongTermGoalRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalUpdateLongTermGoal(updateLongTermGoalRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateLongTermGoalXRequestDto} updateLongTermGoalXRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LongTermGoalApi
     */
    public longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto: UpdateLongTermGoalXRequestDto, options?: RawAxiosRequestConfig) {
        return LongTermGoalApiFp(this.configuration).longTermGoalUpdateLongTermGoalXMatrix(updateLongTermGoalXRequestDto, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PersonalDashboardApi - axios parameter creator
 * @export
 */
export const PersonalDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetActivitySummaryperStatus: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetActivitySummaryperStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetActivitySummaryperTime: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetActivitySummaryperTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetDeliverableSummaryperStatus: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetDeliverableSummaryperStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetDeliverableSummaryperTime: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetDeliverableSummaryperTime`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetFeedSummary: async (userID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetFeedSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetFilterbyXMatrixRequest} getFilterbyXMatrixRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetFilterbyXMatrix: async (getFilterbyXMatrixRequest: GetFilterbyXMatrixRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getFilterbyXMatrixRequest' is not null or undefined
            assertParamExists('personalDashboardGetFilterbyXMatrix', 'getFilterbyXMatrixRequest', getFilterbyXMatrixRequest)
            const localVarPath = `/api/PersonalDashboard/GetFilterbyXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getFilterbyXMatrixRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetKPISummary: async (xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetKPISummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetPersonalDashboardPhases: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetPersonalDashboardPhases`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {GetResourceWorkloadSummaryRequest} getResourceWorkloadSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetResourceWorkloadSummary: async (getResourceWorkloadSummaryRequest: GetResourceWorkloadSummaryRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'getResourceWorkloadSummaryRequest' is not null or undefined
            assertParamExists('personalDashboardGetResourceWorkloadSummary', 'getResourceWorkloadSummaryRequest', getResourceWorkloadSummaryRequest)
            const localVarPath = `/api/PersonalDashboard/GetResourceWorkloadSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(getResourceWorkloadSummaryRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetTeamLeaderPicklistPersonalDashboard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetTeamLeaderPicklistPersonalDashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetUserSelectPersonalDashboard: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetUserSelectPersonalDashboard`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetXMatrixSummary: async (xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/PersonalDashboard/GetXMatrixSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PersonalDashboardApi - functional programming interface
 * @export
 */
export const PersonalDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PersonalDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetActivitySummaryperStatus(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetActivitySummaryperStatusResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetActivitySummaryperStatus(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetActivitySummaryperStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetActivitySummaryperTime(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetActivitySummaryperTimeResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetActivitySummaryperTime(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetActivitySummaryperTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetDeliverableSummaryperStatus(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetDeliverableSummaryperStatusResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetDeliverableSummaryperStatus(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetDeliverableSummaryperStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetDeliverableSummaryperTime(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetDeliverableSummaryperTimeResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetDeliverableSummaryperTime(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetDeliverableSummaryperTime']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetFeedSummary(userID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetFeedSummaryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetFeedSummary(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetFeedSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetFilterbyXMatrixRequest} getFilterbyXMatrixRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest: GetFilterbyXMatrixRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfFilterbyXMatrixResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetFilterbyXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetKPISummary(xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPISummaryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetKPISummary(xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetKPISummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetPersonalDashboardPhases(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfIListOfDeliverableDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetPersonalDashboardPhases(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetPersonalDashboardPhases']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {GetResourceWorkloadSummaryRequest} getResourceWorkloadSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest: GetResourceWorkloadSummaryRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetResourceWorkloadSummaryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetResourceWorkloadSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetTeamLeaderPicklistPersonalDashboard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetUserSelectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetTeamLeaderPicklistPersonalDashboard(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetTeamLeaderPicklistPersonalDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetUserSelectPersonalDashboard(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetUserSelectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetUserSelectPersonalDashboard(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetUserSelectPersonalDashboard']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async personalDashboardGetXMatrixSummary(xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetXMatrixSummaryResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.personalDashboardGetXMatrixSummary(xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PersonalDashboardApi.personalDashboardGetXMatrixSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PersonalDashboardApi - factory interface
 * @export
 */
export const PersonalDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PersonalDashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetActivitySummaryperStatus(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetActivitySummaryperStatusResponseDto> {
            return localVarFp.personalDashboardGetActivitySummaryperStatus(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetActivitySummaryperTime(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetActivitySummaryperTimeResponse> {
            return localVarFp.personalDashboardGetActivitySummaryperTime(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetDeliverableSummaryperStatus(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetDeliverableSummaryperStatusResponseDto> {
            return localVarFp.personalDashboardGetDeliverableSummaryperStatus(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetDeliverableSummaryperTime(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetDeliverableSummaryperTimeResponseDto> {
            return localVarFp.personalDashboardGetDeliverableSummaryperTime(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetFeedSummary(userID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetFeedSummaryResponseDto> {
            return localVarFp.personalDashboardGetFeedSummary(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetFilterbyXMatrixRequest} getFilterbyXMatrixRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest: GetFilterbyXMatrixRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfFilterbyXMatrixResponse> {
            return localVarFp.personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetKPISummary(xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPISummaryResponseDto> {
            return localVarFp.personalDashboardGetKPISummary(xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetPersonalDashboardPhases(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfIListOfDeliverableDto> {
            return localVarFp.personalDashboardGetPersonalDashboardPhases(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {GetResourceWorkloadSummaryRequest} getResourceWorkloadSummaryRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest: GetResourceWorkloadSummaryRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetResourceWorkloadSummaryResponseDto> {
            return localVarFp.personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetTeamLeaderPicklistPersonalDashboard(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetUserSelectResponseDto> {
            return localVarFp.personalDashboardGetTeamLeaderPicklistPersonalDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetUserSelectPersonalDashboard(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetUserSelectResponseDto> {
            return localVarFp.personalDashboardGetUserSelectPersonalDashboard(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        personalDashboardGetXMatrixSummary(xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetXMatrixSummaryResponseDto> {
            return localVarFp.personalDashboardGetXMatrixSummary(xmatrixID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PersonalDashboardApi - object-oriented interface
 * @export
 * @class PersonalDashboardApi
 * @extends {BaseAPI}
 */
export class PersonalDashboardApi extends BaseAPI {
    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetActivitySummaryperStatus(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetActivitySummaryperStatus(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetActivitySummaryperTime(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetActivitySummaryperTime(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetDeliverableSummaryperStatus(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetDeliverableSummaryperStatus(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetDeliverableSummaryperTime(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetDeliverableSummaryperTime(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [userID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetFeedSummary(userID?: string | null, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetFeedSummary(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetFilterbyXMatrixRequest} getFilterbyXMatrixRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest: GetFilterbyXMatrixRequest, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetFilterbyXMatrix(getFilterbyXMatrixRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetKPISummary(xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetKPISummary(xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetPersonalDashboardPhases(options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetPersonalDashboardPhases(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {GetResourceWorkloadSummaryRequest} getResourceWorkloadSummaryRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest: GetResourceWorkloadSummaryRequest, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetResourceWorkloadSummary(getResourceWorkloadSummaryRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetTeamLeaderPicklistPersonalDashboard(options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetTeamLeaderPicklistPersonalDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetUserSelectPersonalDashboard(options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetUserSelectPersonalDashboard(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PersonalDashboardApi
     */
    public personalDashboardGetXMatrixSummary(xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return PersonalDashboardApiFp(this.configuration).personalDashboardGetXMatrixSummary(xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PingApi - axios parameter creator
 * @export
 */
export const PingApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPing: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Ping`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PingApi - functional programming interface
 * @export
 */
export const PingApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PingApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async pingPing(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.pingPing(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PingApi.pingPing']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PingApi - factory interface
 * @export
 */
export const PingApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PingApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pingPing(options?: RawAxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.pingPing(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PingApi - object-oriented interface
 * @export
 * @class PingApi
 * @extends {BaseAPI}
 */
export class PingApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PingApi
     */
    public pingPing(options?: RawAxiosRequestConfig) {
        return PingApiFp(this.configuration).pingPing(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string | null} [projectCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCheckDuplicateProject: async (projectCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/CheckDuplicateProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectCode !== undefined) {
                localVarQueryParameter['projectCode'] = projectCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {CreateProjectRequestDto} createProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateProject: async (createProjectRequestDto: CreateProjectRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'createProjectRequestDto' is not null or undefined
            assertParamExists('projectCreateProject', 'createProjectRequestDto', createProjectRequestDto)
            const localVarPath = `/api/Project/CreateProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(createProjectRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [kPIProjectRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteKPIRelationshipBowlingChart: async (kPIProjectRelationShipID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteKPIRelationshipBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIProjectRelationShipID !== undefined) {
                localVarQueryParameter['KPIProjectRelationShipID'] = kPIProjectRelationShipID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [precautionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeletePrecaution: async (precautionID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeletePrecaution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (precautionID !== undefined) {
                localVarQueryParameter['PrecautionID'] = precautionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProject: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectCost: async (projectCostDetailID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteProjectCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectCostDetailID !== undefined) {
                localVarQueryParameter['ProjectCostDetailID'] = projectCostDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectRepositoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectRepository: async (projectRepositoryID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteProjectRepository`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectRepositoryID !== undefined) {
                localVarQueryParameter['ProjectRepositoryID'] = projectRepositoryID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectRoutineID] 
         * @param {string | null} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectRoutine: async (projectRoutineID?: string, outlookEventId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteProjectRoutine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectRoutineID !== undefined) {
                localVarQueryParameter['ProjectRoutineID'] = projectRoutineID;
            }

            if (outlookEventId !== undefined) {
                localVarQueryParameter['OutlookEventId'] = outlookEventId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [riskID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteRisk: async (riskID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/DeleteRisk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (riskID !== undefined) {
                localVarQueryParameter['RiskID'] = riskID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetDeliverableProjectPicklist: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetDeliverableProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {string | null} [year] 
         * @param {string | null} [isRollupKPI] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetGlobalKPIBowlingChart: async (kPIDetailID?: string | null, year?: string | null, isRollupKPI?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetGlobalKPIBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIDetailID !== undefined) {
                localVarQueryParameter['KPIDetailID'] = kPIDetailID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (isRollupKPI !== undefined) {
                localVarQueryParameter['IsRollupKPI'] = isRollupKPI;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetGoalByProject: async (projectID?: string | null, xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetGoalByProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetInsertKPI: async (kpiDetailID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetInsertKPI`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kpiDetailID !== undefined) {
                localVarQueryParameter['kpiDetailID'] = kpiDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [kPIProjectRelationShipID] 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetKPIBowlingChart: async (kPIProjectRelationShipID?: string | null, projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetKPIBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIProjectRelationShipID !== undefined) {
                localVarQueryParameter['KPIProjectRelationShipID'] = kPIProjectRelationShipID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetKPIProjectPicklist: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetKPIProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [macroProjectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetMacroProject: async (macroProjectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetMacroProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (macroProjectID !== undefined) {
                localVarQueryParameter['macroProjectID'] = macroProjectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetMonthlyAlertByUser: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetMonthlyAlertByUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProject: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectChilds: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectChilds`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCostChart: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectCostChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCostDetail: async (projectCostDetailID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectCostDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectCostDetailID !== undefined) {
                localVarQueryParameter['ProjectCostDetailID'] = projectCostDetailID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCreationPicklist: async (projectId?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectCreationPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverable: async (projectId?: string, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectId !== undefined) {
                localVarQueryParameter['projectId'] = projectId;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string | null} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverableParentPicklist: async (projectID?: string, deliverableID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectDeliverableParentPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (deliverableID !== undefined) {
                localVarQueryParameter['DeliverableID'] = deliverableID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverablePicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectDeliverablePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {boolean} [hasProjectRelation] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectListPage: async (hasProjectRelation?: boolean, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectListPage`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (hasProjectRelation !== undefined) {
                localVarQueryParameter['hasProjectRelation'] = hasProjectRelation;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['xMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectMembers: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectMembersList: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectMembersList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectRepository: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectRepository`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectRoutine: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetProjectRoutine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetRiskPrecaution: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetRiskPrecaution`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['projectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [secondLevelXmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetSecondLevelMacroProjectsRelationship: async (secondLevelXmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetSecondLevelMacroProjectsRelationship`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (secondLevelXmatrixID !== undefined) {
                localVarQueryParameter['SecondLevelXmatrixID'] = secondLevelXmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTeamLeaderProject: async (userID?: string | null, xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetTeamLeaderProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTotalCostProjectChart: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetTotalCostProjectChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTotalTeamCostProjectChart: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetTotalTeamCostProjectChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetUserAvailability: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetUserAvailability`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetUserProjectCostAccess: async (projectID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/GetUserProjectCostAccess`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertKPIProjectRequestDto} insertKPIProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertKPIProject: async (insertKPIProjectRequestDto: InsertKPIProjectRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertKPIProjectRequestDto' is not null or undefined
            assertParamExists('projectInsertKPIProject', 'insertKPIProjectRequestDto', insertKPIProjectRequestDto)
            const localVarPath = `/api/Project/InsertKPIProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertKPIProjectRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertMacroProjectRequest} insertMacroProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertMacroProject: async (insertMacroProjectRequest: InsertMacroProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertMacroProjectRequest' is not null or undefined
            assertParamExists('projectInsertMacroProject', 'insertMacroProjectRequest', insertMacroProjectRequest)
            const localVarPath = `/api/Project/InsertMacroProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertMacroProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectCostDetailRequestDto} insertProjectCostDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectCostDetail: async (insertProjectCostDetailRequestDto: InsertProjectCostDetailRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertProjectCostDetailRequestDto' is not null or undefined
            assertParamExists('projectInsertProjectCostDetail', 'insertProjectCostDetailRequestDto', insertProjectCostDetailRequestDto)
            const localVarPath = `/api/Project/InsertProjectCostDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectCostDetailRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectDeliverableRequestDto} insertProjectDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectDeliverable: async (insertProjectDeliverableRequestDto: InsertProjectDeliverableRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertProjectDeliverableRequestDto' is not null or undefined
            assertParamExists('projectInsertProjectDeliverable', 'insertProjectDeliverableRequestDto', insertProjectDeliverableRequestDto)
            const localVarPath = `/api/Project/InsertProjectDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectDeliverableRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectMemberRequestDto} insertProjectMemberRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectMember: async (insertProjectMemberRequestDto: InsertProjectMemberRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertProjectMemberRequestDto' is not null or undefined
            assertParamExists('projectInsertProjectMember', 'insertProjectMemberRequestDto', insertProjectMemberRequestDto)
            const localVarPath = `/api/Project/InsertProjectMember`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectMemberRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectRepositoryRequestDto} insertProjectRepositoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRepository: async (insertProjectRepositoryRequestDto: InsertProjectRepositoryRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertProjectRepositoryRequestDto' is not null or undefined
            assertParamExists('projectInsertProjectRepository', 'insertProjectRepositoryRequestDto', insertProjectRepositoryRequestDto)
            const localVarPath = `/api/Project/InsertProjectRepository`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectRepositoryRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectRoutineRequestDto} insertProjectRoutineRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRoutine: async (insertProjectRoutineRequestDto: InsertProjectRoutineRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertProjectRoutineRequestDto' is not null or undefined
            assertParamExists('projectInsertProjectRoutine', 'insertProjectRoutineRequestDto', insertProjectRoutineRequestDto)
            const localVarPath = `/api/Project/InsertProjectRoutine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectRoutineRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectRoutineMeetingRequestDto} insertProjectRoutineMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRoutineMeeting: async (insertProjectRoutineMeetingRequestDto: InsertProjectRoutineMeetingRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertProjectRoutineMeetingRequestDto' is not null or undefined
            assertParamExists('projectInsertProjectRoutineMeeting', 'insertProjectRoutineMeetingRequestDto', insertProjectRoutineMeetingRequestDto)
            const localVarPath = `/api/Project/InsertProjectRoutineMeeting`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectRoutineMeetingRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertRiskRequestDto} insertRiskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertRisk: async (insertRiskRequestDto: InsertRiskRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertRiskRequestDto' is not null or undefined
            assertParamExists('projectInsertRisk', 'insertRiskRequestDto', insertRiskRequestDto)
            const localVarPath = `/api/Project/InsertRisk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertRiskRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertSecondLevelMacroProjectsRelationshipRequestDto} insertSecondLevelMacroProjectsRelationshipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertSecondLevelMacroProjectsRelationship: async (insertSecondLevelMacroProjectsRelationshipRequestDto: InsertSecondLevelMacroProjectsRelationshipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertSecondLevelMacroProjectsRelationshipRequestDto' is not null or undefined
            assertParamExists('projectInsertSecondLevelMacroProjectsRelationship', 'insertSecondLevelMacroProjectsRelationshipRequestDto', insertSecondLevelMacroProjectsRelationshipRequestDto)
            const localVarPath = `/api/Project/InsertSecondLevelMacroProjectsRelationship`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertSecondLevelMacroProjectsRelationshipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {SwapTeamMembersActivitiesRequest} swapTeamMembersActivitiesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectSwapTeamMembersActivities: async (swapTeamMembersActivitiesRequest: SwapTeamMembersActivitiesRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'swapTeamMembersActivitiesRequest' is not null or undefined
            assertParamExists('projectSwapTeamMembersActivities', 'swapTeamMembersActivitiesRequest', swapTeamMembersActivitiesRequest)
            const localVarPath = `/api/Project/SwapTeamMembersActivities`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(swapTeamMembersActivitiesRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGlobalKPIBowlingChartRequestDto} updateGlobalKPIBowlingChartRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateGlobalKPIBowlingChart: async (updateGlobalKPIBowlingChartRequestDto: UpdateGlobalKPIBowlingChartRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGlobalKPIBowlingChartRequestDto' is not null or undefined
            assertParamExists('projectUpdateGlobalKPIBowlingChart', 'updateGlobalKPIBowlingChartRequestDto', updateGlobalKPIBowlingChartRequestDto)
            const localVarPath = `/api/Project/UpdateGlobalKPIBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGlobalKPIBowlingChartRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateKPIBowlingChartRequestDto} updateKPIBowlingChartRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateKPIBowlingChart: async (updateKPIBowlingChartRequestDto: UpdateKPIBowlingChartRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateKPIBowlingChartRequestDto' is not null or undefined
            assertParamExists('projectUpdateKPIBowlingChart', 'updateKPIBowlingChartRequestDto', updateKPIBowlingChartRequestDto)
            const localVarPath = `/api/Project/UpdateKPIBowlingChart`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKPIBowlingChartRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateMacroProjectRequest} updateMacroProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateMacroProject: async (updateMacroProjectRequest: UpdateMacroProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateMacroProjectRequest' is not null or undefined
            assertParamExists('projectUpdateMacroProject', 'updateMacroProjectRequest', updateMacroProjectRequest)
            const localVarPath = `/api/Project/UpdateMacroProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateMacroProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateMonthlyAlert: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/Project/UpdateMonthlyAlert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateOnCallPropertyRequestDto} updateOnCallPropertyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateOnCallProperty: async (updateOnCallPropertyRequestDto: UpdateOnCallPropertyRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateOnCallPropertyRequestDto' is not null or undefined
            assertParamExists('projectUpdateOnCallProperty', 'updateOnCallPropertyRequestDto', updateOnCallPropertyRequestDto)
            const localVarPath = `/api/Project/UpdateOnCallProperty`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateOnCallPropertyRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectRequestDto} updateProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProject: async (updateProjectRequestDto: UpdateProjectRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectRequestDto' is not null or undefined
            assertParamExists('projectUpdateProject', 'updateProjectRequestDto', updateProjectRequestDto)
            const localVarPath = `/api/Project/UpdateProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectCostRequest} updateProjectCostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectCost: async (updateProjectCostRequest: UpdateProjectCostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectCostRequest' is not null or undefined
            assertParamExists('projectUpdateProjectCost', 'updateProjectCostRequest', updateProjectCostRequest)
            const localVarPath = `/api/Project/UpdateProjectCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectCostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectCostDetailRequestDto} updateProjectCostDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectCostDetail: async (updateProjectCostDetailRequestDto: UpdateProjectCostDetailRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectCostDetailRequestDto' is not null or undefined
            assertParamExists('projectUpdateProjectCostDetail', 'updateProjectCostDetailRequestDto', updateProjectCostDetailRequestDto)
            const localVarPath = `/api/Project/UpdateProjectCostDetail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectCostDetailRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectDeliverableRequestDto} updateProjectDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectDeliverable: async (updateProjectDeliverableRequestDto: UpdateProjectDeliverableRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectDeliverableRequestDto' is not null or undefined
            assertParamExists('projectUpdateProjectDeliverable', 'updateProjectDeliverableRequestDto', updateProjectDeliverableRequestDto)
            const localVarPath = `/api/Project/UpdateProjectDeliverable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectDeliverableRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectFieldsRequestDto} updateProjectFieldsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectFields: async (updateProjectFieldsRequestDto: UpdateProjectFieldsRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectFieldsRequestDto' is not null or undefined
            assertParamExists('projectUpdateProjectFields', 'updateProjectFieldsRequestDto', updateProjectFieldsRequestDto)
            const localVarPath = `/api/Project/UpdateProjectFields`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectFieldsRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectRoutineRequestDto} updateProjectRoutineRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectRoutine: async (updateProjectRoutineRequestDto: UpdateProjectRoutineRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectRoutineRequestDto' is not null or undefined
            assertParamExists('projectUpdateProjectRoutine', 'updateProjectRoutineRequestDto', updateProjectRoutineRequestDto)
            const localVarPath = `/api/Project/UpdateProjectRoutine`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectRoutineRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectTotalCostRequest} updateProjectTotalCostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectTotalCost: async (updateProjectTotalCostRequest: UpdateProjectTotalCostRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectTotalCostRequest' is not null or undefined
            assertParamExists('projectUpdateProjectTotalCost', 'updateProjectTotalCostRequest', updateProjectTotalCostRequest)
            const localVarPath = `/api/Project/UpdateProjectTotalCost`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectTotalCostRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateRiskRequestDto} updateRiskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateRisk: async (updateRiskRequestDto: UpdateRiskRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateRiskRequestDto' is not null or undefined
            assertParamExists('projectUpdateRisk', 'updateRiskRequestDto', updateRiskRequestDto)
            const localVarPath = `/api/Project/UpdateRisk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateRiskRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateSecondLevelMacroProjectsRelationshipRequestDto} updateSecondLevelMacroProjectsRelationshipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateSecondLevelMacroProjectsRelationship: async (updateSecondLevelMacroProjectsRelationshipRequestDto: UpdateSecondLevelMacroProjectsRelationshipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateSecondLevelMacroProjectsRelationshipRequestDto' is not null or undefined
            assertParamExists('projectUpdateSecondLevelMacroProjectsRelationship', 'updateSecondLevelMacroProjectsRelationshipRequestDto', updateSecondLevelMacroProjectsRelationshipRequestDto)
            const localVarPath = `/api/Project/UpdateSecondLevelMacroProjectsRelationship`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateSecondLevelMacroProjectsRelationshipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateTeamMembersRequest} updateTeamMembersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateTeamMembers: async (updateTeamMembersRequest: UpdateTeamMembersRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateTeamMembersRequest' is not null or undefined
            assertParamExists('projectUpdateTeamMembers', 'updateTeamMembersRequest', updateTeamMembersRequest)
            const localVarPath = `/api/Project/UpdateTeamMembers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateTeamMembersRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string | null} [projectCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCheckDuplicateProject(projectCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCheckDuplicateProject(projectCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectCheckDuplicateProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {CreateProjectRequestDto} createProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectCreateProject(createProjectRequestDto: CreateProjectRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectCreateProject(createProjectRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectCreateProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [kPIProjectRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteKPIRelationshipBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [precautionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeletePrecaution(precautionID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeletePrecaution(precautionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeletePrecaution']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteProject(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteProjectCost(projectCostDetailID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteProjectCost(projectCostDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteProjectCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectRepositoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteProjectRepository(projectRepositoryID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteProjectRepository(projectRepositoryID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteProjectRepository']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectRoutineID] 
         * @param {string | null} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteProjectRoutine(projectRoutineID?: string, outlookEventId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteProjectRoutine(projectRoutineID, outlookEventId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteProjectRoutine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [riskID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDeleteRisk(riskID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDeleteRisk(riskID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectDeleteRisk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetDeliverableProjectPicklist(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetDeliverableProjectPicklist(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetDeliverableProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {string | null} [year] 
         * @param {string | null} [isRollupKPI] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetGlobalKPIBowlingChart(kPIDetailID?: string | null, year?: string | null, isRollupKPI?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetGlobalKPIBowlingChart(kPIDetailID, year, isRollupKPI, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetGlobalKPIBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetGoalByProject(projectID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGoalByProject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetGoalByProject(projectID, xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetGoalByProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetInsertKPI(kpiDetailID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetInsertKPIResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetInsertKPI(kpiDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetInsertKPI']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [kPIProjectRelationShipID] 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetKPIBowlingChart(kPIProjectRelationShipID?: string | null, projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetKPIBowlingChart(kPIProjectRelationShipID, projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetKPIBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetKPIProjectPicklist(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIProjectPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetKPIProjectPicklist(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetKPIProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [macroProjectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetMacroProject(macroProjectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetMacroProjectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetMacroProject(macroProjectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetMacroProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetMonthlyAlertByUser(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetMonthlyAlertByUserResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetMonthlyAlertByUser(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetMonthlyAlertByUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProject(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetProjectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectChilds(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectChildsResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectChilds(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectChilds']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectCostChart(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectCostChart(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectCostChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectCostDetail(projectCostDetailID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetProjectCostDetailResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectCostDetail(projectCostDetailID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectCostDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectCreationPicklist(projectId?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectCreationPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectCreationPicklist(projectId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectCreationPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectDeliverable(projectId?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfProjectDeliverableParent>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectDeliverable(projectId, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string | null} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectDeliverableParentPicklist(projectID?: string, deliverableID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectDeliverableParentPicklist(projectID, deliverableID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectDeliverableParentPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectDeliverablePicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectDeliverablePicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectDeliverablePicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectDeliverablePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {boolean} [hasProjectRelation] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectListPage(hasProjectRelation?: boolean, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectListPageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectListPage(hasProjectRelation, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectListPage']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectMembers(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectMembersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectMembers(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectMembers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectMembersList(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectMembersListResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectMembersList(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectMembersList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectRepository(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetProjectRepositoryList>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectRepository(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectRepository']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetProjectRoutine(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfProjectRoutineResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetProjectRoutine(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetProjectRoutine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetRiskPrecaution(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetRiskPrecautionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetRiskPrecaution(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetRiskPrecaution']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [secondLevelXmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetSecondLevelMacroProjectsRelationship']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetTeamLeaderProject(userID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetTeamLeaderProjectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetTeamLeaderProject(userID, xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetTeamLeaderProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetTotalCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetTotalCostProjectChart(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetTotalCostProjectChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetTotalTeamCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetTotalTeamCostProjectChart(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetTotalTeamCostProjectChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetUserAvailability(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetUserAvailability(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetUserAvailability']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectGetUserProjectCostAccess(projectID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetUserProjectCostAccessResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectGetUserProjectCostAccess(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectGetUserProjectCostAccess']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertKPIProjectRequestDto} insertKPIProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertKPIProject(insertKPIProjectRequestDto: InsertKPIProjectRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertKPIProject(insertKPIProjectRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertKPIProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertMacroProjectRequest} insertMacroProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertMacroProject(insertMacroProjectRequest: InsertMacroProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertMacroProject(insertMacroProjectRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertMacroProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectCostDetailRequestDto} insertProjectCostDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectCostDetail(insertProjectCostDetailRequestDto: InsertProjectCostDetailRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertProjectCostDetailResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectCostDetail(insertProjectCostDetailRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectCostDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectDeliverableRequestDto} insertProjectDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectDeliverable(insertProjectDeliverableRequestDto: InsertProjectDeliverableRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectDeliverable(insertProjectDeliverableRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectMemberRequestDto} insertProjectMemberRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectMember(insertProjectMemberRequestDto: InsertProjectMemberRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertProjectMemberResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectMember(insertProjectMemberRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectMember']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectRepositoryRequestDto} insertProjectRepositoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectRepository(insertProjectRepositoryRequestDto: InsertProjectRepositoryRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectRepository(insertProjectRepositoryRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectRepository']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectRoutineRequestDto} insertProjectRoutineRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectRoutine(insertProjectRoutineRequestDto: InsertProjectRoutineRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertProjectRoutineResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectRoutine(insertProjectRoutineRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectRoutine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectRoutineMeetingRequestDto} insertProjectRoutineMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto: InsertProjectRoutineMeetingRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertProjectRoutineMeeting']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertRiskRequestDto} insertRiskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertRisk(insertRiskRequestDto: InsertRiskRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertRisk(insertRiskRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertRisk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertSecondLevelMacroProjectsRelationshipRequestDto} insertSecondLevelMacroProjectsRelationshipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto: InsertSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectInsertSecondLevelMacroProjectsRelationship']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {SwapTeamMembersActivitiesRequest} swapTeamMembersActivitiesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest: SwapTeamMembersActivitiesRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectSwapTeamMembersActivities']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateGlobalKPIBowlingChartRequestDto} updateGlobalKPIBowlingChartRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto: UpdateGlobalKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateGlobalKPIBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateKPIBowlingChartRequestDto} updateKPIBowlingChartRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto: UpdateKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateKPIBowlingChart']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateMacroProjectRequest} updateMacroProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateMacroProject(updateMacroProjectRequest: UpdateMacroProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateMacroProject(updateMacroProjectRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateMacroProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateMonthlyAlert(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateMonthlyAlert(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateMonthlyAlert']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateOnCallPropertyRequestDto} updateOnCallPropertyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateOnCallProperty(updateOnCallPropertyRequestDto: UpdateOnCallPropertyRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateOnCallProperty(updateOnCallPropertyRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateOnCallProperty']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectRequestDto} updateProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProject(updateProjectRequestDto: UpdateProjectRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProject(updateProjectRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectCostRequest} updateProjectCostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectCost(updateProjectCostRequest: UpdateProjectCostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectCost(updateProjectCostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectCostDetailRequestDto} updateProjectCostDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto: UpdateProjectCostDetailRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectCostDetail']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectDeliverableRequestDto} updateProjectDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto: UpdateProjectDeliverableRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectDeliverable']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectFieldsRequestDto} updateProjectFieldsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectFields(updateProjectFieldsRequestDto: UpdateProjectFieldsRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectFields(updateProjectFieldsRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectFields']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectRoutineRequestDto} updateProjectRoutineRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectRoutine(updateProjectRoutineRequestDto: UpdateProjectRoutineRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectRoutine(updateProjectRoutineRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectRoutine']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectTotalCostRequest} updateProjectTotalCostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateProjectTotalCost(updateProjectTotalCostRequest: UpdateProjectTotalCostRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateProjectTotalCost(updateProjectTotalCostRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateProjectTotalCost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateRiskRequestDto} updateRiskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateRisk(updateRiskRequestDto: UpdateRiskRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateRisk(updateRiskRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateRisk']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateSecondLevelMacroProjectsRelationshipRequestDto} updateSecondLevelMacroProjectsRelationshipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto: UpdateSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateSecondLevelMacroProjectsRelationship']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateTeamMembersRequest} updateTeamMembersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectUpdateTeamMembers(updateTeamMembersRequest: UpdateTeamMembersRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectUpdateTeamMembers(updateTeamMembersRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectApi.projectUpdateTeamMembers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectApiFp(configuration)
    return {
        /**
         * 
         * @param {string | null} [projectCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCheckDuplicateProject(projectCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectCheckDuplicateProject(projectCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {CreateProjectRequestDto} createProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectCreateProject(createProjectRequestDto: CreateProjectRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectCreateProject(createProjectRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [kPIProjectRelationShipID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [precautionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeletePrecaution(precautionID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectDeletePrecaution(precautionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProject(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectDeleteProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectCost(projectCostDetailID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectDeleteProjectCost(projectCostDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectRepositoryID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectRepository(projectRepositoryID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectDeleteProjectRepository(projectRepositoryID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectRoutineID] 
         * @param {string | null} [outlookEventId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteProjectRoutine(projectRoutineID?: string, outlookEventId?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectDeleteProjectRoutine(projectRoutineID, outlookEventId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [riskID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDeleteRisk(riskID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectDeleteRisk(riskID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetDeliverableProjectPicklist(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDto> {
            return localVarFp.projectGetDeliverableProjectPicklist(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [kPIDetailID] 
         * @param {string | null} [year] 
         * @param {string | null} [isRollupKPI] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetGlobalKPIBowlingChart(kPIDetailID?: string | null, year?: string | null, isRollupKPI?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.projectGetGlobalKPIBowlingChart(kPIDetailID, year, isRollupKPI, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetGoalByProject(projectID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGoalByProject> {
            return localVarFp.projectGetGoalByProject(projectID, xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [kpiDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetInsertKPI(kpiDetailID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetInsertKPIResponseDto> {
            return localVarFp.projectGetInsertKPI(kpiDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [kPIProjectRelationShipID] 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetKPIBowlingChart(kPIProjectRelationShipID?: string | null, projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.projectGetKPIBowlingChart(kPIProjectRelationShipID, projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetKPIProjectPicklist(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIProjectPicklistResponseDto> {
            return localVarFp.projectGetKPIProjectPicklist(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [macroProjectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetMacroProject(macroProjectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetMacroProjectResponseDto> {
            return localVarFp.projectGetMacroProject(macroProjectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetMonthlyAlertByUser(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetMonthlyAlertByUserResponseDto> {
            return localVarFp.projectGetMonthlyAlertByUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProject(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetProjectResponseDto> {
            return localVarFp.projectGetProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectChilds(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectChildsResponseDto> {
            return localVarFp.projectGetProjectChilds(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCostChart(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.projectGetProjectCostChart(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectCostDetailID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCostDetail(projectCostDetailID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetProjectCostDetailResponseDto> {
            return localVarFp.projectGetProjectCostDetail(projectCostDetailID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectCreationPicklist(projectId?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectCreationPicklistResponseDto> {
            return localVarFp.projectGetProjectCreationPicklist(projectId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectId] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverable(projectId?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfProjectDeliverableParent> {
            return localVarFp.projectGetProjectDeliverable(projectId, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string | null} [deliverableID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverableParentPicklist(projectID?: string, deliverableID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetDeliverableProjectPicklistResponseDto> {
            return localVarFp.projectGetProjectDeliverableParentPicklist(projectID, deliverableID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectDeliverablePicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectDeliverablePicklistResponseDto> {
            return localVarFp.projectGetProjectDeliverablePicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {boolean} [hasProjectRelation] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectListPage(hasProjectRelation?: boolean, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectListPageResponseDto> {
            return localVarFp.projectGetProjectListPage(hasProjectRelation, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectMembers(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectMembersResponseDto> {
            return localVarFp.projectGetProjectMembers(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectMembersList(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectMembersListResponseDto> {
            return localVarFp.projectGetProjectMembersList(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectPicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectPicklistResponseDto> {
            return localVarFp.projectGetProjectPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectRepository(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetProjectRepositoryList> {
            return localVarFp.projectGetProjectRepository(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetProjectRoutine(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfProjectRoutineResponseDto> {
            return localVarFp.projectGetProjectRoutine(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetRiskPrecaution(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetRiskPrecautionResponseDto> {
            return localVarFp.projectGetRiskPrecaution(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [secondLevelXmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetSecondLevelMacroProjectsRelationshipResponseDto> {
            return localVarFp.projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTeamLeaderProject(userID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetTeamLeaderProjectResponseDto> {
            return localVarFp.projectGetTeamLeaderProject(userID, xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTotalCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.projectGetTotalCostProjectChart(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetTotalTeamCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.projectGetTotalTeamCostProjectChart(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetUserAvailability(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.projectGetUserAvailability(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectGetUserProjectCostAccess(projectID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetUserProjectCostAccessResponseDto> {
            return localVarFp.projectGetUserProjectCostAccess(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertKPIProjectRequestDto} insertKPIProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertKPIProject(insertKPIProjectRequestDto: InsertKPIProjectRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectInsertKPIProject(insertKPIProjectRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertMacroProjectRequest} insertMacroProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertMacroProject(insertMacroProjectRequest: InsertMacroProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectInsertMacroProject(insertMacroProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectCostDetailRequestDto} insertProjectCostDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectCostDetail(insertProjectCostDetailRequestDto: InsertProjectCostDetailRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertProjectCostDetailResponseDto> {
            return localVarFp.projectInsertProjectCostDetail(insertProjectCostDetailRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectDeliverableRequestDto} insertProjectDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectDeliverable(insertProjectDeliverableRequestDto: InsertProjectDeliverableRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectInsertProjectDeliverable(insertProjectDeliverableRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectMemberRequestDto} insertProjectMemberRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectMember(insertProjectMemberRequestDto: InsertProjectMemberRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertProjectMemberResponseDto> {
            return localVarFp.projectInsertProjectMember(insertProjectMemberRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectRepositoryRequestDto} insertProjectRepositoryRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRepository(insertProjectRepositoryRequestDto: InsertProjectRepositoryRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectInsertProjectRepository(insertProjectRepositoryRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectRoutineRequestDto} insertProjectRoutineRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRoutine(insertProjectRoutineRequestDto: InsertProjectRoutineRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertProjectRoutineResponseDto> {
            return localVarFp.projectInsertProjectRoutine(insertProjectRoutineRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectRoutineMeetingRequestDto} insertProjectRoutineMeetingRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto: InsertProjectRoutineMeetingRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertRiskRequestDto} insertRiskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertRisk(insertRiskRequestDto: InsertRiskRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectInsertRisk(insertRiskRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertSecondLevelMacroProjectsRelationshipRequestDto} insertSecondLevelMacroProjectsRelationshipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto: InsertSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {SwapTeamMembersActivitiesRequest} swapTeamMembersActivitiesRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest: SwapTeamMembersActivitiesRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGlobalKPIBowlingChartRequestDto} updateGlobalKPIBowlingChartRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto: UpdateGlobalKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateKPIBowlingChartRequestDto} updateKPIBowlingChartRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto: UpdateKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateMacroProjectRequest} updateMacroProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateMacroProject(updateMacroProjectRequest: UpdateMacroProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateMacroProject(updateMacroProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateMonthlyAlert(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateMonthlyAlert(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateOnCallPropertyRequestDto} updateOnCallPropertyRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateOnCallProperty(updateOnCallPropertyRequestDto: UpdateOnCallPropertyRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateOnCallProperty(updateOnCallPropertyRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectRequestDto} updateProjectRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProject(updateProjectRequestDto: UpdateProjectRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateProject(updateProjectRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectCostRequest} updateProjectCostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectCost(updateProjectCostRequest: UpdateProjectCostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateProjectCost(updateProjectCostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectCostDetailRequestDto} updateProjectCostDetailRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto: UpdateProjectCostDetailRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectDeliverableRequestDto} updateProjectDeliverableRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto: UpdateProjectDeliverableRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectFieldsRequestDto} updateProjectFieldsRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectFields(updateProjectFieldsRequestDto: UpdateProjectFieldsRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateProjectFields(updateProjectFieldsRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectRoutineRequestDto} updateProjectRoutineRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectRoutine(updateProjectRoutineRequestDto: UpdateProjectRoutineRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.projectUpdateProjectRoutine(updateProjectRoutineRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectTotalCostRequest} updateProjectTotalCostRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateProjectTotalCost(updateProjectTotalCostRequest: UpdateProjectTotalCostRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateProjectTotalCost(updateProjectTotalCostRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateRiskRequestDto} updateRiskRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateRisk(updateRiskRequestDto: UpdateRiskRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateRisk(updateRiskRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateSecondLevelMacroProjectsRelationshipRequestDto} updateSecondLevelMacroProjectsRelationshipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto: UpdateSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateTeamMembersRequest} updateTeamMembersRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectUpdateTeamMembers(updateTeamMembersRequest: UpdateTeamMembersRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.projectUpdateTeamMembers(updateTeamMembersRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     * 
     * @param {string | null} [projectCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectCheckDuplicateProject(projectCode?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectCheckDuplicateProject(projectCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {CreateProjectRequestDto} createProjectRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectCreateProject(createProjectRequestDto: CreateProjectRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectCreateProject(createProjectRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [kPIProjectRelationShipID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteKPIRelationshipBowlingChart(kPIProjectRelationShipID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [precautionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeletePrecaution(precautionID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeletePrecaution(precautionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteProject(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectCostDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteProjectCost(projectCostDetailID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteProjectCost(projectCostDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectRepositoryID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteProjectRepository(projectRepositoryID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteProjectRepository(projectRepositoryID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectRoutineID] 
     * @param {string | null} [outlookEventId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteProjectRoutine(projectRoutineID?: string, outlookEventId?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteProjectRoutine(projectRoutineID, outlookEventId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [riskID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectDeleteRisk(riskID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectDeleteRisk(riskID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetDeliverableProjectPicklist(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetDeliverableProjectPicklist(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [kPIDetailID] 
     * @param {string | null} [year] 
     * @param {string | null} [isRollupKPI] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetGlobalKPIBowlingChart(kPIDetailID?: string | null, year?: string | null, isRollupKPI?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetGlobalKPIBowlingChart(kPIDetailID, year, isRollupKPI, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetGoalByProject(projectID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetGoalByProject(projectID, xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [kpiDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetInsertKPI(kpiDetailID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetInsertKPI(kpiDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [kPIProjectRelationShipID] 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetKPIBowlingChart(kPIProjectRelationShipID?: string | null, projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetKPIBowlingChart(kPIProjectRelationShipID, projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetKPIProjectPicklist(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetKPIProjectPicklist(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [macroProjectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetMacroProject(macroProjectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetMacroProject(macroProjectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetMonthlyAlertByUser(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetMonthlyAlertByUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProject(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectChilds(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectChilds(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectCostChart(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectCostChart(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectCostDetailID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectCostDetail(projectCostDetailID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectCostDetail(projectCostDetailID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectCreationPicklist(projectId?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectCreationPicklist(projectId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectId] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectDeliverable(projectId?: string, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectDeliverable(projectId, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {string | null} [deliverableID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectDeliverableParentPicklist(projectID?: string, deliverableID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectDeliverableParentPicklist(projectID, deliverableID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectDeliverablePicklist(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectDeliverablePicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {boolean} [hasProjectRelation] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectListPage(hasProjectRelation?: boolean, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectListPage(hasProjectRelation, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectMembers(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectMembers(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectMembersList(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectMembersList(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectPicklist(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectRepository(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectRepository(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetProjectRoutine(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetProjectRoutine(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetRiskPrecaution(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetRiskPrecaution(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [secondLevelXmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetSecondLevelMacroProjectsRelationship(secondLevelXmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [userID] 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetTeamLeaderProject(userID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetTeamLeaderProject(userID, xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetTotalCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetTotalCostProjectChart(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetTotalTeamCostProjectChart(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetTotalTeamCostProjectChart(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetUserAvailability(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetUserAvailability(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectGetUserProjectCostAccess(projectID?: string, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectGetUserProjectCostAccess(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertKPIProjectRequestDto} insertKPIProjectRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertKPIProject(insertKPIProjectRequestDto: InsertKPIProjectRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertKPIProject(insertKPIProjectRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertMacroProjectRequest} insertMacroProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertMacroProject(insertMacroProjectRequest: InsertMacroProjectRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertMacroProject(insertMacroProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectCostDetailRequestDto} insertProjectCostDetailRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectCostDetail(insertProjectCostDetailRequestDto: InsertProjectCostDetailRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectCostDetail(insertProjectCostDetailRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectDeliverableRequestDto} insertProjectDeliverableRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectDeliverable(insertProjectDeliverableRequestDto: InsertProjectDeliverableRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectDeliverable(insertProjectDeliverableRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectMemberRequestDto} insertProjectMemberRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectMember(insertProjectMemberRequestDto: InsertProjectMemberRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectMember(insertProjectMemberRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectRepositoryRequestDto} insertProjectRepositoryRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectRepository(insertProjectRepositoryRequestDto: InsertProjectRepositoryRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectRepository(insertProjectRepositoryRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectRoutineRequestDto} insertProjectRoutineRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectRoutine(insertProjectRoutineRequestDto: InsertProjectRoutineRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectRoutine(insertProjectRoutineRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectRoutineMeetingRequestDto} insertProjectRoutineMeetingRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto: InsertProjectRoutineMeetingRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertProjectRoutineMeeting(insertProjectRoutineMeetingRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertRiskRequestDto} insertRiskRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertRisk(insertRiskRequestDto: InsertRiskRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertRisk(insertRiskRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertSecondLevelMacroProjectsRelationshipRequestDto} insertSecondLevelMacroProjectsRelationshipRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto: InsertSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectInsertSecondLevelMacroProjectsRelationship(insertSecondLevelMacroProjectsRelationshipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SwapTeamMembersActivitiesRequest} swapTeamMembersActivitiesRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest: SwapTeamMembersActivitiesRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectSwapTeamMembersActivities(swapTeamMembersActivitiesRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGlobalKPIBowlingChartRequestDto} updateGlobalKPIBowlingChartRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto: UpdateGlobalKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateGlobalKPIBowlingChart(updateGlobalKPIBowlingChartRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateKPIBowlingChartRequestDto} updateKPIBowlingChartRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto: UpdateKPIBowlingChartRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateKPIBowlingChart(updateKPIBowlingChartRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateMacroProjectRequest} updateMacroProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateMacroProject(updateMacroProjectRequest: UpdateMacroProjectRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateMacroProject(updateMacroProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateMonthlyAlert(options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateMonthlyAlert(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateOnCallPropertyRequestDto} updateOnCallPropertyRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateOnCallProperty(updateOnCallPropertyRequestDto: UpdateOnCallPropertyRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateOnCallProperty(updateOnCallPropertyRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectRequestDto} updateProjectRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProject(updateProjectRequestDto: UpdateProjectRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProject(updateProjectRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectCostRequest} updateProjectCostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectCost(updateProjectCostRequest: UpdateProjectCostRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectCost(updateProjectCostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectCostDetailRequestDto} updateProjectCostDetailRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto: UpdateProjectCostDetailRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectCostDetail(updateProjectCostDetailRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectDeliverableRequestDto} updateProjectDeliverableRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto: UpdateProjectDeliverableRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectDeliverable(updateProjectDeliverableRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectFieldsRequestDto} updateProjectFieldsRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectFields(updateProjectFieldsRequestDto: UpdateProjectFieldsRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectFields(updateProjectFieldsRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectRoutineRequestDto} updateProjectRoutineRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectRoutine(updateProjectRoutineRequestDto: UpdateProjectRoutineRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectRoutine(updateProjectRoutineRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectTotalCostRequest} updateProjectTotalCostRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateProjectTotalCost(updateProjectTotalCostRequest: UpdateProjectTotalCostRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateProjectTotalCost(updateProjectTotalCostRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateRiskRequestDto} updateRiskRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateRisk(updateRiskRequestDto: UpdateRiskRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateRisk(updateRiskRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateSecondLevelMacroProjectsRelationshipRequestDto} updateSecondLevelMacroProjectsRelationshipRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto: UpdateSecondLevelMacroProjectsRelationshipRequestDto, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateSecondLevelMacroProjectsRelationship(updateSecondLevelMacroProjectsRelationshipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateTeamMembersRequest} updateTeamMembersRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    public projectUpdateTeamMembers(updateTeamMembersRequest: UpdateTeamMembersRequest, options?: RawAxiosRequestConfig) {
        return ProjectApiFp(this.configuration).projectUpdateTeamMembers(updateTeamMembersRequest, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ProjectDashboardApi - axios parameter creator
 * @export
 */
export const ProjectDashboardApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetDivisionPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetDivisionPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetParentProjectPicklist: async (xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetParentProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixID !== undefined) {
                localVarQueryParameter['XmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectCodes: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetProjectCode`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [division] 
         * @param {string | null} [committie] 
         * @param {number} [status] 
         * @param {string | null} [teamLider] 
         * @param {string | null} [sponsor] 
         * @param {number} [timeSemaphore] 
         * @param {number} [budgetSemaphore] 
         * @param {number} [qualitySemaphore] 
         * @param {boolean} [hasParentProject] 
         * @param {string | null} [project] 
         * @param {string | null} [parentProject] 
         * @param {number | null} [filterCriteria] 
         * @param {string} [userID] 
         * @param {number} [comboSemaphore] 
         * @param {string | null} [projectCode] 
         * @param {string | null} [xmatrixID] 
         * @param {boolean} [selectNotRelatedProjects] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectDashboards: async (division?: string | null, committie?: string | null, status?: number, teamLider?: string | null, sponsor?: string | null, timeSemaphore?: number, budgetSemaphore?: number, qualitySemaphore?: number, hasParentProject?: boolean, project?: string | null, parentProject?: string | null, filterCriteria?: number | null, userID?: string, comboSemaphore?: number, projectCode?: string | null, xmatrixID?: string | null, selectNotRelatedProjects?: boolean, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetProjectDashboards`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (division !== undefined) {
                localVarQueryParameter['division'] = division;
            }

            if (committie !== undefined) {
                localVarQueryParameter['committie'] = committie;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (teamLider !== undefined) {
                localVarQueryParameter['teamLider'] = teamLider;
            }

            if (sponsor !== undefined) {
                localVarQueryParameter['sponsor'] = sponsor;
            }

            if (timeSemaphore !== undefined) {
                localVarQueryParameter['timeSemaphore'] = timeSemaphore;
            }

            if (budgetSemaphore !== undefined) {
                localVarQueryParameter['budgetSemaphore'] = budgetSemaphore;
            }

            if (qualitySemaphore !== undefined) {
                localVarQueryParameter['qualitySemaphore'] = qualitySemaphore;
            }

            if (hasParentProject !== undefined) {
                localVarQueryParameter['hasParentProject'] = hasParentProject;
            }

            if (project !== undefined) {
                localVarQueryParameter['Project'] = project;
            }

            if (parentProject !== undefined) {
                localVarQueryParameter['ParentProject'] = parentProject;
            }

            if (filterCriteria !== undefined) {
                localVarQueryParameter['FilterCriteria'] = filterCriteria;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (comboSemaphore !== undefined) {
                localVarQueryParameter['ComboSemaphore'] = comboSemaphore;
            }

            if (projectCode !== undefined) {
                localVarQueryParameter['ProjectCode'] = projectCode;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['XmatrixID'] = xmatrixID;
            }

            if (selectNotRelatedProjects !== undefined) {
                localVarQueryParameter['SelectNotRelatedProjects'] = selectNotRelatedProjects;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectStatusPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetProjectStatusPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetSemaphorePicklist: async (languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetSemaphorePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetTeamLeaderPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetTeamLeaderPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string | null} [teamLider] 
         * @param {number | null} [status] 
         * @param {string | null} [division] 
         * @param {string | null} [sponsor] 
         * @param {string | null} [committie] 
         * @param {string | null} [projectCode] 
         * @param {string | null} [userID] 
         * @param {boolean | null} [selectNotRelatedProjects] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardProjectSummary: async (xmatrixID?: string, teamLider?: string | null, status?: number | null, division?: string | null, sponsor?: string | null, committie?: string | null, projectCode?: string | null, userID?: string | null, selectNotRelatedProjects?: boolean | null, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ProjectDashboard/GetProjectSummary`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }

            if (teamLider !== undefined) {
                localVarQueryParameter['teamLider'] = teamLider;
            }

            if (status !== undefined) {
                localVarQueryParameter['status'] = status;
            }

            if (division !== undefined) {
                localVarQueryParameter['division'] = division;
            }

            if (sponsor !== undefined) {
                localVarQueryParameter['sponsor'] = sponsor;
            }

            if (committie !== undefined) {
                localVarQueryParameter['committie'] = committie;
            }

            if (projectCode !== undefined) {
                localVarQueryParameter['projectCode'] = projectCode;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (selectNotRelatedProjects !== undefined) {
                localVarQueryParameter['selectNotRelatedProjects'] = selectNotRelatedProjects;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ProjectDashboardApi - functional programming interface
 * @export
 */
export const ProjectDashboardApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ProjectDashboardApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetDivisionPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetDivisionPicklistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetDivisionPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetDivisionPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetParentProjectPicklist(xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetParentProjectPicklistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetParentProjectPicklist(xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetParentProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetProjectCodes(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectCodesResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetProjectCodes(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetProjectCodes']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [division] 
         * @param {string | null} [committie] 
         * @param {number} [status] 
         * @param {string | null} [teamLider] 
         * @param {string | null} [sponsor] 
         * @param {number} [timeSemaphore] 
         * @param {number} [budgetSemaphore] 
         * @param {number} [qualitySemaphore] 
         * @param {boolean} [hasParentProject] 
         * @param {string | null} [project] 
         * @param {string | null} [parentProject] 
         * @param {number | null} [filterCriteria] 
         * @param {string} [userID] 
         * @param {number} [comboSemaphore] 
         * @param {string | null} [projectCode] 
         * @param {string | null} [xmatrixID] 
         * @param {boolean} [selectNotRelatedProjects] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetProjectDashboards(division?: string | null, committie?: string | null, status?: number, teamLider?: string | null, sponsor?: string | null, timeSemaphore?: number, budgetSemaphore?: number, qualitySemaphore?: number, hasParentProject?: boolean, project?: string | null, parentProject?: string | null, filterCriteria?: number | null, userID?: string, comboSemaphore?: number, projectCode?: string | null, xmatrixID?: string | null, selectNotRelatedProjects?: boolean, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfProjectDashboards>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetProjectDashboards(division, committie, status, teamLider, sponsor, timeSemaphore, budgetSemaphore, qualitySemaphore, hasParentProject, project, parentProject, filterCriteria, userID, comboSemaphore, projectCode, xmatrixID, selectNotRelatedProjects, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetProjectDashboards']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetProjectStatusPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectStatusPicklistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetProjectStatusPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetProjectStatusPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetSemaphorePicklist(languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetSemaphorePicklistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetSemaphorePicklist(languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetSemaphorePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardGetTeamLeaderPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetTeamLeaderPicklistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardGetTeamLeaderPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardGetTeamLeaderPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string | null} [teamLider] 
         * @param {number | null} [status] 
         * @param {string | null} [division] 
         * @param {string | null} [sponsor] 
         * @param {string | null} [committie] 
         * @param {string | null} [projectCode] 
         * @param {string | null} [userID] 
         * @param {boolean | null} [selectNotRelatedProjects] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async projectDashboardProjectSummary(xmatrixID?: string, teamLider?: string | null, status?: number | null, division?: string | null, sponsor?: string | null, committie?: string | null, projectCode?: string | null, userID?: string | null, selectNotRelatedProjects?: boolean | null, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfProjectSummary>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.projectDashboardProjectSummary(xmatrixID, teamLider, status, division, sponsor, committie, projectCode, userID, selectNotRelatedProjects, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ProjectDashboardApi.projectDashboardProjectSummary']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ProjectDashboardApi - factory interface
 * @export
 */
export const ProjectDashboardApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ProjectDashboardApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetDivisionPicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetDivisionPicklistDto> {
            return localVarFp.projectDashboardGetDivisionPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetParentProjectPicklist(xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetParentProjectPicklistDto> {
            return localVarFp.projectDashboardGetParentProjectPicklist(xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectCodes(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectCodesResponseDto> {
            return localVarFp.projectDashboardGetProjectCodes(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [division] 
         * @param {string | null} [committie] 
         * @param {number} [status] 
         * @param {string | null} [teamLider] 
         * @param {string | null} [sponsor] 
         * @param {number} [timeSemaphore] 
         * @param {number} [budgetSemaphore] 
         * @param {number} [qualitySemaphore] 
         * @param {boolean} [hasParentProject] 
         * @param {string | null} [project] 
         * @param {string | null} [parentProject] 
         * @param {number | null} [filterCriteria] 
         * @param {string} [userID] 
         * @param {number} [comboSemaphore] 
         * @param {string | null} [projectCode] 
         * @param {string | null} [xmatrixID] 
         * @param {boolean} [selectNotRelatedProjects] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectDashboards(division?: string | null, committie?: string | null, status?: number, teamLider?: string | null, sponsor?: string | null, timeSemaphore?: number, budgetSemaphore?: number, qualitySemaphore?: number, hasParentProject?: boolean, project?: string | null, parentProject?: string | null, filterCriteria?: number | null, userID?: string, comboSemaphore?: number, projectCode?: string | null, xmatrixID?: string | null, selectNotRelatedProjects?: boolean, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfProjectDashboards> {
            return localVarFp.projectDashboardGetProjectDashboards(division, committie, status, teamLider, sponsor, timeSemaphore, budgetSemaphore, qualitySemaphore, hasParentProject, project, parentProject, filterCriteria, userID, comboSemaphore, projectCode, xmatrixID, selectNotRelatedProjects, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetProjectStatusPicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectStatusPicklistDto> {
            return localVarFp.projectDashboardGetProjectStatusPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetSemaphorePicklist(languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetSemaphorePicklistDto> {
            return localVarFp.projectDashboardGetSemaphorePicklist(languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardGetTeamLeaderPicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetTeamLeaderPicklistDto> {
            return localVarFp.projectDashboardGetTeamLeaderPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixID] 
         * @param {string | null} [teamLider] 
         * @param {number | null} [status] 
         * @param {string | null} [division] 
         * @param {string | null} [sponsor] 
         * @param {string | null} [committie] 
         * @param {string | null} [projectCode] 
         * @param {string | null} [userID] 
         * @param {boolean | null} [selectNotRelatedProjects] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        projectDashboardProjectSummary(xmatrixID?: string, teamLider?: string | null, status?: number | null, division?: string | null, sponsor?: string | null, committie?: string | null, projectCode?: string | null, userID?: string | null, selectNotRelatedProjects?: boolean | null, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfProjectSummary> {
            return localVarFp.projectDashboardProjectSummary(xmatrixID, teamLider, status, division, sponsor, committie, projectCode, userID, selectNotRelatedProjects, languageCode, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ProjectDashboardApi - object-oriented interface
 * @export
 * @class ProjectDashboardApi
 * @extends {BaseAPI}
 */
export class ProjectDashboardApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetDivisionPicklist(options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetDivisionPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetParentProjectPicklist(xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetParentProjectPicklist(xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetProjectCodes(options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetProjectCodes(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [division] 
     * @param {string | null} [committie] 
     * @param {number} [status] 
     * @param {string | null} [teamLider] 
     * @param {string | null} [sponsor] 
     * @param {number} [timeSemaphore] 
     * @param {number} [budgetSemaphore] 
     * @param {number} [qualitySemaphore] 
     * @param {boolean} [hasParentProject] 
     * @param {string | null} [project] 
     * @param {string | null} [parentProject] 
     * @param {number | null} [filterCriteria] 
     * @param {string} [userID] 
     * @param {number} [comboSemaphore] 
     * @param {string | null} [projectCode] 
     * @param {string | null} [xmatrixID] 
     * @param {boolean} [selectNotRelatedProjects] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetProjectDashboards(division?: string | null, committie?: string | null, status?: number, teamLider?: string | null, sponsor?: string | null, timeSemaphore?: number, budgetSemaphore?: number, qualitySemaphore?: number, hasParentProject?: boolean, project?: string | null, parentProject?: string | null, filterCriteria?: number | null, userID?: string, comboSemaphore?: number, projectCode?: string | null, xmatrixID?: string | null, selectNotRelatedProjects?: boolean, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetProjectDashboards(division, committie, status, teamLider, sponsor, timeSemaphore, budgetSemaphore, qualitySemaphore, hasParentProject, project, parentProject, filterCriteria, userID, comboSemaphore, projectCode, xmatrixID, selectNotRelatedProjects, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetProjectStatusPicklist(options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetProjectStatusPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetSemaphorePicklist(languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetSemaphorePicklist(languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardGetTeamLeaderPicklist(options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardGetTeamLeaderPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixID] 
     * @param {string | null} [teamLider] 
     * @param {number | null} [status] 
     * @param {string | null} [division] 
     * @param {string | null} [sponsor] 
     * @param {string | null} [committie] 
     * @param {string | null} [projectCode] 
     * @param {string | null} [userID] 
     * @param {boolean | null} [selectNotRelatedProjects] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectDashboardApi
     */
    public projectDashboardProjectSummary(xmatrixID?: string, teamLider?: string | null, status?: number | null, division?: string | null, sponsor?: string | null, committie?: string | null, projectCode?: string | null, userID?: string | null, selectNotRelatedProjects?: boolean | null, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return ProjectDashboardApiFp(this.configuration).projectDashboardProjectSummary(xmatrixID, teamLider, status, division, sponsor, committie, projectCode, userID, selectNotRelatedProjects, languageCode, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteUserFromCompany: async (userID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/DeleteUserFromCompany`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetFullProfile: async (userID?: string | null, year?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetFullProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetRolePicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetRolePicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetTotalActiveUsersByRole: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetTotalActiveUsersByRole`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserCommittee: async (userID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserCommittee`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<string> | null} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserInfo: async (userIds?: Array<string> | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userIds) {
                localVarQueryParameter['UserIds'] = userIds;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserListPageList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserListPageList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserProfile: async (year?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string} [xmatrixID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserProject: async (userID?: string | null, xmatrixID?: string, languageCode?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/GetUserProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['userID'] = userID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }

            if (languageCode !== undefined) {
                localVarQueryParameter['languageCode'] = languageCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertSystemUserRequestDto} insertSystemUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInsertSystemUser: async (insertSystemUserRequestDto: InsertSystemUserRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertSystemUserRequestDto' is not null or undefined
            assertParamExists('userInsertSystemUser', 'insertSystemUserRequestDto', insertSystemUserRequestDto)
            const localVarPath = `/api/User/InsertSystemUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertSystemUserRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {Array<UserInvitationRequestDto>} userInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInviteUser: async (userInvitationRequestDto: Array<UserInvitationRequestDto>, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'userInvitationRequestDto' is not null or undefined
            assertParamExists('userInviteUser', 'userInvitationRequestDto', userInvitationRequestDto)
            const localVarPath = `/api/User/InviteUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(userInvitationRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUser: async (contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdateUser`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
                if (headers) {
                localVarFormParams.append('Headers', headers.join(COLLECTION_FORMATS.csv));
            }

    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUserProfile: async (contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/User/UpdateUserProfile`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (contentType !== undefined) { 
                localVarFormParams.append('ContentType', contentType as any);
            }
    
            if (contentDisposition !== undefined) { 
                localVarFormParams.append('ContentDisposition', contentDisposition as any);
            }
                if (headers) {
                localVarFormParams.append('Headers', headers.join(COLLECTION_FORMATS.csv));
            }

    
            if (length !== undefined) { 
                localVarFormParams.append('Length', length as any);
            }
    
            if (name !== undefined) { 
                localVarFormParams.append('Name', name as any);
            }
    
            if (fileName !== undefined) { 
                localVarFormParams.append('FileName', fileName as any);
            }
    
            if (data !== undefined) { 
                localVarFormParams.append('data', data as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userDeleteUserFromCompany(userID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userDeleteUserFromCompany(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userDeleteUserFromCompany']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetFullProfile(userID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetFullProfileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetFullProfile(userID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetFullProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetRolePicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetRolePicklistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetRolePicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetRolePicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetTotalActiveUsersByRole(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetTotalActiveUsersByRoleResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetTotalActiveUsersByRole(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetTotalActiveUsersByRole']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserCommittee(userID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetUserCommitteeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserCommittee(userID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetUserCommittee']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<string> | null} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserInfo(userIds?: Array<string> | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfUserInfoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserInfo(userIds, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetUserInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserListPageList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfUserListPageResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserListPageList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetUserListPageList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserProfile(year?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfUserProfileResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserProfile(year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string} [xmatrixID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userGetUserProject(userID?: string | null, xmatrixID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetUserProjectResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userGetUserProject(userID, xmatrixID, languageCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userGetUserProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertSystemUserRequestDto} insertSystemUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInsertSystemUser(insertSystemUserRequestDto: InsertSystemUserRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInsertSystemUser(insertSystemUserRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userInsertSystemUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {Array<UserInvitationRequestDto>} userInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userInviteUser(userInvitationRequestDto: Array<UserInvitationRequestDto>, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<File>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userInviteUser(userInvitationRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userInviteUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateUser(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateUser(contentType, contentDisposition, headers, length, name, fileName, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userUpdateUser']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async userUpdateUserProfile(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.userUpdateUserProfile(contentType, contentDisposition, headers, length, name, fileName, data, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.userUpdateUserProfile']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userDeleteUserFromCompany(userID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.userDeleteUserFromCompany(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetFullProfile(userID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetFullProfileDto> {
            return localVarFp.userGetFullProfile(userID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetRolePicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetRolePicklistDto> {
            return localVarFp.userGetRolePicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetTotalActiveUsersByRole(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetTotalActiveUsersByRoleResponseDto> {
            return localVarFp.userGetTotalActiveUsersByRole(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserCommittee(userID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetUserCommitteeDto> {
            return localVarFp.userGetUserCommittee(userID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<string> | null} [userIds] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserInfo(userIds?: Array<string> | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfUserInfoResponseDto> {
            return localVarFp.userGetUserInfo(userIds, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserListPageList(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfUserListPageResponseDto> {
            return localVarFp.userGetUserListPageList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserProfile(year?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfUserProfileResponseDto> {
            return localVarFp.userGetUserProfile(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string} [xmatrixID] 
         * @param {string | null} [languageCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userGetUserProject(userID?: string | null, xmatrixID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetUserProjectResponseDto> {
            return localVarFp.userGetUserProject(userID, xmatrixID, languageCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertSystemUserRequestDto} insertSystemUserRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInsertSystemUser(insertSystemUserRequestDto: InsertSystemUserRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.userInsertSystemUser(insertSystemUserRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {Array<UserInvitationRequestDto>} userInvitationRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userInviteUser(userInvitationRequestDto: Array<UserInvitationRequestDto>, options?: RawAxiosRequestConfig): AxiosPromise<File> {
            return localVarFp.userInviteUser(userInvitationRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUser(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.userUpdateUser(contentType, contentDisposition, headers, length, name, fileName, data, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [contentType] 
         * @param {string | null} [contentDisposition] 
         * @param {Array<any> | null} [headers] 
         * @param {number} [length] 
         * @param {string | null} [name] 
         * @param {string | null} [fileName] 
         * @param {string | null} [data] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        userUpdateUserProfile(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.userUpdateUserProfile(contentType, contentDisposition, headers, length, name, fileName, data, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @param {string} [userID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userDeleteUserFromCompany(userID?: string, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userDeleteUserFromCompany(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [userID] 
     * @param {string | null} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetFullProfile(userID?: string | null, year?: string | null, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetFullProfile(userID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetRolePicklist(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetRolePicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetTotalActiveUsersByRole(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetTotalActiveUsersByRole(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [userID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserCommittee(userID?: string | null, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserCommittee(userID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<string> | null} [userIds] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserInfo(userIds?: Array<string> | null, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserInfo(userIds, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserListPageList(options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserListPageList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserProfile(year?: string | null, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserProfile(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [userID] 
     * @param {string} [xmatrixID] 
     * @param {string | null} [languageCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userGetUserProject(userID?: string | null, xmatrixID?: string, languageCode?: string | null, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userGetUserProject(userID, xmatrixID, languageCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertSystemUserRequestDto} insertSystemUserRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userInsertSystemUser(insertSystemUserRequestDto: InsertSystemUserRequestDto, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userInsertSystemUser(insertSystemUserRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {Array<UserInvitationRequestDto>} userInvitationRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userInviteUser(userInvitationRequestDto: Array<UserInvitationRequestDto>, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userInviteUser(userInvitationRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [contentType] 
     * @param {string | null} [contentDisposition] 
     * @param {Array<any> | null} [headers] 
     * @param {number} [length] 
     * @param {string | null} [name] 
     * @param {string | null} [fileName] 
     * @param {string | null} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdateUser(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdateUser(contentType, contentDisposition, headers, length, name, fileName, data, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [contentType] 
     * @param {string | null} [contentDisposition] 
     * @param {Array<any> | null} [headers] 
     * @param {number} [length] 
     * @param {string | null} [name] 
     * @param {string | null} [fileName] 
     * @param {string | null} [data] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public userUpdateUserProfile(contentType?: string | null, contentDisposition?: string | null, headers?: Array<any> | null, length?: number, name?: string | null, fileName?: string | null, data?: string | null, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).userUpdateUserProfile(contentType, contentDisposition, headers, length, name, fileName, data, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * WorkloadContollerApi - axios parameter creator
 * @export
 */
export const WorkloadContollerApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string | null} [year] 
         * @param {string | null} [userID] 
         * @param {string | null} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetProjectTimePlanning: async (year?: string | null, userID?: string | null, projectID?: string | null, xmatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetProjectTimePlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (xmatrixID !== undefined) {
                localVarQueryParameter['xmatrixID'] = xmatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetResourceWorkload: async (year?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetResourceWorkload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetSummaryPlanningProject: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetSummaryPlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTeamPlanningProject: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetTeamPlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTotalResourceWorkload: async (year?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetTotalResourceWorkload`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTotalTeamPlanningProject: async (projectID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetTotalTeamPlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetUserPlanningProject: async (userID?: string | null, year?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetUserPlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {string | null} [userID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetUserTimePlanning: async (year?: string | null, userID?: string | null, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/WorkloadContoller/GetUserTimePlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdatePlaningProjectRequest} updatePlaningProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateTeamPlanningProject: async (updatePlaningProjectRequest: UpdatePlaningProjectRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updatePlaningProjectRequest' is not null or undefined
            assertParamExists('workloadContollerUpdateTeamPlanningProject', 'updatePlaningProjectRequest', updatePlaningProjectRequest)
            const localVarPath = `/api/WorkloadContoller/UpdatePlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updatePlaningProjectRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserPlanning} updateUserPlanning 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateUserPlanningProject: async (updateUserPlanning: UpdateUserPlanning, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserPlanning' is not null or undefined
            assertParamExists('workloadContollerUpdateUserPlanningProject', 'updateUserPlanning', updateUserPlanning)
            const localVarPath = `/api/WorkloadContoller/UpdateUserPlanningProject`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserPlanning, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateUserTimePlanning} updateUserTimePlanning 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateUserTimePlanning: async (updateUserTimePlanning: UpdateUserTimePlanning, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateUserTimePlanning' is not null or undefined
            assertParamExists('workloadContollerUpdateUserTimePlanning', 'updateUserTimePlanning', updateUserTimePlanning)
            const localVarPath = `/api/WorkloadContoller/UpdateUserTimePlanning`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateUserTimePlanning, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * WorkloadContollerApi - functional programming interface
 * @export
 */
export const WorkloadContollerApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = WorkloadContollerApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string | null} [year] 
         * @param {string | null} [userID] 
         * @param {string | null} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetProjectTimePlanning(year?: string | null, userID?: string | null, projectID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetProjectTimePlanning(year, userID, projectID, xmatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetProjectTimePlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetResourceWorkload(year?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetResourceWorkload(year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetResourceWorkload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetSummaryPlanningProject(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetSummaryPlanningProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetSummaryPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetTeamPlanningProject(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetTeamPlanningProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetTeamPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetTotalResourceWorkload(year?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetTotalResourceWorkload(year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetTotalResourceWorkload']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetTotalTeamPlanningProject(projectID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetTotalTeamPlanningProject(projectID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetTotalTeamPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetUserPlanningProject(userID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetUserPlanningProject(userID, year, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetUserPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {string | null} [userID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerGetUserTimePlanning(year?: string | null, userID?: string | null, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfExpandoObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerGetUserTimePlanning(year, userID, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerGetUserTimePlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdatePlaningProjectRequest} updatePlaningProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest: UpdatePlaningProjectRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerUpdateTeamPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateUserPlanning} updateUserPlanning 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerUpdateUserPlanningProject(updateUserPlanning: UpdateUserPlanning, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerUpdateUserPlanningProject(updateUserPlanning, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerUpdateUserPlanningProject']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateUserTimePlanning} updateUserTimePlanning 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async workloadContollerUpdateUserTimePlanning(updateUserTimePlanning: UpdateUserTimePlanning, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.workloadContollerUpdateUserTimePlanning(updateUserTimePlanning, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['WorkloadContollerApi.workloadContollerUpdateUserTimePlanning']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * WorkloadContollerApi - factory interface
 * @export
 */
export const WorkloadContollerApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = WorkloadContollerApiFp(configuration)
    return {
        /**
         * 
         * @param {string | null} [year] 
         * @param {string | null} [userID] 
         * @param {string | null} [projectID] 
         * @param {string} [xmatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetProjectTimePlanning(year?: string | null, userID?: string | null, projectID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.workloadContollerGetProjectTimePlanning(year, userID, projectID, xmatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetResourceWorkload(year?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.workloadContollerGetResourceWorkload(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetSummaryPlanningProject(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.workloadContollerGetSummaryPlanningProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTeamPlanningProject(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.workloadContollerGetTeamPlanningProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTotalResourceWorkload(year?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.workloadContollerGetTotalResourceWorkload(year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetTotalTeamPlanningProject(projectID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.workloadContollerGetTotalTeamPlanningProject(projectID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [userID] 
         * @param {string | null} [year] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetUserPlanningProject(userID?: string | null, year?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.workloadContollerGetUserPlanningProject(userID, year, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [year] 
         * @param {string | null} [userID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerGetUserTimePlanning(year?: string | null, userID?: string | null, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfExpandoObject> {
            return localVarFp.workloadContollerGetUserTimePlanning(year, userID, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdatePlaningProjectRequest} updatePlaningProjectRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest: UpdatePlaningProjectRequest, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserPlanning} updateUserPlanning 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateUserPlanningProject(updateUserPlanning: UpdateUserPlanning, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.workloadContollerUpdateUserPlanningProject(updateUserPlanning, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateUserTimePlanning} updateUserTimePlanning 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        workloadContollerUpdateUserTimePlanning(updateUserTimePlanning: UpdateUserTimePlanning, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.workloadContollerUpdateUserTimePlanning(updateUserTimePlanning, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * WorkloadContollerApi - object-oriented interface
 * @export
 * @class WorkloadContollerApi
 * @extends {BaseAPI}
 */
export class WorkloadContollerApi extends BaseAPI {
    /**
     * 
     * @param {string | null} [year] 
     * @param {string | null} [userID] 
     * @param {string | null} [projectID] 
     * @param {string} [xmatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetProjectTimePlanning(year?: string | null, userID?: string | null, projectID?: string | null, xmatrixID?: string, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetProjectTimePlanning(year, userID, projectID, xmatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetResourceWorkload(year?: string | null, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetResourceWorkload(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetSummaryPlanningProject(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetSummaryPlanningProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetTeamPlanningProject(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetTeamPlanningProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetTotalResourceWorkload(year?: string | null, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetTotalResourceWorkload(year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetTotalTeamPlanningProject(projectID?: string | null, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetTotalTeamPlanningProject(projectID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [userID] 
     * @param {string | null} [year] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetUserPlanningProject(userID?: string | null, year?: string | null, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetUserPlanningProject(userID, year, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [year] 
     * @param {string | null} [userID] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerGetUserTimePlanning(year?: string | null, userID?: string | null, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerGetUserTimePlanning(year, userID, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdatePlaningProjectRequest} updatePlaningProjectRequest 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest: UpdatePlaningProjectRequest, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerUpdateTeamPlanningProject(updatePlaningProjectRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserPlanning} updateUserPlanning 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerUpdateUserPlanningProject(updateUserPlanning: UpdateUserPlanning, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerUpdateUserPlanningProject(updateUserPlanning, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateUserTimePlanning} updateUserTimePlanning 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WorkloadContollerApi
     */
    public workloadContollerUpdateUserTimePlanning(updateUserTimePlanning: UpdateUserTimePlanning, options?: RawAxiosRequestConfig) {
        return WorkloadContollerApiFp(this.configuration).workloadContollerUpdateUserTimePlanning(updateUserTimePlanning, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * XMatrixApi - axios parameter creator
 * @export
 */
export const XMatrixApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrixCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteAnnualGoalXMatrixRelation: async (annualGoalID?: string, xMatrixCode?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteAnnualGoalXMatrixRelation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (annualGoalID !== undefined) {
                localVarQueryParameter['AnnualGoalID'] = annualGoalID;
            }

            if (xMatrixCode !== undefined) {
                localVarQueryParameter['XMatrixCode'] = xMatrixCode;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteDraftXMatrix: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteDraftXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [kPIID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteKPIRelationshipXMatrix: async (kPIID?: string, xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteKPIRelationshipXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (kPIID !== undefined) {
                localVarQueryParameter['KPIID'] = kPIID;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteProjectXMatrixDraftRelation: async (projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteProjectXMatrixDraftRelation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (xMatrixCode !== undefined) {
                localVarQueryParameter['XMatrixCode'] = xMatrixCode;
            }

            if (deleteProject !== undefined) {
                localVarQueryParameter['DeleteProject'] = deleteProject;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteProjectXMatrixRelation: async (projectID?: string | null, xMatrixCode?: string | null, deleteProject?: boolean, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteProjectXMatrixRelation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (xMatrixCode !== undefined) {
                localVarQueryParameter['XMatrixCode'] = xMatrixCode;
            }

            if (deleteProject !== undefined) {
                localVarQueryParameter['DeleteProject'] = deleteProject;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [userID] 
         * @param {string | null} [relation] 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteUserRelationshipXMatrix: async (projectID?: string | null, userID?: string | null, relation?: string | null, xMatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteUserRelationshipXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (projectID !== undefined) {
                localVarQueryParameter['ProjectID'] = projectID;
            }

            if (userID !== undefined) {
                localVarQueryParameter['UserID'] = userID;
            }

            if (relation !== undefined) {
                localVarQueryParameter['Relation'] = relation;
            }

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteXMatrixCycle: async (xMatrixCycleID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/DeleteXMatrixCycle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixCycleID !== undefined) {
                localVarQueryParameter['XMatrixCycleID'] = xMatrixCycleID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetActiveXMatrixInfo: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetActiveXMatrixInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetAnnualGoalPicklistXMatrix: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetAnnualGoalPicklistXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetCommitteePicklistXMatrix: async (xMatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetCommitteePicklistXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {XMatrixSecondLevelFilters} xMatrixSecondLevelFilters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionDataXMatrix: async (xMatrixSecondLevelFilters: XMatrixSecondLevelFilters, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xMatrixSecondLevelFilters' is not null or undefined
            assertParamExists('xMatrixGetDivisionDataXMatrix', 'xMatrixSecondLevelFilters', xMatrixSecondLevelFilters)
            const localVarPath = `/api/XMatrix/GetDivisionDataXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(xMatrixSecondLevelFilters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionPicklistXMatrix: async (xMatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetDivisionPicklistXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionProjectCreationPicklist: async (xmatrixId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetDivisionProjectCreationPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetKPIXMAtrixPicklist: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetKPIXMAtrixPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetLongTermGoalChecklist: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetLongTermGoalChecklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetLongTermGoalPicklistXMatrix: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetLongTermGoalPicklistXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixAnnualGoalPicklist: async (xmatrixId?: string, divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetNewDivisionXMatrixAnnualGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixLongTermGoalPicklist: async (xmatrixId?: string, divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetNewDivisionXMatrixLongTermGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixProjectPicklist: async (xmatrixId?: string, divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetNewDivisionXMatrixProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }

            if (divisionID !== undefined) {
                localVarQueryParameter['divisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewXmatrixAnnualGoalPicklist: async (xmatrixId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetNewXmatrixAnnualGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewXmatrixProjectPicklist: async (xmatrixId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetNewXmatrixProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetProjectPicklistXMatrix: async (xMatrixID?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetProjectPicklistXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetRootXMatrixDivisionList: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetRootXMatrixDivisionList`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetSponsorPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetSponsorPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {XMatrixFilters} xMatrixFilters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrix: async (xMatrixFilters: XMatrixFilters, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'xMatrixFilters' is not null or undefined
            assertParamExists('xMatrixGetXMatrix', 'xMatrixFilters', xMatrixFilters)
            const localVarPath = `/api/XMatrix/GetXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(xMatrixFilters, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixCycle: async (companyID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixCycle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (companyID !== undefined) {
                localVarQueryParameter['CompanyID'] = companyID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixCycleByID: async (xMatrixCycleID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixCycleByID`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixCycleID !== undefined) {
                localVarQueryParameter['XMatrixCycleID'] = xMatrixCycleID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixData: async (xmatrixId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixInfo: async (xmatrixId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixInfo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xmatrixId !== undefined) {
                localVarQueryParameter['xmatrixId'] = xmatrixId;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixUsers: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXMatrixUsers`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXmatrixDivisionParentProjectPicklist: async (divisionID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXmatrixDivisionParentProjectPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (divisionID !== undefined) {
                localVarQueryParameter['DivisionID'] = divisionID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXmatrixDivisionPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/GetXmatrixDivisionPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertXmatrixCycleRequestDto} insertXmatrixCycleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInserXMatrixCycle: async (insertXmatrixCycleRequestDto: InsertXmatrixCycleRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertXmatrixCycleRequestDto' is not null or undefined
            assertParamExists('xMatrixInserXMatrixCycle', 'insertXmatrixCycleRequestDto', insertXmatrixCycleRequestDto)
            const localVarPath = `/api/XMatrix/InserXMatrixCycle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertXmatrixCycleRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertKPIXMatrixRequestDto} insertKPIXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertKPIMatrix: async (insertKPIXMatrixRequestDto: InsertKPIXMatrixRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertKPIXMatrixRequestDto' is not null or undefined
            assertParamExists('xMatrixInsertKPIMatrix', 'insertKPIXMatrixRequestDto', insertKPIXMatrixRequestDto)
            const localVarPath = `/api/XMatrix/InsertKPIMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertKPIXMatrixRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertNextYearXMatrixRequestDto} insertNextYearXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertNextYearXMatrix: async (insertNextYearXMatrixRequestDto: InsertNextYearXMatrixRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertNextYearXMatrixRequestDto' is not null or undefined
            assertParamExists('xMatrixInsertNextYearXMatrix', 'insertNextYearXMatrixRequestDto', insertNextYearXMatrixRequestDto)
            const localVarPath = `/api/XMatrix/InsertNextYearXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertNextYearXMatrixRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertProjectXMatrixRequestDto} insertProjectXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertProjectXMatrix: async (insertProjectXMatrixRequestDto: InsertProjectXMatrixRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertProjectXMatrixRequestDto' is not null or undefined
            assertParamExists('xMatrixInsertProjectXMatrix', 'insertProjectXMatrixRequestDto', insertProjectXMatrixRequestDto)
            const localVarPath = `/api/XMatrix/InsertProjectXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertProjectXMatrixRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InsertXMatrixRequestDto} insertXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertXMatrix: async (insertXMatrixRequestDto: InsertXMatrixRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'insertXMatrixRequestDto' is not null or undefined
            assertParamExists('xMatrixInsertXMatrix', 'insertXMatrixRequestDto', insertXMatrixRequestDto)
            const localVarPath = `/api/XMatrix/InsertXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(insertXMatrixRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InserXMatrixDivisionRequestDto} inserXMatrixDivisionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertXMatrixDivision: async (inserXMatrixDivisionRequestDto: InserXMatrixDivisionRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inserXMatrixDivisionRequestDto' is not null or undefined
            assertParamExists('xMatrixInsertXMatrixDivision', 'inserXMatrixDivisionRequestDto', inserXMatrixDivisionRequestDto)
            const localVarPath = `/api/XMatrix/InsertXMatrixDivision`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inserXMatrixDivisionRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixLongTermGoalPicklist: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/LongTermGoalPicklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateAnnualGoalKPIRelationShipRequestDto} updateAnnualGoalKPIRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateAnnualGoalKPIRelationShip: async (updateAnnualGoalKPIRelationShipRequestDto: UpdateAnnualGoalKPIRelationShipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateAnnualGoalKPIRelationShipRequestDto' is not null or undefined
            assertParamExists('xMatrixUpdateAnnualGoalKPIRelationShip', 'updateAnnualGoalKPIRelationShipRequestDto', updateAnnualGoalKPIRelationShipRequestDto)
            const localVarPath = `/api/XMatrix/UpdateAnnualGoalKPIRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateAnnualGoalKPIRelationShipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGoalKPIRelationShipRequestDto} updateGoalKPIRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateGoalKPIRelationShip: async (updateGoalKPIRelationShipRequestDto: UpdateGoalKPIRelationShipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGoalKPIRelationShipRequestDto' is not null or undefined
            assertParamExists('xMatrixUpdateGoalKPIRelationShip', 'updateGoalKPIRelationShipRequestDto', updateGoalKPIRelationShipRequestDto)
            const localVarPath = `/api/XMatrix/UpdateGoalKPIRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGoalKPIRelationShipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateGoalProjectRelationShipDto} updateGoalProjectRelationShipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateGoalProjectRelationShip: async (updateGoalProjectRelationShipDto: UpdateGoalProjectRelationShipDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateGoalProjectRelationShipDto' is not null or undefined
            assertParamExists('xMatrixUpdateGoalProjectRelationShip', 'updateGoalProjectRelationShipDto', updateGoalProjectRelationShipDto)
            const localVarPath = `/api/XMatrix/UpdateGoalProjectRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateGoalProjectRelationShipDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateKPIProjectRelationShipRequestDto} updateKPIProjectRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateKPIProjectRelationShip: async (updateKPIProjectRelationShipRequestDto: UpdateKPIProjectRelationShipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateKPIProjectRelationShipRequestDto' is not null or undefined
            assertParamExists('xMatrixUpdateKPIProjectRelationShip', 'updateKPIProjectRelationShipRequestDto', updateKPIProjectRelationShipRequestDto)
            const localVarPath = `/api/XMatrix/UpdateKPIProjectRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateKPIProjectRelationShipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLongTermAnnualGoalRelationShipDto} updateLongTermAnnualGoalRelationShipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateLongTermAnnualGoalRelationShip: async (updateLongTermAnnualGoalRelationShipDto: UpdateLongTermAnnualGoalRelationShipDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLongTermAnnualGoalRelationShipDto' is not null or undefined
            assertParamExists('xMatrixUpdateLongTermAnnualGoalRelationShip', 'updateLongTermAnnualGoalRelationShipDto', updateLongTermAnnualGoalRelationShipDto)
            const localVarPath = `/api/XMatrix/UpdateLongTermAnnualGoalRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLongTermAnnualGoalRelationShipDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateLongTermGoalChecklistRequestDto} updateLongTermGoalChecklistRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateLongTermGoalChecklist: async (updateLongTermGoalChecklistRequestDto: UpdateLongTermGoalChecklistRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateLongTermGoalChecklistRequestDto' is not null or undefined
            assertParamExists('xMatrixUpdateLongTermGoalChecklist', 'updateLongTermGoalChecklistRequestDto', updateLongTermGoalChecklistRequestDto)
            const localVarPath = `/api/XMatrix/UpdateLongTermGoalChecklist`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateLongTermGoalChecklistRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectSecondaryProjectRelationShipRequestDto} updateProjectSecondaryProjectRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectSecondaryProjectRelationShip: async (updateProjectSecondaryProjectRelationShipRequestDto: UpdateProjectSecondaryProjectRelationShipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectSecondaryProjectRelationShipRequestDto' is not null or undefined
            assertParamExists('xMatrixUpdateProjectSecondaryProjectRelationShip', 'updateProjectSecondaryProjectRelationShipRequestDto', updateProjectSecondaryProjectRelationShipRequestDto)
            const localVarPath = `/api/XMatrix/UpdateProjectSecondaryProjectRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectSecondaryProjectRelationShipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectUserRelationShipRequestDto} updateProjectUserRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectUserRelationShip: async (updateProjectUserRelationShipRequestDto: UpdateProjectUserRelationShipRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectUserRelationShipRequestDto' is not null or undefined
            assertParamExists('xMatrixUpdateProjectUserRelationShip', 'updateProjectUserRelationShipRequestDto', updateProjectUserRelationShipRequestDto)
            const localVarPath = `/api/XMatrix/UpdateProjectUserRelationShip`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectUserRelationShipRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateProjectXMatrixRequestDto} updateProjectXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectXMatrix: async (updateProjectXMatrixRequestDto: UpdateProjectXMatrixRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateProjectXMatrixRequestDto' is not null or undefined
            assertParamExists('xMatrixUpdateProjectXMatrix', 'updateProjectXMatrixRequestDto', updateProjectXMatrixRequestDto)
            const localVarPath = `/api/XMatrix/UpdateProjectXMatrix`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateProjectXMatrixRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateXMatrixCycleRequestDto} updateXMatrixCycleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixCycle: async (updateXMatrixCycleRequestDto: UpdateXMatrixCycleRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateXMatrixCycleRequestDto' is not null or undefined
            assertParamExists('xMatrixUpdateXMatrixCycle', 'updateXMatrixCycleRequestDto', updateXMatrixCycleRequestDto)
            const localVarPath = `/api/XMatrix/UpdateXMatrixCycle`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateXMatrixCycleRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {UpdateXMatrixByIDRequestDto} updateXMatrixByIDRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixData: async (updateXMatrixByIDRequestDto: UpdateXMatrixByIDRequestDto, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'updateXMatrixByIDRequestDto' is not null or undefined
            assertParamExists('xMatrixUpdateXMatrixData', 'updateXMatrixByIDRequestDto', updateXMatrixByIDRequestDto)
            const localVarPath = `/api/XMatrix/UpdateXMatrixData`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(updateXMatrixByIDRequestDto, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixStatus: async (xMatrixID?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/XMatrix/UpdateXMatrixStatus`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (xMatrixID !== undefined) {
                localVarQueryParameter['XMatrixID'] = xMatrixID;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * XMatrixApi - functional programming interface
 * @export
 */
export const XMatrixApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = XMatrixApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrixCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID?: string, xMatrixCode?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID, xMatrixCode, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteAnnualGoalXMatrixRelation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteDraftXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteDraftXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteDraftXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [kPIID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteKPIRelationshipXMatrix(kPIID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteKPIRelationshipXMatrix(kPIID, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteKPIRelationshipXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteProjectXMatrixDraftRelation(projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteProjectXMatrixDraftRelation(projectID, xMatrixCode, deleteProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteProjectXMatrixDraftRelation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteProjectXMatrixRelation(projectID?: string | null, xMatrixCode?: string | null, deleteProject?: boolean, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteProjectXMatrixRelation(projectID, xMatrixCode, deleteProject, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteProjectXMatrixRelation']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [userID] 
         * @param {string | null} [relation] 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteUserRelationshipXMatrix(projectID?: string | null, userID?: string | null, relation?: string | null, xMatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteUserRelationshipXMatrix(projectID, userID, relation, xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteUserRelationshipXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixDeleteXMatrixCycle(xMatrixCycleID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixDeleteXMatrixCycle(xMatrixCycleID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixDeleteXMatrixCycle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetActiveXMatrixInfo(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetXMatrixInfoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetActiveXMatrixInfo(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetActiveXMatrixInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfAnnualGoalPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetAnnualGoalPicklistXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetCommitteePicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetCommitteePicklistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetCommitteePicklistXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetCommitteePicklistXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {XMatrixSecondLevelFilters} xMatrixSecondLevelFilters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters: XMatrixSecondLevelFilters, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfXMatrixSecondLevelDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetDivisionDataXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetDivisionPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetDivisionPicklistDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetDivisionPicklistXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetDivisionPicklistXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetDivisionProjectCreationPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetDivisionProjectCreationPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetDivisionProjectCreationPicklist(xmatrixId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetDivisionProjectCreationPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetKPIXMAtrixPicklist(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetKPIXMatrixPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetKPIXMAtrixPicklist(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetKPIXMAtrixPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetLongTermGoalChecklist(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetLongTermGoalChecklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetLongTermGoalChecklist(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetLongTermGoalChecklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfLongTermGoalPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetLongTermGoalPicklistXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId, divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetNewDivisionXMatrixAnnualGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId, divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetNewDivisionXMatrixLongTermGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId, divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetNewDivisionXMatrixProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetNewXmatrixAnnualGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetNewXmatrixProjectPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetNewXmatrixProjectPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetNewXmatrixProjectPicklist(xmatrixId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetNewXmatrixProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetProjectPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetProjectPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetProjectPicklistXMatrix(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetProjectPicklistXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetRootXMatrixDivisionList(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetRootXMatrixDivisionListResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetRootXMatrixDivisionList(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetRootXMatrixDivisionList']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetSponsorPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetSponsorPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetSponsorPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetSponsorPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {XMatrixFilters} xMatrixFilters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrix(xMatrixFilters: XMatrixFilters, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfXMatrixDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrix(xMatrixFilters, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixCycle(companyID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetXMatrixCycleResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixCycle(companyID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixCycle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixCycleByID(xMatrixCycleID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetXMatrixCycleByIDResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixCycleByID(xMatrixCycleID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixCycleByID']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixData(xmatrixId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfXMatrixDataByIDResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixData(xmatrixId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixInfo(xmatrixId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfGetXMatrixInfoResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixInfo(xmatrixId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixInfo']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetXMatrixPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXMatrixUsers(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetXMatrixUsersResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXMatrixUsers(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXMatrixUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXmatrixDivisionParentProjectPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixGetXmatrixDivisionPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfGetXmatrixDivisionPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixGetXmatrixDivisionPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixGetXmatrixDivisionPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertXmatrixCycleRequestDto} insertXmatrixCycleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto: InsertXmatrixCycleRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInserXMatrixCycle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertKPIXMatrixRequestDto} insertKPIXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto: InsertKPIXMatrixRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfBoolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInsertKPIMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertNextYearXMatrixRequestDto} insertNextYearXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto: InsertNextYearXMatrixRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertNextYearXMatrixResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInsertNextYearXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertProjectXMatrixRequestDto} insertProjectXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto: InsertProjectXMatrixRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertProjectXMatrixResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInsertProjectXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InsertXMatrixRequestDto} insertXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInsertXMatrix(insertXMatrixRequestDto: InsertXMatrixRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertXMatrixResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInsertXMatrix(insertXMatrixRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInsertXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {InserXMatrixDivisionRequestDto} inserXMatrixDivisionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto: InserXMatrixDivisionRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfInsertXMatrixDivisionResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixInsertXMatrixDivision']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixLongTermGoalPicklist(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfListOfLongTermGoalPicklistResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixLongTermGoalPicklist(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixLongTermGoalPicklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateAnnualGoalKPIRelationShipRequestDto} updateAnnualGoalKPIRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto: UpdateAnnualGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfUpdateAnnualGoalKPIRelationShipResponseDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateAnnualGoalKPIRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateGoalKPIRelationShipRequestDto} updateGoalKPIRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto: UpdateGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateGoalKPIRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateGoalProjectRelationShipDto} updateGoalProjectRelationShipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto: UpdateGoalProjectRelationShipDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateGoalProjectRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateKPIProjectRelationShipRequestDto} updateKPIProjectRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto: UpdateKPIProjectRelationShipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateKPIProjectRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateLongTermAnnualGoalRelationShipDto} updateLongTermAnnualGoalRelationShipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto: UpdateLongTermAnnualGoalRelationShipDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateLongTermAnnualGoalRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateLongTermGoalChecklistRequestDto} updateLongTermGoalChecklistRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto: UpdateLongTermGoalChecklistRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateLongTermGoalChecklist']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectSecondaryProjectRelationShipRequestDto} updateProjectSecondaryProjectRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto: UpdateProjectSecondaryProjectRelationShipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfUpdateProjectSecondaryProjectRelationShipResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateProjectSecondaryProjectRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectUserRelationShipRequestDto} updateProjectUserRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto: UpdateProjectUserRelationShipRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateProjectUserRelationShip']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateProjectXMatrixRequestDto} updateProjectXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto: UpdateProjectXMatrixRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateProjectXMatrix']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateXMatrixCycleRequestDto} updateXMatrixCycleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto: UpdateXMatrixCycleRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateXMatrixCycle']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {UpdateXMatrixByIDRequestDto} updateXMatrixByIDRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto: UpdateXMatrixByIDRequestDto, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateXMatrixData']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async xMatrixUpdateXMatrixStatus(xMatrixID?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ControllerResponseOfObject>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.xMatrixUpdateXMatrixStatus(xMatrixID, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['XMatrixApi.xMatrixUpdateXMatrixStatus']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * XMatrixApi - factory interface
 * @export
 */
export const XMatrixApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = XMatrixApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [annualGoalID] 
         * @param {string} [xMatrixCode] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID?: string, xMatrixCode?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID, xMatrixCode, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteDraftXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.xMatrixDeleteDraftXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [kPIID] 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteKPIRelationshipXMatrix(kPIID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.xMatrixDeleteKPIRelationshipXMatrix(kPIID, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [projectID] 
         * @param {string} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteProjectXMatrixDraftRelation(projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.xMatrixDeleteProjectXMatrixDraftRelation(projectID, xMatrixCode, deleteProject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [xMatrixCode] 
         * @param {boolean} [deleteProject] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteProjectXMatrixRelation(projectID?: string | null, xMatrixCode?: string | null, deleteProject?: boolean, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.xMatrixDeleteProjectXMatrixRelation(projectID, xMatrixCode, deleteProject, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [projectID] 
         * @param {string | null} [userID] 
         * @param {string | null} [relation] 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteUserRelationshipXMatrix(projectID?: string | null, userID?: string | null, relation?: string | null, xMatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.xMatrixDeleteUserRelationshipXMatrix(projectID, userID, relation, xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixDeleteXMatrixCycle(xMatrixCycleID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.xMatrixDeleteXMatrixCycle(xMatrixCycleID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetActiveXMatrixInfo(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetXMatrixInfoResponseDto> {
            return localVarFp.xMatrixGetActiveXMatrixInfo(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfAnnualGoalPicklistResponseDto> {
            return localVarFp.xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetCommitteePicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetCommitteePicklistDto> {
            return localVarFp.xMatrixGetCommitteePicklistXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {XMatrixSecondLevelFilters} xMatrixSecondLevelFilters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters: XMatrixSecondLevelFilters, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfXMatrixSecondLevelDto> {
            return localVarFp.xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetDivisionPicklistDto> {
            return localVarFp.xMatrixGetDivisionPicklistXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetDivisionProjectCreationPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetDivisionProjectCreationPicklistResponseDto> {
            return localVarFp.xMatrixGetDivisionProjectCreationPicklist(xmatrixId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetKPIXMAtrixPicklist(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetKPIXMatrixPicklistResponseDto> {
            return localVarFp.xMatrixGetKPIXMAtrixPicklist(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetLongTermGoalChecklist(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetLongTermGoalChecklistResponseDto> {
            return localVarFp.xMatrixGetLongTermGoalChecklist(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfLongTermGoalPicklistResponseDto> {
            return localVarFp.xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetNewDivisionXMatrixAnnualGoalPicklistResponseDto> {
            return localVarFp.xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId, divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetXMatrixLongTermGoalPicklistResponseDto> {
            return localVarFp.xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId, divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetNewDivisionXMatrixProjectPicklistResponseDto> {
            return localVarFp.xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId, divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetNewXmatrixAnnualGoalPicklistResponseDto> {
            return localVarFp.xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetNewXmatrixProjectPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetNewXmatrixProjectPicklistResponseDto> {
            return localVarFp.xMatrixGetNewXmatrixProjectPicklist(xmatrixId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string | null} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetProjectPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetProjectPicklistResponseDto> {
            return localVarFp.xMatrixGetProjectPicklistXMatrix(xMatrixID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetRootXMatrixDivisionList(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetRootXMatrixDivisionListResponseDto> {
            return localVarFp.xMatrixGetRootXMatrixDivisionList(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetSponsorPicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetSponsorPicklistResponseDto> {
            return localVarFp.xMatrixGetSponsorPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {XMatrixFilters} xMatrixFilters 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrix(xMatrixFilters: XMatrixFilters, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfXMatrixDto> {
            return localVarFp.xMatrixGetXMatrix(xMatrixFilters, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [companyID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixCycle(companyID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetXMatrixCycleResponseDto> {
            return localVarFp.xMatrixGetXMatrixCycle(companyID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixCycleID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixCycleByID(xMatrixCycleID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetXMatrixCycleByIDResponseDto> {
            return localVarFp.xMatrixGetXMatrixCycleByID(xMatrixCycleID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixData(xmatrixId?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfXMatrixDataByIDResponseDto> {
            return localVarFp.xMatrixGetXMatrixData(xmatrixId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xmatrixId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixInfo(xmatrixId?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfGetXMatrixInfoResponseDto> {
            return localVarFp.xMatrixGetXMatrixInfo(xmatrixId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixPicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetXMatrixPicklistResponseDto> {
            return localVarFp.xMatrixGetXMatrixPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXMatrixUsers(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetXMatrixUsersResponseDto> {
            return localVarFp.xMatrixGetXMatrixUsers(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [divisionID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetXmatrixDivisionParentProjectPicklistResponseDto> {
            return localVarFp.xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixGetXmatrixDivisionPicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfGetXmatrixDivisionPicklistResponseDto> {
            return localVarFp.xMatrixGetXmatrixDivisionPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertXmatrixCycleRequestDto} insertXmatrixCycleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto: InsertXmatrixCycleRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertKPIXMatrixRequestDto} insertKPIXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto: InsertKPIXMatrixRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfBoolean> {
            return localVarFp.xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertNextYearXMatrixRequestDto} insertNextYearXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto: InsertNextYearXMatrixRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertNextYearXMatrixResponseDto> {
            return localVarFp.xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertProjectXMatrixRequestDto} insertProjectXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto: InsertProjectXMatrixRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertProjectXMatrixResponse> {
            return localVarFp.xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InsertXMatrixRequestDto} insertXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertXMatrix(insertXMatrixRequestDto: InsertXMatrixRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertXMatrixResponseDto> {
            return localVarFp.xMatrixInsertXMatrix(insertXMatrixRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InserXMatrixDivisionRequestDto} inserXMatrixDivisionRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto: InserXMatrixDivisionRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfInsertXMatrixDivisionResponseDto> {
            return localVarFp.xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixLongTermGoalPicklist(options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfListOfLongTermGoalPicklistResponseDto> {
            return localVarFp.xMatrixLongTermGoalPicklist(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateAnnualGoalKPIRelationShipRequestDto} updateAnnualGoalKPIRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto: UpdateAnnualGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfUpdateAnnualGoalKPIRelationShipResponseDto> {
            return localVarFp.xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGoalKPIRelationShipRequestDto} updateGoalKPIRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto: UpdateGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateGoalProjectRelationShipDto} updateGoalProjectRelationShipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto: UpdateGoalProjectRelationShipDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateKPIProjectRelationShipRequestDto} updateKPIProjectRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto: UpdateKPIProjectRelationShipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLongTermAnnualGoalRelationShipDto} updateLongTermAnnualGoalRelationShipDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto: UpdateLongTermAnnualGoalRelationShipDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateLongTermGoalChecklistRequestDto} updateLongTermGoalChecklistRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto: UpdateLongTermGoalChecklistRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectSecondaryProjectRelationShipRequestDto} updateProjectSecondaryProjectRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto: UpdateProjectSecondaryProjectRelationShipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfUpdateProjectSecondaryProjectRelationShipResponse> {
            return localVarFp.xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectUserRelationShipRequestDto} updateProjectUserRelationShipRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto: UpdateProjectUserRelationShipRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateProjectXMatrixRequestDto} updateProjectXMatrixRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto: UpdateProjectXMatrixRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateXMatrixCycleRequestDto} updateXMatrixCycleRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto: UpdateXMatrixCycleRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {UpdateXMatrixByIDRequestDto} updateXMatrixByIDRequestDto 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto: UpdateXMatrixByIDRequestDto, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xMatrixID] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        xMatrixUpdateXMatrixStatus(xMatrixID?: string, options?: RawAxiosRequestConfig): AxiosPromise<ControllerResponseOfObject> {
            return localVarFp.xMatrixUpdateXMatrixStatus(xMatrixID, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * XMatrixApi - object-oriented interface
 * @export
 * @class XMatrixApi
 * @extends {BaseAPI}
 */
export class XMatrixApi extends BaseAPI {
    /**
     * 
     * @param {string} [annualGoalID] 
     * @param {string} [xMatrixCode] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID?: string, xMatrixCode?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalID, xMatrixCode, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteDraftXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteDraftXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [kPIID] 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteKPIRelationshipXMatrix(kPIID?: string, xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteKPIRelationshipXMatrix(kPIID, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [projectID] 
     * @param {string} [xMatrixCode] 
     * @param {boolean} [deleteProject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteProjectXMatrixDraftRelation(projectID?: string, xMatrixCode?: string, deleteProject?: boolean, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteProjectXMatrixDraftRelation(projectID, xMatrixCode, deleteProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string | null} [xMatrixCode] 
     * @param {boolean} [deleteProject] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteProjectXMatrixRelation(projectID?: string | null, xMatrixCode?: string | null, deleteProject?: boolean, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteProjectXMatrixRelation(projectID, xMatrixCode, deleteProject, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [projectID] 
     * @param {string | null} [userID] 
     * @param {string | null} [relation] 
     * @param {string | null} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteUserRelationshipXMatrix(projectID?: string | null, userID?: string | null, relation?: string | null, xMatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteUserRelationshipXMatrix(projectID, userID, relation, xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixCycleID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixDeleteXMatrixCycle(xMatrixCycleID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixDeleteXMatrixCycle(xMatrixCycleID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetActiveXMatrixInfo(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetActiveXMatrixInfo(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetAnnualGoalPicklistXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetCommitteePicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetCommitteePicklistXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {XMatrixSecondLevelFilters} xMatrixSecondLevelFilters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters: XMatrixSecondLevelFilters, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetDivisionDataXMatrix(xMatrixSecondLevelFilters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetDivisionPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetDivisionPicklistXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetDivisionProjectCreationPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetDivisionProjectCreationPicklist(xmatrixId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetKPIXMAtrixPicklist(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetKPIXMAtrixPicklist(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetLongTermGoalChecklist(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetLongTermGoalChecklist(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetLongTermGoalPicklistXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetNewDivisionXMatrixAnnualGoalPicklist(xmatrixId, divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetNewDivisionXMatrixLongTermGoalPicklist(xmatrixId, divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId?: string, divisionID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetNewDivisionXMatrixProjectPicklist(xmatrixId, divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetNewXmatrixAnnualGoalPicklist(xmatrixId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetNewXmatrixProjectPicklist(xmatrixId?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetNewXmatrixProjectPicklist(xmatrixId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string | null} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetProjectPicklistXMatrix(xMatrixID?: string | null, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetProjectPicklistXMatrix(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetRootXMatrixDivisionList(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetRootXMatrixDivisionList(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetSponsorPicklist(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetSponsorPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {XMatrixFilters} xMatrixFilters 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrix(xMatrixFilters: XMatrixFilters, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrix(xMatrixFilters, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [companyID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixCycle(companyID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixCycle(companyID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixCycleID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixCycleByID(xMatrixCycleID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixCycleByID(xMatrixCycleID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixData(xmatrixId?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixData(xmatrixId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xmatrixId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixInfo(xmatrixId?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixInfo(xmatrixId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixPicklist(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXMatrixUsers(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXMatrixUsers(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [divisionID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXmatrixDivisionParentProjectPicklist(divisionID, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixGetXmatrixDivisionPicklist(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixGetXmatrixDivisionPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertXmatrixCycleRequestDto} insertXmatrixCycleRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto: InsertXmatrixCycleRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInserXMatrixCycle(insertXmatrixCycleRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertKPIXMatrixRequestDto} insertKPIXMatrixRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto: InsertKPIXMatrixRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInsertKPIMatrix(insertKPIXMatrixRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertNextYearXMatrixRequestDto} insertNextYearXMatrixRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto: InsertNextYearXMatrixRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInsertNextYearXMatrix(insertNextYearXMatrixRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertProjectXMatrixRequestDto} insertProjectXMatrixRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto: InsertProjectXMatrixRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInsertProjectXMatrix(insertProjectXMatrixRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InsertXMatrixRequestDto} insertXMatrixRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInsertXMatrix(insertXMatrixRequestDto: InsertXMatrixRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInsertXMatrix(insertXMatrixRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {InserXMatrixDivisionRequestDto} inserXMatrixDivisionRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto: InserXMatrixDivisionRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixInsertXMatrixDivision(inserXMatrixDivisionRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixLongTermGoalPicklist(options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixLongTermGoalPicklist(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateAnnualGoalKPIRelationShipRequestDto} updateAnnualGoalKPIRelationShipRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto: UpdateAnnualGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateAnnualGoalKPIRelationShip(updateAnnualGoalKPIRelationShipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGoalKPIRelationShipRequestDto} updateGoalKPIRelationShipRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto: UpdateGoalKPIRelationShipRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateGoalKPIRelationShip(updateGoalKPIRelationShipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateGoalProjectRelationShipDto} updateGoalProjectRelationShipDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto: UpdateGoalProjectRelationShipDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateGoalProjectRelationShip(updateGoalProjectRelationShipDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateKPIProjectRelationShipRequestDto} updateKPIProjectRelationShipRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto: UpdateKPIProjectRelationShipRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateKPIProjectRelationShip(updateKPIProjectRelationShipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateLongTermAnnualGoalRelationShipDto} updateLongTermAnnualGoalRelationShipDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto: UpdateLongTermAnnualGoalRelationShipDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateLongTermAnnualGoalRelationShip(updateLongTermAnnualGoalRelationShipDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateLongTermGoalChecklistRequestDto} updateLongTermGoalChecklistRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto: UpdateLongTermGoalChecklistRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateLongTermGoalChecklist(updateLongTermGoalChecklistRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectSecondaryProjectRelationShipRequestDto} updateProjectSecondaryProjectRelationShipRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto: UpdateProjectSecondaryProjectRelationShipRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateProjectSecondaryProjectRelationShip(updateProjectSecondaryProjectRelationShipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectUserRelationShipRequestDto} updateProjectUserRelationShipRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto: UpdateProjectUserRelationShipRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateProjectUserRelationShip(updateProjectUserRelationShipRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateProjectXMatrixRequestDto} updateProjectXMatrixRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto: UpdateProjectXMatrixRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateProjectXMatrix(updateProjectXMatrixRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateXMatrixCycleRequestDto} updateXMatrixCycleRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto: UpdateXMatrixCycleRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateXMatrixCycle(updateXMatrixCycleRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {UpdateXMatrixByIDRequestDto} updateXMatrixByIDRequestDto 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto: UpdateXMatrixByIDRequestDto, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateXMatrixData(updateXMatrixByIDRequestDto, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [xMatrixID] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof XMatrixApi
     */
    public xMatrixUpdateXMatrixStatus(xMatrixID?: string, options?: RawAxiosRequestConfig) {
        return XMatrixApiFp(this.configuration).xMatrixUpdateXMatrixStatus(xMatrixID, options).then((request) => request(this.axios, this.basePath));
    }
}




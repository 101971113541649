import { trackPromise } from 'react-promise-tracker';
import { acquireToken } from '../../config/auth/clientAuthProvider';
import axiosInstance from '../../config/axiosConfig/interceptors';
import { authenticatedApi, DivisionApi } from 'src/config/connectors';

const DivisionService = {
  // get commities
  getDivisionsList: async function () {
    const api = await authenticatedApi(DivisionApi);

    return trackPromise(api.divisionGetDivisionListPage());
  },

  getDivisionByiD: async function (id: string) {
    const api = await authenticatedApi(DivisionApi);

    return trackPromise(api.divisionGetDivisionByID(id));
  },

  updateDivision: function (payload: any) {
    const data = acquireToken().then((tokenResponse) => {
      const optionsVal = {
        method: 'PUT',
        url: `api/Division/UpdateDivision`,
        data: payload,
        headers: {
          Authorization: `Bearer ${tokenResponse?.accessToken}`,
        },
      };
      const apiResponse = trackPromise(axiosInstance(optionsVal));
      return apiResponse;
    });
    return data;
  },

  //add division
  addDivision: function (payload: any) {
    const data = acquireToken().then((tokenResponse) => {
      const optionsVal = {
        method: 'POST',
        url: `api/Division/InsertDivision`,
        data: payload,
        headers: {
          Authorization: `Bearer ${tokenResponse?.accessToken}`,
        },
      };
      const apiResponse = trackPromise(axiosInstance(optionsVal));
      return apiResponse;
    });
    return data;
  },
};
export default DivisionService;

import {
  CheckOutlined,
  EditOutlined,
  FilterFilled,
  PlusOutlined,
  SearchOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons';
import { Button, Card, Dropdown, Input, message, Space, Table, Tooltip, Typography } from 'antd';
import { useEffect, useState } from 'react';
import Highlighter from 'react-highlight-words';
import { useTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import ObiettiviLongService from '../../services/pages/obiettiviLongService';
import CategoryIcon from '../shared/CategoryIcon';
import TableLayout from '../shared/tableLayout';
import { isTeamleaderOrTeamMember } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './obietiviLongTerm.scss';

const { Text } = Typography;

const ObiettiviLongTerm = (props) => {
  const [loadingData, setLoadingData] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const [longTermListData, setLongTermListData] = useState(null);
  const history = useHistory();
  const { t } = useTranslation();

  useEffect(() => {
    retrieveLongTermData();
  }, []);

  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder={`Search`}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };

  const handleReset = (clearFilters) => {
    clearFilters();
    setSearchText('');
  };

  const retrieveLongTermData = async () => {
    setLoadingData(true);
    try {
      const response = await ObiettiviLongService.getObiettiviLongList();
      const resp = response.data;
      if (resp.success) {
        setLongTermListData(resp.responseObject.value);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    } finally {
      setLoadingData(false);
    }
  };

  const onNew = () => {
    history.push('/obiettivialungoperiodo/new');
  };

  const { userData } = props;
  const isRoleTeamMemberOrTeamLeader =
    userData && Object.keys(userData).length > 0 ? isTeamleaderOrTeamMember(userData) : false;

  const columns = [
    {
      title: `${t('general.nome')}`,
      dataIndex: 'title',
      ellipsis: {
        showTitle: true,
      },
      width: '35%',
      sorter: (a, b) => (a.title || '').localeCompare(b.title || ''),
      showSorterTooltip: [false],
      ...getColumnSearchProps('title'),
      render: (title, record) => (
        <Tooltip title={title}>
          <Link to={{ pathname: `/obiettivialungoperiodo/details/id/${record.longTermGoalID}` }}>{title}</Link>
        </Tooltip>
      ),
    },
    {
      title: `${t('obiettiviLungoPage.categoria')}`,
      dataIndex: 'goalCategories',
      render: (text, record) => {
        const items =
          record.goalCategories?.map((item, index) => ({
            label: (
              <span className="tw-flex tw-items-center tw-gap-2">
                <CategoryIcon iconName={item.iconSource} />
                {item.name}
              </span>
            ),
            key: index,
          })) || [];

        return record.goalCategories ? (
          <Dropdown
            placement="bottomLeft"
            arrow
            menu={{ items }}>
            <Text onClick={(e) => e.preventDefault()}>
              <UnorderedListOutlined />
            </Text>
          </Dropdown>
        ) : null;
      },
    },
    {
      title: `${t('general.sponsor')}`,
      dataIndex: 'sponsorFullName',
      sorter: (a, b) => (a.sponsorFullName || '').localeCompare(b.sponsorFullName || ''),
      showSorterTooltip: [false],
      ...getColumnSearchProps('sponsorFullName'),
    },
    {
      title: `${t('general.tipo')}`,
      dataIndex: 'goalTypeDescription',
      sorter: (a, b) => (a.goalTypeDescription || '').localeCompare(b.goalTypeDescription || ''),
      showSorterTooltip: [false],
      ...getColumnSearchProps('goalTypeDescription'),
    },
    {
      title: `${t('general.stato')}`,
      dataIndex: 'statusDescription',
      width: '130px',
      sorter: (a, b) => a.statusDescription.localeCompare(b.statusDescription),
      showSorterTooltip: [false],
      ...getColumnSearchProps('statusDescription'),
    },
    {
      title: `${t('obiettiviLungoPage.coerenzaEnvisioning')}`,
      dataIndex: 'isRelatedVMV',
      width: '300px',
      filters: [
        { text: `${t('general.si')}`, value: true },
        { text: `${t('general.no')}`, value: false },
      ],
      filterIcon: (
        <Tooltip title={`${t('general.filter')}`}>
          <FilterFilled />
        </Tooltip>
      ),
      sorter: (a, b) => a.isRelatedVMV.toString().localeCompare(b.isRelatedVMV.toString()),
      onFilter: (value, record) => record.isRelatedVMV === value,
      showSorterTooltip: [false],
      render: (text) => (text ? <CheckOutlined style={{ color: '#16a34a' }} /> : null),
    },
    {
      key: 'action',
      width: '50px',
      render: (text, record) => (
        <Button
          href={`/obiettivialungoperiodo/details/id/${record.longTermGoalID}`}
          icon={<EditOutlined />}></Button>
      ),
    },
  ];

  return (
    <Card>
      <TableLayout title={t('obiettiviLungoPage.obiettiviLungo')}>
        <TableLayout.Actions>
          {!isRoleTeamMemberOrTeamLeader && (
            <Button
              type="primary"
              onClick={onNew}>
              <PlusOutlined />
              {t('buttons.aggiungiNuovo')}
            </Button>
          )}
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            columns={columns}
            size="small"
            dataSource={longTermListData}
            rowKey={(obj) => obj.longTermGoalID}
            loading={loadingData}
            pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
          />
        </TableLayout.Content>
      </TableLayout>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ObiettiviLongTerm));

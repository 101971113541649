import { trackPromise } from 'react-promise-tracker';
import { InsertLongTermGoalRequestDto, LongTermGoalApi, UpdateLongTermGoalChecklistRequestDto, UpdateLongTermGoalXRequestDto, XMatrixApi } from 'src/connectors/backend';
import { authenticatedApi } from 'src/config/connectors';

const ObLongService = {
  updateObiettivoLungoxMartix: async function (payload: UpdateLongTermGoalXRequestDto) {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalUpdateLongTermGoalXMatrix(payload));
  },

  addObiettivoLungoxMartix: async function (payload: InsertLongTermGoalRequestDto) {
    const api = await authenticatedApi(LongTermGoalApi);

    return trackPromise(api.longTermGoalInsertLongTermGoalXMatrix(payload));
  },

  getAttiva: async function (selectedXmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixGetLongTermGoalChecklist(selectedXmatrixId));
  },

  // update obiettivo lungo in matrix
  updateAttiva: async function (payload: UpdateLongTermGoalChecklistRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateLongTermGoalChecklist(payload));
  },
};

export default ObLongService;

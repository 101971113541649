import { FilterFilled, MinusSquareOutlined } from '@ant-design/icons';
import { Button, Select, Tag, Tooltip, Typography } from 'antd';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import React, { useEffect, useState } from 'react';
import PersonalDashboardService from '../../services/pages/personalDashboardService';
import Loader2 from '../shared/components/loader2/loader2';
import { projectStatus } from '../shared/utils/constants';
import { xMatrixFilterChartOptions } from './chartsConfig';

import i18n from '../../i18n';
import TableLayout from '../shared/tableLayout';

const { Option } = Select;

const ProjectsPerSponsor = ({
  userRoleType,
  t,
  activeXmatrixInfo,
  selectedXmatrixValue,
  divisionPicklist,
  teamLeaderPicklist,
  commitiePicklist,
}) => {
  let getCurrentLng = i18n.language;
  const [loadingData, setLoadingData] = useState(false);
  const [chartData, setChartData] = useState(xMatrixFilterChartOptions);

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [selectedDivisionIds, setSelectedDivisionIds] = useState([]);
  const [selectedCommitieIds, setSelectedCommitieIds] = useState([]);

  useEffect(() => {
    if (selectedUserIds.length === 0 && selectedDivisionIds.length === 0 && selectedCommitieIds.length === 0) {
      getXmatrixFilterChartData();
    }
  }, [selectedUserIds, selectedDivisionIds, selectedCommitieIds]);

  useEffect(() => {
    if (activeXmatrixInfo) {
      getXmatrixFilterChartData();
    }
  }, [selectedXmatrixValue, activeXmatrixInfo]);

  const getXmatrixFilterChartData = async () => {
    let xmatrix = selectedXmatrixValue ? selectedXmatrixValue : activeXmatrixInfo?.xMatrixID;

    let payload = {};

    payload['DivisionID'] = selectedDivisionIds;
    payload['UserID'] = selectedUserIds;
    payload['CommitteeID'] = selectedCommitieIds;
    payload['LanguageCode'] = getCurrentLng;
    payload['UserRole'] = userRoleType;
    payload['XMatrixID'] = xmatrix;

    setLoadingData(true);
    await PersonalDashboardService.getXmatrixFilterChartData(payload)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let series = [];
          let xAxisCategories = [];
          let xAxisObject = {};

          let attivoSeries = { name: `${t('status.attivo')}`, data: [] };
          let sospesoSeries = { name: `${t('status.sospeso')}`, data: [] };
          let draftSeries = { name: `${t('status.draft')}`, data: [] };
          let completatoSeries = { name: `${t('status.completato')}`, data: [] };

          xAxisCategories = resp.responseObject.value.map((a) => a.fullName);
          xAxisObject['categories'] = xAxisCategories;

          resp.responseObject.value.map((item) => {
            item.statusList.map((status) => {
              if (status.projectStatus === projectStatus.attivo) {
                attivoSeries.data.push(status.projectNumber);
              }
              if (status.projectStatus === projectStatus.sospeso) {
                sospesoSeries.data.push(status.projectNumber);
              }
              if (status.projectStatus === projectStatus.draft) {
                draftSeries.data.push(status.projectNumber);
              }
              if (status.projectStatus === projectStatus.completato) {
                completatoSeries.data.push(status.projectNumber);
              }
            });
          });

          series.push(attivoSeries);
          series.push(sospesoSeries);
          series.push(draftSeries);
          series.push(completatoSeries);

          const chart = { ...chartData };
          chart.series = series;
          chart.xAxis = xAxisObject;
          setChartData(chart);

          setLoadingData(false);
        } else {
          setLoadingData(false);
        }
      })
      .catch((error) => {
        setLoadingData(false);
      });
  };

  const onUserSelect = (selectedRowKeys) => {
    setSelectedUserIds(selectedRowKeys);
  };

  const onDivisionSelect = (selectedRowKeys) => {
    setSelectedDivisionIds(selectedRowKeys);
  };

  const onCommittieSelect = (selectedRowKeys) => {
    setSelectedCommitieIds(selectedRowKeys);
  };

  const onFilter = async () => {
    await getXmatrixFilterChartData();
  };

  const onClearFilter = async () => {
    setSelectedDivisionIds([]);
    setSelectedUserIds([]);
    setSelectedCommitieIds([]);
  };

  const tagRender = (props) => {
    const { label, closable, onClose } = props;
    console.log(props);

    return (
      <Tag
        className="tw-max-w-28 tw-h-[30px] tw-flex tw-items-center tw-relative [&_.anticon-close]:tw-shrink-0"
        closable={closable}
        onClose={onClose}>
        <Tooltip
          title={label}
          placement="top">
          <span className="tw-shrink tw-max-w-16 tw-truncate">{label?.replace('...', '')}</span>
        </Tooltip>
      </Tag>
    );
  };

  return (
    <TableLayout
      title={
        <Typography.Title
          className="!tw-mb-0"
          level={4}>
          {t('bachechaPersonalePage.progettiSponsor')}
        </Typography.Title>
      }>
      <TableLayout.Actions>
        <Select
          value={selectedUserIds}
          placeholder={t('bachechaPersonalePage.utenti')}
          mode="multiple"
          allowClear
          maxTagCount={2}
          tagRender={tagRender}
          className="tw-shrink-0 tw-w-[300px]"
          onChange={onUserSelect}
          options={teamLeaderPicklist}
          showSearch
          filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
          loading={!teamLeaderPicklist}
        />
        <Select
          value={selectedDivisionIds}
          placeholder={t('general.divisione')}
          mode="multiple"
          allowClear
          showSearch
          maxTagCount={2}
          tagRender={tagRender}
          className="tw-shrink-0 tw-w-[300px]"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={onDivisionSelect}>
          {divisionPicklist &&
            divisionPicklist.map((item) => (
              <Option
                disabled={item.hasAccess === 1 ? false : true}
                value={item.divisionID}
                key={item.divisionID}>
                {item.divisionName}
              </Option>
            ))}
        </Select>
        <Select
          value={selectedCommitieIds}
          placeholder={t('general.comitato')}
          mode="multiple"
          allowClear
          showSearch
          maxTagCount={2}
          tagRender={tagRender}
          className="tw-shrink-0 tw-w-[300px]"
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={onCommittieSelect}>
          {commitiePicklist &&
            commitiePicklist.map((item) => (
              <Option
                disabled={item.hasAccess === 1 ? false : true}
                value={item.committeeID}
                key={item.committeeID}>
                {item.name}
              </Option>
            ))}
        </Select>
        <Tooltip title={t('general.filter')}>
          <Button
            icon={<FilterFilled />}
            onClick={() => onFilter()}
            disabled={!selectedDivisionIds && !selectedUserIds && !selectedCommitieIds}
          />
        </Tooltip>
        <Tooltip title={t('general.clearFilters')}>
          <Button
            icon={<MinusSquareOutlined />}
            onClick={() => onClearFilter()}
            disabled={selectedDivisionIds || selectedUserIds || selectedCommitieIds ? false : true}
          />
        </Tooltip>
      </TableLayout.Actions>

      <TableLayout.Content>
        {loadingData && <Loader2 />}
        {chartData && (
          <HighchartsReact
            highcharts={Highcharts}
            options={chartData}
          />
        )}
      </TableLayout.Content>
    </TableLayout>
  );
};
export default ProjectsPerSponsor;

import { CheckCircleOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, Drawer, Menu, Skeleton, Typography } from 'antd';
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import MatrixService from '../../services/matrix/matrixService';

const { Text } = Typography;
const { SubMenu } = Menu;

class XmatrixFilterPanel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      panelVisibility: false,
      showModal: false,
      loadingButton: false,
      xMatrixData: null,
      xmatrixRootList: null,
      loadingRootList: false,
      selectedModalXmatrixData: null,
    };
  }

  componentDidMount() {
    this.getXmatrixRootList();
  }

  async getXmatrixRootList() {
    this.setState({ loadingRootList: true });
    await MatrixService.getXmatrixDivisionRootList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ loadingRootList: false });
          this.setState({ xmatrixRootList: resp.responseObject.value });
        } else {
          this.setState({ loadingRootList: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingRootList: false });
      });
  }

  showLeftPanel = () => {
    this.setState({ panelVisibility: true });
  };

  hideLeftPanel = () => {
    this.setState({ panelVisibility: false });
  };

  onXmatrixSelect = (xMatrixId, selectedXmatrix) => {
    this.hideLeftPanel();
    this.props.onXmatrixSelect(xMatrixId, selectedXmatrix);
  };

  render() {
    const { panelVisibility, xmatrixRootList, loadingRootList } = this.state;
    const { activeXmatrixInfo } = this.props;
    let t = this.props.t;

    return (
      <>
        <Button
          onClick={this.showLeftPanel}
          icon={<MenuOutlined />}></Button>
        <Drawer
          title={t('xMatrixPage.elencoXmatrix')}
          placement="left"
          onClose={this.hideLeftPanel}
          open={panelVisibility}>
          {activeXmatrixInfo && (
            <Skeleton
              loading={loadingRootList}
              active
              title
              paragraph
              avatar>
              {xmatrixRootList && (
                <Menu
                  defaultOpenKeys={[activeXmatrixInfo?.xMatrixID]}
                  mode="inline">
                  {xmatrixRootList.map((xMatrix, index) => {
                    return (
                      <SubMenu
                        key={xMatrix.xmatrixID}
                        icon={
                          activeXmatrixInfo?.xMatrixID === xMatrix.xmatrixID ? (
                            <CheckCircleOutlined className="active-xmatrix" />
                          ) : (
                            <CheckCircleOutlined className="no-active-xmatrix" />
                          )
                        }
                        title={
                          <Text onClick={() => this.onXmatrixSelect(xMatrix.xmatrixID, xMatrix)}>{xMatrix.hkCode}</Text>
                        }>
                        {xMatrix.childList.map((subDivision) => {
                          return (
                            <Menu.Item key={subDivision.xmatrixID}>
                              <Text onClick={() => this.onXmatrixSelect(subDivision.xmatrixID, subDivision)}>
                                {subDivision.hkCode}
                              </Text>
                            </Menu.Item>
                          );
                        })}
                      </SubMenu>
                    );
                  })}
                </Menu>
              )}
            </Skeleton>
          )}
        </Drawer>
      </>
    );
  }
}

export default withTranslation()(XmatrixFilterPanel);

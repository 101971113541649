import { Layout } from 'antd';
import React, { Component } from 'react';
import './header.scss';
import MenuItems from './menu/menu';

const { Header } = Layout;

class AdminNavbar extends Component {
  render() {
    return (
      <Header className="navbar tw-px-4 tw-shrink-0 tw-overflow-hidden">
        <MenuItems authData={this.props.authData} />
      </Header>
    );
  }
}

export default AdminNavbar;

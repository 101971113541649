import { Form } from 'antd';
import React from 'react';

import { Card, Col, Input, Modal, Row } from 'antd';
import { ModalFooterButtons } from '../../../shared/components/modal/modalFooterButtons';
import { httpLinkRegex, httpsLinkRegex } from '../../../shared/utils/constants';
import { requiredFields } from '../../../shared/utils/notifyMessages';

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class NewDocumentationLinkModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
    };
  }

  componentDidMount() {}

  onSave = (values) => {
    this.props.handelInsertLink(values);
  };

  render() {
    const { showModal, handelModalClose, projectID, currentProject, t, loadingInsertLink, projectLinkData } =
      this.props;
    const {} = this.state;

    return (
      <Modal
        className="add-kpi-modal"
        width={800}
        title={t('proggetiPage.addProjectFileRepo')}
        open={showModal}
        destroyOnClose={true}
        maskClosable={false}
        onCancel={handelModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingInsertLink}
            modalClose={handelModalClose}
            formId={'newLink'}
          />,
        ]}>
        <Card>
          <Form
            id="newLink"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.onSave(values)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  className="main-container"
                  label={t('general.link')}
                  name="link"
                  rules={[
                    { required: true, message: requiredFields.required },
                    {
                      validator: async (_, value) => {
                        if (!httpLinkRegex.test(value)) {
                          return Promise.reject(new Error('Link not valid'));
                        }
                        if (!httpsLinkRegex.test(value)) {
                          return Promise.reject(new Error('Link not valid'));
                        }
                      },
                    },
                  ]}
                  extra="Link format: http://exmaple.com/"
                  initialValue={projectLinkData ? projectLinkData.link : null}>
                  <Input />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default NewDocumentationLinkModal;

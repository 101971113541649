import { trackPromise } from 'react-promise-tracker';
import { authenticatedApi, ProjectApi, ProjectDashboardApi } from 'src/config/connectors';
import i18n from 'src/i18n';

export const getParentProjects = async (projectId: string) => {
  const api = await authenticatedApi(ProjectApi);

  return api.projectGetProjectCreationPicklist(projectId);
};

export const getProjectSintesi = async (
  filters: {
    teamLider?: string | null;
    status?: number | null;
    division?: string | null;
    sponsor?: string | null;
    committie?: string | null;
    projectCode?: string | null;
    userID?: string | null;
  },
  xMatrixId: string,
  selectNotRelatedProjects: boolean,
) => {
  const api = await authenticatedApi(ProjectDashboardApi);

  return trackPromise(api.projectDashboardProjectSummary(
    xMatrixId,
    filters.teamLider,
    filters.status,
    filters.division,
    filters.sponsor,
    filters.committie,
    filters.projectCode,
    filters.userID,
    selectNotRelatedProjects,
    i18n.language,
  ));
};

import { Form } from 'antd';
import React from 'react';
import './divisions';

import { SaveOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Card, Col, Input, message, Row, Select, Space, Upload } from 'antd';
import { withTranslation } from 'react-i18next';
import DivisionService from '../../services/pages/divisionServices';
import UserSelectDropDown from '../shared/components/userSelect/userSelect';
import { notifyMessages, requiredFields } from '../shared/utils/notifyMessages';

const { Option } = Select;

const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class AddNewDivision extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      loadingButton: false,
      uploadedFile: null,
    };
  }

  beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      message.error(requiredFields.uploadCheckFileType);
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error(requiredFields.uploadCheckFileSize);
    }
    this.state.uploadedFile = file;
    return isJpgOrPng && isLt2M;
  }

  handleChange = (info) => {
    if (info.file.status === 'done') {
      message.success(`${info.file.name} file uploaded successfully`);
    } else if (info.file.status === 'error') {
      message.error(`${info.file.name} file upload failed.`);
    }
  };

  removeUploadedFile = () => {
    this.setState({ uploadedFile: null });
  };

  addData = (values) => {
    this.setState({ loadingButton: true });
    const formData = new FormData();
    formData.append('data', JSON.stringify(values));
    formData.append('uploadedFile', this.state.uploadedFile);
    DivisionService.addDivision(formData)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.addSuccess);
          this.setState({ loadingButton: false });
          this.props.history.push('/divisions');
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  render() {
    const { loadingButton } = this.state;
    let t = this.props.t;

    return (
      <div className="edit-division-wrapper">
        <div className="site-card-wrapper">{t('divisioniPage.aggiungiDivisione')}</div>
        <Card>
          <Form
            name="add_ob_lungo"
            layout="vertical"
            {...formItemLayout}
            onFinish={this.addData}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <div className="button-wrapper">
                  <Form.Item wrapperCol={{ span: 24 }}>
                    <Button
                      loading={loadingButton}
                      type="primary"
                      className="tw-float-end"
                      htmlType="submit"
                      disabled={!this.state.fieldsChanged}>
                      <SaveOutlined /> {t('buttons.salva')}
                    </Button>
                  </Form.Item>
                </div>
              </Col>
            </Row>

            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Card title={t('general.informazioniGenerali')}>
                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <Form.Item
                        label={t('general.nome')}
                        name="divisionName"
                        rules={[{ required: true, message: requiredFields.required }]}>
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}>
                      <UserSelectDropDown
                        disabled={false}
                        allowClear={true}
                        fieldLabel={t('general.responsabile')}
                        t={t}
                        formName={'ownerID'}
                        required={false}
                        initValue={null}
                      />
                      {/* <Form.Item
                                                    style={{ marginBottom: 5 }}
                                                    labelAlign="left"                                                    
                                                    label={t('general.responsabile')}
                                                    name="ownerID"   
                                                >
                                                   
                                                    <Select
                                                        showSearch
                                                        allowClear
                                                        placeholder={t('general.seleziona')}
                                                        optionFilterProp="children"
                                                        filterOption={(input, option) =>
                                                            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                                        }
                                                        style={{ width: "100%" }}
                                                    >
                                                        {responsabilePickList && responsabilePickList.map(item =>
                                                            <Option value={item.userID} key={item.userID}>{item.fullName}</Option>
                                                        )}
                                                    </Select>
                                                </Form.Item> */}
                    </Col>
                  </Row>

                  <Row gutter={{ lg: 24 }}>
                    <Col
                      className="gutter-row"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 24 }}
                      lg={{ span: 24 }}>
                      <Form.Item name={t('general.image')}>
                        <Space
                          direction="vertical"
                          style={{ width: '100%' }}>
                          <div className="ant-col ant-col-24 ant-form-item-label">
                            <label className="ant-form-item">{t('divisioniPage.immagineDivisione')}</label>
                          </div>

                          <Upload
                            accept="image/*"
                            maxCount={1}
                            listType="picture"
                            onChange={this.handleImageChange}
                            onRemove={() => this.removeUploadedFile()}
                            beforeUpload={(file) => {
                              this.setState({ uploadedFile: file });
                              return false;
                            }}>
                            <Button icon={<UploadOutlined />}>{t('general.upload')}</Button>
                          </Upload>
                        </Space>
                      </Form.Item>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
          </Form>
        </Card>
      </div>
    );
  }
}

export default withTranslation()(AddNewDivision);

import { Configuration } from 'src/connectors/backend';
import { BaseAPI } from 'src/connectors/backend/base';
import { acquireToken } from './auth/clientAuthProvider';
import deployment from './deploymentConfigs';

export * from 'src/connectors/backend';

interface ApiConstructor<T extends BaseAPI> {
  new (configuration?: Configuration): T;
}

export const api = <T extends BaseAPI>(ctor: ApiConstructor<T>) => {
  return new ctor(
    new Configuration({
      basePath: deployment.backend.href.replace(/\/$/, ''),
    }),
  );
};

export const authenticatedApi = async <T extends BaseAPI>(ctor: ApiConstructor<T>) => {
  const result = await acquireToken();

  return new ctor(
    new Configuration({
      basePath: deployment.backend.href.replace(/\/$/, ''),
      baseOptions: {
        headers: {
          Authorization: `Bearer ${result?.accessToken}`,
        },
      },
    }),
  );
};

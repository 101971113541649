import { trackPromise } from 'react-promise-tracker';
import { AnnualGoalApi, InsertAnnualGoalXRequestDto, UpdateAnnualGoalXRequestDto, XMatrixApi } from 'src/connectors/backend';
import { authenticatedApi } from 'src/config/connectors';

const ObAnnoService = {
  updateObiettivoAnnoxMartix: async function (payload: UpdateAnnualGoalXRequestDto) {    
    const api = await authenticatedApi(AnnualGoalApi);

    return trackPromise(api.annualGoalUpdateAnnualGoalXMatrix(payload));
  },

  addObiettivoAnnoxMartix: async function (payload: InsertAnnualGoalXRequestDto) {
    const api = await authenticatedApi(AnnualGoalApi);

    return trackPromise(api.annualGoalInsertAnnualGoalXMatrix(payload));
  },

  removeObAnno: async function (annualGoalId: string, xmatrixCode: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixDeleteAnnualGoalXMatrixRelation(annualGoalId, xmatrixCode));
  },
};

export default ObAnnoService;

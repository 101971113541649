import { trackPromise } from 'react-promise-tracker';
import { UpdateAnnualGoalKPIRelationShipRequestDto, UpdateGoalKPIRelationShipRequestDto, UpdateGoalProjectRelationShipDto, UpdateKPIProjectRelationShipRequestDto, UpdateLongTermAnnualGoalRelationShipDto, UpdateProjectSecondaryProjectRelationShipRequestDto, UpdateProjectUserRelationShipRequestDto, XMatrixApi } from 'src/connectors/backend';
import { authenticatedApi } from 'src/config/connectors';

const RelationsService = {
  // update long term goal and annual goal relation
  updateLongAnnualRelation: async function (payload: UpdateLongTermAnnualGoalRelationShipDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateLongTermAnnualGoalRelationShip(payload));
  },

  //update annual goal and projects relation
  updateAnnualProjectRelation: async function (payload: UpdateGoalProjectRelationShipDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateGoalProjectRelationShip(payload));
  },

  // update projects and kpi relation
  updateProjectKpiRelation: async function (payload: UpdateKPIProjectRelationShipRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateKPIProjectRelationShip(payload));
  },

  // update kpi and long terma goals relation
  updateLongGoalKpiRelation: async function (payload: UpdateGoalKPIRelationShipRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateGoalKPIRelationShip(payload));
  },

  // update projects and user relation
  updateProjectUserRelation: async function (payload: UpdateProjectUserRelationShipRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateProjectUserRelationShip(payload));
  },

  // delete projects and user relation
  deleteProjectUserRelation: async function (projectId: string, userId: string, relation: string, xmatrixId: string) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixDeleteUserRelationshipXMatrix(projectId, userId, relation, xmatrixId));
  },

  // update project child project relation
  updateProjectChildProjectRelation: async function (payload: UpdateProjectSecondaryProjectRelationShipRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateProjectSecondaryProjectRelationShip(payload));
  },

  // update annual goal and kpi relation
  updateAnnualGoalKpiRelation: async function (payload: UpdateAnnualGoalKPIRelationShipRequestDto) {
    const api = await authenticatedApi(XMatrixApi);

    return trackPromise(api.xMatrixUpdateAnnualGoalKPIRelationShip(payload));
  },
};

export default RelationsService;

import { Col, Row } from 'antd';
import React from 'react';
import Feed from '../../shared/feed/feed';
import { feedObjectType, feedRegardingObjectType } from '../../shared/utils/constants';

const AnnualGoalFeed = ({
  annualGoalData,
  annualGoalId,
  t,
  defaultCheckedFeedType,
  userData,
  commentFeedsOnly,
  fileFeedsOnly,
  othersFeedsOnly,
}) => {
  return (
    <Row gutter={{ lg: 24 }}>
      <Col
        className="gutter-row"
        xs={{ span: 24 }}
        sm={{ span: 24 }}
        md={{ span: 24 }}
        lg={{ span: 24 }}>
        <Feed
          commentFeedsOnly={commentFeedsOnly}
          fileFeedsOnly={fileFeedsOnly}
          defaultCheckedFeedType={defaultCheckedFeedType}
          othersFeedsOnly={othersFeedsOnly}
          objectId={annualGoalId}
          userData={userData}
          objectType={feedObjectType.obAnnoFeed}
          isProject={0}
          feedRegardingObjType={feedRegardingObjectType.annualGoal}
        />
      </Col>
    </Row>
  );
};
export default AnnualGoalFeed;

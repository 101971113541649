import React from 'react';

const CustomPageHeader = ({ title, subtitle, onBack, actions }) => {
  return (
    <div style={{ padding: '16px', backgroundColor: '#f5f5f5', borderBottom: '1px solid #d9d9d9' }}>
      {/* {onBack && (
        <button
          onClick={onBack}
          style={{ marginRight: '16px' }}>
          Indietro
        </button>
      )} */}
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
        <h1 style={{ margin: '0 0 8px 0' }}>{title}</h1>
        {subtitle && <small style={{ color: '#666' }}>{subtitle}</small>}
      </div>
      {actions && <div style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center' }}>{actions}</div>}
    </div>
  );
};

export default CustomPageHeader;

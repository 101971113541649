import { Card, Form } from 'antd';
import React, { Component } from 'react';

import { Col, Divider, Input, Modal, Row, Select, Transfer } from 'antd';
import { connect } from 'react-redux';
import MatrixService from '../../services/matrix/matrixService';
import Loader2 from '../shared/components/loader2/loader2';
import { ModalFooterButtons } from '../shared/components/modal/modalFooterButtons';
import { requiredFields } from '../shared/utils/notifyMessages';
import './matrix.scss';

const { Option } = Select;
const formItemLayout = {
  labelCol: {
    span: 24,
  },
  wrapperCol: {
    span: 24,
  },
};

class EditXmatrixModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
      loadingButton: false,
      strategicCycleList: null,
      loadingXmatrixData: false,
      xmatrixDataObject: null,

      longTermGoalList: [],
      longTermGoalTargetKeys: [],

      annualGoalList: [],
      annualGoalTargetKeys: [],

      projectGoalList: [],
      projectTargetKeys: [],
    };
  }

  componentDidMount() {
    this.retrieveXmatrixCycleList();
    this.retriveXmatrixData();
  }

  async retrieveXmatrixCycleList() {
    const { userData } = this.props;
    await MatrixService.getXmatrixCycleList(userData.lastLoggedInCompanyID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ strategicCycleList: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  }

  async retriveXmatrixData() {
    const { selectedXmatrixValue } = this.props;
    this.setState({ loadingXmatrixData: true });
    await MatrixService.getXmatrixOnEditData(selectedXmatrixValue)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ xmatrixDataObject: resp.responseObject.value });

          this.manageProjectList(resp.responseObject.value);
          this.manageAnnualGoalList(resp.responseObject.value);
          this.manageLongTermGoal(resp.responseObject.value);

          this.setState({ loadingXmatrixData: false });
        } else {
          this.setState({ loadingXmatrixData: false });
        }
      })
      .catch((error) => {
        this.setState({ loadingXmatrixData: false });
      });
  }

  manageProjectList = (data) => {
    const fullProjectsList = [];
    const projectData = data.projectData;
    const mergeAllProjects = projectData.projectRelated.concat(projectData.projectNotRelated);

    mergeAllProjects.map((item, index) => {
      const project = { key: item.projectID, title: `${item.projectName}` + ` (${item.projectCode})` };
      fullProjectsList.push(project);
    });

    let projectsRelated = projectData.projectRelated.map((a) => a.projectID);

    this.setState({ projectGoalList: fullProjectsList });
    this.setState({ projectTargetKeys: projectsRelated });
  };

  manageAnnualGoalList = (data) => {
    const fullAnualGoalList = [];
    const annualObjData = data.annualObjData;
    const mergeAllAnnualGoals = annualObjData.annualGoalRelated.concat(annualObjData.annualGoalNotRelated);

    mergeAllAnnualGoals.map((item, index) => {
      const annualGoal = { key: item.annualGoalID, title: item.annualGoalName };
      fullAnualGoalList.push(annualGoal);
    });

    let annualGoalRelated = annualObjData.annualGoalRelated.map((a) => a.annualGoalID);
    this.setState({ annualGoalList: fullAnualGoalList });
    this.setState({ annualGoalTargetKeys: annualGoalRelated });
  };

  manageLongTermGoal = (data) => {
    const fullLongTermList = [];
    const longTermGoalData = data.longTermGoalData;
    const mergeAllLongTermGoals = longTermGoalData.longTermGoalRelated.concat(longTermGoalData.longTermGoalNotRelated);
    mergeAllLongTermGoals.map((item, index) => {
      const longTermGoal = { key: item.longTermGoalID, title: item.longTermGoalName };
      fullLongTermList.push(longTermGoal);
    });
    let longTermGoalRelated = longTermGoalData.longTermGoalRelated.map((a) => a.longTermGoalID);
    this.setState({ longTermGoalList: fullLongTermList });
    this.setState({ longTermGoalTargetKeys: longTermGoalRelated });
  };

  handleChangeLongTermGoal = (targetKeys) => {
    this.setState({ longTermGoalTargetKeys: targetKeys });
    this.setState({ fieldsChanged: true });
  };

  handleChangeAnnualGoal = (targetKeys) => {
    this.setState({ annualGoalTargetKeys: targetKeys });
    this.setState({ fieldsChanged: true });
  };

  handleChangeProject = (targetKeys) => {
    this.setState({ projectTargetKeys: targetKeys });
    this.setState({ fieldsChanged: true });
  };

  saveData = (values, xmatrixId) => {
    const { longTermGoalTargetKeys, projectTargetKeys, annualGoalTargetKeys } = this.state;
    values['projectIDList'] = projectTargetKeys;
    values['longTermGoalIDList'] = longTermGoalTargetKeys;
    values['annualGoalIDList'] = annualGoalTargetKeys;
    values['xMatrixID'] = xmatrixId;
    this.props.handleEditModalXmatrixSave(values);
  };

  render() {
    const { showEditModalXmatrix, handleEditModalXmatrixClose, loadingButton, t, selectedXmatrixValue } = this.props;
    const {
      strategicCycleList,
      longTermGoalList,
      longTermGoalTargetKeys,
      projectGoalList,
      projectTargetKeys,
      annualGoalList,
      annualGoalTargetKeys,
      xmatrixDataObject,
    } = this.state;

    return (
      <Modal
        className="edit_xmatrix_modal"
        width={1300}
        title={t('xMatrixPage.modificaXmatrix')}
        open={showEditModalXmatrix}
        destroyOnClose={true}
        onCancel={handleEditModalXmatrixClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingButton}
            modalClose={handleEditModalXmatrixClose}
            formId={'editXmatrix'}
          />,
        ]}>
        <Form
          id="editXmatrix"
          name="edit_xmatrix"
          layout="vertical"
          {...formItemLayout}
          onFinish={(values) => this.saveData(values, selectedXmatrixValue)}
          onValuesChange={() => {
            this.setState({ fieldsChanged: true });
          }}>
          {!xmatrixDataObject && <Loader2 />}
          {xmatrixDataObject && (
            <Row gutter={{ lg: 24 }}>
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  label={t('general.nome')}
                  name="xMatrixName"
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={xmatrixDataObject.xMatrixName}>
                  <Input />
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Form.Item
                  style={{ marginBottom: 5 }}
                  labelAlign="left"
                  label={t('xMatrixPage.xmatrixStrategy')}
                  name="xMatrixCycleID"
                  hasFeedback
                  rules={[{ required: true, message: requiredFields.required }]}
                  initialValue={xmatrixDataObject.xMatrixCycleID}>
                  <Select
                    style={{ width: '100%' }}
                    placeholder={t('general.seleziona')}>
                    {strategicCycleList &&
                      strategicCycleList.map((item) => (
                        <Option
                          value={item.xMatrixCycleID}
                          key={item.xMatrixCycleID}>
                          {item.cycleName}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Divider plain>{t('general.obiettiviLungo')}</Divider>
                <Transfer
                  dataSource={longTermGoalList}
                  titles={[`${t('xMatrixPage.notRelatedLongtermGoal')}`, `${t('xMatrixPage.relatedLongtermGoal')}`]}
                  showSearch
                  listStyle={{
                    width: '48.5%',
                    height: 300,
                  }}
                  targetKeys={longTermGoalTargetKeys}
                  onChange={this.handleChangeLongTermGoal}
                  render={(item) => item.title}
                  locale={{
                    itemUnit: `${t('general.obiettiviLungo')}`,
                    itemsUnit: `${t('general.obiettiviLungo')}`,
                    notFoundContent: `${t('general.elencoVuoto')}`,
                    searchPlaceholder: `${t('buttons.cerca')}`,
                  }}
                />
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Divider plain>{t('general.obbietiviAnuali')}</Divider>
                <Transfer
                  dataSource={annualGoalList}
                  titles={[`${t('xMatrixPage.notRelatedAnualGoal')}`, `${t('xMatrixPage.relatedAnualGoal')}`]}
                  showSearch
                  listStyle={{
                    width: '48.5%',
                    height: 300,
                  }}
                  targetKeys={annualGoalTargetKeys}
                  onChange={this.handleChangeAnnualGoal}
                  render={(item) => item.title}
                  locale={{
                    itemUnit: `${t('general.obbietiviAnuali')}`,
                    itemsUnit: `${t('general.obbietiviAnuali')}`,
                    notFoundContent: `${t('general.elencoVuoto')}`,
                    searchPlaceholder: `${t('buttons.cerca')}`,
                  }}
                />
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Divider plain>{t('general.progetti')}</Divider>
                <Transfer
                  dataSource={projectGoalList}
                  titles={[`${t('xMatrixPage.notRelatedProgetti')}`, `${t('xMatrixPage.relatedProgetti')}`]}
                  showSearch
                  listStyle={{
                    width: '48.5%',
                    height: 300,
                  }}
                  targetKeys={projectTargetKeys}
                  onChange={this.handleChangeProject}
                  render={(item) => item.title}
                  locale={{
                    itemUnit: `${t('general.progetti')}`,
                    itemsUnit: `${t('general.progetti')}`,
                    notFoundContent: `${t('general.elencoVuoto')}`,
                    searchPlaceholder: `${t('buttons.cerca')}`,
                  }}
                />
              </Col>
            </Row>
          )}
        </Form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default connect(mapStateToProps)(EditXmatrixModal);

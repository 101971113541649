import {
  BookOutlined,
  BorderOuterOutlined,
  CheckOutlined,
  CommentOutlined,
  DesktopOutlined,
  EuroOutlined,
  ExclamationOutlined,
  FileOutlined,
  FullscreenExitOutlined,
  NotificationOutlined,
  OrderedListOutlined,
} from '@ant-design/icons';
import { Button, Menu, message, Tooltip } from 'antd';
import SubMenu from 'antd/es/menu/SubMenu';
import moment from 'moment';
import { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { history } from '../../../App';
import {
  AlarmIcon,
  CheckboxMultipleIcon,
  FileTextIcon,
  FocusLineIcon,
  LinksIcon,
  QuestionLineIcon,
  ScalesIcon,
  SettingsIcon,
  SpeedometerIcon,
  SpeedometerLineIcon,
  StackShareIcon,
  TeamIcon,
  TodoIcon,
} from '../../../assets/icons';
import CommonService from '../../../services/commonService';
import ProgettiService from '../../../services/pages/projectServices/progettiService';
import UsersService from '../../../services/pages/usersService';
import { addFragment, addQueryParam, getQueryParam, hasQueryParam, removeFragment } from '../../../utils/url-utils';
import A3Index from '../../a3/a3';
import Section2 from '../../a3/section2/section2';
import {
  hasUserProjectCostAccess,
  userHasAccessProjectFields,
} from '../../shared/utils/authRolesProvilege/authRolesPrivilege';
import {
  feedObjectType,
  feedRegardingObjectType,
  objectTypeUserSecurity,
  projectStatus,
} from '../../shared/utils/constants';
import { NoAccessMesssage } from '../../shared/utils/functions';
import { notifyMessages } from '../../shared/utils/notifyMessages';
import '../project.scss';
import ProjectDataHeader from '../projectDataHeader';
import ProjectGoalEditor from '../projectGoalEditor/goalEditor.jsx';
import ReasonToAct from '../projectReasonToAct/reasonToAct';
import ChildProjects from './childProjects';
import ProjectDeliverable from './deliverablesAndKpi/projectDeliverable';
import ProjectKpi from './deliverablesAndKpi/projectKpi';
import SettingsAndTeam from './mainInfo/settingsAndTeam';
import ProjectTeamMembers from './mainInfo/teamMembers/teamMembers';
import ProjectActivities from './projectActivities/projectActivities';
import ProjectCosts from './projectCosts/projectCost';
import ProjectFeed from './projectFeed/projectFeed';
import ProjectFiles from './projectFiles/projectFiles';
import ProjectObjectivesIndex from './projectObjectives/index';
import ProjectResourceWorkload from './projectResourceWorload/projectResourceWorkload';
import RiskAndPrecautions from './riskAndPrecaution/riskAndPrecaution';

import AttivitaSvolte from '../../a3/section3/attivitaSvolte';

import PresentationBase from '../../a3/presentationMode/presentationBase';

import Risks from '../../a3/section4/risks';
import KpiDashboard from '../../kpiDashboard/kpiDashboard';
import ProjectMeetings from './projectMeetings/projectMeetings.jsx';

import FontSizeChanger from 'react-font-size-changer';

class ProjectIndex extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentProject: null,
      activeTab: 10.1,
      checkUserAccessOnObject: null,
      projectCostUserAccessSP: null,
      projectCostUserAccess: null,
      projectTeamMembers: [],
      projectTeamMembersList: [],
      projectTeamMembersListExtended: [],
      isTeamDataLoading: true,
      isPresentationMode: false,
      fullScreenMode: false,
      dynamicFontClass: 'dynaFontSize20',
      dynamicFontSize: 20,
    };
  }

  componentDidMount() {
    const { id, cmpid } = this.props.match.params;

    if (hasQueryParam('presentationMode')) {
      this?.setState({ isPresentationMode: !this.state.isPresentationMode });
      this?.setState({ activeTab: '15' });
      addQueryParam({ activeTab: '15' });
    }

    if (hasQueryParam('tab')) {
      this.setState({ activeTab: getQueryParam('tab') || '10.1' });
    } else {
      // addQueryParam('tab', 10.1);
      this.setState({ activeTab: '10.1' });
    }

    const sectionFragment = this.props.location.hash.slice(1);
    if (sectionFragment) {
      setTimeout(() => {
        const section = document.getElementById(sectionFragment);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', inline: 'nearest' });
        }
      }, 1000);
    }

    this.retrieveComponentData(id);
    this.checkIfUserHasAccess(id);

    if (cmpid && cmpid.length > 2) {
      this.updateUserCompnay(id, cmpid);
    }
    if (cmpid && cmpid.length <= 2) {
      this.setState({ activeTab: cmpid });
    }
  }

  retrieveComponentData = async (id) => {
    this.getUserProjectCostAccess(id);
    this.getProjectData(id);
    this.getTeamMembers(id);
    this.getTeamMembersExtended(id);
  };

  getTeamMembers(id) {
    ProgettiService.getProjectTeamMembers(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          let respData = resp.responseObject.value;
          let usr = [];
          this.setState({ projectTeamMembers: respData.map((a) => a.userID) });

          respData.map((item) => {
            usr.push({ value: item.userID, label: item.fullName, key: item.userID, disabled: item.isDeleted });
          });
          this.setState({ projectTeamMembersList: usr });
        } else {
        }
      })
      .catch((error) => {});
  }

  getTeamMembersExtended(id) {
    this.setState({ isTeamDataLoading: true });
    ProgettiService.getProjectTeamMembers(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ projectTeamMembersListExtended: resp.responseObject.value });
          this.setState({ isTeamDataLoading: false });
        } else {
          this.setState({ isTeamDataLoading: false });
        }
      })
      .catch((error) => {
        this.setState({ isTeamDataLoading: false });
      });
  }

  getUserProjectCostAccess(projectId) {
    ProgettiService.getProjectCostUserAccess(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (resp.responseObject.value.hasAccess == 1) {
            this.setState({ projectCostUserAccessSP: true });
          } else {
            this.setState({ projectCostUserAccessSP: false });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  getProjectData = (id) => {
    ProgettiService.getProgettiByiD(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentProject: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  };

  updateUserCompnay = (id, companyId) => {
    const { userData } = this.props;
    let payload = {};
    payload['userID'] = userData.userId;
    payload['companyID'] = companyId;

    if (userData !== undefined && userData.hasOwnProperty('userId')) {
      let lastLogInCompany = userData.userCompanies.filter((item) => {
        return item.isLastLoggedInCompany === 1;
      });

      if (lastLogInCompany[0].lastLoggedInCompany !== companyId.toLowerCase()) {
        UsersService.updateUserLogedInCompany(payload)
          .then((response) => response.data)
          .then((resp) => {
            if (resp.success) {
              window.location.replace(`/progetti/id/${id}/${companyId}`);
              this.getProjectData(id);
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  };

  async checkIfUserHasAccess(objectId) {
    let obj = {};
    obj['type'] = objectTypeUserSecurity.project;
    obj['objectID'] = objectId;
    await CommonService.chaeckUserAccessOnObject(obj)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          if (resp.responseObject.value.hasPrivilege === 1) this.setState({ checkUserAccessOnObject: true });
          else {
            this.setState({ checkUserAccessOnObject: false });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  handleMenuClick = (e) => {
    const { key } = e;

    addQueryParam('tab', key);
    this.setState({ activeTab: key }, () => {
      if (key.includes('.')) {
        addFragment(key);

        setTimeout(() => {
          const section = document.getElementById(key);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
          }
        }, 500);
      } else {
        removeFragment();
      }
    });
  };

  handlePresentationMode = () => {
    addQueryParam('presentationMode', !this.state.isPresentationMode ?? true);

    this?.setState({ isPresentationMode: !this.state.isPresentationMode });
    this?.setState({ activeTab: '15' });
  };

  handleFullScreenMode = () => {
    addQueryParam('fullScreenMode', !this.state.fullScreenMode ?? true);

    this?.setState({ fullScreenMode: !this.state.fullScreenMode });
  };

  checkProjectEndDateValidity = (endDate) => {
    if (endDate) {
      let currentDate = moment().endOf('day');
      let projectEndDate = moment(endDate).endOf('day');

      if (projectEndDate < currentDate) {
        return true;
      } else {
        return false;
      }
    }
  };

  render() {
    const {
      currentProject,
      checkUserAccessOnObject,
      projectCostUserAccessSP,
      projectTeamMembers,
      projectTeamMembersList,
      projectTeamMembersListExtended,
      isTeamDataLoading,
      dynamicFontClass,
    } = this.state;
    const { id } = this.props.match.params;
    const { userData } = this.props;
    let t = this.props.t;
    const { activeTab } = this.state;

    let hasUserAccess =
      currentProject && userData
        ? !userHasAccessProjectFields(userData, currentProject.teamLeaderID, currentProject.sponsorID) &&
          currentProject.status === projectStatus.completato
          ? true
          : false
        : null;

    let projectCostUserAccess =
      currentProject && userData
        ? hasUserProjectCostAccess(
            userData,
            currentProject.teamLeaderID,
            currentProject.sponsorID,
            projectCostUserAccessSP,
            projectTeamMembers,
          )
        : null;

    const renderComponent = () => {
      switch (activeTab) {
        case '2':
          return (
            <ProjectDeliverable
              userData={userData}
              t={t}
              hasUserAccess={hasUserAccess}
              currentProject={currentProject}
              projectId={currentProject.projectID}
              projectName={currentProject.name}
              history={history}
            />
          );

        case '3':
          return (
            <RiskAndPrecautions
              t={t}
              projectId={currentProject.projectID}
              hasUserAccess={hasUserAccess}
            />
          );

        case '4':
          return (
            <ProjectActivities
              userData={userData}
              t={t}
              projectId={currentProject.projectID}
              projectName={currentProject.name}
              currentProject={currentProject}
              hasUserAccess={hasUserAccess}
            />
          );

        case '5':
          return (
            <ProjectResourceWorkload
              userData={userData}
              t={t}
              hasUserAccess={hasUserAccess}
              projectId={currentProject.projectID}
              projectName={currentProject.name}
              reloadProject={() => this.getProjectData(currentProject.projectID)}
            />
          );

        case '6':
          return (
            <ChildProjects
              t={t}
              projectId={currentProject.projectID}
              projectName={currentProject.name}
            />
          );

        case '7':
          return (
            <ProjectCosts
              t={t}
              projectCostUserAccessProp={projectCostUserAccess}
              hasUserAccess={hasUserAccess}
              projectId={currentProject.projectID}
              projectName={currentProject.name}
              currentProject={currentProject}
              reloadProject={() => this.getProjectData(id)}
            />
          );

        case '8':
          return (
            <ProjectKpi
              isPresentationMode={this.state.isPresentationMode}
              projectTeamMembers={projectTeamMembersList}
              t={t}
              hasUserAccess={hasUserAccess}
              currentProject={currentProject}
              projectId={currentProject.projectID}
              projectName={currentProject.name}
              history={history}
            />
          );

        case '10':
        case '10.1':
        case '10.2':
        case '10.3':
        case '10.4':
          return <A3Index id={currentProject.projectID} />;

        case '11':
          return (
            <ProjectFeed
              objectId={currentProject.projectID}
              userData={userData}
              isProject={1}
              hasUserAccess={hasUserAccess}
              objectType={feedObjectType.projectFeed}
              feedRegardingObjType={feedRegardingObjectType.project}
              showFeed={true}
            />
          );

        case '12':
          return (
            <ProjectFiles
              userData={userData}
              defaultCheckedFeedType={3}
              feedRegardingObjType={feedRegardingObjectType.project}
              objectId={currentProject.projectID}
              hasUserAccess={hasUserAccess}
              t={t}
              projectId={currentProject.projectID}
              currentProject={currentProject}
            />
          );

        case '13':
          return (
            <SettingsAndTeam
              t={t}
              projectId={currentProject.projectID}
              currentProject={currentProject}
              reloadProject={() => this.getProjectData(currentProject.projectID)}
              hasUserAccess={hasUserAccess}
            />
          );

        case '14':
          return (
            <ProjectObjectivesIndex
              t={t}
              projectId={currentProject.projectID}
              currentProject={currentProject}
              hasUserAccess={hasUserAccess}
            />
          );

        case '15':
          return (
            <ReasonToAct
              key={'groundToAct'}
              currentProject={this.state.currentProject}
              isPresentationMode={true}
            />
          );

        case '16':
          return (
            <ProjectGoalEditor
              isEditable={!hasUserAccess}
              isPresentationMode={true}
              key={'projectGoal'}
              currentProject={this.state.currentProject}
            />
          );

        case '17':
          return (
            <ProjectTeamMembers
              t={t}
              projectId={currentProject.projectID}
              teamMembers={projectTeamMembersListExtended}
              reloadUsers={() => this.getTeamMembersExtended(currentProject.projectID)}
              hasUserAccess={hasUserAccess}
              isDataLoading={isTeamDataLoading}
              isAdminOrProjectTeam={true}
              isPresentationMode={true}
              checkProjectEndDateValidity={this.checkProjectEndDateValidity(currentProject.endDate)}
            />
          );

        case '18':
          return (
            <Section2
              className="presTable"
              currentProject={this.state.currentProject}
              t={t}
              id={currentProject.projectID}
              hasUserAccess={hasUserAccess}
              isPresentationMode={true}
              dynaFontClass={this.state.dynamicFontClass}
              userData={userData}
              showGantt={true}
              showPrs={false}
            />
          );

        case '19':
          return (
            <ProjectDeliverable
              isPresentationMode={true}
              userData={userData}
              t={t}
              hasUserAccess={hasUserAccess}
              currentProject={currentProject}
              projectId={currentProject.projectID}
              projectName={currentProject.name}
              history={history}
            />
          );

        case '20':
          return (
            <AttivitaSvolte
              t={t}
              projectId={currentProject.projectID}
              isPresentationMode={true}
              companyData={this.props.companyData}
              dynaFontClass={this.state.dynamicFontClass}
            />
          );

        case '21':
          return (
            <KpiDashboard
              isPresentationMode={true}
              presentationProjectId={currentProject.projectID}
            />
          );

        case '22':
          return <PresentationBase takeAsBlackBox="tab8" />;

        case '23':
          return (
            <Risks
              className="sub700-pres-card-body"
              t={t}
              projectId={currentProject.projectID}
              isPresentationMode={true}
              dynaFontClass={this.state.dynamicFontClass}
            />
          );

        case '24':
          return <PresentationBase takeAsBlackBox="tab10" />;

        case '25':
          return (
            <ProjectFiles
              isPresentationMode={true}
              t={t}
              projectId={currentProject.projectID}
              currentProject={currentProject}
            />
          );

        case '26':
          return (
            <ProjectFeed
              isPresentationMode={true}
              dynaFontClass={this.state.dynamicFontClass}
              objectId={currentProject.projectID}
              userData={userData}
              isProject={1}
              hasUserAccess={hasUserAccess}
              objectType={feedObjectType.projectFeed}
              feedRegardingObjType={feedRegardingObjectType.project}
              showFeed={false}
            />
          );

        case '27':
          return (
            <ProjectMeetings
              objectId={currentProject.projectID}
              userData={userData}
              isProject={1}
              hasUserAccess={hasUserAccess}
              objectType={feedObjectType.projectFeed}
              feedRegardingObjType={feedRegardingObjectType.project}
              showFeed={true}
            />
          );

        default:
          return null;
      }
    };

    return (
      <div>
        {currentProject && (
          <>
            {!checkUserAccessOnObject && <NoAccessMesssage />}

            {checkUserAccessOnObject && (
              <div className="tw-flex tw-flex-col tw-overflow-hidden tw-absolute tw-inset-0 tw-size-full">
                <ProjectDataHeader
                  t={t}
                  id={id}
                  presentationModeTemplate={
                    <>
                      <div>
                        {!this.state.isPresentationMode && (
                          <Button
                            onClick={this.handlePresentationMode}
                            icon={<DesktopOutlined className="editIcon printIcon" />}
                            type="primary">
                            {t('a3Page.presenta')}
                          </Button>
                        )}

                        {this.state.isPresentationMode && (
                          <div className="tw-flex tw-items-center tw-gap-2">
                            <Tooltip
                              placement="bottom"
                              title={t('a3Page.fullscreenPage')}>
                              <Button
                                type="dashed"
                                onClick={this.handleFullScreenMode}
                                icon={<BorderOuterOutlined />}></Button>
                            </Tooltip>

                            <FontSizeChanger
                              targets={['.presContent']}
                              onChange={(element, newValue, oldValue) => {
                                this.setState({ dynamicFontSize: newValue });
                                this.setState({ dynamicFontClass: 'dynaFontSize' + newValue.toString() });
                              }}
                              options={{
                                stepSize: 2,
                                range: 8,
                              }}
                              customButtons={{
                                up: (
                                  <Button
                                    disabled={this.state.dynamicFontSize < 36 ? false : true}
                                    shape="circle">
                                    A+
                                  </Button>
                                ),
                                down: (
                                  <Button
                                    disabled={this.state.dynamicFontSize > 20 ? false : true}
                                    shape="circle">
                                    A-
                                  </Button>
                                ),
                                style: {
                                  border: 'none',
                                  WebkitBoxSizing: 'none',
                                },
                              }}
                            />

                            <Tooltip
                              placement="bottom"
                              title={t('a3Page.chiuderePresentazioneA3')}>
                              <Button
                                type="primary"
                                icon={<FullscreenExitOutlined />}
                                onClick={() => {
                                  addQueryParam('presentationMode', false);

                                  this?.setState({ isPresentationMode: false });
                                  this?.setState({ activeTab: '10.1' });
                                }}>
                                Esci
                              </Button>
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    </>
                  }
                  isPresentationMode={this.state.isPresentationMode}
                  currentProjectData={currentProject}
                  location={'project'}
                />

                <div className="tw-flex tw-size-full tw-overflow-y-hidden">
                  <Menu
                    mode="inline"
                    className="tw-min-h-full tw-overflow-y-auto tw-p-2 tw-shrink-0"
                    onClick={this.handleMenuClick}
                    defaultOpenKeys={['10']}
                    defaultActiveFirst
                    style={{ width: 250 }}
                    defaultSelectedKeys={[this.state.activeTab]}
                    selectedKeys={[activeTab]}>
                    {!this.state.isPresentationMode && (
                      <SubMenu
                        key="10"
                        title={
                          <div className="tw-flex tw-flex-row tw-items-center tw-gap-2">
                            <SpeedometerIcon />
                            {t('proggetiPage.a3')}
                          </div>
                        }>
                        <Menu.Item key="10.1">{t('proggetiPage.obiettivo')}</Menu.Item>
                        <Menu.Item key="10.2">{t('proggetiPage.masterplan')}</Menu.Item>
                        <Menu.Item key="10.3">{t('proggetiPage.implementazione')}</Menu.Item>
                        <Menu.Item key="10.4">{t('proggetiPage.review')}</Menu.Item>
                      </SubMenu>
                    )}
                    {this.state.isPresentationMode && (
                      <>
                        <Menu.Item
                          key="15"
                          className="with-icon-title">
                          <QuestionLineIcon />
                          {t('proggetiPage.motivoAgire')}
                        </Menu.Item>

                        <Menu.Item
                          key="16"
                          className="with-icon-title">
                          <FocusLineIcon />
                          {t('proggetiPage.expectedResult')}
                        </Menu.Item>

                        <Menu.Item
                          key="17"
                          className="with-icon-title">
                          <TeamIcon />
                          {t('proggetiPage.team')}
                        </Menu.Item>

                        <Menu.Item
                          key="8"
                          className="with-icon-title">
                          <SpeedometerLineIcon /> {t('general.kpi')}
                        </Menu.Item>

                        <Menu.Item
                          key="18"
                          className="with-icon-title">
                          <TodoIcon />
                          {t('proggetiPage.masterplan')}
                        </Menu.Item>

                        <Menu.Item
                          key="19"
                          className="with-icon-title">
                          <CheckboxMultipleIcon /> {t('proggetiPage.projectReviewSystem')}
                        </Menu.Item>

                        <Menu.Item
                          key="20"
                          className="with-icon-title">
                          <CheckOutlined /> {t('proggetiPage.attivitaSvolte')}
                        </Menu.Item>

                        <Menu.Item
                          key="21"
                          className="with-icon-title">
                          <SpeedometerIcon /> {t('proggetiPage.kpiStatus')}
                        </Menu.Item>

                        <Menu.Item
                          key="22"
                          className="with-icon-title">
                          <OrderedListOutlined /> {t('proggetiPage.prossimiPassi')}
                        </Menu.Item>

                        <Menu.Item
                          key="23"
                          className="with-icon-title">
                          <ExclamationOutlined /> {t('proggetiPage.criticitaRischi')}
                        </Menu.Item>

                        <Menu.Item
                          key="24"
                          className="with-icon-title">
                          <BookOutlined /> {t('proggetiPage.hensei')}
                        </Menu.Item>

                        <Menu.Item
                          key="25"
                          className="with-icon-title">
                          <FileOutlined /> {t('proggetiPage.file')}
                        </Menu.Item>

                        <Menu.Item
                          key="26"
                          className="with-icon-title">
                          <CommentOutlined /> {t('general.commenti')}
                        </Menu.Item>
                      </>
                    )}

                    {!this.state.isPresentationMode && (
                      <>
                        <Menu.Item
                          key="14"
                          className="with-icon-title">
                          <LinksIcon />
                          {t('proggetiPage.obiettiviCollegati')}
                        </Menu.Item>

                        <Menu.Item
                          key="2"
                          className="with-icon-title">
                          <CheckboxMultipleIcon /> {t('proggetiPage.projectReviewSystem')}
                        </Menu.Item>

                        <Menu.Item
                          key="8"
                          className="with-icon-title">
                          <SpeedometerLineIcon /> {t('general.kpi')}
                        </Menu.Item>

                        <Menu.Item
                          key="3"
                          className="with-icon-title">
                          <AlarmIcon />
                          {t('proggetiPage.criticitaRischi')}
                        </Menu.Item>
                        <Menu.Item
                          key="4"
                          className="with-icon-title">
                          <TodoIcon /> {t('proggetiPage.toDoProject')}
                        </Menu.Item>
                        <Menu.Item
                          key="5"
                          className="with-icon-title">
                          <ScalesIcon /> {t('proggetiPage.caricoRisorse')}
                        </Menu.Item>
                        <Menu.Item
                          key="7"
                          className="with-icon-title">
                          <EuroOutlined />
                          {t('proggetiPage.costiProgetto')}
                        </Menu.Item>
                        <Menu.Item
                          key="6"
                          className="with-icon-title">
                          <StackShareIcon /> {t('proggetiPage.progetttoFigli')}
                        </Menu.Item>
                        <Menu.Item
                          key="11"
                          className="with-icon-title">
                          <FileTextIcon /> {t('feed.feed')}
                        </Menu.Item>
                        <Menu.Item
                          key="27"
                          className="with-icon-title">
                          <NotificationOutlined /> {t('proggetiPage.notificeRiunioni')}
                        </Menu.Item>
                        <Menu.Item
                          key="12"
                          className="with-icon-title">
                          <FileOutlined />
                          {t('proggetiPage.file')}
                        </Menu.Item>
                        <Menu.Item
                          key="13"
                          className="with-icon-title">
                          <SettingsIcon className="tw-fill-black tw-shrink-0" /> {t('general.settings')}
                        </Menu.Item>
                      </>
                    )}
                  </Menu>
                  <div
                    className={
                      'tw-size-full tw-p-8 tw-overflow-auto tw-h-full ' +
                      (this.state.isPresentationMode &&
                        !!this.state.dynamicFontClass &&
                        'presContent ' + this.state.dynamicFontClass)
                    }>
                    {renderComponent()}
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
  companyData: state.companyData.companyData,
});

export default withTranslation()(connect(mapStateToProps)(withRouter(ProjectIndex)));

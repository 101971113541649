import { userProjectCostAccessLevel, userRolesCodes } from '../constants';

export const userIsAdminOrSteering = (userData) => {
  if (
    userData.roleCode === userRolesCodes.lenovysAdmin ||
    userData.roleCode === userRolesCodes.techAdmin ||
    userData.roleCode === userRolesCodes.companyAdmin ||
    userData.roleCode === userRolesCodes.steering
  ) {
    return true;
  } else {
    return false;
  }
};

export const isUserAdmin = (userData) => {
  if (
    userData.roleCode === userRolesCodes.lenovysAdmin ||
    userData.roleCode === userRolesCodes.techAdmin ||
    userData.roleCode === userRolesCodes.companyAdmin
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkUserIsAdminUser = (userData) => {
  if (
    userData.roleCode === userRolesCodes.lenovysAdmin ||
    userData.roleCode === userRolesCodes.techAdmin ||
    userData.roleCode === userRolesCodes.companyAdmin
  ) {
    return false;
  } else {
    return true;
  }
};

export const checkUserIsLenovysOrCompanyAdmin = (userData) => {
  if (userData.roleCode === userRolesCodes.lenovysAdmin || userData.roleCode === userRolesCodes.companyAdmin) {
    return true;
  } else {
    return false;
  }
};

export const isUserAdminUser = (userData) => {
  if (
    userData.roleCode === userRolesCodes.lenovysAdmin ||
    userData.roleCode === userRolesCodes.techAdmin ||
    userData.roleCode === userRolesCodes.companyAdmin
  ) {
    return true;
  } else {
    return false;
  }
};

export const checkUserIsLenovysOrTechAdmin = (userData) => {
  if (userData.roleCode === userRolesCodes.lenovysAdmin || userData.roleCode === userRolesCodes.techAdmin) {
    return false;
  } else {
    return true;
  }
};

export const checkUserIsLenovysAdmin = (userData) => {
  if (userData.roleCode === userRolesCodes.lenovysAdmin) {
    return true;
  } else {
    return false;
  }
};

export const checkUserIsNotLenovysAdmin = (userData) => {
  if (userData.roleCode === userRolesCodes.lenovysAdmin) {
    return false;
  } else {
    return true;
  }
};

export const checkUserIsLenovysTechAdmin = (userData) => {
  if (userData.roleCode === userRolesCodes.lenovysAdmin || userData.roleCode === userRolesCodes.techAdmin) {
    return true;
  } else {
    return false;
  }
};

export const checkUserIsNormalUser = (userData) => {
  if (userData.roleCode === userRolesCodes.lenovysAdmin || userData.roleCode === userRolesCodes.techAdmin) {
    return false;
  } else if (
    userData.roleCode === userRolesCodes.steering ||
    userData.roleCode === userRolesCodes.teamLeader ||
    userData.roleCode === userRolesCodes.teamMember ||
    userData.roleCode === userRolesCodes.companyAdmin ||
    userData.roleCode === userRolesCodes.sponsor ||
    userData.roleCode === userRolesCodes.externalReviewer
  ) {
    return false;
  } else {
    return true;
  }
};

export const userHasAccessProjectFields = (userData, teamLeaderId, sponsorId) => {
  if (
    userData.roleCode === userRolesCodes.lenovysAdmin ||
    userData.roleCode === userRolesCodes.techAdmin ||
    userData.roleCode === userRolesCodes.teamLeader ||
    userData.roleCode === userRolesCodes.sponsor ||
    userData.userId === teamLeaderId ||
    userData.userId === sponsorId
  ) {
    return true;
  } else if (
    userData.roleCode === userRolesCodes.lenovysAdmin ||
    userData.roleCode === userRolesCodes.techAdmin ||
    userData.roleCode === userRolesCodes.teamLeader ||
    userData.roleCode === userRolesCodes.sponsor ||
    userData.userId !== teamLeaderId ||
    userData.userId !== sponsorId
  ) {
    return false;
  } else {
    return false;
  }
};

export const userIsAdminOrProjectTeam = (userData, teamLeaderId, sponsorId) => {
  if (
    userData.roleCode === userRolesCodes.lenovysAdmin ||
    userData.roleCode === userRolesCodes.techAdmin ||
    userData.roleCode === userRolesCodes.companyAdmin ||
    userData.userId === teamLeaderId ||
    userData.userId === sponsorId
  ) {
    return true;
  } else if (
    userData.roleCode === userRolesCodes.lenovysAdmin ||
    userData.roleCode === userRolesCodes.techAdmin ||
    userData.roleCode === userRolesCodes.companyAdmin ||
    userData.userId !== teamLeaderId ||
    userData.userId !== sponsorId
  ) {
    return false;
  } else {
    return false;
  }
};

export const isRoleTeamMember = (userData) => {
  if (userData && userData.roleCode === userRolesCodes.teamMember) {
    return true;
  } else {
    return false;
  }
};

export const isTeamleaderOrTeamMember = (curentUserData) => {
  if (curentUserData.roleCode === userRolesCodes.teamLeader || curentUserData.roleCode === userRolesCodes.teamMember) {
    return true;
  } else {
    return false;
  }
};

export const isTeamleaderOrTeamMemberOrObjectSponsor = (curentUserData, obLungoData) => {
  if (curentUserData.roleCode === userRolesCodes.teamLeader || curentUserData.roleCode === userRolesCodes.teamMember) {
    if (curentUserData.roleCode === userRolesCodes.teamMember && obLungoData.sponsorID === curentUserData.userId) {
      return false;
    }
    return true;
  } else {
    return false;
  }
};

export const isTeamleaderOrTeamMemberOrObAnnoResponsible = (curentUserData, obAnnoData) => {
  if (curentUserData.roleCode === userRolesCodes.teamLeader || curentUserData.roleCode === userRolesCodes.teamMember) {
    if (
      curentUserData.roleCode === userRolesCodes.teamMember &&
      obAnnoData.getAnnualGoalDto.owner === curentUserData.userId
    ) {
      return false;
    }
    return true;
  } else {
    return false;
  }
};

export const hasUserProjectCostAccess = (
  userData,
  teamLeaderId,
  sponsorId,
  projectCostUserAccessSP,
  projectTeamMembers,
) => {
  if (
    userData.roleCode === userRolesCodes.lenovysAdmin ||
    userData.roleCode === userRolesCodes.steering ||
    userData.roleCode === userRolesCodes.companyAdmin ||
    userData.userId === teamLeaderId ||
    userData.userId === sponsorId
  ) {
    return userProjectCostAccessLevel.viewEdit;
  } else if (
    projectTeamMembers.indexOf(userData.userId) > -1 &&
    userData.roleCode !== userRolesCodes.steering &&
    !projectCostUserAccessSP
  ) {
    return userProjectCostAccessLevel.limited;
  } else if (projectCostUserAccessSP) {
    return userProjectCostAccessLevel.view;
  } else {
    return userProjectCostAccessLevel.noAccess;
  }
};

export const userIsProjectTeamLeaderOrSponsor = (userData, teamLeaderId, sponsorId) => {
  if (userData.userId === teamLeaderId || userData.userId === sponsorId) {
    return true;
  } else {
    return false;
  }
};

export const isUserAdminOrSteering = (userData) => {
  if (
    userData.roleCode === userRolesCodes.lenovysAdmin ||
    userData.roleCode === userRolesCodes.techAdmin ||
    userData.roleCode === userRolesCodes.companyAdmin ||
    userData.roleCode === userRolesCodes.steering
  ) {
    return true;
  } else {
    return false;
  }
};

export const responsabileAnnualGoalOrAdmin = (userData, annualGoalResponsabile) => {
  if (annualGoalResponsabile === userData.userId || isUserAdmin(userData)) {
    return true;
  } else {
    return false;
  }
};

export const userIsProjectTeamLeaderOrSponsorOrAdmin = (userData, teamLeaderId, sponsorId) => {
  if (userData.userId === teamLeaderId || userData.userId === sponsorId || isUserAdmin(userData)) {
    return true;
  } else {
    return false;
  }
};

import { Form } from 'antd';
import React, { Component } from 'react';

import { Card, Col, Modal, Row } from 'antd';
import BraftEditor from 'braft-editor';
import { ModalFooterButtons } from '../shared/components/modal/modalFooterButtons';
import { formItemLayout, richTectEditoControllsNoImage } from '../shared/utils/constants';
import { withTranslation } from 'react-i18next';

class EditKpiCommentModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      fieldsChanged: false,
    };
  }

  onSave = (values, kpiDetailID) => {
    const { rowData } = this.props;

    let editorContentToHtml = BraftEditor.createEditorState(values.comment).toHTML();
    let payload = {};
    payload['KPIDetailID'] = kpiDetailID;
    payload['Comment'] = editorContentToHtml;

    // if(rowData?.kpiType === kpiType.globale || rowData?.type === kpiType.globale){
    //     payload["isGlobalKPI"] = true;
    // } else {
    //     payload["isGlobalKPI"] = false;
    // }

    this.props.onCommentUpdate(payload);
  };

  render() {
    const { showCommentModal, handelCommentModalClose, loadingCommentSave, t, rowData } = this.props;

    return (
      <Modal
        className="add-comment-modal"
        width={800}
        title={`${t('general.commenti')} : ${rowData.kpiName}`}
        open={showCommentModal}
        destroyOnClose={true}
        onCancel={handelCommentModalClose}
        footer={[
          <ModalFooterButtons
            disabled={!this.state.fieldsChanged}
            loadingButton={loadingCommentSave}
            modalClose={handelCommentModalClose}
            key={rowData.kpiName}
            formId={'addKpiComment'}
          />,
        ]}>
        <Card>
          <Form
            id="addKpiComment"
            layout="vertical"
            {...formItemLayout}
            onFinish={(values) => this.onSave(values, rowData.kpiDetailID)}
            onValuesChange={() => {
              this.setState({ fieldsChanged: true });
            }}>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row"
                xs={{ span: 24 }}
                sm={{ span: 24 }}
                md={{ span: 24 }}
                lg={{ span: 24 }}>
                <Form.Item
                  className="main-container"
                  name="comment"
                  initialValue={BraftEditor.createEditorState(rowData.notes)}>
                  <BraftEditor
                    contentFormat="html"
                    language="en"
                    id="editor-text"
                    controls={richTectEditoControllsNoImage}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Form>
        </Card>
      </Modal>
    );
  }
}

export default withTranslation()(EditKpiCommentModal);

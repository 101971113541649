import axios from 'axios';
import deployment from '../deploymentConfigs';
import { Button, notification } from 'antd';

let createBrowserHistory = require('history').createBrowserHistory;
let history = createBrowserHistory({ forceRefresh: true });

const key = `open${Date.now()}`;
const notificationButton = (
  <Button
    type="primary"
    onClick={() => closeNotificationTab(key)}>
    Ok
  </Button>
);

const closeNotificationTab = async () => {
  // notification.close(key)
  history.push('/unauthorized');
  // notification.close(key)
};

const axiosInstance = axios.create({
  baseURL: deployment.backend.href,
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';

const axiosErrorHander = function (error) {
  console.log('error', error);

  if (error.response) {
    if (error.response.status === 401) {
      notification.open({
        duration: 18,
        message: 'NON AUTORIZZATO',
        description: 'Non sei autorizzato ad accedere alla pagina che stai cercando.',
        btn: notificationButton,
        key,
        onClose: closeNotificationTab,
      });
    }
    // switch (props) {
    //     case 401:
    //         //window.location = '/401';
    //         //history.push("/unauthorized");
    //        <ProtectedComponent code = {error.response.status} />
    //         //<Redirect to={{ pathname: '/unauthorized' }} />
    //         break;
    //     case 404:
    //         // Handle 404 here
    //         // redirect
    //         break;
    //     case 500:
    //         // Handle 500 here
    //         // redirect
    //         break;
    //     default:
    //         break;
    //}
  } else {
    console.log('other error:', error);
    //history.push("/error");
  }
  return Promise.reject(error);
}

axios.interceptors.response.use(
  (r) => r,
  axiosErrorHander,
);

axiosInstance.interceptors.response.use(
  (r) => r,
  axiosErrorHander,
);

export default axiosInstance;

import { Card, Col, Popover, Row, Space, Table, Tooltip, Typography } from 'antd';
import Parser from 'html-react-parser';
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import Section3Service from '../../../services/a3/section3Service';
import Loader2 from '../../shared/components/loader2/loader2';
import { BudgetSemaphore, QualitySemaphore, TimeSemaphore } from '../../shared/semaphores/semaphores';
import {
  checkCompanyBudgetAlert,
  projectDeliverableStatusColor,
  ResponsiblePopoverContent,
} from '../../shared/utils/functions';

import moment from 'moment';

const { Text } = Typography;

class Section3PrintPreview extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentData: null,
      firstTableData: [],
      fullScreen: false,
      loadingButton: false,
      currentDataActivities: null,
      isEnabled: false,
      followGraph: [],
      kpiList: [],
      selectedKpiValue: '',
      editorModal: false,
      fieldObject: null,
      options: {},
      sectionFieldChanged: false,
      companyData: this.props.companyData,
    };
  }

  async componentDidMount() {
    const { id } = this.props;
    this.getDataSection3(id);

    if (this.props.companyData && Object.keys(this.props.companyData).length > 0) {
      await this.retrieveProjectCompletedActivities(this.props.id, this.props.companyData.implementationDaysOfNotice);
    }

    await this.retrieveKPIPicklist(id);
    const { kpiList } = this.state;
    for (let i = 0; i < kpiList.length; i++) {
      if (kpiList.length > 0) {
        await this.getDataGraph(id, kpiList[i].kpiDetailID);
      }
    }
  }

  async componentDidUpdate(prevProps) {
    if (prevProps.companyData.implementationDaysOfNotice !== this.props.companyData.implementationDaysOfNotice) {
      await this.retrieveProjectCompletedActivities(this.props.id, this.props.companyData.implementationDaysOfNotice);
    }
  }

  getDataSection3 = (id) => {
    Section3Service.getImplementationSection(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentData: resp.responseObject.value });
        } else {
          // message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        // message.error(notifyMessages.retrieveFailed);
      });
  };

  getDataGraph = async (id, kpiId) => {
    await Section3Service.getFollowUpGraph(id, kpiId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ followGraph: resp.responseObject.value });
        } else {
        }
      })
      .catch((error) => {});
  };
  retrieveProjectCompletedActivities = (id, implementationDaysOfNotice) => {
    Section3Service.getCompletedActivities(id, implementationDaysOfNotice)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ currentDataActivities: resp.responseObject.value });
        } else {
          // message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        // message.error(notifyMessages.retrieveFailed);
      });
  };

  retrieveKPIPicklist = async (id) => {
    await Section3Service.getKPIPicklist(id)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          for (let i = 0; i < resp.responseObject.value.length; i++) {
            if (resp.responseObject.value.length > 0) {
              this.setState({
                kpiList: resp.responseObject.value,
                selectedKpiValue: resp.responseObject.value[i].name,
              });
            }
          }
        } else {
        }
      })
      .catch((error) => {});
  };

  render() {
    const { currentData, currentDataActivities, isEnabled, followGraph, selectedKpiValue } = this.state;
    const { companyData, t } = this.props;
    const ytdData = [];
    const targetYtdData = [];

    let max = 0;

    let val = max + max * 0.15;
    let currentMonth = new Date().getMonth();
    let currentYear = new Date().getFullYear();
    var options = {};

    const projectProgress = [
      {
        title: `${t('general.end')}`,
        dataIndex: 'startDate',
        key: 'startDate',
        ellipsis: {
          showTitle: true,
        },
        width: '100px',
        render: (text, record) => {
          return {
            props: {
              style: { borderLeft: `5px solid ${projectDeliverableStatusColor(record.deliverableStatus)}` },
            },
            children: (
              <div>
                <Tooltip title={record.notes}>{moment(text).format('MM/YYYY')}</Tooltip>
              </div>
            ),
          };
        },
      },
      {
        title: `${t('general.deliverable')}`,
        dataIndex: 'name',
        key: 'name',
        className: 'twoRowEllipses',
        // ellipsis: {
        //   showTitle: false,
        // },
        render: (title, record) => (
          <Tooltip title={title}>
            <Link
              to={{ pathname: `/deliverables/id/${record.deliverableID}` }}
              style={{ color: 'black' }}>
              {title}
            </Link>
          </Tooltip>
        ),
      },
      {
        title: `${t('general.assegnatoA')}`,
        dataIndex: 'ownerFullNameInitials',
        key: 'ownerFullNameInitials',
        width: '100px',
        ellipsis: {
          showTitle: false,
        },
        render: (title, record) => (
          // <Tooltip  title={record.ownerFullName}>
          //   {title}
          // </Tooltip>
          <Popover
            content={
              <ResponsiblePopoverContent
                data={record}
                t={t}
              />
            }
            title={record.ownerFullName}>
            <span style={record.isGenericResponsabile == true ? { color: '#f2883b' } : {}}>{title}</span>
          </Popover>
        ),
      },
      // {
      //   title: `${t('general.nota')}`,
      //   dataIndex: "notes",
      //   key: "notes",
      //   ellipsis: {
      //     showTitle: false,
      //   },
      //   render: title => (
      //     <Tooltip  title={title}>
      //       {title}
      //     </Tooltip>
      //   )

      // },
    ];

    return (
      <div>
        {!currentData && <Loader2 />}

        {currentData && followGraph && (
          <>
            <Row
              gutter={{ lg: 24 }}
              className="row-margin">
              <Col
                className="gutter-row tw-mb-4"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Card
                  title={t('a3Page.indicatoriTarget')}
                  className=" custom-card height260">
                  <Col
                    className="gutter-row no-padding"
                    xs={{ span: 24 }}
                    sm={{ span: 24 }}
                    md={{ span: 24 }}
                    lg={{ span: 24 }}>
                    <Row
                      gutter={{ lg: 24 }}
                      style={{ paddingTop: 5 }}>
                      <Col
                        className="gutter-row semaphore-section text-center"
                        xs={{ span: 8 }}
                        sm={{ span: 8 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <li className="semaphoreSpace">
                          <Space direction="vertical">
                            <TimeSemaphore data={currentData} />
                            <Text>
                              {currentData.timeSemaphoreNumerator ? currentData.timeSemaphoreNumerator : '0'}{' '}
                              {t('proggetiPage.semTimeChiudere')}
                            </Text>
                            <Text>
                              {currentData.timeSemaphoreDenominator ? currentData.timeSemaphoreDenominator : '0'}{' '}
                              {t('proggetiPage.semTimeDaChiudere')}
                            </Text>
                          </Space>
                        </li>
                      </Col>
                      <Col
                        className="gutter-row semaphore-section text-center"
                        xs={{ span: 8 }}
                        sm={{ span: 8 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <li className="semaphoreSpace">
                          <Space direction="vertical">
                            <BudgetSemaphore data={currentData} />
                            <Text>
                              {currentData.budgetSemaphoreNumerator ? currentData.budgetSemaphoreNumerator : '0'}{' '}
                              {checkCompanyBudgetAlert(companyData)} {t('proggetiPage.semCostConsutivati')}
                            </Text>
                            <Text>
                              {currentData.budgetSemaphoreDenominator ? currentData.budgetSemaphoreDenominator : '0'}{' '}
                              {checkCompanyBudgetAlert(companyData)} {t('proggetiPage.semCostBudget')}
                            </Text>
                          </Space>
                        </li>
                      </Col>
                      <Col
                        className="gutter-row semaphore-section no-border text-center"
                        xs={{ span: 8 }}
                        sm={{ span: 8 }}
                        md={{ span: 8 }}
                        lg={{ span: 8 }}>
                        <li className="semaphoreSpace">
                          <Space direction="vertical">
                            <QualitySemaphore data={currentData} />
                            <Text>
                              {currentData.qualitySemaphoreNumerator ? currentData.qualitySemaphoreNumerator : '0'}{' '}
                              {t('proggetiPage.semKpiLinea')}
                            </Text>
                            <Text>
                              {currentData.qualitySemaphoreDenominator ? currentData.qualitySemaphoreDenominator : '0'}{' '}
                              {t('proggetiPage.semKpi')}
                            </Text>
                          </Space>
                        </li>
                      </Col>
                    </Row>
                  </Col>
                </Card>
              </Col>

              <Col
                className="gutter-row tw-mb-4"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                {companyData && (
                  <Card
                    title={t('a3Page.statoAavanzamento')}
                    className="custom-card height260">
                    <Table
                      className="custom-table-section1 project-status"
                      columns={projectProgress}
                      dataSource={currentDataActivities}
                      rowClassName={(record) => 'status-' + record.deliverableStatus}
                      pagination={false}
                      rowKey={(obj) => obj.deliverableID}
                      size="small"
                    />
                  </Card>
                )}
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Card
                  title={t('a3Page.commentiS3')}
                  className="custom-card results-section text-area">
                  {Parser(currentData.comment ? currentData.comment : '')}
                </Card>
              </Col>

              <Col
                className="gutter-row"
                xs={{ span: 12 }}
                sm={{ span: 12 }}
                md={{ span: 12 }}
                lg={{ span: 12 }}>
                <Card
                  title={t('proggetiPage.beneficiOtenuti')}
                  className="custom-card results-section text-area">
                  {Parser(currentData.obtainedBenefit ? currentData.obtainedBenefit : '')}
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  companyData: state.companyData.companyData,
});

export default connect(mapStateToProps)(Section3PrintPreview);

import { Select, Space } from 'antd';

const { Option } = Select;

export const XmatrixFilter = (props) => {
  return (
    // <Col className="pad6 gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
    <Space
      direction="horizontal"
      className="mb10">
      {props.loadingPicklist === false ? (
        <Select
          id="xmatrix_filter"
          value={props.selectedXmatrixValue}
          loading={props.loadingPicklist}
          style={{ minWidth: '170px', color: '#909090' }}
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onChange={(option) => props.onXmatrixChange(option)}>
          {props.xmatrixPicklist &&
            props.xmatrixPicklist.map((item) => (
              <Option
                value={item.xMatrixID}
                key={item.xMatrixID}>
                {item.hkCode}
              </Option>
            ))}
        </Select>
      ) : (
        <Select
          id="xmatrix_filter"
          className="tw-pointer-events-none tw-touch-n"
          value={'...'}
          style={{ minWidth: '170px', color: '#909090' }}
          showSearch
          optionFilterProp="children"></Select>
      )}
    </Space>
    // </Col>
  );
};

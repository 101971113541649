import React from 'react';
import { FaCogs, FaLeaf, FaLightbulb, FaUsb } from 'react-icons/fa';
import { IoLogoUsd } from 'react-icons/io5';

const CategoryIcon = ({ iconName }) => {
  const icons = {
    'fa-usd ': <IoLogoUsd />,
    'fa-cogs': <FaCogs />,
    'fa-leaf': <FaLeaf />,
    'fa-lightbulb-o': <FaLightbulb />,
    'fa-usb ': <FaUsb />,
  };

  return <div className="tw-shrink-0">{icons[iconName] || <span>Icon not found</span>}</div>;
};

export default CategoryIcon;

import { DeleteFilled, EditFilled, PlusOutlined, WarningOutlined } from '@ant-design/icons';
import { Button, Col, Divider, List, message, Popconfirm, Row, Typography } from 'antd';
import React, { Component } from 'react';
import { ArcherContainer, ArcherElement } from 'react-archer';
import Riskervice from '../../../../services/pages/projectServices/riskServices';
import Loader from '../../../shared/components/loader/loader';
import TableLayout from '../../../shared/tableLayout';
import { checkRiskSeverity, checkStatus } from '../../../shared/utils/functions';
import { notifyMessages } from '../../../shared/utils/notifyMessages';
import '../../project.scss';
import AddCriticModal from './addCritic';
import EditCriticModal from './editCriticModal';
import './riskAndPrecaution.scss';

const { Text } = Typography;

const rootStyle = { display: 'flex', alignItem: 'center' };
const rowStyle = {
  margin: '0 100px 0 0',
  height: '50px',
  display: 'flex',
  alignItems: 'center',
  background: '#f5f5f5',
  borderRadius: '8px',
  width: '40%',
  textAlign: 'left',
};
const boxStyle = {
  background: '#f5f5f5',
  borderRadius: '8px',
  padding: '10px 0',
  marginLeft: '20px',
  fontSize: '18px',
};
class RiskAndPrecautions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingButton: false,
      isDataLoading: false,
      addCriticModal: false,
      editCriticModal: false,
      riskAndPrecautionList: [],
      editModalData: null,
    };
  }

  componentWillMount() {
    this.retrieveRiskAndPrecautionList();
  }

  componentWillUnmount() {
    this.setState({ isDataLoading: false });
  }

  toggleAddCriticModal = () => {
    this.setState({ addCriticModal: true });
    //this.setState({ fieldObject: fieldData });
  };

  handelCriticModalClose = () => {
    this.setState({ addCriticModal: false });
    this.setState({ loadingButton: false });
  };

  addCritic = (values, projectId) => {
    this.setState({ loadingButton: true });
    values['projectID'] = projectId;

    Riskervice.addCritic(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handelCriticModalClose();
          message.success(notifyMessages.addSuccess);
          this.retrieveRiskAndPrecautionList();
        } else {
          message.error(notifyMessages.addFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.addFailed);
        this.setState({ loadingButton: false });
      });
  };

  toggleEditCriticModal = (data) => {
    this.setState({ editCriticModal: true });
    this.setState({ editModalData: data });
  };

  handelEditModalClose = () => {
    this.setState({ editCriticModal: false });
    this.setState({ loadingButton: false });
    this.retrieveRiskAndPrecautionList();
  };

  editCritic = (values, riskID) => {
    this.setState({ loadingButton: true });
    values['riskID'] = riskID;

    Riskervice.updateCritic(values)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.handelEditModalClose();
          message.success(notifyMessages.updateSuccess);
          this.retrieveRiskAndPrecautionList();
        } else {
          message.error(notifyMessages.updateFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.updateFailed);
        this.setState({ loadingButton: false });
      });
  };

  onRemoveCritic = (riskID) => {
    Riskervice.deleteRisk(riskID)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          message.success(notifyMessages.deleteSuccess);
          this.retrieveRiskAndPrecautionList();
        } else {
          message.error(notifyMessages.deleteFailed);
          this.setState({ loadingButton: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.deleteFailed);
        this.setState({ loadingButton: false });
      });
  };

  async retrieveRiskAndPrecautionList() {
    this.setState({ isDataLoading: true });
    const { projectId } = this.props;
    await Riskervice.getRiskAndPrecaution(projectId)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ riskAndPrecautionList: resp.responseObject.value });
        } else {
          message.error(notifyMessages.retrieveFailed);
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
      });
  }

  render() {
    const { riskAndPrecautionList, addCriticModal, editCriticModal, editModalData } = this.state;
    const { projectId, hasUserAccess, t } = this.props;

    return (
      <TableLayout title={t('proggetiPage.criticitaRischi')}>
        <TableLayout.Actions>
          {addCriticModal && (
            <AddCriticModal
              showCriticModal={addCriticModal}
              handelCriticModalClose={this.handelCriticModalClose}
              handelCriticModalSave={this.addCritic}
              loadingButton={this.state.loadingButton}
              projectId={projectId}
              t={t}
            />
          )}

          {editCriticModal && (
            <EditCriticModal
              showEditCriticModal={editCriticModal}
              handelEditCriticModalClose={this.handelEditModalClose}
              handeEditCriticModalSave={this.editCritic}
              loadingButton={this.state.loadingButton}
              data={editModalData}
              t={t}
            />
          )}

          {!hasUserAccess ? (
            <Button
              data-testid="add_risk_btn"
              onClick={this.toggleAddCriticModal}
              type="primary">
              <PlusOutlined />
              {t('buttons.aggiungiNuovo')}
            </Button>
          ) : null}
        </TableLayout.Actions>
        <TableLayout.Content>
          {riskAndPrecautionList && (
            <div className="tw-border tw-rounded-md tw-p-4">
              <Row
                className="mb-row test"
                gutter={{ lg: 24 }}>
                <Col
                  xs={{ span: 24 }}
                  sm={{ span: 24 }}
                  md={{ span: 24 }}
                  lg={{ span: 24 }}>
                  <Loader />

                  <Row>
                    <Col
                      className="gutter-row tw-mb-4"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 10 }}
                      lg={{ span: 10 }}>
                      <div className="ant-list-item-meta-content tw-mb-4 list-headers">
                        <h5
                          className="ant-list-item-meta-title"
                          style={{ marginBottom: 0 }}>
                          {t('proggetiPage.criticitaRischi')}
                        </h5>
                        <div
                          className="ant-list-item-meta-description"
                          style={{ fontSize: 11 }}>
                          {t('a3Page.criticitaRischiDescription')}
                        </div>
                      </div>
                    </Col>

                    <Col
                      className="gutter-row tw-mb-4"
                      xs={{ span: 24 }}
                      sm={{ span: 24 }}
                      md={{ span: 14 }}
                      lg={{ span: 14 }}>
                      <div className="ant-list-item-meta-content tw-mb-4 list-headers">
                        <h5
                          className="ant-list-item-meta-title"
                          style={{ marginBottom: 0 }}>
                          {t('proggetiPage.contromisureIdentificate')}
                        </h5>
                        <div
                          className="ant-list-item-meta-description"
                          style={{ fontSize: 11 }}>
                          {t('a3Page.contrimisureIdentificateDescription')}
                        </div>
                      </div>
                    </Col>
                  </Row>

                  {riskAndPrecautionList.map((critic, index) => (
                    <Row
                      gutter={2}
                      key={index}>
                      <Col
                        xs={{ span: 24 }}
                        sm={{ span: 24 }}
                        md={{ span: 24 }}
                        lg={{ span: 24 }}>
                        <Col
                          className={(critic.status === 1 ? ' red' : '') + (critic.status === 2 ? ' green' : '')}
                          xs={{ span: 24 }}
                          sm={{ span: 24 }}
                          md={{ span: 24 }}
                          lg={{ span: 24 }}>
                          <ArcherContainer strokeColor="red">
                            <div
                              className="arrow-root"
                              style={rootStyle}>
                              <ArcherElement
                                id="root"
                                relations={critic.precautions.map((el) => {
                                  return {
                                    targetId: el.precautionID,
                                    targetAnchor: 'left',
                                    sourceAnchor: 'right',
                                    style: { endShape: 'circle', strokeColor: 'grey', strokeWidth: 1 },
                                  };
                                })}>
                                <span style={rowStyle}>
                                  {checkRiskSeverity(critic)}
                                  <Divider type="vertical" />
                                  {checkStatus(critic)}
                                  <span>
                                    <Text style={{ padding: '0px 10px' }}> {critic.riskDescription}</Text>
                                  </span>
                                  <div style={{ marginLeft: 'auto', marginRight: 0, padding: '0px 10px' }}>
                                    {/* <Divider type="vertical" /> */}
                                    <EditFilled
                                      style={{ color: '#023B72', fontSize: '16px', margin: '5px 10px' }}
                                      onClick={() => this.toggleEditCriticModal(critic)}
                                    />
                                    <span>
                                      <Popconfirm
                                        title={t('general.rimuovi')}
                                        icon={<WarningOutlined />}
                                        okText={t('general.si')}
                                        cancelText={t('general.no')}
                                        onConfirm={() => this.onRemoveCritic(critic.riskID)}>
                                        <DeleteFilled style={{ color: '#c30e0e', fontSize: '16px' }} />
                                      </Popconfirm>
                                    </span>
                                  </div>
                                </span>
                              </ArcherElement>
                              {critic.precautions.length > 0 && critic.precautions && (
                                <List
                                  bordered={false}
                                  className="countermeasures"
                                  dataSource={critic.precautions}
                                  renderItem={(item) => (
                                    <List.Item>
                                      <div style={boxStyle}>
                                        <ArcherElement
                                          id={item.precautionID}
                                          key={item.precautionID}>
                                          <div>
                                            <Text style={{ padding: '0px 10px' }}> {item.precautionDescription}</Text>
                                            {/* <Divider type="vertical" />
                                                                                    <EditFilled style={{color:'#023B72', fontSize: '16px', margin: '5px 10px'}} onClick={() => this.toggleEditCriticModal(critic)} /> */}
                                            {/* <span>
                                                                                <Popconfirm
                                                                                    title={t('general.rimuovi')}
                                                                                    icon={<WarningOutlined  />}
                                                                                    okText={t('general.si')}
                                                                                    cancelText={t('general.no')}
                                                                                    onConfirm={() => this.onRemoveCritic(critic.riskID)}>
                                                                                    <DeleteFilled style={{color:'#c30e0e', fontSize: '16px'}}/>
                                                                                </Popconfirm>
                                                                                </span> */}
                                          </div>
                                        </ArcherElement>
                                      </div>
                                    </List.Item>
                                  )}
                                />
                              )}
                            </div>
                          </ArcherContainer>
                        </Col>

                        {/*                                                        <Col className="gutter-row tw-mb-4" xs={{ span: 1 }} sm={{ span: 1 }} md={{ span: 1 }} lg={{ span: 1 }}>
                                                            <CaretRightOutlined style={{ color: "#c1c1c1" }} />
                                                        </Col>*/}
                      </Col>

                      {/*<Col className="gutter-row vertical-align" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 14 }} lg={{ span: 14 }}>

                                                    <Row gutter={2} >
                                                        <Col className="gutter-row tw-mb-4" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 20 }} lg={{ span: 21 }}>
                                                            {(critic.precautions.length > 0 && critic.precautions) &&
                                                                <List
                                                                    bordered={false}
                                                                    className="countermeasures"
                                                                    dataSource={critic.precautions.map(a => a.precautionDescription)}
                                                                    renderItem={item => <List.Item><Text ><RightOutlined className="arrow-icon" /> {item}</Text></List.Item>}
                                                                />
                                                            }
                                                        </Col>

                                                        {!hasUserAccess && 

                                                        <Col className="gutter-row tw-mb-4 vertical-align flex-end" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 4 }} lg={{ span: 3 }}>
                                                            <span>
                                                                <EditFilled style={{color:'#023B72'}} onClick={() => this.toggleEditCriticModal(critic)} />
                                                                <Divider type="vertical" />
                                                                <Popconfirm
                                                                    title={t('general.rimuovi')}
                                                                    icon={<WarningOutlined  />}
                                                                    okText={t('general.si')}
                                                                    cancelText={t('general.no')}
                                                                    onConfirm={() => this.onRemoveCritic(critic.riskID)}
                                                                >
                                                                    <DeleteFilled style={{color:'#C3C3C3'}}/>
                                                                </Popconfirm>
                                                            </span>
                                                        </Col>
                                                        }


                                                    </Row>


                                                </Col>*/}
                    </Row>
                  ))}
                </Col>
              </Row>
            </div>
          )}
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

export default RiskAndPrecautions;

import { Form, Typography } from 'antd';
import React, { useState, useEffect } from 'react';
import { SaveOutlined } from '@ant-design/icons';
import { Button, InputNumber, message, Switch } from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import CompanyService from '../../services/pages/companyService';
import Loader from '../shared/components/loader/loader';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';

const CompanyNotifications = () => {
  const [loadingButton, setLoadingButton] = useState(false);
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [notificationsData, setNotificationsData] = useState(null);
  const [currentCompany, setCurrentCompany] = useState();

  const { t: t } = useTranslation();
  const userData = useSelector((state) => state.userData.userData);

  useEffect(() => {
    retrieveCompanyNotifications();
    getCompanyData();
  }, []);

  const retrieveCompanyNotifications = async () => {
    try {
      const response = await CompanyService.getCompanyNotifications(userData.lastLoggedInCompanyID);
      const resp = response.data;
      if (resp.success) {
        setNotificationsData(resp.responseObject.value);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const getCompanyData = async () => {
    try {
      const response = await CompanyService.getCompanyData();
      const resp = response.data;
      if (resp.success) {
        setCurrentCompany(resp.responseObject.value);
      } else {
        message.error(notifyMessages.retrieveFailed);
      }
    } catch (error) {
      message.error(notifyMessages.retrieveFailed);
    }
  };

  const updateCompanyNotifications = async (values) => {
    const currentCompanyId = userData.lastLoggedInCompanyID;
    const alertID = notificationsData.alertID;
    values.companyID = currentCompanyId;
    values.alertID = alertID;

    setLoadingButton(true);
    try {
      const response = await CompanyService.updateCompanyNotificationsAlert(values);
      const resp = response.data;
      if (resp.success) {
        message.success(notifyMessages.updateSuccess);
      } else {
        message.error(notifyMessages.updateFailed);
      }
    } catch (error) {
      message.error(notifyMessages.updateFailed);
    } finally {
      setLoadingButton(false);
    }
  };

  return (
    <Form
      className="form-item-space-between"
      onFinish={(values) => updateCompanyNotifications(values)}
      onValuesChange={() => setFieldsChanged(true)}>
      <TableLayout title={`${t('companyNotificationsPage.companyNotifications')}`}>
        <TableLayout.Actions>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!fieldsChanged}
            loading={loadingButton}>
            {t('buttons.salva')}
          </Button>
        </TableLayout.Actions>
        <TableLayout.Content>
          <Typography.Paragraph>
            {t('companyNotificationsPage.pageDescription', {
              companyName: currentCompany?.companyName,
            })}
          </Typography.Paragraph>
          <Loader />
          {notificationsData && userData && (
            <div className="tw-flex tw-flex-col tw-divide-y">
              <Form.Item
                label={t('companyNotificationsPage.tuttoInUno')}
                name="allInOne"
                valuePropName="checked"
                initialValue={notificationsData.allInOne}>
                <Switch />
              </Form.Item>
              <Form.Item
                label={t('companyNotificationsPage.inviaMail')}
                name="sendViaMail"
                valuePropName="checked"
                initialValue={notificationsData.sendViaMail}>
                <Switch />
              </Form.Item>
              <Form.Item
                label={t('companyNotificationsPage.inviaCamanelloNotifica')}
                name="sendViaNotificationBell"
                valuePropName="checked"
                initialValue={notificationsData.sendViaNotificationBell}>
                <Switch />
              </Form.Item>
              <Form.Item
                label={t('companyNotificationsPage.giorniFrequenza')}
                name="reminderInDaysFrequence"
                initialValue={notificationsData.reminderInDaysFrequence}>
                <InputNumber
                  min={1}
                  className="tw-w-40"
                  max={100}
                />
              </Form.Item>
              <Form.Item
                label={t('companyNotificationsPage.periodo')}
                name="periodo"
                initialValue={notificationsData.periodo}>
                <InputNumber
                  className="tw-w-40"
                  min={1}
                  max={100}
                />
              </Form.Item>
            </div>
          )}
        </TableLayout.Content>
      </TableLayout>
    </Form>
  );
};

export default CompanyNotifications;

import { useEffect, useState } from 'react';
import KanBanColumn from './kanban-column';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const KanBan = ({ items = [], columns = [], colFn, colGroupFn, onDropFn }) => {
  const [columnItems, setColumnItems] = useState([]);

  useEffect(() => {
    if (!colFn) return;

    const columnList = columns
      .map((column) => ({
        ...column,
        items: items.filter((item) => colFn(item)?.id === column.id),
      }))
      .filter((column) => !(column?.hideIfEmpty && column.items?.length === 0));

    setColumnItems(columnList);
  }, [items, columns, colFn]);

  return (
    <DndProvider backend={HTML5Backend}>
      {columnItems.map(({ id, isDndEnabled, canCollapse, title, icon, items }) => (
        <KanBanColumn
          // for react only
          key={id}
          // if the column can collapse when no items - default true
          canCollapse={canCollapse}
          // function for dnd - when a card is dropped
          onDropFn={onDropFn}
          // boolean to indicate when the dnd is enabled - default false
          isDndEnabled={isDndEnabled}
          // function to group cards into the column
          colGroupFn={colGroupFn}
          // column items
          items={items}
          // others
          id={id}
          title={title}
          icon={icon}
        />
      ))}
    </DndProvider>
  );
};

export default KanBan;

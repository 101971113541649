import classNames from 'classnames';
import React from 'react';

const TableLayout = (props) => {
  const { title, children, grow = true } = props;

  const extraTitle = React.Children.toArray(children).find((child) => child.type === TableLayout.ExtraTitle);
  const actions = React.Children.toArray(children).find((child) => child.type === TableLayout.Actions);
  const subHeader = React.Children.toArray(children).find((child) => child.type === TableLayout.SubHeader);
  const content = React.Children.toArray(children).find((child) => child.type === TableLayout.Content);

  // Render title based on its type
  const renderTitle = () => {
    if (typeof title === 'string') {
      return <h2 className="tw-text-2xl tw-flex-1 tw-font-medium">{title}</h2>;
    } else if (title === undefined || title === null) {
      return <div className="tw-flex-1" />; // Placeholder element to take up space
    } else {
      return title;
    }
  };

  return (
    <div
      className={classNames('tw-flex tw-flex-col', {
        'tw-h-full': grow,
      })}
      {...props}>
      <div className="header-content tw-flex tw-h-10 tw-justify-between tw-items-center tw-gap-2 tw-mb-4">
        <div className="tw-flex tw-items-center tw-gap-2">
          {renderTitle()}
          {extraTitle ? extraTitle : null}
        </div>
        <div className="tw-flex empty:tw-hidden tw-items-center tw-gap-2">{actions}</div>
      </div>
      {subHeader && <div>{subHeader}</div>}
      <div
        className={classNames('tw-overflow-y-auto', {
          'tw-grow': grow,
        })}>
        {content}
      </div>
    </div>
  );
};

TableLayout.ExtraTitle = ({ children }) => {
  return <>{children}</>;
};

TableLayout.Actions = ({ children }) => {
  return <>{children}</>;
};

TableLayout.SubHeader = ({ children }) => {
  return <>{children}</>;
};

TableLayout.Content = ({ children }) => {
  return <>{children}</>;
};

export default TableLayout;

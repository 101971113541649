import { Table, Tag, message } from 'antd';
import React from 'react';
import { withTranslation } from 'react-i18next';
import { history } from '../../App';
import CategoryService from '../../services/pages/categoryService';
import TableLayout from '../shared/tableLayout';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './listCategoryPage.scss';
import CategoryIcon from '../shared/CategoryIcon';

class ListCategoryPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      searchText: '',
      searchedColumn: '',
      categoryListData: null,
    };
  }

  // Init of component after rendering
  componentDidMount() {
    this.retrieveCatgoryList();
  }

  onNew = () => {
    history.push('/categorie/new');
  };

  // Retrieve category data
  async retrieveCatgoryList() {
    this.setState({ loadingData: true });
    await CategoryService.getCategoryList()
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ categoryListData: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  render() {
    const { categoryListData, loadingData } = this.state;
    let t = this.props.t;

    const columns = [
      {
        title: `${t('general.nome')}`,
        dataIndex: 'name',
        ellipsis: {
          showTitle: true,
        },
        sorter: (a, b) => {
          return a.name.localeCompare(b.name);
        },
        showSorterTooltip: [true],
      },
      {
        title: `${t('categoriePage.icona')}`,
        dataIndex: 'iconSource',
        render: (text, record) => {
          return (
            <Tag className="tw-py-2">
              <CategoryIcon iconName={record.iconSource} />
            </Tag>
          );
        },
      },
      // {
      //   key: 'action',
      //   width: "30px",
      //   render: (text, record) => (
      //     <Space >
      //       <Link
      //         to={{
      //           pathname: `/categorie/id/${record.goalCategoryID}`
      //         }}>
      //         <EditOutlined  />
      //       </Link>
      //     </Space>
      //   ),
      // }
    ];

    return (
      <TableLayout title={t('categoriePage.categorie')}>
        <TableLayout.Actions>
          {/* <div className="button-wrapper">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="mb10 add-new"
                  onClick={this.onNew}
                >
                  <PlusOutlined />{t('buttons.aggiungiNuovo')}</Button>
              </div> */}
        </TableLayout.Actions>
        <TableLayout.Content>
          <Table
            size="small"
            columns={columns}
            dataSource={categoryListData}
            rowKey={(obj) => obj.goalCategoryID}
            loading={loadingData}
          />
        </TableLayout.Content>
      </TableLayout>
    );
  }
}

export default withTranslation()(ListCategoryPage);

import { CheckOutlined, CloseOutlined, EditOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Space, Switch, Table, Tooltip, Typography } from 'antd';
import { Component } from 'react';
import Highlighter from 'react-highlight-words';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ObiettiviAnnoService from '../../services/pages/obiettiviAnnoService';
import TableLayout from '../shared/tableLayout';
import { isTeamleaderOrTeamMember } from '../shared/utils/authRolesProvilege/authRolesPrivilege';
import { isTeamleader } from '../shared/utils/functions';
import { notifyMessages } from '../shared/utils/notifyMessages';
import './obietiviAnno.scss';

const { Text } = Typography;

class ObietiviAnno extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
      searchText: '',
      searchedColumn: '',
      obiettoAnnoListData: null,
      obAnnualRelationCheck: false,
    };
  }

  // Init of component after rendering
  componentDidMount() {
    this.retrieveObiettivoAnnoData();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={(node) => {
            this.searchInput = node;
          }}
          placeholder="Search"
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type="primary"
            onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => this.handleReset(clearFilters)}
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : '#677582', fontSize: 18 }} />,
    onFilter: (value, record) =>
      record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
    render: (text) =>
      this.state.searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
          searchWords={[this.state.searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ''}
        />
      ) : (
        text
      ),
  });

  handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    this.setState({
      searchText: selectedKeys[0],
      searchedColumn: dataIndex,
    });
  };

  handleReset = (clearFilters) => {
    clearFilters({ confirm: true });
    this.setState({ searchText: '' });
  };

  // Retrieve objective anno data
  async retrieveObiettivoAnnoData() {
    const { obAnnualRelationCheck } = this.state;

    this.setState({ loadingData: true });
    await ObiettiviAnnoService.getObiettiviAnnoList(obAnnualRelationCheck)
      .then((response) => response.data)
      .then((resp) => {
        if (resp.success) {
          this.setState({ obiettoAnnoListData: resp.responseObject.value });
          this.setState({ loadingData: false });
        } else {
          message.error(notifyMessages.retrieveFailed);
          this.setState({ loadingData: false });
        }
      })
      .catch((error) => {
        message.error(notifyMessages.retrieveFailed);
        this.setState({ loadingData: false });
      });
  }

  onNew = () => {
    this.props.history.push('/obiettiviannuali/new');
  };

  onChecked = (checked) => {
    this.setState({ obAnnualRelationCheck: checked }, () => {
      this.retrieveObiettivoAnnoData();
    });
  };

  render() {
    const { obiettoAnnoListData, loadingData } = this.state;
    let t = this.props.t;
    const { userData } = this.props;
    let isRoleTeamLeader = userData && Object.keys(userData).length > 0 ? isTeamleader(userData) : false;
    let isRoleTeamMemberOrTeamLeader =
      userData && Object.keys(userData).length > 0 ? isTeamleaderOrTeamMember(userData) : false;

    const columns = [
      // {
      //   width: '7px',
      //   render(text, record) {
      //     return {
      //       props: {
      //         style: { background: obAnualSemaphoreColor(record.qualitySemaphore) },
      //       },
      //     };
      //   },
      // },

      {
        title: `${t('general.nome')}`,
        dataIndex: 'description',
        width: '35%',
        ellipsis: {
          showTitle: true,
        },
        sorter: (a, b) => {
          a = a.description || '';
          b = b.description || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('description'),
        render: (title, record) => (
          <Tooltip title={title}>
            <Link
              to={{ pathname: `/obiettiviannuali/details/id/${record.annualGoalID}` }}
              style={{ color: '#323232' }}>
              {title}
            </Link>
          </Tooltip>
        ),
      },
      {
        title: `${t('general.responsabile')}`,
        dataIndex: 'ownerName',
        sorter: (a, b) => {
          a = a.ownerName || '';
          b = b.ownerName || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('ownerName'),
      },
      {
        title: `${t('general.stato')}`,
        dataIndex: 'statusDescription',
        width: '150px',
        sorter: (a, b) => {
          a = a.statusDescription || '';
          b = b.statusDescription || '';
          return a.localeCompare(b);
        },
        showSorterTooltip: [false],
        ...this.getColumnSearchProps('statusDescription'),
      },
      {
        key: 'action',
        width: '50px',
        render: (text, record) => (
          <Button
            icon={<EditOutlined />}
            href={`/obiettiviannuali/details/id/${record.annualGoalID}`}></Button>
        ),
      },
    ];

    return (
      <Card>
        <TableLayout title={t('obiettiviAnnoPage.obbietiviAnuali')}>
          <TableLayout.Actions>
            <Form.Item
              className="tw-mb-0 tw-mr-4"
              label={t('obiettiviAnnoPage.objSenzaRelazioni')}>
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                defaultChecked={false}
                onChange={(checked) => this.onChecked(checked)}
              />
            </Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              onClick={this.onNew}>
              <PlusOutlined />
              {t('buttons.aggiungiNuovo')}
            </Button>
          </TableLayout.Actions>
          <TableLayout.Content>
            <Table
              columns={columns}
              size="small"
              dataSource={obiettoAnnoListData}
              rowKey={(obj) => obj.annualGoalID}
              loading={loadingData}
              pagination={{ showSizeChanger: true, defaultPageSize: 50 }}
            />
          </TableLayout.Content>
        </TableLayout>
      </Card>
    );
  }
}

const mapStateToProps = (state) => ({
  userData: state.userData.userData,
});

export default withTranslation()(connect(mapStateToProps)(ObietiviAnno));

export const getQueryParam = (key) => {
  const url = new URL(window.location.href);
  return url.searchParams.get(key) || '';
};

export const addQueryParam = (key, value) => {
  const url = new URL(window.location.href);

  if (key === getQueryParam(key)) return;

  url.searchParams.set(key, value);

  window.history.replaceState({}, '', url.toString());
};

export const addQueryParams = (params) => {
  const url = new URL(window.location.href);

  Object.keys(params).forEach((key) => {
    if (params[key] === getQueryParam(key)) return;
    url.searchParams.set(key, params[key]);
  });

  window.history.replaceState({}, '', url.toString());
};

export const hasQueryParam = (key) => {
  const url = new URL(window.location.href);
  return url.searchParams.has(key);
};

export const addFragment = (fragment) => {
  const url = new URL(window.location.href);
  url.hash = fragment;
  window.history.replaceState({}, '', url.toString());
};

export const removeFragment = () => {
  const url = new URL(window.location.href);
  url.hash = '';
  window.history.replaceState({}, '', url.toString());
};

export const removeQueryParam = (key) => {
  const url = new URL(window.location.href);
  url.searchParams.delete(key);
  window.history.replaceState({}, '', url.toString());
};

export const clearQueryParams = () => {
  const url = new URL(window.location.href);
  url.searchParams.forEach((value, key) => url.searchParams.delete(key));
  window.history.replaceState({}, '', url.toString());
};

export const isRoute = (route) => {
  const path = window.location.pathname;

  return path.startsWith(route);
};
